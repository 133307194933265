import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, Chip, TableSortLabel } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ArrowDownward, ImportExport } from '@material-ui/icons';
import PrivateWrapper from '../../layout/Private';
import TableToolbar from '../../components/TableToolbar';
import listingStyle from '../../theme/styles/TableListing';
import listAll from '../../services/Zipcode';
import FormDialog from '../../components/zipcode/FormDialog';
import ConfirmDialog from '../../components/zipcode/ConfirmDialog';
import { viewStateById } from '../../services/State';
import UserTypes from '../../config/UserTypes';

const useStyles = makeStyles(listingStyle);

// Zipcodes management
const Zipcodes = () => {
  const pageName = 'Zip Codes';
  const classes = useStyles();

  const history = useHistory();

  const query = new URLSearchParams(window.location.search);
  const qstateId = query.get('stateId');

  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;

  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reloadRows, setReloadRows] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const [openModel, setOpenModel] = useState(false);
  const [confirmModel, setConfirmModel] = useState(false);

  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const [filterdIDText, setFilterdIDText] = useState(null);

  const columns = [
    { name: 'Zip Code', id: 'zipCode', width: '30%' },
    { name: 'City', id: 'city', width: '30%' },
    { name: 'State', id: 'stateCode', width: '20%' },
    { name: 'Status', id: 'status', width: '10%' },
  ];

  const getParams = () => {
    let queryString = '1=1';
    if (qstateId) queryString += `&stateId=${qstateId}`;
    if (sortBy) queryString += `&sortBy=${sortBy}`;
    if (orderBy) queryString += `&orderBy=${orderBy}`;
    if (searchText) queryString += `&searchText=${searchText}`;

    return queryString.substring(4);
  };

  useEffect(() => {
    setFilterdIDText(null);
    if (qstateId) {
      viewStateById(qstateId).then((res) => {
        setFilterdIDText(res.data.code);
      });
    }

    setDataLoaded(false);
    listAll(activePage, getParams())
      .then((res) => {
        setRows(res.data.rows);
        setTotalPages(res.data.totalPages);
        setDataLoaded(true);
      })
      .catch(() => {
        setDataLoaded(true);
      });
  }, [reloadRows, activePage]);

  const searchList = () => {
    setActivePage(1);
    setReloadRows(!reloadRows);
  };

  const sortHandler = (colId) => {
    setActivePage(1);
    setSortBy(colId);
    setOrderBy(orderBy === 'asc' ? 'desc' : 'asc');
    setReloadRows(!reloadRows);
  };

  const refreshList = () => {
    setReloadRows(!reloadRows);
  };

  const deleteData = (id) => {
    setDeleteId(id);
    setConfirmModel(true);
  };

  const resetList = () => {
    setSearchText('');
    setOrderBy('');
    setSortBy('');
    setFilterdIDText(null);
    setReloadRows(!reloadRows);
    setActivePage(1);
    history.push('/zipcodes');
  };

  const clearURLquery = () => {
    setFilterdIDText(null);
    setReloadRows(!reloadRows);
    setActivePage(1);
    history.push('/zipcodes');
  };

  return (
    <PrivateWrapper pageName={pageName}>
      <>
        <div className={classes.filterToolbar}>
          <TableToolbar
            refreshList={refreshList}
            searchList={searchList}
            getSearchText={(e) => setSearchText(encodeURIComponent(e.target.value))}
            addNew={() => setOpenModel(true)}
            resetList={resetList}
            showAddOption={userType === UserTypes.SUPER_ADMIN.value}
          />
        </div>
        <div>
          {filterdIDText && (
            <Box mb={2}>
              Filtered by state: <Chip label={filterdIDText} onDelete={clearURLquery} />
            </Box>
          )}
        </div>
        <TableContainer component={Paper}>
          <Table className={classes.tableData} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((col) => (
                  <TableCell width={col.width} align={col.align || ''}>
                    <TableSortLabel
                      active={sortBy === col.id}
                      direction={sortBy === col.id ? orderBy : 'asc'}
                      onClick={() => sortHandler(col.id)}
                      IconComponent={sortBy !== col.id ? ImportExport : ArrowDownward}
                    >
                      <span>{col.name}</span>
                    </TableSortLabel>
                  </TableCell>
                ))}
                {userType === UserTypes.SUPER_ADMIN.value && (
                  <TableCell align="right" width="10%">
                    Action
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!dataLoaded &&
                [1, 2, 3, 4, 5].map((val) => (
                  <TableRow key={`zpc-${val}`}>
                    <TableCell component="th" scope="row">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    {userType === UserTypes.SUPER_ADMIN.value && (
                      <TableCell align="right">
                        <Skeleton variant="text" className={classes.placeholderSkeleton} />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              {dataLoaded &&
                (rows.length > 0 ? (
                  rows.map((row) => (
                    <TableRow key={`zpc-${row.id}`}>
                      <TableCell component="th" scope="row">
                        {row.zipCode}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.city}
                      </TableCell>
                      <TableCell>{row.stateCode}</TableCell>
                      <TableCell>
                        <span
                          className={
                            row.status === 'Active' ? classes.statusActive : classes.statusInactive
                          }
                        >
                          {row.status}
                        </span>
                      </TableCell>
                      {userType === UserTypes.SUPER_ADMIN.value && (
                        <TableCell align="right">
                          <IconButton
                            aria-label="delete"
                            className={classes.deleteBtn}
                            onClick={() => deleteData(row.id)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      size="medium"
                      colSpan={5}
                      className={classes.noRecord}
                      style={{ padding: 20, fontSize: 15 }}
                    >
                      No records found
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {dataLoaded && totalPages > 1 && (
          <Pagination
            count={totalPages}
            showFirstButton
            showLastButton
            className={classes.tablePagination}
            onChange={(_, pageNumber) => {
              setActivePage(pageNumber);
              setReloadRows(!reloadRows);
            }}
            page={activePage}
          />
        )}

        {openModel && (
          <FormDialog
            open={openModel}
            closeModel={() => {
              setOpenModel(false);
              setEditId(null);
            }}
            title={editId ? 'Edit Zip Code' : 'Add Zip Code'}
            onSuccess={() => {
              setReloadRows(!reloadRows);
            }}
            editId={editId}
            qstateId={qstateId || null}
          />
        )}
        {confirmModel && (
          <ConfirmDialog
            open={confirmModel}
            closeModel={() => {
              setConfirmModel(false);
              setDeleteId(null);
            }}
            title="Zip Code"
            onSuccess={() => {
              setReloadRows(!reloadRows);
            }}
            deleteId={deleteId}
          />
        )}
      </>
    </PrivateWrapper>
  );
};

export default Zipcodes;
