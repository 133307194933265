import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';

const BioHtmlFiltered = ({ closeModel }) => {
  const handleDialog = () => {
    closeModel();
  };

  return (
    <Dialog open onClose={handleDialog}>
      <DialogTitle id="alert-dialog-title">Heads up!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          We had to reformat some of your bio that was probably copy &amp; pasted, and it&apos;s
          possible it might not look quite the same.
          <br />
          <br />
          Please double check, and make any final edits using only our editor, then re-save.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialog} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BioHtmlFiltered.propTypes = {
  closeModel: PropTypes.func.isRequired,
};

export default BioHtmlFiltered;
