import { Box, Typography } from '@material-ui/core';
import React from 'react';
import OpenSubmissionsGrid from '../submissions/OpenSubmissionsGrid';
import PostSubmissionsTable from '../submissions/PostSubmissionsTable';

const AgentNrpInsights = () => (
  <Box>
    <Box>
      <Box mb={2}>
        <Typography variant="h5">Open Prompts</Typography>
      </Box>
      <OpenSubmissionsGrid />
    </Box>
    <Box mt={3}>
      <Box mb={2}>
        <Typography variant="h5">Past Prompts</Typography>
      </Box>
      <PostSubmissionsTable />
    </Box>
  </Box>
);

export default AgentNrpInsights;
