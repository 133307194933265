import { Box, Paper, Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ManageCards from '../../components/billing/ManageCards';
import AgentTeam from '../agents/AgentTeam';
import RoutePaths from '../../config/Routes';
import Private from '../../layout/Private';
import { checkHasContract } from '../../services/Contract';

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const Billing = () => {
  const getDetails = window.localStorage.getItem('userDetail');
  const hasTeamAccess = getDetails ? JSON.parse(getDetails).hasTeamAccess : 0;
  const params = useParams();
  const history = useHistory();
  const pageName = 'Billing';
  const [tabValue, setTabValue] = useState('billing');

  useEffect(() => {
    checkHasContract().catch(() => history.push(RoutePaths.HOME));
    setTabValue(params.page);
  }, [params.page]);

  return (
    <Private pageName={pageName}>
      <Box m={2} p={2} component={Paper} elevation={0} minHeight="85vh">
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          onChange={(_, v) => {
            setTabValue(v);
          }}
        >
          {hasTeamAccess && <Tab value="team" label="My Team" {...a11yProps(0)} />}
          <Tab value="subs" label="Subscriptions" {...a11yProps(0)} />
          <Tab value="billing" label="Billing" {...a11yProps(0)} />
        </Tabs>
        {tabValue === 'billing' && (
          <Box mt={2}>
            <ManageCards />
          </Box>
        )}

        {hasTeamAccess && tabValue === 'team' && (
          <Box mt={2}>
            <AgentTeam />
          </Box>
        )}
      </Box>
    </Private>
  );
};

export default Billing;
