// All user types
const UserTypes = {
  SUPER_ADMIN: {
    value: 1,
    text: 'Super Admin',
  },
  ADMIN: {
    value: 2,
    text: 'Admin',
  },
  SALES_PERSON: {
    value: 3,
    text: 'Sales',
  },
  AGENT: {
    value: 4,
    text: 'Agent',
  },
  SUPPORT: {
    value: 5,
    text: 'Support',
  },
  SALES_MANAGER: {
    value: 6,
    text: 'Sales Manager',
  },
};

export default UserTypes;
