/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Table,
  TableCell,
  TableRow,
} from '@material-ui/core';
import JsFileDownloader from 'js-file-downloader';
import { viewSubmissionById } from '../../services/Approvals';
import { viewBlogById } from '../../services/Blogs';
import SnackbarMessage from '../SnackbarMessage';

const ViewDialog = ({ from, id, onClose }) => {
  const [submissionData, setSubmissionData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  useEffect(() => {
    setDataLoaded(false);
    if (from === 'submission') {
      viewSubmissionById(id).then((res) => {
        setSubmissionData(res.data);
        setDataLoaded(true);
      });
    } else {
      viewBlogById(id).then((res) => {
        setSubmissionData(res.data);
        setDataLoaded(true);
      });
    }
  }, []);

  const downloadVideo = (url) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    // process for download video
    // eslint-disable-next-line no-unused-vars
    const download = new JsFileDownloader({
      url,
      timeout: 30 * 60 * 1000,
    });
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: 'Video id downloading, Please do not close the browser or refresh the page.',
      type: 'success',
      show: true,
    });
  };

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>{from === 'submission' ? 'Submission' : 'Blog'} Details</DialogTitle>
      <DialogContent>
        {dataLoaded && (
          <Table>
            <TableRow>
              <TableCell width="25%">Prompt Title</TableCell>
              <TableCell width="75%">{submissionData.title}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Prompt Description</TableCell>
              <TableCell>{submissionData.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>{submissionData.statusText}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Schedule</TableCell>
              <TableCell>{submissionData.schedule}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Submissions</TableCell>
              <TableCell>
                {submissionData.submitted}/
                {submissionData.submission > 0 ? submissionData.submission : '∞'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>View Link</TableCell>
              <TableCell>
                {submissionData.viewLink ? (
                  <Link target="_blank" href={submissionData.viewLink}>
                    {from === 'submission' ? 'Click To Watch' : 'Click To View'}
                  </Link>
                ) : (
                  '-'
                )}
              </TableCell>
            </TableRow>
            {submissionData.standardVideoUrl && from === 'submission' && (
              <TableRow>
                <TableCell>Standard Video</TableCell>
                <TableCell>
                  <Link
                    color="primary"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      downloadVideo(submissionData.standardVideoUrl);
                    }}
                  >
                    Download
                  </Link>
                </TableCell>
              </TableRow>
            )}
            {submissionData.squareVideoUrl && from === 'submission' && (
              <TableRow>
                <TableCell>Square Video</TableCell>
                <TableCell>
                  <Link
                    color="primary"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      downloadVideo(submissionData.squareVideoUrl);
                    }}
                  >
                    Download
                  </Link>
                </TableCell>
              </TableRow>
            )}
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button disableElevation color="default" variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

ViewDialog.propTypes = {
  from: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ViewDialog;
