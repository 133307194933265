import React, { useState } from 'react';
import PrivateWrapper from '../../layout/Private';
import DashboardNavbar from '../../components/dashboard/DashboardNavbar';
import ManageNotifications from '../../components/dashboard/ManageNotifications';
import ManageBanners from '../../components/dashboard/ManageBanners';
import ManageBulletins from '../../components/dashboard/ManageBulletins';
import ManageFastTracks from '../../components/dashboard/ManageFastTracks';

// Dashboard page
const Dashboard = () => {
  const pageName = 'NRP Dashboard';
  const dashboardModules = ['notifications', 'banners', 'bulletins', 'fasttracks'];
  const [activeModule, setActiveModule] = useState(dashboardModules[0]);

  const handleModuleChange = (v) => {
    setActiveModule(v);
  };

  return (
    <PrivateWrapper pageName={pageName}>
      <br />
      <DashboardNavbar
        handleModuleChange={(v) => handleModuleChange(v)}
        dashboardModules={dashboardModules}
        activeModule={activeModule}
      />
      <br />
      {activeModule === dashboardModules[0] && <ManageNotifications />}
      {activeModule === dashboardModules[1] && <ManageBanners />}
      {activeModule === dashboardModules[2] && <ManageBulletins />}
      {activeModule === dashboardModules[3] && <ManageFastTracks />}
      <br />
    </PrivateWrapper>
  );
};

export default Dashboard;
