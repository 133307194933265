import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isToday, isTomorrow } from 'date-fns';
import { getOpenPrompts, postAgentSubmission } from '../../services/Approvals';
import submissionStyle from '../../theme/styles/Submission';
import SubmissionVideoUpload from '../approvals/SubmissionVideoUpload';
import ConfirmDialog from './ConfirmDialog';
import ViewVideo from './ViewVideo';
import SnackbarMessage from '../SnackbarMessage';

const useStyles = makeStyles(submissionStyle);
const OpenSubmissionsGrid = () => {
  const classes = useStyles();
  const [openPrompts, setOpenPrompts] = useState([]);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [reloadData, setReloadData] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [actionId, setActionId] = useState(0);
  const [viewModel, setViewModel] = useState(false);
  const [viewUrl, setViewUrl] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    setDataLoaded(false);
    getOpenPrompts().then((res) => {
      setOpenPrompts(res.data);
      setDataLoaded(true);
    });
  }, [reloadData]);

  const setAgentVideo = (url, id) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    postAgentSubmission(id, { url })
      .then(() => {
        setReloadData(!reloadData);
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
      });
  };

  const handleAction = (row, action) => {
    if (action === 'delete') {
      setActionId(row.id);
      setConfirmDialog(true);
    } else if (action === 'view') {
      setViewUrl(row.videoUrl);
      setViewModel(true);
    }
  };

  return (
    <Grid container spacing={2}>
      {openPrompts.length === 0 && dataLoaded && (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" mt={1} mb={2}>
          <Typography variant="body2">
            Please check back soon for the next NRP Insights prompt!
          </Typography>
        </Box>
      )}
      {dataLoaded &&
        openPrompts.map((p) => (
          <Grid item>
            <Card
              component={Box}
              width={250}
              height={250}
              p={0.5}
              display="flex"
              flexDirection="column"
            >
              <CardContent className={classes.cardContent}>
                <Typography className={classes.promptTitle}>{p.title}</Typography>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="column"
                  height={144}
                >
                  <Typography className={classes.promptDescription}>{p.description}</Typography>
                  <Box>
                    <Typography className={classes.submitText}>Submit Video By:</Typography>
                    <Typography
                      className={
                        isToday(new Date(p.closedDate)) || isTomorrow(new Date(p.closedDate))
                          ? classes.redText
                          : ``
                      }
                    >
                      {p.closeDate}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>

              <CardActions>
                {!p.videoUrl ? (
                  <SubmissionVideoUpload
                    title="Upload Video"
                    submissionId={p.id}
                    onSuccess={(url) => {
                      setAgentVideo(url, p.id);
                    }}
                    size="small"
                    variant="contained"
                    hideInfo
                    disabled={!p.isAvailable}
                  />
                ) : (
                  <UploadMenu handleAction={handleAction} row={p} />
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      {confirmDialog && (
        <ConfirmDialog
          deleteId={actionId}
          closeModel={() => {
            setConfirmDialog(false);
            setActionId(0);
          }}
          onSuccess={() => {
            setReloadData(!reloadData);
            setConfirmDialog(false);
            setActionId(0);
          }}
        />
      )}
      {viewModel && (
        <ViewVideo
          handleClose={() => {
            setViewModel(false);
            setViewUrl('');
          }}
          url={viewUrl}
        />
      )}
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Grid>
  );
};

const UploadMenu = ({ handleAction, row }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptClick = (action) => {
    handleAction(row, action);
    handleClose();
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<ArrowDropDown />}
        size="small"
      >
        Manage Video
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleOptClick('view')}>View</MenuItem>
        <MenuItem onClick={() => handleOptClick('delete')}>Delete</MenuItem>
      </Menu>
    </>
  );
};

UploadMenu.propTypes = {
  row: PropTypes.objectOf().isRequired,
  handleAction: PropTypes.func.isRequired,
};
export default OpenSubmissionsGrid;
