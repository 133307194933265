import { Box, Paper, Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AgentContentEngine from '../content-engine/AgentContentEngine';
import Private from '../../layout/Private';
import { trackUserClick } from '../../utils/Activity';
import ContentEnginePics from '../../components/branding/ContentEnginePics';
import { agentTypeValues } from '../../config/DataValues';
import RoutePaths from '../../config/Routes';

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

function getTabID() {
  let initilalTabOpen = 'setup';
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('#');
  const anchor = urlParts.length > 1 ? urlParts[1] : '';

  if (anchor === 'setup' || anchor === '') {
    initilalTabOpen = 'setup';
  } else if (anchor === 'facebook-calendar') {
    initilalTabOpen = 'facebook-calendar';
  }
  return initilalTabOpen;
}

const Content = () => {
  const pageName = 'Content Engine';
  const user = window.localStorage.getItem('userDetail');
  const userNameObj = user ? JSON.parse(user) : null;
  const agentType = Number(userNameObj.agentType || agentTypeValues.AGENTS);
  const isCEUser = agentType === agentTypeValues.CE_USERS;
  const [tabValue, setTabValue] = useState(getTabID());

  const history = useHistory();
  if (!isCEUser) {
    history.push(RoutePaths.HOME);
    return <></>;
  }

  useEffect(() => {
    let objId = 0;
    if (tabValue === 'facebook-calendar') {
      // objId = 79;
      objId = 90;
    } else if (tabValue === 'setup') {
      objId = 78;
    }
    if (objId) {
      trackUserClick(objId);
    }
  }, [tabValue]);

  return (
    <Private pageName={pageName}>
      <Box m={2} p={2} component={Paper} elevation={0} minHeight="85vh">
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          onChange={(_, v) => {
            setTabValue(v);
          }}
        >
          {<Tab value="setup" label="Setup" {...a11yProps(0)} />}
          {<Tab value="facebook-calendar" label="Facebook Calendar" {...a11yProps(0)} />}
        </Tabs>
        {tabValue === 'setup' && (
          <Box mt={2}>
            <ContentEnginePics />
          </Box>
        )}
        {tabValue === 'facebook-calendar' && (
          <Box mt={2}>
            <AgentContentEngine />
          </Box>
        )}
      </Box>
    </Private>
  );
};

export default Content;
