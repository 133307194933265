import React from 'react';
import PrivateWrapper from '../../layout/Private';
import OverviewPage from '../../components/OverviewPage';
import AgentOverviewPage from '../../components/dashboard/AgentOverview';

// Overview page
const Overview = () => {
  const pageName = '';
  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;

  return (
    <PrivateWrapper pageName={pageName}>
      <br />
      {userType !== 4 && <OverviewPage />}
      {/* UserType 4 is for Agent */}
      {userType === 4 && <AgentOverviewPage />}
    </PrivateWrapper>
  );
};

export default Overview;
