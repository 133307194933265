import {
  Button,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import RoutePaths from '../../config/Routes';
import { resetMyPassword } from '../../services/Auth';
import formInput from '../../theme/styles/FormInput';
import Validations from '../../utils/Validations';
import { isDefaultApp } from '../../utils/AppDomain';
import SnackbarMessage from '../../components/SnackbarMessage';
import PublicWrapper from '../../layout/Public';
import loginStyle from '../../theme/styles/Login';
import logo from '../../assets/images/logo.png';
import cLogo from '../../assets/images/c-logo.png';

const useStyles = makeStyles(formInput);
const useStyles2 = makeStyles(loginStyle);

const ForgotPassword = () => {
  const classes = useStyles();
  const classes2 = useStyles2();

  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const { handleSubmit, control, reset, getValues, setValue } = useForm();

  const submitForm = (data) => {
    setProcessing(true);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    resetMyPassword(data)
      .then((res) => {
        reset();
        setValue('userName', '');
        setProcessing(false);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: res.message,
          type: 'success',
          show: true,
        });
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
        setProcessing(false);
      });
  };

  return (
    <PublicWrapper>
      <Grid container spacing={0} align="center" justify="center" direction="column">
        <Container maxWidth="xs">
          <img
            src={isDefaultApp() ? logo : cLogo}
            alt={process.env.REACT_APP_NAME}
            className={classes2.logo}
          />
          <Container component="div" className={classes2.authPageText}>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
          </Container>
          <form onSubmit={handleSubmit(submitForm)} className={classes2.authForm}>
            <Controller
              control={control}
              rules={Validations.REQUIRED}
              name="userName"
              id="userName"
              defaultValue={getValues('userName') || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="Email Address*"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  className={classes.formInput}
                  fullWidth
                />
              )}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
              endIcon={processing && <CircularProgress size={25} />}
              disabled={processing}
              fullWidth
            >
              Request
            </Button>
            {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
          </form>
          Remember your password?{' '}
          <Link className={classes2.greenLinkText} to={RoutePaths.LOGIN}>
            Log In
          </Link>
        </Container>
      </Grid>
    </PublicWrapper>
  );
};

export default ForgotPassword;
