import {
  Box,
  CircularProgress,
  Grid,
  Button,
  CardMedia,
  IconButton,
  Tooltip,
  Card,
  Typography,
  CardContent,
  CardActions,
  makeStyles,
  CardActionArea,
} from '@material-ui/core';
import { RefreshOutlined } from '@material-ui/icons';
import copy from 'copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import RefreshIcon from '@material-ui/icons/Refresh';
import VideoPlayer from 'react-video-js-player';
import React, { useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login';
import JsFileDownloader from 'js-file-downloader';
import {
  listAgentContentList,
  getAgentAsset,
  setupFBAccount,
  hasFBAccountSetup,
  skipCePost,
  retryCePost,
} from '../../services/ContentEngine';
import profilePicStyle from '../../theme/styles/ProfilePics';
import ViewAgentContent from './ViewAgentContent';
import SnackbarMessage from '../../components/SnackbarMessage';
import AgentCePostStatus from '../../config/AgentCePostStatus';
import { formatCePostDateAgentSide } from '../../utils/Datetime';
import sampleImg1 from '../../assets/images/samples/ce-post1.png';
import sampleImg2 from '../../assets/images/samples/ce-post2.png';
import sampleImg3 from '../../assets/images/samples/ce-post3.png';
import fbIcon from '../../assets/images/icons/facebook.png';
import { trackUserClick } from '../../utils/Activity';

const useStyles = makeStyles(profilePicStyle);
const useStylesFb = makeStyles({
  fbButton: {
    backgroundColor: '#1877F2',
    color: 'white',
    cursor: 'pointer',
    width: '290px',
    textTransform: 'none',
    padding: '5px 15px',
    border: '1px solid rgba(67, 160, 71, 0.5)',
    borderRadius: '4px',
    fontWeight: '600',
    lineHeight: '1.95',
    letterSpacing: '0.02857em',
    fontSize: '0.885rem',
    '& .fbicon-div': {
      width: '28px !important',
      height: '28px !important',
      borderRadius: '100% !important',
      background: 'white',
      float: 'left',
      '& img': {
        width: '28px',
        marginTop: 2,
      },
    },
  },
});

const AgentContentEngineList = () => {
  const FBAppId = process.env.REACT_APP_FB_APP_ID;
  const getDetails = window.localStorage.getItem('userDetail');
  const loggedInAgentId = getDetails ? Number(JSON.parse(getDetails).id) : 0;
  const classes = useStyles();
  const classesFb = useStylesFb();
  const [contentData, setContentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewModel, setViewModel] = useState(false);
  const [viewItem, setViewItem] = useState(null);
  const [agentAsset, setAgentAsset] = useState(null);
  const [isFBSetup, setIsFBSetup] = useState(false);
  const [refreshList, setRefreshList] = useState(1);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  useEffect(() => {
    listAgentContentList()
      .then((res) => {
        setContentData(res.data?.rows || []);
      })
      .catch(() => {
        setContentData([]);
      });
    getAgentAsset(loggedInAgentId)
      .then((resp) => {
        setAgentAsset(resp.data.url);
      })
      .catch(() => {
        setAgentAsset('');
      });
    hasFBAccountSetup()
      .then((res) => {
        setIsFBSetup(Number(res.data) > 0);
        if (Number(res.data) === 0) {
          setLoading(false);
        }
      })
      .catch(() => {});

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [refreshList]);

  const handleRefreshClick = () => {
    setSnackbarMeesage({
      show: false,
      type: '',
      message: '',
    });
    setLoading(true);
    setRefreshList(refreshList + 1);
  };

  const openViewModel = (item) => {
    setViewItem(item);
    trackUserClick(93, { agent_id: loggedInAgentId, ce_post_id: item.id });
    setViewModel(true);
  };

  const copyText = (text, id) => {
    setSnackbarMeesage({
      show: false,
      type: '',
      message: '',
    });
    copy(text);
    trackUserClick(98, { agent_id: loggedInAgentId, ce_post_id: id });
    setTimeout(() => {
      setSnackbarMeesage({
        message: `Caption copied to clipboard!`,
        type: 'success',
        show: true,
      });
    }, 250);
  };

  const handleFacebookResponse = (response) => {
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });

    const payload = {
      ...response,
      status: response.accessToken ? 1 : 0,
      linkingModal: 2,
    };
    setupFBAccount(payload)
      .then(() => {
        if (payload.status === 0) {
          setSnackbarMeesage({
            message: 'There seems to be a problem linking your Facebook account. Please try again.',
            type: 'error',
            show: true,
          });
        } else {
          trackUserClick(92);
          setSnackbarMeesage({
            message: 'Your Facebook account has been successfully linked!',
            type: 'success',
            show: true,
          });
          setIsFBSetup(true);
        }
      })
      .catch(() => {
        setSnackbarMeesage({
          message: 'There seems to be a problem linking your Facebook account. Please try again.',
          type: 'error',
          show: true,
        });
      });
  };

  const handleSkipPost = (id, skip) => {
    trackUserClick(skip ? 94 : 95, { agent_id: loggedInAgentId, ce_post_id: id });

    skipCePost(id, { skip })
      .then(() => {
        setRefreshList(refreshList + 1);
      })
      .catch(() => {
        setRefreshList(refreshList + 1);
      });
  };

  const generateDownloadProcess = (url, id, contentType) => {
    setSnackbarMeesage({
      show: false,
      type: '',
      message: '',
    });
    trackUserClick(97, { agent_id: loggedInAgentId, ce_post_id: id });

    if (contentType === 'image') {
      const filename = url.split('/').pop();

      // eslint-disable-next-line no-unused-vars
      const download = new JsFileDownloader({
        url: `${url}?rand=${Math.random()}`,
        timeout: 2 * 60 * 1000,
        forceDesktopMode: true,
        nameCallback() {
          return `${filename}`;
        },
      });
    } else {
      window.open(url);
    }
    setTimeout(() => {
      setSnackbarMeesage({
        message: 'Download will start in a few seconds.',
        type: 'success',
        show: true,
      });
    }, 250);
  };

  const handleRetryPost = (id) => {
    setSnackbarMeesage({
      show: false,
      type: '',
      message: '',
    });

    retryCePost(id)
      .then(() => {
        trackUserClick(96, { agent_id: loggedInAgentId, ce_post_id: id, result: 'success' });
        setRefreshList(refreshList + 1);
        setSnackbarMeesage({
          message: 'Please wait a few minutes while we retry posting on Facebook.',
          type: 'success',
          show: true,
        });
      })
      .catch(() => {
        trackUserClick(96, { agent_id: loggedInAgentId, ce_post_id: id, result: 'fail' });
        setRefreshList(refreshList + 1);
        setSnackbarMeesage({
          message: 'Something went wrong, please try again.',
          type: 'error',
          show: true,
        });
      });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress style={{ marginTop: 100, marginBottom: 100 }} />
      </Box>
    );
  }

  return (
    <>
      {!isFBSetup && (
        <>
          <Grid container spacing={4} style={{ marginTop: 40, marginBottom: 30 }}>
            <div style={{ width: 320, marginLeft: 20, marginBottom: 20 }}>
              We need you to link your Facebook page to automatically post on your behalf.
            </div>
            <div style={{ width: 300, marginLeft: 20, marginBottom: 20 }}>
              <FacebookLogin
                appId={FBAppId}
                fields="name,email,picture"
                scope="pages_show_list,pages_manage_posts"
                callback={handleFacebookResponse}
                onFailure={() => {}}
                onClick={() => trackUserClick(91)}
                textButton="Link your Facebook page"
                cssClass={classesFb.fbButton}
                icon={
                  <div className="fbicon-div">
                    <img src={fbIcon} alt="facebook" />
                  </div>
                }
                isMobile={false}
                disableMobileRedirect
              />
            </div>
          </Grid>
          <Grid container spacing={4} style={{ opacity: 0.4 }}>
            <Grid item>
              <Card component={Box} width={300}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt=""
                    image={sampleImg1}
                    title=""
                    height={200}
                    width={300}
                    className={classes.contentEngineImage}
                  />
                </CardActionArea>
                <CardContent>
                  <Box display="flex" flexDirection="column">
                    <Box
                      style={{
                        maxWidth: 280,
                        minHeight: 20,
                        maxHeight: 20,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        A Home For Stockings
                      </Typography>
                    </Box>
                    <Box
                      mt={2}
                      style={{
                        maxWidth: 280,
                        minHeight: 70,
                        maxHeight: 70,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <Typography variant="body1">
                        The holiday spirit is in the air. And it&apos;s not too late to dream of a
                        future home for next year.
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Card component={Box} width={300}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt=""
                    image={sampleImg2}
                    title=""
                    height={200}
                    width={300}
                    className={classes.contentEngineImage}
                  />
                </CardActionArea>
                <CardContent>
                  <Box display="flex" flexDirection="column">
                    <Box
                      style={{
                        maxWidth: 280,
                        minHeight: 20,
                        maxHeight: 20,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        Myth or Fact?
                      </Typography>
                    </Box>
                    <Box
                      mt={2}
                      style={{
                        maxWidth: 280,
                        minHeight: 70,
                        maxHeight: 70,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <Typography variant="body1">
                        You don&apos;t want to get this one wrong.
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Card component={Box} width={300}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt=""
                    image={sampleImg3}
                    title=""
                    height={200}
                    width={300}
                    className={classes.contentEngineImage}
                  />
                </CardActionArea>
                <CardContent>
                  <Box display="flex" flexDirection="column">
                    <Box
                      style={{
                        maxWidth: 280,
                        minHeight: 20,
                        maxHeight: 20,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        Tip of the iceberg?
                      </Typography>
                    </Box>
                    <Box
                      mt={2}
                      style={{
                        maxWidth: 280,
                        minHeight: 70,
                        maxHeight: 70,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <Typography variant="body1">
                        Calling all the real estate agents in the community, do you agree?
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      )}

      {isFBSetup && (
        <>
          {!loading && (
            <Box display="flex" justifyContent="flex-end" mb={1} style={{ marginTop: '-55px' }}>
              <Tooltip title="Refresh">
                <IconButton size="small" onClick={handleRefreshClick}>
                  <RefreshOutlined />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          <Grid container spacing={2}>
            {contentData &&
              contentData.map((item) => (
                <Grid item key={`card-ce-${item.id}`}>
                  <Card component={Box} width={300}>
                    <CardActionArea>
                      {item.imageURL && (
                        <CardMedia
                          component="img"
                          alt=""
                          image={item.imageURL}
                          title=""
                          height={200}
                          width={300}
                          className={classes.contentEngineImage}
                        />
                      )}
                      {item.videoURL && (
                        <VideoPlayer
                          controls={false}
                          src={item.videoURL}
                          width="300"
                          height="200"
                          hideControls={[
                            'play',
                            'volume',
                            'seekbar',
                            'timer',
                            'playbackrates',
                            'fullscreen',
                          ]}
                          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                        />
                      )}
                      {agentAsset &&
                        (item.videoURL || item.imageURL) &&
                        item.showAgentAssetOverlay && (
                          <>
                            <Box
                              style={{
                                position: 'absolute',
                                bottom: '2px',
                                heigh: '30px',
                                width: '100%',
                                left: 0,
                                textAlign: 'center',
                              }}
                            >
                              <img src={agentAsset} alt="" height={30} />
                            </Box>
                          </>
                        )}
                    </CardActionArea>
                    <CardContent>
                      <Box display="flex" flexDirection="column">
                        <Box
                          style={{
                            maxWidth: 280,
                            minHeight: 20,
                            maxHeight: 20,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                            {item.topic}
                          </Typography>
                        </Box>
                        <Box
                          mt={2}
                          style={{
                            maxWidth: 280,
                            minHeight: 70,
                            maxHeight: 70,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <Typography variant="body1">{item.proposedText}</Typography>
                        </Box>
                        <Typography variant="body1" style={{ fontSize: 15, marginTop: 15 }}>
                          Post Date: {formatCePostDateAgentSide(item.displayDate)}
                          <br />
                          Status:{' '}
                          <span
                            style={{
                              color: [
                                AgentCePostStatus.SCHEDULED.value,
                                AgentCePostStatus.POSTED.value,
                              ].includes(item.status)
                                ? 'inherit'
                                : 'red',
                            }}
                          >
                            {item.statusText}
                          </span>
                        </Typography>
                      </Box>
                    </CardContent>
                    <CardActions className={classes.cardAction}>
                      <div>
                        <Button
                          type="button"
                          onClick={() => openViewModel(item)}
                          size="small"
                          color="primary"
                          variant="contained"
                          style={{
                            textTransform: 'none',
                            marginRight: 5,
                            width: 130,
                            boxShadow: 'none',
                          }}
                        >
                          View full post
                        </Button>
                        {item.status === AgentCePostStatus.SCHEDULED.value && item.inFuture && (
                          <Button
                            type="button"
                            onClick={() => handleSkipPost(item.id, 1)}
                            size="small"
                            color="primary"
                            variant="contained"
                            style={{
                              textTransform: 'none',
                              marginRight: 5,
                              backgroundColor: '#ff000094',
                              width: 130,
                              boxShadow: 'none',
                            }}
                          >
                            Do not post!
                          </Button>
                        )}
                        {item.status === AgentCePostStatus.SKIPPED.value && item.inFuture && (
                          <Button
                            type="button"
                            onClick={() => handleSkipPost(item.id, 0)}
                            size="small"
                            color="primary"
                            variant="contained"
                            style={{
                              textTransform: 'none',
                              marginRight: 5,
                              width: 130,
                              boxShadow: 'none',
                            }}
                          >
                            Unskip
                          </Button>
                        )}
                      </div>

                      <div>
                        {[
                          AgentCePostStatus.PAST.value,
                          AgentCePostStatus.SKIPPED.value,
                          AgentCePostStatus.UNSUCCESSFUL.value,
                        ].includes(item.status) &&
                          !item.inFuture && (
                            <>
                              <Tooltip title="Click to retry sharing to Facebook">
                                <Button
                                  type="button"
                                  size="small"
                                  onClick={() => handleRetryPost(item.id)}
                                  className={classes.actionBtn}
                                  style={{
                                    marginRight: item.showAgentAssetOverlay ? 87 : 5,
                                    padding: 2,
                                  }}
                                >
                                  <RefreshIcon />
                                </Button>
                              </Tooltip>
                              {!item.showAgentAssetOverlay && (
                                <Tooltip title="Click to copy image or video">
                                  <Button
                                    type="button"
                                    size="small"
                                    onClick={() =>
                                      generateDownloadProcess(
                                        item.imageURL || item.videoURL,
                                        item.id,
                                        item.contentType
                                      )
                                    }
                                    className={classes.actionBtn}
                                    style={{ marginRight: 5, padding: 2 }}
                                  >
                                    <PhotoLibraryIcon />
                                  </Button>
                                </Tooltip>
                              )}
                              {!item.showAgentAssetOverlay && (
                                <Tooltip title="Click to copy caption text">
                                  <Button
                                    type="button"
                                    size="small"
                                    onClick={() => copyText(item.proposedText)}
                                    className={classes.actionBtn}
                                    style={{ padding: 2 }}
                                  >
                                    <FileCopyIcon />
                                  </Button>
                                </Tooltip>
                              )}
                            </>
                          )}
                      </div>
                    </CardActions>
                  </Card>
                </Grid>
              ))}

            {contentData.length === 0 && (
              <div style={{ width: '100%', textAlign: 'center', marginTop: 100 }}>
                There are no posts available.
              </div>
            )}
          </Grid>
        </>
      )}

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}

      {viewModel && (
        <ViewAgentContent
          topic={viewItem.topic}
          imageURL={viewItem.imageURL || ''}
          videoURL={viewItem.videoURL || ''}
          contentType={viewItem.contentType || ''}
          agentAsset={agentAsset || ''}
          proposedText={viewItem.proposedText}
          showAgentAssetOverlay={viewItem.showAgentAssetOverlay}
          onClose={() => {
            setViewModel(false);
            setViewItem(null);
          }}
        />
      )}
    </>
  );
};

export default AgentContentEngineList;
