import { deleteApiCall, getApiCall, postApiCall } from '../utils/Api';

export const getAgentNuggets = async (agentId) => {
  const result = await getApiCall(`/agent/${agentId}/nuggets`);
  return result.data;
};

export const createNugget = async (data) => {
  const result = await postApiCall(`/nugget/create`, data);
  return result.data;
};

export const updateNuggetUrl = async (data) => {
  const result = await postApiCall(`/nugget/update-url`, data);
  return result.data;
};

export const updateNuggetStatus = async (data) => {
  const result = await postApiCall(`/nugget/${data.nuggetId}/update-status`, data);
  return result.data;
};

export const getNuggetById = async (nuggetId) => {
  const result = await getApiCall(`/nugget/${nuggetId}/view`);
  return result.data;
};

export const deleteNuggetById = async (nuggetId) => {
  const result = await deleteApiCall(`/nugget/${nuggetId}/remove`);
  return result.data;
};
