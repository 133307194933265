import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Container,
  Paper,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Controller, useForm } from 'react-hook-form';
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { format } from 'date-fns';
import Validations from '../../utils/Validations';
import SnackbarMessage from '../SnackbarMessage';
import { createContratSubscription, updataContractStatus } from '../../services/Contract';
import {
  contractStatusValue,
  paymentFrequencies,
  countries,
  USState,
} from '../../config/DataValues';
import '../../assets/style/stripe.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY);

const ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#424770',
      '::placeholder': {
        color: 'rgba(0,0,0,0.5)',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const InjectedPaymentForm = ({
  token,
  finalAmount,
  subscriptionType,
  refershPage,
  subscriptionStartDate,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [processing, setProcessing] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('US');
  const [selectedState, setSelectedState] = useState('Alabama');

  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const { handleSubmit, control } = useForm();

  // eslint-disable-next-line no-unused-vars
  const handlePayment = async (data) => {
    setProcessing(true);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });

    try {
      if (!stripe || !elements) {
        return;
      }

      const card = elements.getElement(CardNumberElement);
      const addBilling = process.env.REACT_APP_STRIPE_ADD_BILLING_INFO;
      const cardPayload = {
        type: 'card',
        card,
        billing_details: {
          name: data.name,
        },
      };
      if (addBilling === 'true') {
        cardPayload.billing_details.address = {
          city: data.city,
          country: selectedCountry,
          line1: data.street,
          line2: data.street2 || '',
          postal_code: data.zipCode,
          state: selectedCountry === 'US' ? selectedState : data.stateProvince,
        };
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod(cardPayload);

      if (error) {
        throw new Error(error.message);
      }

      const res = await createContratSubscription({
        paymentMethodId: paymentMethod.id,
        token,
      });

      if (res.success) {
        const { clientSecret, paymentMethodId } = res.data;
        let paymentSuccess = false;

        if (clientSecret) {
          const paymentResult = await stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethodId,
          });

          if (paymentResult.error) {
            throw new Error(paymentResult.error.message);
          }

          if (paymentResult.paymentIntent.status === 'succeeded') {
            paymentSuccess = true;
          }
        } else {
          paymentSuccess = true;
        }

        if (paymentSuccess) {
          // Show a success message to agent.
          await updataContractStatus({ token, status: contractStatusValue.PAID });
          setSnackbarMeesage(() => ({
            ...snackbarMeesage,
            message:
              'Payment completed successfully. Do not close the window, you will be redirected to the final step.',
            type: 'success',
            show: true,
          }));
          setProcessing(false);
          setTimeout(() => {
            refershPage();
          }, 2500);
        }
      } else {
        throw new Error('Error creating new subscription.');
      }
    } catch (error) {
      setSnackbarMeesage({
        ...snackbarMeesage,
        message: `${error.message}, please wait retrying...`,
        type: 'error',
        show: true,
      });
      setProcessing(false);

      setTimeout(() => {
        refershPage();
      }, 3000);
    }
  };

  const handleChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleChangeState = (event) => {
    setSelectedState(event.target.value);
  };

  const calculateNextBillingDate = () => {
    const currentDate = new Date();
    let nextBillingDate;
    if (subscriptionType) {
      if (subscriptionStartDate) {
        const subStartDate = new Date(Number(subscriptionStartDate) * 1000);
        nextBillingDate = subStartDate.setMonth(
          subStartDate.getMonth() + paymentFrequencies[subscriptionType].multiply
        );
      } else {
        nextBillingDate = currentDate.setMonth(
          currentDate.getMonth() + paymentFrequencies[subscriptionType].multiply
        );
      }

      return format(new Date(nextBillingDate), 'MMMM d, yyyy');
    }
    return '';
  };

  const nextBillingDate = calculateNextBillingDate();

  return (
    <Box py={3} width="50%" mx="auto">
      <Container component={Paper}>
        <Box py={3} justifyContent="center" alignItems="center">
          <Typography variant="h5" color="primary" style={{ marginBottom: 15, fontWeight: 'bold' }}>
            Make Your First Payment
          </Typography>
          <Typography variant="h5" style={{ marginBottom: 15, fontSize: 14 }}>
            Pay <strong>{finalAmount && dollarUS.format(finalAmount)}</strong> now, and then your
            next&nbsp;
            <strong>
              {subscriptionType &&
                paymentFrequencies[subscriptionType].label.toString().toLowerCase()}
            </strong>
            &nbsp;payments will not be due until <strong>{nextBillingDate}</strong>.
          </Typography>
          <form onSubmit={handleSubmit(handlePayment)}>
            <Controller
              control={control}
              rules={{
                ...Validations.REQUIRED,
              }}
              name="name"
              id="name"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <input
                    placeholder="Name On Card"
                    value={value}
                    onChange={onChange}
                    className="PaymentElement"
                  />
                  {error && <div className="error-msg">{error.message}</div>}
                </>
              )}
            />

            <FormControl fullWidth style={{ marginBottom: 15, marginTop: 15 }}>
              <CardNumberElement id="cardNumber" options={ELEMENT_OPTIONS} />
            </FormControl>

            <Box display="flex" justifyContent="space-between" style={{ marginBottom: 25 }}>
              <FormControl fullWidth style={{ marginRight: 10 }}>
                <CardExpiryElement id="expiry" options={ELEMENT_OPTIONS} />
              </FormControl>
              <FormControl fullWidth style={{ marginLeft: 10 }}>
                <CardCvcElement id="cvv" options={ELEMENT_OPTIONS} />
              </FormControl>
            </Box>
            <Box style={{ marginTop: 50 }}>
              <h3>Billing Address</h3>
              <FormControl fullWidth style={{ marginBottom: 15, marginTop: 15 }}>
                <InputLabel id="country">Country</InputLabel>
                <Select
                  labelId="country"
                  id="country"
                  value={selectedCountry}
                  onChange={handleChange}
                  label="country"
                >
                  {countries.map((c) => (
                    <MenuItem value={c.code}>{c.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: 15 }}>
                <Controller
                  control={control}
                  rules={Validations.REQUIRED}
                  name="street"
                  id="street"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Street"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: 15, marginTop: 15 }}>
                <Controller
                  control={control}
                  name="street2"
                  id="street2"
                  render={({ field: { onChange, value } }) => (
                    <TextField label="Street (line 2)" value={value} onChange={onChange} />
                  )}
                />
              </FormControl>
              <FormControl fullWidth style={{ marginBottom: 15, marginTop: 15 }}>
                <Controller
                  control={control}
                  rules={Validations.REQUIRED}
                  name="city"
                  id="city"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="City"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </FormControl>
              {selectedCountry && selectedCountry === 'US' ? (
                <FormControl fullWidth style={{ marginBottom: 15, marginTop: 15 }}>
                  <InputLabel id="state">State</InputLabel>
                  <Select
                    labelId="state"
                    id="state"
                    value={selectedState}
                    onChange={handleChangeState}
                    label="State"
                  >
                    {USState.map((c) => (
                      <MenuItem value={c.name}>{c.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <FormControl fullWidth style={{ marginBottom: 15, marginTop: 15 }}>
                  <Controller
                    control={control}
                    rules={Validations.REQUIRED}
                    name="stateProvince"
                    id="stateProvince"
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label="State/Province"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </FormControl>
              )}
              <FormControl fullWidth style={{ marginBottom: 15, marginTop: 15 }}>
                <Controller
                  control={control}
                  rules={Validations.REQUIRED}
                  name="zipCode"
                  id="zipCode"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Zip/Postcode"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disableElevation
                fullWidth
                endIcon={processing && <CircularProgress size={25} />}
                disabled={processing}
              >
                Pay
              </Button>
              {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
            </Box>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

InjectedPaymentForm.propTypes = {
  finalAmount: PropTypes.number.isRequired,
  subscriptionType: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
  refershPage: PropTypes.func.isRequired,
  subscriptionStartDate: PropTypes.number.isRequired,
};

const Payment = (props) => (
  <Elements stripe={stripePromise}>
    <InjectedPaymentForm {...props} />
  </Elements>
);
export default Payment;
