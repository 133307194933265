import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Delete, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import agentStyle from '../../theme/styles/Agents';
import { fetchAmendmentZones, fetchPurchasedZone, removeAssignedZone } from '../../services/Agent';
import SnackbarMessage from '../SnackbarMessage';
import { viewZoneById } from '../../services/Zones';
import UserTypes from '../../config/UserTypes';

const useStyles = makeStyles(agentStyle);

const AssignZoneSection = ({
  agentId,
  fromAddAgent,
  setTempAssignedZoneData,
  tempAssignedZoneData,
  zoneArr,
  updateList,
  editable,
  doHaveDraftAmendment,
}) => {
  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;
  const IS_SUPPORT_LOGIN = userType === UserTypes.SUPPORT.value;

  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [amendmentZones, setAmendmentZones] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [confirmModel, setConfirmModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [processing, setProcessing] = useState(false);

  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const exclusitivityArr = {
    1: 'Exclusive',
    2: 'Semi Exclusive',
  };

  const statusArr = {
    1: 'Paid',
    2: 'Hold',
  };

  const columns = [
    {
      field: 'purchasedZone',
      headerName: 'Purchased Zone',
      width: 300,
      valueFormatter: (params) => zoneArr[params.value],
    },
    {
      field: 'exclusivity',
      headerName: 'Exclusivity',
      width: 200,
      valueFormatter: (params) => exclusitivityArr[params.value],
    },
    {
      field: 'paymentStatus',
      headerName: 'Status',
      width: 120,
      valueFormatter: (params) => statusArr[params.value],
    },
  ];

  if (!IS_SUPPORT_LOGIN) {
    columns.push({
      field: 'nrpFee',
      headerName: 'NRP Fee',
      width: 140,
      align: 'right',
      valueFormatter: (params) => dollarUS.format(params.value),
    });
  }
  useEffect(() => {
    if (!fromAddAgent) {
      fetchPurchasedZone(agentId).then((res) => {
        const arr = res.data.map((item) => ({
          id: item.id,
          purchasedZone: item.zoneId,
          exclusivity: item.purchaseType === 'sold' ? 1 : 2,
          paymentStatus: item.paymentStatus === 'hold' ? 2 : 1,
          nrpFee: item.price,
          status: item.status,
        }));
        setRows(arr);
        setDataLoaded(true);
      });
    } else {
      setRows(tempAssignedZoneData);
      setDataLoaded(true);
    }

    if (doHaveDraftAmendment) {
      setDataLoaded(false);
      fetchAmendmentZones(agentId).then((res) => {
        const arr = res.data.map((item) => ({
          id: item.id,
          purchasedZone: item.zoneId,
          exclusivity: item.purchaseType === 'sold' ? 1 : 2,
          paymentStatus: item.paymentStatus === 'hold' ? 2 : 1,
          nrpFee: item.price,
          status: item.status,
        }));
        setAmendmentZones(arr);
        setDataLoaded(true);
      });
    }
  }, [reloadData, updateList]);

  const deleteRecord = (id) => {
    setDeleteId(id);
    setConfirmModel(true);
  };

  const deleteAssignedZone = () => {
    setProcessing(true);
    if (!fromAddAgent) {
      removeAssignedZone(deleteId)
        .then(() => {
          setProcessing(false);
          setConfirmModel(false);
          setReloadData(!reloadData);
        })
        .catch(({ response }) => {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.data.message,
            type: 'error',
            show: 'error',
          });
          setProcessing(false);
        });
    } else {
      const updated = [...rows];
      const oldData = updated.filter((r) => r.id === deleteId);
      if (oldData.length > 0) {
        const index = updated.indexOf(oldData[0]);
        if (index !== -1) {
          updated.splice(index, 1);
          setTempAssignedZoneData(updated);
          setProcessing(false);
          setConfirmModel(false);
          setReloadData(!reloadData);
        } else {
          setProcessing(false);
          setConfirmModel(false);
          setReloadData(!reloadData);
        }
      } else {
        setProcessing(false);
        setConfirmModel(false);
        setReloadData(!reloadData);
      }
    }
  };

  return (
    <div>
      <TableContainer component={Paper}>
        {dataLoaded && (
          <div className={classes.dataGridContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" />
                  {columns.map((col) => (
                    <TableCell width={col.width}>{col.headerName}</TableCell>
                  ))}
                  <TableCell width={120}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length === 0 && (
                  <TableCell colSpan="6" align="center">
                    No record found.
                  </TableCell>
                )}
                {rows.map((row) => (
                  <ExpandableTableRow key={row.id} zoneId={row.purchasedZone}>
                    {columns.map((col) => (
                      <TableCell align={col.align}>
                        {col.valueFormatter
                          ? col.valueFormatter({ value: row[col.field] })
                          : row[col.field]}
                      </TableCell>
                    ))}
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => deleteRecord(row.id)}
                        disabled={IS_SUPPORT_LOGIN ? true : !editable}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </ExpandableTableRow>
                ))}
              </TableBody>
            </Table>

            {doHaveDraftAmendment && (
              <div>
                <Typography variant="h6" className={classes.amendmenetZoneTitle}>
                  Amendment Zones
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox" />
                      {columns.map((col) => (
                        <TableCell width={col.width}>{col.headerName}</TableCell>
                      ))}
                      <TableCell width={120}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.length === 0 && (
                      <TableCell colSpan="6" align="center">
                        No record found.
                      </TableCell>
                    )}
                    {amendmentZones.map((row) => (
                      <ExpandableTableRow key={row.id} zoneId={row.purchasedZone}>
                        {columns.map((col) => (
                          <TableCell align={col.align}>
                            {col.valueFormatter
                              ? col.valueFormatter({ value: row[col.field] })
                              : row[col.field]}
                          </TableCell>
                        ))}
                        <TableCell>
                          <IconButton
                            size="small"
                            onClick={() => deleteRecord(row.id)}
                            disabled={IS_SUPPORT_LOGIN ? true : !editable}
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </ExpandableTableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </div>
        )}
      </TableContainer>
      {confirmModel && (
        <Dialog open onClose={() => setConfirmModel(false)}>
          <DialogTitle id="alert-dialog-title">Delete Zone</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to delete this record? This action can not be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={deleteAssignedZone}
              color="secondary"
              autoFocus
              endIcon={processing && <CircularProgress size={25} />}
              disabled={processing}
            >
              Delete
            </Button>
            <Button onClick={() => setConfirmModel(false)} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </div>
  );
};

const ExpandableTableRow = ({ children, zoneId, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [zipCodes, setZipcodes] = useState(null);

  useEffect(() => {
    if (!zipCodes) {
      viewZoneById(zoneId)
        .then((res) => {
          setZipcodes(res.data.zoneZipCodes);
        })
        .catch(() => setZipcodes(''));
    }
  }, [isExpanded]);

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell padding="checkbox" />
          <TableCell colSpan="5">{zipCodes}</TableCell>
        </TableRow>
      )}
    </>
  );
};

ExpandableTableRow.propTypes = {
  children: PropTypes.element.isRequired,
  zoneId: PropTypes.number.isRequired,
};

AssignZoneSection.propTypes = {
  agentId: PropTypes.number.isRequired,
  fromAddAgent: PropTypes.bool,
  setIsOpenAssignZone: PropTypes.func,
  setTempAssignedZoneData: PropTypes.func,
  tempAssignedZoneData: PropTypes.arrayOf(),

  zoneArr: PropTypes.arrayOf().isRequired,
  updateList: PropTypes.bool.isRequired,
  editable: PropTypes.bool,
  doHaveDraftAmendment: PropTypes.bool,
};
AssignZoneSection.defaultProps = {
  fromAddAgent: false,
  setIsOpenAssignZone: () => true,
  setTempAssignedZoneData: () => true,
  tempAssignedZoneData: [],
  editable: true,
  doHaveDraftAmendment: false,
};

export default AssignZoneSection;
