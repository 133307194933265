/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableCell,
  TableRow,
} from '@material-ui/core';
import VideoPlayer from 'react-video-js-player';
import { viewById } from '../../services/Resource';
import { resourceCategories } from '../../config/DataValues';

const ViewDialog = ({ id, onClose }) => {
  const [resourceData, setResourceData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    setDataLoaded(false);
    viewById(id).then((res) => {
      setResourceData(res.data);
      setDataLoaded(true);
    });
  }, []);

  return (
    <Dialog open fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle>Resource Details</DialogTitle>
      <DialogContent>
        {dataLoaded && (
          <Table>
            <TableRow>
              <TableCell width="20%">
                <b>Category</b>
              </TableCell>
              <TableCell width="80%">{resourceData.category}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ verticalAlign: 'top' }}>
                <b>Short Title</b>
              </TableCell>
              <TableCell>{resourceData.shortTitle}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ verticalAlign: 'top' }}>
                <b>Full Title</b>
              </TableCell>
              <TableCell>{resourceData.title}</TableCell>
            </TableRow>
            {(resourceCategories.indexOf(resourceData.category) === 3 ||
              resourceCategories.indexOf(resourceData.category) === 1) && (
              <TableRow>
                <TableCell style={{ verticalAlign: 'top' }}>
                  <b>Description</b>
                </TableCell>
                <TableCell>{resourceData.description || ''}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                <b>Display Date</b>
              </TableCell>
              <TableCell>{resourceData.dateDisplay}</TableCell>
            </TableRow>
            {resourceData.type === 0 && (
              <TableRow>
                <TableCell style={{ verticalAlign: 'top' }}>
                  <b>Image</b>
                </TableCell>
                <TableCell>
                  {resourceData.fileUrl && (
                    <img src={resourceData.fileUrl} alt="" width={300} height={200} />
                  )}
                </TableCell>
              </TableRow>
            )}

            {resourceData.type === 1 && (
              <TableRow>
                <TableCell style={{ verticalAlign: 'top' }}>
                  <b>Video</b>
                </TableCell>
                <TableCell>
                  <VideoPlayer controls src={resourceData.fileUrl} width="300" />
                </TableCell>
              </TableRow>
            )}
            {(resourceCategories.indexOf(resourceData.category) === 3 ||
              resourceCategories.indexOf(resourceData.category) === 1) &&
              resourceData.type === 1 && (
                <TableRow>
                  <TableCell style={{ verticalAlign: 'top' }}>
                    <b>Thumbnail</b>
                  </TableCell>
                  <TableCell>
                    {resourceData.videoThumb && (
                      <img src={resourceData.videoThumb} alt="" width={300} height={200} />
                    )}
                  </TableCell>
                </TableRow>
              )}
            {(resourceCategories.indexOf(resourceData.category) === 3 ||
              resourceCategories.indexOf(resourceData.category) === 1) && (
              <TableRow>
                <TableCell style={{ verticalAlign: 'top' }}>
                  <b>Supplementary Documents</b>
                </TableCell>
                <TableCell>
                  {resourceData.resourceDocuments &&
                    resourceData.resourceDocuments.map((d) => (
                      <>
                        <a href={d.url} target="_blank" rel="noreferrer">
                          {d.fileName}
                        </a>
                        <br />
                      </>
                    ))}
                </TableCell>
              </TableRow>
            )}
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button disableElevation color="default" variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ViewDialog.propTypes = {
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ViewDialog;
