// Style for information display
const Style = () => ({
  infoContainer: {
    height: '80%',
    display: 'flex',
  },
  infoPageText: {
    marginTop: 15,
    marginBottom: 15,
  },
});

export default Style;
