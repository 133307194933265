/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  CardActionArea,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { Controller, useForm } from 'react-hook-form';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { addNotification, updateNotification } from '../../services/Notification';
import Validations, { MaxLengthValidationFunc } from '../../utils/Validations';
import {
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  // Icon7,
  // Icon8,
  // Icon9,
  // Icon10,
  // Icon11,
  // Icon12,
  renderDashboardIcon,
} from '../DashboardIcons';
import DashboardForm from '../../theme/styles/DashboardForm';
import SnackbarMessage from '../SnackbarMessage';

const useStyles = makeStyles(DashboardForm);

const AddNewNotification = ({ editData, editNotification, setRefreshFlag, deleteId }) => {
  const classes = useStyles();
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [iconSelected, setIconSelected] = useState('');
  const [iconError, setIconError] = useState('');
  const [dateError, setDateError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [isOpenIconsDialog, setIsOpenIconsDialog] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const { handleSubmit, control, setValue, reset, getValues } = useForm();
  const resetForm = () => {
    reset();
    editNotification({});
  };
  useEffect(() => {
    reset();
    setSelectedIcon('');
    if (editData && editData.id) {
      setValue('nrptype', editData.forAgentType);
      setValue('startDate', editData.startDate);
      setValue('expiryDate', editData.expiryDate);
      setValue('title', editData.title);
      setValue('description', editData.description);
      setSelectedIcon(editData.icon);
    }

    if (deleteId === editData.id) {
      resetForm();
    }
  }, [editData, deleteId]);

  const submitForm = async (data) => {
    setIconError('');

    let dateErrorCompare = '';
    setDateError('');
    if (data.startDate > data.expiryDate) {
      dateErrorCompare = "Expiration date can't be less then start date.";
      setDateError(dateErrorCompare);
    }

    let descriptionWordsError = '';
    setDescriptionError('');
    if (data.description.split(' ').length > 100) {
      descriptionWordsError = 'Maximum 100 words allowed for description';
      setDescriptionError(descriptionWordsError);
    }

    setSnackbarMeesage({
      show: false,
      type: '',
      message: '',
    });

    setProcessing(true);
    if (editData.id && dateErrorCompare === '' && descriptionWordsError === '') {
      const payload = {
        title: data.title,
        description: data.description,
        icon: selectedIcon,
        startDate: data.startDate,
        expiryDate: data.expiryDate,
        forAgentType: data.nrptype || editData.forAgentType,
        status: editData.status,
      };
      updateNotification(editData.id, payload)
        .then((response) => {
          if (response.success) {
            resetForm();
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: 'Notification has been updated successfully.',
              type: 'success',
              show: true,
            });
            setRefreshFlag(true);
            setProcessing(false);
          }
        })
        .catch(({ response }) => {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.data.message,
            type: 'error',
            show: true,
          });
          setProcessing(false);
        });
    } else if (dateErrorCompare === '' && descriptionWordsError === '') {
      const payload = {
        title: data.title,
        description: data.description,
        icon: selectedIcon,
        startDate: data.startDate,
        expiryDate: data.expiryDate,
        forAgentType: data.nrptype || 0,
        status: 1,
      };
      if (selectedIcon) {
        addNotification(payload)
          .then((res) => {
            if (res && res.success) {
              setProcessing(false);
              setSelectedIcon(null);
              resetForm();
              setSnackbarMeesage({
                ...snackbarMeesage,
                message: 'Notification has been created successfully.',
                type: 'success',
                show: true,
              });
              setRefreshFlag(true);
            } else {
              setSnackbarMeesage({
                ...snackbarMeesage,
                message: 'Something went wrong. Please try again.',
                type: 'error',
                show: true,
              });
              setProcessing(false);
            }
          })
          .catch(({ response }) => {
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: response.data.message,
              type: 'error',
              show: true,
            });
            setProcessing(false);
          });
      } else {
        setIconError('Please select notification Icon.');
      }
    }
    // Get some others value from state variable
  };

  const saveIcon = () => {
    setIsOpenIconsDialog(false);
    setIconError('');
    setSelectedIcon(iconSelected);
  };

  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h5">
            {editData.id ? 'Update Notification' : 'Create Notifications'}
          </Typography>
          <Box mt={3}>
            <hr />
          </Box>
          <Box mt={4}>
            <form id="add-notification" onSubmit={handleSubmit(submitForm)}>
              <Grid container spacing={4}>
                <Grid item xs={4} sm={4}>
                  <Box
                    ml={4}
                    mt={-2}
                    display="flex"
                    justifyContent="center"
                    style={{
                      border: '2px',
                      borderStyle: 'dashed',
                      borderColor: iconError ? '#f44336' : '#41a14d2b',
                      backgroundColor: '#41a14d0d',
                      height: '160px',
                      cursor: 'pointer',
                    }}
                    onClick={() => setIsOpenIconsDialog(true)}
                  >
                    <IconButton
                      style={{ backgroundColor: '#fff', height: '90px', alignSelf: 'center' }}
                    >
                      {renderDashboardIcon(selectedIcon)}
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <Typography variant="h5">Select Icon</Typography>
                  <Box mt={-1}>
                    {/* <Typography variant="body2">
                      <br />
                      Lorem ipsum dolor sit amet,
                      <br />
                      consectetur adipiscing.Lorem ipsum
                      <br />
                      dolor sit amet, consectetur adipiscing.
                    </Typography> */}
                  </Box>
                </Grid>
                {iconError && (
                  <Box ml={6} display="flex" justifyContent="center">
                    <p
                      style={{ color: '#f44336' }}
                      className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
                    >
                      {iconError}
                    </p>
                  </Box>
                )}
              </Grid>
              <Box mt={4} sx={{ p: 3 }}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography>NRP Type</Typography>
                    <Box mt={1}>
                      <Controller
                        control={control}
                        name="nrptype"
                        id="nrptype"
                        defaultValue={0}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            variant="outlined"
                            label=""
                            placeholder="Select"
                            value={value || 0}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                            SelectProps={{
                              native: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            select
                          >
                            <option key={0} value={0}>
                              Both
                            </option>
                            <option key={1} value={1}>
                              Agent
                            </option>
                            <option key={2} value={2}>
                              Lender
                            </option>
                          </TextField>
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={-2} sx={{ p: 3 }}>
                <Grid container rowSpacing={3}>
                  <Grid item xs={5}>
                    <Typography>Start Date</Typography>
                    <Box mt={1}>
                      <Controller
                        control={control}
                        name="startDate"
                        id="startDate"
                        rules={{ ...Validations.REQUIRED }}
                        defaultValue={null}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              inputVariant="outlined"
                              format="MM/dd/yyyy"
                              value={value}
                              autoOk
                              clearable
                              onChange={onChange}
                              helperText={error ? error.message : null}
                              error={!!error}
                            />
                          </MuiPickersUtilsProvider>
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box mt={4}>
                      <Typography align="center" variant="body2" style={{ fontSize: '2rem' }}>
                        -
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>Expiration</Typography>
                    <Box mt={1}>
                      <Controller
                        control={control}
                        name="expiryDate"
                        id="expiryDate"
                        rules={{ ...Validations.REQUIRED }}
                        defaultValue={null}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              inputVariant="outlined"
                              format="MM/dd/yyyy"
                              value={value}
                              autoOk
                              clearable
                              onChange={onChange}
                              helperText={error ? error.message : null}
                              error={!!error}
                            />
                          </MuiPickersUtilsProvider>
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {dateError && (
                <Box ml={6} display="flex" justifyContent="center">
                  <p
                    style={{ color: '#f44336' }}
                    className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
                  >
                    {dateError}
                  </p>
                </Box>
              )}
              <Box mt={-2} sx={{ p: 3 }}>
                <Typography>Notification Title</Typography>
                <Box mt={1}>
                  <Controller
                    control={control}
                    name="title"
                    rules={{ ...Validations.REQUIRED, ...MaxLengthValidationFunc(50) }}
                    id="title"
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        variant="outlined"
                        label=""
                        placeholder="Type Here"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        fullWidth
                        autoFocus={editData.id || false}
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box mt={-2} sx={{ p: 3 }}>
                <Typography>Notification Description</Typography>
                <Box mt={1}>
                  <Controller
                    control={control}
                    rules={{ ...Validations.REQUIRED, ...MaxLengthValidationFunc(800) }}
                    name="description"
                    id="description"
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        variant="outlined"
                        label=""
                        placeholder="Maximum 100 words"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        fullWidth
                        multiline
                        rows={4}
                      />
                    )}
                  />
                </Box>
              </Box>
              {descriptionError && (
                <Box ml={6} display="flex" justifyContent="center">
                  <p
                    style={{ color: '#f44336' }}
                    className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
                  >
                    {descriptionError}
                  </p>
                </Box>
              )}
              <Box mt={-2} sx={{ p: 3 }}>
                <Box mt={1}>
                  <Grid container spacing={editData.id ? 2 : 1}>
                    {editData.id && (
                      <Grid item xs={editData.id ? 6 : 12}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disableElevation
                          fullWidth
                          style={{ height: '50px', borderRadius: '40px' }}
                          onClick={() => resetForm()}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    )}
                    <Grid item xs={editData.id ? 6 : 12}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        form="add-notification"
                        disableElevation
                        endIcon={processing}
                        fullWidth
                        style={{ height: '50px', borderRadius: '40px' }}
                      >
                        {editData.id ? 'Update' : 'Save'}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </form>
          </Box>
        </CardContent>
      </Card>
      {isOpenIconsDialog && (
        <Dialog open maxWidth="sm" onClose={() => setIsOpenIconsDialog(false)}>
          <Box display="flex" justifyContent="end">
            <IconButton onClick={() => setIsOpenIconsDialog(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box mt={-5} display="flex" justifyContent="center">
            <DialogTitle id="alert-dialog-title">Select Icon</DialogTitle>
          </Box>

          <DialogContent>
            <Box>
              <hr />
            </Box>
            <DialogContentText id="alert-dialog-description">
              {/* <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box>
                    <Controller
                      control={control}
                      name="searchicon"
                      id="searchicon"
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          variant="outlined"
                          placeholder="Search..."
                          value={value}
                          size="small"
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                          fullWidth
                          style={{ borderRadius: '50px 50px 0 0' }}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid> */}
              <Box sx={{ p: 2 }}>
                <Box mt={5}>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={4}
                      className={iconSelected === 'Icon1' ? classes.notificationIcon : ''}
                    >
                      <CardActionArea onClick={() => setIconSelected('Icon1')}>
                        <Box sx={{ p: 2 }}>
                          <Icon1 />
                        </Box>
                      </CardActionArea>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      className={iconSelected === 'Icon2' ? classes.notificationIcon : ''}
                    >
                      <CardActionArea onClick={() => setIconSelected('Icon2')}>
                        <Box sx={{ p: 2 }}>
                          <Icon2 />
                        </Box>
                      </CardActionArea>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      className={iconSelected === 'Icon3' ? classes.notificationIcon : ''}
                    >
                      <CardActionArea onClick={() => setIconSelected('Icon3')}>
                        <Box sx={{ p: 2 }}>
                          <Icon3 />
                        </Box>
                      </CardActionArea>
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={5}>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={4}
                      className={iconSelected === 'Icon4' ? classes.notificationIcon : ''}
                    >
                      <CardActionArea onClick={() => setIconSelected('Icon4')}>
                        <Box sx={{ p: 2 }}>
                          <Icon4 />
                        </Box>
                      </CardActionArea>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      className={iconSelected === 'Icon5' ? classes.notificationIcon : ''}
                    >
                      <CardActionArea onClick={() => setIconSelected('Icon5')}>
                        <Box sx={{ p: 2 }}>
                          <Icon5 />
                        </Box>
                      </CardActionArea>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      className={iconSelected === 'Icon6' ? classes.notificationIcon : ''}
                    >
                      <CardActionArea onClick={() => setIconSelected('Icon6')}>
                        <Box sx={{ p: 2 }}>
                          <Icon6 />
                        </Box>
                      </CardActionArea>
                    </Grid>
                  </Grid>
                </Box>
                {/* <Box mt={5}>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={4}
                      className={iconSelected === 'Icon7' ? classes.notificationIcon : ''}
                    >
                      <CardActionArea onClick={() => setIconSelected('Icon7')}>
                        <Box sx={{ p: 2 }}>
                          <Icon7 />
                        </Box>
                      </CardActionArea>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      className={iconSelected === 'Icon8' ? classes.notificationIcon : ''}
                    >
                      <CardActionArea onClick={() => setIconSelected('Icon8')}>
                        <Box sx={{ p: 2 }}>
                          <Icon8 />
                        </Box>
                      </CardActionArea>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      className={iconSelected === 'Icon9' ? classes.notificationIcon : ''}
                    >
                      <CardActionArea onClick={() => setIconSelected('Icon9')}>
                        <Box sx={{ p: 2 }}>
                          <Icon9 />
                        </Box>
                      </CardActionArea>
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={5}>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={4}
                      className={iconSelected === 'Icon10' ? classes.notificationIcon : ''}
                    >
                      <CardActionArea onClick={() => setIconSelected('Icon10')}>
                        <Box sx={{ p: 2 }}>
                          <Icon10 />
                        </Box>
                      </CardActionArea>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      className={iconSelected === 'Icon11' ? classes.notificationIcon : ''}
                    >
                      <CardActionArea onClick={() => setIconSelected('Icon11')}>
                        <Box sx={{ p: 2 }}>
                          <Icon11 />
                        </Box>
                      </CardActionArea>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      className={iconSelected === 'Icon12' ? classes.notificationIcon : ''}
                    >
                      <CardActionArea onClick={() => setIconSelected('Icon12')}>
                        <Box sx={{ p: 2 }}>
                          <Icon12 />
                        </Box>
                      </CardActionArea>
                    </Grid>
                  </Grid>
                </Box> */}
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              style={{ height: '50px', borderRadius: '40px' }}
              onClick={() => saveIcon()}
            >
              Select
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </>
  );
};

AddNewNotification.propTypes = {
  editData: PropTypes.object.isRequired,
  editNotification: PropTypes.func.isRequired,
  setRefreshFlag: PropTypes.func.isRequired,
  deleteId: PropTypes.number.isRequired,
};

export default AddNewNotification;
