import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';

const RealtorWordWarning = ({ closeModel }) => {
  const handleContinue = () => {
    closeModel(true);
  };

  const handleBackToEdit = () => {
    closeModel(false);
  };

  return (
    <Dialog open onClose={handleContinue}>
      <DialogTitle id="alert-dialog-title">A Note From Our Legal Team:</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          &quot;Realtor&quot; is a branded word. We recommend using &quot;Negotiators&quot; instead,
          but it&apos;s up to you, of course. Feel free to make the necessary edits.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleContinue} color="primary">
          Continue With Realtor
        </Button>
        <Button onClick={handleBackToEdit} color="secondary">
          Back To Edit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RealtorWordWarning.propTypes = {
  closeModel: PropTypes.func.isRequired,
};

export default RealtorWordWarning;
