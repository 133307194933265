import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import infoStyle from '../theme/styles/Info';

const useStyles = makeStyles(infoStyle);

// Coming soon message
const ComingSoon = () => {
  const classes = useStyles();

  return (
    <div className={classes.infoContainer}>
      <Grid container spacing={0} align="center" justify="center" direction="column">
        <Container maxWidth="xs">
          <Container component="div" className={classes.infoPageText}>
            <Typography component="h1" variant="h4">
              Coming Soon!
            </Typography>
            <Typography component="p">This page will be active soon.</Typography>
          </Container>
        </Container>
      </Grid>
    </div>
  );
};

export default ComingSoon;
