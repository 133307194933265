/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Tooltip,
  Paper,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import VideoPlayer from 'react-video-js-player';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import AddIcon from '@material-ui/icons/Add';
import { Refresh, MoreVert } from '@material-ui/icons';
import nuggetsStyle from '../../theme/styles/Nuggets';
import AddAgentAdVideo from './AddAgentAdVideo';
import ActionConfirmDialog from './ActionConfirmDialog';
import { getAgentAdVideos } from '../../services/AgentAdVideos';
import { agentAdVideoStatus, agentAdVideoAssetStatus } from '../../config/DataValues';
import { trackUserClick } from '../../utils/Activity';

const useStyles = makeStyles(nuggetsStyle);
const TooltipStyle = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgb(97 97 97 / 98%)',
    fontSize: '.8rem',
  },
}))(Tooltip);

const AgentAdVideos = () => {
  const classes = useStyles();
  const getDetails = window.localStorage.getItem('userDetail');
  const userId = getDetails ? Number(JSON.parse(getDetails).id) : 0;

  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [reloadRows, setReloadRows] = useState(false);
  const [allowVideos, setAllowVideos] = useState(true);

  const [viewVideoUrl, setViewVideoUrl] = useState('');

  const [openModel, setOpenModel] = useState(false);
  const [confirmModel, setConfirmModel] = useState(false);

  const [selectedVideo, setSelectedVideo] = useState(null);

  const deleteData = (id) => {
    setSelectedVideo(id);
    setConfirmModel(true);
  };

  useEffect(() => {
    setDataLoaded(false);
    getAgentAdVideos(userId).then((res) => {
      if ('notAllowed' in res.data) {
        setAllowVideos(false);
      } else {
        setRows(res.data);
      }

      setDataLoaded(true);
    });
  }, [reloadRows]);

  const refreshItems = () => {
    setReloadRows(!reloadRows);
  };

  const getToolTipTitle = (row) => {
    let title = '';
    if (row.status === agentAdVideoStatus.PENDING) {
      if (Number(row.assetStatus) === agentAdVideoAssetStatus.WAITING) {
        title = 'The video will soon be edited with required details.';
      } else if (Number(row.assetStatus) === agentAdVideoAssetStatus.PREPARING) {
        title = 'The video editing is in progress. It takes a few minutes to complete the process.';
      } else if (Number(row.assetStatus) === agentAdVideoAssetStatus.READY) {
        title = 'The video is ready for review. The support team will start reviewing it soon.';
      } else if (Number(row.assetStatus) === agentAdVideoAssetStatus.FAILED) {
        title = 'The video editing failed. Please delete the video and re-upload it.';
      }
    } else if (row.status === agentAdVideoStatus.APPROVED) {
      if (Number(row.assetStatus) === agentAdVideoAssetStatus.MUX_WAITING) {
        title = 'The video is being finalised.';
      } else if (Number(row.assetStatus) === agentAdVideoAssetStatus.MUX_UPLOADING) {
        title = 'The video is being finalised.';
      } else if (Number(row.assetStatus) === agentAdVideoAssetStatus.MUX_FAIL) {
        title = 'Something went wrong finalising the video. Please contact support team.';
      }
    } else if (row.status === agentAdVideoStatus.DISAPPROVED) {
      title = row.comments;
    }
    return title;
  };

  const downloadVideo = (vidUrl) => {
    const fileName = Math.floor(Date.now() / 1000);
    const mp4Url = vidUrl.replace('.m3u8', `/high.mp4?download=${fileName}`);
    trackUserClick(83, { video_url: mp4Url });
    window.open(mp4Url, '_blank');
  };

  const handleActions = (row, action) => {
    if (action === 'delete') {
      deleteData(row.id);
    } else if (action === 'download') {
      downloadVideo(row.videoUrl);
    }
  };

  return (
    <>
      {!dataLoaded && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {dataLoaded && (
        <Box display="flex" justifyContent="flex-end">
          <Tooltip title="Refresh">
            <IconButton onClick={refreshItems} size="small">
              <Refresh />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {dataLoaded && rows.length > 0 && (
        <Grid
          component={Box}
          display="flex"
          justifyContent="flex-start"
          flexDirection="row"
          flexWrap="wrap"
        >
          {rows.map((row, i) => (
            <Grid component={Box} width={250} mr={2} mb={2}>
              <Card className={`${classes.card} ${classes.negcard}`}>
                <Box component={Paper} m={2} position="absolute" className={classes.cardItemCount}>
                  {i + 1}
                </Box>
                <ActionMenu id={row.id} selectAction={handleActions} row={row} />

                <Box
                  className={classes.playIconContainer}
                  onClick={() => {
                    setViewVideoUrl(row.videoUrl);
                  }}
                >
                  <VideoPlayer
                    src={row.videoUrl}
                    width="250"
                    controls={false}
                    preload="metadata"
                    hideControls={[
                      'play',
                      'volume',
                      'seekbar',
                      'timer',
                      'playbackrates',
                      'fullscreen',
                    ]}
                    onReady={(p) => {
                      p.currentTime(4);
                      p.pause();
                    }}
                  />
                  <PlayArrowIcon className={classes.playIcon} />
                </Box>
                <CardContent className={classes.cardContent}>
                  <TooltipStyle title={getToolTipTitle(row)}>
                    <Box display="flex" justifyContent="center" style={{ cursor: 'pointer' }}>
                      {row.status === agentAdVideoStatus.PENDING && (
                        <span color="warning" className={classes.statusPending}>
                          {Number(row.assetStatus) === agentAdVideoAssetStatus.WAITING
                            ? 'Waiting'
                            : ''}
                          {Number(row.assetStatus) === agentAdVideoAssetStatus.PREPARING
                            ? 'Processing'
                            : ''}
                          {Number(row.assetStatus) === agentAdVideoAssetStatus.READY
                            ? 'Pending'
                            : ''}
                          {Number(row.assetStatus) === agentAdVideoAssetStatus.FAILED
                            ? 'Failed'
                            : ''}
                        </span>
                      )}
                      {row.status === agentAdVideoStatus.APPROVED && (
                        <span
                          className={
                            Number(row.assetStatus) === agentAdVideoAssetStatus.MUX_FAIL
                              ? classes.statusInactive
                              : Number(row.assetStatus) === agentAdVideoAssetStatus.MUX_READY
                              ? classes.statusActive
                              : classes.statusPending
                          }
                        >
                          {Number(row.assetStatus) === agentAdVideoAssetStatus.MUX_WAITING
                            ? 'Finalising'
                            : ''}
                          {Number(row.assetStatus) === agentAdVideoAssetStatus.MUX_UPLOADING
                            ? 'Finalising'
                            : ''}
                          {Number(row.assetStatus) === agentAdVideoAssetStatus.MUX_FAIL
                            ? 'Failed'
                            : ''}
                          {Number(row.assetStatus) === agentAdVideoAssetStatus.MUX_READY
                            ? 'Approved'
                            : ''}
                        </span>
                      )}
                      {row.status === agentAdVideoStatus.DISAPPROVED && (
                        <span className={classes.statusInactive}>Disapproved</span>
                      )}
                    </Box>
                  </TooltipStyle>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    className={classes.otherDetails}
                  >
                    <div className={classes.rowDetail}>
                      <span className={classes.dateText}>
                        {row.created ? `Added at ${row.created} ` : ''}
                      </span>

                      {(row.status === agentAdVideoStatus.PENDING ||
                        row.status === agentAdVideoStatus.DISAPPROVED) && (
                        <IconButton
                          aria-label="delete"
                          className={classes.iconBtn}
                          onClick={() => {
                            deleteData(row.id);
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      )}
                    </div>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
          {rows.length < 4 && allowVideos && (
            <Grid component={Box} width={250} height={315} mr={2} mb={2}>
              <Card
                component={Button}
                style={{ height: '100%', width: '100%', color: '#43a047' }}
                borderRadius={10}
                onClick={() => {
                  setOpenModel(true);
                  trackUserClick(64);
                }}
              >
                Add New Video
              </Card>
            </Grid>
          )}
        </Grid>
      )}
      {dataLoaded && allowVideos && rows.length === 0 && (
        <Box
          p={1}
          textAlign="center"
          borderRadius={10}
          my={18}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="p" align="center">
            You haven&apos;t uploaded any video yet.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenModel(true);
              trackUserClick(64);
            }}
            startIcon={<AddIcon />}
            style={{ margin: '20px 0', width: 'max-content' }}
          >
            Add New Video
          </Button>
        </Box>
      )}

      {!allowVideos && (
        <Grid component={Box} mr={2} mb={2}>
          <Typography variant="body2" style={{ marginTop: 20, textAlign: 'center' }}>
            You can begin uploading as soon as your profile is published.
          </Typography>
        </Grid>
      )}

      {openModel && (
        <AddAgentAdVideo
          open={openModel}
          onClose={() => {
            setOpenModel(false);
          }}
          onSuccess={() => {
            setOpenModel(false);
            setReloadRows(!reloadRows);
          }}
        />
      )}
      {confirmModel && (
        <ActionConfirmDialog
          open={confirmModel}
          action="delete"
          adVideoId={selectedVideo}
          handleClose={() => {
            setSelectedVideo(null);
            setConfirmModel(false);
          }}
          onSuccess={() => {
            setConfirmModel(false);
            setSelectedVideo(null);
            setReloadRows(!reloadRows);
          }}
        />
      )}

      {viewVideoUrl && (
        <VideoPreview
          videoSrc={viewVideoUrl}
          handleClose={() => {
            setViewVideoUrl(null);
          }}
        />
      )}
    </>
  );
};

const VideoPreview = ({ handleClose, videoSrc }) => (
  <Dialog open onClose={handleClose} maxWidth="md" fullWidth>
    <DialogTitle>Video Preview</DialogTitle>
    <DialogContent>
      <Box justifyContent="center" display="flex">
        <VideoPlayer controls src={videoSrc || ''} width="800" />
      </Box>
      <Box justifyContent="center" display="flex">
        <div style={{ marginTop: 15, marginBottom: 15 }}>
          Note: Some video preview may not work in browser, however once the video is converted then
          preview will work fine.
        </div>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary" variant="contained">
        close
      </Button>
    </DialogActions>
  </Dialog>
);

VideoPreview.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const ActionMenu = ({ row, selectAction }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (action) => {
    selectAction(row, action);
    handleClose();
  };
  return (
    <>
      <Box component={Paper} m={2} position="absolute" className={classes.cardItemMenu}>
        <IconButton
          aria-controls={`simple-menu${row.id}`}
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          color="primary"
        >
          <MoreVert />
        </IconButton>
      </Box>

      <Menu
        id={`simple-menu${row.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {row.status === agentAdVideoStatus.APPROVED && (
          <MenuItem
            id={`simple-menu${row.id}1`}
            onClick={() => {
              handleMenuClick('download');
            }}
            button
            dense
          >
            Download
          </MenuItem>
        )}
        {(row.status === agentAdVideoStatus.PENDING ||
          row.status === agentAdVideoStatus.DISAPPROVED ||
          row.status === agentAdVideoStatus.APPROVED) && (
          <MenuItem
            id={`simple-menu${row.id}2`}
            onClick={() => {
              handleMenuClick('delete');
            }}
            button
            dense
          >
            Delete
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
ActionMenu.propTypes = {
  row: PropTypes.objectOf().isRequired,
  selectAction: PropTypes.func.isRequired,
};

export default AgentAdVideos;
