import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Radio,
  FormControlLabel,
  RadioGroup,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import VideoPlayer from 'react-video-js-player';
import { CloudUpload } from '@material-ui/icons';
import ReactS3Uploader from 'react-s3-uploader';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import formInput from '../../theme/styles/FormInput';
import Validations, { MaxLengthValidationFunc } from '../../utils/Validations';
import SnackbarMessage from '../SnackbarMessage';
import {
  addNewContent,
  viewById,
  updateContent,
  getResourceSignedUrl,
} from '../../services/Resource';
import { resourceCategories } from '../../config/DataValues';

const useStyles = makeStyles(formInput);

const UploadFile = ({ name, accept, type, buttonText, setSuccessData, value }) => {
  const hiddenFileInput = useRef(null);

  const [selectedUrl, setSelectedUrl] = useState(value);
  const [fileSelected, setFileSelected] = useState(false);
  const [progress, setProgress] = useState(0);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const onProgress = (p) => {
    setProgress(p);
  };
  const onError = (error) => {
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });
    setTimeout(() => {
      setSnackbarMeesage({
        message: error,
        type: 'error',
        show: true,
      });
    }, 250);
  };
  const onFinish = (data) => {
    setProgress(0);
    if (type !== 'document') {
      setSuccessData(data.publicUrl);
      setSelectedUrl(data.publicUrl);
    } else {
      setSuccessData({
        fileName: data.originalFilename || 'Document',
        url: data.publicUrl,
      });
    }
    setFileSelected(false);
  };

  const getSignedUrl = async (file, callback) => {
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });

    if (type === 'image' || type === 'thumb') {
      const acceptedImages = ['image/jpeg', 'image/gif', 'image/png', 'image/jpg'];
      if (!acceptedImages.includes(file.type)) {
        setSnackbarMeesage({
          message: '',
          type: '',
          show: false,
        });
        setTimeout(() => {
          setSnackbarMeesage({
            message: 'Please select image of type jpeg, jpg, png or gif file only.',
            type: 'error',
            show: true,
          });
        }, 250);
        hiddenFileInput.current.value = '';
        return;
      }
    }

    const payload = {
      objectName: file.name,
      contentType: file.type,
      module: type,
    };
    getResourceSignedUrl(payload)
      .then((res) => {
        setFileSelected(true);
        callback(res.data);
      })
      .catch(() => {
        setSnackbarMeesage({
          message: `Something went wrong.`,
          type: 'error',
          show: true,
        });
        hiddenFileInput.current.value = '';
      });
  };

  return (
    <Box mb={type === 'document' ? 2 : 4} style={{ width: '200px' }}>
      <ReactS3Uploader
        getSignedUrl={getSignedUrl}
        accept={accept}
        onProgress={onProgress}
        onError={onError}
        onFinish={onFinish}
        uploadRequestHeaders={{
          'x-amz-acl': 'public-read',
        }}
        contentDisposition="attachment"
        inputRef={hiddenFileInput}
        name={name}
        style={{ width: 0, height: 0, display: 'none' }}
      />

      <div style={{ color: 'grey', fontSize: '12px', marginBottom: '5px', paddingLeft: '5px' }}>
        Upload {buttonText}
      </div>
      <Button
        onClick={handleClick}
        fullWidth
        variant="outlined"
        startIcon={<CloudUpload />}
        style={{ width: 350 }}
      >
        {selectedUrl !== '' && !fileSelected ? `Change ${buttonText}` : ''}
        {selectedUrl === '' && !fileSelected ? `Upload ${buttonText}` : ''}
        {fileSelected && progress < 100 ? `File Uploading (${progress}%)` : ''}
        {fileSelected && progress === 100 ? 'File Uploaded' : ''}
      </Button>

      {selectedUrl && (type === 'image' || type === 'thumb') && (
        <Box mt={1} mb={3}>
          <img
            alt=""
            src={selectedUrl}
            style={{
              maxWidth: '100%',
              maxHeight: '150px',
              display: 'block',
              marginBottom: '3px',
              objectFit: 'contain',
            }}
          />
        </Box>
      )}
      {selectedUrl && type === 'video' && (
        <Box mt={1} mb={3}>
          <VideoPlayer src={selectedUrl} width="240" height="150" />
        </Box>
      )}

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Box>
  );
};
UploadFile.propTypes = {
  name: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  setSuccessData: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

const FormDialog = ({ onClose, editId, onSuccess }) => {
  const classes = useStyles();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [selectedValue, setSelectedValue] = useState('image');
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const [selectedThumb, setSelectedThumb] = useState('');
  const [selectedDoc, setSelectedDoc] = useState([]);
  const [contentError, setContentError] = useState('');

  const { handleSubmit, control, setValue, reset, getValues, watch } = useForm();

  const GreenRadio = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  useEffect(() => {
    if (editId) {
      viewById(editId).then((res) => {
        const { data } = res;
        setSelectedValue(data.type === 0 ? 'image' : 'video');
        if (data.type === 0) {
          setSelectedImageUrl(data.fileUrl);
        } else {
          setSelectedVideoUrl(data.fileUrl);
        }
        setValue('category', resourceCategories.indexOf(data.category));
        setValue('title', data.title);
        setValue('shortTitle', data.shortTitle);
        setValue('status', data.status);
        setValue('displayDate', data.displayDate);
        if (
          resourceCategories.indexOf(data.category) === 3 ||
          resourceCategories.indexOf(data.category) === 1
        ) {
          setValue('description', data.description || '');
          setSelectedThumb(data.videoThumb || '');
          setSelectedDoc(data.resourceDocuments || []);
        }
        setTimeout(() => {
          setDataLoaded(true);
        }, 250);
      });
    } else {
      setDataLoaded(true);
    }
  }, []);

  const submitForm = (data) => {
    if (selectedImageUrl || selectedVideoUrl) {
      setSnackbarMeesage({
        ...snackbarMeesage,
        message: '',
        type: '',
        show: false,
      });
      setProcessing(true);
      const payload = {
        category: Number(data.category) + 1,
        shortTitle: data.shortTitle,
        title: data.title || data.shortTitle,
        displayDate: data.displayDate,
        status: data.status,
        type: selectedValue === 'image' ? 0 : 1,
        fileUrl: selectedValue === 'image' ? selectedImageUrl : selectedVideoUrl,
        description: data.description || '',
        videoThumb: selectedThumb || '',
        resourceDocuments: selectedDoc || [],
      };
      if (editId) {
        updateContent(editId, payload)
          .then(() => {
            setProcessing(false);
            reset();
            onSuccess();
            onClose();
          })
          .catch((res) => {
            setProcessing(false);
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: res.response.data.message,
              type: 'error',
              show: true,
            });
          });
      } else {
        addNewContent(payload)
          .then(() => {
            setProcessing(false);
            reset();
            onSuccess();
            onClose();
          })
          .catch((res) => {
            setProcessing(false);
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: res.response.data.message,
              type: 'error',
              show: true,
            });
          });
      }
    } else {
      setContentError('Please select image or video.');
    }
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleDocDelete = (idx) => {
    const tmp = [];
    selectedDoc.forEach((v, i) => {
      if (i !== idx) {
        tmp.push(v);
      }
    });
    setSelectedDoc(tmp);
  };

  const defaultFormValues = getValues();
  const category = watch('category');

  return (
    <Dialog open fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle>{editId ? `Edit` : `Add New`} Resource</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(submitForm)} id="submission-form">
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="category"
            id="category"
            defaultValue={defaultFormValues.status || 0}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Category"
                value={value}
                onChange={(e) => {
                  onChange(e);
                }}
                error={!!error}
                className={classes.formInput}
                SelectProps={{
                  native: true,
                }}
                fullWidth
                select
              >
                {resourceCategories.map((c, idx) => (
                  <option key={c} value={idx}>
                    {c}
                  </option>
                ))}
              </TextField>
            )}
          />
          <Controller
            control={control}
            rules={{ ...Validations.REQUIRED, ...MaxLengthValidationFunc(65) }}
            name="shortTitle"
            id="shortTitle"
            defaultValue={defaultFormValues.title || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Short Title*"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
                autoFocus
              />
            )}
          />
          <div
            style={{ color: 'grey', fontSize: '12px', marginBottom: '25px', marginTop: '-12px' }}
          >
            This title will be shown in the gallery view. Character limit: 65
          </div>

          <Controller
            control={control}
            rules={{ ...MaxLengthValidationFunc(255) }}
            name="title"
            id="title"
            defaultValue={defaultFormValues.title || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Full Title"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
              />
            )}
          />
          <div
            style={{ color: 'grey', fontSize: '12px', marginBottom: '25px', marginTop: '-12px' }}
          >
            This title will be shown in the preview popup. If left blank, Short Title will be used
            instead. Character limit: 255
          </div>

          {[1, 3].includes(Number(category)) && (
            <Controller
              control={control}
              rules={{ ...MaxLengthValidationFunc(255) }}
              name="description"
              id="description"
              defaultValue={defaultFormValues.description || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  variant="outlined"
                  label="Description"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  className={classes.formInput}
                  fullWidth
                  multiline
                  rows={3}
                />
              )}
            />
          )}
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="status"
            id="status"
            defaultValue={defaultFormValues.status || 1}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Status"
                value={value}
                onChange={(e) => {
                  onChange(e);
                }}
                error={!!error}
                className={classes.formInput}
                SelectProps={{
                  native: true,
                }}
                fullWidth
                select
              >
                <option key={1} value={1}>
                  Active
                </option>
                <option key={0} value={0}>
                  Inactive
                </option>
              </TextField>
            )}
          />
          <Controller
            control={control}
            name="displayDate"
            id="displayDate"
            rules={{ ...Validations.REQUIRED }}
            defaultValue={defaultFormValues.displayDate || new Date()}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label="Display Date"
                  format="MM/dd/yyyy"
                  autoOk
                  clearable
                  onChange={(e) => {
                    onChange(e);
                  }}
                  inputVariant="outlined"
                  helperText={error ? error.message : null}
                  error={!!error}
                  className={classes.formInput}
                  value={value}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
          />
          {!editId && (
            <>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="controlled-radio-buttons-group"
                value={selectedValue}
                onChange={handleChange}
              >
                <FormControlLabel value="image" control={<GreenRadio />} label="Upload Image" />
                <FormControlLabel value="video" control={<GreenRadio />} label="Upload Video" />
              </RadioGroup>

              {contentError && (
                <Box display="flex" mb={2}>
                  <div
                    style={{ color: '#f44336' }}
                    className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
                  >
                    {contentError}
                  </div>
                </Box>
              )}
            </>
          )}
          {selectedValue === 'image' && dataLoaded && (
            <UploadFile
              name="imageUpload"
              accept=".png, .jpg, .jpeg, .gif"
              type="image"
              buttonText="Image"
              setSuccessData={(val) => {
                setSelectedImageUrl(val);
                setContentError('');
              }}
              value={selectedImageUrl}
            />
          )}
          {selectedValue === 'video' && dataLoaded && (
            <UploadFile
              name="videoUpload"
              accept="video/*"
              type="video"
              buttonText="Video"
              setSuccessData={(val) => {
                setSelectedVideoUrl(val);
                setContentError('');
              }}
              value={selectedVideoUrl}
            />
          )}
          {selectedValue === 'video' &&
            dataLoaded &&
            (Number(category) === 3 || Number(category) === 1) && (
              <UploadFile
                name="thumbUpload"
                accept=".png, .jpg, .jpeg, .gif"
                type="thumb"
                buttonText="Thumbnail"
                setSuccessData={(val) => setSelectedThumb(val)}
                value={selectedThumb}
              />
            )}
          {(Number(category) === 3 || Number(category) === 1) && dataLoaded && (
            <UploadFile
              name="documentUpload"
              accept="*"
              type="document"
              buttonText="Supplementary Document"
              setSuccessData={(val) => {
                setSelectedDoc([...selectedDoc, { id: 0, fileName: val.fileName, url: val.url }]);
              }}
              value=""
            />
          )}
          {selectedDoc.map((doc, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`doc-${idx}`} className={classes.documentRow}>
              <div>
                <a href={doc.url} target="_blank" rel="noreferrer">
                  {doc.fileName}
                </a>
              </div>
              <div>
                <Button size="small" color="secondary" onClick={() => handleDocDelete(idx)}>
                  Delete
                </Button>
              </div>
            </div>
          ))}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          type="submit"
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing}
          form="submission-form"
        >
          Submit
        </Button>

        <Button variant="contained" disableElevation onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

FormDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  editId: PropTypes.number,
};
FormDialog.defaultProps = {
  editId: 0,
};

export default FormDialog;
