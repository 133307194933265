// Team Role
const TeamRoles = [
  {
    value: 1,
    key: 'SUPER_ADMIN',
    text: 'Super Admin',
  },
  {
    value: 2,
    key: 'ADMIN',
    text: 'Admin',
  },
  {
    value: 3,
    key: 'SALES_PERSON',
    text: 'Sales',
  },
  {
    value: 5,
    key: 'SUPPORT',
    text: 'Support',
  },
  {
    value: 6,
    key: 'SALES_MANAGER',
    text: 'Sales Manager',
  },
];
export default TeamRoles;
