import { Box, Container, Paper, Typography } from '@material-ui/core';
import React from 'react';
import ChangePasswordForm from '../../components/auth/ChangePasswordForm';
import Private from '../../layout/Private';

const ChangePassword = () => {
  const pageName = 'Change Password';
  return (
    <Private pageName={pageName}>
      <Box m={4}>
        <Container component={Paper}>
          <Box pt={0.3} pb={3}>
            <Box mb={4} mt={3}>
              <Typography variant="h5">Change Your Password</Typography>
            </Box>
            <ChangePasswordForm />
          </Box>
        </Container>
      </Box>
    </Private>
  );
};

export default ChangePassword;
