import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import axios from 'axios';
import { ArrowDropDown } from '@material-ui/icons';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getAgentsUpcomingShows, postAgentShowVideo } from '../../services/ShowSegments';
import { uploadSubmissionVideo } from '../../services/AWS';
import submissionStyle from '../../theme/styles/Submission';
import getS3FileUrl from '../../utils/GetS3FileUrl';
import ConfirmDialog from './ConfirmDialog';
import ViewVideo from './ViewVideo';
import SnackbarMessage from '../SnackbarMessage';
import { segmentVideoUploadGuidelines } from '../../config/DataValues';
import { trackUserClick } from '../../utils/Activity';

const useStyles = makeStyles(submissionStyle);

const ScheduledSegments = () => {
  const classes = useStyles();
  const [rowData, setRowData] = useState([]);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [reloadData, setReloadData] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [actionId, setActionId] = useState(0);
  const [videoId, setVideoId] = useState(0);
  const [viewModel, setViewModel] = useState(false);
  const [viewUrl, setViewUrl] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);

  const getDetails = window.localStorage.getItem('userDetail');
  const loggedInAgentId = getDetails ? Number(JSON.parse(getDetails).id) : 0;

  useEffect(() => {
    setDataLoaded(false);
    getAgentsUpcomingShows()
      .then((res) => {
        setRowData(res.data);
        setDataLoaded(true);
      })
      .catch(() => {
        setDataLoaded(true);
      });
  }, [reloadData]);

  const saveAgentVideo = (url, id) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });

    postAgentShowVideo(id, { videoUrl: url })
      .then(() => {
        setReloadData(!reloadData);
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
      });
  };

  const handleAction = (row, action) => {
    if (action === 'delete') {
      setActionId(row.id);
      setVideoId(row.videoId);
      setConfirmDialog(true);
      trackUserClick(108, { agent_id: loggedInAgentId, show_segment_subs_call_id: row.id });
    } else if (action === 'view') {
      setViewUrl(row.videoUrl);
      setViewModel(true);
      trackUserClick(107, { agent_id: loggedInAgentId, show_segment_subs_call_id: row.id });
    }
  };

  return (
    <Grid container spacing={2}>
      {!dataLoaded && (
        <>
          <Grid item xs={6}>
            <Card component={Box} p={0.5} display="flex" flexDirection="column">
              <CardContent className={classes.cardContent}>
                <Typography className={classes.segmentTitle}>
                  <Skeleton variant="text" />
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  className={classes.segmentDetailContainer}
                >
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Box>
                <Typography className={classes.segmentTheme}>
                  <Skeleton variant="text" />
                </Typography>
              </CardContent>
              <CardActions style={{ padding: '8px 0px' }}>&nbsp;</CardActions>
            </Card>
          </Grid>
        </>
      )}
      {rowData.length === 0 && dataLoaded && (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" mt={1} mb={2}>
          <Typography variant="body2" style={{ textAlign: 'center', marginBottom: 150 }}>
            Oops! Looks like there are no open slots for Show Segments right now.
            <br />
            Please check back soon for our next call for Submissions!
          </Typography>
        </Box>
      )}

      {dataLoaded &&
        rowData.map((p) => (
          <Grid item xs={6}>
            <Card component={Box} p={0.5} display="flex" flexDirection="column">
              <CardContent className={classes.cardContent}>
                <Typography className={classes.segmentTitle}>{p.title}</Typography>

                <Box
                  display="flex"
                  flexDirection="column"
                  className={classes.segmentDetailContainer}
                >
                  {p.specialTheme && (
                    <Typography className={classes.segmentTheme}>
                      <i>Special Theme: {p.specialTheme}</i>
                    </Typography>
                  )}
                  <Typography className={classes.segmentDescription}>
                    {p.description
                      ? p.description
                      : 'Take this chance to be a part of our upcoming episode!'}
                  </Typography>
                </Box>
                <Typography className={classes.segmentTheme}>{p.statusText}</Typography>
              </CardContent>
              <CardActions style={{ padding: '8px 0px' }}>
                <UploadMenu
                  handleAction={handleAction}
                  row={p}
                  saveVideo={(url) => saveAgentVideo(url, p.id)}
                  loggedInAgentId={loggedInAgentId}
                />
              </CardActions>
            </Card>
          </Grid>
        ))}
      {confirmDialog && (
        <ConfirmDialog
          deleteId={actionId}
          videoId={videoId}
          closeModel={() => {
            setConfirmDialog(false);
            setActionId(0);
          }}
          onSuccess={() => {
            setReloadData(!reloadData);
            setConfirmDialog(false);
            setActionId(0);
          }}
        />
      )}
      {viewModel && (
        <ViewVideo
          handleClose={() => {
            setViewModel(false);
            setViewUrl('');
          }}
          url={viewUrl}
        />
      )}
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Grid>
  );
};

const UploadMenu = ({ handleAction, row, saveVideo, loggedInAgentId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const hiddenFileInput = useRef(null);

  const [isFileUploading, setIsFileUploading] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onProgress = () => {
    if (!isFileUploading) {
      setIsFileUploading(true);
    }
  };

  const onFinish = (filename) => {
    const url = getS3FileUrl(filename);
    saveVideo(url);
    trackUserClick(104, {
      agent_id: loggedInAgentId,
      show_segment_subs_call_id: row.id,
      video_url: url,
    });
    setIsFileUploading(false);
  };

  const onError = () => {
    setIsFileUploading(false);
  };

  const uploadFile = async (data, file) => {
    try {
      await axios.put(data.signedUrl, file, {
        headers: { 'Content-Type': file.type },
        onUploadProgress: () => {
          onProgress();
        },
      });
      onFinish(data.filename);
    } catch {
      onError();
    }
  };

  const getSignedUrl = (file) => {
    const params = {
      objectName: file.name,
      contentType: file.type,
      path: `show-segments/${row.id}/`,
    };

    uploadSubmissionVideo(params)
      .then((data) => {
        uploadFile(data, file);
      })
      .catch(() => {
        setIsFileUploading(false);
      });
  };

  const handleChange = (event) => {
    setSnackbarMeesage({
      show: false,
      type: '',
      message: '',
    });
    const picker = event.target;

    if (picker.files[0].type.split('/')[0] === 'video') {
      getSignedUrl(picker.files[0]);
      window.document.getElementById(`upload-button-${row.id}`).value = null;
    } else {
      setTimeout(() => {
        setSnackbarMeesage({
          message: `Please select video file only.`,
          type: 'error',
          show: true,
        });
      }, 200);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    trackUserClick(106, { agent_id: loggedInAgentId, show_segment_subs_call_id: row.id });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptClick = (action) => {
    handleAction(row, action);
    handleClose();
  };

  const openGuidelines = () => {
    trackUserClick(105, { agent_id: loggedInAgentId, show_segment_subs_call_id: row.id });
    window.open(segmentVideoUploadGuidelines, '_blank');
  };

  return (
    <>
      {row.videoId && (
        <>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            endIcon={<ArrowDropDown />}
            size="small"
            variant="contained"
            color="primary"
          >
            Manage Video
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleOptClick('view')}>View</MenuItem>
            <MenuItem onClick={() => handleOptClick('delete')}>Delete</MenuItem>
          </Menu>
        </>
      )}
      {!row.videoId && row.status !== 'Scheduled' && (
        <>
          <input
            accept="video/*"
            id={`upload-button-${row.id}`}
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: 'none' }}
            disabled={isFileUploading}
          />
          <label htmlFor={`upload-button-${row.id}`}>
            <Button
              variant="contained"
              color="primary"
              component="span"
              disabled={isFileUploading}
              size="small"
              onClick={() => {
                trackUserClick(103, {
                  agent_id: loggedInAgentId,
                  show_segment_subs_call_id: row.id,
                });
              }}
            >
              {isFileUploading ? 'Uploading video...' : 'Upload Video'}
            </Button>
          </label>
        </>
      )}

      <Button variant="contained" size="small" onClick={openGuidelines} style={{ marginLeft: 8 }}>
        Check Guidelines
      </Button>

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </>
  );
};

UploadMenu.propTypes = {
  row: PropTypes.objectOf().isRequired,
  handleAction: PropTypes.func.isRequired,
  saveVideo: PropTypes.func.isRequired,
  loggedInAgentId: PropTypes.number.isRequired,
};

export default ScheduledSegments;
