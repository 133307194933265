import { getApiCall, postApiCall, deleteApiCall } from '../utils/Api';

export const listAll = async (page, params) => {
  const result = await getApiCall(`/content-engine/list?page=${page}${params ? `&${params}` : ''}`);
  return result.data;
};

export const addNewContent = async (data) => {
  const result = await postApiCall(`/content-engine/create`, data);
  return result.data;
};

export const viewById = async (id) => {
  const result = await getApiCall(`/content-engine/${id}/view`);
  return result.data;
};

export const updateContent = async (id, data) => {
  const result = await postApiCall(`/content-engine/${id}/update`, data);
  return result.data;
};

export const deleteContent = async (id) => {
  const result = await deleteApiCall(`/content-engine/${id}/delete`);
  return result.data;
};

export const getContentSignedUrl = async (data) => {
  const result = await postApiCall(`/content-engine/content-upload-url`, data);
  return result.data;
};

export const getAgentAsset = async (id) => {
  const result = await getApiCall(`/content-engine/${id}/agent-asset`);
  return result.data;
};

export const deleteAgentPic = async (id) => {
  const result = await deleteApiCall(`/content-engine/${id}/remove-agent-logo`);
  return result.data;
};

export const setupFBAccount = async (data) => {
  const result = await postApiCall(`/content-engine/setup-fb-account`, data);
  return result.data;
};

export const hasFBAccountSetup = async () => {
  const result = await getApiCall(`/content-engine/is-fb-setup`);
  return result.data;
};

export const addAgentContentAsset = async (data) => {
  const result = await postApiCall(`/content-engine/agent-content-create`, data);
  return result.data;
};

export const listAgentContentList = async () => {
  const result = await getApiCall(`/content-engine/agent-contnet-list`);
  return result.data;
};

export const skipCePost = async (id, data) => {
  const result = await postApiCall(`/content-engine/${id}/skip-ce-post`, data);
  return result.data;
};

export const retryCePost = async (id) => {
  const result = await getApiCall(`/content-engine/${id}/retry-ce-post`);
  return result.data;
};
