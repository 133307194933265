/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Dropzone from 'react-dropzone';
import IconButton from '@material-ui/core/IconButton';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Controller, useForm } from 'react-hook-form';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Validations, { MaxLengthValidationFunc } from '../../utils/Validations';
import {
  addBanner,
  updateBanner,
  listInternalLinks,
  getSignedImageUrl,
} from '../../services/Banner';
import SnackbarMessage from '../SnackbarMessage';
import { renderDashboardIcon } from '../DashboardIcons';

const AddNewBanner = ({ editData, setListRefreshFlag, editBanner, deleteId }) => {
  const myRef = useRef(null);
  const GreenRadio = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const [selectedValue, setSelectedValue] = useState('internal');
  const [bannerError, setBannerError] = useState('');
  const [selectedBannerURL, setSelectedBannerURL] = useState(null);
  const [internalLinkList, setInternalLinkList] = useState([]);
  const [internalLinkOption, setInternalLinkOption] = useState([]);
  const [dateError, setDateError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [processing, setProcessing] = useState(false);

  const { handleSubmit, control, setValue, reset } = useForm();

  const resetForm = () => {
    reset();
    setSelectedBannerURL(null);
  };

  const makeInternalLinkList = (data, value) => {
    const links = [];
    data.forEach((l) => {
      if (Number(value) === 0 && l.forAgentType === 0) {
        links.push({
          id: l.id,
          title: l.title,
          forAgentType: l.forAgentType,
        });
      } else if (Number(value) === 1 && (l.forAgentType === 1 || l.forAgentType === 0)) {
        links.push({
          id: l.id,
          title: l.title,
          forAgentType: l.forAgentType,
        });
      } else if (Number(value) === 2 && (l.forAgentType === 0 || l.forAgentType === 2)) {
        links.push({
          id: l.id,
          title: l.title,
          forAgentType: l.forAgentType,
        });
      }
    });
    setInternalLinkOption(links);
  };

  useEffect(() => {
    reset();
    listInternalLinks().then((res) => {
      if (res.success) {
        setInternalLinkList(res.data.rows);
        makeInternalLinkList(res.data.rows, 0);
      }
    });
    if (editData && editData.id) {
      myRef.current.scrollIntoView();
      setSelectedValue(editData.bannerClickTarget === 1 ? 'internal' : 'external');
      setValue('nrptype', editData.forAgentType);
      setValue('bannerOrder', Number(editData.bannerOrder));
      setTimeout(() => {
        setValue(
          'link',
          editData.bannerClickTarget === 1 ? editData.internalLinkId : editData.bannerClickUrl
        );
      }, 10);
      setValue('title', editData.title);
      setValue('internallinkid', editData.internalLinkId);
      setValue('description', editData.description);
      setValue('expiryDate', editData.expiryDate);
      setValue('startDate', editData.startDate);
      setValue('bannerImageUrl', editData.bannerImageUrl);
      setSelectedBannerURL(editData.bannerImageUrl);
    }

    if (deleteId === editData.id) {
      resetForm();
      editBanner({});
    }
  }, [editData, deleteId]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setTimeout(() => {
      setValue('link', '');
    }, 10);
  };

  const createView = () => {
    resetForm();
    editBanner({});
  };

  const uploadImage = async (file) => {
    if (file.length > 0 && file[0].type.split('/')[0] === 'image') {
      const options = {
        headers: {
          'Content-Type': file[0].type,
        },
      };
      const payload = {
        objectName: file[0].name,
        contentType: file[0].type,
      };
      const data = await getSignedImageUrl(payload);
      if (data.success) {
        const res = await axios.put(data.data.signedUrl, file[0], options);
        if (res) {
          setSelectedBannerURL(data.data.publicUrl);
        } else {
          setSnackbarMeesage({
            message: `Something went wrong.`,
            type: 'error',
            show: true,
          });
        }
      } else {
        setSnackbarMeesage({
          message: `Something went wrong.`,
          type: 'error',
          show: true,
        });
      }
    } else {
      setSnackbarMeesage({
        message: `Please select image file only.`,
        type: 'error',
        show: true,
      });
    }
    setProcessing(false);
  };

  const handleBannerChange = async (file) => {
    setSnackbarMeesage({
      message: ``,
      type: '',
      show: false,
    });
    setProcessing(true);
    setBannerError('');
    const bannerImage = new Image();
    bannerImage.addEventListener('load', () => {
      if (bannerImage.width === 1110 && bannerImage.height === 280) {
        uploadImage(file);
      } else {
        setSnackbarMeesage({
          message: `Banner resolution must be 1110x280.`,
          type: 'error',
          show: true,
        });
        setProcessing(false);
      }
    });
    bannerImage.src = URL.createObjectURL(file[0]);
  };

  const submitBannerForm = async (data) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: '',
    });

    let dateErrorCompare = '';
    setDateError('');
    if (data.startDate > data.expiryDate) {
      dateErrorCompare = "Expiration date can't be less then start date.";
      setDateError(dateErrorCompare);
    }
    let descriptionWordsError = '';
    setDescriptionError('');
    if (data.description.split(' ').length > 100) {
      descriptionWordsError = 'Maximum 100 words allowed for description';
      setDescriptionError(descriptionWordsError);
    }

    if (editData.id && dateErrorCompare === '' && descriptionWordsError === '') {
      const payload = {
        bannerImageUrl: selectedBannerURL || editData.bannerImageUrl,
        title: data.title,
        description: data.description,
        bannerClickTarget: selectedValue === 'internal' ? 1 : 2,
        bannerClickUrl: selectedValue !== 'internal' ? data.link : '',
        internalLinkId: selectedValue === 'internal' ? data.internallinkid : 0,
        startDate: data.startDate,
        expiryDate: data.expiryDate,
        bannerOrder: data.bannerOrder || 1,
        forAgentType: data.nrptype,
        status: 1,
      };
      updateBanner(editData.id, payload)
        .then((response) => {
          if (response.success) {
            resetForm();
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: 'Banner has been updated successfully.',
              type: 'success',
              show: true,
            });
            setTimeout(() => {
              editBanner({});
              setListRefreshFlag(true);
            }, 1000);
          }
        })
        .catch(({ response }) => {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.data.message,
            type: 'error',
            show: true,
          });
        });
    } else if (selectedBannerURL && dateErrorCompare === '' && descriptionWordsError === '') {
      const payload = {
        bannerImageUrl: selectedBannerURL,
        title: data.title,
        description: data.description,
        bannerClickTarget: selectedValue === 'internal' ? 1 : 2,
        bannerClickUrl: data.link || null,
        internalLinkId: selectedValue === 'internal' ? data.internallinkid : 0,
        startDate: data.startDate,
        expiryDate: data.expiryDate,
        bannerOrder: data.bannerOrder || 1,
        forAgentType: data.nrptype,
        status: 1,
      };

      addBanner(payload)
        .then((res) => {
          if (res && res.success) {
            setListRefreshFlag(true);
            resetForm();
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: 'Banner has been added successfully.',
              type: 'success',
              show: true,
            });
          }
        })
        .catch(({ res }) => {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: res.data.message,
            type: 'error',
            show: true,
          });
        });
    } else {
      setBannerError('Please select banner.');
    }
  };

  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <Typography ref={myRef} variant="h5">
            Banner
          </Typography>
          <Box mt={2}>
            <hr />
          </Box>
        </CardContent>
        <CardContent>
          <Box mt={-2}>
            <Typography variant="h6">{editData.id ? 'Update Banner' : 'Add New Banner'}</Typography>
          </Box>

          <Box mt={4}>
            <form id="add-banner" onSubmit={handleSubmit(submitBannerForm)}>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '50%' }}>
                  <Box sx={{ p: 2 }}>
                    <Controller
                      control={control}
                      rules={{ ...Validations.REQUIRED }}
                      name="bannerImageUrl"
                      id="bannerImageUrl"
                      defaultValue={1}
                      render={({ fieldState: { error } }) => (
                        <Dropzone
                          onDrop={handleBannerChange}
                          rules={{ ...Validations.REQUIRED }}
                          name="file"
                          accept="image/png, image/jpg, image/jpeg"
                          minSize={1024}
                          maxSize={3072000}
                          helperText={error ? error.message : null}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <Box
                              {...getRootProps({ className: 'dropzone' })}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              style={{
                                height: '150px',
                                border: bannerError ? '2px dashed red' : '2px dashed #41a14d',
                                backgroundColor: '#41a14d1c',
                                cursor: 'pointer',
                              }}
                            >
                              <input {...getInputProps()} />
                              <Grid container spacing={1}>
                                <Grid
                                  item
                                  xs={4}
                                  sm={4}
                                  style={{ display: 'flex', justifyContent: 'center' }}
                                >
                                  <IconButton
                                    style={{
                                      backgroundColor: '#fff',
                                      height: '90px',
                                      alignSelf: 'center',
                                    }}
                                  >
                                    {renderDashboardIcon('')}
                                  </IconButton>
                                </Grid>
                                <Grid item xs={8} sm={8}>
                                  <Typography
                                    variant="h6"
                                    style={{ color: '#41a14d', fontSize: '16px' }}
                                  >
                                    Upload Banner Image (1110x280) <br />
                                  </Typography>
                                  <Box>
                                    <Typography
                                      variant="body2"
                                      style={{ color: '#41a14d', fontSize: '16px' }}
                                    >
                                      Drop your file here or browse
                                    </Typography>
                                  </Box>
                                  <Box mt={1}>
                                    {selectedBannerURL && !processing && (
                                      <img
                                        alt=""
                                        src={selectedBannerURL}
                                        style={{
                                          maxWidth: '90%',
                                          maxHeight: '70px',
                                          display: 'block',
                                          marginBottom: '3px',
                                          objectFit: 'contain',
                                        }}
                                      />
                                    )}
                                    {processing && <CircularProgress />}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          )}
                        </Dropzone>
                      )}
                    />
                    {bannerError && (
                      <Box>
                        <p
                          style={{ color: '#f44336' }}
                          className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
                        >
                          {bannerError}
                        </p>
                      </Box>
                    )}
                  </Box>
                  <Box mt={1} sx={{ p: 2 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12}>
                        <Typography>Banner Order</Typography>
                        <Box mt={1}>
                          <Controller
                            control={control}
                            rules={{ ...Validations.REQUIRED }}
                            name="bannerOrder"
                            id="bannerOrder"
                            defaultValue={1}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                variant="outlined"
                                label=""
                                placeholder="Type Here"
                                type="number"
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                InputProps={{ inputProps: { min: 1, max: 32000 } }}
                                helperText={error ? error.message : null}
                                fullWidth
                              />
                            )}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={1} sx={{ p: 2 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12}>
                        <Typography>NRP Type</Typography>
                        <Box mt={1}>
                          <Controller
                            control={control}
                            rules={{ ...Validations.REQUIRED }}
                            name="nrptype"
                            id="nrptype"
                            defaultValue={0}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                variant="outlined"
                                label=""
                                placeholder="Select"
                                value={value}
                                onChange={(e) => {
                                  onChange(e.target.value);
                                  makeInternalLinkList(internalLinkList, e.target.value);
                                }}
                                error={!!error}
                                helperText={error ? error.message : null}
                                SelectProps={{
                                  native: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                select
                              >
                                <option key={0} value={0}>
                                  Both
                                </option>
                                <option key={1} value={1}>
                                  Agent
                                </option>
                                <option key={2} value={2}>
                                  Lender
                                </option>
                              </TextField>
                            )}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={1} sx={{ p: 2 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12}>
                        <RadioGroup
                          row
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={selectedValue}
                          onChange={handleChange}
                        >
                          <Box>
                            <Typography id="demo-row-radio-buttons-group-label">
                              Banner URL &nbsp;&nbsp;&nbsp;
                            </Typography>
                          </Box>
                          <Box mt={-1.3}>
                            <FormControlLabel
                              value="internal"
                              control={<GreenRadio />}
                              label="Internal Link"
                            />
                            &nbsp;&nbsp;
                            <FormControlLabel
                              value="external"
                              control={<GreenRadio />}
                              label="External Link"
                            />
                          </Box>
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={-3} sx={{ p: 2 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12}>
                        <Box mt={1}>
                          {selectedValue === 'internal' ? (
                            <Controller
                              control={control}
                              rules={{ ...Validations.REQUIRED }}
                              name="internallinkid"
                              id="internallinkid"
                              defaultValue={1}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  variant="outlined"
                                  label=""
                                  placeholder="Select"
                                  value={value}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  select
                                >
                                  {internalLinkOption.length > 0 &&
                                    internalLinkOption.map((l) => (
                                      <option key={l.id} value={l.id}>
                                        {l.title}
                                      </option>
                                    ))}
                                </TextField>
                              )}
                            />
                          ) : (
                            <Controller
                              control={control}
                              rules={{ ...Validations.REQUIRED, ...Validations.OPTIONAL_URL }}
                              name="link"
                              id="link"
                              defaultValue={selectedValue !== 'internal' ? '' : ''}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  variant="outlined"
                                  label=""
                                  placeholder="Type Here"
                                  value={value}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  fullWidth
                                />
                              )}
                            />
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
                <div style={{ width: '50%' }}>
                  <Box mt={-3} sx={{ p: 2 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12}>
                        <Typography>Banner Title</Typography>
                        <Box mt={1}>
                          <Controller
                            control={control}
                            rules={{ ...Validations.REQUIRED, ...MaxLengthValidationFunc(50) }}
                            name="title"
                            id="title"
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                variant="outlined"
                                label=""
                                placeholder="Type Here"
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                fullWidth
                                autoFocus={editData.id || false}
                              />
                            )}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={1} sx={{ p: 2 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12}>
                        <Typography>Banner Description</Typography>
                        <Box mt={1}>
                          <Controller
                            control={control}
                            rules={{ ...Validations.REQUIRED, ...MaxLengthValidationFunc(800) }}
                            name="description"
                            id="description"
                            defaultValue=""
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                variant="outlined"
                                label=""
                                placeholder="Maximum 100 words"
                                value={value}
                                onChange={onChange}
                                maxLength={800}
                                error={!!error}
                                helperText={error ? error.message : null}
                                fullWidth
                                multiline
                                rows={3}
                              />
                            )}
                          />
                        </Box>
                        {descriptionError && (
                          <Box ml={6} display="flex" justifyContent="center">
                            <p
                              style={{ color: '#f44336' }}
                              className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
                            >
                              {descriptionError}
                            </p>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={0} sx={{ p: 2 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={5} sm={5}>
                        <Typography>Start</Typography>
                        <Box mt={1}>
                          <Controller
                            control={control}
                            name="startDate"
                            id="startDate"
                            rules={{ ...Validations.REQUIRED }}
                            defaultValue={null}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  inputVariant="outlined"
                                  format="MM/dd/yyyy"
                                  value={value}
                                  autoOk
                                  clearable
                                  onChange={onChange}
                                  helperText={error ? error.message : null}
                                  error={!!error}
                                />
                              </MuiPickersUtilsProvider>
                            )}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <Box mt={5}>
                          <Typography align="center" variant="body2" style={{ fontSize: '1.5rem' }}>
                            -
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={5} sm={5}>
                        <Typography>Expiration</Typography>
                        <Box mt={1}>
                          <Controller
                            control={control}
                            name="expiryDate"
                            id="expiryDate"
                            rules={{ ...Validations.REQUIRED }}
                            defaultValue={null}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  inputVariant="outlined"
                                  format="MM/dd/yyyy"
                                  value={value}
                                  autoOk
                                  clearable
                                  onChange={onChange}
                                  helperText={error ? error.message : null}
                                  error={!!error}
                                />
                              </MuiPickersUtilsProvider>
                            )}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    {dateError && (
                      <Box ml={6} display="flex" justifyContent="center">
                        <p
                          style={{ color: '#f44336' }}
                          className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
                        >
                          {dateError}
                        </p>
                      </Box>
                    )}
                  </Box>
                  <Box mt={0} sx={{ p: 2 }}>
                    <Box mt={0}>
                      <Grid container spacing={editData.id ? 2 : 1}>
                        {editData.id && (
                          <Grid item xs={editData.id ? 6 : 12}>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              disableElevation
                              fullWidth
                              style={{ height: '50px', borderRadius: '40px' }}
                              onClick={() => createView()}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        )}
                        <Grid item xs={editData.id ? 6 : 12}>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            form="add-banner"
                            disableElevation
                            fullWidth
                            style={{ height: '50px', borderRadius: '40px' }}
                          >
                            {editData.id ? 'Update' : 'Save'}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </div>
              </div>
            </form>
          </Box>
        </CardContent>
      </Card>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </>
  );
};

AddNewBanner.propTypes = {
  editData: PropTypes.object.isRequired,
  setListRefreshFlag: PropTypes.func.isRequired,
  editBanner: PropTypes.func.isRequired,
  deleteId: PropTypes.number.isRequired,
};

export default AddNewBanner;
