import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
// import ListBulletins from './ListBulletins';
// import AddNewBulletin from './AddNewBulletin';
import ComingSoon from '../ComingSoon';

const ManageBulletins = () => (
  <Grid container spacing={3}>
    <Grid item xs={12} sm={12}>
      <Box mt={30} display="flex" justifyContent="center">
        <ComingSoon />
      </Box>
    </Grid>
    {/* <Grid item xs={12} sm={12}>
      <ListBulletins />
    </Grid> */}
  </Grid>
);

export default ManageBulletins;
