/* eslint-disable react/jsx-props-no-spreading */
import { Box, Paper, Tab, Tabs } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { zoneTypeValues } from '../../config/DataValues';
import PrivateWrapper from '../../layout/Private';
import ZipcodeSearchWaitlist from '../../components/widgets/ZipcodeSearchWaitlist';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ZipcodeSearchList = () => {
  const pageName = 'Widget Waitlist';
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setActiveTab(0);
  }, []);

  return (
    <PrivateWrapper pageName={pageName}>
      <Box component={Paper} mt={2} p={2} minHeight="80vh">
        <Box>
          <Tabs
            value={activeTab}
            aria-label="simple tabs example"
            indicatorColor="primary"
            onChange={(_, v) => {
              setActiveTab(v);
            }}
          >
            <Tab value={0} label="Agent Zip Codes Search" {...a11yProps(0)} />
            <Tab value={1} label="Lender Zip Codes Search" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={activeTab} index={0}>
          <ZipcodeSearchWaitlist zoneType={zoneTypeValues.AGENTS} />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <ZipcodeSearchWaitlist zoneType={zoneTypeValues.LENDERS} />
        </TabPanel>
      </Box>
    </PrivateWrapper>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

export default ZipcodeSearchList;
