/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TableContainer,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Link,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import agentStyle from '../../theme/styles/Agents';
import listingStyle from '../../theme/styles/TableListing';
import { viewAgents } from '../../services/Zones';

const useStyles = makeStyles(agentStyle);
const useStyles2 = makeStyles(listingStyle);

const AgentCell = ({ text, zoneId, title }) => {
  const classes = useStyles();
  const classes2 = useStyles2();

  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  const columns = [
    { name: 'Agents', id: 'name', width: '15%' },
    { name: 'Exclusivity', id: 'purchaseType', width: '15%' },
    { name: 'Status', id: 'paymentStatus', width: '15%' },
  ];

  const handleAgent = () => {
    setDataLoaded(false);
    setOpen(true);
    viewAgents(zoneId)
      .then((res) => {
        setRows(res.data.rows);
        setDataLoaded(true);
      })
      .catch(() => {
        setDataLoaded(true);
      });
  };

  return (
    <div>
      {text > 0 ? (
        <Link
          onClick={(e) => {
            e.preventDefault();
            handleAgent();
          }}
          href="#"
          className={classes2.linkText}
        >
          {text}
        </Link>
      ) : (
        <Typography variant="h6">-</Typography>
      )}

      <Dialog open={open} fullWidth maxWidth="md" onClose={() => setOpen(false)}>
        <DialogTitle>
          <div className={classes.titleContainer}>
            <Typography variant="h6">{title}</Typography>
            <IconButton size="small" onClick={() => setOpen(false)}>
              <Close />{' '}
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table className={classes.tableData} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((col) => (
                    <TableCell width={col.width}>{col.name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataLoaded &&
                  (rows.length > 0 ? (
                    rows.map((row) => (
                      <TableRow key={`ag-${row.id}`}>
                        <TableCell component="th" scope="row">
                          <Typography variant="body1">{`${row.firstName} ${row.lastName}`}</Typography>
                          <Typography variant="body2" className={classes.emailText}>
                            {row.email}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {row.purchaseType === '2' ? 'Semi Exclusive' : 'Exclusive'}
                        </TableCell>
                        <TableCell>{row.paymentStatus}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        align="center"
                        size="medium"
                        colSpan={8}
                        className={classes.noRecord}
                        style={{ padding: 20, fontSize: 15 }}
                      >
                        No records found
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};

AgentCell.propTypes = {
  text: PropTypes.number.isRequired,
  zoneId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default AgentCell;
