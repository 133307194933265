import { getApiCall, postApiCall } from '../utils/Api';
import { agentTypeValues } from '../config/DataValues';

export const viewByAgentId = async (id) => {
  const result = await getApiCall(`/contract/${id}/view-agent`);
  return result.data;
};

export const createContract = async (data, module = agentTypeValues.AGENTS) => {
  const result = await postApiCall(`/contract/create?module=${module}`, data);
  return result.data;
};

export const viewByToken = async (token) => {
  const result = await getApiCall(`/contract/${token}/view`);
  return result.data;
};

export const updataContractStatus = async (data) => {
  const result = await postApiCall(`/contract/${data.token}/update-status`, data);
  return result.data;
};

export const createContratSubscription = async (data) => {
  const result = await postApiCall(`/contract/${data.token}/create-subscription`, {
    paymentMethodId: data.paymentMethodId,
  });
  return result.data;
};

export const cancelContract = async (id, data) => {
  const result = await postApiCall(`/contract/${id}/cancel`, data);
  return result.data;
};

export const createDraftAmendment = async (data) => {
  const result = await postApiCall(`/contract/create-draft-amendment`, data);
  return result.data;
};

export const viewDraftAmendment = async (id) => {
  const result = await getApiCall(`/contract/${id}/view-draft-amendment`);
  return result.data;
};

export const createDraftContractZoneItem = async (data) => {
  const result = await postApiCall(`/contract/create-draft-contract-zone-item`, data);
  return result.data;
};

export const deleteDraftContractZoneItem = async (data) => {
  const result = await postApiCall(`/contract/delete-draft-contract-zone-item`, data);
  return result.data;
};

export const sendAmendment = async (data) => {
  const result = await postApiCall(`/contract/send-amendment`, data);
  return result.data;
};

export const cancelAmendment = async (id, data) => {
  const result = await postApiCall(`/contract/${id}/cancel-amendment`, data);
  return result.data;
};

export const createAmendmentSubscription = async (token) => {
  const result = await postApiCall(`/contract/${token}/create-amendment-subscription`);
  return result.data;
};

export const checkHasContract = async () => {
  const result = await getApiCall(`/contract/check-contract`);
  return result.data;
};
