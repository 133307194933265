import green from '@material-ui/core/colors/green';

// Style for dashboard navs
const Style = () => ({
  navCard: {
    borderRadius: 15,
    boxShadow: '-3px 0px 20px rgba(196, 196, 196, 0.51)',
    cursor: 'pointer',
  },
  navCardActive: {
    borderRadius: 15,
    boxShadow: '-3px 0px 20px rgba(196, 196, 196, 0.51)',
    cursor: 'pointer',
    '& .MuiCardContent-root': {
      backgroundColor: green[600],
      color: 'white',
    },
  },
  navIcon: {
    marginBottom: 10,
    fontSize: '5rem',
  },
  navTitle: {
    marginBottom: 10,
  },
});

export default Style;
