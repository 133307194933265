import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Box,
  TextField,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import React, { useEffect, useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Controller, useForm } from 'react-hook-form';
import {
  ArrowRightAlt,
  Done,
  Facebook,
  Twitter,
  Instagram,
  YouTube,
  Pinterest,
  LinkedIn,
} from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import InputAdornment from '@material-ui/core/InputAdornment';
import agentStyles from '../../theme/styles/Agents';
import formInputStyles from '../../theme/styles/FormInput';
import { updateAgentProfile, viewAgentById } from '../../services/Agent';
import Validations, {
  MaxLengthValidationFunc,
  MinLengthValidationFunc,
} from '../../utils/Validations';
import SnackbarMessage from '../SnackbarMessage';
import ProfileUploader from '../ProfileUploader';
import RoutePaths from '../../config/Routes';
import UploadAsset from '../assets/UploadAsset';
import { searchStateCode } from '../../services/State';
import { trackUserClick } from '../../utils/Activity';
import WYSIWYGEditor from '../WYSIWYG';
import BioHtmlFiltered from '../agent/BioHtmlFiltered';
import RealtorWordWarning from '../agent/RealtorWordWarning';
import bioContainRealtorWord from '../../utils/Realtor';

const TikTokIcon = () => (
  <svg
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    width="100%"
    height="100%"
  >
    <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
  </svg>
);

const useStyles = makeStyles(agentStyles);
const useStyles2 = makeStyles(formInputStyles);

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '100px',
    marginTop: '-5px',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '208px',
  }),
};

const AgentDetails = () => {
  const classes = useStyles();
  const classes2 = useStyles2();

  const history = useHistory();
  const getDetails = window.localStorage.getItem('userDetail');
  const userId = getDetails ? Number(JSON.parse(getDetails).id) : 0;

  const [userDetails, setUserDetails] = useState(null);
  const [userProfile, setUserProfile] = useState('');
  const [showProfileError, setShowProfileError] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [assetId, setAssetId] = useState(0);
  const [assetStatus, setAssetStatus] = useState('');
  const [agentWebStatus, setAgentWebStatus] = useState('-');
  const [agentWebUrl, setAgentWebUrl] = useState('');
  const [webStatusModal, setWebStatusModal] = useState(false);
  const [displayPhone, setDisplayPhone] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [showHtmlFilterWarning, setShowHtmlFilterWarning] = useState(false);
  const [showRealtorWordWarning, setShowRealtorWordWarning] = useState(false);
  const [reloadPage, setReloadPage] = useState(1);

  const { handleSubmit, control, setValue, getValues } = useForm();

  useEffect(() => {
    setDataLoaded(false);
    viewAgentById(userId)
      .then((res) => {
        setValue('email', res.data.email);
        setValue('location', res.data.location);
        setValue('bio', res.data.bio);
        setValue('phone', res.data.phone);
        setValue('facebook', res.data.facebook);
        setValue('twitter', res.data.twitter);
        setValue('instagram', res.data.instagram);
        setValue('youtube', res.data.youtube);
        setValue('pinterest', res.data.pinterest);
        setValue('tiktok', res.data.tiktok);
        setValue('linkedin', res.data.linkedin);
        setValue('website', res.data.website);
        setValue('stateCodeId', res.data.state.id ? res.data.state : '');
        setUserDetails(res.data);
        setUserProfile(res.data.profilePic);
        setAssetId(res.data.assetId);
        setAssetStatus(res.data.assetStatus);
        setAgentWebStatus(res.data.webStatus || '-');
        setAgentWebUrl(res.data.webUrl || '');
        setDisplayPhone(res.data.displayPhone === 1);
        setDataLoaded(true);
      })
      .catch(() => {
        setUserDetails(null);
        setDataLoaded(true);
      });
  }, [reloadPage]);

  const submitForm = (formData) => {
    const data = formData;
    data.stateCodeId = formData.stateCodeId ? Number(formData.stateCodeId.id) : null;
    delete data.email;

    if (!userProfile) {
      setShowProfileError(true);
      return;
    }

    const stopSaving = bioContainRealtorWord(data.bio, 'bio-text-wrapper');
    if (stopSaving) {
      setShowRealtorWordWarning(true);
      return;
    }

    setProcessing(true);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    updateAgentProfile({ ...data, profilePic: userProfile, displayPhone })
      .then((res) => {
        setProcessing(false);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'Your profile has been updated successfully.',
          type: 'success',
          show: true,
        });
        if (res.data.hasBioFiltered) {
          setReloadPage(reloadPage + 1);
          setShowHtmlFilterWarning(true);
        } else {
          history.push(RoutePaths.HOME);
        }
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
        setProcessing(false);
      });
  };

  // load options using API call
  const loadStateOptions = (inputStateValue, callback) => {
    searchStateCode(inputStateValue).then((res) => {
      callback(
        res.data.rows.map((i) => ({
          code: i.code,
          id: i.id,
        }))
      );
    });
  };

  const toggleWebStatus = () => {
    setWebStatusModal(!webStatusModal);
  };

  const handleCloseHtmlFilteredNotice = () => {
    setShowHtmlFilterWarning(false);
  };

  const handleCloseRealtorWordWarning = (whatNext) => {
    setShowRealtorWordWarning(false);
    if (whatNext) {
      window.document.getElementById('submit-btn-profile').click();
    }
  };

  const handleSubmitButtonClick = () => {
    if (userProfile === '') {
      setShowProfileError(true);
    } else {
      setShowProfileError(false);
    }
  };

  const defaultFormValues = getValues();

  return (
    <div>
      <form onSubmit={handleSubmit(submitForm)}>
        <Grid
          container
          alignContent="center"
          justify="center"
          direction="column"
          alignItems="center"
        >
          <Grid item>
            <ProfileUploader
              profilePic={userProfile}
              onSuccess={(url) => {
                setUserProfile(url);
                if (url === '') {
                  trackUserClick(22);
                  setShowProfileError(true);
                } else if (url) {
                  trackUserClick(21);
                  setShowProfileError(false);
                }
              }}
              setUploading={setUploading}
              updateHeaderImage
            />
            {userProfile === '' && showProfileError && (
              <Typography className={classes2.formErrorText}>
                Please upload your profile picture.
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Box mt={1.5}>
              <Typography variant="h5">
                {dataLoaded && userDetails ? (
                  `${userDetails.firstName} ${userDetails.lastName}`
                ) : (
                  <Skeleton variant="text" width={100} />
                )}
              </Typography>
            </Box>
          </Grid>
          {agentWebUrl !== '' && (
            <Grid item>
              <Box mt={1} mb={2}>
                <a
                  onClick={() => trackUserClick(23)}
                  href={agentWebUrl}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: 'green' }}
                >
                  {agentWebUrl}
                </a>
              </Box>
            </Grid>
          )}
          <Grid item style={{ marginTop: '10px' }}>
            <Button
              size="small"
              variant="contained"
              disabled={!dataLoaded}
              color="primary"
              onClick={() => {
                toggleWebStatus();
                trackUserClick(24);
              }}
            >
              Preview
            </Button>
          </Grid>
        </Grid>
        <Container className={classes.agentDetailsMainDiv}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8} className={classes.agentDetailDiv}>
              <Typography className={classes.agentDetailsHeading}>Email</Typography>
              <Controller
                control={control}
                name="email"
                id="email"
                defaultValue={defaultFormValues.email || ''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    placeholder="Email"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={classes.formInput}
                    fullWidth
                    size="small"
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={4} className={classes.agentDetailDiv}>
              <Typography className={classes.agentDetailsHeading}>Location*</Typography>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Controller
                  control={control}
                  rules={{
                    ...Validations.REQUIRED,
                    ...MinLengthValidationFunc(3),
                    ...MaxLengthValidationFunc(25),
                    ...{ pattern: { value: /^[a-zA-Z0-9 -]*$/, message: 'Invalid value' } },
                  }}
                  name="location"
                  id="location"
                  defaultValue={defaultFormValues.location || ''}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      placeholder="City"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      className={classes.formInput}
                      fullWidth
                      size="small"
                      disabled={!dataLoaded}
                      style={{ width: '50%' }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="stateCodeId"
                  id="stateCodeId"
                  rules={Validations.REQUIRED}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div className={classes.formInput}>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={value}
                        getOptionLabel={(e) => e.code}
                        getOptionValue={(e) => e.id}
                        loadOptions={loadStateOptions}
                        onChange={(v) => onChange(v)}
                        placeholder="State"
                        styles={customStyles}
                        disabled={!dataLoaded}
                        menuPlacement="top"
                      />
                      {error && (
                        <Typography className={classes2.formErrorText}>{error.message}</Typography>
                      )}
                    </div>
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={8} className={classes.agentDetailDiv} id="bio-text-wrapper">
              <Typography className={classes.agentDetailsHeading}>Bio*</Typography>
              <Controller
                control={control}
                name="bio"
                id="bio"
                rules={Validations.REQUIRED}
                defaultValue={defaultFormValues.bio || ''}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <>
                    <WYSIWYGEditor value={value} onChange={onChange} />
                    {error && (
                      <Typography
                        style={{ marginTop: -15, marginBottom: 10 }}
                        className={classes2.formErrorText}
                      >
                        {error.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} lg={4} className={classes.agentDetailDiv}>
              <Typography className={classes.agentDetailsHeading}>Phone*</Typography>
              <Controller
                control={control}
                rules={{
                  ...Validations.REQUIRED,
                  ...MaxLengthValidationFunc(14),
                  ...{ pattern: { value: /^\(\d{3}\)\s\d{3}-\d{4}$/, message: 'Invalid value' } },
                }}
                name="phone"
                id="phone"
                defaultValue={defaultFormValues.phone || ''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <InputMask
                    mask="(999) 999-9999"
                    value={value}
                    maskChar="_"
                    alwaysShowMask
                    onChange={onChange}
                  >
                    {() => (
                      <TextField
                        className={classes.formInput}
                        error={!!error}
                        helperText={error ? error.message : null}
                        fullWidth
                      />
                    )}
                  </InputMask>
                )}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={displayPhone}
                    onChange={(e) => setDisplayPhone(e.target.checked)}
                    color="primary"
                    disabled={!dataLoaded}
                  />
                }
                label="Display Phone on ODIS"
              />
            </Grid>
            <Grid item xs={12} className={classes.agentDetailDiv}>
              <Controller
                control={control}
                name="facebook"
                id="facebook"
                defaultValue={defaultFormValues.facebook || ''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Facebook />
                        </InputAdornment>
                      ),
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={classes.formInput}
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className={classes.agentDetailDiv}>
              <Controller
                control={control}
                name="twitter"
                id="twitter"
                defaultValue={defaultFormValues.twitter || ''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Twitter />
                        </InputAdornment>
                      ),
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={classes.formInput}
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className={classes.agentDetailDiv}>
              <Controller
                control={control}
                name="instagram"
                id="instagram"
                defaultValue={defaultFormValues.instagram || ''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Instagram />
                        </InputAdornment>
                      ),
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={classes.formInput}
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className={classes.agentDetailDiv}>
              <Controller
                control={control}
                name="youtube"
                id="youtube"
                defaultValue={defaultFormValues.youtube || ''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <YouTube />
                        </InputAdornment>
                      ),
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={classes.formInput}
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className={classes.agentDetailDiv}>
              <Controller
                control={control}
                name="pinterest"
                id="pinterest"
                defaultValue={defaultFormValues.pinterest || ''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Pinterest />
                        </InputAdornment>
                      ),
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={classes.formInput}
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className={classes.agentDetailDiv}>
              <Controller
                control={control}
                name="tiktok"
                id="tiktok"
                defaultValue={defaultFormValues.tiktok || ''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div style={{ width: '24px' }}>
                            <TikTokIcon />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={classes.formInput}
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className={classes.agentDetailDiv}>
              <Controller
                control={control}
                name="linkedin"
                id="linkedin"
                defaultValue={defaultFormValues.linkedin || ''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LinkedIn />
                        </InputAdornment>
                      ),
                    }}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={classes.formInput}
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className={classes.agentDetailDiv}>
              <Controller
                control={control}
                name="website"
                id="website"
                defaultValue={defaultFormValues.website || ''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    placeholder="Website"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={classes.formInput}
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <UploadAsset userId={userId} assetId={assetId} assetStatus={assetStatus} />
            </Grid>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Button
                  id="submit-btn-profile"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disableElevation
                  endIcon={processing ? <CircularProgress size={20} /> : <Done />}
                  disabled={!dataLoaded || processing || uploading}
                  onClick={handleSubmitButtonClick}
                >
                  Save Changes
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowRightAlt />}
                  disableElevation
                  component={Link}
                  to={RoutePaths.CHANGE_PASSWORD}
                  onClick={() => trackUserClick(26)}
                >
                  Change Password
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </form>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}

      <Dialog open={webStatusModal} onClose={toggleWebStatus}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
            {agentWebStatus !== 'PENDING' && agentWebStatus !== 'APPROVED' && agentWebUrl === '' && (
              <>
                <div>
                  Your profile is being created on Negotiators.tv. Once it&apos;s published,
                  you&apos;ll see the url of your profile here.
                </div>
              </>
            )}
            {agentWebStatus === 'PENDING' && (
              <>
                <h2 style={{ color: 'red' }}>Pending</h2>
                <div>
                  We&apos;re verifying your profile updates. Once it&apos;s verified, you&apos;ll
                  see those changes live on your Negotiators.tv profile.
                </div>
              </>
            )}
            {agentWebStatus === 'APPROVED' && (
              <>
                <h2 style={{ color: 'green' }}>Live!</h2>
                <div>
                  Thanks for submitting your information. Your profile is updated on Negotiators.tv
                </div>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleWebStatus} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {showHtmlFilterWarning && <BioHtmlFiltered closeModel={handleCloseHtmlFilteredNotice} />}
      {showRealtorWordWarning && (
        <RealtorWordWarning closeModel={(v) => handleCloseRealtorWordWarning(v)} />
      )}
    </div>
  );
};

export default AgentDetails;
