/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
  Button,
  TableContainer,
  Paper,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { Pagination, Skeleton } from '@material-ui/lab';
import { getAgentTeam } from '../../services/Agent';
import listingStyle from '../../theme/styles/TableListing';
import logo from '../../assets/images/ng.ico';
import SnackbarMessage from '../../components/SnackbarMessage';
import { viewUserById, resetMyPassword, loginAsTeam } from '../../services/Auth';
import ConfirmDialog from '../../components/agent/ConfirmDialog';
import ResendInviteTeamMember from '../../components/agent/ResendInviteTeamMember';

const useStyles = makeStyles(listingStyle);

const AgentTeam = () => {
  const classes = useStyles();
  const getDetails = window.localStorage.getItem('userDetail');
  const agentId = getDetails ? JSON.parse(getDetails).id : 0;
  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reloadRows, setReloadRows] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [accessEmail, setAccessEmail] = useState(null);
  const [userModel, setUserModel] = useState(false);
  const [userAction, setUserAction] = useState('');
  const [teamMemberEmail, setTeamMemberEmail] = useState('');
  const [teamMemberId, setTeamMemberId] = useState(0);
  const [loginId, setLoginId] = useState(0);
  const [processingRequestResetPwd, setProcessingRequestResetPwd] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [showResendInvite, setShowResendInvite] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    setDataLoaded(false);
    getAgentTeam(agentId, activePage).then((res) => {
      setRows(res.data.rows);
      setTotalPages(res.data.totalPages);
      setDataLoaded(true);
    });
  }, [activePage, reloadRows]);

  const requestResetPassword = (id) => {
    setProcessingRequestResetPwd(true);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    viewUserById(id)
      .then((res) => {
        resetMyPassword({ userName: res.data.username })
          .then((resp) => {
            setProcessingRequestResetPwd(false);
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: resp.message,
              type: 'success',
              show: true,
            });
          })
          .catch(({ response }) => {
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: response.data.message,
              type: 'error',
              show: true,
            });
            setProcessingRequestResetPwd(false);
          });
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
        setProcessingRequestResetPwd(false);
      });
  };

  const userAccess = (email, uid) => {
    setUserAction('revoke');
    setAccessEmail(email);
    setDeleteId(uid);
    setUserModel(true);
  };

  const loginAsTeamMember = (data) => {
    setUserAction('loginAsTeam');
    setLoginId(agentId);
    setTeamMemberId(data.id);
    setTeamMemberEmail(data.email);
    setUserModel(true);
  };

  const handleActions = (row, action) => {
    if (action === 'reset-pwd' && row.status === 'Active') {
      requestResetPassword(row.userId);
    } else if (action === 'login-team' && row.status === 'Active') {
      loginAsTeamMember(row);
    } else if (action === 'resend-invi') {
      setEditId(row.id);
      setShowResendInvite(true);
    } else if (action === 'revoke-access' && row.status === 'Active') {
      userAccess(row.email, row.userId);
    }
  };

  return (
    <>
      <Box>
        <TableContainer component={Paper} className={classes.tableData} aria-label="cards table">
          <Table className={classes.tableData}>
            <TableHead>
              <TableRow>
                <TableCell width="2%" />
                <TableCell width="25%">Name</TableCell>
                <TableCell width="38%">Email</TableCell>
                <TableCell width="15%">Phone</TableCell>
                <TableCell width="38%">Status</TableCell>
                <TableCell width="38%">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!dataLoaded &&
                [1, 2, 3, 4, 5].map((val) => (
                  <TableRow key={`sub-${val}`}>
                    <TableCell component="th" scope="row">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                  </TableRow>
                ))}
              {rows.map((row) => (
                <TableRow key={`sub-${row.id}`}>
                  <TableCell>
                    {row.videoUrl ? (
                      <img src={logo} alt={process.env.REACT_APP_NAME} width={30} height={20} />
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell>{`${row.firstName} ${row.lastName}`}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell>
                    <span
                      className={
                        row.status === 'Active' ? classes.statusActive : classes.statusInactive
                      }
                    >
                      {row.status}
                    </span>
                  </TableCell>
                  <TableCell>
                    <ActionMenu id={row.id} selectAction={handleActions} row={row} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {dataLoaded && totalPages > 1 && (
          <Pagination
            count={totalPages}
            showFirstButton
            showLastButton
            className={classes.tablePagination}
            onChange={(_, pageNumber) => {
              setActivePage(pageNumber);
              setReloadRows(!reloadRows);
            }}
            page={activePage}
          />
        )}
      </Box>
      <Box mt={5}>
        <div>
          Please contact support via the chat widget in the bottom right corner to request team
          members be invited to join your team.
        </div>
      </Box>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      {processingRequestResetPwd && (
        <Backdrop open className={classes.backdrop}>
          <CircularProgress color="inherit" />
          <Typography variant="body2">Processing Request...</Typography>
        </Backdrop>
      )}
      {userModel && (
        <ConfirmDialog
          open={userModel}
          closeModel={() => {
            setUserModel(false);
            setDeleteId(null);
          }}
          // Are you sure you want to login as ${singularSmallLabel}? You will be redirected to the ${singularSmallLabel} information view.
          title={
            userAction === 'loginAsTeam'
              ? `Login as Team Member`
              : `Revoke access of ${accessEmail}`
          }
          description={
            userAction === 'loginAsTeam'
              ? `Are you sure you want to login as ${teamMemberEmail}? You will be redirected to the team member information view.`
              : 'Do you want to revoke access from this member?'
          }
          onSuccess={() => {
            setUserModel(false);
            setDeleteId(null);
            setReloadRows(true);
          }}
          deleteId={deleteId}
          loginId={loginId}
          teamMemberId={teamMemberId}
          action={userAction}
        />
      )}

      {showResendInvite && (
        <ResendInviteTeamMember
          closeModel={() => {
            setEditId(null);
            setShowResendInvite(false);
          }}
          onSuccess={() => {
            setEditId(null);
            setShowResendInvite(false);
            setReloadRows(!reloadRows);
          }}
          agentId={editId}
        />
      )}
    </>
  );
};
const ActionMenu = ({ row, selectAction }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (action) => {
    selectAction(row, action);
    handleClose();
  };
  return (
    <div>
      <Button aria-controls={`simple-menu${row.id}`} aria-haspopup="true" onClick={handleClick}>
        <MoreVert />
      </Button>
      <Menu
        id={`simple-menu${row.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleMenuClick('login-team');
          }}
          button
          disabled={row.status !== 'Active'}
        >
          Login as Team Member
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClick('resend-invi');
          }}
          button
          disabled={row.status !== 'Pending'}
        >
          Resend Invitation
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClick('reset-pwd');
          }}
          button
          disabled={row.status !== 'Active' || !row.userId}
        >
          Request Reset Password
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClick('revoke-access');
          }}
          button
          disabled={row.status !== 'Active' || !row.userId}
        >
          Revoke Access
        </MenuItem>
      </Menu>
    </div>
  );
};
ActionMenu.propTypes = {
  row: PropTypes.objectOf().isRequired,
  selectAction: PropTypes.func.isRequired,
  // rowAgentType: PropTypes.number.isRequired,
};

export default AgentTeam;
