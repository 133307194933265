import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { viewById } from '../../services/Widgets';
import zoneStyle from '../../theme/styles/Zone';

const useStyles = makeStyles(zoneStyle);

const ViewWaitlist = ({ rowId, closeModel }) => {
  const classes = useStyles();

  const [viewData, setViewData] = useState({
    name: '',
    phone: '',
    email: '',
    zipcodes: '',
    formOption: '',
    formSource: '',
    zones: '',
    openZonesFound: '',
    response: '',
    ip: '',
    searchTime: '',
  });

  useEffect(() => {
    viewById(rowId).then((res) => {
      const { data } = res;
      setViewData(data);
    });
  }, []);

  const handleDialog = () => {
    closeModel();
  };

  return (
    <Dialog open onClose={handleDialog} fullWidth maxWidth="lg">
      <DialogTitle>Search Query Detail</DialogTitle>

      <DialogContent>
        <TableContainer>
          <Table className={classes.viewTable}>
            <TableRow>
              <TableCell width="30%" className={classes.viewLabel}>
                Zip Codes
              </TableCell>
              <TableCell width="70%" className={classes.detailsCell}>
                {viewData.zipcodes}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.viewLabel}>Form Option</TableCell>
              <TableCell className={classes.detailsCell}>{viewData.formOption}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.viewLabel}>Form Source</TableCell>
              <TableCell className={classes.detailsCell}>{viewData.formSource}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.viewLabel}>All Zones</TableCell>
              <TableCell className={classes.detailsCell}>{viewData.zones}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.viewLabel}>Open Zones Found</TableCell>
              <TableCell className={classes.detailsCell}>{viewData.openZonesFound}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.viewLabel}>Redirected To</TableCell>
              <TableCell className={classes.detailsCell}>{viewData.response}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.viewLabel}>Name</TableCell>
              <TableCell className={classes.detailsCell}>{viewData.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.viewLabel}>Email</TableCell>
              <TableCell className={classes.detailsCell}>{viewData.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.viewLabel}>Phone</TableCell>
              <TableCell className={classes.detailsCell}>{viewData.phone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.viewLabel}>IP</TableCell>
              <TableCell className={classes.detailsCell}>{viewData.ip}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.viewLabel}>Search Date</TableCell>
              <TableCell className={classes.detailsCell}>{viewData.searchTime}</TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleDialog} disableElevation>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ViewWaitlist.propTypes = {
  closeModel: PropTypes.func.isRequired,
  rowId: PropTypes.number.isRequired,
};

export default ViewWaitlist;
