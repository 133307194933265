import { getApiCall, postApiCall, deleteApiCall } from '../utils/Api';

export const listAllByAgentId = async (page, params) => {
  const result = await getApiCall(`/agent/inquiries?page=${page}${params ? `&${params}` : ''}`);
  return result.data;
};

export const addInquiryNote = async (id, data) => {
  const result = await postApiCall(`/agent/inquiries/${id}/add-notes`, data);
  return result.data;
};

export const listNotes = async (id) => {
  const result = await getApiCall(`/agent/inquiries/${id}/list-notes`);
  return result.data;
};

export const updateInquiryNote = async (id, data) => {
  const result = await postApiCall(`/agent/inquiries/${id}/update-notes`, data);
  return result.data;
};

export const deleteInquiryNote = async (id) => {
  const result = await deleteApiCall(`/agent/inquiries/${id}/remove-notes`);
  return result.data;
};
