import { deleteApiCall, getApiCall, postApiCall } from '../utils/Api';

/* eslint-disable import/prefer-default-export */
export const listAll = async (page, params) => {
  const result = await getApiCall(`/show-segments/list?page=${page}${params ? `&${params}` : ''}`);
  return result.data;
};

export const viewById = async (id) => {
  const result = await getApiCall(`/show-segments/${id}/view`);
  return result.data;
};

export const create = async (data) => {
  const result = await postApiCall(`/show-segments/create`, data);
  return result.data;
};

export const update = async (id, data) => {
  const result = await postApiCall(`/show-segments/${id}/update`, data);
  return result.data;
};

export const deleteById = async (id) => {
  const result = await deleteApiCall(`/show-segments/${id}/delete`);
  return result.data;
};

export const getAgentsUpcomingShows = async () => {
  const result = await getApiCall(`/show-segments/users/list-segments`);
  return result.data;
};

export const getAgentsPastShows = async (page) => {
  const result = await getApiCall(`/show-segments/users/past-segments?page=${page || 1}`);
  return result.data;
};

export const postAgentShowVideo = async (id, data) => {
  const result = await postApiCall(`/show-segments/users/${id}/add-video`, data);
  return result.data;
};

export const deleteAgentShowVideo = async (id, data) => {
  const result = await postApiCall(`/show-segments/users/${id}/delete-video`, data);
  return result.data;
};

export const getAgentSubmissions = async (id, page) => {
  const result = await getApiCall(`/show-segments/${id}/list-submissions?page=${page}`);
  return result.data;
};
