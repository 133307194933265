/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppsIcon from '@material-ui/icons/Apps';
import PeopleIcon from '@material-ui/icons/People';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import RoomIcon from '@material-ui/icons/Room';
import ExploreIcon from '@material-ui/icons/Explore';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import BarChartIcon from '@material-ui/icons/BarChart';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import BallotIcon from '@material-ui/icons/Ballot';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import WidgetsIcon from '@material-ui/icons/Widgets';
import LinkIcon from '@material-ui/icons/Link';
import TimelineIcon from '@material-ui/icons/Timeline';
import {
  AccountCircle,
  ExpandLess,
  ExpandMore,
  FeaturedVideo,
  Subject,
  ThumbUpAlt,
  Facebook,
} from '@material-ui/icons';
import ShareIcon from '@material-ui/icons/Share';
import { Collapse } from '@material-ui/core';
import Routes from '../config/Routes';
import sidebarStyle from '../theme/styles/SidebarNavs';
import UserTypes from '../config/UserTypes';
import { trackUserClick } from '../utils/Activity';
import { agentTypeValues } from '../config/DataValues';

const useStyles = makeStyles(sidebarStyle);

// Sidebar navigation links
const SidebarNavs = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;
  const agentType = getDetails
    ? Number(JSON.parse(getDetails).agentType || agentTypeValues.AGENTS)
    : agentTypeValues.AGENTS;
  const [openedSubMenus, setOpenedSubMenu] = useState([]);

  const routeArr = [
    {
      title: 'Overview',
      path: Routes.HOME,
      icon: <AppsIcon />,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.AGENT.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
      allowedAgentTypes: [agentTypeValues.AGENTS, agentTypeValues.LENDERS],
    },
    {
      title: 'Sales Tools',
      path: Routes.SALES_DEMO_LINK,
      icon: <LinkIcon />,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      title: 'NRP Dashboard',
      path: Routes.NRP_DASHBOARD,
      icon: <TimelineIcon />,
      allowUsers: [UserTypes.SUPER_ADMIN.value, UserTypes.ADMIN.value],
    },
    {
      title: 'Content Engine',
      path: Routes.CONTENT_ENGINE,
      icon: <ShareIcon />,
      allowUsers: [UserTypes.SUPER_ADMIN.value, UserTypes.ADMIN.value],
    },
    {
      title: 'Agents',
      path: Routes.AGENTS,
      icon: <PeopleIcon />,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      title: 'Lenders',
      path: Routes.LENDERS,
      icon: <PeopleIcon />,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      title: 'Content Engine Users',
      path: Routes.CE_USERS,
      icon: <SupervisedUserCircleIcon />,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      title: 'Team',
      path: Routes.TEAM,
      icon: <GroupWorkIcon />,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      title: 'Zones',
      path: Routes.ZONES,
      icon: <RoomIcon />,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      title: 'Lender Zones',
      path: Routes.LENDER_ZONES,
      icon: <RoomIcon />,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      title: 'States',
      path: Routes.STATE_CODES,
      icon: <ExploreIcon />,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      title: 'Zip Codes',
      path: Routes.ZIPCODES,
      icon: <GpsFixedIcon />,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      title: 'Resources',
      path: Routes.ADMIN_RESOURCE,
      icon: <RoomIcon />,
      allowUsers: [UserTypes.SUPER_ADMIN.value, UserTypes.ADMIN.value],
    },
    {
      title: 'Approvals',
      path: '',
      icon: <ThumbUpAlt />,
      allowUsers: [UserTypes.SUPER_ADMIN.value, UserTypes.ADMIN.value],
      subItems: [
        {
          title: 'Ads',
          path: Routes.AD_APPROVALS,
          icon: <FeaturedVideo />,
          allowUsers: [UserTypes.SUPER_ADMIN.value, UserTypes.ADMIN.value],
        },
        {
          title: 'Submissions',
          path: Routes.SUBMISSIONS,
          icon: <Subject />,
          allowUsers: [UserTypes.SUPER_ADMIN.value, UserTypes.ADMIN.value],
        },
      ],
    },
    {
      title: 'Widget Waitlist',
      path: Routes.WIDGET_ZIPCODE_SEARCH_LIST,
      icon: <WidgetsIcon />,
      allowUsers: [UserTypes.SUPER_ADMIN.value, UserTypes.ADMIN.value],
    },
    {
      title: 'Reports',
      path: Routes.REPORTS,
      icon: <BarChartIcon />,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      title: 'Resources',
      path: Routes.RESOURCES,
      icon: <PeopleIcon />,
      allowUsers: [UserTypes.AGENT.value],
      allowedAgentTypes: [agentTypeValues.AGENTS, agentTypeValues.LENDERS],
    },
    {
      title: 'Content Engine',
      path: Routes.CONTENT,
      icon: <ShareIcon />,
      allowUsers: [UserTypes.AGENT.value],
      allowedAgentTypes: [agentTypeValues.CE_USERS],
    },
    {
      title: userType === UserTypes.AGENT.value ? 'Web Profile' : 'Manage Profile',
      path: Routes.UPDATE_PROFILE,
      icon: <AccountCircle />,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.AGENT.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
      allowedAgentTypes: [agentTypeValues.AGENTS, agentTypeValues.LENDERS],
    },
    {
      title: 'TV Show',
      path: Routes.TV_SHOW,
      icon: <LiveTvIcon />,
      allowUsers: [UserTypes.AGENT.value],
      allowedAgentTypes: [agentTypeValues.AGENTS, agentTypeValues.LENDERS],
    },
    // {
    //   title: 'Participation',
    //   path: Routes.PARTICIPATION,
    //   icon: <BallotIcon />,
    //   allowUsers: [UserTypes.AGENT.value],
    //   allowedAgentTypes: [agentTypeValues.AGENTS, agentTypeValues.LENDERS],
    // },
    {
      title: 'Facebook Group',
      path: 'facebook',
      icon: <Facebook />,
      allowUsers: [UserTypes.AGENT.value],
      allowedAgentTypes: [agentTypeValues.AGENTS, agentTypeValues.LENDERS],
    },
  ];

  useEffect(() => {
    if (location.pathname.includes('/approvals')) {
      setOpenedSubMenu([...openedSubMenus, 'Approvals']);
    }
  }, []);

  const handleLink = (page) => {
    let objId = 0;
    if (page === Routes.HOME) {
      objId = 1;
    } else if (page === Routes.RESOURCES) {
      objId = 2;
    } else if (page === Routes.CONTENT) {
      objId = 77;
    } else if (page === Routes.TV_SHOW) {
      objId = 76;
    } else if (page === Routes.LOGOUT) {
      objId = 5;
    } else if (page === Routes.UPDATE_PROFILE) {
      objId = 75;
    } else if (page === 'facebook') {
      objId = 80;
    } else if (page === Routes.PARTICIPATION) {
      objId = 81;
    }
    if (objId) {
      trackUserClick(objId);
    }
    if (page === 'facebook') {
      window.open('https://www.facebook.com/groups/nrp.tv', '_blank');
    } else {
      history.push(page);
    }
  };

  const handleSubMenu = (title) => {
    if (openedSubMenus.includes(title)) {
      const updated = openedSubMenus.splice(1, openedSubMenus.indexOf(title));
      setOpenedSubMenu(updated);
    } else {
      setOpenedSubMenu([...openedSubMenus, title]);
    }
  };

  return (
    <div className={userType === 4 ? classes.menuDrawer : ''}>
      <List className={classes.menuItemContainer}>
        {routeArr.map((route) => {
          if (!route.allowUsers.includes(userType)) {
            return null;
          }
          if (
            route.allowUsers.includes(UserTypes.AGENT.value) &&
            !route.allowedAgentTypes.includes(agentType)
          ) {
            return null;
          }
          return (
            <>
              {route.title === 'Facebook Group' ? (
                <ListItem
                  button
                  component="a"
                  onClick={() => handleLink(route.path)}
                  target="_blank"
                  className={classes.menuItem}
                >
                  <ListItemIcon className={classes.menuIcon}>{route.icon}</ListItemIcon>
                  <ListItemText className={classes.menuText} primary={route.title} />
                </ListItem>
              ) : (
                <ListItem
                  button
                  onClick={() =>
                    route?.subItems ? handleSubMenu(route.title) : handleLink(route.path)
                  }
                  selected={location.pathname === route.path}
                  className={classes.menuItem}
                >
                  <ListItemIcon className={classes.menuIcon}>{route.icon}</ListItemIcon>
                  <ListItemText className={classes.menuText} primary={route.title} />
                  {route.subItems && !openedSubMenus.includes(route.title) ? <ExpandMore /> : null}
                  {route.subItems && openedSubMenus.includes(route.title) ? <ExpandLess /> : null}
                </ListItem>
              )}

              {route?.subItems && route?.subItems.length > 0 && (
                <Collapse in={openedSubMenus.includes(route.title)} timeout={0} unmountOnExit>
                  <List component="div" disablePadding>
                    {route.subItems.map((s) => (
                      <ListItem
                        button
                        onClick={() => handleLink(s.path)}
                        selected={location.pathname === s.path}
                        className={`${classes.nested} ${classes.menuItem}`}
                      >
                        <ListItemIcon className={classes.menuIcon}>{s.icon}</ListItemIcon>
                        <ListItemText className={classes.menuText} primary={s.title} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </>
          );
        })}
      </List>
      <List>
        <ListItem
          button
          onClick={() => handleLink(Routes.LOGOUT)}
          selected={location.pathname === Routes.LOGOUT}
          className={userType === 4 ? classes.menuItemLogout : classes.menuItem}
        >
          <ListItemIcon className={classes.menuIcon}>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText className={classes.menuText} primary="Logout" />
        </ListItem>
      </List>
    </div>
  );
};

export default SidebarNavs;
