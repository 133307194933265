// Style for overview page
const Style = () => ({
  overviewContainer: {
    display: 'flex',
    marginTop: 50,
    marginBottom: 50,
  },
  overviewCard: {
    minHeight: '15rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title1: {
    fontSize: '2rem',
    fontWeight: '100',
  },
  title2: {
    fontSize: '3rem',
    fontWeight: 'bold',
  },
  cardButton: {
    width: 'fit-content',
  },
});

export default Style;
