import { format } from 'date-fns';

// Get current time stamp
export const getCurrentTimestamp = () => Math.ceil(Date.now() / 1000);
export const convertTimestampToDate = (val) => {
  const d = new Date(Number(val) * 1000);
  return d.toUTCString();
};
export const convertContractTimestampToDate = (timestamp) =>
  new Date(timestamp * 1000).toISOString().substring(0, 10);

export const isPastDate = (date) =>
  new Date(date).getTime() / 1000 + 24 * 60 * 60 < getCurrentTimestamp();

export const formatSubmissionDate = (date) => {
  if (date.toString() === `Invalid Date` || !date) {
    return date;
  }
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return format(new Date(`${date.toString().substring(0, 15)} 00:00:00`), 'yyyy-MM-dd', {
    timeZone: timezone,
  });
};

export const formatSubmissionDateEOD = (date) => {
  if (date.toString() === `Invalid Date` || !date) {
    return date;
  }
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return format(new Date(`${date.toString().substring(0, 15)} 23:59:00`), 'yyyy-MM-dd', {
    timeZone: timezone,
  });
};

export const formatCePostDateAgentSide = (datetime) => {
  const dateFormat = new Date(
    new Date(datetime).setSeconds(new Date(datetime).getSeconds() - 5 * 60 * 60)
  )
    .toUTCString()
    .replace('GMT', 'EST');
  let finalFormat = '';
  finalFormat += `${dateFormat.substring(8, 11)} `;
  finalFormat += `${dateFormat.substring(5, 7)}, `;
  finalFormat += `${dateFormat.substring(12, 16)}`;

  return finalFormat;
};

export const formatESTTimezone = (dateTime) => {
  const offset = new Date().getTimezoneOffset();
  const date = new Date(
    new Date(dateTime).setSeconds(new Date(dateTime).getSeconds() - 5 * 60 * 60 + offset * 60)
  );

  let hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
  const amPm = date.getHours() >= 12 ? 'PM' : 'AM';
  hours = hours < 10 ? `0${hours}` : hours;
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const time = `${date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}/${
    date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
  }/${date.getFullYear()} ${hours}:${minutes} ${amPm} EST`;

  return time;
};
