import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import OdisProfile from './OdisProfile';

const OdisProfileModel = ({ onClose, agentId }) => (
  <Dialog open onClose={onClose} fullWidth maxWidth="lg">
    <DialogContent>
      <OdisProfile agentId={agentId} from="admin" />
    </DialogContent>
    <DialogActions>
      <Button variant="contained" disableElevation onClick={onClose}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

OdisProfileModel.propTypes = {
  onClose: PropTypes.func.isRequired,
  agentId: PropTypes.number.isRequired,
};
export default OdisProfileModel;
