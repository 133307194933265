/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Box, Button } from '@material-ui/core';
import { uploadSubmissionVideo } from '../../services/AWS';
import SnackbarMessage from '../SnackbarMessage';
import getS3FileUrl from '../../utils/GetS3FileUrl';
import { trackUserClick } from '../../utils/Activity';

const SubmissionVideoUpload = ({
  submissionId,
  onSuccess,
  title,
  size,
  variant,
  hideInfo,
  disabled,
  slug,
}) => {
  const buttonId = `${submissionId}${slug ? `${slug}` : ''}`;

  const hiddenFileInput = useRef(null);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [showVideoProgressTip, setShowVideoProgressTip] = useState(false);

  const onProgress = () => {
    if (!isFileUploading) {
      setIsFileUploading(true);
    }
  };

  const onFinish = (filename) => {
    const url = getS3FileUrl(filename);
    onSuccess(url);
    setIsFileUploading(false);
  };

  const onError = () => {
    setIsFileUploading(false);
  };

  const uploadFile = async (data, file) => {
    setShowVideoProgressTip(true);
    try {
      await axios.put(data.signedUrl, file, {
        headers: { 'Content-Type': file.type },
        onUploadProgress: () => {
          onProgress();
        },
      });
      onFinish(data.filename);
      setShowVideoProgressTip(false);
    } catch {
      onError();
    }
  };

  const getSignedUrl = (file) => {
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });
    const params = {
      objectName: file.name,
      contentType: file.type,
      path: `submissions/${submissionId}/`,
    };

    uploadSubmissionVideo(params)
      .then((data) => {
        uploadFile(data, file);
      })
      .catch(() => {
        setIsFileUploading(false);
      });
  };

  const handleChange = (event) => {
    setSnackbarMeesage({
      show: false,
      type: '',
      message: '',
    });
    const picker = event.target;

    if (picker.files[0].type.split('/')[0] === 'video') {
      getSignedUrl(picker.files[0]);
      document.getElementById(`upload-button-${buttonId}`).value = null;
    } else {
      setSnackbarMeesage({
        message: `Please select video file only.`,
        type: 'error',
        show: true,
      });
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <input
        accept="video/*"
        id={`upload-button-${buttonId}`}
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        disabled={isFileUploading || disabled}
      />
      <label htmlFor={`upload-button-${buttonId}`}>
        <Button
          variant={variant}
          color="primary"
          fullWidth
          component="span"
          disabled={isFileUploading || disabled}
          size={size}
          disableElevation
          onClick={() => trackUserClick(38)}
        >
          {isFileUploading ? `Uploading video...` : title}
        </Button>
      </label>
      <div style={{ marginBottom: 20, color: 'grey' }}>
        {showVideoProgressTip &&
          !hideInfo &&
          `Do not close the window or save the details until whole video is uploaded.`}
      </div>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Box>
  );
};

SubmissionVideoUpload.propTypes = {
  submissionId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  size: PropTypes.string,
  variant: PropTypes.string,
  hideInfo: PropTypes.bool,
  disabled: PropTypes.bool,
  slug: PropTypes.string,
};
SubmissionVideoUpload.defaultProps = {
  size: '',
  variant: 'outlined',
  hideInfo: false,
  disabled: false,
  slug: '',
};
export default SubmissionVideoUpload;
