import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { useHistory } from 'react-router-dom';
import { MenuItem, Menu, Avatar, Button, Box } from '@material-ui/core';
import clsx from 'clsx';
import { ArrowRightAlt } from '@material-ui/icons';
import SidebarNavs from './SidebarNavs';
import MenuNotifications from './MenuNotifications';
import menubarStyle from '../theme/styles/Menubar';
import RoutePaths from '../config/Routes';
import logo from '../assets/images/logo.png';
import cLogo from '../assets/images/c-logo.png';
import UserTypes from '../config/UserTypes';
import { checkHasContract } from '../services/Contract';
import { loginAsAccessor } from '../services/Auth';
import { getCurrentTimestamp } from '../utils/Datetime';
import { getAgentTypeLabel } from '../utils/GetModuleType';
import { isDefaultApp } from '../utils/AppDomain';
import { agentTypeValues } from '../config/DataValues';
import { trackUserClick } from '../utils/Activity';

const useStyles = makeStyles(menubarStyle);

// Menubar with sidebar options
const Menubar = ({ pageName, isForContractPage, agentDetails }) => {
  const classes = useStyles();
  const history = useHistory();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasContract, setHasContract] = useState(false);
  const [isTeamMember, setIsTeamMember] = useState(false);

  const user = agentDetails || window.localStorage.getItem('userDetail');
  const userNameObj = user ? JSON.parse(user) : null;
  const userName = user ? `${userNameObj.firstName} ${userNameObj.lastName}` : '';
  const userProfilePic = user ? userNameObj.profilePic : '';
  const userType = user ? Number(userNameObj.type) : 0;
  const isTeamLeader = user ? userNameObj.isTeamLeader : false;
  const hasTeamAccess = user ? userNameObj.hasTeamAccess : false;
  const agentTypeLabel = getAgentTypeLabel(userNameObj?.agentType, false, true);
  const agentType = Number(userNameObj?.agentType || agentTypeValues.AGENTS);
  const isCEUser = agentType === agentTypeValues.CE_USERS;

  useEffect(() => {
    if (userType === UserTypes.AGENT.value && !isCEUser) {
      checkHasContract()
        .then(() => setHasContract(true))
        .catch(() => setHasContract(false));
    }
  }, []);

  const isAccessor = window.localStorage.getItem('isAccessorLoggedIn');

  const doLoginAsAccessor = () => {
    loginAsAccessor()
      .then(() => {
        setTimeout(() => {
          if (userNameObj.agentType === agentTypeValues.LENDERS) {
            window.localStorage.removeItem('isAccessorLoggedIn');
            window.location.assign(RoutePaths.LENDERS);
          } else if (userNameObj.agentType === agentTypeValues.AGENTS) {
            window.localStorage.setItem('isTeamMemberLoggedIn', Number(0));
            window.localStorage.removeItem('isAccessorLoggedIn');
            window.location.assign(RoutePaths.AGENTS);
          } else {
            window.localStorage.setItem('isTeamMemberLoggedIn', Number(0));
            window.localStorage.removeItem('isAccessorLoggedIn');
            window.location.assign(RoutePaths.CE_USERS);
          }
        }, 1000);
      })
      .catch(() => {
        window.location.assign(RoutePaths.LOGOUT);
      });
  };

  const updateAccessorLoginTime = () => {
    const localIsAccessorValue = window.localStorage.getItem('isAccessorLoggedIn');
    if (localIsAccessorValue) {
      window.localStorage.setItem('isAccessorLoggedIn', getCurrentTimestamp());
      setTimeout(() => {
        updateAccessorLoginTime();
      }, 60000);
    } else {
      doLoginAsAccessor();
    }
  };

  useEffect(() => {
    setIsTeamMember(Number(window.localStorage.getItem('isTeamMemberLoggedIn')) || 0);
    if (isAccessor) {
      const isAccessorValue = window.localStorage.getItem('isAccessorLoggedIn');
      const requestTime = Number(isAccessorValue);
      const currentTime = getCurrentTimestamp();
      const diffInSeconds = Number(currentTime - requestTime);
      if (diffInSeconds > 75) {
        doLoginAsAccessor();
      } else {
        setTimeout(() => {
          updateAccessorLoginTime();
        }, 60000);
      }
    }
  }, []);

  const handleMenu = (event) => {
    if (userType === UserTypes.AGENT.value) {
      trackUserClick(6);
    }

    if (!isForContractPage) setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleDrawer = () => {
    setMobileOpen(!mobileOpen);
  };

  const logoutUser = () => {
    trackUserClick(48);
    history.push(RoutePaths.LOGOUT);
  };

  const changePassword = () => {
    trackUserClick(47);
    history.push(RoutePaths.CHANGE_PASSWORD);
  };

  const manageProfile = () => {
    trackUserClick(43);
    history.push(RoutePaths.UPDATE_PROFILE);
  };

  const manageBilling = () => {
    trackUserClick(44);
    history.push(RoutePaths.BILLING.replace(':page', 'billing'));
  };

  const manageTeam = () => {
    history.push(RoutePaths.BILLING.replace(':page', 'team'));
  };

  return (
    <div>
      <AppBar position="fixed" className={classes.appBar}>
        {isAccessor && (
          <div className={classes.topBar}>
            <Box ml={1}>
              <Typography
                className={classes.adminViewBtn}
                variant="button"
              >{`You are logged in as ${agentTypeLabel}: ${userName}`}</Typography>
            </Box>
            <Button
              onClick={doLoginAsAccessor}
              endIcon={<ArrowRightAlt />}
              className={classes.adminViewBtn}
            >
              {isTeamMember ? 'Go back to Agent View' : 'Go back to Admin View'}
            </Button>
          </div>
        )}

        <div className={classes.mainAppBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={handleToggleDrawer}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>

            {isForContractPage || !isDefaultApp() ? (
              <img
                src={isDefaultApp() ? logo : cLogo}
                alt={process.env.REACT_APP_NAME}
                className={classes.logo}
              />
            ) : (
              <Typography variant="h6" noWrap>
                {process.env.REACT_APP_NAME}
                {pageName && ` / ${pageName}`}
              </Typography>
            )}
          </Toolbar>

          <div style={{ marginTop: 8 }}>
            {userType === UserTypes.AGENT.value && !isCEUser && <MenuNotifications />}

            <IconButton
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              className={classes.userMenu}
            >
              <Avatar
                className={classes.userProfile}
                src={userProfilePic}
                alt=""
                id="user-profile-image"
              />{' '}
              {userName}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={classes.userOptions}
            >
              {!isCEUser && <MenuItem onClick={manageProfile}>Manage Profile</MenuItem>}

              {userType === UserTypes.AGENT.value && hasContract && isTeamLeader && !isCEUser && (
                <MenuItem onClick={manageBilling}>My Account</MenuItem>
              )}
              {userType === UserTypes.AGENT.value &&
                !isCEUser &&
                hasContract &&
                hasTeamAccess &&
                isTeamLeader && <MenuItem onClick={manageTeam}>Manage Team</MenuItem>}
              <MenuItem onClick={changePassword}>Change Password</MenuItem>
              <MenuItem onClick={logoutUser}>Logout</MenuItem>
            </Menu>
          </div>
        </div>
      </AppBar>

      {!isForContractPage && (
        <nav className={classes.drawer}>
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleToggleDrawer}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <IconButton onClick={handleToggleDrawer} className={classes.closeMenuButton}>
                <CloseIcon />
              </IconButton>
              <SidebarNavs />
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: isAccessor
                  ? clsx(classes.drawerPaper, classes.paperWithTopBarStyle)
                  : classes.drawerPaper,
              }}
            >
              <div className={classes.toolbar} />
              <SidebarNavs />
            </Drawer>
          </Hidden>
        </nav>
      )}
    </div>
  );
};

Menubar.propTypes = {
  pageName: PropTypes.string.isRequired,
  isForContractPage: PropTypes.bool,
  agentDetails: PropTypes.string,
};

Menubar.defaultProps = {
  isForContractPage: false,
  agentDetails: null,
};

export default Menubar;
