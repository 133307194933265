import { green, grey } from '@material-ui/core/colors';

// Style for agent pages
const Style = (theme) => ({
  dataGridContainer: {
    width: '100%',
    height: '100%',
  },
  addZoneButton: {
    marginBottom: 15,
    float: 'right',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formInput: {
    marginBottom: 20,
  },
  emailText: {
    color: 'gray',
  },
  agentDetailsDiv: {
    marginTop: '4rem',
  },
  agentProfile: {
    height: '6.5rem',
    width: '6.5rem',
    borderRadius: 5,
  },
  agentProfileDiv: {
    marginTop: '-2.5rem',
  },
  defaultAgentText: {
    color: grey[500],
  },
  agentDetailsHeading: {
    color: grey[400],
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    letterSpacing: '0.1rem',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  },
  agentDetailsMainDiv: {
    marginTop: '2rem',
    width: '82%',
  },
  listingHeading: {
    color: green[600],
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  },
  bioDiv: {
    whiteSpace: 'pre-line',
    maxHeight: '220px',
    overflowY: 'scroll',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  addAgentFormBtn: {
    marginRight: 10,
  },

  amendmenetZoneTitle: {
    margin: '30px 0',
    [theme.breakpoints.down('md')]: {
      margin: '15px 0',
    },
  },
});

export default Style;
