/* eslint-disable react/jsx-props-no-spreading */
import { Box, Paper, Tab, Tabs } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PrivateWrapper from '../../layout/Private';
import NrpInsights from '../../components/approvals/NrpInsights';
import GuestBlogs from '../../components/approvals/Blogs';
import ShowSegments from '../../components/approvals/show-segments/List';
import UserTypes from '../../config/UserTypes';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SubmissionsPage = () => {
  const pageName = 'Submissions';
  const [activeTab, setActiveTab] = useState(0);
  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;
  const [tabValue, setTabValue] = useState(
    userType === UserTypes.SUPER_ADMIN.value ? 'submission' : 'segments'
  );

  useEffect(() => {
    if (tabValue === 'submission') {
      setActiveTab(0);
    } else if (tabValue === 'segments') {
      setActiveTab(2);
    } else {
      setActiveTab(1);
    }
  }, [tabValue]);

  return (
    <PrivateWrapper pageName={pageName}>
      <Box component={Paper} mt={2} p={2} minHeight="80vh">
        <Box>
          <Tabs
            value={tabValue}
            aria-label="simple tabs example"
            indicatorColor="primary"
            onChange={(_, v) => {
              setTabValue(v);
            }}
          >
            {userType === UserTypes.SUPER_ADMIN.value && (
              <Tab value="submission" label="NRP Insights" {...a11yProps(0)} />
            )}
            {userType === UserTypes.SUPER_ADMIN.value && (
              <Tab value="blog" label="Guest Blogs" {...a11yProps(1)} />
            )}
            <Tab value="segments" label="NRP Show Segments" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <Box>
          {userType === UserTypes.SUPER_ADMIN.value && tabValue === 'submission' && (
            <TabPanel value={activeTab} index={0}>
              <NrpInsights />
            </TabPanel>
          )}
          {userType === UserTypes.SUPER_ADMIN.value && tabValue === 'blog' && (
            <TabPanel value={activeTab} index={1}>
              <GuestBlogs />
            </TabPanel>
          )}
          {tabValue === 'segments' && (
            <TabPanel value={activeTab} index={2}>
              <ShowSegments />
            </TabPanel>
          )}
        </Box>
      </Box>
    </PrivateWrapper>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

export default SubmissionsPage;
