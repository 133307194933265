import { getApiCall, postApiCall, deleteApiCall } from '../utils/Api';

const listAll = async (page, params) => {
  const result = await getApiCall(
    `/state/list${page ? `?page=${page}${params ? `&${params}` : ''}` : ''}`
  );
  return result.data;
};

export const addNewState = async (data) => {
  const result = await postApiCall(`/state/create`, data);
  return result.data;
};

export const editState = async (id, data) => {
  const result = await postApiCall(`/state/${id}/update`, data);
  return result.data;
};

export const viewStateById = async (id) => {
  const result = await getApiCall(`/state/${id}/view`);
  return result.data;
};

export const removeState = async (id) => {
  const result = await deleteApiCall(`/state/${id}/remove`);
  return result.data;
};

export const listStates = async () => {
  const result = await getApiCall(`/state/search`);
  return result.data;
};

export const searchStateCode = async (searchText) => {
  const result = await getApiCall(`/state/search?searchText=${searchText}`);
  return result.data;
};

export const searchStateName = async (searchText) => {
  const result = await getApiCall(`/state/search-name?searchText=${searchText}`);
  return result.data;
};

export default listAll;
