import { Box, Typography } from '@material-ui/core';
import React from 'react';
import OpenBlogsGrid from '../blog/OpenBlogsGrid';
import PostBlogsTable from '../blog/PostBlogsTable';

const AgentBlogs = () => (
  <Box>
    <Box>
      <Box mb={2}>
        <Typography variant="h5">Open Blogs</Typography>
      </Box>
      <OpenBlogsGrid />
    </Box>
    <Box mt={3}>
      <Box mb={2}>
        <Typography variant="h5">Past Blogs</Typography>
      </Box>
      <PostBlogsTable />
    </Box>
  </Box>
);

export default AgentBlogs;
