import React, { useEffect, useState } from 'react';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Pagination, Skeleton } from '@material-ui/lab';
import { getAgentsPastShows } from '../../services/ShowSegments';
import listingStyle from '../../theme/styles/TableListing';

const useStyles = makeStyles(listingStyle);

const PastSegments = () => {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reloadRows, setReloadRows] = useState(false);

  useEffect(() => {
    setDataLoaded(false);
    getAgentsPastShows(activePage).then((res) => {
      setRows(res.data.rows);
      setTotalPages(res.data.totalPages);
      setDataLoaded(true);
    });
  }, [activePage, reloadRows]);

  return (
    <>
      {(!dataLoaded || rows.length > 0) && (
        <Table className={classes.tableData}>
          <TableHead>
            <TableRow>
              <TableCell width="2%" />
              <TableCell width="58%">Title</TableCell>
              <TableCell width="20%">Status</TableCell>
              <TableCell width="20%">Submission Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!dataLoaded &&
              [1, 2, 3, 4, 5].map((val) => (
                <TableRow key={`sub-${val}`}>
                  <TableCell component="th" scope="row">
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                </TableRow>
              ))}
            {rows.map((row) => (
              <TableRow key={`sub-${row.id}`}>
                <TableCell />
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.statusText}</TableCell>
                <TableCell>{row.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {dataLoaded && totalPages > 1 && (
        <Pagination
          count={totalPages}
          showFirstButton
          showLastButton
          className={classes.tablePagination}
          onChange={(_, pageNumber) => {
            setActivePage(pageNumber);
            setReloadRows(!reloadRows);
          }}
          page={activePage}
        />
      )}

      {rows.length === 0 && dataLoaded && (
        <div style={{ width: '100%', textAlign: 'center', marginTop: 25, marginBottom: 50 }}>
          You have not submitted any Show Segments video yet.
        </div>
      )}
    </>
  );
};

export default PastSegments;
