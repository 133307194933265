const Style = () => ({
  profileAvtar: {
    height: '6.5rem',
    width: '6.5rem',
    borderRadius: 5,
  },
  profileUploaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadProgress: {
    flex: 1,
    marginLeft: '1rem',
  },
  uploadLabel: {
    backgroundColor: '#fff',
    padding: '0px',
    borderRadius: '50%',
  },
  uploadMultipleLabel: {
    position: 'absolute',
    bottom: 0,
    left: '29%',
    backgroundColor: '#fff',
    borderRadius: '18%',
  },
  uploadIcon: {
    padding: '0px',
  },
  deleteIcon: {
    color: 'red',
  },
  mr10: {
    marginRight: 10,
  },
});

export default Style;
