import {
  Box,
  Button,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { ArrowDownward, ImportExport, MoreVert } from '@material-ui/icons';
import { Pagination, Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import PrivateWrapper from '../../layout/Private';
import RoutePaths from '../../config/Routes';
import ConfirmDialog from '../../components/resources/ConfirmDialog';
import { listAll } from '../../services/Resource';
import listingStyle from '../../theme/styles/TableListing';
import TableToolbar from '../../components/TableToolbar';
import FormDialog from '../../components/resources/FormDialog';
import ViewDialog from '../../components/resources/ViewDialog';
import SnackbarMessage from '../../components/SnackbarMessage';
import { getCurrentTimestamp } from '../../utils/Datetime';

const useStyles = makeStyles(listingStyle);

const AdminResources = () => {
  const pageName = 'Resources';
  const classes = useStyles();
  const history = useHistory();

  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reloadRows, setReloadRows] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [resourceDialog, setResourceDialog] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);

  const [actionId, setActionId] = useState(0);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const columns = [
    { name: 'Category', id: 'category', width: '20%' },
    { name: 'Asset', id: 'assets', width: '10%', align: 'center' },
    { name: 'Short Title', id: 'shortTitle', width: '40%' },
    { name: 'Status', id: 'status', width: '10%', align: 'center' },
    { name: 'Display Date', id: 'displaydate', width: '15%', align: 'center' },
  ];

  const getParams = () => {
    let queryString = '1=1';
    if (sortBy) queryString += `&sortBy=${sortBy}`;
    if (orderBy) queryString += `&orderBy=${orderBy}`;
    if (searchText) queryString += `&searchText=${searchText}`;
    return queryString.substring(4);
  };

  useEffect(() => {
    setDataLoaded(false);
    listAll(activePage, getParams())
      .then((res) => {
        setRows(res.data.rows);
        setTotalPages(res.data.totalPages);
        setDataLoaded(true);
      })
      .catch(() => {
        setDataLoaded(true);
      });
  }, [reloadRows, activePage]);

  const searchList = () => {
    setActivePage(1);
    setReloadRows(!reloadRows);
  };

  const sortHandler = (colId) => {
    setActivePage(1);
    setSortBy(colId);
    setOrderBy(orderBy === 'asc' ? 'desc' : 'asc');
    setReloadRows(!reloadRows);
  };

  const refreshList = () => {
    setReloadRows(!reloadRows);
  };

  const resetList = () => {
    setSearchText('');
    setOrderBy('');
    setSortBy('');
    setReloadRows(!reloadRows);
    setActivePage(1);
    history.push(RoutePaths.ADMIN_RESOURCE);
  };

  const handleActions = (row, action) => {
    if (action === 'edit') {
      setActionId(row.id);
      setResourceDialog(true);
    } else if (action === 'delete') {
      setActionId(row.id);
      setDeleteDialog(true);
    } else if (action === 'view') {
      setActionId(row.id);
      setViewDialog(true);
    } else if (action === 'copy-active' || action === 'copy-inactive') {
      const currentUrl = window.location.host;
      const url = `${currentUrl}/resources?category=${encodeURIComponent(row.category)}&id=${
        row.id
      }`;
      setSnackbarMeesage({
        message: '',
        type: '',
        show: false,
      });
      setTimeout(() => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message:
            action === 'copy-active'
              ? `Link copied successfully.`
              : `Link copied successfully. \n The resource is inactive or in a future date. \n The video/image will not be visible until it is active.`,
          type: 'success',
          show: true,
        });
        copy(url);
      }, 300);
    }
  };

  return (
    <PrivateWrapper pageName={pageName}>
      <Box mt={2}>
        <div className={classes.filterToolbar}>
          <TableToolbar
            refreshList={refreshList}
            searchList={searchList}
            getSearchText={(e) => setSearchText(encodeURIComponent(e.target.value))}
            addNew={() => setResourceDialog(true)}
            resetList={resetList}
          />
        </div>
        <TableContainer component={Paper} elevation={0}>
          <Table className={classes.tableData} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((col) => (
                  <TableCell width={col.width} align={col.align || ''}>
                    {!['schedule', 'submission'].includes(col.id) ? (
                      <TableSortLabel
                        active={sortBy === col.id}
                        direction={sortBy === col.id ? orderBy : 'asc'}
                        onClick={() => sortHandler(col.id)}
                        IconComponent={sortBy !== col.id ? ImportExport : ArrowDownward}
                      >
                        <span>{col.name}</span>
                      </TableSortLabel>
                    ) : (
                      col.name
                    )}
                  </TableCell>
                ))}
                <TableCell align="center" width="5%">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!dataLoaded &&
                [1, 2, 3, 4, 5].map((val) => (
                  <TableRow key={`sub-${val}`}>
                    <TableCell component="th" scope="row">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell align="center">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                  </TableRow>
                ))}
              {dataLoaded &&
                (rows.length > 0 ? (
                  rows.map((row) => (
                    <TableRow key={`sub-${row.id}`}>
                      <TableCell>{row.category}</TableCell>
                      <TableCell align="center">{row.type === 0 ? 'Image' : 'Video'}</TableCell>
                      <TableCell>{row.shortTitle}</TableCell>
                      <TableCell align="center">
                        <span
                          className={
                            row.status === 'Active' ? classes.statusActive : classes.statusInactive
                          }
                        >
                          {row.status}
                        </span>
                      </TableCell>
                      <TableCell align="center">{row.displayDate}</TableCell>
                      <TableCell align="right">
                        <ActionMenu row={row} selectAction={handleActions} />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      size="medium"
                      colSpan={6}
                      className={classes.noRecord}
                      style={{ padding: 20, fontSize: 15 }}
                    >
                      No records found
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {dataLoaded && totalPages > 1 && (
          <Pagination
            count={totalPages}
            showFirstButton
            showLastButton
            className={classes.tablePagination}
            onChange={(_, pageNumber) => {
              setActivePage(pageNumber);
              setReloadRows(!reloadRows);
            }}
            page={activePage}
          />
        )}
        {deleteDialog && (
          <ConfirmDialog
            closeModel={() => {
              setDeleteDialog(false);
              setActionId(0);
            }}
            onSuccess={() => {
              setDeleteDialog(false);
              setActionId(0);
              setReloadRows(!reloadRows);
            }}
            deleteId={actionId}
          />
        )}

        {resourceDialog && (
          <FormDialog
            onClose={() => {
              setResourceDialog(false);
              setActionId(0);
            }}
            onSuccess={() => {
              setReloadRows(!reloadRows);
            }}
            editId={actionId}
          />
        )}
        {viewDialog && (
          <ViewDialog
            id={actionId}
            onClose={() => {
              setViewDialog(false);
              setActionId(0);
            }}
          />
        )}
      </Box>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </PrivateWrapper>
  );
};

const ActionMenu = ({ row, selectAction }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (action) => {
    selectAction(row, action);
    handleClose();
  };
  let copyLinkAction = 'copy-inactive';
  if (row.status === 'Active' && row.displayDateTS < getCurrentTimestamp()) {
    copyLinkAction = 'copy-active';
  }
  return (
    <div>
      <Button aria-controls={`simple-menu${row.id}`} aria-haspopup="true" onClick={handleClick}>
        <MoreVert />
      </Button>
      <Menu
        id={`simple-menu${row.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleMenuClick('edit');
          }}
          button
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClick('view');
          }}
          button
        >
          View
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClick(copyLinkAction);
          }}
          button
        >
          Copy Link
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClick('delete');
          }}
          button
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};
ActionMenu.propTypes = {
  row: PropTypes.objectOf().isRequired,
  selectAction: PropTypes.func.isRequired,
};

const SubmissionButton = ({ row, onClick }) => {
  const totalSubmission = row.submission || '∞';
  const totalSubmitted = row.submitted;

  return (
    <Button disableElevation onClick={() => onClick(row.id)}>
      {totalSubmitted}/{totalSubmission}
    </Button>
  );
};

SubmissionButton.propTypes = {
  row: PropTypes.objectOf().isRequired,
  onClick: PropTypes.objectOf().isRequired,
};
export default AdminResources;
