import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import { useForm, Controller } from 'react-hook-form';
import SnackbarMessage from '../SnackbarMessage';
import formInput from '../../theme/styles/FormInput';
import Validations, { MaxLengthValidationFunc } from '../../utils/Validations';
import { inviteTeamMember } from '../../services/Agent';

const useStyles = makeStyles(formInput);

const InviteTeamMember = ({ closeModel, onSuccess, agentId, rowAgentType }) => {
  const classes = useStyles();

  const { handleSubmit, control } = useForm();

  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleDialog = () => {
    closeModel();
  };

  const submitForm = ({ firstName, lastName, email, phone }) => {
    setProcessing(true);
    setSnackbarMeesage({
      ...snackbarMeesage,
      show: false,
    });

    const payload = {
      firstName,
      lastName,
      email,
      phone,
      agentType: Number(rowAgentType),
    };
    inviteTeamMember(agentId, payload)
      .then(() => {
        onSuccess();
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
        setProcessing(false);
      });
  };

  return (
    <>
      <Dialog open fullWidth maxWidth="md">
        <DialogTitle>Invite New Team Member</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form id="add-agent" onSubmit={handleSubmit(submitForm)}>
            <Grid container>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  rules={{ ...Validations.REQUIRED, ...MaxLengthValidationFunc(50) }}
                  name="firstName"
                  id="firstName"
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      variant="outlined"
                      label="First Name*"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      className={classes.formInput}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  rules={{ ...Validations.REQUIRED, ...MaxLengthValidationFunc(50) }}
                  name="lastName"
                  id="lastName"
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      variant="outlined"
                      label="Last Name*"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      className={classes.formInput}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  rules={{ ...Validations.REQUIRED_EMAIL, ...MaxLengthValidationFunc(250) }}
                  name="email"
                  id="email"
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      variant="outlined"
                      label="Email*"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      type="email"
                      className={classes.formInput}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  rules={
                    agentId
                      ? {
                          ...MaxLengthValidationFunc(14),
                          ...{
                            pattern: {
                              value: /^\(\d{3}\)\s\d{3}-\d{4}$/,
                              message: 'Invalid value',
                            },
                          },
                        }
                      : {
                          ...Validations.REQUIRED,
                          ...MaxLengthValidationFunc(14),
                          ...{
                            pattern: {
                              value: /^\(\d{3}\)\s\d{3}-\d{4}$/,
                              message: 'Invalid value',
                            },
                          },
                        }
                  }
                  name="phone"
                  id="phone"
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <InputMask
                      mask="(999) 999-9999"
                      value={value}
                      maskChar="_"
                      alwaysShowMask
                      onChange={onChange}
                    >
                      {() => (
                        <TextField
                          label={agentId ? 'Phone Number' : 'Phone Number*'}
                          variant="outlined"
                          className={classes.formInput}
                          error={!!error}
                          helperText={error ? error.message : null}
                          fullWidth
                        />
                      )}
                    </InputMask>
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            type="submit"
            endIcon={processing && <CircularProgress size={25} />}
            disabled={processing}
            form="add-agent"
          >
            Send Invite
          </Button>
          <Button variant="contained" onClick={handleDialog} disableElevation>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </>
  );
};

InviteTeamMember.propTypes = {
  closeModel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  agentId: PropTypes.number.isRequired,
  rowAgentType: PropTypes.number.isRequired,
};

export default InviteTeamMember;
