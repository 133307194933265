import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import SnackbarMessage from '../SnackbarMessage';
import formInput from '../../theme/styles/FormInput';
import { addNewTeam, editTeam, viewTeamById } from '../../services/Team';
import TeamRoles from '../../config/TeamRoles';
import Validations, { MaxLengthValidationFunc } from '../../utils/Validations';
import ProfileUploader from '../ProfileUploader';
import UserTypes from '../../config/UserTypes';

const useStyles = makeStyles(formInput);

const FormDialog = ({ open, editId, closeModel, title, onSuccess }) => {
  const classes = useStyles();

  const [processing, setProcessing] = useState(false);
  const [userProfile, setUserProfile] = useState('');
  const [uploading, setUploading] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const { handleSubmit, control, setValue, reset, getValues } = useForm();
  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;

  const IS_SALES_MANAGER_LOGIN = userType === UserTypes.SALES_MANAGER.value;

  let teamRoles =
    IS_SALES_MANAGER_LOGIN && !editId
      ? TeamRoles.filter((r) => r.key === 'SALES_PERSON')
      : TeamRoles;

  if (!IS_SALES_MANAGER_LOGIN) {
    teamRoles =
      userType === UserTypes.SUPER_ADMIN.value
        ? TeamRoles
        : TeamRoles.filter((x) => x.key !== 'ADMIN');
  }

  const computeDefaultType = () => {
    switch (userType) {
      case UserTypes.SUPER_ADMIN.value:
        return UserTypes.ADMIN.value;
      case UserTypes.ADMIN.value:
      case UserTypes.SALES_MANAGER.value:
        return UserTypes.SALES_PERSON.value;
      default:
        return 0;
    }
  };

  useEffect(() => {
    if (editId) {
      viewTeamById(editId).then((res) => {
        setValue('firstName', res.data.first_name);
        setValue('lastName', res.data.last_name);
        setValue('status', res.data.status);
        setValue('email', res.data.email);
        setValue('phone', res.data.phone);
        setValue('location', res.data.location);
        setValue('bioText', res.data.bio_text);
        setValue('type', res.data.type);
        setUserProfile(res.data.profilePic);
      });
    } else {
      setValue('status', 1);
      setValue('type', computeDefaultType());
    }
  }, []);

  const handleDialog = () => {
    reset();
    closeModel();
  };

  const submitForm = ({
    firstName,
    lastName,
    type,
    phone,
    location,
    bioText,
    status,
    email,
    sendInvitation,
  }) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    setProcessing(true);
    const payload = {
      firstName,
      lastName,
      type: Number(type),
      phone,
      location,
      bioText,
      profilePic: userProfile,
      status: Number(status),
    };

    if (editId) {
      editTeam(editId, payload)
        .then(() => {
          setProcessing(false);
          handleDialog();
          onSuccess();
        })
        .catch(({ response }) => {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.data.message,
            type: 'error',
            show: true,
          });
          setProcessing(false);
        });
    } else {
      payload.email = email;
      payload.sendInvitation = sendInvitation;
      addNewTeam(payload)
        .then((res) => {
          if (sendInvitation) {
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: res.data.invitationMessage,
              type: res.data.invitationStatus ? 'success' : 'error',
              show: true,
            });
          }

          setTimeout(
            () => {
              setProcessing(false);
              handleDialog();
              onSuccess();
            },
            sendInvitation ? 3000 : 0
          );
        })
        .catch(({ response }) => {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.data.message,
            type: 'error',
            show: true,
          });
          setProcessing(false);
        });
    }
  };

  const defaultFormValues = getValues();

  return (
    <Dialog open={open} onClose={handleDialog} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form onSubmit={handleSubmit(submitForm)} id="agent-form">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box mb={2}>
                <ProfileUploader
                  profilePic={userProfile}
                  onSuccess={(url) => {
                    setUserProfile(url);
                  }}
                  setUploading={setUploading}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                rules={{ ...Validations.REQUIRED, ...MaxLengthValidationFunc(50) }}
                name="firstName"
                id="firstName"
                defaultValue={defaultFormValues.firstName || ''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    variant="outlined"
                    label="First Name*"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={classes.formInput}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                rules={{ ...Validations.REQUIRED, ...MaxLengthValidationFunc(50) }}
                name="lastName"
                id="lastName"
                defaultValue={defaultFormValues.lastName || ''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    variant="outlined"
                    label="Last Name*"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={classes.formInput}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                rules={{ ...Validations.REQUIRED_EMAIL, ...MaxLengthValidationFunc(250) }}
                name="email"
                id="email"
                defaultValue={defaultFormValues.email || ''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    variant="outlined"
                    label="Email*"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    type="email"
                    className={classes.formInput}
                    disabled={editId ? 'disabled' : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                rules={{
                  ...MaxLengthValidationFunc(20),
                  ...{ pattern: { value: /^[0-9 )(+-]+$/, message: 'Invalid value' } },
                }}
                name="phone"
                id="phone"
                defaultValue={defaultFormValues.phone || ''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    variant="outlined"
                    label="Phone Number"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={classes.formInput}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                rules={Validations.REQUIRED}
                name="status"
                id="status"
                defaultValue={defaultFormValues.name || 1}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    variant="outlined"
                    label="Status*"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={classes.formInput}
                    SelectProps={{
                      native: true,
                    }}
                    fullWidth
                    select
                  >
                    <option key={1} value={1}>
                      Active
                    </option>
                    <option key={0} value={0}>
                      Inactive
                    </option>
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                rules={Validations.REQUIRED}
                name="type"
                id="type"
                defaultValue={defaultFormValues.type || 0}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    variant="outlined"
                    label="Team Role*"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={classes.formInput}
                    SelectProps={{
                      native: true,
                    }}
                    fullWidth
                    select
                    disabled={IS_SALES_MANAGER_LOGIN}
                  >
                    {teamRoles
                      .filter((x) => x.value !== 1)
                      .map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                rules={MaxLengthValidationFunc(100)}
                name="location"
                id="location"
                defaultValue={defaultFormValues.location || ''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    variant="outlined"
                    label="Location"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={classes.formInput}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="bioText"
                id="bioText"
                defaultValue={defaultFormValues.bioText || ''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    variant="outlined"
                    label="Bio Text"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={classes.formInput}
                    multiline
                    rows={5}
                    fullWidth
                  />
                )}
              />
            </Grid>
            {!editId && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="sendInvitation"
                  id="sendInvitation"
                  defaultValue={false}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      className={classes.formInput}
                      control={<Checkbox checked={value} onChange={onChange} color="primary" />}
                      label="Send Invitation"
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          type="submit"
          form="agent-form"
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing || uploading}
        >
          Submit
        </Button>
        <Button variant="contained" onClick={handleDialog} disableElevation>
          Cancel
        </Button>
      </DialogActions>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  editId: PropTypes.number,
  title: PropTypes.string,
};
FormDialog.defaultProps = {
  editId: 0,
  title: '',
};

export default FormDialog;
