import {
  Box,
  Button,
  CircularProgress,
  Container,
  Link,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { viewAgentById } from '../../services/Agent';
import odisPreviewImage from '../../assets/images/odisPreview.png';
import odisStyle from '../../theme/styles/OdisProfile';
import { submitOdisImage } from '../../services/Odis';
import SnackbarMessage from '../SnackbarMessage';
import { trackUserClick } from '../../utils/Activity';

const useStyles = makeStyles(odisStyle);

const OdisProfile = ({ agentId, from }) => {
  const classes = useStyles();
  const getDetails = window.localStorage.getItem('userDetail');
  const userId = agentId || (getDetails ? Number(JSON.parse(getDetails).id) : 0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [odisImage, setOdisImage] = useState('');
  const [userProfileImage, setUserProfileImage] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [previewType, setPreviewType] = useState(1);
  const [refreshOdis, setRefreshOdis] = useState(0);
  const [odisStatus, setOdisStatus] = useState(null);
  const [odisLiveProfile, setOdisLiveProfile] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  useEffect(() => {
    setDataLoaded(false);
    viewAgentById(userId)
      .then((res) => {
        setOdisImage(res.data.adImage);
        setOdisStatus(res.data.agentOdisStatus);
        setOdisLiveProfile(res.data.liveOdisImage || null);
        setPreviewImage(res.data.adImage);
        if (res.data.agentOdisStatus === 'APPROVED') {
          setPreviewType(2);
        } else {
          setPreviewType(1);
        }
        setUserProfileImage(res.data.profilePic);
        setDataLoaded(true);
      })
      .catch(() => {
        setOdisImage('');
        setPreviewImage('');
        setPreviewType(1);
        setDataLoaded(true);
      });
  }, [refreshOdis]);

  const handleActions = (e) => {
    const { value } = e.target;

    setPreviewType(value);
    if (value === 1) {
      setPreviewImage(odisImage);
    } else if (value === 2) {
      setPreviewImage(odisLiveProfile);
    }
  };

  const submitOdis = async () => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    setProcessing(true);
    submitOdisImage(userId)
      .then(() => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'The ODIS profile for the agent is approved and synced to Reveel.',
          type: 'success',
          show: true,
        });
        setProcessing(false);
        setRefreshOdis(refreshOdis + 1);
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
        setProcessing(false);
      });
  };

  return (
    <Container>
      {!dataLoaded && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {dataLoaded && odisImage && (
        <Box display="flex" justifyContent="center" flexDirection="column">
          <Box display="flex" justifyContent="space-between" flexDirection="row" mb={1.5}>
            <Box display="flex" alignItems="center">
              {odisStatus !== 'APPROVED' && (
                <>
                  <Typography variant="h6" className={classes.mr10}>
                    Preview
                  </Typography>
                  <Select
                    id="preview"
                    value={previewType}
                    onChange={handleActions}
                    variant="outlined"
                    className={classes.previewSelection}
                  >
                    {odisStatus !== 'APPROVED' && <MenuItem value={1}>Pending ODIS</MenuItem>}
                    {odisLiveProfile !== null && <MenuItem value={2}>Live ODIS</MenuItem>}
                  </Select>
                </>
              )}
              {odisStatus === 'APPROVED' && (
                <>
                  <Typography variant="h6" className={classes.mr10}>
                    Live ODIS Preview
                  </Typography>
                </>
              )}
            </Box>
            <Box display="flex" alignItems="center">
              {from === 'admin' && previewType === 1 && odisStatus === 'PENDING' && (
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className={classes.mr10}
                  onClick={submitOdis}
                  disabled={processing}
                >
                  Approve Request
                </Button>
              )}
              <Link onClick={() => trackUserClick(28)} href={previewImage} target="_blank">
                Download
              </Link>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" className={classes.container}>
            <img src={odisPreviewImage} alt="" className={classes.odisImg} />
            <div className={classes.odisImgContainer}>
              <img src={previewImage} alt="" />
            </div>
          </Box>
        </Box>
      )}
      {dataLoaded && !odisImage && userProfileImage && (
        <Box display="flex" justifyContent="center">
          <Typography>Currently you don&apos;t have any ODIS profile.</Typography>
        </Box>
      )}
      {dataLoaded && !odisImage && !userProfileImage && (
        <Box display="flex" justifyContent="center">
          <Typography>Upload profile image to generate ODIS profile.</Typography>
        </Box>
      )}

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Container>
  );
};

OdisProfile.propTypes = {
  agentId: PropTypes.number,
  from: PropTypes.string,
};
OdisProfile.defaultProps = {
  agentId: null,
  from: 'agent',
};

export default OdisProfile;
