/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import { Link, TableSortLabel, CircularProgress } from '@material-ui/core';
import { ArrowDownward, ImportExport } from '@material-ui/icons';
import TableToolbar from '../../components/TableToolbar';
import listingStyle from '../../theme/styles/TableListing';
import PrivateWrapper from '../../layout/Private';
import { AssetsToolTip, NuggetIndicator } from '../agents/Agents';
import UserTypes from '../../config/UserTypes';
import AgentWebStatus from '../../components/agent/AgentWebStatus';
import NuggetsDialog from '../../components/nuggets/NuggetsDialog';
import OdisProfileModel from '../../components/agent/OdisProfileModel';
import { pendingApprovalList } from '../../services/Agent';
import SnackbarMessage from '../../components/SnackbarMessage';
import { getAgentTypeLabel } from '../../utils/GetModuleType';
import { agentTypeValues } from '../../config/DataValues';
import AdVideosIndicator from '../../components/agent-ad-videos/AdVideosIndicator';
import AdVideosDialog from '../../components/agent-ad-videos/AdVideosDialog';

const useStyles = makeStyles(listingStyle);

const ExpandableTableRow = ({
  children,
  leaderRow,
  handleActions,
  handleRowActions,
  searchText,
}) => {
  const { id, hasTeamProfile, matchedSubProfile } = leaderRow;
  let expandedFlag = false;
  if (matchedSubProfile === 'Yes') {
    expandedFlag = true;
  }
  const [isExpanded, setIsExpanded] = useState(expandedFlag);
  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setDataLoaded(false);
    setDataLoaded(false);
    if (isExpanded) {
      pendingApprovalList(1, `searchText=${searchText}&leaderId=${id}`)
        .then((res) => {
          setRows(res.data.rows);
          setDataLoaded(true);
        })
        .catch(() => {
          setDataLoaded(true);
        });
    } else {
      setRows([]);
    }
  }, [isExpanded]);
  return (
    <>
      <TableRow>
        <TableCell padding="normal" size="small">
          {!!hasTeamProfile && (
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? (
                <KeyboardArrowDownIcon fontSize="small" />
              ) : (
                <ChevronRightIcon fontSize="small" />
              )}
            </IconButton>
          )}
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && !dataLoaded && (
        <TableRow>
          <TableCell align="center" size="medium" colSpan={10}>
            <div className={classes.loader}>
              <CircularProgress color="inherit" />
            </div>
          </TableCell>
        </TableRow>
      )}
      {isExpanded &&
        dataLoaded &&
        (rows.length > 0 ? (
          rows.map((row) => (
            <TableRow
              style={{
                backgroundColor: row.matchedRow === 'Yes' ? '#e8f5e9' : '',
              }}
            >
              <TableCell
                padding="normal"
                size="small"
                style={{ textAlign: 'right', backgroundClip: 'padding-box' }}
              >
                <SubdirectoryArrowRightIcon fontSize="small" />
              </TableCell>
              <DataRow
                isMember={1}
                row={row}
                handleActions={handleActions}
                handleRowActions={handleRowActions}
              />
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell
              align="center"
              size="medium"
              colSpan={10}
              className={classes.noRecord}
              style={{ padding: 20, fontSize: 15 }}
            >
              No data found.
            </TableCell>
          </TableRow>
        ))}
    </>
  );
};

ExpandableTableRow.propTypes = {
  children: PropTypes.objectOf().isRequired,
  leaderRow: PropTypes.object.isRequired,
  handleActions: PropTypes.func.isRequired,
  handleRowActions: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
};

const DataRow = ({ row, handleRowActions }) => {
  const classes = useStyles();

  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;
  const IS_SA_OR_ADMIN = [UserTypes.ADMIN.value, UserTypes.SUPER_ADMIN.value].includes(userType);

  const calculateAssets = (agentData) => {
    const finalAssets = [];

    if (agentData.firstName || agentData.lastName) {
      finalAssets.push('Name');
    }
    if (agentData.email) {
      finalAssets.push('Email');
    }
    if (agentData.phone) {
      finalAssets.push('Phone');
    }
    if (agentData.bio) {
      finalAssets.push('Bio');
    }
    if (agentData.profilePic) {
      finalAssets.push('Picture');
    }
    if (agentData.assetId) {
      finalAssets.push('Video');
    }
    if (agentData.location) {
      finalAssets.push('Location');
    }

    return finalAssets;
  };

  return (
    <>
      <TableCell>{getAgentTypeLabel(row.agentType, false, true)}</TableCell>
      <TableCell>{row.name}</TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell align="center">
        <AssetsToolTip assets={calculateAssets(row)} agentId={row.id} />
      </TableCell>
      <TableCell align="center">
        {row.agentType === agentTypeValues.AGENTS && (
          <NuggetIndicator
            nuggets={row.nuggets || []}
            onClick={() => {
              if (IS_SA_OR_ADMIN) {
                handleRowActions(row, 'nugget-videos');
              }
            }}
            hasAccess={IS_SA_OR_ADMIN}
          />
        )}
        {row.agentType !== agentTypeValues.AGENTS && '-'}
      </TableCell>
      <TableCell align="center">
        {row.agentType === agentTypeValues.LENDERS && (
          <NuggetIndicator
            nuggets={row.nuggets || []}
            onClick={() => {
              if (IS_SA_OR_ADMIN) {
                handleRowActions(row, 'nugget-videos');
              }
            }}
            hasAccess={IS_SA_OR_ADMIN}
          />
        )}
        {row.agentType !== agentTypeValues.LENDERS && '-'}
      </TableCell>
      <TableCell align="center">
        {row.agentType === agentTypeValues.AGENTS && (
          <AdVideosIndicator
            adVideos={row.adVideos || []}
            onClick={() => {
              if (IS_SA_OR_ADMIN) {
                handleRowActions(row, 'ad-videos');
              }
            }}
            hasAccess={IS_SA_OR_ADMIN}
          />
        )}
        {row.agentType !== agentTypeValues.AGENTS && '-'}
      </TableCell>
      <TableCell align="center">
        {row.agentOdisStatus !== 'N/A' && row.agentType === agentTypeValues.AGENTS ? (
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleRowActions(row, 'odis-status');
            }}
            className={classes.contractStatus}
            target="_blank"
          >
            {row.agentOdisStatus}
          </Link>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell align="center">
        {row.webStatus !== '-' ? (
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleRowActions(row, 'web-status');
            }}
            className={classes.contractStatus}
            target="_blank"
          >
            {row.webStatus}
          </Link>
        ) : (
          row.webStatus
        )}
      </TableCell>
    </>
  );
};

DataRow.propTypes = {
  row: PropTypes.objectOf().isRequired,
  handleRowActions: PropTypes.func.isRequired,
};

const AgentsAdApproval = () => {
  const pageName = 'Approval / Ads';
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [nuggetsForAgent, setNuggetsForAgent] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reloadRows, setReloadRows] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const [searchText, setSearchText] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const [manageOdisModel, setManageOdisModel] = useState(false);
  const [manageOdisData, setManageOdisData] = useState(null);
  const [manageWebStatusModel, setManageWebStatusModel] = useState(false);
  const [manageWebStatusData, setManageWebStatusData] = useState(null);
  const [nuggetModal, setNuggetModal] = useState(false);
  const [selectedAgentType, setSelectedAgentType] = useState(null);

  const [adVideoForAgent, setAdVideoForAgent] = useState(null);
  const [adVideoModal, setAdVideoModal] = useState(false);

  const columns = [
    { name: 'Type', id: 'agentType', width: '10%' },
    { name: 'Name', id: 'name', width: '15%' },
    { name: 'Email', id: 'email', width: '15%' },
    { name: 'Assets', id: 'assets', width: '10%', align: 'center' },
    { name: 'Nuggets', id: 'nuggets', width: '10%', align: 'center' },
    { name: 'Lender Minutes', id: 'nuggets', width: '10%', align: 'center' },
    { name: 'Show Intro', id: 'adVideos', width: '10%', align: 'center' },
    { name: 'ODIS Status', id: 'agentOdisStatus', width: '10%', align: 'center' },
    { name: 'Web Status', id: 'webStatus', width: '10%', align: 'center' },
  ];

  const refreshList = () => {
    setReloadRows(!reloadRows);
  };

  const searchList = () => {
    setActivePage(1);
    setReloadRows(!reloadRows);
  };

  const sortHandler = (colId) => {
    setActivePage(1);
    setSortBy(colId);
    setOrderBy(orderBy === 'asc' ? 'desc' : 'asc');
    setReloadRows(!reloadRows);
  };

  const resetList = () => {
    setSearchText('');
    setOrderBy('');
    setSortBy('');
    setReloadRows(!reloadRows);
    setActivePage(1);
  };

  const openNuggetsForAgent = (id, type) => {
    setSelectedAgentType(type);
    setNuggetsForAgent(id);
    setNuggetModal(true);
  };

  const openAdVideosForAgent = (id, type) => {
    setSelectedAgentType(type);
    setAdVideoForAgent(id);
    setAdVideoModal(true);
  };

  const closeAdVideoDialog = () => {
    setAdVideoForAgent(null);
    setAdVideoModal(false);
    setReloadRows(!reloadRows);
  };

  const handleRowActions = (row, action) => {
    if (action === 'web-status') {
      setManageWebStatusData(row);
      setManageWebStatusModel(true);
    } else if (action === 'odis-status') {
      setManageOdisModel(true);
      setManageOdisData(row.id);
    } else if (action === 'ad-videos') {
      openAdVideosForAgent(row.id);
    } else if (action === 'nugget-videos') {
      openNuggetsForAgent(row.id);
    }
  };

  const successWebStatusModal = () => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      show: false,
    });
    setManageWebStatusModel(false);
    setManageWebStatusData(null);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: 'Web status updated successfully.',
      type: 'success',
      show: true,
    });
    setReloadRows(!reloadRows);
  };
  const closeWebStatusModal = () => {
    setManageWebStatusModel(false);
    setManageWebStatusData(null);
  };
  const closeNuggetDialog = () => {
    setNuggetsForAgent(null);
    setNuggetModal(false);
    setReloadRows(!reloadRows);
  };
  const getParams = () => {
    let queryString = '1=1';
    if (sortBy) queryString += `&sortBy=${sortBy}`;
    if (orderBy) queryString += `&orderBy=${orderBy}`;
    if (searchText) queryString += `&searchText=${searchText}`;
    return queryString.substring(4);
  };

  useEffect(() => {
    setDataLoaded(false);
    pendingApprovalList(activePage, getParams())
      .then((res) => {
        setRows(res.data.rows);
        setTotalPages(res.data.totalPages);
        setDataLoaded(true);
      })
      .catch(() => {
        setDataLoaded(true);
      });
  }, [reloadRows, activePage]);

  return (
    <PrivateWrapper pageName={pageName}>
      <>
        <div className={classes.filterToolbar}>
          <TableToolbar
            addNew={() => true}
            refreshList={refreshList}
            searchList={searchList}
            getSearchText={(e) => setSearchText(encodeURIComponent(e.target.value))}
            showAddOption={false}
            resetList={resetList}
            inputSearch={decodeURIComponent(searchText)}
          />
        </div>
        <TableContainer component={Paper}>
          <Table className={classes.tableData} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell padding="normal" sizze="small" />
                {columns.map((col) => (
                  <TableCell width={col.width} align={col.align || 'left'}>
                    {!['assets', 'nuggets', 'adVideos'].includes(col.id) ? (
                      <TableSortLabel
                        active={sortBy === col.id}
                        direction={sortBy === col.id ? orderBy : 'asc'}
                        onClick={() => sortHandler(col.id)}
                        IconComponent={sortBy !== col.id ? ImportExport : ArrowDownward}
                      >
                        <span>{col.name}</span>
                      </TableSortLabel>
                    ) : (
                      col.name
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!dataLoaded &&
                [1, 2, 3, 4, 5].map((val) => (
                  <TableRow key={`ag-${val}`}>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                  </TableRow>
                ))}
              {dataLoaded &&
                (rows.length > 0 ? (
                  rows.map((row) => (
                    <ExpandableTableRow
                      key={`ag-${row.id}`}
                      leaderRow={row}
                      handleRowActions={handleRowActions}
                      searchText={searchText}
                    >
                      <DataRow isMember={0} row={row} handleRowActions={handleRowActions} />
                    </ExpandableTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      size="medium"
                      colSpan={10}
                      className={classes.noRecord}
                      style={{ padding: 20, fontSize: 15 }}
                    >
                      No records found
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {dataLoaded && totalPages > 1 && (
          <Pagination
            count={totalPages}
            showFirstButton
            showLastButton
            className={classes.tablePagination}
            onChange={(_, pageNumber) => {
              setActivePage(pageNumber);
              setReloadRows(!reloadRows);
            }}
            page={activePage}
          />
        )}
        {manageOdisModel && (
          <OdisProfileModel
            onClose={() => {
              setManageOdisData(null);
              setManageOdisModel(false);
              refreshList();
            }}
            agentId={manageOdisData}
          />
        )}
        {manageWebStatusModel && (
          <AgentWebStatus
            webUrl={manageWebStatusData.webUrl}
            webStatus={manageWebStatusData.webStatus}
            successCallback={successWebStatusModal}
            cancelCallback={closeWebStatusModal}
            agentId={manageWebStatusData.id}
            rowAgentType={manageWebStatusData.agentType}
          />
        )}
        {nuggetModal && (
          <NuggetsDialog
            handleClose={closeNuggetDialog}
            agentId={nuggetsForAgent}
            rowAgentType={selectedAgentType}
          />
        )}
        {adVideoModal && (
          <AdVideosDialog
            handleClose={closeAdVideoDialog}
            agentId={adVideoForAgent}
            rowAgentType={selectedAgentType}
          />
        )}
        {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      </>
    </PrivateWrapper>
  );
};

export default AgentsAdApproval;
