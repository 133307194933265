/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Paper } from '@material-ui/core';
import AgentUpdateProfileForm from './AgentUpdateProfileForm';
import InquiriesListing from '../inquiries/InquiriesListing';
import { trackUserClick } from '../../utils/Activity';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function SimpleTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    let objId = 0;
    if (value === 0) {
      objId = 20;
    } else if (value === 1) {
      objId = 58;
    }
    if (objId) {
      trackUserClick(objId);
    }
  }, [value]);

  return (
    <div>
      <Paper elevation={0}>
        <Tabs value={value} onChange={handleChange} indicatorColor="primary">
          <Tab label="Edit Profile" {...a11yProps(0)} />
          <Tab label="Contact Form" {...a11yProps(1)} />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <AgentUpdateProfileForm />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <InquiriesListing />
      </TabPanel>
    </div>
  );
}
