import { getApiCall, postApiCall } from '../utils/Api';

const listAgentCards = async () => {
  const result = await getApiCall(`/billing/list`);
  return result.data;
};

export const makeDefaultCard = async (data) => {
  const result = await postApiCall(`/billing/mark-default-card`, data);
  return result.data;
};

export const removeCard = async (data) => {
  const result = await postApiCall(`/billing/remove-card`, data);
  return result.data;
};

export const updateAgentCard = async (data) => {
  const result = await postApiCall(`/billing/update-card`, data);
  return result.data;
};

/* export const sendAddCardNotification = async () => {
  const result = await getApiCall(`/billing/notification`);
  return result.data;
}; */

export default listAgentCards;
