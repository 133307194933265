import React from 'react';

const ZoneMap = () => (
  <div>
    <iframe
      title="Zone Map"
      width="1470"
      height="760"
      src="https://datastudio.google.com/embed/reporting/7f3f7b66-9598-415c-9559-c556128ac471/page/p_fevfdsbswc"
      frameBorder="0"
      style={{ border: '0' }}
      allowFullScreen
    />
  </div>
);

export default ZoneMap;
