import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
// import ListFastTracks from './ListFastTracks';
// import AddNewFastTrack from './AddNewFastTrack';
import ComingSoon from '../ComingSoon';

const ManageFastTracks = () => (
  <Grid container spacing={3}>
    <Grid item xs={12} sm={12}>
      <Box mt={30} display="flex" justifyContent="center">
        <ComingSoon />
      </Box>
    </Grid>
    {/* <Grid item xs={12} sm={6}>
      <ListFastTracks />
    </Grid> */}
  </Grid>
);

export default ManageFastTracks;
