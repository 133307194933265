/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Typography,
  Container,
  Stepper,
  Step,
  StepLabel,
  makeStyles,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Payment from '../../components/contract/Payment';
import Menubar from '../../components/Menubar';
import { viewByToken } from '../../services/Contract';
import wrapperStyle from '../../theme/styles/LayoutWrapper';
import contractStyle from '../../theme/styles/Contract';
import formInput from '../../theme/styles/FormInput';
import { contractStatusValue } from '../../config/DataValues';
import { viewAgentById } from '../../services/Agent';
import RoutePaths from '../../config/Routes';
import CreateAccountForm from '../../components/auth/CreateAccountForm';
import AmendmentPayment from '../../components/contract/AmendmentPayment';

const useStyles = makeStyles(wrapperStyle);
const useStyles2 = makeStyles(contractStyle);
const useStyles3 = makeStyles(formInput);

const Contract = () => {
  const pageName = 'Contract';
  const classes = useStyles();
  const classes2 = useStyles2();

  const { token } = useParams();

  const [status, setStatus] = useState(null);
  const [finalAmount, setFinalAmount] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [embedUrl, setEmbedUrl] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [agentFirstName, setAgentFirstName] = useState('');
  const [agentLastName, setAgentLastName] = useState('');
  const [agentEmail, setAgentEmail] = useState('');
  const [agentId, setAgentId] = useState(null);
  const [invoicePdf, setInvoicePdf] = useState(null);
  const [contractPdf, setContractPdf] = useState(null);
  const [refersh, setRefersh] = useState(false);
  const [isSetupAccount, setIsSetupAccount] = useState();
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(0);

  const [isAmendment, setIsAmendment] = useState(false);
  const [finalAmendmentAmount, setFinalAmendmentAmount] = useState(null);
  const [doPayForAmendment, setDoPayForAmendment] = useState(null);
  const [stripeCredit, setStripeCredit] = useState(null);
  const [unusedMoney, setUnusedMoney] = useState(null);
  const [pendingCancelationDetails, setPendingCancelationDetails] = useState({
    zones: [],
    discount: 0,
  });

  const labels = ['Sign the Contract', 'Payment', 'Setup Profile'];

  const refershPage = () => {
    setRefersh(!refersh);
    setActiveStep(2);
  };

  const contractStatusSimilarToCancelled = [
    contractStatusValue.CANCELLED,
    contractStatusValue.EXPIRED,
  ];

  useEffect(() => {
    viewByToken(token)
      .then((res) => {
        if (res.success) {
          const { data } = res;
          setIsAmendment(data.isAmendment || false);
          setStatus(data.status);
          setSubscriptionStartDate(data.subscriptionStartDate || 0);
          if (data.status === contractStatusValue.SIGNED) setActiveStep(1);
          else if (
            data.status === contractStatusValue.COMPLETED ||
            data.status === contractStatusValue.PAID
          ) {
            setActiveStep(2);
          }
          if (!contractStatusSimilarToCancelled.includes(data.status)) {
            setEmbedUrl(data.embeddedUrl);
            setFinalAmount(data.finalAmount);
            setSubscriptionType(Number(data.subscriptionType));
            setInvoicePdf(data.invoicePdf || null);
            setContractPdf(data.contractPdf || null);
          }
          setFinalAmendmentAmount(data.amendmentDetails?.finalAmendmentAmount || null);
          setDoPayForAmendment(data.amendmentDetails?.isUpgraded || null);
          setUnusedMoney(data.amendmentDetails?.unusedMoney);
          setStripeCredit(data.amendmentDetails?.stripeCredit);
          setAgentFirstName(data.firstName);
          setAgentLastName(data.lastName);
          setAgentEmail(data.email);
          setAgentId(data.agentId);
          setPendingCancelationDetails({
            zones: data?.amendmentDetails?.pendingCancelationZoneNames || [],
            discount: data?.amendmentDetails?.pendingCancelationZoneDiscount || 0,
          });
        }
      })
      .catch(() => {
        setStatus(contractStatusValue.CANCELLED);
      });
  }, [refersh]);

  const handleSetupAccount = async () => {
    try {
      const agentDetails = await viewAgentById(agentId);
      if (agentDetails.data.agentUserID > 0) {
        window.open(`${window.location.origin}${RoutePaths.LOGIN}`, '_blank');
      } else {
        setIsSetupAccount(true);
      }
    } catch (error) {
      // do something
    }
  };

  const closeSetupAccountDialog = () => setIsSetupAccount(false);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <iframe
            title={status}
            src={embedUrl}
            frameBorder="0"
            width="85%"
            className={classes2.iframe}
          />
        );
      case 1:
        return isAmendment ? (
          <AmendmentPayment
            token={token}
            doPayForAmendment={doPayForAmendment}
            finalAmendmentAmount={finalAmendmentAmount}
            subscriptionType={subscriptionType}
            refershPage={refershPage}
            subscriptionStartDate={subscriptionStartDate}
            unusedMoney={unusedMoney}
            stripeCredit={stripeCredit}
            actualAmendmentAmount={finalAmount}
            pendingCancelationDetails={pendingCancelationDetails}
          />
        ) : (
          <Payment
            token={token}
            finalAmount={finalAmount}
            subscriptionType={subscriptionType}
            refershPage={refershPage}
            subscriptionStartDate={subscriptionStartDate}
          />
        );
      case 2:
        return (
          <FinalStepContent
            status={status}
            contractPdf={contractPdf}
            invoicePdf={invoicePdf}
            subscriptionStartDate={subscriptionStartDate}
            handleSetupAccount={handleSetupAccount}
            agentId={agentId}
          />
        );
      default:
        return <Typography variant="h6">Something went wrong.</Typography>;
    }
  }

  return (
    <Container className={classes.wrapper} maxWidth="xl">
      <Menubar
        pageName={pageName}
        isForContractPage
        agentDetails={JSON.stringify({ firstName: agentFirstName, lastName: agentLastName })}
      />
      <div className={classes.contentContract}>
        <div className={classes.toolbar} />

        <Box minHeight="90vh" component={Paper} display="flex" flexDirection="column">
          {contractStatusSimilarToCancelled.includes(status) ? (
            <div className={classes2.failedContractWrapper}>
              {Number(status) === contractStatusValue.CANCELLED && (
                <Typography variant="h6">
                  Unfortunately your contract has been cancelled. Please contact your sales person
                  for further assistance.
                </Typography>
              )}
              {Number(status) === contractStatusValue.EXPIRED && (
                <Typography variant="h6">
                  Unfortunately your contract has expired. Please contact your sales person for
                  further assistance.
                </Typography>
              )}
            </div>
          ) : (
            <>
              <Stepper activeStep={activeStep} className={classes2.stepper} alternativeLabel>
                {labels.map((label, index) => {
                  const labelProps = {};

                  if (activeStep === -1) {
                    labelProps.error = true;
                  }

                  return (
                    <Step key={label}>
                      <StepLabel {...labelProps}>
                        {label}
                        <br />
                        {index === 0 && contractPdf && (
                          <a
                            href={contractPdf}
                            target="_blank"
                            className={classes2.linkText}
                            rel="noreferrer"
                          >
                            Download Agreement
                          </a>
                        )}
                        {index === 1 && invoicePdf && !subscriptionStartDate && (
                          <a
                            href={invoicePdf}
                            target="_blank"
                            className={classes2.linkText}
                            rel="noreferrer"
                          >
                            Download Invoice
                          </a>
                        )}
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <div className={classes2.stepperContentWrapper}>{getStepContent(activeStep)}</div>
            </>
          )}
        </Box>
        {isSetupAccount && (
          <AgentAccountSetupDialog
            closeSetupAccountDialog={closeSetupAccountDialog}
            userData={{
              firstName: agentFirstName,
              id: agentId,
              lastName: agentLastName,
              email: agentEmail,
            }}
            isSetupAccount={isSetupAccount}
          />
        )}
      </div>
    </Container>
  );
};

const FinalStepContent = ({ handleSetupAccount, agentId }) => {
  const classes2 = useStyles2();
  const [hasAccess, setHasAccess] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setProcessing(true);
    viewAgentById(agentId).then((res) => {
      if (res.data.agentUserID > 0) {
        setHasAccess(true);
      } else {
        setHasAccess(false);
      }
      setProcessing(false);
    });
  }, [agentId]);

  if (processing) {
    return (
      <>
        <CircularProgress />
      </>
    );
  }

  return (
    <>
      {!hasAccess && (
        <>
          <>
            <Typography variant="h4" className={classes2.mt10}>
              Create Your Account
            </Typography>
            <br />
            <Typography variant="p" align="center" className={classes2.finalMessage}>
              Create your account &amp; password to set up your profile for Negotiators.tv and
              upload your video assets when ready.
              <br />
              <br /> This is also where you will manage your billing, download contracts &amp;
              invoices, and contact support.
            </Typography>
          </>

          <div className={classes2.btnWrapper}>
            <Button
              startIcon={<AccountCircleIcon />}
              onClick={handleSetupAccount}
              variant="contained"
              color="primary"
              disableElevation
              size="large"
            >
              Create account
            </Button>
          </div>
        </>
      )}
      {hasAccess && (
        <>
          <>
            <Typography variant="h4" className={classes2.mt10}>
              Log Back In
            </Typography>
            <br />
            <Typography variant="p" align="center" className={classes2.finalMessage}>
              Log in to your account to view your account changes, set up your profiles, <br />{' '}
              manage your account, or get additional support.
            </Typography>
          </>

          <div className={classes2.btnWrapper}>
            <Button
              onClick={handleSetupAccount}
              variant="contained"
              color="primary"
              disableElevation
              size="large"
            >
              Login
            </Button>
          </div>
        </>
      )}
    </>
  );
};

FinalStepContent.propTypes = {
  handleSetupAccount: PropTypes.func.isRequired,
  agentId: PropTypes.number.isRequired,
};

const AgentAccountSetupDialog = ({ userData, isSetupAccount, closeSetupAccountDialog }) => {
  const classes3 = useStyles3();
  const classes2 = useStyles2();

  return (
    <Dialog open={isSetupAccount} fullWidth maxWidth="md" onClose={closeSetupAccountDialog}>
      <DialogTitle>Setup account</DialogTitle>
      <DialogContent className={`${classes3.dialogContent} ${classes3.dialogContentNoTopPadding}`}>
        <CreateAccountForm isForContractPage userData={userData} />
        <Button
          fullWidth
          variant="contained"
          className={classes2.mt10}
          disableElevation
          onClick={closeSetupAccountDialog}
        >
          Close
        </Button>
      </DialogContent>
    </Dialog>
  );
};

AgentAccountSetupDialog.propTypes = {
  isSetupAccount: PropTypes.bool.isRequired,
  userData: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  closeSetupAccountDialog: PropTypes.func.isRequired,
};

export default Contract;
