import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import { ArrowDropDown } from '@material-ui/icons';
import UserTypes from '../../../config/UserTypes';
import formInput from '../../../theme/styles/FormInput';
import Validations, { MaxLengthValidationFunc } from '../../../utils/Validations';
import SnackbarMessage from '../../SnackbarMessage';
import { create, update, viewById } from '../../../services/ShowSegments';
import { formatSubmissionDate, formatSubmissionDateEOD } from '../../../utils/Datetime';

const useStyles = makeStyles(formInput);

const FormDialog = ({ onClose, editId, onSuccess, viewOnly }) => {
  const classes = useStyles();

  let dialogTitle = 'Add New Call for Submissions';
  if (editId && viewOnly) {
    dialogTitle = 'Call for Submissions Details';
  } else if (editId && !viewOnly) {
    dialogTitle = 'Edit Call for Submissions';
  }

  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;
  const isSuperAdmin = userType === UserTypes.SUPER_ADMIN.value;

  const [closeDateError, setCloseDateError] = useState('');
  const [allowOpenDateChange, setAllowOpenDateChange] = useState(!viewOnly);
  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const { handleSubmit, control, setValue, reset, getValues } = useForm();

  useState(() => {
    setValue('openDate', new Date());
    setValue('status', 'Open');

    if (editId) {
      viewById(editId).then((res) => {
        const { data } = res;
        setValue('title', data.title);
        setValue('specialTheme', data.specialTheme);
        setValue('description', data.description);
        setValue('openDate', new Date((data.openDate + 12 * 60 * 60) * 1000));
        setValue('closeDate', new Date((data.closeDate - 12 * 60 * 60) * 1000));
        setValue('status', data.status);

        if (data.status !== 'Scheduled' && !isSuperAdmin && !viewOnly) {
          setAllowOpenDateChange(false);
        }
      });
    }
  }, []);

  const submitForm = (data) => {
    if (viewOnly) {
      return;
    }

    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    setProcessing(true);

    const payload = {
      title: data.title,
      specialTheme: data.specialTheme,
      description: data.description,
      openDate: data.openDate ? `${formatSubmissionDate(data.openDate)}T00:00:00Z` : null,
      closeDate: data.closeDate ? `${formatSubmissionDateEOD(data.closeDate)}T23:59:00Z` : null,
    };

    if (!editId) {
      create(payload)
        .then(() => {
          setProcessing(false);
          reset();
          onSuccess();
          onClose();
        })
        .catch(() => {
          setProcessing(false);
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: 'Something went wrong, please try again later.',
            type: 'error',
            show: true,
          });
          setProcessing(false);
        });
    } else {
      update(editId, payload)
        .then(() => {
          setProcessing(false);
          reset();
          onSuccess();
          onClose();
        })
        .catch(() => {
          setProcessing(false);
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: 'Something went wrong, please try again later.',
            type: 'error',
            show: true,
          });
          setProcessing(false);
        });
    }
  };

  const defaultFormValues = getValues();

  const validateDatesAndStatus = (o, c) => {
    const secondsInADay = 24 * 60 * 60;
    const openDate = o || getValues('openDate');
    const closeDate = c || getValues('closeDate');
    const today = new Date().getTime() / 1000;
    const compareOpen = new Date(openDate).getTime() / 1000;
    const compareClose = new Date(closeDate).getTime() / 1000;
    const openDateInDays = Math.floor(compareOpen / secondsInADay);
    const closeDateInDays = Math.floor(compareClose / secondsInADay);
    const todayInDays = Math.floor(today / secondsInADay);

    if (compareOpen && compareClose) {
      if (openDateInDays >= closeDateInDays) {
        setCloseDateError(
          'Please choose a Close Date that is at least one day after the Open Date'
        );
      } else {
        setCloseDateError('');

        if (openDateInDays > todayInDays) {
          setValue('status', 'Scheduled', { shouldDirty: true });
        } else if (openDateInDays <= todayInDays && closeDateInDays >= todayInDays) {
          setValue('status', 'Open', { shouldDirty: true });
        } else {
          setValue('status', 'Closed', { shouldDirty: true });
        }
      }
    }
  };

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(submitForm)} id="show-segments-form">
          <Controller
            control={control}
            rules={{ ...Validations.REQUIRED, ...MaxLengthValidationFunc(80) }}
            name="title"
            id="title"
            defaultValue={defaultFormValues.title || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Title*"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
                autoFocus={!viewOnly}
                InputProps={{
                  readOnly: viewOnly,
                }}
              />
            )}
          />
          <Controller
            control={control}
            rules={{ ...MaxLengthValidationFunc(80) }}
            name="specialTheme"
            id="specialTheme"
            defaultValue={defaultFormValues.title || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Special Theme"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
                InputProps={{
                  readOnly: viewOnly,
                }}
              />
            )}
          />
          <Controller
            control={control}
            rules={{ ...MaxLengthValidationFunc(500) }}
            name="description"
            id="description"
            defaultValue={defaultFormValues.description || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Description"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
                multiline
                rows={5}
                InputProps={{
                  readOnly: viewOnly,
                }}
              />
            )}
          />
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="openDate"
            id="openDate"
            defaultValue={defaultFormValues.openDate}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label="Open Date"
                  format="MM/dd/yyyy"
                  autoOk
                  clearable={allowOpenDateChange}
                  onChange={(e) => {
                    onChange(e);
                    validateDatesAndStatus(e, null);
                  }}
                  inputVariant="outlined"
                  error={!!error}
                  helperText={error ? error.message : null}
                  className={classes.formInput}
                  value={value}
                  fullWidth
                  InputProps={{
                    readOnly: !allowOpenDateChange,
                  }}
                  readOnly={!allowOpenDateChange}
                />
              </MuiPickersUtilsProvider>
            )}
          />
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="closeDate"
            id="closeDate"
            defaultValue={defaultFormValues.closeDate || null}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label="Close Date"
                  format="MM/dd/yyyy"
                  autoOk
                  clearable={!viewOnly}
                  onChange={(e) => {
                    onChange(e);
                    validateDatesAndStatus(null, e);
                  }}
                  inputVariant="outlined"
                  error={!!error || closeDateError}
                  helperText={error ? error.message : closeDateError}
                  className={classes.formInput}
                  value={value}
                  fullWidth
                  InputProps={{
                    readOnly: viewOnly,
                  }}
                  readOnly={viewOnly}
                />
              </MuiPickersUtilsProvider>
            )}
          />
          <Controller
            control={control}
            name="status"
            id="status"
            defaultValue={defaultFormValues.status}
            disabled
            readOnly
            render={({ field: { onChange, value } }) => (
              <TextField
                variant="outlined"
                label="Status"
                value={value}
                onChange={onChange}
                className={classes.formInput}
                fullWidth
                disabled
                InputProps={{
                  readOnly: true,
                }}
              />
            )}
          />
          <div style={{ color: 'grey', marginTop: -10, fontSize: 12 }}>
            Status is automatically determined based on Open Date and Close Date
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        {!viewOnly && (
          <Button
            variant="contained"
            color="primary"
            disableElevation
            type="submit"
            endIcon={processing && <CircularProgress size={25} />}
            disabled={processing || closeDateError}
            form="show-segments-form"
          >
            Submit
          </Button>
        )}

        <Button variant="contained" disableElevation onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

const UploadMenu = ({ handleAction, title, slug }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptClick = (action) => {
    handleAction(slug, action);
    handleClose();
  };

  return (
    <Box mb={2}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<ArrowDropDown />}
        fullWidth
        variant="outlined"
      >
        {title}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleOptClick('view')}>View</MenuItem>
        <MenuItem onClick={() => handleOptClick('delete')}>Delete</MenuItem>
      </Menu>
    </Box>
  );
};

UploadMenu.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  handleAction: PropTypes.func.isRequired,
};

FormDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  editId: PropTypes.number,
  viewOnly: PropTypes.bool.isRequired,
};
FormDialog.defaultProps = {
  editId: 0,
};
export default FormDialog;
