import { Box, Button, Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Add, OpenInBrowser } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import overviewPageStyle from '../theme/styles/Overview';
import getCounts from '../services/Overview';
import RoutePaths from '../config/Routes';
import UserTypes from '../config/UserTypes';

const useStyles = makeStyles(overviewPageStyle);

const Overview = () => {
  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;

  const classes = useStyles();
  const [counts, setCounts] = useState({
    agents: '-',
    openZones: '-',
    soldZones: '-',
    paidAgents: '-',
    lenders: '-',
    openLenderZones: '-',
    soldLenderZones: '-',
  });

  useEffect(() => {
    getCounts().then((res) => {
      setCounts(res.data);
    });
  }, []);

  const formatNumber = (str) => str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return (
    <>
      <Container className={classes.overviewContainer}>
        <Grid direction="row" container spacing={10} align="center" justify="center">
          <Grid item xs>
            <Box
              component={Paper}
              p={3}
              textAlign="center"
              className={classes.overviewCard}
              borderRadius={20}
            >
              <Typography className={classes.title1}>Open Zones</Typography>
              <Typography className={classes.title2}>{formatNumber(counts.openZones)}</Typography>
              <Button
                variant="contained"
                color="primary"
                endIcon={<OpenInBrowser />}
                className={classes.cardButton}
                component={Link}
                to={`${RoutePaths.ZONES}?type=open`}
              >
                View Open Zones
              </Button>
            </Box>
          </Grid>
          <Grid item xs>
            <Box
              component={Paper}
              p={3}
              textAlign="center"
              className={classes.overviewCard}
              borderRadius={20}
            >
              <Typography className={classes.title1}>Sold Zones</Typography>
              <Typography className={classes.title2}>{formatNumber(counts.soldZones)}</Typography>
              <Button
                variant="contained"
                color="primary"
                endIcon={<OpenInBrowser />}
                className={classes.cardButton}
                component={Link}
                to={`${RoutePaths.ZONES}?type=sold`}
              >
                View Sold Zones
              </Button>
            </Box>
          </Grid>
          <Grid item xs>
            <Box
              component={Paper}
              p={3}
              textAlign="center"
              className={classes.overviewCard}
              borderRadius={20}
            >
              <Typography className={classes.title1}>Active Agents</Typography>
              <Typography className={classes.title2}>{formatNumber(counts.agents)}</Typography>
              {userType === UserTypes.SUPPORT.value ? (
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<OpenInBrowser />}
                  className={classes.cardButton}
                  component={Link}
                  to={RoutePaths.AGENTS}
                >
                  View Agents
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  className={classes.cardButton}
                  component={Link}
                  to={`${RoutePaths.AGENTS}?addMode=1`}
                >
                  Add new agent
                </Button>
              )}
            </Box>
          </Grid>
          {/* <Grid item xs>
          <Box
            component={Paper}
            p={3}
            textAlign="center"
            className={classes.overviewCard}
            borderRadius={20}
          >
            <Typography className={classes.title1}>Paid Agents</Typography>
            <Typography className={classes.title2}>{formatNumber(counts.paidAgents)}</Typography>
            <Button
              variant="contained"
              color="primary"
              endIcon={<OpenInBrowser />}
              className={classes.cardButton}
              component={Link}
              to={`${RoutePaths.AGENTS}?paidAgents=1`}
            >
              View Agents
            </Button>
          </Box>
        </Grid> */}
        </Grid>
      </Container>
      <Container className={classes.overviewContainer}>
        <Grid direction="row" container spacing={10} align="center" justify="center">
          <Grid item xs>
            <Box
              component={Paper}
              p={3}
              textAlign="center"
              className={classes.overviewCard}
              borderRadius={20}
            >
              <Typography className={classes.title1}>Open Lender Zones</Typography>
              <Typography className={classes.title2}>
                {formatNumber(counts.openLenderZones)}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                endIcon={<OpenInBrowser />}
                className={classes.cardButton}
                component={Link}
                to={`${RoutePaths.LENDER_ZONES}?type=open`}
              >
                View Open Lender Zones
              </Button>
            </Box>
          </Grid>
          <Grid item xs>
            <Box
              component={Paper}
              p={3}
              textAlign="center"
              className={classes.overviewCard}
              borderRadius={20}
            >
              <Typography className={classes.title1}>Sold Lender Zones</Typography>
              <Typography className={classes.title2}>
                {formatNumber(counts.soldLenderZones)}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                endIcon={<OpenInBrowser />}
                className={classes.cardButton}
                component={Link}
                to={`${RoutePaths.LENDER_ZONES}?type=sold`}
              >
                View Sold Lender Zones
              </Button>
            </Box>
          </Grid>
          <Grid item xs>
            <Box
              component={Paper}
              p={3}
              textAlign="center"
              className={classes.overviewCard}
              borderRadius={20}
            >
              <Typography className={classes.title1}>Active Lenders</Typography>
              <Typography className={classes.title2}>{formatNumber(counts.lenders)}</Typography>
              {userType === UserTypes.SUPPORT.value ? (
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<OpenInBrowser />}
                  className={classes.cardButton}
                  component={Link}
                  to={RoutePaths.LENDERS}
                >
                  View Lenders
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  className={classes.cardButton}
                  component={Link}
                  to={`${RoutePaths.LENDERS}?addMode=1`}
                >
                  Add new lender
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Overview;
