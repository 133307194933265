// Style for dashboard module listing
const Style = () => ({
  listNotification: {
    height: '830px',
  },
  cardBackgroundColor: {
    backgroundColor: '#d7d7d7',
  },
  fontColor: {
    color: '#43a047',
  },
  deleteButton: {
    backgroundColor: '#e30000',
    borderRadius: 40,
    height: 60,
    cursor: 'pointer',
  },
  buttonStyle: {
    fontSize: '1.5rem',
    color: '#fff',
    textTransform: 'capitalize',
  },
  notificationIconStyle: {
    fontSize: '5rem',
    color: '#43a047',
  },
  iconList: {
    background: '#fff',
    borderRadius: '50%',
    width: '40%',
    alignSelf: 'center',
    display: 'flex',
  },
});

export default Style;
