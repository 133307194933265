import { getApiCall, postApiCall } from '../utils/Api';
import { zoneTypeValues } from '../config/DataValues';

export const validateSearchZipcodes = async (data, module = zoneTypeValues.AGENTS) => {
  const result = await postApiCall(`/widgets/zipcode-search?module=${module}`, data);
  return result.data;
};

export const validateSearchZipcodesV2 = async (data, module = zoneTypeValues.AGENTS) => {
  const result = await postApiCall(`/widgets/zipcode-search/v2?module=${module}`, data);
  return result.data;
};

export const fetchZipcodes = async (params) => {
  const result = await getApiCall(`/widgets/zipcodes?${params}`);
  return result.data;
};

export const listZipcodeSearch = async (page, params, module = zoneTypeValues.AGENTS) => {
  const result = await getApiCall(
    `/widgets/zipcode-search/list?module=${module}&page=${page}&${params}`
  );
  return result.data;
};

export const viewById = async (id) => {
  const result = await getApiCall(`/widgets/zipcode-search/${id}/view`);
  return result.data;
};

export const toggleWaitlist = async (id, data) => {
  const result = await postApiCall(`/widgets/zipcode-search/${id}/waitlist`, data);
  return result.data;
};
