// Style for table listing
const Style = (theme) => ({
  filterLeft: {
    width: '80%',
    float: 'left',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 5,
    },
  },
  filterRight: {
    width: '20%',
    float: 'left',
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 5,
    },
  },
  searchInput: {
    '& .MuiInputBase-root': {
      marginTop: 0,
    },
    '& .MuiFormLabel-root': {
      top: -20,
    },
  },
  searchFilterBtn: {
    marginLeft: 10,
    boxShadow: 'none',
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    },
    '&:hover': {
      boxShadow: 'none',
    },
  },
  addNewBtn: {
    boxShadow: 'none',
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    },
    '&:hover': {
      boxShadow: 'none',
    },
  },
});

export default Style;
