/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableCell,
  TableRow,
} from '@material-ui/core';
import VideoPlayer from 'react-video-js-player';
import { viewById } from '../../services/ContentEngine';
import { formatESTTimezone } from '../../utils/Datetime';

const ViewDialog = ({ id, onClose }) => {
  const [contentData, setContentData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [schDate, setSchDate] = useState('');

  useEffect(() => {
    setDataLoaded(false);
    viewById(id).then((res) => {
      setContentData(res.data);
      setDataLoaded(true);
      const scheduleDate = new Date(
        new Date(res.data.scheduleDate).setSeconds(
          new Date(res.data.scheduleDate).getSeconds() - 5 * 60 * 60
        )
      )
        .toUTCString()
        .replace('GMT', 'EST');
      setSchDate(scheduleDate);
    });
  }, []);

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>Content Details</DialogTitle>
      <DialogContent>
        {dataLoaded && (
          <Table>
            <TableRow>
              <TableCell>Topic</TableCell>
              <TableCell>{contentData.topic}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>{contentData.statusText}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell width="35%">Social Media Channel</TableCell>
              <TableCell>{contentData.socialMedia}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Caption Text</TableCell>
              <TableCell>{contentData.captionText}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Post Date</TableCell>
              <TableCell>{formatESTTimezone(schDate)}</TableCell>
            </TableRow>
            {contentData.imageURL && (
              <TableRow>
                <TableCell>Image</TableCell>
                <TableCell>
                  {contentData.imageURL && (
                    <img src={contentData.imageURL} alt="" width={300} height={200} />
                  )}
                </TableCell>
              </TableRow>
            )}

            {contentData.videoURL && (
              <TableRow>
                <TableCell>Video</TableCell>
                <TableCell>
                  <VideoPlayer controls src={contentData.videoURL} width="300" />
                </TableCell>
              </TableRow>
            )}
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button disableElevation color="default" variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ViewDialog.propTypes = {
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ViewDialog;
