import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import TokensList from './TokensList';

const StatsBadge = ({ header, statCount }) => (
  <Grid item xs={4}>
    <Box component={Paper} p={2} textAlign="center" borderRadius={10} elevation={6}>
      <Typography style={{ fontWeight: 'bold' }}>{header}</Typography>
      <Typography style={{ fontSize: '2rem', fontWeight: 'bold' }}>{statCount}</Typography>
    </Box>
  </Grid>
);

StatsBadge.propTypes = {
  header: PropTypes.string.isRequired,
  statCount: PropTypes.number.isRequired,
};

const ManageTokens = ({ agentId }) => {
  const [stats, setStats] = useState({
    referralActiveTokens: { header: 'Referral Active Tokens', count: 0 },
    manualActiveTokens: { header: 'Manual Active Tokens', count: 0 },
    totalActiveTokens: { header: 'Total Active Tokens', count: 0 },
  });

  const handleStats = (key, value) => {
    setStats((ps) => ({ ...ps, [key]: { ...ps[key], count: value } }));
  };

  return (
    <Box>
      <Grid direction="row" container spacing={2} align="center">
        {Object.keys(stats).map((k) => (
          <StatsBadge header={stats[k].header} statCount={stats[k].count} />
        ))}
      </Grid>
      <TokensList agentId={agentId} setStats={handleStats} withStats />
      <TokensList agentId={agentId} setStats={handleStats} forInactive />
    </Box>
  );
};

ManageTokens.propTypes = {
  agentId: PropTypes.number.isRequired,
};

export default ManageTokens;
