import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableCell,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { paymentFrequencies } from '../../config/DataValues';
import { getAgentPastContracts, copyContractData } from '../../services/Agent';

const AgentPastContracts = ({ handleClose, agentId, setContractData }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [pastContracts, setPastContracts] = useState([]);
  const [contractId, setContractId] = useState(0);
  const [contractCopy, setContractCopy] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    getAgentPastContracts(agentId)
      .then((res) => {
        setPastContracts(res.data);
        setDataLoaded(true);
      })
      .catch(() => {
        setDataLoaded(true);
        setErrorMessage('Something went wrong. Please try again.');
      });
  }, []);

  const handleSelectContract = (id) => {
    setContractId(id);
  };
  const handleCancelContract = () => {
    setContractId(0);
    setErrorMessage('');
  };
  const handleConfirmCopy = () => {
    setContractCopy(true);

    copyContractData(agentId, contractId)
      .then((res) => {
        setContractData(res.data);
      })
      .catch((res) => {
        setContractCopy(false);
        setErrorMessage(res?.response?.data?.message || 'Something went wrong. Please try again.');
      });
  };

  return (
    <Dialog open onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Reactivate Subscription from Past Contracts</DialogTitle>
      <DialogContent>
        {dataLoaded &&
          pastContracts.map((obj) => (
            <Table
              size="small"
              aria-label="a dense table"
              component={Paper}
              elevation={3}
              style={{ marginBottom: 10 }}
            >
              <TableRow>
                <TableCell width="37%">
                  <strong>Sent At:</strong> {obj.created}
                </TableCell>
                <TableCell width="38%">
                  <strong>Terminated At:</strong> {obj.terminateTime}
                </TableCell>
                <TableCell width="25%">
                  <strong>Type:</strong> {paymentFrequencies[obj.subType].label}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <strong>Sub Total:</strong> {obj.price}
                </TableCell>
                <TableCell>
                  <strong>Discount:</strong> {obj.discount}
                </TableCell>
                <TableCell>
                  <strong>Total:</strong> {obj.finalAmount}
                </TableCell>
              </TableRow>
              {obj.exclusiveZones !== '' && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <strong>Exclusive Zone(s):</strong> {obj.exclusiveZones}
                  </TableCell>
                </TableRow>
              )}
              {obj.semiExclusiveZones !== '' && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <strong>Semi Exclusive Zone(s):</strong> {obj.semiExclusiveZones}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={3} align="right">
                  {contractId === obj.id && (
                    <>
                      {errorMessage === '' && (
                        <>
                          Are you sure you want to re-enroll with this contract data?{' '}
                          <Button
                            onClick={handleConfirmCopy}
                            color="secondary"
                            disabled={contractCopy}
                          >
                            {contractCopy ? 'Processing' : 'Yes'}
                          </Button>
                          <Button onClick={handleCancelContract} color="primary">
                            No
                          </Button>
                        </>
                      )}
                      {errorMessage !== '' && (
                        <>
                          <span style={{ color: 'red' }}>{errorMessage}</span>
                          <Button
                            onClick={handleCancelContract}
                            color="primary"
                            style={{ marginLeft: 5 }}
                          >
                            Ok
                          </Button>
                        </>
                      )}
                    </>
                  )}
                  {contractId !== obj.id && (
                    <Button
                      onClick={() => handleSelectContract(obj.id)}
                      color="primary"
                      disabled={contractCopy}
                    >
                      Select
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            </Table>
          ))}
        {dataLoaded && pastContracts.length === 0 && (
          <Table
            size="small"
            aria-label="a dense table"
            component={Paper}
            elevation={3}
            style={{ marginBottom: 10 }}
          >
            <TableRow>
              <TableCell width="100%" align="center">
                {errorMessage !== '' ? errorMessage : 'No past contract found.'}
              </TableCell>
            </TableRow>
          </Table>
        )}
        {!dataLoaded && (
          <Table
            size="small"
            aria-label="a dense table"
            component={Paper}
            elevation={3}
            style={{ marginBottom: 10 }}
          >
            <TableRow>
              <TableCell width="100%" align="center">
                <CircularProgress color="inherit" />
              </TableCell>
            </TableRow>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        {contractId === 0 && errorMessage !== '' ? `${errorMessage} ` : ''}
        <Button onClick={handleClose} color="primary">
          Back
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AgentPastContracts.propTypes = {
  handleClose: PropTypes.func.isRequired,
  agentId: PropTypes.number.isRequired,
  setContractData: PropTypes.func.isRequired,
};

export default AgentPastContracts;
