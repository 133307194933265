import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  makeStyles,
  MenuItem,
  Menu,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import SnackbarMessage from '../SnackbarMessage';
import listingStyle from '../../theme/styles/TableListing';
import { getAgentDiscountTokens } from '../../services/DiscountTokens';
import CancelCreditModal from './CancelCreditModal';
import AddToken from './AddToken';
import { convertContractTimestampToDate } from '../../utils/Datetime';
import { discountTokenStatus, discountTokenType } from '../../config/DataValues';

const useStyles = makeStyles(listingStyle);

const TokensList = ({ agentId, forInactive, setStats }) => {
  const classes = useStyles();
  const columns = [
    { name: 'Priority', id: 'priority', width: '3%', align: 'right' },
    { name: 'Date', id: 'created', width: '15%' },
    { name: 'Value', id: 'value', width: '10%', align: 'right' },
    { name: 'Type', id: 'type', width: '25%' },
    { name: 'Note', id: 'note', width: '20%' },
    { name: 'Actions', id: 'actions', width: '8%' },
  ];

  if (forInactive) {
    columns.shift();
    columns.pop();
    columns.splice(1, 0, { name: 'Inactivated by', id: 'inactivatedBy', width: '20%' });
  }

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [refreshData, setRefreshData] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const [rows, setRows] = useState([]);
  const [selectedToken, setSelectedToken] = useState(null);
  const [openCancelCreditModal, setOpenCancelCreditModal] = useState(false);
  const [openAddTokenModal, setOpenAddTokenModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setIsDataLoaded(false);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    getAgentDiscountTokens(agentId, activePage, forInactive ? 1 : 0).then((res) => {
      const { data } = res;

      if (!forInactive) {
        setStats('totalActiveTokens', data?.stats?.totalActiveTokens || 0);
        setStats('manualActiveTokens', data?.stats?.manualActiveTokens || 0);
        setStats('referralActiveTokens', data?.stats?.referralActiveTokens || 0);
      }
      const dtData = data?.rows?.map((r) => {
        let notes = '';
        let inactivatedBy = '';
        if (r.type === discountTokenType.REFERRAL) {
          notes = `Referred ${r.referredAgentName} (${r.referredAgentEmail})`;
        } else {
          notes = `Added by ${r.creatorName} for: ${r.creationReason}`;
        }

        if (forInactive) {
          if (r.status === discountTokenStatus.CANCELLED) {
            inactivatedBy = `Manual by ${r.creatorName} for ${r.notes}`;
          } else if (r.status === discountTokenStatus.USED) {
            inactivatedBy = `Used for payment ${r.paymentId}`;
          } else if (r.status === discountTokenStatus.EXPIRED) {
            inactivatedBy = 'NRP Termination';
          }
        }

        return { ...r, notes, inactivatedBy };
      });
      setRows(dtData);
      setTotalPages(data.totalPages);
    });
    setIsDataLoaded(true);
  }, [refreshData]);

  const handleActions = (row, action) => {
    if (action === 'cancel-credit') {
      setSelectedToken(row.discountTokenId);
      setOpenCancelCreditModal(true);
    }
  };

  return (
    <Box display="flex" flexDirection="column" mt={forInactive ? 0 : 6}>
      <Box display="flex" flexDirection="row" mb={1}>
        <Typography variant="h6" style={{ fontSize: '1rem' }}>
          {forInactive ? 'Inactive Tokens' : 'Active Tokens'}
        </Typography>
        {!forInactive && (
          <Box display="flex" flexDirection="column" ml="auto">
            <Button
              variant="contained"
              disableElevation
              color="primary"
              onClick={() => {
                setOpenAddTokenModal(true);
              }}
            >
              Add Token
            </Button>
          </Box>
        )}
      </Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell width={col.width} align={col.align || ''}>
                  <span>{col.name}</span>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isDataLoaded && (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            )}

            {isDataLoaded && rows.length === 0 && (
              <TableRow>
                <TableCell
                  align="center"
                  size="medium"
                  colSpan={!forInactive ? 6 : 4}
                  className={classes.noRecord}
                  style={{ padding: 20, fontSize: 15 }}
                >
                  No records found
                </TableCell>
              </TableRow>
            )}

            {isDataLoaded &&
              rows.length > 0 &&
              rows.map((row, i) => (
                <TableRow key={row.discountTokenId}>
                  {!forInactive && (
                    <TableCell align="right">{i + 1 + 5 * (activePage - 1)}</TableCell>
                  )}
                  <TableCell>{convertContractTimestampToDate(row.created)}</TableCell>
                  {forInactive && <TableCell>{row.inactivatedBy}</TableCell>}
                  <TableCell align="right">{row.percentOff}</TableCell>
                  <TableCell>{discountTokenType[Number(row.type)]}</TableCell>
                  <TableCell>{row.notes}</TableCell>
                  {!forInactive && (
                    <TableCell>
                      <ActionMenu selectAction={handleActions} row={row} />
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isDataLoaded && totalPages > 1 && (
        <Pagination
          count={totalPages}
          showFirstButton
          showLastButton
          className={classes.tablePagination}
          onChange={(_, pageNumber) => {
            setActivePage(pageNumber);
            setRefreshData(!refreshData);
          }}
          page={activePage}
        />
      )}
      {openCancelCreditModal && (
        <CancelCreditModal
          discountTokenId={selectedToken}
          closeDialog={() => {
            setSelectedToken(null);
            setOpenCancelCreditModal(false);
            setRefreshData(!refreshData);
          }}
        />
      )}
      {openAddTokenModal && (
        <AddToken
          agentId={agentId}
          closeDialog={() => {
            setOpenAddTokenModal(false);
            setRefreshData(!refreshData);
          }}
        />
      )}
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Box>
  );
};

const ActionMenu = ({ row, selectAction }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (action) => {
    selectAction(row, action);
    handleClose();
  };
  return (
    <div>
      <Button aria-controls={`simple-menu${row.id}`} aria-haspopup="true" onClick={handleClick}>
        <MoreVert />
      </Button>
      <Menu
        id={`simple-menu${row.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleMenuClick('cancel-credit');
          }}
          button
        >
          Cancel credit
        </MenuItem>
      </Menu>
    </div>
  );
};
ActionMenu.propTypes = {
  row: PropTypes.objectOf().isRequired,
  selectAction: PropTypes.func.isRequired,
};

TokensList.defaultProps = {
  forInactive: false,
};

TokensList.propTypes = {
  agentId: PropTypes.arrayOf(PropTypes.any).isRequired,
  forInactive: PropTypes.bool,
  setStats: PropTypes.func.isRequired,
};

export default TokensList;
