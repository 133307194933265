import { Box, CircularProgress, Container, Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PublicWrapper from '../../layout/Public';
import { validateResetPwdToken } from '../../services/Auth';
import SetNewPasswordForm from '../../components/auth/SetNewPasswordForm';
import { isDefaultApp } from '../../utils/AppDomain';
import logo from '../../assets/images/logo.png';
import cLogo from '../../assets/images/c-logo.png';
import loginStyle from '../../theme/styles/Login';

const useStyles = makeStyles(loginStyle);

const ResetPassword = ({ match }) => {
  const classes = useStyles();
  const { params } = match;

  const [validaToken, setValidToken] = useState(false);
  const [processingToken, setProcessingToken] = useState(false);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    setValidToken(false);
    setProcessingToken(true);
    validateResetPwdToken(params.token)
      .then(() => {
        setProcessingToken(false);
        setValidToken(true);
      })
      .catch(({ response }) => {
        setErrorText(response?.data.message || '');
        setProcessingToken(false);
        setValidToken(false);
      });
  }, []);

  return (
    <PublicWrapper>
      <Grid container spacing={0} align="center" justify="center" direction="column">
        <Container maxWidth="xs">
          <img
            src={isDefaultApp() ? logo : cLogo}
            alt={process.env.REACT_APP_NAME}
            className={classes.logo}
          />
          {processingToken && <CircularProgress />}
          {!processingToken && validaToken && (
            <Box p={3}>
              <Typography variant="h5">Reset your password</Typography>
              <SetNewPasswordForm token={params.token} />
            </Box>
          )}
          {!processingToken && !validaToken && <Typography variant="h6">{errorText}</Typography>}
        </Container>
      </Grid>
    </PublicWrapper>
  );
};

export default ResetPassword;

ResetPassword.propTypes = {
  match: PropTypes.objectOf().isRequired,
};
