import React from 'react';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';

import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import TimelineOutlinedIcon from '@material-ui/icons/TimelineOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import CameraFrontOutlinedIcon from '@material-ui/icons/CameraFrontOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

export const Icon1 = () => <SettingsOutlinedIcon style={{ fontSize: '4rem' }} />;
export const Icon2 = () => <TuneOutlinedIcon style={{ fontSize: '4rem' }} />;
export const Icon3 = () => <TimelineOutlinedIcon style={{ fontSize: '4rem' }} />;
export const Icon4 = () => <BusinessCenterOutlinedIcon style={{ fontSize: '4rem' }} />;
export const Icon5 = () => <CameraFrontOutlinedIcon style={{ fontSize: '4rem' }} />;
export const Icon6 = () => <DeleteOutlineOutlinedIcon style={{ fontSize: '4rem' }} />;
// export const Icon7 = () => <TimelineOutlinedIcon style={{ fontSize: '4rem' }} />;
// export const Icon8 = () => <SmartDisplayOutlinedIcon style={{ fontSize: '4rem' }} />;
// export const Icon9 = () => <BusinessCenterOutlinedIcon style={{ fontSize: '4rem' }} />;
// export const Icon10 = () => <CameraFrontOutlinedIcon style={{ fontSize: '4rem' }} />;
// export const Icon11 = () => <DeleteOutlineOutlinedIcon style={{ fontSize: '4rem' }} />;
// export const Icon12 = () => <MilitaryTechOutlinedIcon style={{ fontSize: '4rem' }} />;

export const renderDashboardIcon = (icon, iconColor) => {
  switch (icon) {
    case 'Icon1':
      return (
        <div style={{ color: iconColor }}>
          <Icon1 />
        </div>
      );

    case 'Icon2':
      return (
        <div style={{ color: iconColor }}>
          <Icon2 />
        </div>
      );

    case 'Icon3':
      return (
        <div style={{ color: iconColor }}>
          <Icon3 />
        </div>
      );

    case 'Icon4':
      return (
        <div style={{ color: iconColor }}>
          <Icon4 />
        </div>
      );

    case 'Icon5':
      return (
        <div style={{ color: iconColor }}>
          <Icon5 />
        </div>
      );

    case 'Icon6':
      return (
        <div style={{ color: iconColor }}>
          <Icon6 />
        </div>
      );

    // case 'Icon7':
    //   return (
    //     <div style={{ color: iconColor }}>
    //       <Icon7 />
    //     </div>
    //   );

    // case 'Icon8':
    //   return (
    //     <div style={{ color: iconColor }}>
    //       <Icon8 />
    //     </div>
    //   );

    // case 'Icon9':
    //   return (
    //     <div style={{ color: iconColor }}>
    //       <Icon9 />
    //     </div>
    //   );
    // case 'Icon10':
    //   return (
    //     <div style={{ color: iconColor }}>
    //       <Icon10 />
    //     </div>
    //   );

    // case 'Icon11':
    //   return (
    //     <div style={{ color: iconColor }}>
    //       <Icon11 />
    //     </div>
    //   );
    // case 'Icon12':
    //   return (
    //     <div style={{ color: iconColor }}>
    //       <Icon12 />
    //     </div>
    //   );

    default:
      return (
        <div>
          <AddPhotoAlternateOutlinedIcon style={{ fontSize: '4rem', color: 'green' }} />
        </div>
      );
  }
};

export const renderDashboardIconAgentSide = (icon, iconColor) => {
  switch (icon) {
    case 'Icon1':
      return <SettingsOutlinedIcon style={{ fontSize: '2rem', color: iconColor }} />;

    case 'Icon2':
      return <TuneOutlinedIcon style={{ fontSize: '2rem', color: iconColor }} />;

    case 'Icon3':
      return <TimelineOutlinedIcon style={{ fontSize: '2rem', color: iconColor }} />;

    case 'Icon4':
      return <BusinessCenterOutlinedIcon style={{ fontSize: '2rem', color: iconColor }} />;

    case 'Icon5':
      return <CameraFrontOutlinedIcon style={{ fontSize: '2rem', color: iconColor }} />;

    case 'Icon6':
      return <DeleteOutlineOutlinedIcon style={{ fontSize: '2rem', color: iconColor }} />;

    // case 'Icon7':
    //   return <TimelineOutlinedIcon style={{ fontSize: '2rem', color: iconColor }} />;

    // case 'Icon8':
    //   return <SmartDisplayOutlinedIcon style={{ fontSize: '2rem', color: iconColor }} />;

    // case 'Icon9':
    //   return <BusinessCenterOutlinedIcon style={{ fontSize: '2rem', color: iconColor }} />;

    // case 'Icon10':
    //   return <CameraFrontOutlinedIcon style={{ fontSize: '2rem', color: iconColor }} />;

    // case 'Icon11':
    //   return <DeleteOutlineOutlinedIcon style={{ fontSize: '2rem', color: iconColor }} />;

    // case 'Icon12':
    //   return <MilitaryTechOutlinedIcon style={{ fontSize: '2rem', color: iconColor }} />;

    default:
      return <AddPhotoAlternateOutlinedIcon style={{ fontSize: '2rem', color: 'green' }} />;
  }
};
