import { green, red, yellow } from '@material-ui/core/colors';

const Style = () => ({
  Scheduled: {
    background: yellow[600],
    padding: 5,
    borderRadius: 10,
  },
  Open: {
    background: yellow[600],
    padding: 5,
    borderRadius: 10,
  },
  Closed: {
    background: red[600],
    padding: 5,
    borderRadius: 10,
    color: 'white',
  },
  Published: {
    background: green[600],
    padding: 5,
    borderRadius: 10,
    color: 'white',
  },
  promptTitle: {
    fontWeight: 'bold',
    fontSize: '19px',
    marginBottom: 5,
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
  promptDescription: {
    fontSize: '14px',
    marginBottom: 5,
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
  },
  submitText: {
    fontSize: '12px',
    marginRight: 2,
    marginTop: 5,
  },
  cardContent: {
    padding: 8,
  },
  redText: {
    color: red[500],
  },
  segmentTitle: {
    fontWeight: 'bold',
    fontSize: '19px',
    lineHeight: '30px',
    marginBottom: 5,
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
  segmentTheme: {
    fontSize: '14px',
    marginBottom: 10,
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
  segmentDescription: {
    fontSize: '14px',
    marginBottom: 10,
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 5,
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'pre-wrap',
  },
  segmentSchedule: {
    fontSize: '14px',
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
  segmentDetailContainer: {
    height: 150,
  },
});

export default Style;
