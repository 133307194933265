import React, { useEffect, useRef, useState } from 'react';
import * as UpChunk from '@mux/upchunk';
import { Button, MenuItem, Menu } from '@material-ui/core';
import { ArrowDropDown, CloudUpload } from '@material-ui/icons';
import PropTypes from 'prop-types';
import uploadVideo from '../../services/Assets';
import ViewVideo from './ViewVideo';
import ConfirmDialog from './ConfirmDialog';
import SnackbarMessage from '../SnackbarMessage';

const UploadAsset = ({ userId, assetId, assetStatus }) => {
  const hiddenFileInput = useRef(null);

  const [videoAsset, setVideoAsset] = useState(0);
  const [progress, setProgress] = useState(0);
  const [fileSelected, setFileSelected] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewModel, setViewModel] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [showVideoProgressTip, setShowVideoProgressTip] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleManageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setVideoAsset(assetId);
  }, [assetId]);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const hideVideoUploadTip = () => {
    setShowVideoProgressTip(false);
  };

  const handleFileUpload = (file, endpoint) => {
    setShowVideoProgressTip(true);

    const upload = UpChunk.createUpload({
      endpoint,
      file,
      chunkSize: 5120, // Uploads the file in ~5mb chunks
    });
    let localProgress = 0;

    upload.on('error', (err) => {
      setShowVideoProgressTip(false);
      const message = (err && err.detail && err.detail.message) || 'unknown';
      setErrorMessage(message);
      setHasError(true);
    });

    upload.on('progress', (p) => {
      if (localProgress === 100) return;
      setProgress(Math.ceil(p.detail));
      localProgress = Math.ceil(p.detail);
    });

    upload.on('success', () => {
      setVideoAsset(100);
      localProgress = 100;

      setTimeout(hideVideoUploadTip, 2000);
    });
  };

  const handleChange = (event) => {
    setSnackbarMeesage({
      show: false,
      type: '',
      message: '',
    });
    const picker = event.target;

    if (picker.files[0].type.split('/')[0] === 'video') {
      setFileSelected(true);
      uploadVideo({ userId: userId.toString() })
        .then((res) => {
          const { url } = res.data;
          handleFileUpload(picker.files[0], url);
        })
        .catch(() => {
          setErrorMessage('Something went wrong.');
          setHasError(true);
        });
    } else {
      setSnackbarMeesage({
        message: `Please select video file only.`,
        type: 'error',
        show: true,
      });
    }
  };

  if (assetStatus === 'waiting' || assetStatus === 'preparing') {
    return (
      <>
        <div style={{ marginBottom: 5 }}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={handleClick}
            title={errorMessage}
            disabled
          >
            Processing Video...
          </Button>
        </div>
        <div style={{ marginBottom: 20, color: 'grey' }}>
          The video is being processed. It may take a few minutes to be available.
        </div>
      </>
    );
  }

  return (
    <>
      {videoAsset > 0 && assetStatus === 'ready' ? (
        <>
          <div style={{ marginBottom: 5 }}>
            <Button
              aria-controls="video"
              aria-haspopup="true"
              variant="outlined"
              fullWidth
              endIcon={<ArrowDropDown />}
              onClick={handleManageClick}
              style={{ marginBottom: 5, color: 'green', borderColor: 'green' }}
            >
              Manage Video
            </Button>
            <Menu
              id="video"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  setViewModel(true);
                }}
                button
              >
                View Video
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setConfirmDialog(true);
                }}
                button
              >
                Remove Video
              </MenuItem>
              <MenuItem
                fullWidth
                onClick={handleClick}
                button
                color="primary"
                disabled={progress === 100}
              >
                {!fileSelected && `Upload New Video`}
                {!hasError && fileSelected && progress < 100 && `File Uploading (${progress}%)`}
                {!hasError && fileSelected && progress === 100 && 'File Uploaded'}
                {hasError && 'Error'}
              </MenuItem>
            </Menu>
          </div>
          <div style={{ marginBottom: 20, color: 'grey' }}>
            {showVideoProgressTip &&
              `Do not close the window or save the details until whole video is uploaded. Current progress - ${progress}% / 100%`}
          </div>
        </>
      ) : (
        <>
          <div style={{ marginBottom: 5 }}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              startIcon={<CloudUpload />}
              onClick={handleClick}
              title={errorMessage}
            >
              {!fileSelected && 'Select Video'}
              {!hasError && fileSelected && progress < 100 && `File Uploading (${progress}%)`}
              {!hasError &&
                fileSelected &&
                progress === 100 &&
                'File Uploaded & Processing video...'}
              {hasError && 'Error'}
            </Button>
          </div>
          <div style={{ marginBottom: 20, color: 'grey' }}>
            {showVideoProgressTip &&
              `Do not close the window or save the details until whole video is uploaded. Current progress - ${progress}% / 100%`}
          </div>
        </>
      )}
      <input
        type="file"
        name="video"
        accept="video/*"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />

      {viewModel && (
        <ViewVideo
          userId={userId}
          viewModelOpen={viewModel}
          handleViewModel={(flag) => setViewModel(flag)}
        />
      )}

      {confirmDialog && (
        <ConfirmDialog
          deleteId={userId}
          closeModel={() => setConfirmDialog(false)}
          onSuccess={() => {
            setConfirmDialog(false);
            setVideoAsset(0);
          }}
          open={confirmDialog}
        />
      )}

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </>
  );
};

UploadAsset.propTypes = {
  userId: PropTypes.number.isRequired,
  assetId: PropTypes.number.isRequired,
  assetStatus: PropTypes.string.isRequired,
};

export default UploadAsset;
