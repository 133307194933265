// Style for dashboard module form
const Style = () => ({
  notificationIcon: {
    cursor: 'pointer',
    color: 'green',
    border: '2px',
    borderStyle: 'dashed',
  },
});

export default Style;
