import { Box, Paper, Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
// import ShortLinks from '../../components/branding/ShortLinks';
import AgentNuggets from '../../components/nuggets/AgentNuggets';
import AgentAdVideos from '../../components/agent-ad-videos/AgentAdVideos';
import OdisProfile from '../../components/agent/OdisProfile';
import AgentShowSegments from '../../components/agent/AgentShowSegments';
import Private from '../../layout/Private';
import { trackUserClick } from '../../utils/Activity';
import { showODISModule, showNuggetsModule, showLenderMinutesModule } from '../../utils/ShowModule';
import { agentTypeValues } from '../../config/DataValues';

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

function getTabID(agentType) {
  let initilalTabOpen = 'negotiatorNuggets';
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('#');
  let anchor = urlParts.length > 1 ? urlParts[1] : '';
  if (anchor === 'nrp-promo-link') {
    anchor = '';
  }

  if (anchor === 'nrp-promo-link' && agentType === agentTypeValues.AGENTS) {
    initilalTabOpen = 'promolink';
  } else if (
    anchor === 'show-intro' &&
    anchor === 'intro' &&
    agentType === agentTypeValues.AGENTS
  ) {
    initilalTabOpen = 'showIntro';
  } else if (
    (anchor === 'negotiator-nuggets' || anchor === 'nuggets') &&
    agentType === agentTypeValues.AGENTS
  ) {
    initilalTabOpen = 'negotiatorNuggets';
  } else if (anchor === 'odis' && agentType === agentTypeValues.AGENTS) {
    initilalTabOpen = 'odis';
  } else if (anchor === '' && agentType === agentTypeValues.AGENTS) {
    initilalTabOpen = 'showIntro';
  } else if (anchor === 'minutes' && agentType === agentTypeValues.LENDERS) {
    initilalTabOpen = 'negotiatorNuggets';
  } else if (agentType === agentTypeValues.AGENTS) {
    initilalTabOpen = 'showIntro';
  }

  return initilalTabOpen;
}

const TvShow = () => {
  const pageName = 'Tv Show';
  const user = window.localStorage.getItem('userDetail');
  const userNameObj = user ? JSON.parse(user) : null;
  // // eslint-disable-next-line no-unused-vars
  // const showNrpPromoLink = showNrpPromoLinkModule(userNameObj?.agentType);
  const showODIS = showODISModule(userNameObj?.agentType);
  const showNuggets = showNuggetsModule(userNameObj?.agentType);
  const showLenderMinutes = showLenderMinutesModule(userNameObj?.agentType);
  const [tabValue, setTabValue] = useState(getTabID(userNameObj?.agentType));

  useEffect(() => {
    let objId = 0;
    if (tabValue === 'odis') {
      objId = 27;
    } else if (tabValue === 'negotiatorNuggets' && !showLenderMinutes) {
      objId = 29;
    } else if (tabValue === 'showIntro') {
      objId = 59;
    } else if (tabValue === 'negotiatorNuggets' && showLenderMinutes) {
      objId = 60;
    } else if (tabValue === 'show-segments') {
      objId = 101;
    }
    if (objId) {
      trackUserClick(objId);
    }
  }, [tabValue]);

  return (
    <Private pageName={pageName}>
      <Box m={2} p={2} component={Paper} elevation={0} minHeight="85vh">
        {showODIS && showNuggets && (
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            onChange={(_, v) => {
              setTabValue(v);
            }}
          >
            {/* <Tab value="promolink" label="Promo Link" {...a11yProps(0)} /> */}
            <Tab value="showIntro" label="Show Intro" {...a11yProps(0)} />
            <Tab value="negotiatorNuggets" label="Negotiator Nuggets" {...a11yProps(0)} />
            <Tab value="odis" label="ODIS" {...a11yProps(0)} />
            <Tab value="show-segments" label="NRP Show Segments" {...a11yProps(0)} />
          </Tabs>
        )}
        {showLenderMinutes && (
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            onChange={(_, v) => {
              setTabValue(v);
            }}
          >
            <Tab value="negotiatorNuggets" label="Lenders Minute" {...a11yProps(0)} />
            <Tab value="show-segments" label="NRP Show Segments" {...a11yProps(0)} />
          </Tabs>
        )}

        {/* {tabValue === 'promolink' && (
          <Box mt={2}>
            <ShortLinks />
          </Box>
        )} */}
        {tabValue === 'showIntro' && (
          <Box mt={2}>
            <AgentAdVideos />
          </Box>
        )}
        {tabValue === 'negotiatorNuggets' && (
          <Box mt={2}>
            <AgentNuggets
              module={showLenderMinutes ? agentTypeValues.LENDERS : agentTypeValues.AGENTS}
            />
          </Box>
        )}
        {tabValue === 'odis' && (
          <Box mt={2}>
            <OdisProfile />
          </Box>
        )}
        {tabValue === 'show-segments' && (
          <Box mt={2}>
            <AgentShowSegments />
          </Box>
        )}
      </Box>
    </Private>
  );
};

export default TvShow;
