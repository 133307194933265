import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
} from '@material-ui/core';
import { ContractSubTypes } from '../../config/DataValues';
import AssignZoneSection from './AssignZoneSection';
import listAll from '../../services/Zones';
import EnrollContract from '../contract/EnrollContract';
import { enrollAgentSubscription, fetchPurchasedZone } from '../../services/Agent';
import SnackbarMessage from '../SnackbarMessage';
import AgentPastContracts from './AgentPastContracts';

const EnrollSubscription = ({ onClose, data, zoneType, showReactivationFlow }) => {
  const [tabValue, setTabValue] = useState(1);
  const [zoneObj, setZoneObj] = useState({});
  const [tempAssignedZoneData, setTempAssignedZoneData] = useState([]);
  const [contractValues, setContractValues] = useState({
    subTotal: 0,
    discount: 0,
    total: 0,
    subType: ContractSubTypes.QUARTERLY.value,
    subStartDate: 0,
    nextRecurringDate: 0,
    contractNotes: '',
    isReactivate: 0,
    oldContract: null,
  });
  const [contractValueError, setContractValueError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(1);

  useEffect(() => {
    setLoading(true);
    listAll(null, null, zoneType).then((item) => {
      const tmpzoneObj = {};
      item.data.rows.forEach((zone) => {
        tmpzoneObj[zone.id] = zone.name;
      });
      setZoneObj(tmpzoneObj);
    });

    fetchPurchasedZone(data.id).then((resp) => {
      const arr = resp.data.map((item) => ({
        id: item.id,
        purchasedZone: item.zoneId,
        exclusivity: item.purchaseType === 'sold' ? 1 : 2,
        paymentStatus: item.paymentStatus === 'hold' ? 2 : 1,
        nrpFee: item.price,
        status: item.status,
      }));
      setTempAssignedZoneData(arr);
      setLoading(false);
    });
  }, [refresh]);

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const enrollSubscription = () => {
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });
    setProcessing(true);
    const payload = {
      contractZoneData: tempAssignedZoneData.map((zone) => ({
        zoneId: zone.purchasedZone,
        price: zone.nrpFee,
        purchaseType: zone.exclusivity,
      })),
      contractValues,
    };
    enrollAgentSubscription(data.id, payload)
      .then(() => {
        onClose();
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
        setProcessing(false);
      });
  };

  const handlePastContractsClick = () => {
    onClose();
  };
  const handleCopyFromContract = (payload) => {
    setContractValues({
      ...contractValues,
      subTotal: payload.price,
      discount: payload.discount,
      total: payload.finalAmount,
      subType: payload.subType,
      subStartDate: payload.subStartDate,
      isReactivate: 1,
      oldContract: payload.id,
    });
    setRefresh(refresh + 1);
    setTabValue(3);
  };

  return (
    <>
      <Dialog
        open={!showReactivationFlow || tabValue > 1}
        onClose={onClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{showReactivationFlow ? 'Reactivate' : 'Enroll'} Subscription</DialogTitle>
        <DialogContent>
          {!loading && (
            <>
              {!showReactivationFlow && (
                <Tabs value={tabValue} indicatorColor="primary">
                  <Tab label="Verify Details" value={1} />
                  <Tab label="Verify Zones" value={2} />
                  <Tab label="Enroll Subscription" value={3} />
                </Tabs>
              )}
              <Box mt={1.5}>
                {tabValue === 1 && (
                  <TableContainer>
                    <Table>
                      <TableRow>
                        <TableCell>Stripe Email</TableCell>
                        <TableCell>{data.mappedEmail}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>{data.name}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>{data.email}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Phone</TableCell>
                        <TableCell>{data.phone}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Location</TableCell>
                        <TableCell>{data.location}</TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>
                )}
                {tabValue === 2 && (
                  <AssignZoneSection
                    agentId={data.id}
                    fromAddAgent
                    tempAssignedZoneData={tempAssignedZoneData}
                    setTempAssignedZoneData={setTempAssignedZoneData}
                    zoneArr={zoneObj}
                    editable={false}
                  />
                )}
                {tabValue === 3 && (
                  <EnrollContract
                    tempAssignedZoneData={tempAssignedZoneData}
                    zoneArr={zoneObj}
                    contractValues={contractValues}
                    setContractValues={setContractValues}
                    contractValueError={setContractValueError}
                  />
                )}
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <div />
          <div>
            {!showReactivationFlow && tabValue > 1 && (
              <Button
                variant="outlined"
                disableElevation
                onClick={() => handleTabChange('', tabValue - 1)}
                style={{ marginRight: '10px' }}
                disabled={contractValues.isReactivate === 1}
              >
                Previous
              </Button>
            )}
            {!showReactivationFlow && tabValue < 3 && (
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => handleTabChange('', tabValue + 1)}
                style={{ marginRight: '10px' }}
                disabled={contractValues.isReactivate === 1}
              >
                Next
              </Button>
            )}
            {tabValue === 3 && (
              <Button
                variant="contained"
                color="primary"
                disableElevation
                disabled={contractValueError || processing}
                onClick={enrollSubscription}
                style={{ marginRight: '10px' }}
              >
                {showReactivationFlow ? 'Reactivate Subscription' : 'Create'}
              </Button>
            )}
            <Button variant="contained" disableElevation onClick={onClose}>
              Cancel
            </Button>
          </div>
        </DialogActions>

        {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      </Dialog>

      {showReactivationFlow && tabValue === 1 && (
        <AgentPastContracts
          setContractData={handleCopyFromContract}
          handleClose={handlePastContractsClick}
          agentId={data.id}
        />
      )}
    </>
  );
};

EnrollSubscription.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.objectOf().isRequired,
  zoneType: PropTypes.number.isRequired,
  showReactivationFlow: PropTypes.bool.isRequired,
};

export default EnrollSubscription;
