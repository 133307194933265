import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Pagination from '@material-ui/lab/Pagination';
import {
  Button,
  Box,
  CardMedia,
  Paper,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControlLabel,
} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { Controller, useForm } from 'react-hook-form';
import { listBanner, setFallbackBanner, removeBanner } from '../../services/Banner';
import SnackbarMessage from '../SnackbarMessage';

const ListBanners = ({ editBanner, setListRefreshFlag, listRefresh, setDeleteId }) => {
  const [bannerList, setBannerList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [editData, SetEditData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const { control } = useForm();

  useEffect(() => {
    setSnackbarMeesage({
      show: false,
      type: '',
      message: '',
    });
    setListRefreshFlag(false);
    setDataLoaded(false);
    listBanner(activePage, `searchText=${encodeURIComponent(searchText)}`)
      .then((res) => {
        if (res && res.data) {
          setBannerList(res.data.rows);
          setTotalPages(res.data.totalPages);
          setDataLoaded(true);
        }
      })
      .catch(() => {
        setBannerList([]);
      });
  }, [refresh, listRefresh, searchText, activePage]);

  const setbannerFallback = (id, flag) => {
    setFallbackBanner(id, flag)
      .then((response) => {
        if (response.success) {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: `Banner fallback has been ${flag ? 'set' : 'unset'} successfully.`,
            type: 'success',
            show: true,
          });
          setTimeout(() => {
            setRefresh(!refresh);
          }, 1000);
        }
      })
      .catch((response) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.message,
          type: 'error',
          show: true,
        });
      });
  };

  const editBannerData = (id) => {
    const data = bannerList.filter((b) => b.id === id);
    editBanner(data[0]);
  };

  const hideDialog = () => {
    setIsDelete(false);
  };

  const deleteBanner = (id) => {
    const data = bannerList.filter((b) => b.id === id);
    SetEditData(data[0]);
    setIsDelete(true);
  };

  const bannerRemove = (id) => {
    setDataLoaded(false);
    removeBanner(id)
      .then((res) => {
        if (res && res.success) {
          setDeleteId(id);
          setIsDelete(false);
          setRefresh(true);
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: 'Banner has been deleted successfully.',
            type: 'success',
            show: true,
          });
          setDataLoaded(true);
          setRefresh(false);
        }
      })
      .catch((res) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: res.message,
          type: 'error',
          show: true,
        });
        setDataLoaded(true);
      });
  };

  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <Typography variant="h5">Current Banner</Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Controller
                control={control}
                name="title"
                id="title"
                defaultValue=""
                render={({ field: { value }, fieldState: { error } }) => (
                  <TextField
                    variant="outlined"
                    placeholder="Search..."
                    value={searchText || value}
                    size="small"
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    error={!!error}
                    helperText={error ? error.message : null}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <hr />
          </Box>
        </CardContent>
        <CardContent>
          <Box mt={-2}>
            <Typography variant="h6">Uploaded Banner</Typography>
          </Box>
          <Box mt={1}>
            <Grid container spacing={3}>
              {!dataLoaded &&
                [1, 2, 3, 4].map(() => (
                  <Grid item xs={6}>
                    <Paper elevation={0}>
                      <Skeleton
                        variant="rounded"
                        style={{ height: '248px', borderRadius: '15px' }}
                      />
                    </Paper>
                  </Grid>
                ))}
              {bannerList.length > 0 &&
                bannerList.map((b) => (
                  <Grid item xs={6}>
                    <Paper elevation={0}>
                      <Card
                        variant="outlined"
                        style={{
                          backgroundColor: '#d7d7d7',
                          borderRadius: '15px',
                        }}
                      >
                        <CardContent
                          style={{
                            paddingBottom: '4px',
                          }}
                        >
                          <Box ml={3}>
                            <Grid
                              container
                              spacing={3}
                              style={{
                                height: '166px',
                              }}
                            >
                              <Grid item xs={5} sm={5}>
                                <Box
                                  mt={2}
                                  sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '1',
                                    WebkitBoxOrient: 'vertical',
                                  }}
                                >
                                  <Typography variant="h6" title={b.title}>
                                    {b.title}
                                  </Typography>
                                </Box>
                                <Box
                                  mt={2}
                                  sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '4',
                                    WebkitBoxOrient: 'vertical',
                                  }}
                                >
                                  <Typography variant="body2">{b.description}</Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={5} sm={5}>
                                {b.bannerImageUrl && (
                                  <CardMedia
                                    component="img"
                                    alt=""
                                    image={b.bannerImageUrl}
                                    title=""
                                    style={{
                                      objectFit: 'contain',
                                      width: '90%',
                                      marginTop: '15px',
                                      maxHeight: '100px',
                                    }}
                                  />
                                )}
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                sm={2}
                                style={{
                                  paddingTop: '38px',
                                }}
                              >
                                <Box mt={3} display="flex" justifyContent="end">
                                  <Button
                                    variant="outlined"
                                    lg="auto"
                                    style={{
                                      height: '40px',
                                      borderRadius: '25px',
                                      backgroundColor: '#fff',
                                      minWidth: '88px',
                                      color: 'silver',
                                      textTransform: 'none',
                                    }}
                                    onClick={() => editBannerData(b.id)}
                                  >
                                    Edit/View
                                  </Button>
                                </Box>
                                <Box mt={1} display="flex" justifyContent="end">
                                  <Button
                                    variant="outlined"
                                    lg="auto"
                                    style={{
                                      height: '40px',
                                      borderRadius: '25px',
                                      minWidth: '88px',
                                      backgroundColor: '#fff',
                                      color: 'silver',
                                      textTransform: 'none',
                                    }}
                                    onClick={() => deleteBanner(b.id)}
                                  >
                                    Trash
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={12}>
                                <Controller
                                  control={control}
                                  name="banner1"
                                  id="banner1"
                                  defaultValue={false}
                                  render={({ field: { value } }) => (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={b.isFallbackBanner || value}
                                          onChange={() =>
                                            setbannerFallback(
                                              b.id,
                                              b.isFallbackBanner || value ? 0 : 1
                                            )
                                          }
                                          color="primary"
                                          style={{ color: '#43a047' }}
                                        />
                                      }
                                      label={
                                        <Typography
                                          style={{
                                            fontSize: '1rem',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitLineClamp: '1',
                                            WebkitBoxOrient: 'vertical',
                                          }}
                                          title="Activate Banner Fall Back"
                                        >
                                          Activate Banner Fall Back
                                        </Typography>
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </CardContent>
                      </Card>
                    </Paper>
                  </Grid>
                ))}
            </Grid>
            {dataLoaded && bannerList.length === 0 && (
              <Box mt={5} display="flex" justifyContent="center">
                <Typography variant="body2">No records found</Typography>
              </Box>
            )}
            {dataLoaded && totalPages > 0 && (
              <Box mt={5}>
                <Pagination
                  count={totalPages}
                  showFirstButton
                  showLastButton
                  onChange={(_, pageNumber) => {
                    setActivePage(pageNumber);
                  }}
                  page={activePage}
                />
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
      {isDelete && (
        <Dialog open maxWidth="sm" onClose={hideDialog}>
          <Box display="flex" justifyContent="end">
            <IconButton onClick={hideDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <DialogContentText id="alert-dialog-delete">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    <Grid item xs={12}>
                      <Paper elevation={0}>
                        <Card variant="outlined" style={{ backgroundColor: '#bdbdbd' }}>
                          <CardContent>
                            <Grid container spacing={3}>
                              <Grid item xs={6} sm={6}>
                                <Typography variant="h6">{editData.title}</Typography>
                                <Typography variant="body2">{editData.description}</Typography>
                              </Grid>
                              <Grid item xs={6} sm={6}>
                                <Box mt={1}>
                                  <CardMedia
                                    component="img"
                                    alt=""
                                    image={editData.bannerImageUrl}
                                    title=""
                                    height={210}
                                    style={{ objectFit: 'contain' }}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Paper>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box mt={5} display="flex" justifyContent="center">
                    <Typography variant="h5">Delete This Banner?</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box mt={2} display="flex" justifyContent="center">
                    <Typography>This is going to be final. Are you sure?</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={3} />
                <Grid item xs={6}>
                  <Box
                    mt={5}
                    display="flex"
                    justifyContent="center"
                    style={{
                      backgroundColor: '#e30000',
                      borderRadius: 40,
                      height: 60,
                      cursor: 'pointer',
                    }}
                    onClick={() => bannerRemove(editData.id)}
                  >
                    <Button
                      style={{ fontSize: '1.5rem', color: '#fff', textTransform: 'capitalize' }}
                    >
                      DELETE ANYWAY
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={3} />
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </>
  );
};

ListBanners.propTypes = {
  editBanner: PropTypes.func.isRequired,
  setListRefreshFlag: PropTypes.func.isRequired,
  listRefresh: PropTypes.bool.isRequired,
  setDeleteId: PropTypes.func.isRequired,
};

export default ListBanners;
