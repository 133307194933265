import { getApiCall, postApiCall, deleteApiCall } from '../utils/Api';
import { agentTypeValues } from '../config/DataValues';

const listAll = async (page, params, module = agentTypeValues.AGENTS) => {
  const result = await getApiCall(
    `/agent/list?module=${module}&page=${page}${params ? `&${params}` : ''}`
  );
  return result.data;
};

export const addNewAgent = async (data, module = agentTypeValues.AGENTS) => {
  const result = await postApiCall(`/agent/create?module=${module}`, data);
  return result.data;
};

export const editAgent = async (id, data) => {
  const result = await postApiCall(`/agent/${id}/update`, data);
  return result.data;
};

export const viewAgentById = async (id) => {
  const result = await getApiCall(`/agent/${id}/view`);
  return result.data;
};

export const removeAgent = async (id) => {
  const result = await deleteApiCall(`/agent/${id}/remove`);
  return result.data;
};

export const fetchPurchasedZone = async (id) => {
  const result = await getApiCall(`/agent/${id}/zones`);
  return result.data;
};

export const updatePurchasedZone = async (id, data, module = agentTypeValues.AGENTS) => {
  const result = await postApiCall(`/agent/zones/${id}/update?module=${module}`, data);
  return result.data;
};

export const assignZone = async (id, data, module = agentTypeValues.AGENTS) => {
  const result = await postApiCall(`/agent/${id}/zones/create?module=${module}`, data);
  return result.data;
};

export const removeAssignedZone = async (id) => {
  const result = await deleteApiCall(`/agent/zones/${id}/remove`);
  return result.data;
};

export const validateAssignZone = async (data, module = agentTypeValues.AGENTS) => {
  const result = await postApiCall(`/agent/validate-zone?module=${module}`, data);
  return result.data;
};

export const updateAgentProfile = async (data) => {
  const result = await postApiCall(`/agent/update-profile`, data);
  return result.data;
};

export const getVideoPreview = async (id) => {
  const result = await getApiCall(`/agent/${id}/video`, true);
  return result.data;
};

export const removeAgentVideo = async (id) => {
  const result = await deleteApiCall(`/agent/${id}/remove-video`);
  return result.data;
};

export const updateAgentWebStatus = async (id, data) => {
  const result = await postApiCall(`/agent/${id}/web-status`, data);
  return result.data;
};

export const getAgentWebStatus = async (id) => {
  const result = await getApiCall(`/agent/${id}/get-web-status`);
  return result.data;
};

export const approveAgentWebStatus = async (id, data) => {
  const result = await postApiCall(`/agent/${id}/approve-web-status`, data);
  return result.data;
};

export const rejectAgentWebStatus = async (id, data) => {
  const result = await postApiCall(`/agent/${id}/reject-web-status`, data);
  return result.data;
};

export const updateAgentWebProfile = async (id, data) => {
  const result = await postApiCall(`/agent/${id}/update-web-profile`, data);
  return result.data;
};

export const enrollAgentSubscription = async (id, data) => {
  const result = await postApiCall(`/agent/${id}/enroll-sub`, data);
  return result.data;
};

export const fetchAmendmentZones = async (id) => {
  const result = await getApiCall(`/agent/${id}/draft-contract-zones`);
  return result.data;
};

export const searchAgent = async (params, module = agentTypeValues.AGENTS) => {
  const result = await getApiCall(`/agent/search?module=${module}&${params}`);
  return result.data;
};

export const getAgentPaymentDetails = async (agentId) => {
  const result = await getApiCall(`/agent/${agentId}/payment-details`);
  return result.data;
};

export const cancelAgentSubscription = async ({ cancellationType, cancelSubAt, agentId }) => {
  const result = await postApiCall(`/agent/${agentId}/cancel-subscription`, {
    cancellationType,
    cancelSubAt,
    agentId,
  });

  return result.data;
};

export const resumeAgentSubscription = async (agentId) => {
  const result = await postApiCall(`/agent/${agentId}/resume-subscription`, {
    agentId,
  });

  return result.data;
};

export const syncCancelSubWithStripe = async (agentId) => {
  const result = await getApiCall(`/agent/${agentId}/sync-cancel-stripe`);
  return result.data;
};

export const pendingApprovalList = async (page, params) => {
  const result = await getApiCall(
    `/agent/list-pending-approvals?page=${page}${params ? `&${params}` : ''}`
  );
  return result.data;
};

export const getAgentPastContracts = async (id) => {
  const result = await getApiCall(`/agent/${id}/get-past-contracts`);
  return result.data;
};

export const copyContractData = async (id, contractId) => {
  const result = await getApiCall(`/agent/${id}/${contractId}/copy-contract`);
  return result.data;
};

export const enableTeamProfile = async (id, flag) => {
  const result = await getApiCall(`/agent/${id}/${flag}/team-profile`);
  return result.data;
};

export const getAgentTeam = async (id, page) => {
  const result = await getApiCall(`/agent/${id}/team-member?page=${page || 1}`);
  return result.data;
};

export const inviteTeamMember = async (id, data) => {
  const result = await postApiCall(`/agent/${id}/invite-team-member`, data);
  return result.data;
};

export const resendInviteTeamMember = async (id) => {
  const result = await getApiCall(`/agent/${id}/resend-invite-team-member`);
  return result.data;
};

export const getAgentBanners = async () => {
  const result = await getApiCall(`/agent/banners`);
  return result.data;
};

export const getAgentNotifications = async (page = 1) => {
  const result = await getApiCall(`/agent/notifications?page=${page || 1}`);
  return result.data;
};

export const markNotificationRead = async (id) => {
  const result = await getApiCall(`/agent/notifications/${id}/read`);
  return result.data;
};

export const getAgentCeUserData = async () => {
  const result = await getApiCall(`/agent/ce-ghl-data`);
  return result.data;
};

export default listAll;
