/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  InputAdornment,
  makeStyles,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { isAfter, isBefore } from 'date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Add, Room } from '@material-ui/icons';
import contractStyle from '../../theme/styles/Contract';
import { paymentFrequencies, ContractSubTypes } from '../../config/DataValues';
import ContractZoneTable from './ContractZoneTable';
import { isPastDate } from '../../utils/Datetime';
import AssignZoneForm from '../agent/AssignZoneForm';
import ContractNoteDialog from './ContractNoteDialog';
import { getAgentTypeLabel } from '../../utils/GetModuleType';

const useStyles = makeStyles(contractStyle);

const PrepareContract = ({
  setContractZoneData,
  contractZoneData,
  zoneArr,
  updateList,
  setUpdateList,
  agentDetails,
  setContractValues,
  contractValues,
  contractStatus,
  contractValueError,
  fromAmendContract,
  refershData,
  setRefershData,
  hasAmendContractAccess,
  setContractNotes,
  contractNotes,
  canShowNotesPopup,
  zoneType,
}) => {
  const classes = useStyles();

  const dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const contractExpTmp2 = new Date();

  const [discountError, setDiscountError] = useState('');
  const [subStartDateError, setSubStartDateError] = useState('');
  const [contractExpiryDateError, setContractExpiryDateError] = useState('');
  const [addZoneMode, setAddZoneMode] = useState(false);

  const [openContractNotesDialog, setOpenContractNotesDialog] = useState(false);
  const requireContractNotesFor = [ContractSubTypes.MONTHLY.value];

  const label = getAgentTypeLabel(zoneType);

  useEffect(() => {
    setUpdateList(!updateList);
    if (contractStatus === null || contractStatus === 0) {
      let subT = 0;
      contractZoneData.forEach((z) => {
        subT += z.nrpFee * paymentFrequencies[contractValues.subType].multiply;
      });
      const values = {
        ...contractValues,
        subTotal: subT,
        discount: contractValues.discount,
        total: subT - contractValues.discount,
      };
      setContractValues(values);
    }
  }, [contractZoneData]);

  useEffect(
    () => {
      let subT = 0;
      contractZoneData.forEach((z) => {
        subT += z.nrpFee * paymentFrequencies[contractValues.subType].multiply;
      });

      if (subT - contractValues.discount <= 0 && subT > 0) {
        contractValueError(true);
        setDiscountError('Invalid discount');
      } else {
        contractValueError(false);
        setDiscountError('');
      }

      const values = {
        ...contractValues,
        subTotal: subT,
        discount: contractValues.discount,
        total: subT - contractValues.discount,
      };
      setContractValues(values);
    },
    contractStatus === null || contractStatus === 0
      ? [contractValues.subType, contractZoneData]
      : [contractValues.subType]
  );

  useEffect(() => {
    if (
      contractValues &&
      requireContractNotesFor.includes(Number(contractValues.subType || 0)) &&
      canShowNotesPopup &&
      (!fromAmendContract || (fromAmendContract && hasAmendContractAccess))
    ) {
      setOpenContractNotesDialog(true);
    }
  }, [canShowNotesPopup, contractValues.subType]);

  const updateValues = (key, value) => {
    const values = {
      ...contractValues,
      [key]: value,
    };
    setContractValues(values);
  };

  const saveContractNotes = (notes, contractSubType) => {
    setContractNotes(notes);
    updateValues('subType', contractSubType);
    setOpenContractNotesDialog(false);
  };

  const closeContractNotesDialog = () => {
    setOpenContractNotesDialog(false);
    // if contract type requires contract notes and
    // no contract notes are provided then change the contract type to quarterly.
    if (requireContractNotesFor.includes(contractValues.subType) && !contractNotes) {
      updateValues('subType', ContractSubTypes.QUARTERLY.value);
    }
  };

  const checkLocalValidationForZone = (zone) => {
    const findExisting = contractZoneData.filter((z) => z.purchasedZone === zone.purchasedZone);
    if (findExisting.length === 2) {
      return { status: false, message: 'The selected zone is already occupied.' };
    }
    if (findExisting.length === 1) {
      if (findExisting[0].exclusivity === 1) {
        return { status: false, message: 'The selected zone is already occupied.' };
      }
      if (zone.exclusivity === 2 && findExisting[0].exclusivity === 2) {
        return {
          status: false,
          message:
            'The selected zone is already purchased as semi-exclusively by you, please change the exclusivity of purchased zone.',
        };
      }
      if (zone.exclusivity === 1 && findExisting[0].exclusivity === 2) {
        return {
          status: false,
          message:
            `The selected zone is already purchased by other ${label}, please change the exclusivity.`,
        };
      }
      if (zone.exclusivity === 2 && findExisting[0].exclusivity === 1) {
        return {
          status: false,
          message: 'The selected zone is already occupied.',
        };
      }
      return {
        status: false,
        message:
          `The selected zone is already purchased by other ${label}, please change the exclusivity.`,
      };
    }
    return { status: true };
  };

  return (
    <div>
      <Box display="flex" flexDirection="row">
        <div className={classes.info}>
          <div className={classes.infoRow}>
            <div
              className={`${classes.infoRowAgentName} ${classes.infoRowCell}`}
            >{`${agentDetails.firstName} ${agentDetails.lastName}`}</div>
            <div className={`${classes.infoRowCell} ${classes.infoRowLocation}`}>
              {agentDetails.location && <Room />}
              <span>{agentDetails.location}</span>
            </div>
          </div>
          <div className={classes.infoRow}>
            <div className={classes.infoRowCell}>{agentDetails.email}</div>
            <div className={classes.infoRowCell}>{agentDetails.phone}</div>
          </div>
        </div>
        {fromAmendContract && hasAmendContractAccess && (
          <div className={classes.assignBtnContainer}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => {
                setAddZoneMode(true);
              }}
              className={classes.assignNewZoneBtn}
            >
              Assign New Zone
            </Button>
          </div>
        )}
      </Box>
      <ContractZoneTable
        setTempAssignedZoneData={setContractZoneData}
        tempAssignedZoneData={contractZoneData}
        updateList={updateList}
        zoneArr={zoneArr}
        quantity={paymentFrequencies[contractValues.subType].multiply}
        allowDelete={fromAmendContract ? hasAmendContractAccess : true}
        fromAmendContract={fromAmendContract}
        agentId={agentDetails.agentId}
        refershData={refershData}
        setRefershData={setRefershData}
      />
      <Table>
        <TableRow>
          <TableCell width="40%">Subscription</TableCell>
          <TableCell width="20%">
            <TextField
              label=""
              defaultValue={2}
              value={contractValues.subType}
              onChange={(e) => {
                if (requireContractNotesFor.includes(Number(e.target.value))) {
                  setOpenContractNotesDialog(true);
                } else {
                  setContractNotes('');
                }
                updateValues('subType', Number(e.target.value));
              }}
              SelectProps={{
                native: true,
              }}
              fullWidth
              select
              disabled={fromAmendContract ? !hasAmendContractAccess : false}
            >
              <option key={1} value={1}>
                Monthly
              </option>
              <option key={2} value={2}>
                Quarterly
              </option>
              <option key={3} value={3}>
                Bi-Annual
              </option>
              <option key={4} value={4}>
                Annual
              </option>
            </TextField>
          </TableCell>

          <TableCell width="15%">Sub Total</TableCell>
          <TableCell align="right">{dollarUS.format(contractValues.subTotal)}</TableCell>
          <TableCell width="7%" />
        </TableRow>
        <TableRow>
          <TableCell>
            Subscription Start Date <br />
            <Typography color="textSecondary" variant="p">
              This will be subscription start date however billing cycle will be varied depending
              upon first payment date.
            </Typography>
          </TableCell>
          <TableCell>
            {!fromAmendContract || (fromAmendContract && contractValues.subStartDate) ? (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  format="MM/dd/yyyy"
                  value={contractValues.subStartDate || null}
                  autoOk
                  clearable
                  disabled={fromAmendContract ? !hasAmendContractAccess : false}
                  onChange={(e) => {
                    contractValueError(discountError || contractExpiryDateError);
                    setSubStartDateError('');
                    if (e && e.toString() === 'Invalid Date') {
                      setSubStartDateError('Invalid Date');
                      contractValueError(true);
                    }

                    if (
                      contractValues.contractExpiryDate &&
                      isAfter(e, contractValues.contractExpiryDate)
                    ) {
                      setContractExpiryDateError('');
                    }

                    if (e && isPastDate(e)) {
                      setSubStartDateError('Date is already passed.');
                      contractValueError(true);
                    }
                    updateValues('subStartDate', e);
                  }}
                  error={subStartDateError !== ''}
                  helperText={subStartDateError}
                />
              </MuiPickersUtilsProvider>
            ) : (
              '-'
            )}
          </TableCell>

          <TableCell>Discount</TableCell>
          <TableCell align="right">
            <TextField
              label=""
              value={contractValues.discount}
              className={classes.discountInput}
              onChange={(e) => {
                const { value } = e.target;
                contractValueError(subStartDateError || contractExpiryDateError);
                setDiscountError('');
                const regex = /[0-9.]$/;
                if (!regex.test(value) || value < 0) {
                  setDiscountError('Invalid value');
                  contractValueError(true);
                  const values = {
                    ...contractValues,
                    discount: value,
                  };
                  setContractValues(values);
                } else if (value < 0 || (contractValues.subTotal - value <= 0 && contractValues.subTotal > 0)) {
                  setDiscountError('Invalid discount');
                  contractValueError(true);
                  const values = {
                    ...contractValues,
                    discount: value,
                  };
                  setContractValues(values);
                } else {
                  const values = {
                    ...contractValues,
                    discount: value,
                    total: contractValues.subTotal - value,
                  };
                  setContractValues(values);
                }
              }}
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              error={discountError !== ''}
              helperText={discountError}
              fullWidth
              disabled={fromAmendContract ? !hasAmendContractAccess : false}
            />
          </TableCell>
          <TableCell />
        </TableRow>
        <TableRow>
          <TableCell>Contract Expiry Date</TableCell>
          <TableCell>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                format="MM/dd/yyyy"
                value={contractValues.contractExpiryDate || null}
                maxDate={
                  fromAmendContract
                    ? new Date(contractExpTmp2.setDate(contractExpTmp2.getDate() + 7))
                    : new Date(contractExpTmp2.setDate(contractExpTmp2.getDate() + 30))
                }
                autoOk
                clearable
                disabled={fromAmendContract ? !hasAmendContractAccess : false}
                onChange={(e) => {
                  contractValueError(discountError || subStartDateError);
                  setContractExpiryDateError('');
                  if (e && e.toString() === 'Invalid Date') {
                    setContractExpiryDateError('Invalid Date');
                    contractValueError(true);
                  }
                  if (contractValues.subStartDate && isBefore(e, contractValues.subStartDate)) {
                    setSubStartDateError('');
                  }
                  const contractExpTmp = new Date();
                  if (
                    contractValues.subStartDate &&
                    isAfter(e, new Date(contractExpTmp.setDate(contractExpTmp.getDate() + 30)))
                  ) {
                    setContractExpiryDateError('Expiry date should not be more than 30 days.');
                    contractValueError(true);
                  }
                  if (e && isPastDate(e)) {
                    setContractExpiryDateError('Date is already passed.');
                    contractValueError(true);
                  }
                  updateValues('contractExpiryDate', e);
                }}
                error={contractExpiryDateError !== ''}
                helperText={contractExpiryDateError}
              />
            </MuiPickersUtilsProvider>
          </TableCell>
          <TableCell>Total</TableCell>
          <TableCell align="right">{dollarUS.format(contractValues.total)}</TableCell>
          <TableCell />
        </TableRow>
      </Table>
      {contractNotes && (
        <Box m={2}>
          Monthly Contract Notes:
          <Typography color="textSecondary" variant="p" className={classes.contractNotes}>
            {contractNotes}
          </Typography>
        </Box>
      )}
      {addZoneMode && (
        <AssignZoneForm
          forDraftAmendment
          agentId={agentDetails.agentId}
          setAddMode={() => {
            setAddZoneMode(false);
            setRefershData(!refershData);
          }}
          checkLocalValidationForZone={checkLocalValidationForZone}
          agentIsEnrolled
          zoneType={zoneType}
        />
      )}
      {openContractNotesDialog && (
        <ContractNoteDialog
          contractSubType={contractValues.subType}
          defaultContractNotes={contractNotes}
          closeDialog={closeContractNotesDialog}
          onSave={saveContractNotes}
        />
      )}
    </div>
  );
};

PrepareContract.propTypes = {
  setContractZoneData: PropTypes.func,
  contractZoneData: PropTypes.arrayOf(),
  zoneArr: PropTypes.arrayOf().isRequired,
  updateList: PropTypes.bool.isRequired,
  setUpdateList: PropTypes.func.isRequired,
  setContractValues: PropTypes.func.isRequired,
  agentDetails: PropTypes.objectOf().isRequired,
  contractValues: PropTypes.objectOf().isRequired,
  contractStatus: PropTypes.string,
  contractValueError: PropTypes.func,
  fromAmendContract: PropTypes.bool,
  refershData: PropTypes.bool,
  setRefershData: PropTypes.func,
  hasAmendContractAccess: PropTypes.bool,
  setContractNotes: PropTypes.func.isRequired,
  contractNotes: PropTypes.string.isRequired,
  canShowNotesPopup: PropTypes.bool,
  zoneType: PropTypes.number.isRequired,
};
PrepareContract.defaultProps = {
  setContractZoneData: () => true,
  contractZoneData: [],
  contractStatus: null,
  contractValueError: () => true,
  fromAmendContract: false,
  refershData: false,
  setRefershData: () => {},
  hasAmendContractAccess: false,
  canShowNotesPopup: false,
};

export default PrepareContract;
