/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import agentStyle from '../../theme/styles/Agents';
import AssignZoneSection from './AssignZoneSection';
import AssignZoneForm from './AssignZoneForm';
import { listAllZoneNames } from '../../services/Zones';
import CancelContract from '../contract/CancelContract';
import UserTypes from '../../config/UserTypes';

const useStyles = makeStyles(agentStyle);

const ZoneCell = ({ manageZoneData, onClose, doHaveDraftAmendment, zoneType }) => {
  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;
  const IS_SUPPORT_LOGIN = userType === UserTypes.SUPPORT.value;

  const classes = useStyles();

  const [addMode, setAddMode] = useState(false);
  const [updateList, setUpdateList] = useState(false);
  const [zoneLoaded, setZoneLoaded] = useState(false);
  const [zoneArr, setZoneArr] = useState([]);
  const [zoneObj, setZoneObj] = useState({});
  const [cancelContractModel, setCancelContractModel] = useState(false);

  useEffect(() => {
    listAllZoneNames(zoneType).then((item) => {
      const tmpzoneObj = {};
      item.data.rows.forEach((zone) => {
        tmpzoneObj[zone.id] = zone.name;
      });
      setZoneObj(tmpzoneObj);
      setZoneArr(Object.entries(tmpzoneObj));
      setZoneLoaded(true);
    });
  }, []);

  const cancelContract = () => {
    setCancelContractModel(true);
  };

  const contractStatusSimilarToCancelled = ['cancelled', 'terminated', 'expired'];
  const showCancelContract = [...contractStatusSimilarToCancelled, 'completed'];

  return (
    <>
      <Dialog open fullWidth maxWidth="md" onClose={onClose}>
        <DialogTitle>
          <div className={classes.titleContainer}>
            <Typography variant="h6">{`Zones of ${manageZoneData.firstName} ${manageZoneData.lastName}`}</Typography>
            <IconButton
              size="small"
              onClick={() => {
                onClose();
              }}
            >
              <Close />{' '}
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {!IS_SUPPORT_LOGIN &&
            manageZoneData.contractStatus &&
            !showCancelContract.includes(manageZoneData.contractStatus) && (
              <Box display="flex" justifyContent="space-between" mb={2} alignItems="center">
                <Typography>
                  The contract has been prepared, please cancel the contract first to update the
                  zone(s).
                </Typography>
                <Button variant="contained" color="secondary" onClick={cancelContract}>
                  Cancel Contract
                </Button>
              </Box>
            )}
          {!IS_SUPPORT_LOGIN &&
            zoneArr.length > 0 &&
            zoneLoaded &&
            (!manageZoneData.contractStatus ||
              contractStatusSimilarToCancelled.includes(manageZoneData.contractStatus || '')) && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={() => {
                  setAddMode(true);
                }}
                className={classes.addZoneButton}
                disabled={manageZoneData.contractStatus === 'completed'}
              >
                Assign New Zone
              </Button>
            )}
          {zoneArr.length === 0 && zoneLoaded && (
            <Alert className={classes.formInput} severity="info">
              There are no zones available for purchase.
            </Alert>
          )}
          {!zoneLoaded && (
            <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
              <CircularProgress size={30} />
            </Box>
          )}
          {addMode && (
            <AssignZoneForm
              agentId={manageZoneData.id}
              agentIsEnrolled={!manageZoneData.allowEnrollSub}
              fromAddAgent={false}
              setAddMode={() => {
                setAddMode();
                setUpdateList(!updateList);
              }}
              zoneType={zoneType}
            />
          )}
          <AssignZoneSection
            doHaveDraftAmendment={doHaveDraftAmendment}
            agentId={manageZoneData.id}
            zoneArr={zoneObj}
            updateList={updateList}
            editable={
              !manageZoneData.contractStatus ||
              contractStatusSimilarToCancelled.includes(manageZoneData.contractStatus || '')
            }
          />
        </DialogContent>
      </Dialog>
      {cancelContractModel && (
        <CancelContract
          handleDialog={() => {
            setCancelContractModel(false);
          }}
          onSuccess={() => {
            onClose();
          }}
          contractId={manageZoneData.contract}
        />
      )}
    </>
  );
};

ZoneCell.propTypes = {
  manageZoneData: PropTypes.objectOf().isRequired,
  onClose: PropTypes.func.isRequired,
  doHaveDraftAmendment: PropTypes.bool,
  zoneType: PropTypes.number.isRequired,
};

ZoneCell.defaultProps = {
  doHaveDraftAmendment: false,
};

export default ZoneCell;
