import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import ListBanners from './ListBanners';
import AddNewBanner from './AddNewBanner';

const ManageBanners = () => {
  const [listRefresh, setListRefresh] = useState(false);
  const [editBannerData, setEditBannerData] = useState({});
  const [deleteId, setDeleteId] = useState(0);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <AddNewBanner
          editData={editBannerData}
          setListRefreshFlag={setListRefresh}
          editBanner={setEditBannerData}
          deleteId={deleteId}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <ListBanners
          editBanner={setEditBannerData}
          setListRefreshFlag={setListRefresh}
          listRefresh={listRefresh}
          setDeleteId={setDeleteId}
        />
      </Grid>
    </Grid>
  );
};

export default ManageBanners;
