/* eslint-disable react/jsx-props-no-spreading */
import { Box, Paper, Tab, Tabs } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PrivateWrapper from '../../layout/Private';
import ContentEngine from '../../components/content-engine/ContentEngine';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ContentEnginePage = () => {
  const pageName = 'Content Engine';
  const [activeTab, setActiveTab] = useState(0);
  const [tabValue, setTabValue] = useState('content');

  useEffect(() => {
    if (tabValue === 'content') {
      setActiveTab(0);
    } else {
      setActiveTab(1);
    }
  }, [tabValue]);

  return (
    <PrivateWrapper pageName={pageName}>
      <Box component={Paper} mt={2} p={2} minHeight="80vh">
        <Box>
          <Tabs
            value={tabValue}
            aria-label="simple tabs example"
            indicatorColor="primary"
            onChange={(_, v) => {
              setTabValue(v);
            }}
          >
            <Tab value="content" label="Content Engine" {...a11yProps(0)} />
          </Tabs>
        </Box>
        <Box>
          {tabValue === 'content' && (
            <TabPanel value={activeTab} index={0}>
              <ContentEngine />
            </TabPanel>
          )}
        </Box>
      </Box>
    </PrivateWrapper>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

export default ContentEnginePage;
