import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import { removeAgent, enableTeamProfile } from '../../services/Agent';
import SnackbarMessage from '../SnackbarMessage';
import { loginAsAgent, removeUser, loginAsTeam } from '../../services/Auth';
import RoutePaths from '../../config/Routes';
import { getCurrentTimestamp } from '../../utils/Datetime';
import { getAgentTypeLabel } from '../../utils/GetModuleType';

const ConfirmDialog = ({
  open,
  deleteId,
  teamProfileId,
  closeModel,
  title,
  onSuccess,
  description,
  action,
  loginId,
  rowAgentType,
  teamMemberId,
}) => {
  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const label = getAgentTypeLabel(rowAgentType);
  const labelWordCase = getAgentTypeLabel(rowAgentType, false, true);

  const handleDialog = () => {
    const localIsAccessorValue = window.localStorage.getItem('isAccessorLoggedIn');
    if (localIsAccessorValue && action !== 'loginAsTeam' && action !== 'revoke') {
      window.location.assign(RoutePaths.HOME);
    } else {
      closeModel();
    }
  };

  const handleConfirmation = (e) => {
    e.preventDefault();
    setProcessing(true);

    if (deleteId) {
      setSnackbarMeesage({
        message: '',
        type: '',
        show: false,
      });
      if (action === 'revoke') {
        removeUser(deleteId)
          .then(() => {
            setProcessing(false);
            handleDialog();
            onSuccess();
          })
          .catch(() => {
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: 'Some error occurred while revoke user access, please try again.',
              type: 'error',
              show: 'error',
            });
            setProcessing(false);
          });
      } else {
        removeAgent(deleteId)
          .then(() => {
            setProcessing(false);
            handleDialog();
            onSuccess();
          })
          .catch(({ response }) => {
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: response.data.message,
              type: 'error',
              show: true,
            });
            setProcessing(false);
          });
      }
    } else if (loginId && action === 'loginAs') {
      loginAsAgent(loginId)
        .then(() => {
          window.location.assign(RoutePaths.HOME);
          setProcessing(false);
          handleDialog();
          onSuccess();
          window.localStorage.setItem('isLoggedIn', true);
          window.localStorage.setItem('isAccessorLoggedIn', getCurrentTimestamp());
          window.localStorage.setItem('isTeamMemberLoggedIn', Number(0));
        })
        .catch((res) => {
          let message = 'Some error occurred, please try again.';
          if (res.message === 'Request failed with status code 400') {
            message = `${labelWordCase} can not be deleted. Please release zones from assigned zones section in order to delete this ${label}.`;

            setTimeout(handleDialog, 2000);
          } else {
            setProcessing(false);
          }
          setSnackbarMeesage({
            ...snackbarMeesage,
            message,
            type: 'error',
            show: 'error',
          });
        });
    } else if (teamMemberId && action === 'loginAsTeam') {
      loginAsTeam(teamMemberId)
        .then(() => {
          window.location.assign(RoutePaths.HOME);
          setProcessing(false);
          handleDialog();
          onSuccess();
          window.localStorage.setItem('isLoggedIn', true);
          window.localStorage.setItem('isAccessorLoggedIn', getCurrentTimestamp());
          window.localStorage.setItem('isTeamMemberLoggedIn', Number(1));
        })
        .catch((res) => {
          let message = 'Some error occurred, please try again.';
          if (res.message === 'Request failed with status code 400') {
            message = `${labelWordCase} can not be deleted. Please release zones from assigned zones section in order to delete this ${label}.`;

            setTimeout(handleDialog, 2000);
          } else {
            setProcessing(false);
          }
          setSnackbarMeesage({
            ...snackbarMeesage,
            message,
            type: 'error',
            show: 'error',
          });
        });
    } else if (teamProfileId) {
      let act = -1;
      if (action === 'EnableProfile') {
        act = 1;
      } else {
        // if (action === 'DisableProfile')
        act = 0;
      }
      enableTeamProfile(teamProfileId, act)
        .then(() => {
          setProcessing(false);
          handleDialog();
          onSuccess();
        })
        .catch((res) => {
          let message = 'Some error occurred, please try again.';
          if (res.message === 'Request failed with status code 400' && act === 1) {
            message = `Status should be active to assign team profile.`;
            setTimeout(handleDialog, 2000);
          } else if (res.message === 'Request failed with status code 400' && act === 0) {
            message = `Delete sub profiles first to disable team profile.`;
            setTimeout(handleDialog, 2000);
          } else {
            setProcessing(false);
          }
          setSnackbarMeesage({
            ...snackbarMeesage,
            message,
            type: 'error',
            show: 'error',
          });
        });
    }
  };

  return (
    <Dialog open={open} onClose={handleDialog}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirmation}
          color="secondary"
          autoFocus
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing}
        >
          {action === 'revoke' ||
          action === 'loginAs' ||
          action === 'EnableProfile' ||
          action === 'DisableProfile' ||
          action === 'loginAsTeam'
            ? 'Yes'
            : 'Delete'}
        </Button>
        <Button onClick={handleDialog} color="primary">
          Cancel
        </Button>
      </DialogActions>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  deleteId: PropTypes.number,
  teamProfileId: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  action: PropTypes.string,
  loginId: PropTypes.string,
  teamMemberId: PropTypes.string,
  rowAgentType: PropTypes.number.isRequired,
};
ConfirmDialog.defaultProps = {
  deleteId: 0,
  title: '',
  action: '',
  loginId: '',
  teamProfileId: 0,
  teamMemberId: '',
};

export default ConfirmDialog;
