import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { paymentFrequencies } from '../../config/DataValues';
import Validations from '../../utils/Validations';

const ContractNoteDialog = ({ closeDialog, onSave, contractSubType }) => {
  const { handleSubmit, control, watch } = useForm();
  const contractNotes = watch('contractNotes');

  const onSubmit = (event) => {
    if (event) {
      if (typeof event.preventDefault === 'function') {
        event.preventDefault();
      }
      if (typeof event.stopPropagation === 'function') {
        // prevents outer forms from submit event
        event.stopPropagation();
      }
    }

    return handleSubmit(async (values) => {
      onSave(values.contractNotes, contractSubType);
    })(event);
  };

  return (
    <Dialog maxWidth="sm" fullWidth open>
      <DialogTitle> {`${paymentFrequencies[contractSubType].label} Contract Notes`}</DialogTitle>
      <DialogContent>
        <form id="contract-notes-form" onSubmit={onSubmit}>
          <Controller
            control={control}
            name="contractNotes"
            rules={Validations.REQUIRED}
            id="contractNotes"
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="standard"
                multiline
                label="Notes (required)"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                fullWidth
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button type="submit" form="contract-notes-form" color="primary" disabled={!contractNotes}>
          Continue
        </Button>
        <Button onClick={closeDialog} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ContractNoteDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  contractSubType: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ContractNoteDialog;
