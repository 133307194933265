import green from '@material-ui/core/colors/green';

// Style for login, logout page
const Style = () => ({
  logo: {
    width: '250px',
    marginTop: 10,
    marginBottom: 10,
  },
  authPageText: {
    marginTop: 15,
    marginBottom: 15,
  },
  authForm: {
    marginBottom: 20,
    marginTop: 20,
  },
  authInput: {
    marginBottom: 15,
    width: '100%',
  },
  authInput50: {
    marginBottom: 15,
    width: '50%',
    float: 'left',
  },
  authSubmitBtn: {
    marginTop: 10,
  },
  loadingContainer: {
    color: green[600],
  },
  loadingText: {
    marginTop: 20,
  },
  greenLinkText: {
    color: green[600],
  },
});

export default Style;
