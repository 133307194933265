import { getApiCall, postApiCall, deleteApiCall } from '../utils/Api';

export const signInUser = async (data) => {
  await postApiCall(`/auth/login`, data);
};

export const signOutUser = async () => {
  await getApiCall(`/auth/logout`);
};

export const validateSession = async () => {
  const response = await getApiCall(`/auth/validate-session`);
  return response.data;
};

export const createUser = async (data) => {
  await postApiCall(`/auth/create`, data);
};

export const viewUserById = async (id) => {
  const result = await getApiCall(`/auth/${id}/view`);
  return result.data;
};

export const removeUser = async (id) => {
  const result = await deleteApiCall(`/auth/${id}/remove`);
  return result.data;
};

export const updateUser = async (id, data) => {
  await postApiCall(`/auth/${id}/update`, data);
};

export const viewMyDetails = async () => {
  const result = await getApiCall(`/auth/me`);
  return result.data;
};

export const updateMyProfile = async (data) => {
  await postApiCall(`/auth/update-profile`, data);
};

export const updateMyPassword = async (data) => {
  await postApiCall(`/auth/change-password`, data);
};

export const resetMyPassword = async (data) => {
  const result = await postApiCall(`/auth/reset-password`, data);
  return result.data;
};

export const validateResetPwdToken = async (token) => {
  const result = await getApiCall(`/auth/${token}/validate-reset-token`);
  return result.data;
};

export const setNewPassword = async (data, token) => {
  const result = await postApiCall(`/auth/${token}/set-new-password`, data);
  return result.data;
};

export const loginAsAgent = async (id) => {
  await getApiCall(`/auth/agent/${id}/login`);
};

export const validateInvitationToken = async (token) => {
  const result = await getApiCall(`/auth/${token}/validate-invitation`);
  return result.data;
};

export const acceptInvitation = async (token, data) => {
  const result = await postApiCall(`/auth/${token}/accept-invitation`, data);
  return result.data;
};

export const createAgentAccount = async (data) => {
  const result = await postApiCall(`/auth/${data.agentId}/create-agent`, data);
  return result.data;
};

export const loginAsAccessor = async () => {
  await getApiCall(`/auth/login-accessor`);
};

export const loginAsTeam = async (memberId) => {
  await getApiCall(`/auth/team/${memberId}/login`);
};
