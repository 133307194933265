import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Tab,
  Tabs,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Add } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import SnackbarMessage from '../SnackbarMessage';
import formInput from '../../theme/styles/FormInput';
import { addNewAgent, editAgent, viewAgentById } from '../../services/Agent';
import UserTypes from '../../config/UserTypes';
import AddAgentForm from './AddAgentForm';
import AssignZoneSection from './AssignZoneSection';
import AssignZoneForm from './AssignZoneForm';
import listAll from '../../services/Zones';
import agentStyle from '../../theme/styles/Agents';
import PrepareContract from '../contract/PrepareContract';
import BioHtmlFiltered from './BioHtmlFiltered';
import { getAgentTypeLabel } from '../../utils/GetModuleType';
import { agentTypeValues } from '../../config/DataValues';

const useStyles = makeStyles(formInput);
const useStyles2 = makeStyles(agentStyle);

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const FormDialog = ({ open, editId, closeModel, title, onSuccess, module }) => {
  const classes = useStyles();
  const classes2 = useStyles2();

  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;
  const loggedInUserId = getDetails ? Number(JSON.parse(getDetails).id) : 0;

  const defaultUser = {
    id: 0,
    firstName: '',
    lastName: '',
  };
  const loggedInUser = getDetails ? JSON.parse(getDetails) : defaultUser;

  const subStart = new Date();
  const contractExp = new Date();
  const defaultContractStatus = {
    subTotal: 0,
    discount: 0,
    total: 0,
    subType: 2,
    subStartDate: new Date(subStart.setDate(subStart.getDate() + 14)),
    contractExpiryDate: new Date(contractExp.setDate(contractExp.getDate() + 30)),
  };

  const [contractNotes, setContractNotes] = useState('');

  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const [tabValue, setTabValue] = useState(0);

  const [tempAssignedZoneData, setTempAssignedZoneData] = useState([]);

  const [zoneLoaded, setZoneLoaded] = useState(false);
  const [zoneArr, setZoneArr] = useState([]);
  const [zoneObj, setZoneObj] = useState({});
  const [addMode, setAddMode] = useState(false);
  const [updateList, setUpdateList] = useState(false);
  const [userProfile, setUserProfile] = useState('');
  const [uploading, setUploading] = useState(false);
  const [assetId, setAssetId] = useState(0);
  const [contractValues, setContractValues] = useState(defaultContractStatus);
  const [contractZoneData, setContractZoneData] = useState([]);
  const [assetStatus, setAssetStatus] = useState('');
  const [contractValueError, setContractValueError] = useState(false);
  const [hasSignedOrPaidContract, setHasSignedOrPaidContract] = useState(false);

  const [showHtmlFilterWarning, setShowHtmlFilterWarning] = useState(false);
  const [reloadForm, setReloadForm] = useState(1);

  const label = getAgentTypeLabel(module);
  const labelWordCase = getAgentTypeLabel(module, false, true);

  let createContractWithAgent = false;
  let onNextClick = false;

  useEffect(() => {
    listAll(null, null, module).then((item) => {
      const tmpzoneObj = {};
      item.data.rows.forEach((zone) => {
        tmpzoneObj[zone.id] = zone.name;
      });
      setZoneObj(tmpzoneObj);
      setZoneArr(Object.entries(tmpzoneObj));
      setZoneLoaded(true);
    });
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const { handleSubmit, control, setValue, reset, getValues } = useForm();

  const isSalesPerson = userType === UserTypes.SALES_PERSON.value;

  const setCreateAgentWithContract = (val) => {
    createContractWithAgent = val;
  };

  useEffect(() => {
    const name = `${loggedInUser.firstName} ${loggedInUser.lastName}`;
    const salesPersonValue = isSalesPerson
      ? Number(loggedInUser.id)
      : { id: Number(loggedInUser.id), name };

    setValue('salesPersonId', salesPersonValue);
    if (editId) {
      viewAgentById(editId).then((res) => {
        setValue('firstName', res.data.firstName);
        setValue('lastName', res.data.lastName);
        setValue('status', res.data.status);
        setValue('email', res.data.email);
        setValue('phone', res.data.phone);
        setValue('facebook', res.data.facebook);
        setValue('twitter', res.data.twitter);
        setValue('instagram', res.data.instagram);
        setValue('youtube', res.data.youtube);
        setValue('pinterest', res.data.pinterest);
        setValue('tiktok', res.data.tiktok);
        setValue('linkedin', res.data.linkedin);
        setValue('website', res.data.website);
        setValue('stateCodeId', res.data.state.id ? res.data.state : '');
        setValue('referredBy', res.data.referredBy.id ? res.data.referredBy : '');
        setValue('salesPersonId', res.data.salesPerson);
        setValue('location', res.data.location);
        setValue('bioText', res.data.bio);
        setAssetId(res.data.assetId);
        setAssetStatus(res.data.assetStatus);
        setUserProfile(res.data.profilePic);
        setHasSignedOrPaidContract(res.data.signedOrPaidContract);
      });
    } else {
      setValue('status', 0);
    }
  }, [reloadForm]);

  const handleDialog = () => {
    reset();
    closeModel();
  };

  const submitForm = ({
    firstName,
    lastName,
    email,
    phone,
    facebook,
    twitter,
    instagram,
    youtube,
    pinterest,
    tiktok,
    linkedin,
    website,
    status,
    stateCodeId,
    salesPersonId,
    bioText,
    location,
    referredBy,
    sendInvitation,
  }) => {
    if (!onNextClick) {
      setSnackbarMeesage({
        ...snackbarMeesage,
        message: '',
        type: '',
        show: false,
      });
      setProcessing(true);
      const payload = {
        firstName,
        lastName,
        email,
        phone,
        facebook: facebook || null,
        twitter: twitter || null,
        instagram: instagram || null,
        youtube: youtube || null,
        pinterest: pinterest || null,
        tiktok: tiktok || null,
        linkedin: linkedin || null,
        website: website || null,
        stateCodeId: stateCodeId ? Number(stateCodeId.id) : null,
        referredBy: referredBy ? Number(referredBy.id) : null,
        status: Number(status),
        bioText: bioText || '',
        location,
        profilePic: userProfile,
      };
      if (module === agentTypeValues.CE_USERS) {
        if (editId) {
          payload.salesPersonId = getValues('salesPersonId')?.id || 0;
        } else {
          payload.salesPersonId = isSalesPerson
            ? Number(loggedInUserId)
            : Number(salesPersonId?.id || 0);
        }
      } else {
        payload.salesPersonId = isSalesPerson ? Number(loggedInUserId) : Number(salesPersonId.id);
      }

      if (editId) {
        editAgent(editId, payload)
          .then((res) => {
            setProcessing(false);
            if (res.data.hasBioFiltered) {
              setReloadForm(reloadForm + 1);
              setShowHtmlFilterWarning(true);
            } else {
              handleDialog();
              onSuccess();
            }
          })
          .catch(({ response }) => {
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: response.data.message,
              type: 'error',
              show: true,
            });
            setProcessing(false);
          });
      } else {
        payload.email = email;
        payload.sendInvitation = sendInvitation;
        payload.assignZone = [];
        if (tempAssignedZoneData.length > 0) {
          payload.assignZone = tempAssignedZoneData.map((zone) => ({
            zoneId: zone.purchasedZone,
            paymentStatus: zone.paymentStatus === 1 ? 'paid' : 'hold',
            price: zone.nrpFee,
            purchaseType: zone.exclusivity,
            soldBy: zone.soldBy,
            status: zone.status,
          }));
        }
        if (createContractWithAgent) {
          const contractExpTmp = new Date();
          payload.contractDetails = {
            ...contractValues,
            subStartDate: contractValues.subStartDate,
            contractExpiryDate:
              contractValues.contractExpiryDate ||
              new Date(contractExpTmp.setDate(contractExpTmp.getDate() + 30)),
            contractNotes,
          };
          payload.contractZoneData = contractZoneData.map((zone) => ({
            zoneId: zone.purchasedZone,
            price: zone.nrpFee,
            purchaseType: zone.exclusivity,
          }));
        }
        addNewAgent(payload, module)
          .then((res) => {
            if (res.data.errorProcessZone) {
              setSnackbarMeesage({
                ...snackbarMeesage,
                message: res.data.errorProcessZone,
                type: 'error',
                show: true,
              });
              setTimeout(() => {
                setProcessing(false);
                handleDialog();
                onSuccess();
              }, 3000);
            } else {
              if (createContractWithAgent) {
                setSnackbarMeesage({
                  ...snackbarMeesage,
                  message: res.data.contractMessage,
                  type: res.data.contractStatus ? 'success' : 'error',
                  show: true,
                });
              }
              if (sendInvitation && !createContractWithAgent) {
                setSnackbarMeesage({
                  ...snackbarMeesage,
                  message: res.data.invitationMessage,
                  type: res.data.invitationStatus ? 'success' : 'error',
                  show: true,
                });
              }

              setTimeout(
                () => {
                  setProcessing(false);
                  handleDialog();
                  onSuccess();
                },
                sendInvitation || createContractWithAgent ? 3000 : 0
              );
            }
          })
          .catch(({ response }) => {
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: response.data.message,
              type: 'error',
              show: true,
            });
            setProcessing(false);
          });
      }
    } else {
      if (tabValue === 0) {
        handleTabChange('_', tabValue + 1);
      }

      const filterHoldStatusZones = tempAssignedZoneData.filter((z) => z.paymentStatus === 2);
      if (tabValue === 1 && tempAssignedZoneData.length > 0 && filterHoldStatusZones.length > 0) {
        setContractValues(defaultContractStatus);
        setContractZoneData(filterHoldStatusZones);
        handleTabChange('_', tabValue + 1);
      }
      onNextClick = false;
    }
  };

  const checkLocalValidationForZone = (zone) => {
    const findExisting = tempAssignedZoneData.filter((z) => z.purchasedZone === zone.purchasedZone);
    if (findExisting.length === 2) {
      return { status: false, message: 'The selected zone is already occupied.' };
    }
    if (findExisting.length === 1) {
      if (findExisting[0].exclusivity === 1) {
        return { status: false, message: 'The selected zone is already occupied.' };
      }
      if (zone.exclusivity === 2 && findExisting[0].exclusivity === 2) {
        return {
          status: false,
          message:
            'The selected zone is already purchased as semi-exclusively by you, please change the exclusivity of purchased zone.',
        };
      }
      if (zone.exclusivity === 1 && findExisting[0].exclusivity === 2) {
        return {
          status: false,
          message: `The selected zone is already purchased by other ${label}, please change the exclusivity.`,
        };
      }
      if (zone.exclusivity === 2 && findExisting[0].exclusivity === 1) {
        return {
          status: false,
          message: 'The selected zone is already occupied.',
        };
      }
      return {
        status: false,
        message: `The selected zone is already purchased by other ${label}, please change the exclusivity.`,
      };
    }
    return { status: true };
  };

  const defaultFormValues = getValues();

  const onFormError = () => {
    if (tabValue === 1) {
      handleTabChange(null, 0);
    }
  };

  const handleCloseHtmlFilteredNotice = () => {
    setShowHtmlFilterWarning(false);
  };

  const disableNextButton =
    tabValue === 1 && tempAssignedZoneData.filter((z) => z.paymentStatus === 2).length === 0;

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={`${classes.dialogContent} ${classes.dialogContentNoTopPadding}`}>
          <form id="add-agent" onSubmit={handleSubmit(submitForm, onFormError)}>
            <Paper elevation={0}>
              <Tabs value={tabValue} aria-label="agent tabs" indicatorColor="primary">
                <Tab label={`${labelWordCase} Details`} {...a11yProps(0)} />
                {!editId && module !== agentTypeValues.CE_USERS && (
                  <Tab label="Assign Zone" {...a11yProps(1)} />
                )}
                {!editId && module !== agentTypeValues.CE_USERS && (
                  <Tab label="Prepare Contract" {...a11yProps(2)} />
                )}
              </Tabs>
            </Paper>
            <TabPanel value={tabValue} index={0}>
              <AddAgentForm
                agentId={editId || null}
                assetId={assetId}
                control={control}
                defaultFormValues={defaultFormValues}
                userProfile={userProfile}
                setUserProfile={setUserProfile}
                setUploading={setUploading}
                assetStatus={assetStatus}
                hasSignedOrPaidContract={hasSignedOrPaidContract}
                module={module}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              {zoneArr.length > 0 && zoneLoaded && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  onClick={() => {
                    setAddMode(true);
                  }}
                  className={classes2.addZoneButton}
                >
                  Assign New Zone
                </Button>
              )}
              {zoneArr.length === 0 && zoneLoaded && (
                <Alert className={classes.formInput} severity="info">
                  There are no zones available for purchase.
                </Alert>
              )}
              {!zoneLoaded && (
                <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                  <CircularProgress size={30} />
                </Box>
              )}
              <AssignZoneSection
                agentId={0}
                fromAddAgent
                setTempAssignedZoneData={setTempAssignedZoneData}
                tempAssignedZoneData={tempAssignedZoneData}
                updateList={updateList}
                zoneArr={zoneObj}
                editable
              />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <PrepareContract
                agentId={0}
                fromAddAgent
                setContractZoneData={setContractZoneData}
                contractZoneData={contractZoneData}
                updateList={updateList}
                setUpdateList={setUpdateList}
                zoneArr={zoneObj}
                agentDetails={{
                  firstName: getValues().firstName,
                  lastName: getValues().lastName,
                  email: getValues().email,
                  phone: getValues().phone,
                  location: getValues().location,
                }}
                setContractValues={setContractValues}
                contractValues={contractValues}
                contractValueError={setContractValueError}
                contractNotes={contractNotes}
                setContractNotes={setContractNotes}
                zoneType={module}
              />
            </TabPanel>
          </form>

          {addMode && (
            <AssignZoneForm
              agentId={0}
              fromAddAgent
              setAddMode={() => {
                setAddMode(false);
                setUpdateList(!updateList);
              }}
              setTempStateValues={(obj) => {
                const updated = [...tempAssignedZoneData];
                updated.push(obj);
                setTempAssignedZoneData(updated);
              }}
              agentIsEnrolled
              checkLocalValidationForZone={checkLocalValidationForZone}
              zoneType={module}
            />
          )}
        </DialogContent>
        {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}

        <DialogActions className={classes2.spaceBetween}>
          <div>
            {!editId && module !== agentTypeValues.CE_USERS && (
              <Button
                variant="contained"
                color="primary"
                disableElevation
                type="submit"
                endIcon={processing && <CircularProgress size={25} />}
                disabled={processing || uploading}
                form="add-agent"
                className={classes2.addAgentFormBtn}
                onClick={() => {
                  onNextClick = false;
                }}
              >
                Add {labelWordCase}
              </Button>
            )}
          </div>
          <div>
            {tabValue > 0 && module !== agentTypeValues.CE_USERS && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  handleTabChange('_', tabValue - 1);
                  onNextClick = false;
                }}
                disableElevation
                className={classes2.addAgentFormBtn}
              >
                Previous
              </Button>
            )}
            {(tabValue === 0 || tabValue === 1) && !editId && module !== agentTypeValues.CE_USERS && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    onNextClick = true;
                  }}
                  type="submit"
                  form="add-agent"
                  disableElevation
                  className={classes2.addAgentFormBtn}
                  disabled={disableNextButton}
                >
                  Next
                </Button>
              </>
            )}
            {tabValue === 2 && module !== agentTypeValues.CE_USERS && (
              <Button
                variant="contained"
                color="primary"
                disableElevation
                type="submit"
                endIcon={processing && <CircularProgress size={25} />}
                disabled={
                  processing ||
                  uploading ||
                  contractZoneData.length === 0 ||
                  contractValueError ||
                  (contractValues.subType === 1 && !contractNotes)
                }
                form="add-agent"
                className={classes2.addAgentFormBtn}
                onClick={() => {
                  setCreateAgentWithContract(true);
                  onNextClick = false;
                }}
              >
                Create Contract
              </Button>
            )}
            {(editId || module === agentTypeValues.CE_USERS) && (
              <Button
                variant="contained"
                color="primary"
                disableElevation
                type="submit"
                endIcon={processing && <CircularProgress size={25} />}
                disabled={processing || uploading}
                form="add-agent"
                className={classes2.addAgentFormBtn}
              >
                Submit
              </Button>
            )}
            <Button variant="contained" onClick={handleDialog} disableElevation>
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      {showHtmlFilterWarning && <BioHtmlFiltered closeModel={handleCloseHtmlFilteredNotice} />}
    </>
  );
};

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  editId: PropTypes.number,
  title: PropTypes.string,
  module: PropTypes.number.isRequired,
};
FormDialog.defaultProps = {
  editId: 0,
  title: '',
};

export default FormDialog;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      <Box pt={3}>{children}</Box>
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
