import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import Menubar from '../components/Menubar';
import wrapperStyle from '../theme/styles/LayoutWrapper';
import UserTypes from '../config/UserTypes';

const useStyles = makeStyles(wrapperStyle);

// Layout for authorized pages
const Private = ({ pageName, children }) => {
  const classes = useStyles();
  const history = useHistory();
  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;
  const isAccessorStripVisible = window.localStorage.getItem('isAccessorLoggedIn');

  useEffect(() => {
    setTimeout(() => {
      if (userType === UserTypes.AGENT.value) {
        window.FreshworksWidget('show');
        const agentData = JSON.parse(getDetails);
        window.FreshworksWidget('identify', 'ticketForm', {
          name: `${agentData.firstName || ''} ${agentData.lastName || ''}`,
          email: agentData.email || '',
        });
      } else {
        window.FreshworksWidget('hide');
      }
    }, 500);
  }, []);

  useEffect(() => {
    if (!localStorage.getItem('isLoggedIn')) {
      history.push('/logout');
    }
  }, [pageName]);

  return (
    <Container className={classes.wrapper} maxWidth="xl">
      <Menubar pageName={pageName} />

      <div className={classes.content}>
        <div
          className={classes.toolbar}
          style={isAccessorStripVisible ? { marginTop: 40 } : null}
        />
        {children}
      </div>
    </Container>
  );
};

Private.propTypes = {
  pageName: PropTypes.string,
  children: PropTypes.element.isRequired,
};
Private.defaultProps = {
  pageName: '',
};

export default Private;
