import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import orange from '@material-ui/core/colors/orange';

// Style for table listing
const Style = (theme) => ({
  filterToolbar: {
    display: 'flex',
    marginTop: 30,
    marginBottom: 20,
  },
  tableData: {
    width: '100%',
    '& .MuiTableCell-root': {
      padding: 8,
      fontSize: '0.75rem',
    },
  },
  tablePagination: {
    marginTop: 20,
    marginBottom: 20,
  },
  statusActive: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: green[600],
    color: 'white',
  },
  statusPending: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: yellow[500],
    color: 'black',
  },
  statusInactive: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: red[500],
    color: 'white',
  },
  statusPendingCancelation: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: orange[300],
    color: 'black',
  },
  deleteBtn: {
    padding: 8,
  },
  editBtn: {
    padding: 8,
    marginRight: 5,
  },
  activeBtn: {
    padding: 8,
    marginRight: 5,
    color: green[600],
  },
  placeholderSkeleton: {
    marginTop: 5,
    marginBottom: 5,
  },
  noRecord: {
    color: 'red',
  },
  linkText: {
    color: green[600],
    fontSize: '0.9rem',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  sortDefaultIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
  greenText: {
    color: green[600],
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
  contractStatus: {
    fontSize: '0.9rem',
  },
  paymentPageCell: {
    padding: 8,
  },
  agentOverviewTable: {
    marginTop: 20,
    '& .MuiTableCell-root': {
      padding: '10px 0px 10px 0px',
    },
  },
  agentOverviewButtons: {
    borderRadius: 20,
    marginBottom: 10,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: green[500],
      color: 'white',
    },
  },
  tableDataAgentZones: {
    width: '100%',
    '& .MuiTableCell-root': {
      padding: 10,
      fontSize: '0.75rem',
    },
    '& .MuiTableCell-head': {
      background: 'none',
      color: green[500],
      backgroundColor: 'white',
    },
  },
  agentZoneIcon: {
    fontSize: 30,
    color: green[500],
    backgroundColor: green[100],
    borderRadius: 15,
    padding: 5,
    marginRight: 10,
  },
  odisIndicatorOn: {
    fontSize: 30,
    color: blue[500],
    backgroundColor: blue[100],
    borderRadius: 15,
    padding: 5,
    marginRight: 10,
  },
  odisIndicatorOff: {
    fontSize: 30,
    color: grey[500],
    backgroundColor: grey[100],
    borderRadius: 15,
    padding: 5,
    marginRight: 10,
  },
  odisIndicatorLabelOn: {
    color: blue[500],
  },
  odisIndicatorLabelOff: {
    color: grey[500],
  },
  statusSegmentOpen: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: '#FED965',
    color: 'black',
    minWidth: 50,
  },
  statusSegmentScheduled: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: '#9FC5E8',
    color: 'black',
    minWidth: 50,
  },
  statusSegmentClosed: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: '#EA9A99',
    color: 'black',
    minWidth: 50,
  },
});

export default Style;
