/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Typography, Tooltip } from '@material-ui/core';
import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import ScheduledSegments from '../segments/ScheduledSegments';
import PastSegments from '../segments/PastSegments';
import { segmentVideoUploadGuidelines } from '../../config/DataValues';
import { trackUserClick } from '../../utils/Activity';

const AgentShowSegments = () => {
  const openGuidelines = () => {
    trackUserClick(102);
    window.open(segmentVideoUploadGuidelines, '_blank');
  };

  return (
    <Box>
      <Box mb={4}>
        <Box mb={2}>
          <Typography variant="h5" style={{ fontWeight: 500 }}>
            Be part of our show, Negotiators: Luxe!
            <a href="#" onClick={openGuidelines} style={{ marginLeft: 10 }} rel="noreferrer">
              <Tooltip title="Click to download full guidelines">
                <GetAppIcon color="primary" style={{ position: 'relative', top: 5 }} />
              </Tooltip>
            </a>
          </Typography>
        </Box>
        <ScheduledSegments />
      </Box>
      <Box style={{ marginTop: 40 }}>
        <Box mb={2}>
          <Typography variant="h5" style={{ fontWeight: 500 }}>
            Your past submissions
          </Typography>
        </Box>
        <PastSegments />
      </Box>
    </Box>
  );
};

export default AgentShowSegments;
