export const setBioCheckRealtorLastChecked = () => {
  const d = new Date();
  d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `realtor-check=true;${expires};path=/`;
};

export const getBioCheckRealtorLastChecked = () => {
  const name = 'realtor-check=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
};

const bioContainRealtorWord = (data, wrapper) => {
  if (!getBioCheckRealtorLastChecked()) {
    setBioCheckRealtorLastChecked();
    const returnVal = data ? data.match(/Realtor/i) : false;

    if (returnVal) {
      let text = window.document.getElementById(wrapper).innerHTML;
      const re = new RegExp('Realtor', 'g');
      text = text.replace(re, `<mark>Realtor</mark>`);
      const re1 = new RegExp('realtor', 'g');
      text = text.replace(re1, `<mark>realtor</mark>`);
      window.document.getElementById(wrapper).innerHTML = text;
      return true;
    }
  }
  return false;
};

export default bioContainRealtorWord;
