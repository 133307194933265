import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';

const WarningDialog = ({ title, description, closeModel }) => {
  const handleDialog = () => {
    closeModel();
  };

  return (
    <Dialog open onClose={handleDialog}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialog} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

WarningDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  closeModel: PropTypes.func.isRequired,
};

export default WarningDialog;
