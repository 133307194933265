import createUserActivity from '../services/Activity';

const storeUserActivity = (payload) => {
  createUserActivity(payload);
};

export const trackUserClick = (objId, refData = null) => {
  if (objId) {
    const payload = {
      objectListMasterId: objId,
    };
    if (refData) {
      payload.refData = refData;
    }
    storeUserActivity(payload);
  }
};

export default storeUserActivity;
