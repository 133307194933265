import { Paper, Container, Box, Typography, Grid, Button } from '@material-ui/core';
import React from 'react';
import { ArrowRightAlt } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import UpdateProfileForm from '../../components/auth/UpdateProfileForm';
import UserTypes from '../../config/UserTypes';
import Private from '../../layout/Private';
// import AgentUpdateProfileForm from '../../components/auth/AgentUpdateProfileForm';
import RoutePaths from '../../config/Routes';
import ManageProfiles from '../../components/auth/ManageProfiles';

const UpdateProfile = () => {
  const pageName = 'Web Profile';
  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;
  const isAgentLogin = Number(userType) === UserTypes.AGENT.value;

  return (
    <Private pageName={pageName}>
      <Box pt={4}>
        {!isAgentLogin && (
          <Container component={Paper}>
            <Grid container>
              <Grid item xs={12}>
                <Box mb={4} mt={3}>
                  <Box mb={3} display="flex" justifyContent="space-between">
                    <Typography variant="h5">Manage Profile</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<ArrowRightAlt />}
                      disableElevation
                      component={Link}
                      to={RoutePaths.CHANGE_PASSWORD}
                    >
                      Change Password
                    </Button>
                  </Box>
                  <UpdateProfileForm />
                </Box>
              </Grid>
            </Grid>
          </Container>
        )}
        {isAgentLogin && (
          <Box component={Paper} p={2}>
            <ManageProfiles />
          </Box>
        )}
      </Box>
    </Private>
  );
};

export default UpdateProfile;
