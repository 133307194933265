/* eslint-disable no-nested-ternary */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import Validations from '../../utils/Validations';
import { addInquiryNote, updateInquiryNote, deleteInquiryNote } from '../../services/Inquiries';

const InquiryNote = ({ closeDialog, onSave, id, editId, text, deleteNote }) => {
  const [processing, setProcessing] = useState(false);

  const { handleSubmit, control, watch } = useForm();
  const notes = watch('notes');

  const submitForm = (data) => {
    setProcessing(true);
    if (editId) {
      updateInquiryNote(editId, { notes: data.notes })
        .then(() => {
          onSave();
        })
        .catch(() => {
          closeDialog();
        });
    } else {
      addInquiryNote(id, { notes: data.notes })
        .then(() => {
          onSave();
        })
        .catch(() => {
          closeDialog();
        });
    }
  };

  const checkFormKeyDown = (e) => {
    const keyCode = e.keyCode ? e.keyCode : e.which;
    if (keyCode === 13 && e.shiftKey) {
      handleSubmit(submitForm)();
    }
  };

  const handleDeleteCall = () => {
    setProcessing(true);
    deleteInquiryNote(editId)
      .then(() => {
        onSave();
      })
      .catch(() => {
        closeDialog();
      });
  };

  return (
    <Dialog maxWidth="sm" fullWidth open>
      <DialogTitle>{deleteNote ? 'Delete' : editId === 0 ? 'Add' : 'Edit'} A Note</DialogTitle>
      <DialogContent>
        {deleteNote === false && (
          <form id="notes-form" onSubmit={handleSubmit(submitForm)}>
            <Controller
              control={control}
              name="notes"
              rules={Validations.REQUIRED}
              id="notes"
              defaultValue={text}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  variant="standard"
                  multiline
                  label="Notes (required)"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  fullWidth
                  onKeyDown={checkFormKeyDown}
                />
              )}
            />
          </form>
        )}
        {deleteNote && 'Do you want to delete this note? This action can not be undone.'}
      </DialogContent>
      <DialogActions>
        {deleteNote === false && (
          <>
            <Button type="submit" form="notes-form" color="primary" disabled={!notes || processing}>
              Save
            </Button>
            <Button onClick={closeDialog} color="secondary">
              Cancel
            </Button>
          </>
        )}
        {deleteNote && (
          <>
            <Button
              type="submit"
              form="notes-form"
              color="primary"
              disabled={processing}
              onClick={handleDeleteCall}
            >
              Yes
            </Button>
            <Button onClick={closeDialog} color="secondary">
              No
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

InquiryNote.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  editId: PropTypes.number,
  text: PropTypes.string,
  deleteNote: PropTypes.bool,
};
InquiryNote.defaultProps = {
  editId: 0,
  text: '',
  deleteNote: false,
};

export default InquiryNote;
