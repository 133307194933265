/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import VideoPlayer from 'react-video-js-player';
import { trackUserClick } from '../../utils/Activity';
import { viewById } from '../../services/Resource';

const ViewResource = ({ onClose, userId, id }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [viewData, setViewData] = useState({});

  useEffect(() => {
    viewById(id)
      .then((res) => {
        setViewData(res.data);
        setTimeout(() => setDataLoaded(true), 500);
      })
      .catch(() => {});
  }, []);

  const handleDownload = (docId, url) => {
    trackUserClick(112, {
      agent_id: userId,
      resource_id: id,
      nrc_resources_supplementary_docs_id: docId,
    });
    window.open(url, '_blank');
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      onClose={onClose}
      style={{ maxWidth: 720, margin: '0 auto', padding: 0 }}
    >
      <DialogContent>
        {!dataLoaded && (
          <div style={{ width: '100%', textAlign: 'center', marginTop: 200, marginBottom: 200 }}>
            Loading preview
          </div>
        )}
        {dataLoaded && (
          <>
            <div
              style={{
                position: 'relative',
                width: '600px',
                margin: '0px auto',
                textAlign: 'center',
              }}
            >
              {viewData.type === 0 && (
                <img
                  src={viewData.fileUrl}
                  alt=""
                  style={{ maxWidth: '600px', maxHeight: '500px' }}
                />
              )}

              {viewData.type === 1 && (
                <VideoPlayer
                  controls
                  src={viewData.fileUrl}
                  width="600"
                  config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                />
              )}
            </div>

            <div
              style={{
                position: 'relative',
                width: '600px',
                margin: '0px auto',
                padding: 5,
                marginTop: '-10px',
              }}
            >
              <p style={{ fontSize: '16px', lineHeight: '20px', fontWeight: 'bold' }}>
                {viewData.title}
              </p>
              {viewData.description !== '' && (
                <p style={{ marginBottom: 20 }}>{viewData.description}</p>
              )}

              {viewData.resourceDocuments.map((d) => (
                <>
                  <Button
                    size="small"
                    startIcon={<GetAppIcon />}
                    onClick={() => handleDownload(d.id, d.url)}
                    style={{ marginBottom: '2px' }}
                  >
                    {d.fileName}
                  </Button>
                  <br />
                </>
              ))}
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disableElevation
          color="default"
          variant="contained"
          onClick={onClose}
          style={{ marginRight: 15 }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ViewResource.propTypes = {
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
};

export default ViewResource;
