/* eslint-disable no-unused-vars */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { createDraftAmendment } from '../../services/Contract';
import SnackbarMessage from '../SnackbarMessage';

const AmendContractConfirmDialog = ({ handleDialog, contractId, onSuccess }) => {
  const [processing, setProcessing] = useState(false);
  // const [isShowYesButton, setIsShowYesButton] = useState(true);
  const [dialogMessage, setdialogMessage] = useState(
    'Do you want to amend the contract? This will create a copy of current contract which you can edit further for preparing amendment.'
  );
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleConfirmation = () => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });

    createDraftAmendment({ id: contractId })
      .then(() => {
        setProcessing(false);
        onSuccess();
        handleDialog();
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
        setProcessing(false);
      });
    setProcessing(true);
    // checkOverdueInvoices()
    //   .then((res) => {
    //     if (!res.data) {
    //       setIsShowYesButton(false);
    //       setdialogMessage(
    //         'Amendment of the contract could not go through because of overdue payment on existing contract. Request to clear the pending due on existing contract to send amended copy of the contract.'
    //       );
    //       setProcessing(false);
    //     } else {
    //       createDraftAmendment({ id: contractId })
    //         .then(() => {
    //           setProcessing(false);
    //           onSuccess();
    //           handleDialog();
    //         })
    //         .catch(({ response }) => {
    //           setSnackbarMeesage({
    //             ...snackbarMeesage,
    //             message: response.data.message,
    //             type: 'error',
    //             show: true,
    //           });
    //           setProcessing(false);
    //         });
    //       setProcessing(true);
    //     }
    //   })
    //   .catch(({ res }) => {
    //     setSnackbarMeesage({
    //       ...snackbarMeesage,
    //       message: res.data.message,
    //       type: 'error',
    //       show: true,
    //     });
    //     setProcessing(false);
    //   });
    // setProcessing(true);
  };

  return (
    <Dialog open onClose={handleDialog}>
      <DialogTitle>Amend Contract</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirmation}
          color="secondary"
          autoFocus
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing}
        >
          Yes
        </Button>

        <Button onClick={handleDialog} color="primary">
          No
        </Button>
      </DialogActions>

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

AmendContractConfirmDialog.propTypes = {
  handleDialog: PropTypes.func.isRequired,
  contractId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
export default AmendContractConfirmDialog;
