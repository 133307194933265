/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ArrowDownward, ImportExport, MoreVert } from '@material-ui/icons';
import PrivateWrapper from '../../layout/Private';
import TableToolbar from '../../components/TableToolbar';
import listingStyle from '../../theme/styles/TableListing';
import listAll from '../../services/Team';
import FormDialog from '../../components/team/FormDialog';
import ConfirmDialog from '../../components/team/ConfirmDialog';
import UserForm from '../../components/team/UserForm';
import PurchaseCell from '../../components/team/PurchaseCell';
import AgentCell from '../../components/team/AgentCell';
import SnackbarMessage from '../../components/SnackbarMessage';
import { resetMyPassword, viewUserById } from '../../services/Auth';
import UserTypes from '../../config/UserTypes';
import { agentTypeValues, zoneTypeValues } from '../../config/DataValues';

const useStyles = makeStyles(listingStyle);

// Team management
const Team = () => {
  const pageName = 'Team';
  const classes = useStyles();

  const history = useHistory();

  const getDetails = window.localStorage.getItem('userDetail');
  const loggedInUserId = getDetails ? Number(JSON.parse(getDetails).id) : 0;
  const loggedInUserType = getDetails ? Number(JSON.parse(getDetails).type) : 0;

  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reloadRows, setReloadRows] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const [openModel, setOpenModel] = useState(false);
  const [confirmModel, setConfirmModel] = useState(false);
  const [userModel, setUserModel] = useState(false);

  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [accessEmail, setAccessEmail] = useState(null);
  const [userId, setUserId] = useState(null);

  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [processingRequestResetPwd, setProcessingRequestResetPwd] = useState(false);
  const columns = [
    { name: 'Name / Email', id: 'name', width: '17%' },
    { name: 'Type', id: 'type', width: '7%' },
    { name: 'Status', id: 'status', width: '7%', align: 'center' },
    { name: 'Agents', id: 'agents', width: '7%', align: 'right' },
    { name: 'E-Z Sold', id: 'excSoldZone', width: '8%', align: 'right', title: 'Exc Zones Sold' },
    { name: 'SE-Z Sold', id: 'semiExcSoldZone', width: '8%', align: 'right', title: 'Semi Exc Zones Sold', },
    { name: 'Z On Hold', id: 'holdZone', width: '8%', align: 'right', title: 'On Hold Zones' },
    { name: 'Lenders', id: 'lenders', width: '7%', align: 'right' },
    { name: 'E-Z Sold', id: 'excSoldLenderZone', width: '8%', align: 'right', title: 'Exc Lender Zones Sold' },
    { name: 'SE-Z Sold', id: 'semiExcSoldLenderZone', width: '8%', align: 'right', title: 'Semi Exc Lender Zones Sold' },
    { name: 'Z On Hold', id: 'holdLenderZone', width: '8%', align: 'right', title: 'On Hold Lender Zones' },
  ];

  const getParams = () => {
    let queryString = '1=1';
    if (sortBy) queryString += `&sortBy=${sortBy}`;
    if (orderBy) queryString += `&orderBy=${orderBy}`;
    if (searchText) queryString += `&searchText=${searchText}`;

    return queryString.substring(4);
  };

  useEffect(() => {
    setDataLoaded(false);
    listAll(activePage, getParams())
      .then((res) => {
        setRows(res.data.rows);
        setTotalPages(res.data.totalPages);
        setDataLoaded(true);
      })
      .catch(() => {
        setDataLoaded(true);
      });
  }, [reloadRows, activePage]);

  const searchList = () => {
    setActivePage(1);
    setReloadRows(!reloadRows);
  };

  const sortHandler = (colId) => {
    setActivePage(1);
    setSortBy(colId);
    setOrderBy(orderBy === 'asc' ? 'desc' : 'asc');
    setReloadRows(!reloadRows);
  };

  const refreshList = () => {
    setReloadRows(!reloadRows);
  };

  const editData = (id) => {
    setEditId(id);
    setOpenModel(true);
  };

  const deleteData = (id) => {
    setDeleteId(id);
    setConfirmModel(true);
  };
  const userAccess = (email, id, uid) => {
    setAccessEmail(email);
    setEditId(id);
    setUserId(uid);
    setUserModel(true);
  };

  const resetList = () => {
    setSearchText('');
    setOrderBy('');
    setSortBy('');
    setReloadRows(!reloadRows);
    setActivePage(1);
    history.push('/team');
  };

  const requestResetPassword = (id) => {
    setProcessingRequestResetPwd(true);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    viewUserById(id)
      .then((res) => {
        resetMyPassword({ userName: res.data.username })
          .then((resp) => {
            setProcessingRequestResetPwd(false);
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: resp.message,
              type: 'success',
              show: true,
            });
          })
          .catch(({ response }) => {
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: response.data.message,
              type: 'error',
              show: true,
            });
            setProcessingRequestResetPwd(false);
          });
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
        setProcessingRequestResetPwd(false);
      });
  };

  const handleActions = (row, action) => {
    if (action === 'edit') {
      editData(row.id);
    } else if (action === 'delete') {
      deleteData(row.id);
    } else if (action === 'access') {
      userAccess(row.email, row.id, row.userID);
    } else if (action === 'reset-pwd') {
      requestResetPassword(row.userID);
    }
  };

  return (
    <PrivateWrapper pageName={pageName}>
      <>
        <div className={classes.filterToolbar}>
          <TableToolbar
            refreshList={refreshList}
            searchList={searchList}
            getSearchText={(e) => setSearchText(encodeURIComponent(e.target.value))}
            addNew={() => setOpenModel(true)}
            resetList={resetList}
          />
        </div>
        <TableContainer component={Paper}>
          <Table className={classes.tableData} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((col) => (
                  <TableCell width={col.width} align={col.align || ''} title={col.title || ''}>
                    <TableSortLabel
                      active={sortBy === col.id}
                      direction={sortBy === col.id ? orderBy : 'asc'}
                      onClick={() => sortHandler(col.id)}
                      IconComponent={sortBy !== col.id ? ImportExport : ArrowDownward}
                    >
                      <span>{col.name}</span>
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell align="right" width="7%">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!dataLoaded &&
                [1, 2, 3, 4, 5].map((val) => (
                  <TableRow key={`tm-${val}`}>
                    <TableCell component="th" scope="row">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                  </TableRow>
                ))}
              {dataLoaded &&
                (rows.length > 0 ? (
                  rows.map((row) => (
                    <TableRow key={`tm-${row.id}`}>
                      <TableCell component="th" scope="row">
                        {`${row.firstName} ${row.lastName}`}
                        <br />
                        {row.email}
                      </TableCell>
                      <TableCell>{row.type}</TableCell>
                      <TableCell align="center">
                        <span
                          className={
                            row.status === 'Active' ? classes.statusActive : classes.statusInactive
                          }
                        >
                          {row.status}
                        </span>
                      </TableCell>
                      <TableCell align="right">
                        <AgentCell
                          text={row.agents}
                          title={`Agents of ${row.firstName} ${row.lastName}`}
                          agentId={row.id}
                          agentType={agentTypeValues.AGENTS}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <PurchaseCell
                          text={row.excSoldZone}
                          title={`Exclusive Zone of ${row.firstName} ${row.lastName}`}
                          agentId={row.id}
                          purchase="SOLD"
                          zoneType={zoneTypeValues.AGENTS}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <PurchaseCell
                          text={row.semiExcSoldZone}
                          title={`Semi Exclusive Zone of ${row.firstName} ${row.lastName}`}
                          agentId={row.id}
                          purchase="HALF_SOLD"
                          zoneType={zoneTypeValues.AGENTS}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <PurchaseCell
                          text={row.holdZone}
                          title={`Hold Zone of ${row.firstName} ${row.lastName}`}
                          agentId={row.id}
                          purchase="HOLD"
                          zoneType={zoneTypeValues.AGENTS}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <AgentCell
                          text={row.lenders}
                          title={`Lenders of ${row.firstName} ${row.lastName}`}
                          agentId={row.id}
                          agentType={agentTypeValues.LENDERS}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <PurchaseCell
                          text={row.excSoldLenderZone}
                          title={`Exclusive Zone of ${row.firstName} ${row.lastName}`}
                          agentId={row.id}
                          purchase="SOLD"
                          zoneType={zoneTypeValues.LENDERS}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <PurchaseCell
                          text={row.semiExcSoldLenderZone}
                          title={`Semi Exclusive Zone of ${row.firstName} ${row.lastName}`}
                          agentId={row.id}
                          purchase="HALF_SOLD"
                          zoneType={zoneTypeValues.LENDERS}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <PurchaseCell
                          text={row.holdLenderZone}
                          title={`Hold Zone of ${row.firstName} ${row.lastName}`}
                          agentId={row.id}
                          purchase="HOLD"
                          zoneType={zoneTypeValues.LENDERS}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <ActionMenu id={row.id} selectAction={handleActions} row={row} loggedInUserId={loggedInUserId} loggedInUserType={loggedInUserType} />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      size="medium"
                      colSpan={12}
                      className={classes.noRecord}
                      style={{ padding: 20, fontSize: 15 }}
                    >
                      No records found
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {dataLoaded && totalPages > 1 && (
          <Pagination
            count={totalPages}
            showFirstButton
            showLastButton
            className={classes.tablePagination}
            onChange={(_, pageNumber) => {
              setActivePage(pageNumber);
              setReloadRows(!reloadRows);
            }}
            page={activePage}
          />
        )}

        {openModel && (
          <FormDialog
            open={openModel}
            closeModel={() => {
              setOpenModel(false);
              setEditId(null);
            }}
            title={editId ? 'Edit Team Member' : 'Add New Team Member'}
            onSuccess={() => {
              setReloadRows(!reloadRows);
            }}
            editId={editId}
          />
        )}

        {confirmModel && (
          <ConfirmDialog
            open={confirmModel}
            closeModel={() => {
              setConfirmModel(false);
              setDeleteId(null);
            }}
            title="Team Member"
            onSuccess={() => {
              setReloadRows(!reloadRows);
            }}
            deleteId={deleteId}
            removeFor="team"
          />
        )}
        {userModel && (
          <UserForm
            open={userModel}
            closeModel={() => {
              setUserModel(false);
              setAccessEmail(null);
              setEditId(null);
              setUserId(null);
            }}
            title="Assign user access to"
            onSuccess={() => {
              setReloadRows(!reloadRows);
            }}
            accessEmail={accessEmail}
            teamId={editId}
            userId={userId}
          />
        )}
      </>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      {processingRequestResetPwd && (
        <Backdrop open className={classes.backdrop}>
          <CircularProgress color="inherit" />
          <Typography variant="body2"> Processing Request...</Typography>
        </Backdrop>
      )}
    </PrivateWrapper>
  );
};

const ActionMenu = ({ row, selectAction, loggedInUserId, loggedInUserType }) => {
  let allowActions = false;
  let allowAccess = false;
  let allowEdit = false;
  let allowDelete = false;
  let allowResetPass = false;
  if (loggedInUserType === UserTypes.SUPER_ADMIN.value) {
    allowActions = true;
    allowAccess = true;
    allowEdit = true;
    allowDelete = true;
    allowResetPass = true;
  } else if (loggedInUserType === UserTypes.ADMIN.value && row.type !== 'Super Admin') {
    allowActions = true;
    allowAccess = true;
    allowEdit = true;
    allowDelete = true;
    allowResetPass = true;
  } else if (loggedInUserType === UserTypes.SALES_MANAGER.value && row.type === 'Sales') {
    allowActions = true;
    allowAccess = true;
    allowEdit = true;
    allowDelete = true;
    allowResetPass = true;
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (action) => {
    selectAction(row, action);
    handleClose();
  };
  return (
    <div>
      <Button aria-controls={`simple-menu${row.id}`} aria-haspopup="true" onClick={handleClick} disabled={!allowActions}>
        <MoreVert />
      </Button>
      <Menu
        id={`simple-menu${row.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleMenuClick('access');
          }}
          disabled={!allowAccess || row.id === loggedInUserId}
          button
        >
          {row.hasAccess > 0 ? 'Manage Access' : 'Assign Access'}
        </MenuItem>
        {allowEdit && (
          <MenuItem
            onClick={() => {
              handleMenuClick('edit');
            }}
            disabled={!allowEdit || row.id === loggedInUserId}
            button
          >
            Edit
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleMenuClick('delete');
          }}
          disabled={!allowDelete || row.id === loggedInUserId}
          button
        >
          Delete
        </MenuItem>
        {row.userID > 0 && (
          <MenuItem
            onClick={() => {
              handleMenuClick('reset-pwd');
            }}
            disabled={!allowResetPass || row.id === loggedInUserId}
            button
          >
            Request Reset Password
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
ActionMenu.propTypes = {
  row: PropTypes.objectOf().isRequired,
  selectAction: PropTypes.func.isRequired,
  loggedInUserId: PropTypes.number.isRequired,
  loggedInUserType: PropTypes.number.isRequired,
};

export default Team;
