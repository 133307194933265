/* eslint-disable no-unused-vars */

import { deleteApiCall, getApiCall, postApiCall } from '../utils/Api';

/* eslint-disable import/prefer-default-export */
export const listAllBlogs = async (page, params) => {
  const result = await getApiCall(`/blog/list?page=${page}${params ? `&${params}` : ''}`);
  return result.data;
};

export const viewBlogById = async (id) => {
  const result = await getApiCall(`/blog/${id}/view`);
  return result.data;
};

export const getBlogAgents = async (id, page) => {
  const result = await getApiCall(`/blog/${id}/agents?page=${page}`);
  return result.data;
};

export const getOpenBlogs = async () => {
  const result = await getApiCall(`/blog/open-blogs`);
  return result.data;
};

export const getPastBlogs = async (page) => {
  const result = await getApiCall(`/blog/past-blogs?page=${page || 1}`);
  return result.data;
};

export const postAgentBlog = async (id, data) => {
  const result = await postApiCall(`/blog/${id}/add-blog`, data);
  return result.data;
};

export const getBlogById = async (id) => {
  const result = await getApiCall(`/blog/${id}/agent-blog-view`);
  return result.data;
};

export const createBlog = async (data) => {
  const result = await postApiCall(`/blog/create`, data);
  return result.data;
};

export const updateBlog = async (id, data) => {
  const result = await postApiCall(`/blog/${id}/update`, data);
  return result.data;
};

export const updateAgentBlog = async (id, data) => {
  const result = await postApiCall(`/blog/${id}/update-blog`, data);
  return result.data;
};

export const deleteBlog = async (id) => {
  const result = await deleteApiCall(`/blog/${id}/delete`);
  return result.data;
};

export const releaseAgentBlog = async (id) => {
  const result = await postApiCall(`/blog/${id}/release-blog`);
  return result.data;
};
