import { getApiCall } from '../utils/Api';

const uploadProfilePic = async (data) => {
  const objName = data.objectName.replace(/[^a-z0-9. ]/gi, ' ');
  const result = await getApiCall(
    `/s3/sign?objectName=${encodeURIComponent(objName)}&contentType=${data.contentType}&path=${
      data.path || ''
    }`
  );
  return result.data;
};

export const uploadSubmissionVideo = async (data) => {
  const objName = data.objectName.replace(/[^a-z0-9. ]/gi, ' ');
  const result = await getApiCall(
    `/s3/sign?objectName=${encodeURIComponent(objName)}&contentType=${data.contentType}&path=${
      data.path || ''
    }`
  );
  return result.data;
};

export default uploadProfilePic;
