const Style = () => ({
  odisImg: {
    width: '100%',
  },
  odisImgContainer: {
    position: 'absolute',
    width: '480px',
    height: '500px',
    top: 0,
    right: 0,
    boxShadow: '-3px 0px 20px rgba(196, 196, 196, 0.51)',
    backdropFilter: 'blur(10px)',
    borderRadius: '100% 0% 0% 100%',
    opacity: '0.90',
    backgroundColor: '#3E9C36',
    '& img': {
      position: 'absolute',
      top: '0px',
      right: '0px',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  container: {
    position: 'relative',
    height: '500px',
    overflowX: 'hidden',
  },
  mr10: {
    marginRight: 10,
  },
  previewSelection: {
    height: '40px',
  },
});

export default Style;
