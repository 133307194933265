import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import AsyncSelect from 'react-select/async';
import Grid from '@material-ui/core/Grid';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import Validations, { MaxLengthValidationFunc } from '../../utils/Validations';
import isPageInIframe from '../../utils/isPageInIframe';
import WidgetWrapper from '../../layout/Widget';
import { validateSearchZipcodesV2, fetchZipcodes } from '../../services/Widgets';
import widgetStyle from '../../theme/styles/Widgets';
import { getWidgetType } from '../../utils/GetModuleType';

const useStyles = makeStyles(widgetStyle);
const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
    width: '200px',
    textAlign: 'left',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '120px',
  }),
};

// Zip code search page
const ZipcodeSearch = () => {
  const classes = useStyles();
  const query = new URLSearchParams(window.location.search);
  const formOption = query.get('option') || '';
  const zoneSearchType = getWidgetType();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const { handleSubmit, control, reset } = useForm();

  const loadZipcodes = (inputStateValue, callback) => {
    fetchZipcodes(`searchText=${encodeURI(inputStateValue)}`).then((res) => {
      callback(
        res.data.rows.map((i) => ({
          name: i.name,
          id: i.id,
        }))
      );
    });
  };

  const resetSearch = () => {
    setIsSubmitted(false);
    setShowFailure(false);
    setShowError(false);
    setShowSuccess(false);
  };

  const successRedirect = (url) => {
    try {
      if (isPageInIframe()) {
        window.parent.location.href = url;
      } else {
        window.location.href = url;
      }
    } catch (e) {
      window.location.href = url;
    }
  };

  const onSubmit = (data) => {
    setIsSubmitted(true);

    const payloadData = [];
    data.zipcodes.forEach((v) => {
      if (!payloadData.includes(v.name)) {
        payloadData.push(v.name);
      }
    });

    let embedSource = '';
    try {
      // For security concerns, put it in try.. catch
      embedSource = window.parent.location.href;
    } catch (e) {
      // do nothing
    }

    setUserInfo({
      name: data.name,
      email: data.email,
      phone: data.phone,
    });

    validateSearchZipcodesV2(
      {
        option: formOption,
        embedSource,
        zipcodes: payloadData,
        name: data.name,
        email: data.email,
        phone: data.phone,
      },
      zoneSearchType
    )
      .then((res) => {
        reset();

        if (Number(res.data.matches) === 0) {
          setShowFailure(true);
        } else {
          setShowSuccess(true);
          setTimeout(() => {
            successRedirect(res.data.url);
          }, 2000);
        }
      })
      .catch(() => {
        setShowError(true);
      });
  };

  return (
    <WidgetWrapper>
      <Grid container spacing={0} direction="column">
        <Container maxWidth="xs">
          {!showError && !showSuccess && !showFailure && (
            <form onSubmit={handleSubmit(onSubmit)} className={classes.pageForm}>
              <FormControl className={classes.pageInput}>
                <Controller
                  control={control}
                  name="name"
                  id="name"
                  rules={{ ...Validations.REQUIRED, ...MaxLengthValidationFunc(100) }}
                  defaultValue={userInfo.name}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div className={classes.formInput}>
                      <TextField
                        label="Name*"
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        fullWidth
                      />
                    </div>
                  )}
                />
              </FormControl>
              <FormControl className={classes.pageInput}>
                <Controller
                  control={control}
                  rules={Validations.REQUIRED_EMAIL}
                  name="email"
                  id="email"
                  defaultValue={userInfo.email}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Email Address*"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </FormControl>
              <FormControl className={classes.pageInput}>
                <Controller
                  control={control}
                  rules={{
                    ...MaxLengthValidationFunc(20),
                    ...{ pattern: { value: /^[0-9 )(+-]+$/, message: 'Invalid value' } },
                    ...Validations.REQUIRED,
                  }}
                  name="phone"
                  id="phone"
                  defaultValue={userInfo.phone}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      variant="outlined"
                      label="Phone Number*"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      className={classes.formInput}
                      fullWidth
                    />
                  )}
                />
              </FormControl>
              <FormControl className={classes.pageInput}>
                <Controller
                  control={control}
                  name="zipcodes"
                  id="zipcodes"
                  rules={Validations.REQUIRED}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div className={classes.formInput}>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={value}
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e.id}
                        loadOptions={loadZipcodes}
                        onChange={(v) => onChange(v)}
                        placeholder="Zip Codes You Service"
                        isMulti
                        styles={customStyles}
                      />
                      {error && (
                        <Typography className={classes.errorText}>{error.message}</Typography>
                      )}
                    </div>
                  )}
                />
              </FormControl>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submitBtn}
                disabled={isSubmitted}
              >
                See If My Area Is Available!
              </Button>
            </form>
          )}

          {showError && (
            <Container component="div" className={classes.errorContainer}>
              <ErrorIcon fontSize="large" />
              <Typography component="p" variant="body1">
                Something went wrong. Please try again!
              </Typography>
              <Button
                type="button"
                variant="contained"
                color="primary"
                className={classes.submitBtn}
                onClick={resetSearch}
              >
                Try Again
              </Button>
            </Container>
          )}

          {showFailure && (
            <Container component="div" className={classes.errorContainer}>
              <ErrorIcon fontSize="large" />
              <Typography component="p" variant="body1">
                We&apos;re sorry, your area is currently unavailable.
              </Typography>
              <Typography component="p" variant="body1">
                But we&apos;ll let you know when these areas are available for you.
              </Typography>
              <Button
                type="button"
                variant="contained"
                color="primary"
                className={classes.submitBtn}
                onClick={resetSearch}
              >
                Try Another Zip Code
              </Button>
            </Container>
          )}

          {showSuccess && (
            <Container component="div" className={classes.successContainer}>
              <DoneIcon fontSize="large" />
              <Typography component="p" variant="body1">
                Congrats! Your area is available.
                <br />
                You&apos;ll be forwarded to a producer&apos;s calendar momentarily
              </Typography>
            </Container>
          )}
        </Container>
      </Grid>
    </WidgetWrapper>
  );
};

export default ZipcodeSearch;
