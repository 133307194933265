import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import formInput from '../../theme/styles/FormInput';
import { addNewState, editState, viewStateById } from '../../services/State';
import Validations, { MaxLengthValidationFunc } from '../../utils/Validations';
import SnackbarMessage from '../SnackbarMessage';

const useStyles = makeStyles(formInput);

const FormDialog = ({ open, editId, closeModel, title, onSuccess }) => {
  const classes = useStyles();

  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const { handleSubmit, control, setValue, reset, getValues } = useForm();

  useEffect(() => {
    if (editId) {
      viewStateById(editId).then((res) => {
        setValue('stateName', res.data.stateName);
        setValue('code', res.data.code);
        setValue('status', res.data.status);
      });
    } else {
      setValue('status', 1);
    }
  }, []);

  const handleDialog = () => {
    reset();
    closeModel();
  };

  const submitForm = ({ stateName, code, status }) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    setProcessing(true);
    const payload = {
      stateName,
      code,
      status: Number(status),
    };

    if (editId) {
      editState(editId, payload)
        .then(() => {
          setProcessing(false);
          handleDialog();
          onSuccess();
        })
        .catch(({ response }) => {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.data.message,
            type: 'error',
            show: true,
          });
          setProcessing(false);
        });
    } else {
      addNewState(payload)
        .then(() => {
          setProcessing(false);
          handleDialog();
          onSuccess();
        })
        .catch(({ response }) => {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.data.message,
            type: 'error',
            show: true,
          });
          setProcessing(false);
        });
    }
  };

  const defaultFormValues = getValues();

  return (
    <Dialog open={open} onClose={handleDialog} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={handleSubmit(submitForm)}>
        <DialogContent className={classes.dialogContent}>
          <Controller
            control={control}
            rules={{ ...Validations.REQUIRED }}
            name="stateName"
            id="stateName"
            defaultValue={defaultFormValues.stateName || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="State Name*"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
              />
            )}
          />
          <Controller
            control={control}
            rules={{
              ...Validations.REQUIRED,
              ...MaxLengthValidationFunc(5),
              ...Validations.TEXT_ONLY,
            }}
            name="code"
            id="code"
            defaultValue={defaultFormValues.code || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="State Code*"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
              />
            )}
          />
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="status"
            id="status"
            defaultValue={defaultFormValues.status || 1}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Status*"
                defaultValue={value}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                SelectProps={{
                  native: true,
                }}
                fullWidth
                select
              >
                <option key={1} value={1}>
                  Active
                </option>
                <option key={0} value={0}>
                  Inactive
                </option>
              </TextField>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            type="submit"
            endIcon={processing && <CircularProgress size={25} />}
            disabled={processing}
          >
            Submit
          </Button>

          <Button variant="contained" onClick={handleDialog} disableElevation>
            Cancel
          </Button>
        </DialogActions>
      </form>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  editId: PropTypes.number,
  title: PropTypes.string,
};
FormDialog.defaultProps = {
  editId: 0,
  title: '',
};

export default FormDialog;
