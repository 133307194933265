import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Delete, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import agentStyle from '../../theme/styles/Agents';
import { viewZoneById } from '../../services/Zones';
import { deleteDraftContractZoneItem } from '../../services/Contract';

const useStyles = makeStyles(agentStyle);

const ContractZoneTable = ({
  setTempAssignedZoneData,
  tempAssignedZoneData,
  zoneArr,
  updateList,
  quantity,
  allowDelete,
  fromAmendContract,
  agentId,
  refershData,
  setRefershData,
}) => {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [confirmModel, setConfirmModel] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);
  const [processing, setProcessing] = useState(false);

  const dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const exclusitivityArr = {
    1: 'Exclusive',
    2: 'Semi Exclusive',
  };

  const statusArr = {
    1: 'Paid',
    2: 'Hold',
  };

  const columns = [
    {
      field: 'purchasedZone',
      headerName: 'Purchased Zone',
      valueFormatter: (params) => zoneArr[params.value],
    },
    {
      field: 'exclusivity',
      headerName: 'Exclusivity',
      valueFormatter: (params) => exclusitivityArr[params.value],
    },
    {
      field: 'paymentStatus',
      headerName: 'Status',
      valueFormatter: (params) => statusArr[params.value],
    },
    {
      field: 'nrpFee',
      headerName: 'NRP Fee',
      align: 'right',
      valueFormatter: (params) => dollarUS.format(params.value),
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      align: 'right',
      valueFormatter: () => `x ${quantity}`,
    },
    {
      field: 'total',
      headerName: 'Total',
      align: 'right',
      valueFormatter: (params) => dollarUS.format(params.row.nrpFee * quantity),
    },
  ];

  useEffect(() => {
    setRows(tempAssignedZoneData);
    setDataLoaded(true);
  }, [reloadData, updateList]);

  const deleteRecord = (row) => {
    setDeleteRow(row);
    setConfirmModel(true);
  };

  const deleteAssignedZone = () => {
    setProcessing(true);
    if (fromAmendContract) {
      // delete draftzone entry
      deleteDraftContractZoneItem({ agentId, zoneId: deleteRow.purchasedZone })
        .then(() => {
          setProcessing(false);
          setConfirmModel(false);
          setRefershData(!refershData);
        })
        .catch(() => {
          setProcessing(false);
          setConfirmModel(false);
        });
    } else {
      const updated = [...rows];
      const oldData = updated.filter((r) => r.id === deleteRow.id);
      if (oldData.length > 0) {
        const index = updated.indexOf(oldData[0]);
        if (index !== -1) {
          updated.splice(index, 1);
          setTempAssignedZoneData(updated);
          setProcessing(false);
          setConfirmModel(false);
          setReloadData(!reloadData);
        } else {
          setProcessing(false);
          setConfirmModel(false);
          setReloadData(!reloadData);
        }
      } else {
        setProcessing(false);
        setConfirmModel(false);
        setReloadData(!reloadData);
      }
    }
  };

  return (
    <div>
      <TableContainer component={Paper}>
        {dataLoaded && (
          <div className={classes.dataGridContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" />
                  {columns.map((col) => (
                    <TableCell width={col.width}>{col.headerName}</TableCell>
                  ))}
                  <TableCell width={60} />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length === 0 && (
                  <TableCell colSpan="8" align="center">
                    No record found.
                  </TableCell>
                )}
                {rows.map((row) => (
                  <ExpandableTableRow key={row.id} zoneId={row.purchasedZone}>
                    {columns.map((col) => (
                      <TableCell align={col.align}>
                        {col.valueFormatter
                          ? col.valueFormatter({ value: row[col.field], row })
                          : row[col.field]}
                      </TableCell>
                    ))}
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => deleteRecord(row)}
                        disabled={!allowDelete}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </ExpandableTableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </TableContainer>
      {confirmModel && (
        <Dialog open onClose={() => setConfirmModel(false)}>
          <DialogTitle id="alert-dialog-title">Delete Zone</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to delete this record? This action can not be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={deleteAssignedZone}
              color="secondary"
              autoFocus
              endIcon={processing && <CircularProgress size={25} />}
              disabled={processing}
            >
              Delete
            </Button>
            <Button onClick={() => setConfirmModel(false)} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

const ExpandableTableRow = ({ children, zoneId, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [zipCodes, setZipcodes] = useState(null);

  useEffect(() => {
    if (!zipCodes) {
      viewZoneById(zoneId)
        .then((res) => {
          setZipcodes(res.data.zoneZipCodes);
        })
        .catch(() => setZipcodes(''));
    }
  }, [isExpanded]);

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell padding="checkbox" />
          <TableCell colSpan="7">{zipCodes}</TableCell>
        </TableRow>
      )}
    </>
  );
};

ExpandableTableRow.propTypes = {
  children: PropTypes.element.isRequired,
  zoneId: PropTypes.number.isRequired,
};

ContractZoneTable.propTypes = {
  setTempAssignedZoneData: PropTypes.func,
  tempAssignedZoneData: PropTypes.arrayOf(),
  quantity: PropTypes.number.isRequired,
  zoneArr: PropTypes.arrayOf().isRequired,
  updateList: PropTypes.bool.isRequired,
  allowDelete: PropTypes.bool,
  fromAmendContract: PropTypes.bool,
  agentId: PropTypes.number,
  refershData: PropTypes.bool,
  setRefershData: PropTypes.func,
};
ContractZoneTable.defaultProps = {
  setTempAssignedZoneData: () => true,
  tempAssignedZoneData: [],
  allowDelete: true,
  fromAmendContract: false,
  agentId: 0,
  refershData: false,
  setRefershData: () => {},
};

export default ContractZoneTable;
