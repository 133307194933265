import React, { useEffect, useState } from 'react';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Pagination, Skeleton } from '@material-ui/lab';
import { getPastBlogs } from '../../services/Blogs';
import listingStyle from '../../theme/styles/TableListing';
import logo from '../../assets/images/ng.ico';

const useStyles = makeStyles(listingStyle);

const PostBlogsTable = () => {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reloadRows, setReloadRows] = useState(false);

  useEffect(() => {
    setDataLoaded(false);
    getPastBlogs(activePage).then((res) => {
      setRows(res.data.rows);
      setTotalPages(res.data.totalPages);
      setDataLoaded(true);
    });
  }, [activePage, reloadRows]);

  return (
    <>
      <Table className={classes.tableData}>
        <TableHead>
          <TableRow>
            <TableCell width="2%" />
            <TableCell width="60%">Blog Title</TableCell>
            <TableCell width="38%">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!dataLoaded &&
            [1, 2, 3, 4].map((val) => (
              <TableRow key={`sub-${val}`}>
                <TableCell component="th" scope="row">
                  <Skeleton variant="text" className={classes.placeholderSkeleton} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" className={classes.placeholderSkeleton} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" className={classes.placeholderSkeleton} />
                </TableCell>
              </TableRow>
            ))}
          {rows.map((row) => (
            <TableRow key={`sub-${row.id}`}>
              <TableCell>
                {row.videoUrl ? (
                  <img src={logo} alt={process.env.REACT_APP_NAME} width={30} height={20} />
                ) : (
                  ''
                )}
              </TableCell>
              <TableCell>{row.title}</TableCell>
              <TableCell>{row.schedule}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {dataLoaded && totalPages > 1 && (
        <Pagination
          count={totalPages}
          showFirstButton
          showLastButton
          className={classes.tablePagination}
          onChange={(_, pageNumber) => {
            setActivePage(pageNumber);
            setReloadRows(!reloadRows);
          }}
          page={activePage}
        />
      )}
    </>
  );
};

export default PostBlogsTable;
