import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import copy from 'copy-to-clipboard';
import { Box, IconButton, Button, Tooltip } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import HelpIcon from '@material-ui/icons/Help';
import Grid from '@material-ui/core/Grid';
import SnackbarMessage from '../SnackbarMessage';
import UserTypes from '../../config/UserTypes';
import { getDemoLinkAgentsList, getActiveDemoLink, setActiveDemoLink } from '../../services/Sales';
import logo from '../../assets/images/logo.png';

const DemoLink = () => {
  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;
  const allowSetNewLink = [
    UserTypes.ADMIN.value,
    UserTypes.SUPER_ADMIN.value,
    UserTypes.SALES_MANAGER.value,
  ].includes(userType);

  const [data, setData] = useState({
    url: '',
    agentId: 0,
    agentName: '',
    nrpAgent: null,
  });
  const [selectedAgent, setSelectedAgent] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  useEffect(() => {
    getActiveDemoLink()
      .then((res) => {
        setData(res.data);
        setSelectedAgent(res.data.agentId);
      })
      .catch(() => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'Something went wrong. Please try again later.',
          type: 'error',
          show: true,
        });
      });
  }, [refresh]);

  const copyLink = (url) => {
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });
    setTimeout(() => {
      setSnackbarMeesage({
        ...snackbarMeesage,
        message: 'Copied successfully.',
        type: 'success',
        show: true,
      });
      copy(url);
    }, 300);
  };

  const loadAgentsData = (inputStateValue, callback) => {
    getDemoLinkAgentsList(inputStateValue).then((res) => {
      callback(
        res.data.rows.map((i) => ({
          name: i.name,
          id: i.id,
        }))
      );
    });
  };

  const handleSaveDemoLink = () => {
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });
    setActiveDemoLink({ agentId: selectedAgent })
      .then(() => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'Data updated successfully.',
          type: 'success',
          show: true,
        });
        setRefresh(!refresh);
      })
      .catch(() => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'Something went wrong. Please try again later.',
          type: 'error',
          show: true,
        });
      });
  };

  return (
    <>
      <Grid container spacing={2} style={{ marginTop: 50, marginBottom: 20 }}>
        <Grid item xs={4} sm={2}>
          <img src={logo} alt="Logo" style={{ width: '90%' }} />
        </Grid>
        <Grid item xs={8} sm={6}>
          {allowSetNewLink && (
            <div
              style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'space-between',
                marginBottom: 20,
              }}
            >
              <div style={{ width: '65%' }}>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  value={data.nrpAgent}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.id}
                  loadOptions={loadAgentsData}
                  onChange={(v) => {
                    setSelectedAgent(v.id);
                    setData({
                      ...data,
                      nrpAgent: v,
                    });
                  }}
                  placeholder="Select Agent"
                  menuPlacement="bottom"
                />
              </div>
              <div style={{ width: '5%' }}>
                <Tooltip
                  title="All agents in this list have an approved ODIS, Nugget and Pre-Roll."
                  style={{ top: -5, left: -10 }}
                >
                  <IconButton aria-label="delete" size="small">
                    <HelpIcon size="small" />
                  </IconButton>
                </Tooltip>
              </div>
              <div style={{ width: '25%' }}>
                {selectedAgent !== data.agentId && (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ top: -3 }}
                    onClick={handleSaveDemoLink}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
          )}

          <div style={{ marginBottom: 20 }}>
            <ul style={{ marginLeft: -20 }}>
              <li>Current promo NRP: {data.agentName || '-'}</li>
              <li>Will override ads for: 7 days</li>
            </ul>
          </div>

          <Box
            component="div"
            sx={{ display: 'inline' }}
            style={{
              width: '80%',
              backgroundColor: '#dfdfdf',
              minHeight: '50px',
              padding: '10px 5px',
            }}
            mr={3}
          >
            {data.url}
          </Box>
          <Box component="div" sx={{ display: 'inline' }}>
            <IconButton
              onClick={() => copyLink(data.url)}
              size="small"
              title="Click to copy"
              style={{ marginTop: -5 }}
            >
              <FileCopy />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={0} sm={4} />
      </Grid>

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </>
  );
};

export default DemoLink;
