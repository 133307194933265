import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  makeStyles,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { isToday, isTomorrow } from 'date-fns';
import { getOpenBlogs } from '../../services/Blogs';
import submissionStyle from '../../theme/styles/Submission';
import BlogFormDialog from './BlogFormDialog';
import ViewBlogDialog from './ViewBlogDialog';
import ConfirmDialog from './ConfirmDialog';

const useStyles = makeStyles(submissionStyle);
const OpenBlogsGrid = () => {
  const classes = useStyles();
  const [openPrompts, setOpenPrompts] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [userAction, setUserAction] = useState('');
  const [selectedBlogID, setSelectedBlogID] = useState(null);
  const [selectedBlogGuideLink, setSelectedBlogGuideLink] = useState('');
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setDataLoaded(false);
    getOpenBlogs().then((res) => {
      setOpenPrompts(res.data);
      setDataLoaded(true);
    });
  }, [reloadData]);

  const openCreateBlogDialog = (id, guideLink, action) => {
    setSelectedBlogID(id);
    setSelectedBlogGuideLink(guideLink);
    setUserAction(action);
    if (action === 'claim') {
      setProcessing(true);
    }
  };

  return (
    <Grid container spacing={2}>
      {openPrompts.length === 0 && dataLoaded && (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" mt={1} mb={2}>
          <Typography variant="body2">Please check back soon for the next Blog prompt!</Typography>
        </Box>
      )}
      {dataLoaded &&
        openPrompts.map((p) => (
          <Grid item>
            <Card
              component={Box}
              width={250}
              height={250}
              p={0.5}
              display="flex"
              flexDirection="column"
            >
              <CardContent className={classes.cardContent}>
                <Typography className={classes.promptTitle}>{p.title}</Typography>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="column"
                  height={144}
                >
                  <Typography className={classes.promptDescription}>{p.description}</Typography>
                  {p.closedDate && (
                    <Box>
                      <Typography className={classes.submitText}>Submit Blog By:</Typography>
                      <Typography
                        className={
                          isToday(new Date(p.closedDate)) || isTomorrow(new Date(p.closedDate))
                            ? classes.redText
                            : ``
                        }
                      >
                        {p.closeDate}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </CardContent>

              <CardActions>
                {((!p.isSelfClaimed && p.claimedBy) || !p.claimedBy) && (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    endIcon={processing && <CircularProgress size={25} />}
                    disabled={processing || (!p.isSelfClaimed && p.claimedBy)}
                    onClick={() => openCreateBlogDialog(p.id, null, 'claim')}
                  >
                    Claim
                  </Button>
                )}
                {p.isSelfClaimed && p.claimedBy && !p.articleViewLink && !p.articleText && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={() => openCreateBlogDialog(p.id, p.viewLink, 'create')}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      onClick={() => openCreateBlogDialog(p.id, p.viewLink, 'release')}
                    >
                      Release
                    </Button>
                  </>
                )}
                {p.isSelfClaimed && p.claimedBy && (p.articleViewLink || p.articleText) && (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={() => openCreateBlogDialog(p.id, p.viewLink, 'view')}
                  >
                    View
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      {userAction === 'create' && (
        <BlogFormDialog
          guideLink={selectedBlogGuideLink}
          editId={selectedBlogID}
          closeModel={() => {
            setUserAction('');
          }}
          onSuccess={() => {
            setReloadData(!reloadData);
            setUserAction('');
          }}
        />
      )}
      {userAction === 'view' && (
        <ViewBlogDialog
          editId={selectedBlogID}
          closeModel={() => {
            setUserAction('');
          }}
        />
      )}
      {(userAction === 'claim' || userAction === 'release') && (
        <ConfirmDialog
          id={selectedBlogID}
          userAction={userAction}
          closeModel={() => {
            setReloadData(!reloadData);
            setUserAction('');
            setProcessing(false);
          }}
        />
      )}
    </Grid>
  );
};

export default OpenBlogsGrid;
