import React, { useState, useEffect } from 'react';
import { Button, Box } from '@material-ui/core';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getAgentBanners } from '../../services/Agent';
import '../../assets/style/slider.css';

const AgentBanners = () => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [data, setData] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  useEffect(() => {
    getAgentBanners()
      .then((res) => {
        setData(res.data);
        setDataLoaded(true);
      })
      .catch(() => {
        setDataLoaded(true);
      });
  }, []);

  if (!dataLoaded || data.length === 0) {
    return <></>;
  }

  const getInternalLink = (linkId) => {
    // this needs to get from table and update links there
    switch (linkId) {
      case 1:
        return '/resources#profile-pics';
      case 2:
        return '/participation#nrp-insights';
      case 3:
        return '/participation#guest-blogs';
      case 4:
        return '/tv-show#nrp-promo-link';
      case 5:
        return '/manage-profile#web';
      case 6:
        return '/tv-show#odis';
      case 7:
        return '/tv-show#nuggets';
      case 8:
        return '/tv-show#intro';
      default:
        return '';
    }
  };

  const getClickAction = (d) => {
    if (d.clickTarget === 1) {
      window.location = getInternalLink(d.internalUrl);
    } else {
      window.open(d.externalUrl, '_blank');
    }
  };

  return (
    <div
      style={{
        margin: '0px auto',
        height: '280px',
        width: 'calc(100vw - 300px)',
        display: 'inline-block !important',
        borderRadius: '25px',
        marginBottom: 30,
      }}
    >
      <Slider {...settings}>
        {data.map((obj) => (
          <div key={`bnr-${obj.id}`}>
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
              alt={obj.title}
              width={1110}
              height={280}
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                color: 'white',
                fontWeight: 500,
                width: 'calc(100vw - 300px)',
                height: '280px',
                backgroundSize: 'contain',
                borderRadius: '25px',
                background: `linear-gradient(270deg, rgba(0,0,0,0.086) 0%, rgba(0,0,0,0) 15%, rgba(0 0 0 / 90%) 74%), url("${obj.image}")`,
                backgroundPosition: 'right',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <Box mt={4} ml={5}>
                <div style={{ width: '100%', height: 120 }}>&nbsp;</div>
              </Box>
              <Box mt={6} ml={5} style={{ position: 'absolute' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => getClickAction(obj)}
                  disableElevation
                  style={{
                    height: '50px',
                    borderRadius: '15px',
                    width: '140px',
                    textTransform: 'none',
                  }}
                >
                  Explore Now
                </Button>
              </Box>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default AgentBanners;
