import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import wrapperStyle from '../theme/styles/LayoutWrapper';

const useStyles = makeStyles(wrapperStyle);

// Layout for widget pages
const Widget = ({ children }) => {
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      window.FreshworksWidget('hide');
    }, 500);
  }, []);

  return (
    <Container className={classes.wrapper} style={{ backgroundColor: 'white' }} maxWidth="xl">
      {children}
    </Container>
  );
};

Widget.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Widget;
