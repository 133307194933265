import { getApiCall, postApiCall, deleteApiCall } from '../utils/Api';

export const listAll = async (page, params) => {
  const result = await getApiCall(`/resources/list?page=${page}${params ? `&${params}` : ''}`);
  return result.data;
};

export const addNewContent = async (data) => {
  const result = await postApiCall(`/resources/create`, data);
  return result.data;
};

export const viewById = async (id) => {
  const result = await getApiCall(`/resources/${id}/view`);
  return result.data;
};

export const updateContent = async (id, data) => {
  const result = await postApiCall(`/resources/${id}/update`, data);
  return result.data;
};

export const getResourceSignedUrl = async (data) => {
  const result = await postApiCall(`/resources/resource-upload-url`, data);
  return result.data;
};

export const listResourceContentList = async (selectedCategory) => {
  const result = await getApiCall(`/resources/${selectedCategory}/resource-content`);
  return result.data;
};

export const deleteResource = async (id) => {
  const result = await deleteApiCall(`/resources/${id}/delete`);
  return result.data;
};
