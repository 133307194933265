import { Button, CircularProgress, makeStyles, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import RoutePaths from '../../config/Routes';
import { setNewPassword } from '../../services/Auth';
import formInput from '../../theme/styles/FormInput';
import Validations, {
  MaxLengthValidationFunc,
  MinLengthValidationFunc,
} from '../../utils/Validations';
import SnackbarMessage from '../SnackbarMessage';

const useStyles = makeStyles(formInput);

const SetNewPasswordForm = ({ token }) => {
  const classes = useStyles();
  const history = useHistory();

  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const { handleSubmit, control, reset } = useForm();

  const submitForm = (data) => {
    setProcessing(true);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    setNewPassword(data, token)
      .then((res) => {
        setProcessing(false);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: res.message,
          type: 'success',
          show: true,
        });
        reset();
        history.push(RoutePaths.LOGIN);
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
        setProcessing(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Controller
        control={control}
        rules={{
          ...Validations.REQUIRED,
          ...MaxLengthValidationFunc(80),
          ...MinLengthValidationFunc(6),
        }}
        name="password"
        id="password"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            type="password"
            label="New Password*"
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
            className={classes.formInput}
            fullWidth
          />
        )}
      />
      <Controller
        control={control}
        rules={{
          ...Validations.REQUIRED,
          ...MaxLengthValidationFunc(80),
          ...MinLengthValidationFunc(6),
        }}
        name="confirmPassword"
        id="confirmPassword"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            type="password"
            label="Confirm New Password*"
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
            className={classes.formInput}
            fullWidth
          />
        )}
      />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disableElevation
        endIcon={processing && <CircularProgress size={25} />}
        disabled={processing}
        fullWidth
      >
        Submit
      </Button>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </form>
  );
};

export default SetNewPasswordForm;

SetNewPasswordForm.propTypes = {
  token: PropTypes.string.isRequired,
};
