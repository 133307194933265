/* eslint-disable prettier/prettier */
import React from 'react';
import AsyncSelect from 'react-select/async';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { Box, TextField, Typography, FormControlLabel } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { Controller } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import { Facebook, Twitter, Instagram, YouTube, Pinterest, LinkedIn } from '@material-ui/icons';
import formInput from '../../theme/styles/FormInput';
import { searchStateCode } from '../../services/State';
import { searchSalesPerson } from '../../services/Team';
import Validations, { MaxLengthValidationFunc } from '../../utils/Validations';
import UserTypes from '../../config/UserTypes';
import ProfileUploader from '../ProfileUploader';
import UploadAsset from '../assets/UploadAsset';
import { searchAgent } from '../../services/Agent';
import { agentTypeValues } from '../../config/DataValues';
import WYSIWYGEditor from '../WYSIWYG';

const TikTokIcon = () => (
  <svg
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    width="100%"
    height="100%"
  >
    <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
  </svg>
);

const useStyles = makeStyles(formInput);
const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '208px',
  }),
};

const AddAgentForm = ({
  defaultFormValues,
  control,
  userProfile,
  setUserProfile,
  setUploading,
  agentId,
  assetId,
  assetStatus,
  hasSignedOrPaidContract,
  module,
  isMember,
}) => {
  const classes = useStyles();

  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;

  const allowEmailEditAllTimes = [UserTypes.SUPER_ADMIN.value, UserTypes.ADMIN.value];
  const isSalesPersonOrManagerLogin = userType === UserTypes.SALES_PERSON.value;
  const isSuperAdmin = userType === UserTypes.SUPER_ADMIN.value;
  const isCEUser = module === agentTypeValues.CE_USERS;

  const getAgentSearchParams = (searchText) => {
    let queryString = '1=1';
    if (searchText) queryString += `&searchText=${searchText}`;
    if (agentId) queryString += `&ignoreAgent=${agentId}`;

    return queryString.substring(4);
  };

  // load options using API call
  const loadStateOptions = (inputStateValue, callback) => {
    searchStateCode(inputStateValue).then((res) => {
      callback(
        res.data.rows.map((i) => ({
          code: i.code,
          id: i.id,
        }))
      );
    });
  };

  const loadSalesOptions = (inputStateValue, callback) => {
    searchSalesPerson(inputStateValue).then((res) => {
      callback(
        res.data.rows.map((i) => ({
          name: i.name,
          id: i.id,
        }))
      );
    });
  };

  const loadAgentsOptions = (inputStateValue, callback) => {
    searchAgent(getAgentSearchParams(inputStateValue), module).then((res) => {
      callback(
        res.data.rows.map((i) => ({
          name: i.name,
          id: i.id,
        }))
      );
    });
  };

  return (
    <div>
      <Grid container spacing={3}>
        {!isSalesPersonOrManagerLogin && (
          <Grid item xs={12}>
            <Box mb={2}>
              <ProfileUploader
                profilePic={userProfile}
                onSuccess={(url) => {
                  setUserProfile(url);
                }}
                setUploading={setUploading}
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={6}>
          <Controller
            control={control}
            rules={{ ...Validations.REQUIRED, ...MaxLengthValidationFunc(50) }}
            name="firstName"
            id="firstName"
            defaultValue={defaultFormValues.firstName || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="First Name*"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            rules={{ ...Validations.REQUIRED, ...MaxLengthValidationFunc(50) }}
            name="lastName"
            id="lastName"
            defaultValue={defaultFormValues.lastName || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Last Name*"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            rules={{ ...Validations.REQUIRED_EMAIL, ...MaxLengthValidationFunc(250) }}
            name="email"
            id="email"
            defaultValue={defaultFormValues.email || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Email*"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                type="email"
                className={classes.formInput}
                fullWidth
                disabled={
                  allowEmailEditAllTimes.includes(userType) ? false : hasSignedOrPaidContract
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            rules={
              agentId
                ? {
                    ...MaxLengthValidationFunc(14),
                    ...{ pattern: { value: /^\(\d{3}\)\s\d{3}-\d{4}$/, message: 'Invalid value' } },
                  }
                : {
                    ...Validations.REQUIRED,
                    ...MaxLengthValidationFunc(14),
                    ...{ pattern: { value: /^\(\d{3}\)\s\d{3}-\d{4}$/, message: 'Invalid value' } },
                  }
            }
            name="phone"
            id="phone"
            defaultValue={defaultFormValues.phone || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputMask
                mask="(999) 999-9999"
                value={value}
                maskChar="_"
                alwaysShowMask
                onChange={onChange}
              >
                {() => (
                  <TextField
                    label={agentId ? 'Phone Number' : 'Phone Number*'}
                    variant="outlined"
                    className={classes.formInput}
                    error={!!error}
                    helperText={error ? error.message : null}
                    fullWidth
                  />
                )}
              </InputMask>
            )}
          />
        </Grid>
        {!isSalesPersonOrManagerLogin && (
          <Grid item xs={6}>
            <Controller
              control={control}
              name="stateCodeId"
              id="stateCodeId"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <div className={classes.formInput}>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={value}
                    getOptionLabel={(e) => e.code}
                    getOptionValue={(e) => e.id}
                    loadOptions={loadStateOptions}
                    onChange={(v) => onChange(v)}
                    placeholder="State"
                    styles={customStyles}
                    menuPlacement="top"
                  />
                  {error && (
                    <Typography className={classes.formErrorText}>{error.message}</Typography>
                  )}
                </div>
              )}
            />
          </Grid>
        )}
        {!isSalesPersonOrManagerLogin && (
          <Grid item xs={6}>
            <Controller
              control={control}
              rules={MaxLengthValidationFunc(100)}
              name="location"
              id="location"
              defaultValue={defaultFormValues.bioText || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  variant="outlined"
                  label="Location"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  className={classes.formInput}
                  fullWidth
                />
              )}
            />
          </Grid>
        )}
        {!isCEUser && (
          <Grid item xs={12} className={classes.agentDetailDiv}>
            <Controller
              control={control}
              name="facebook"
              id="facebook"
              defaultValue={defaultFormValues.facebook || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Facebook />
                      </InputAdornment>
                    ),
                  }}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  className={classes.formInput}
                  fullWidth
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {!isCEUser && (
          <Grid item xs={12} className={classes.agentDetailDiv}>
            <Controller
              control={control}
              name="twitter"
              id="twitter"
              defaultValue={defaultFormValues.twitter || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Twitter />
                      </InputAdornment>
                    ),
                  }}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  className={classes.formInput}
                  fullWidth
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {!isCEUser && (
          <Grid item xs={12} className={classes.agentDetailDiv}>
            <Controller
              control={control}
              name="instagram"
              id="instagram"
              defaultValue={defaultFormValues.instagram || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Instagram />
                      </InputAdornment>
                    ),
                  }}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  className={classes.formInput}
                  fullWidth
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {!isCEUser && (
          <Grid item xs={12} className={classes.agentDetailDiv}>
            <Controller
              control={control}
              name="youtube"
              id="youtube"
              defaultValue={defaultFormValues.youtube || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <YouTube />
                      </InputAdornment>
                    ),
                  }}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  className={classes.formInput}
                  fullWidth
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {!isCEUser && (
          <Grid item xs={12} className={classes.agentDetailDiv}>
            <Controller
              control={control}
              name="pinterest"
              id="pinterest"
              defaultValue={defaultFormValues.pinterest || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Pinterest />
                      </InputAdornment>
                    ),
                  }}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  className={classes.formInput}
                  fullWidth
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {!isCEUser && (
          <Grid item xs={12} className={classes.agentDetailDiv}>
            <Controller
              control={control}
              name="tiktok"
              id="tiktok"
              defaultValue={defaultFormValues.tiktok || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div style={{ width: '24px' }}>
                          <TikTokIcon />
                        </div>
                      </InputAdornment>
                    ),
                  }}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  className={classes.formInput}
                  fullWidth
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {!isCEUser && (
          <Grid item xs={12} className={classes.agentDetailDiv}>
            <Controller
              control={control}
              name="linkedin"
              id="linkedin"
              defaultValue={defaultFormValues.linkedin || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkedIn />
                      </InputAdornment>
                    ),
                  }}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  className={classes.formInput}
                  fullWidth
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {!isCEUser && (
          <Grid item xs={12} className={classes.agentDetailDiv}>
            <Controller
              control={control}
              name="website"
              id="website"
              defaultValue={defaultFormValues.website || ''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  placeholder="Website"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  className={classes.formInput}
                  fullWidth
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {!isMember && !isCEUser && !isSalesPersonOrManagerLogin && (
          <Grid item xs={6}>
            <Controller
              control={control}
              rules={Validations.REQUIRED}
              name="salesPersonId"
              id="salesPersonId"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <div className={classes.formInput}>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={value}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.id}
                    loadOptions={loadSalesOptions}
                    onChange={(v) => onChange(v)}
                    placeholder="Sales Person*"
                    styles={customStyles}
                    menuPlacement="top"
                  />
                  {error && (
                    <Typography className={classes.formErrorText}>{error.message}</Typography>
                  )}
                </div>
              )}
            />
          </Grid>
        )}
        {!isMember && !isSalesPersonOrManagerLogin && (
          <Grid item xs={6}>
            <Controller
              control={control}
              rules={Validations.REQUIRED}
              name="status"
              id="status"
              defaultValue={defaultFormValues.status || 0}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  variant="outlined"
                  label="Status*"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  className={classes.formInput}
                  SelectProps={{
                    native: true,
                  }}
                  fullWidth
                  select
                  disabled={!isSuperAdmin}
                >
                  <option key={1} value={1}>
                    Active
                  </option>
                  <option key={0} value={0}>
                    Inactive
                  </option>
                </TextField>
              )}
            />
          </Grid>
        )}
        {agentId && !isSalesPersonOrManagerLogin && !isCEUser && (
          <Grid item xs={12}>
            <UploadAsset userId={agentId} assetId={assetId} assetStatus={assetStatus} />
          </Grid>
        )}
        {!isSalesPersonOrManagerLogin && !isCEUser && (
          <Grid item xs={12}>
            <Controller
              control={control}
              name="bioText"
              id="bioText"
              defaultValue={defaultFormValues.bioText || ''}
              render={({ field: { value, onChange } }) => (
                <WYSIWYGEditor value={value} onChange={onChange} />
              )}
            />
          </Grid>
        )}
        {!isMember && !isCEUser && (
          <Grid item xs={12}>
            <Controller
              control={control}
              name="referredBy"
              id="referredBy"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <div className={classes.formInput}>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    isDisabled={agentId && defaultFormValues.referredBy}
                    value={value}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.id}
                    loadOptions={loadAgentsOptions}
                    onChange={(v) => onChange(v)}
                    placeholder="Referred By"
                    styles={customStyles}
                    menuPlacement="top"
                  />
                  {error && (
                    <Typography className={classes.formErrorText}>{error.message}</Typography>
                  )}
                </div>
              )}
            />
          </Grid>
        )}
        {!agentId && !isSalesPersonOrManagerLogin && !isCEUser && (
          <Grid item xs={12}>
            <Controller
              control={control}
              name="sendInvitation"
              id="sendInvitation"
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  className={classes.formInput}
                  control={<Checkbox checked={value} onChange={onChange} color="primary" />}
                  label="Send Invitation"
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

AddAgentForm.propTypes = {
  defaultFormValues: PropTypes.objectOf(),
  control: PropTypes.element,
  userProfile: PropTypes.string.isRequired,
  setUserProfile: PropTypes.func.isRequired,
  setUploading: PropTypes.func.isRequired,
  agentId: PropTypes.number,
  assetId: PropTypes.number.isRequired,
  assetStatus: PropTypes.string.isRequired,
  hasSignedOrPaidContract: PropTypes.bool,
  module: PropTypes.number,
  isMember: PropTypes.number,
};
AddAgentForm.defaultProps = {
  defaultFormValues: {},
  control: null,
  agentId: null,
  hasSignedOrPaidContract: false,
  module: agentTypeValues.AGENTS,
  isMember: 0,
};

export default AddAgentForm;
