import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const AmendContractOverdueDialog = ({ handleDialog }) => (
  <Dialog open onClose={handleDialog}>
    <DialogTitle>Amend Contract</DialogTitle>
    <DialogContent>
      <DialogContentText>
        This NRP has a past-due payment and the amendment can not be sent. The NRP needs to update
        their payment information and pay their balance before the amendment can be sent, or the
        contract must be terminated and a non-amendment contract sent.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleDialog} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

AmendContractOverdueDialog.propTypes = {
  handleDialog: PropTypes.func.isRequired,
};

export default AmendContractOverdueDialog;
