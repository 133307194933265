// Application route path information
const RoutePaths = {
  HOME: '/',
  LOGIN: '/login',
  AGENTS: '/agents',
  LENDERS: '/lenders',
  CE_USERS: '/ce-users',
  TEAM: '/team',
  ZONES: '/zones',
  LENDER_ZONES: '/lender-zones',
  REPORTS: '/reports',
  STATE_CODES: '/state-codes',
  ZIPCODES: '/zipcodes',
  LOGOUT: '/logout',
  UPDATE_PROFILE: '/manage-profile',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:token',
  CHANGE_PASSWORD: '/change-password',
  INVITATION: '/invitation/:token',
  CONTRACT: '/contract/:token',
  CONTRACT_SIGNED: '/:token/signed',
  CONTRACT_CANCELLED: '/:token/cancelled',
  CONTRACT_PAYMENT: '/:token/payment',
  RESOURCES: '/resources',
  WIDGET_ZIPCODE_SEARCH: '/widget/zipcode-search',
  WIDGET_ZIPCODE_SEARCH_V2: '/widget/zipcode-search/v2',
  WIDGET_LENDER_ZIPCODE_SEARCH: '/widget/lender-zipcode-search',
  WIDGET_LENDER_ZIPCODE_SEARCH_V2: '/widget/lender-zipcode-search/v2',
  WIDGET_ZIPCODE_SEARCH_LIST: '/widget/zipcode-search/list',
  // INQUIRIES: '/conversations',
  BILLING: '/account/:page',
  AD_APPROVALS: '/approvals/ads',
  SUBMISSIONS: '/approvals/submissions',
  SALES_DEMO_LINK: '/sales-tool/demo-link',
  NRP_DASHBOARD: '/dashboard',
  CONTENT_ENGINE: '/content-engine',
  ADMIN_RESOURCE: '/manage-resources',
  TV_SHOW: '/tv-show',
  PARTICIPATION: '/participation',
  CONTENT: '/content',
  RESOURCES_ARCHIVE_SUCCESS_CALLS: '/resources/success-calls',
};

export default RoutePaths;
