import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import { makeStyles } from '@material-ui/core/styles';
import Validations from '../../utils/Validations';
import SnackbarMessage from '../SnackbarMessage';
import { createToken } from '../../services/DiscountTokens';
import { searchAgent } from '../../services/Agent';
import formInput from '../../theme/styles/FormInput';

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '208px',
  }),
};

const useStyles = makeStyles(formInput);

const AddToken = ({ closeDialog, agentId }) => {
  const classes = useStyles();
  const { handleSubmit, control, watch } = useForm();

  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const type = watch('type');
  const handleConfirmation = (data) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    setProcessing(true);
    if (Number(type) === 1 && !data.referredBy) {
      setSnackbarMeesage({
        ...snackbarMeesage,
        message: 'Please provide referred agent information when creating referral disount.',
        type: 'error',
        show: true,
      });
      return;
    }
    createToken({ ...data, agentId, referredAgentId: data.referredBy?.id })
      .then(() => {
        setProcessing(false);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'Created successfully.',
          type: 'success',
          show: true,
        });
        closeDialog();
      })
      .catch(({ response }) => {
        setProcessing(false);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data?.message || 'Something went wrong.',
          type: 'error',
          show: true,
        });
      });
  };

  const getAgentSearchParams = (searchText) => {
    let queryString = '1=1';
    if (searchText) queryString += `&searchText=${searchText}`;
    if (agentId) queryString += `&ignoreAgent=${agentId}`;
    queryString += `&exclude_referred=1`;
    return queryString.substring(4);
  };

  const loadAgentsOptions = (inputStateValue, callback) => {
    searchAgent(getAgentSearchParams(inputStateValue), 0).then((res) => {
      callback(
        res.data.rows.map((i) => ({
          name: i.name,
          id: i.id,
        }))
      );
    });
  };

  return (
    <Dialog open onClose={closeDialog} maxWidth="sm" fullWidth>
      <DialogTitle>Add Discount Token</DialogTitle>
      <DialogContent>
        <form id="add-discount-token" onSubmit={handleSubmit(handleConfirmation)}>
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="type"
            id="type"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Type*"
                value={value}
                onChange={onChange}
                error={!!error}
                className={classes.formInput}
                helperText={error ? error.message : null}
                SelectProps={{
                  native: true,
                }}
                fullWidth
                select
              >
                <option key={0} value={0} selected disabled>
                  Select
                </option>
                <option key={1} value={1}>
                  Referral
                </option>
                <option key={2} value={2}>
                  Participation Reward
                </option>
                <option key={3} value={3}>
                  Retention Campaign
                </option>
              </TextField>
            )}
          />

          {Number(type) === 1 && (
            <Controller
              control={control}
              name="referredBy"
              id="referredBy"
              rules={Validations.REQUIRED}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <div style={{ marginBottom: 20 }}>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={value}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.id}
                    loadOptions={loadAgentsOptions}
                    onChange={(v) => onChange(v)}
                    placeholder="Referred NRP*"
                    styles={customStyles}
                    menuPlacement="bottom"
                  />
                  {error && <Typography>{error.message}</Typography>}
                </div>
              )}
            />
          )}

          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="percentOff"
            id="percentOff"
            defaultValue={50}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Discount"
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!error}
                className={classes.formInput}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                helperText={error ? error.message : null}
                SelectProps={{
                  native: true,
                }}
                fullWidth
                select
              >
                <option key={0} value={50} selected>
                  50
                </option>
              </TextField>
            )}
          />

          {Number(type) !== 1 && (
            <Controller
              control={control}
              name="creationReason"
              id="creationReason"
              rules={Validations.REQUIRED}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <div>
                  <TextField
                    label="What is this token for?*"
                    variant="outlined"
                    value={value}
                    className={classes.formInput}
                    onChange={onChange}
                    type="text"
                    error={!!error}
                    helperText={error}
                    fullWidth
                  />
                </div>
              )}
            />
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          form="add-discount-token"
          color="secondary"
          autoFocus
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing}
        >
          Submit
        </Button>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
      </DialogActions>

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

AddToken.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  agentId: PropTypes.number.isRequired,
};

export default AddToken;
