import { green, grey } from '@material-ui/core/colors';

// Style for overview page
const Style = () => ({
  selectedFrame: {
    outline: `2px solid ${green[600]}`,
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardDate: {
    color: grey[600],
    fontSize: '0.8rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  cardImage: {
    objectFit: 'contain',
  },
  processingCard: {
    opacity: 0.2,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  processingDiv: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    color: grey[700],
    flexDirection: 'column',
    fontWeight: 'bold',
  },
  actionBtn: {
    fontSize: 12,
    minWidth: 36,
  },
  contentEngineImage: {
    objectFit: 'contain',
    backgroundColor: 'rgba(0,0,0,0.9)',
  },
  resourcesCardAction: {
    width: 300,
    height: 200,
  },
  resourcesContent: {
    padding: 10,
    width: 280,
    height: 58,
    wordWrap: 'break-word',
    display: 'inline-block',
    overflow: 'hidden',
  },
  resourceTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '26px',
  },
});

export default Style;
