import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Paper,
  Select,
  MenuItem,
  CardContent,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import Validations from '../../utils/Validations';
import SnackbarMessage from '../SnackbarMessage';
import { makeDefaultCard, updateAgentCard } from '../../services/Billing'; // , sendAddCardNotification
import { countries, USState } from '../../config/DataValues';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY);

const ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#424770',
      '::placeholder': {
        color: 'rgba(0,0,0,0.5)',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const AddCardForm = ({ toggleAddCard, refreshList, cardData, editId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('US');
  const [selectedState, setSelectedState] = useState('Alabama');
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const { handleSubmit, control, setValue } = useForm();

  useEffect(() => {
    if (editId && cardData) {
      setValue('street', cardData.billingDetails.address.line1);
      setValue('street2', cardData.billingDetails.address.line2 || '');
      setValue('city', cardData.billingDetails.address.city);
      setValue('stateProvince', cardData.billingDetails.address.state);
      setValue('zipCode', cardData.billingDetails.address.postal_code);
      setSelectedCountry(cardData.billingDetails.address.country);
      setSelectedState(cardData.billingDetails.address.state);
    }
  }, []);

  const addNewCard = async (data) => {
    setProcessing(true);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });

    try {
      if (!stripe || !elements) {
        return;
      }
      if (editId) {
        const updateData = {
          id: editId,
          billingDetails: {
            address: {
              city: data.city,
              country: selectedCountry,
              line1: data.street,
              line2: data.street2 || '',
              postal_code: data.zipCode,
              state: selectedCountry === 'US' ? selectedState : data.stateProvince,
            },
          },
        };
        updateAgentCard(updateData)
          .then((res) => {
            if (res.success && res.data) {
              setSnackbarMeesage({
                ...snackbarMeesage,
                message: `Card has been updated successfully.`,
                type: 'success',
                show: true,
              });
              setTimeout(() => {
                refreshList();
                toggleAddCard();
              }, 4000);
            }
          })
          .catch(() => {
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: `Some error occurred, Please try again.`,
              type: 'error',
              show: true,
            });
            setProcessing(false);
            toggleAddCard();
          });
      } else {
        const card = elements.getElement(CardNumberElement);
        const addBilling = process.env.REACT_APP_STRIPE_ADD_BILLING_INFO;
        const cardPayload = {
          type: 'card',
          card,
          billing_details: {
            name: data.name,
          },
        };
        if (addBilling === 'true') {
          cardPayload.billing_details.address = {
            city: data.city,
            country: selectedCountry,
            line1: data.street,
            line2: data.street2 || '',
            postal_code: data.zipCode,
            state: selectedCountry === 'US' ? selectedState : data.stateProvince,
          };
        }
        const { error, paymentMethod } = await stripe.createPaymentMethod(cardPayload);

        if (error) {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: `Some error occurred, Please try again.`,
            type: 'error',
            show: true,
          });
          setProcessing(false);
          toggleAddCard();
        } else {
          makeDefaultCard({ paymentMethodId: paymentMethod.id })
            .then(() => {
              setSnackbarMeesage({
                ...snackbarMeesage,
                message: `New card is added and marked as default.`,
                type: 'success',
                show: true,
              });
              setTimeout(() => {
                refreshList();
                toggleAddCard();
              }, 4000);
            })
            .catch(() => {
              setSnackbarMeesage({
                ...snackbarMeesage,
                message: `Some error occurred, Please try again.`,
                type: 'error',
                show: true,
              });
              setProcessing(false);
            });
          // await sendAddCardNotification();
        }
      }
    } catch {
      setSnackbarMeesage({
        ...snackbarMeesage,
        message: `Some error occurred, Please try again.`,
        type: 'error',
        show: true,
      });
      setProcessing(false);
      toggleAddCard();
    }
  };

  const handleChange = (event) => {
    setSelectedState('Alabama');
    setSelectedCountry(event.target.value);
  };

  const handleChangeState = (event) => {
    setSelectedState(event.target.value);
  };

  return (
    <Dialog open onClose={toggleAddCard} fullWidth maxWidth="xs">
      <DialogTitle>{editId ? 'Update Billing Information' : 'Add New Card'}</DialogTitle>
      <DialogContent>
        <form id="add-card" onSubmit={handleSubmit(addNewCard)}>
          {!editId && (
            <>
              <Controller
                control={control}
                rules={{
                  ...Validations.REQUIRED,
                }}
                name="name"
                id="name"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <input
                      placeholder="Name On Card"
                      value={value}
                      onChange={onChange}
                      className="PaymentElement"
                    />
                    {error && (
                      <div className="MuiFormHelperText-root Mui-error">{error.message}</div>
                    )}
                  </>
                )}
              />

              <FormControl fullWidth style={{ marginBottom: 15, marginTop: 15 }}>
                <CardNumberElement id="cardNumber" options={ELEMENT_OPTIONS} />
              </FormControl>

              <Box display="flex" justifyContent="space-between" style={{ marginBottom: 25 }}>
                <FormControl fullWidth style={{ marginRight: 10 }}>
                  <CardExpiryElement id="expiry" options={ELEMENT_OPTIONS} />
                </FormControl>
                <FormControl fullWidth style={{ marginLeft: 10 }}>
                  <CardCvcElement id="cvv" options={ELEMENT_OPTIONS} />
                </FormControl>
              </Box>
            </>
          )}
          <Box style={{ marginTop: `${!editId ? 50 : 0}` }}>
            {!editId && <h3>Billing Address</h3>}
            {editId && cardData && (
              <Card style={{ marginBottom: 30 }}>
                <Box>
                  <Paper elevation={12} style={{ backgroundColor: '#e0e0e0a6' }}>
                    <CardContent>
                      <Box display="flex" justifyContent="center" style={{ letterSpacing: 2 }}>
                        <h3>{cardData.billingDetails.name}</h3>
                      </Box>
                      <Box display="flex" justifyContent="center" style={{ letterSpacing: 5 }}>
                        <h3>{`XXXX XXXX XXXX ${cardData.cardDetails.last4}`}</h3>
                      </Box>
                      <Box display="flex" justifyContent="center">
                        <h3>EXPIRES</h3>
                      </Box>
                      <Box mt={-3} display="flex" justifyContent="center">
                        <h4>
                          {`${cardData.cardDetails.exp_month} / ${cardData.cardDetails.exp_year}`}
                        </h4>
                      </Box>
                    </CardContent>
                  </Paper>
                </Box>
              </Card>
            )}

            <FormControl fullWidth style={{ marginBottom: 15, marginTop: 15 }}>
              <InputLabel id="country">Country</InputLabel>
              <Select
                labelId="country"
                id="country"
                value={selectedCountry}
                onChange={handleChange}
                label="Country"
              >
                {countries.map((c) => (
                  <MenuItem value={c.code}>{c.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: 15 }}>
              <Controller
                control={control}
                rules={Validations.REQUIRED}
                name="street"
                id="street"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Street"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: 15, marginTop: 15 }}>
              <Controller
                control={control}
                name="street2"
                id="street2"
                render={({ field: { onChange, value } }) => (
                  <TextField label="Street (line 2)" value={value} onChange={onChange} />
                )}
              />
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: 15, marginTop: 15 }}>
              <Controller
                control={control}
                rules={Validations.REQUIRED}
                name="city"
                id="city"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="City"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </FormControl>
            {selectedCountry && selectedCountry === 'US' ? (
              <FormControl fullWidth style={{ marginBottom: 15, marginTop: 15 }}>
                <InputLabel id="state">State</InputLabel>
                <Select
                  labelId="state"
                  id="state"
                  value={selectedState}
                  onChange={handleChangeState}
                  label="State"
                >
                  {USState.map((c) => (
                    <MenuItem value={c.name}>{c.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <FormControl fullWidth style={{ marginBottom: 15, marginTop: 15 }}>
                <Controller
                  control={control}
                  rules={Validations.REQUIRED}
                  name="stateProvince"
                  id="stateProvince"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="State/Province"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </FormControl>
            )}
            <FormControl fullWidth style={{ marginBottom: 15, marginTop: 15 }}>
              <Controller
                control={control}
                rules={Validations.REQUIRED}
                name="zipCode"
                id="zipCode"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="Zip/Postcode"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </FormControl>
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleAddCard} variant="contained">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          form="add-card"
          disableElevation
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing}
        >
          {editId ? 'Update Billing Info' : 'Add Card'}
        </Button>
      </DialogActions>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

AddCardForm.propTypes = {
  toggleAddCard: PropTypes.func.isRequired,
  refreshList: PropTypes.func.isRequired,
  cardData: PropTypes.object,
  editId: PropTypes.string,
};

AddCardForm.defaultProps = {
  cardData: {},
  editId: '',
};
const AddCard = (props) => (
  <Elements stripe={stripePromise}>
    <AddCardForm {...props} />
  </Elements>
);

export default AddCard;
