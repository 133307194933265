/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import nuggetsStyle from '../../theme/styles/Nuggets';
import { agentAdVideoStatus } from '../../config/DataValues';

const useStyles2 = makeStyles(nuggetsStyle);

const AdVideosIndicator = ({ adVideos, onClick, hasAccess }) => {
  const classes2 = useStyles2();

  const TOTAL_ALLOWED_AD_VIDEOS = 4;
  const [adVideosStatus, setAdVideosStatus] = useState('checked');

  useEffect(() => {
    if (adVideos) {
      if (adVideos.length > 0) {
        adVideos.forEach((adv) => {
          if (adv.status === agentAdVideoStatus.PENDING) {
            setAdVideosStatus('pending');
          }
        });
      } else {
        setAdVideosStatus('na');
      }
    }
  }, [adVideos]);

  const pending = classes2.pendingNuggetIndicator;
  const checked = classes2.checkedNuggetIndicator;
  const status = adVideosStatus;
  const indicatorClass =
    status === 'pending' ? pending : status === 'checked' ? checked : classes2.noNuggetIndicator;

  if (!hasAccess) {
    return (
      <Button
        className={indicatorClass}
        style={{ cursor: 'auto' }}
        disableElevation
        disableRipple
      >{`${adVideos.length}/${TOTAL_ALLOWED_AD_VIDEOS}`}</Button>
    );
  }

  return (
    <Button
      className={indicatorClass}
      onClick={onClick}
    >{`${adVideos.length}/${TOTAL_ALLOWED_AD_VIDEOS}`}</Button>
  );
};

AdVideosIndicator.propTypes = {
  adVideos: PropTypes.arrayOf(PropTypes.any).isRequired,
  onClick: PropTypes.func.isRequired,
  hasAccess: PropTypes.bool.isRequired,
};

export default AdVideosIndicator;
