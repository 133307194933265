/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Avatar,
  Badge,
  Button,
  DialogContentText,
  DialogTitle,
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  DialogActions,
  Box,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AddCircleOutline, Delete, Edit } from '@material-ui/icons';
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import uploadProfilePic from '../services/AWS';
import uploaderStyle from '../theme/ProfileUploader';
import getProfilePicUrl from '../utils/GetProfilePicUrl';
import SnackbarMessage from './SnackbarMessage';
import imageSize from '../utils/ImageUtil';

const useStyles = makeStyles(uploaderStyle);
const supportedExts = ['jpeg', 'png', 'gif'];

const ProfileUploader = ({ onSuccess, profilePic, setUploading, updateHeaderImage }) => {
  const classes = useStyles();

  const [cropper, setCropper] = useState(null);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [cropperModel, setCropperModel] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [cropperLoaded, setCropperLoaded] = useState(false);
  const [cropDetails, setCropDetails] = useState({
    width: 0,
    height: 0,
    isValid: true,
  });

  const onProgress = () => {
    if (!isFileUploading) {
      setIsFileUploading(true);
      setUploading(true);
    }
  };

  const onError = () => {
    setIsFileUploading(false);
    setUploading(false);
  };

  const replaceURL = (URL) => {
    const parent = document.getElementById('user-profile-image');
    if (parent.childNodes[0].tagName === 'svg') {
      parent.removeChild(parent.firstChild);
      const updatedParent = document.getElementById('user-profile-image');
      updatedParent.appendChild(document.createElement('img'));
      updatedParent.childNodes[0].className = 'MuiAvatar-img';
      updatedParent.childNodes[0].src = URL;
    } else {
      parent.childNodes[0].src = URL;
    }
  };

  const onFinish = (filename) => {
    const URL = getProfilePicUrl(filename);
    if (updateHeaderImage) {
      replaceURL(URL);
    }

    onSuccess(URL);
    setIsFileUploading(false);
    setUploading(false);
  };

  const closeCropModel = () => {
    setCropperModel(false);
    setCropper(null);
  };

  const uploadFile = async (data, blobData) => {
    try {
      await axios.put(data.signedUrl, blobData, {
        headers: { 'Content-Type': selectedFile.type },
        onUploadProgress: () => {
          onProgress();
        },
      });
      onFinish(data.filename);
    } catch {
      onError();
    }
  };

  const getSignedUrl = (file, blobData) => {
    closeCropModel();
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });
    const params = {
      objectName: file.name,
      contentType: file.type,
    };

    uploadProfilePic(params)
      .then((data) => {
        uploadFile(data, blobData);
      })
      .catch(() => {
        setIsFileUploading(false);
        setUploading(false);
      });
  };

  const deleteImage = () => {
    onSuccess('');
    setIsFileUploading(false);
    setUploading(false);
    setConfirmDialog(false);
  };

  const initCropper = () => {
    const image = document.getElementById('image-preview');
    const cropperRef = new Cropper(image, {
      aspectRatio: 1 / 1,
      viewMode: 2,
      crop(e) {
        setCropDetails({
          width: parseFloat(e.detail.width).toFixed(0),
          height: parseFloat(e.detail.height).toFixed(0),
          isValid: e.detail.width > 759 && e.detail.height > 759,
        });
      },
    });
    const contData = cropperRef.getContainerData(); // Get container data
    cropperRef.setCropBoxData({ height: contData.height, width: contData.width }); // set data
    setCropperLoaded(true);
    setCropper(cropperRef);
  };

  const readURL = async (evt) => {
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });
    const input = evt.target;
    if (input.files && input.files[0]) {
      const imageDimensions = await imageSize(input.files[0]);

      if (
        input.files[0].type.split('/')[0] === 'image' &&
        supportedExts.includes(input.files[0].type.split('/')[1]) &&
        imageDimensions === true
      ) {
        setSelectedFile(input.files[0]);
        const reader = new FileReader();
        reader.onload = (e) => {
          document.getElementById('image-preview').src = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        setCropperModel(true);
        setCropperLoaded(false);
        setTimeout(initCropper, 1000);
        // clear input
        input.value = '';
      } else {
        setSelectedFile(null);
        if (!supportedExts.includes(input.files[0].type.split('/')[1])) {
          setSnackbarMeesage({
            message: `Sorry, 
            ${input.files[0].name} is invalid, allowed extensions are: ${supportedExts.join(', ')}`,
            type: 'error',
            show: true,
          });
        } else {
          setSnackbarMeesage({
            message: imageDimensions || `Please select image file only.`,
            type: 'error',
            show: true,
          });
        }

        setIsFileUploading(false);
        setUploading(false);

        // clear input
        input.value = '';
      }
    }
  };

  const getCroppedImageUrl = () => {
    if (cropper) {
      cropper.getCroppedCanvas({ width: 760, height: 760 }).toBlob(
        (blob) => {
          getSignedUrl(selectedFile, blob);
        },
        selectedFile.type,
        1
      );
    }
  };

  return (
    <div className={classes.profileUploaderContainer}>
      <div>
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={
            isFileUploading ? (
              <CircularProgress size={30} color="secondary" />
            ) : (
              <>
                {!profilePic && (
                  <label htmlFor="contained-button-file" className={classes.uploadLabel}>
                    <IconButton
                      variant="contained"
                      color="primary"
                      component="span"
                      disabled={isFileUploading}
                      className={!profilePic && classes.uploadIcon}
                      size="small"
                    >
                      <AddCircleOutline fontSize="large" />
                    </IconButton>
                  </label>
                )}
              </>
            )
          }
        >
          <Avatar className={classes.profileAvtar} alt="" src={profilePic} />
          {profilePic && (
            <div className={classes.uploadMultipleLabel}>
              <label htmlFor="contained-button-file">
                <IconButton
                  variant="contained"
                  color="primary"
                  component="span"
                  disabled={isFileUploading}
                  size="small"
                >
                  <Edit fontSize="small" />
                </IconButton>
              </label>
              <IconButton
                variant="contained"
                color="red"
                disabled={isFileUploading}
                className={classes.deleteIcon}
                size="small"
                onClick={() => setConfirmDialog(true)}
              >
                <Delete fontSize="small" />
              </IconButton>
            </div>
          )}
        </Badge>
      </div>
      <div>
        <input id="contained-button-file" accept="image/*" type="file" onChange={readURL} hidden />
      </div>

      {cropperModel && (
        <Dialog open onClose={closeCropModel} maxWidth="lg" fullWidth>
          <DialogTitle>Move, Scale and Select area for profile image</DialogTitle>
          <DialogContent>
            <img id="image-preview" src="#" alt="" hidden={!cropperLoaded} />
            {!cropperLoaded && <CircularProgress size={30} />}
          </DialogContent>
          <DialogActions>
            <Box display="flex" width="100%">
              <Box
                display="flex"
                flexDirection="row"
                flex={2}
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body2">Minimum required resolution: 760x760&nbsp;</Typography>
                <Typography
                  variant="body2"
                  style={{ color: `${!cropDetails.isValid ? 'red' : 'black'}` }}
                >
                  Your selected area: {cropDetails.width}x{cropDetails.height}
                </Typography>
              </Box>
              <Button
                onClick={getCroppedImageUrl}
                color="primary"
                variant="contained"
                disableElevation
                className={classes.mr10}
                disabled={!cropDetails.isValid}
              >
                Save
              </Button>
              <Button onClick={closeCropModel} color="default" variant="contained" disableElevation>
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}

      {confirmDialog && (
        <Dialog open onClose={() => setConfirmDialog(false)}>
          <DialogTitle id="alert-dialog-title">Delete Profile</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to delete this record? This action can not be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteImage} color="secondary" autoFocus>
              Delete
            </Button>
            <Button onClick={() => setConfirmDialog(false)} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </div>
  );
};

export default ProfileUploader;

ProfileUploader.propTypes = {
  profilePic: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  setUploading: PropTypes.func.isRequired,
  updateHeaderImage: PropTypes.bool,
};
ProfileUploader.defaultProps = {
  updateHeaderImage: false,
};
