import React, { useState } from 'react';
import { Box, Paper, Tab, Tabs } from '@material-ui/core';
import PrivateWrapper from '../../layout/Private';
import ZoneMap from '../../components/reports/ZoneMap';
import SaleDashboard from '../../components/reports/SaleDashboard';

// Reports page
const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const Reports = () => {
  const pageName = 'Reports';
  const [tabValue, setTabValue] = useState('zonemap');

  return (
    <PrivateWrapper pageName={pageName}>
      <Box m={2} p={2} component={Paper} elevation={0} minHeight="85vh">
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          onChange={(_, v) => {
            setTabValue(v);
          }}
        >
          <Tab value="zonemap" label="Zone Map" {...a11yProps(0)} />
          <Tab value="saledashboard" label="Sales Performance" {...a11yProps(0)} />
        </Tabs>

        {tabValue === 'zonemap' && (
          <Box mt={2}>
            <ZoneMap />
          </Box>
        )}
        {tabValue === 'saledashboard' && (
          <Box mt={2}>
            <SaleDashboard />
          </Box>
        )}
      </Box>
    </PrivateWrapper>
  );
};

export default Reports;
