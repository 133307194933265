import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import Validations from '../../utils/Validations';
import SnackbarMessage from '../SnackbarMessage';
import { cancelCreditById } from '../../services/DiscountTokens';

const CancelCreditModal = ({ discountTokenId, closeDialog }) => {
  const { handleSubmit, control } = useForm();

  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleConfirmation = ({ reason }) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    setProcessing(true);
    cancelCreditById(discountTokenId, reason)
      .then(() => {
        setProcessing(false);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'Cancelled successfully.',
          type: 'success',
          show: true,
        });
        closeDialog();
      })
      .catch(({ response }) => {
        setProcessing(false);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data?.message || 'Something went wrong.',
          type: 'error',
          show: true,
        });
      });
  };

  return (
    <Dialog open onClose={closeDialog} maxWidth="sm" fullWidth>
      <DialogTitle>Cancel credit</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to cancel this credit? This action can not be undone.
        </DialogContentText>
        <form id="cancel-credit" onSubmit={handleSubmit(handleConfirmation)}>
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="reason"
            id="reason"
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                style={{ margin: '20px 0' }}
                variant="outlined"
                label="Why are you canceling this credit?*"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                fullWidth
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          form="cancel-credit"
          color="secondary"
          autoFocus
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing}
        >
          Submit
        </Button>
        <Button onClick={closeDialog} color="primary">
          Cancel
        </Button>
      </DialogActions>

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

CancelCreditModal.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  discountTokenId: PropTypes.number.isRequired,
};

export default CancelCreditModal;
