/* eslint-disable prefer-promise-reject-errors */
const imageSize = (file) => {
  const promise = new Promise((resolve) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img.width >= 760 && img.height >= 760) {
        resolve(true);
      } else {
        resolve(`Sorry, this image doesn't look like the size we wanted. It's 
          ${img.width} x ${img.height} but we require minimum 760 x 760 size image.`);
      }
    };
  });

  return promise;
};

export const validateProfilePicSize = (file) => {
  const promise = new Promise((resolve) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img.width >= 1080 && img.height >= 1080) {
        resolve(true);
      } else {
        resolve(`Sorry, this image doesn't look like the size we wanted. It's 
          ${img.width} x ${img.height} but we require minimum 1080 x 1080 size image.`);
      }
    };
  });

  return promise;
};

export const getImageHeightAndWidth = (file) => {
  const promise = new Promise((resolve) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
  });

  return promise;
};

export default imageSize;
