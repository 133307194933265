import { Button, Chip, Grid, TextField, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import formInput from '../../theme/styles/FormInput';
import { validateZipCode } from '../../services/Zones';

const useStyles = makeStyles(formInput);
const numberPattern = /^\d+$/;

const ZoneZipCodes = ({
  zipCodes,
  setZipCodes,
  error,
  clearError,
  stateCodeId,
  setDisableSubmit,
  zoneType,
}) => {
  const classes = useStyles();

  const [zipCode, setZipCode] = useState('');
  const [errorCodes, setErrorCodes] = useState([]);
  const [duplicateCodeMessages, setDuplicateCodeMessages] = useState([]);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setErrorCodes([]);
    setDuplicateCodeMessages([]);
  }, [stateCodeId]);

  const validateCode = (code) => {
    // code should not be blank
    // code should have max length of 6 chars
    // code should have number type
    if (code && code.length <= 6 && numberPattern.test(code)) {
      const checkExists = zipCodes.filter((c) => c === code);
      if (checkExists.length === 0) {
        return code;
      }
    }
    return false;
  };

  const addedNewZipCode = () => {
    clearError('');
    if (zipCode) {
      setProcessing(true);
      setDisableSubmit(true);
      const splitZipcodes = zipCode.split(',');
      const varify = splitZipcodes
        .map((code) => validateCode(code.trim()))
        .filter((x) => x !== false);

      if (varify.length > 0) {
        validateZipCode({ zipCodes: varify.join(','), stateCodeId }, zoneType)
          .then((res) => {
            const availableCodes = res.data.filter((x) => x.status === true);
            const notAvailableCodes = res.data.filter(
              (x) => x.status === false && x.invalid === true
            );
            const duplicateCodes = res.data.filter(
              (x) => x.status === false && x.invalid === false
            );

            if (availableCodes.length > 0) {
              availableCodes.forEach((code) => {
                zipCodes.push(code.code);
                setZipCodes(zipCodes);
              });
            }

            if (notAvailableCodes.length > 0) {
              const codeArr = notAvailableCodes.map((code) => code.code);
              setErrorCodes(codeArr);
            }
            if (duplicateCodes.length > 0) {
              const duplicateZipcodeMessageArr = [];
              duplicateCodes.forEach((code) => {
                duplicateZipcodeMessageArr.push(code.message);
                setZipCodes(zipCodes);
              });
              setDuplicateCodeMessages(duplicateZipcodeMessageArr);
            }

            setZipCode('');
            setProcessing(false);
            setDisableSubmit(false);
          })
          .catch(() => {
            setProcessing(false);
            setDisableSubmit(false);
          });
      } else {
        setErrorCodes(splitZipcodes);
        setZipCode('');
        setProcessing(false);
        setDisableSubmit(false);
      }
    }
  };

  const deleteCode = (code) => {
    const updated = zipCodes.filter((c) => c !== code);
    setZipCodes(updated);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={9}>
        <TextField
          variant="outlined"
          className={classes.formInput}
          name="zipCode"
          id="zipCode"
          label="Zip Code*"
          fullWidth
          value={zipCode}
          onChange={(e) => {
            setZipCode(e.target.value);
          }}
          size="small"
          error={!!error}
          helperText={error || null}
          onInput={(e) => {
            setErrorCodes([]);
            setDuplicateCodeMessages([]);
            e.target.value = e.target.value.split(' ').join(',');
          }}
          disabled={stateCodeId === 0}
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="contained"
          color="default"
          onClick={addedNewZipCode}
          disabled={stateCodeId === 0 || processing}
          startIcon={processing ? <CircularProgress size={25} /> : <Add />}
        >
          Add
        </Button>
      </Grid>
      {errorCodes.length > 0 && (
        <Grid xs={12} className={classes.formInput} style={{ marginBottom: '10px' }}>
          <Alert
            severity="error"
            style={{ padding: '1px 5px' }}
          >{`Invalid zip code(s): ${errorCodes.join(', ')}`}</Alert>
        </Grid>
      )}
      {duplicateCodeMessages.length > 0 && (
        <Grid xs={12} className={classes.formInput}>
          {duplicateCodeMessages.map((m) => (
            <Alert severity="error" style={{ padding: '1px 5px' }}>
              {m}
            </Alert>
          ))}
        </Grid>
      )}
      <Grid className={classes.formInput}>
        {zipCodes.length > 0 &&
          zipCodes.map((code) => (
            <Chip label={code} className={classes.chip} onDelete={() => deleteCode(code)} />
          ))}
      </Grid>
    </Grid>
  );
};

ZoneZipCodes.propTypes = {
  zipCodes: PropTypes.arrayOf(PropTypes.any).isRequired,
  error: PropTypes.string,
  setZipCodes: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  setDisableSubmit: PropTypes.func.isRequired,
  stateCodeId: PropTypes.number.isRequired,
  zoneType: PropTypes.number.isRequired,
};
ZoneZipCodes.defaultProps = {
  error: '',
};

export default ZoneZipCodes;
