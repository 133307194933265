import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { viewZoneById } from '../../services/Zones';
import zoneStyle from '../../theme/styles/Zone';

const useStyles = makeStyles(zoneStyle);

const FormDialog = ({ open, editId, closeModel, title }) => {
  const classes = useStyles();
  const tableData = {
    columns: [
      { name: 'Name', key: 'name' },
      { name: 'State', key: 'state' },
      { name: 'Zone Type', key: 'type' },
      { name: 'Cities', key: 'cities' },
      { name: 'Zipcodes', key: 'zipCodes', tags: true },
    ],
    data: [],
  };

  const [viewData, setViewData] = useState(tableData);

  useEffect(() => {
    if (editId) {
      viewZoneById(editId).then((res) => {
        const { data } = res;
        setViewData({
          ...tableData,
          data: {
            ...data,
            zipCodes: data.zoneZipCodes ? data.zoneZipCodes.split(', ') : [],
            state: data.stateCodeId.name,
          },
        });
      });
    }
  }, []);

  const handleDialog = () => {
    closeModel();
  };

  return (
    <Dialog open={open} onClose={handleDialog} fullWidth>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <TableContainer>
          <Table className={classes.viewTable}>
            {viewData.columns.map((row) => (
              <TableRow>
                <TableCell width="30%" className={classes.viewLabel}>
                  {row.name}
                </TableCell>
                <TableCell width="70%" className={classes.detailsCell}>
                  {row.tags ? (
                    <ZipCodeTags zipCodes={viewData.data[row.key] ? viewData.data[row.key] : []} />
                  ) : (
                    viewData.data[row.key] || '-'
                  )}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleDialog} disableElevation>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ZipCodeTags = ({ zipCodes }) => {
  const classes = useStyles();
  return (
    <>
      {zipCodes.length > 0
        ? zipCodes.map((code) => <Chip label={code} className={classes.codeChip} />)
        : '-'}
    </>
  );
};

ZipCodeTags.propTypes = {
  zipCodes: PropTypes.arrayOf().isRequired,
};

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModel: PropTypes.func.isRequired,
  editId: PropTypes.number,
  title: PropTypes.string,
};
FormDialog.defaultProps = {
  editId: 0,
  title: '',
};

export default FormDialog;
