/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Avatar,
  Button,
  Grid,
  Typography,
  Box,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import RoomIcon from '@material-ui/icons/Room';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import AgentBanners from './AgentBanners';
import { fetchPurchasedZone, viewAgentById } from '../../services/Agent';
import listingStyle from '../../theme/styles/TableListing';
import RoutePaths from '../../config/Routes';
import ViewVideo from '../assets/ViewVideo';
import { trackUserClick } from '../../utils/Activity';
import { agentTypeValues } from '../../config/DataValues';

const useStyles2 = makeStyles(listingStyle);

const AgentOverviewPage = () => {
  const classes2 = useStyles2();
  const history = useHistory();

  const getDetails = window.localStorage.getItem('userDetail');
  const userId = getDetails ? Number(JSON.parse(getDetails).id) : 0;
  const isTeamLeader = getDetails ? JSON.parse(getDetails).isTeamLeader : false;
  const agentType = getDetails
    ? Number(JSON.parse(getDetails).agentType || agentTypeValues.AGENTS)
    : agentTypeValues.AGENTS;
  const isCEUser = agentType === agentTypeValues.CE_USERS;

  const [userDetails, setUserDetails] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const [viewModel, setViewModel] = useState(false);
  const [assetStatus, setAssetStatus] = useState('');
  const [assetId, setAssetId] = useState(0);
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(0);
  const [agentWebStatus, setAgentWebStatus] = useState('-');
  const [agentWebUrl, setAgentWebUrl] = useState('');
  const [webStatusModal, setWebStatusModal] = useState(false);
  const [pendingCancelationMessage, setPendingCancelationMessage] = useState('');

  const dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  useEffect(() => {
    if (isCEUser) {
      history.push(RoutePaths.CONTENT);
      return;
    }

    setDataLoaded(false);
    viewAgentById(userId)
      .then((res) => {
        setUserDetails(res.data);
        setAssetStatus(res.data.assetStatus);
        setAssetId(res.data.assetId || 0);
        setAgentWebStatus(res.data.webStatus || '-');
        setAgentWebUrl(res.data.webUrl || '');
        setDataLoaded(true);
        setSubscriptionStartDate(res.data.subscriptionStartDate || 0);
      })
      .catch(() => {
        setUserDetails(null);
        setDataLoaded(true);
      });
  }, []);

  useEffect(() => {
    fetchPurchasedZone(userId).then((res) => {
      setRows(
        res.data.map((d) => ({
          name: d.name,
          zipcodes: d.zipcodes,
          price: d.price,
          hasPendingCancelation: d.hasPendingCancelation,
        }))
      );
      const pendingCancelationZones = [];
      for (let i = 0; i < res.data.length; i += 1) {
        if (res.data[i].hasPendingCancelation) {
          pendingCancelationZones.push(res.data[i].name);
        }
      }
      if (pendingCancelationZones.length > 0) {
        setPendingCancelationMessage(
          `${pendingCancelationZones.join(
            ','
          )} zone(s) have pending cancelation requests. Those will be allotted to you once those zones are available.`
        );
      }
      setDataLoaded(true);
    });
  }, []);

  const toggleWebStatus = () => {
    setWebStatusModal(!webStatusModal);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <AgentBanners />
        </Grid>
      </Grid>
      {dataLoaded && userDetails && (
        <Grid container style={{ backgroundColor: 'white', borderRadius: 10 }}>
          <Grid item xs={3} sm={2} style={{ padding: 10 }}>
            <Avatar
              alt={`${userDetails.firstName} ${userDetails.lastName}`}
              src={userDetails.profilePic}
              style={{ width: 130, height: 130, margin: '0px auto' }}
            />
          </Grid>
          <Grid item xs={7} sm={8} style={{ padding: 10 }}>
            <Typography variant="h6" style={{ marginBottom: 5 }}>
              {userDetails.firstName} {userDetails.lastName}
            </Typography>
            <div
              variant="div"
              style={{
                marginBottom: 10,
                maxHeight: 150,
                overflowX: 'hidden',
                overflowY: 'scroll',
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: userDetails.bio }} />
            </div>
            <TableContainer>
              <Table className={classes2.agentOverviewTable}>
                <TableBody>
                  <TableRow>
                    <TableCell width="10%">
                      <b>Email:</b>
                    </TableCell>
                    <TableCell width="90%" colSpan={2}>
                      {userDetails.email}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Location:</b>
                    </TableCell>
                    <TableCell colSpan={2}>
                      {userDetails.location ? userDetails.location : ''}
                      {userDetails.location && userDetails.state?.code ? ', ' : ''}
                      {userDetails.state?.code ? userDetails.state.code : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Phone:</b>
                    </TableCell>
                    <TableCell width="35%">{userDetails.phone}</TableCell>
                    <TableCell width="55%">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        }}
                      >
                        <BeenhereIcon
                          className={
                            userDetails.displayPhone === 1
                              ? classes2.odisIndicatorOn
                              : classes2.odisIndicatorOff
                          }
                        />
                        <span
                          className={
                            userDetails.displayPhone === 1
                              ? classes2.odisIndicatorLabelOn
                              : classes2.odisIndicatorLabelOff
                          }
                        >
                          Display in ODIS
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={2} sm={2} style={{ padding: 10 }}>
            <Button
              className={classes2.agentOverviewButtons}
              variant="outlined"
              color="primary"
              fullWidth
              disableElevation
              to={RoutePaths.UPDATE_PROFILE}
              component={Link}
              onClick={() => trackUserClick(8)}
            >
              Edit Profile
            </Button>
            <Button
              className={classes2.agentOverviewButtons}
              variant="outlined"
              color="primary"
              fullWidth
              disableElevation
              endIcon={<OpenInNewIcon />}
              disabled={!agentWebUrl}
              href={agentWebUrl}
              target="_blank"
              onClick={() => trackUserClick(7)}
            >
              Open Website
            </Button>
            <Button
              className={classes2.agentOverviewButtons}
              variant="outlined"
              color="primary"
              fullWidth
              disableElevation
              endIcon={<OpenInNewIcon />}
              disabled={!userDetails.contractPdf}
              href={userDetails.contractPdf}
              target="_blank"
              onClick={() => trackUserClick(10)}
            >
              Contract
            </Button>
            <Button
              className={classes2.agentOverviewButtons}
              variant="outlined"
              color="primary"
              fullWidth
              disableElevation
              endIcon={<OpenInNewIcon />}
              disabled={!userDetails.invoicePdf || subscriptionStartDate}
              href={userDetails.invoicePdf}
              target="_blank"
              onClick={() => trackUserClick(11)}
            >
              Invoice
            </Button>
            <Button
              className={classes2.agentOverviewButtons}
              variant="outlined"
              color="primary"
              fullWidth
              disableElevation
              disabled={assetStatus === 'waiting' || Number(assetId) === 0}
              onClick={() => {
                setViewModel(true);
                trackUserClick(12);
              }}
            >
              Play Video
            </Button>
          </Grid>
        </Grid>
      )}

      {isTeamLeader && dataLoaded && (
        <Grid container style={{ backgroundColor: 'white', borderRadius: 10, marginTop: 20 }}>
          <Grid item xs={12} sm={12} style={{ padding: 10 }}>
            <Box display="flex" justifyContent="space-between" ml={1}>
              <Typography variant="h6">Zones</Typography>
            </Box>
            <TableContainer>
              <Table className={classes2.tableDataAgentZones} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell width="10%">Zone</TableCell>
                    <TableCell width="45%">Zipcodes</TableCell>
                    <TableCell width="5%">NRP</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!dataLoaded &&
                    [1, 2, 3].map((val) => (
                      <TableRow key={`st-${val}`}>
                        <TableCell component="th" scope="row">
                          <Skeleton variant="text" className={classes2.placeholderSkeleton} />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Skeleton variant="text" className={classes2.placeholderSkeleton} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" className={classes2.placeholderSkeleton} />
                        </TableCell>
                      </TableRow>
                    ))}
                  {dataLoaded &&
                    (rows.length > 0 ? (
                      rows.map((row) => (
                        <TableRow key={`st-${row.id}`}>
                          <TableCell scope="row">
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                              }}
                            >
                              <RoomIcon className={classes2.agentZoneIcon} />
                              <span>
                                <b>{row.name}</b>
                              </span>
                            </div>
                          </TableCell>
                          <TableCell>{row.zipcodes}</TableCell>
                          <TableCell>{dollarUS.format(row.price)}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          align="center"
                          size="medium"
                          colSpan={3}
                          className={classes2.noRecord}
                          style={{ padding: 20, fontSize: 15 }}
                        >
                          No records found
                        </TableCell>
                      </TableRow>
                    ))}
                  {dataLoaded && pendingCancelationMessage !== '' && (
                    <TableRow>
                      <TableCell
                        size="medium"
                        colSpan={3}
                        className={classes2.noRecord}
                        style={{ padding: 20, fontSize: 15 }}
                      >
                        {pendingCancelationMessage}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
      <br />
      <br />
      <br />

      {viewModel && (
        <ViewVideo
          userId={userId}
          viewModelOpen={viewModel}
          handleViewModel={(flag) => setViewModel(flag)}
        />
      )}

      <Dialog open={webStatusModal} onClose={toggleWebStatus}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
            {agentWebStatus !== 'PENDING' && agentWebStatus !== 'APPROVED' && agentWebUrl === '' && (
              <>
                <div>
                  Your profile is being created on Negotiators.tv. Once it&apos;s published,
                  you&apos;ll see the url of your profile here.
                </div>
              </>
            )}
            {agentWebStatus === 'PENDING' && (
              <>
                <h2 style={{ color: 'red' }}>Pending</h2>
                <div>
                  We&apos;re verifying your profile updates. Once it&apos;s verified, you&apos;ll
                  see those changes live on your Negotiators.tv profile.
                </div>
              </>
            )}
            {agentWebStatus === 'APPROVED' && (
              <>
                <h2 style={{ color: 'green' }}>Live!</h2>
                <div>
                  Thanks for submitting your information. Your profile is updated on Negotiators.tv
                </div>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleWebStatus} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AgentOverviewPage;
