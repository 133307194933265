import { Box } from '@material-ui/core';
import React from 'react';
import AgentContentEngineList from './AgentContentEngineList';

const AgentContentEngine = () => (
  <Box>
    <Box mt={4}>
      <AgentContentEngineList />
    </Box>
  </Box>
);

export default AgentContentEngine;
