import { Box, Paper, Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AgentNrpInsights from '../../components/agent/AgentNrpInsights';
import AgentBlogs from '../../components/agent/AgentBlogs';
import Private from '../../layout/Private';
import { trackUserClick } from '../../utils/Activity';

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

function getTabID() {
  let initilalTabOpen = 'nrpInsights';
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('#');
  const anchor = urlParts.length > 1 ? urlParts[1] : '';

  if (anchor === 'nrp-insights' || anchor === '') {
    initilalTabOpen = 'nrpInsights';
  } else if (anchor === 'guest-blogs') {
    initilalTabOpen = 'blog';
  }
  return initilalTabOpen;
}

const Participation = () => {
  const pageName = 'Participation';

  // const user = window.localStorage.getItem('userDetail');
  // const userNameObj = user ? JSON.parse(user) : null;
  // const showNrpPromoLink = showNrpPromoLinkModule(userNameObj?.agentType);
  const [tabValue, setTabValue] = useState(getTabID());

  useEffect(() => {
    let objId = 0;
    if (tabValue === 'nrpInsights') {
      objId = 37;
    } else if (tabValue === 'blog') {
      objId = 55;
    }
    if (objId) {
      trackUserClick(objId);
    }
  }, [tabValue]);

  return (
    <Private pageName={pageName}>
      <Box m={2} p={2} component={Paper} elevation={0} minHeight="85vh">
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          onChange={(_, v) => {
            setTabValue(v);
          }}
        >
          <Tab value="nrpInsights" label="NRP Insights" {...a11yProps(0)} />
          <Tab value="blog" label="Guest Blogs" {...a11yProps(0)} />
        </Tabs>
        {tabValue === 'nrpInsights' && (
          <Box mt={2}>
            <AgentNrpInsights />
          </Box>
        )}
        {tabValue === 'blog' && (
          <Box mt={2}>
            <AgentBlogs />
          </Box>
        )}
      </Box>
    </Private>
  );
};

export default Participation;
