import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import List from '@material-ui/core/List';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import { getAgentNotifications, markNotificationRead } from '../services/Agent';
import { renderDashboardIconAgentSide } from './DashboardIcons';

const MenuNotifications = () => {
  const [data, setData] = useState({
    rows: [],
    currentPage: 1,
    totalPages: 0,
    unReadCount: 0,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [activeNotification, setActiveNotification] = useState({
    id: 0,
    title: '',
    description: '',
  });
  const [reloadData, setReloadData] = useState(1);

  useEffect(() => {
    getAgentNotifications()
      .then((res) => {
        setData({
          ...data,
          rows: [],
        });
        setTimeout(() => {
          setData(res.data);
        }, 250);
      })
      .catch(() => {
        // ignore
      });
  }, [reloadData]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNotification = (obj) => {
    if (obj.notifRead > 0) {
      return;
    }

    handleClose();
    markNotificationRead(obj.id)
      .then(() => {
        setReloadData(reloadData + 1);
        setActiveNotification({
          id: obj.id,
          title: obj.title,
          description: obj.description,
        });
      })
      .catch(() => {
        // ignore
      });
  };

  const handleNotificationRead = () => {
    setActiveNotification({
      id: 0,
      title: '',
      description: '',
    });
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="notif-menu"
        aria-haspopup="true"
        style={{ marginRight: 15 }}
        onClick={handleClick}
      >
        <Badge badgeContent={data.unReadCount > 99 ? '99+' : data.unReadCount} color="secondary">
          <NotificationsIcon style={{ color: data.unReadCount > 0 ? 'black' : 'grey' }} />
        </Badge>
      </IconButton>
      <Menu
        id="notif-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        style={{ marginTop: 40 }}
        MenuListProps={{ disablePadding: true }}
      >
        <List style={{ width: 300, maxHeight: 600 }}>
          {data.rows.length > 0 &&
            data.rows.map((obj) => (
              <ListItem
                alignItems="flex-start"
                key={`not-${obj.id}`}
                style={{
                  cursor: 'pointer',
                  backgroundColor: obj.notifRead === 0 ? 'rgba(0, 0, 0, 0.08)' : 'none',
                  marginBottom: 5,
                }}
                onClick={() => handleOpenNotification(obj)}
              >
                <ListItemAvatar style={{ minWidth: 42 }}>
                  {renderDashboardIconAgentSide(obj.icon, 'grey')}
                </ListItemAvatar>
                <ListItemText primary={obj.title} secondary={obj.description} />
              </ListItem>
            ))}
          {data.rows.length === 0 && (
            <ListItem
              alignItems="flex-start"
              key="not-data"
              style={{
                backgroundColor: 'none',
                marginBottom: 5,
              }}
            >
              No data found.
            </ListItem>
          )}
        </List>
      </Menu>

      <Dialog
        open={activeNotification.id !== 0}
        onClose={handleNotificationRead}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{activeNotification.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {activeNotification.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={handleNotificationRead}>
            Mark As Read
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MenuNotifications;
