import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
import { ArrowDownward, ImportExport } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableToolbar from '../TableToolbar';
import listingStyle from '../../theme/styles/TableListing';
import { listZipcodeSearch, toggleWaitlist } from '../../services/Widgets';
import ViewWaitlist from './ViewWaitlist';
import FormDialog from './FormDialog';

const useStyles = makeStyles(listingStyle);

const ZipcodeSearchList = ({ zoneType }) => {
  const classes = useStyles();

  const columns = [
    { name: 'Search Date', id: 'searchTime', width: '15%' },
    { name: 'Form Option', id: 'formOption', width: '15%' },
    { name: 'Zip Code(s)', id: 'zipcodes', width: '25%' },
    { name: 'Open Zone(s)', id: 'openZonesFound', width: '25%' },
    { name: 'Waitlist', id: 'waitlist', width: '10%', align: 'right' },
  ];

  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reloadRows, setReloadRows] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const [waitlistId, setWaitlistId] = useState(0);
  const [addManualEntry, setAddManualEntry] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const sortHandler = (colId) => {
    setActivePage(1);
    setSortBy(colId);
    setOrderBy(orderBy === 'asc' ? 'desc' : 'asc');
    setReloadRows(!reloadRows);
  };

  const getParams = () => {
    let queryString = '1=1';

    if (sortBy) queryString += `&sortBy=${sortBy}`;
    if (orderBy) queryString += `&orderBy=${orderBy}`;
    if (searchText) queryString += `&searchText=${searchText}`;

    return queryString.substring(4);
  };

  const searchList = () => {
    setActivePage(1);
    setReloadRows(!reloadRows);
  };

  const refreshList = () => {
    setReloadRows(!reloadRows);
  };

  const resetList = () => {
    setSearchText('');
    setOrderBy('');
    setSortBy('');
    setReloadRows(!reloadRows);
    setActivePage(1);
  };

  useEffect(() => {
    setDataLoaded(false);

    listZipcodeSearch(activePage, getParams(), zoneType)
      .then((res) => {
        setRows(res.data.rows);
        setTotalPages(res.data.totalPages);
        setDataLoaded(true);
      })
      .catch(() => {
        setDataLoaded(true);
      });
  }, [reloadRows, activePage]);

  const handleOpenViewData = (id) => {
    setSelectedRow(id);
  };
  const handleCloseViewData = () => {
    setSelectedRow(0);
  };

  const handleWaitlistToggel = (id, val) => {
    setWaitlistId(id);

    toggleWaitlist(id, { waitlist: val })
      .then(() => {
        setWaitlistId(0);
        setReloadRows(!reloadRows);
      })
      .catch(() => {
        setWaitlistId(0);
        setReloadRows(!reloadRows);
      });
  };

  const handleManualEntryCreate = () => {
    setAddManualEntry(true);
  };
  const handleManualEntryClose = (refresh) => {
    setAddManualEntry(false);
    if (refresh) {
      setReloadRows(!reloadRows);
    }
  };

  return (
    <>
      <div className={classes.filterToolbar}>
        <TableToolbar
          refreshList={refreshList}
          searchList={searchList}
          getSearchText={(e) => setSearchText(encodeURIComponent(e.target.value))}
          resetList={resetList}
          addNew={handleManualEntryCreate}
          showAddOption
        />
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.tableData} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell width={col.width} align={col.align || ''}>
                  <TableSortLabel
                    active={sortBy === col.id}
                    direction={sortBy === col.id ? orderBy : 'asc'}
                    onClick={() => sortHandler(col.id)}
                    IconComponent={sortBy !== col.id ? ImportExport : ArrowDownward}
                  >
                    <span>{col.name}</span>
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell width="10%" align="right">
                <span>View</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!dataLoaded &&
              [1, 2, 3, 4, 5].map((val) => (
                <TableRow key={`aql-${val}`}>
                  <TableCell component="th" scope="row">
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell align="right">
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                </TableRow>
              ))}
            {dataLoaded &&
              rows.map((row) => (
                <TableRow key={`aq-${row.id}`}>
                  <TableCell>{row.searchTime}</TableCell>
                  <TableCell>{row.formOption}</TableCell>
                  <TableCell>{row.zipcodes}</TableCell>
                  <TableCell>{row.openZonesFound}</TableCell>
                  <TableCell align="right">
                    {Number(row.waitlist) === 1 && (
                      <IconButton
                        size="small"
                        variant="contained"
                        color="secondary"
                        title="Remove From Waitlist"
                        onClick={() => handleWaitlistToggel(row.id, 0)}
                        disabled={waitlistId === row.id}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    )}
                    {Number(row.waitlist) === 0 && (
                      <IconButton
                        size="small"
                        variant="contained"
                        title="Add To Waitlist"
                        onClick={() => handleWaitlistToggel(row.id, 1)}
                        disabled={waitlistId === row.id}
                      >
                        <CheckIcon />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton size="small" onClick={() => handleOpenViewData(row.id)}>
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {dataLoaded && rows.length === 0 && (
              <TableRow>
                <TableCell
                  align="center"
                  size="medium"
                  colSpan={6}
                  className={classes.noRecord}
                  style={{ padding: 20, fontSize: 15 }}
                >
                  No records found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {dataLoaded && totalPages > 1 && (
        <Pagination
          count={totalPages}
          showFirstButton
          showLastButton
          className={classes.tablePagination}
          onChange={(_, pageNumber) => {
            setActivePage(pageNumber);
            setReloadRows(!reloadRows);
          }}
          page={activePage}
        />
      )}

      {addManualEntry && (
        <FormDialog
          closeModel={() => handleManualEntryClose(false)}
          onSuccess={() => handleManualEntryClose(true)}
          zoneSearchType={zoneType}
        />
      )}

      {selectedRow !== 0 && <ViewWaitlist rowId={selectedRow} closeModel={handleCloseViewData} />}
    </>
  );
};

ZipcodeSearchList.propTypes = {
  zoneType: PropTypes.number.isRequired,
};

export default ZipcodeSearchList;
