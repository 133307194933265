import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { Controller, useForm } from 'react-hook-form';
import SnackbarMessage from '../SnackbarMessage';
import Validations from '../../utils/Validations';
import { agentAdVideoStatus } from '../../config/DataValues';
import { deleteAgentAdVideoById, updateAgentAdVideoStatus } from '../../services/AgentAdVideos';

const ActionConfirmDialog = ({ handleClose, onSuccess, action, adVideoId }) => {
  const { handleSubmit, control } = useForm();

  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleConfirmation = (data) => {
    setProcessing(true);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });

    if (action === 'delete') {
      deleteAgentAdVideoById(adVideoId)
        .then(() => {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: 'Deleted successfully.',
            type: 'success',
            show: true,
          });
          setTimeout(onSuccess, 1000);
        })
        .catch(({ response }) => {
          setProcessing(false);
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.data?.message || 'Something went wrong.',
            type: 'error',
            show: true,
          });
        });
    } else if (action === 'approve' || action === 'reject') {
      const newUpdatedStatus =
        action === 'approve' ? agentAdVideoStatus.APPROVED : agentAdVideoStatus.DISAPPROVED;

      const updatedData = { adVideoId, status: newUpdatedStatus };
      if (data.reason) {
        updatedData.comments = data.reason;
      }

      updateAgentAdVideoStatus(updatedData)
        .then(() => {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: `The video has been 
            ${action === 'approve' ? 'approved' : 'disapproved'} successfully.`,
            type: 'success',
            show: true,
          });

          setTimeout(onSuccess, 1000);
        })
        .catch(({ response }) => {
          setProcessing(false);
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.data?.message || 'Something went wrong.',
            type: 'error',
            show: true,
          });
        });
    }
  };
  const getTitle = () => {
    switch (action) {
      case 'delete':
        return `Delete Video`;
      case 'approve':
        return `Approve Video`;
      case 'reject':
        return `Reject Video`;

      default:
        return '';
    }
  };

  const getDescription = () => {
    switch (action) {
      case 'delete':
        return 'Do you want to delete this video? This action can not be undone.';
      case 'approve':
        return 'Are you sure want to approve this video?';
      case 'reject':
        return 'Are you sure want to reject this video?';

      default:
        return '';
    }
  };

  return (
    <Dialog open onClose={handleClose} fullWidth>
      <DialogTitle id="alert-dialog-title">{getTitle()}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {getDescription()}
          {action === 'reject' && (
            <form id="reject-agent-ad-video">
              <Controller
                control={control}
                rules={Validations.REQUIRED}
                name="reason"
                id="reason"
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    style={{ marginTop: 10 }}
                    variant="outlined"
                    label="Reason*"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    fullWidth
                  />
                )}
              />
            </form>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit(handleConfirmation)}
          color="secondary"
          autoFocus
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing}
        >
          Yes
        </Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

ActionConfirmDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  adVideoId: PropTypes.number.isRequired,
  action: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
export default ActionConfirmDialog;
