/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import agentStyle from '../../theme/styles/Agents';
import formInputStyle from '../../theme/styles/FormInput';
import Validations from '../../utils/Validations';
import { convertTimestampToDate } from '../../utils/Datetime';
import { assignZone, validateAssignZone } from '../../services/Agent';
import { searchSalesPerson } from '../../services/Team';
import SnackbarMessage from '../SnackbarMessage';
import { checkContracts, searchZone } from '../../services/Zones';
import UserTypes from '../../config/UserTypes';
import { contractStatusValue, EXCLUSITIVY, PAYMENT_STATUS } from '../../config/DataValues';
import { createDraftContractZoneItem } from '../../services/Contract';

const useStyles = makeStyles(agentStyle);
const useStyles2 = makeStyles(formInputStyle);

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
};

const AssignZoneForm = ({
  agentId,
  fromAddAgent,
  setTempStateValues,
  setAddMode,
  checkLocalValidationForZone,
  forDraftAmendment,
  agentIsEnrolled,
  zoneType,
}) => {
  const classes = useStyles();
  const classes2 = useStyles2();

  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;

  const defaultUser = {
    id: 0,
    firstName: '',
    lastName: '',
  };
  const loggedInUser = getDetails ? JSON.parse(getDetails) : defaultUser;

  const exclusitivityArr = Object.entries(EXCLUSITIVY);
  const statusArr = Object.entries(PAYMENT_STATUS);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const { handleSubmit, control, reset, setValue } = useForm();

  const isSalesPerson = userType === UserTypes.SALES_PERSON.value;
  const isSalesManager = userType === UserTypes.SALES_MANAGER.value;

  useEffect(() => {
    const name = `${loggedInUser.firstName} ${loggedInUser.lastName}`;
    const soldByValue = isSalesPerson
      ? Number(loggedInUser.id)
      : { id: Number(loggedInUser.id), name };
    setValue('soldBy', soldByValue);
    setValue('purchasedZone', '');
    setValue('exclusivity', (isSalesPerson || isSalesManager) ? 1 : 2);
    setValue('paymentStatus', !agentIsEnrolled ? 1 : 2);
  }, []);

  const checkIsAvaibleForPurchase = (agentWithCompletedPayment, purchaseType) => {
    let isValid = true;

    if (
      agentWithCompletedPayment.length === 1 &&
      !agentWithCompletedPayment.includes(agentId) &&
      purchaseType === 1
    ) {
      isValid = false;
    } else if (agentWithCompletedPayment.length === 2 && purchaseType === 1) {
      isValid = false;
    }

    return isValid;
  };

  const submitForm = (data) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });

    if (data.purchasedZone && data.soldBy && Number(data.exclusivity)) {
      const payload = {
        zoneId: Number(data.purchasedZone.id),
        paymentStatus: Number(data.paymentStatus) === 1 ? 'paid' : 'hold',
        price: Number(data.nrpFee),
        purchaseType: Number(data.exclusivity),
        soldBy: isSalesPerson ? Number(loggedInUser.id) : Number(data.soldBy.id),
        status: 1,
      };

      if (forDraftAmendment) {
        const obj = {
          id: Date.now(),
          purchasedZone: payload.zoneId,
          exclusivity: payload.purchaseType,
          paymentStatus: Number(data.paymentStatus),
          soldBy: payload.soldBy,
          nrpFee: payload.price,
          status: payload.status,
        };

        let isAvailable = false;
        if (data.purchasedZone.zoneToBeCanceled) {
          isAvailable = true;
        } else {
          isAvailable = checkIsAvaibleForPurchase(
            data.purchasedZone.agentWithCompletedPayment,
            Number(data.exclusivity)
          );
        }

        if (!isAvailable) {
          setSnackbarMeesage(() => ({
            ...snackbarMeesage,
            message: 'This zone cannot be purchased with given exclusivity.',
            type: 'error',
            show: true,
          }));
          return;
        }

        const localValidation = checkLocalValidationForZone(obj);

        if (localValidation.status) {
          setTempStateValues(obj);
          createDraftContractZoneItem({ ...payload, agentId })
            .then(() => {
              reset();
              setAddMode(false);
            })
            .catch(({ response }) => {
              setSnackbarMeesage({
                ...snackbarMeesage,
                message: response.data.message,
                type: 'error',
                show: true,
              });
            });
        } else {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: localValidation.message,
            type: 'error',
            show: true,
          });
        }
      } else if (!fromAddAgent) {
        assignZone(agentId, payload, zoneType)
          .then(() => {
            reset();
            setAddMode(false);
          })
          .catch(({ response }) => {
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: response.data.message,
              type: 'error',
              show: true,
            });
          });
      } else {
        validateAssignZone({
          zoneId: payload.zoneId,
          purchaseType: payload.purchaseType,
          paymentStatus: Number(data.paymentStatus),
        }, zoneType)
          .then(() => {
            const obj = {
              id: Date.now(),
              purchasedZone: payload.zoneId,
              exclusivity: payload.purchaseType,
              paymentStatus: Number(data.paymentStatus),
              soldBy: payload.soldBy,
              nrpFee: payload.price,
              status: payload.status,
            };
            const localValidation = checkLocalValidationForZone(obj);
            if (localValidation.status) {
              setTempStateValues(obj);
              reset();
              setAddMode(false);
            } else {
              setSnackbarMeesage({
                ...snackbarMeesage,
                message: localValidation.message,
                type: 'error',
                show: true,
              });
            }
          })
          .catch(({ response }) => {
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: response.data.message,
              type: 'error',
              show: true,
            });
          });
      }
    } else if (!data.purchasedZone) {
      setSnackbarMeesage({
        ...snackbarMeesage,
        message: 'Please select zone.',
        type: 'error',
        show: true,
      });
    } else if (!data.soldBy) {
      setSnackbarMeesage({
        ...snackbarMeesage,
        message: 'Please select agent.',
        type: 'error',
        show: true,
      });
    } else if (data.exclusivity === '0') {
      setSnackbarMeesage({
        ...snackbarMeesage,
        message: 'Please select exclusivity.',
        type: 'error',
        show: true,
      });
    }
  };

  const handleExistingContractCheck = async (zone) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    try {
      const res = await checkContracts(zone.id);
      if (res.success) {
        const { data } = res;
        if (data.count > 0 && !confirmDialog) {
          if (Number(data.contractStatus) === contractStatusValue.SIGNED) {
            setConfirmationMessage(
              `Zone ${zone.name} has an outstanding signed(unpaid) contract, would you like to continue?`
            );
          } else {
            setConfirmationMessage(
              `Zone ${zone.name} has an outstanding sent contract, would you like to continue?`
            );
          }
          setConfirmDialog(true);
        }
      }
    } catch (error) {
      setSnackbarMeesage({
        ...snackbarMeesage,
        message: 'Something went wrong',
        type: 'error',
        show: true,
      });
    }
  };

  const getZoneNameString = (name, purchaseStatus, agentWithCompletedPayment, zoneToBeCanceled) => {
    if (zoneToBeCanceled) {
      return `${name} (Pending Cancelation)`;
    }
    if (forDraftAmendment && purchaseStatus.toLowerCase() === 'sold') {
      if (agentWithCompletedPayment.includes(agentId)) {
        return name;
      }
      return `${name}. This zone is already sold  to another agent..`;
    }
    if (purchaseStatus.toLowerCase() === 'sold') {
      return `${name}. This zone is already sold.`;
    }
    return name;
  };

  const getZoneDisabledOrNot = (purchaseStatus, agentWithCompletedPayment, zoneToBeCanceled) => {
    if (zoneToBeCanceled && agentWithCompletedPayment.length <= 1) {
      return false;
    }

    return forDraftAmendment && purchaseStatus.toLowerCase() === 'sold'
    ? !agentWithCompletedPayment.includes(agentId)
    : purchaseStatus.toLowerCase() === 'sold'
  };

  // load options using API call
  const loadZoneOptions = (inputStateValue, callback) => {
    searchZone(inputStateValue, zoneType).then((res) => {
      callback(
        res.data.rows.map((i) => ({
          name: getZoneNameString(i.name, i.purchaseStatus, i.agentWithCompletedPayment, i.zoneToBeCanceled),
          id: i.id,
          isDisabled: getZoneDisabledOrNot(i.purchaseStatus, i.agentWithCompletedPayment, i.zoneToBeCanceled),
          agentWithCompletedPayment: i.agentWithCompletedPayment,
          zoneToBeCanceled: i.zoneToBeCanceled,
        }))
      );
    });
  };

  const loadTeamOptions = (inputStateValue, callback) => {
    searchSalesPerson(inputStateValue).then((res) => {
      callback(
        res.data.rows.map((i) => ({
          name: i.name,
          id: i.id,
        }))
      );
    });
  };

  return (
    <div>
      <Dialog
        open
        onClose={() => {
          reset();
          setAddMode(false);
        }}
      >
        <DialogTitle>Assign Zone</DialogTitle>
        <DialogContent>
          <form id="assign-zone" onSubmit={handleSubmit(submitForm)}>
            <Controller
              control={control}
              rules={Validations.REQUIRED}
              name="purchasedZone"
              id="purchasedZone"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <div className={classes2.formInput}>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={value}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.id}
                    loadOptions={loadZoneOptions}
                    onChange={async (v) => {
                      if (v.zoneToBeCanceled) {
                        const zoneToBeCanceledDetails = v.zoneToBeCanceled.split('_');
                        const cancelDateTime = convertTimestampToDate(zoneToBeCanceledDetails[1]);
                        setConfirmationMessage(
                          `This zone has pending cancelation which will end @ ${cancelDateTime}. Would you still like to proceed?`
                        );
                        setConfirmDialog(true);
                      } else {
                        await handleExistingContractCheck(v);
                      }
                      onChange(v);
                    }}
                    placeholder="Zone*"
                    styles={customStyles}
                  />
                  {error && (
                    <Typography className={classes2.formErrorText}>{error.message}</Typography>
                  )}
                </div>
              )}
            />
            {!isSalesPerson && (
              <Controller
                control={control}
                rules={Validations.REQUIRED}
                name="soldBy"
                id="soldBy"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <div className={classes2.formInput}>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      value={value}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.id}
                      loadOptions={loadTeamOptions}
                      onChange={(v) => onChange(v)}
                      placeholder="Sold by*"
                      styles={customStyles}
                    />
                    {error && (
                      <Typography className={classes2.formErrorText}>{error.message}</Typography>
                    )}
                  </div>
                )}
              />
            )}
            <Controller
              control={control}
              rules={Validations.REQUIRED}
              name="exclusivity"
              id="exclusivity"
              defaultValue={1}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  variant="outlined"
                  label="Exclusivity*"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={
                    // eslint-disable-next-line no-nested-ternary
                    error || Number(value) === 2
                      ? Number(value) === 2
                        ? error?.message ||
                          'Zones assigned semi-exclusively will not be displayed in the final contract copy, but amount will be charged for those zones.'
                        : error.message
                      : null
                  }
                  className={classes.formInput}
                  SelectProps={{
                    native: true,
                  }}
                  fullWidth
                  select
                  disabled={isSalesPerson || isSalesManager}
                >
                  {exclusitivityArr.map((opt) => (
                    <option value={Number(opt[0])}>{opt[1]}</option>
                  ))}
                </TextField>
              )}
            />
            {!isSalesPerson && !isSalesManager && (
              <Controller
                control={control}
                rules={Validations.REQUIRED}
                name="paymentStatus"
                id="paymentStatus"
                defaultValue={!agentIsEnrolled ? 1 : 2}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    variant="outlined"
                    label="Payment Status*"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={classes.formInput}
                    SelectProps={{
                      native: true,
                    }}
                    fullWidth
                    select
                    disabled={isSalesPerson || !agentIsEnrolled}
                  >
                    {statusArr.map((opt) => (
                      <option value={Number(opt[0])}>{opt[1]}</option>
                    ))}
                  </TextField>
                )}
              />
            )}
            <Controller
              control={control}
              rules={{
                ...Validations.REQUIRED,
                ...{ pattern: { value: /^[0-9.]+$/, message: 'Invalid value' } },
                ...{
                  max: {
                    value: 1000000,
                    message: 'Please enter a value less than or equal to 1000000.',
                  },
                },
              }}
              name="nrpFee"
              id="nrpFee"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  variant="outlined"
                  label="NRP Fee*"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  className={classes.formInput}
                  fullWidth
                />
              )}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" type="submit" form="assign-zone">
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              reset();
              setAddMode(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
        {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
        {confirmDialog && (
          <ConfirmDialog
            onClose={() => setConfirmDialog(false)}
            onDecline={() => {
              setValue('purchasedZone', '');
              setConfirmDialog(false);
            }}
            confirmationMessage={confirmationMessage}
          />
        )}
      </Dialog>
    </div>
  );
};

AssignZoneForm.propTypes = {
  agentId: PropTypes.number.isRequired,
  fromAddAgent: PropTypes.bool,
  setTempStateValues: PropTypes.func,
  setAddMode: PropTypes.func.isRequired,
  checkLocalValidationForZone: PropTypes.func.isRequired,
  forDraftAmendment: PropTypes.bool,
  agentIsEnrolled: PropTypes.bool,
  zoneType: PropTypes.number.isRequired,
};

AssignZoneForm.defaultProps = {
  fromAddAgent: false,
  setTempStateValues: () => true,
  forDraftAmendment: false,
  agentIsEnrolled: false,
};

const ConfirmDialog = ({ onClose, confirmationMessage, onDecline }) => (
  <Dialog open onClose={onClose}>
    <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{confirmationMessage}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="secondary" autoFocus>
        Yes
      </Button>
      <Button onClick={onDecline} color="primary">
        No
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  confirmationMessage: PropTypes.string.isRequired,
  onDecline: PropTypes.func.isRequired,
};

export default AssignZoneForm;
