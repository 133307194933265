import RoutePaths from '../config/Routes';
import { agentTypeValues, zoneTypeValues } from '../config/DataValues';

export const getAgentType = () => {
  const location = window.location.href;
  if (location.indexOf(RoutePaths.AGENTS) > -1) {
    return agentTypeValues.AGENTS;
  }
  if (location.indexOf(RoutePaths.LENDERS) > -1) {
    return agentTypeValues.LENDERS;
  }
  if (location.indexOf(RoutePaths.CE_USERS) > -1) {
    return agentTypeValues.CE_USERS;
  }
  return agentTypeValues.AGENTS;
};

export const getAgentTypeLabel = (rowType, plural = false, wordCap = false) => {
  let label = wordCap ? 'Agent' : 'agent';
  if (Number(rowType) === agentTypeValues.LENDERS) {
    label = wordCap ? 'Lender' : 'lender';
  }
  if (Number(rowType) === agentTypeValues.CE_USERS) {
    label = wordCap ? 'Content Engine User' : 'content engine user';
  }

  if (plural) {
    label += 's';
  }

  return label;
};

export const getZoneType = () => {
  const location = window.location.href;
  if (location.indexOf(RoutePaths.ZONES) > -1) {
    return zoneTypeValues.AGENTS;
  }
  if (location.indexOf(RoutePaths.LENDER_ZONES) > -1) {
    return zoneTypeValues.LENDERS;
  }
  return zoneTypeValues.AGENTS;
};

export const getWidgetType = () => {
  const location = window.location.href;
  if (location.indexOf(RoutePaths.WIDGET_LENDER_ZIPCODE_SEARCH) > -1) {
    return zoneTypeValues.LENDERS;
  }
  if (location.indexOf(RoutePaths.WIDGET_LENDER_ZIPCODE_SEARCH_V2) > -1) {
    return zoneTypeValues.LENDERS;
  }
  return zoneTypeValues.AGENTS;
};
