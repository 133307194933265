import { green, yellow, red } from '@material-ui/core/colors';

// Style for agent pages
const Style = () => ({
  statusActive: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: green[600],
    color: 'white',
    fontSize: 12,
  },
  statusPending: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: yellow[500],
    color: 'black',
    fontSize: 12,
  },
  statusInactive: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: red[500],
    color: 'white',
    fontSize: 12,
  },
  dateText: {
    fontSize: 12,
  },
  playIconContainer: {
    backgroundColor: '#000',
    minHeight: 200,
    maxHeight: 250,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  cardContent: {
    padding: '20px !important',
  },
  otherDetails: {
    marginTop: 12,
  },
  rowDetail: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconBtn: {
    padding: '0 !important',
    marginLeft: 8,
  },
  card: {
    minHeight: '100%',
  },
  negcard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  pendingNuggetIndicator: {
    background: yellow[500],
    color: 'black',
    padding: 0,
    minWidth: 30,
    '&:hover': {
      background: yellow[500],
      color: 'black',
      padding: 0,
      minWidth: 30,
    },
  },
  checkedNuggetIndicator: {
    background: green[600],
    color: 'white',
    padding: 0,
    minWidth: 30,
    '&:hover': {
      background: green[500],
      color: 'white',
      padding: 0,
      minWidth: 30,
    },
  },
  noNuggetIndicator: {
    padding: 0,
    minWidth: 30,
    '&:hover': {
      padding: 0,
      minWidth: 30,
    },
  },
  playIcon: {
    fontSize: '3rem',
    position: 'absolute',
    color: '#FFF',
  },
  cardItemCount: {
    zIndex: 2,
    background: '#FFF',
    borderRadius: '50%',
    width: 22,
    height: 22,
    textAlign: 'center',
  },
  cardItemMenu: {
    zIndex: 2,
    width: 30,
    height: 30,
    alignSelf: 'flex-end',
  },
});

export default Style;
