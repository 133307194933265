import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import SnackbarMessage from '../../components/SnackbarMessage';
import Validations from '../../utils/Validations';
import { isDefaultApp } from '../../utils/AppDomain';
import PublicWrapper from '../../layout/Public';
import logo from '../../assets/images/logo.png';
import cLogo from '../../assets/images/c-logo.png';
import RoutePaths from '../../config/Routes';
import loginStyle from '../../theme/styles/Login';
import { signInUser } from '../../services/Auth';

const useStyles = makeStyles(loginStyle);

// Login page
const Login = () => {
  const classes = useStyles();

  const [showAgentInactive, setShowAgentInactive] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    signInUser(data)
      .then(() => {
        setIsSubmitted(true);
        window.localStorage.setItem('isLoggedIn', true);
        window.location.assign(RoutePaths.HOME);
      })
      .catch(({ response }) => {
        if (response.status === 403) {
          setShowAgentInactive(true);
        } else {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.data.message,
            type: 'error',
            show: true,
          });
        }
      });
  };

  const handleBackToLogin = () => {
    setShowAgentInactive(false);
  };

  return (
    <PublicWrapper>
      <Grid container spacing={0} align="center" justify="center" direction="column">
        <Container maxWidth="xs">
          <img
            src={isDefaultApp() ? logo : cLogo}
            alt={process.env.REACT_APP_NAME}
            className={classes.logo}
          />

          {!showAgentInactive && (
            <>
              <form onSubmit={handleSubmit(onSubmit)} className={classes.authForm}>
                <FormControl className={classes.authInput}>
                  <Controller
                    control={control}
                    rules={Validations.REQUIRED}
                    name="userName"
                    id="userName"
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label="Email Address*"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        autoFocus
                      />
                    )}
                  />
                </FormControl>
                <FormControl className={classes.authInput}>
                  <Controller
                    control={control}
                    className={classes.authInput}
                    rules={Validations.REQUIRED}
                    name="password"
                    id="password"
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label="Password*"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        type="password"
                      />
                    )}
                  />
                </FormControl>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.authSubmitBtn}
                  disabled={isSubmitted}
                >
                  Login
                </Button>
              </form>
              <Link className={classes.greenLinkText} to={RoutePaths.FORGOT_PASSWORD}>
                Forgot your Password?
              </Link>
            </>
          )}

          {showAgentInactive && (
            <>
              <Typography component="p" variant="body1" style={{ marginBottom: 30 }}>
                We&apos;re sorry, your account is no longer active. If you feel this was a mistake,
                please contact{' '}
                <a href="mailto:nrpsupport@negotiators.tv">nrpsupport@negotiators.tv</a> for further
                assistance.
              </Typography>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.authSubmitBtn}
                onClick={handleBackToLogin}
              >
                Back To Login
              </Button>
            </>
          )}
        </Container>

        {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      </Grid>
    </PublicWrapper>
  );
};

export default Login;
