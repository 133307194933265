import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import PublicWrapper from '../../layout/Public';
import RoutePaths from '../../config/Routes';
import loginStyle from '../../theme/styles/Login';
import { signOutUser } from '../../services/Auth';

const useStyles = makeStyles(loginStyle);

// Logout page
const Logout = () => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    signOutUser().then(() => {
      window.localStorage.removeItem('isLoggedIn');
      window.localStorage.removeItem('userDetail');
      window.localStorage.removeItem('isTeamMemberLoggedIn');
      // hide chat widget
      window.FreshworksWidget('hide');
      window.localStorage.removeItem('isAccessorLoggedIn');
      setTimeout(() => {
        history.push(RoutePaths.LOGIN);
      }, 1000);
    });
  }, []);

  return (
    <PublicWrapper>
      <Grid container spacing={0} align="center" justify="center" direction="column">
        <Container maxWidth="xs" className={classes.loadingContainer}>
          <CircularProgress color="inherit" />

          <Typography component="p" className={classes.loadingText}>
            Logging Out...
          </Typography>
        </Container>
      </Grid>
    </PublicWrapper>
  );
};

export default Logout;
