/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Tooltip,
  Paper,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import VideoPlayer from 'react-video-js-player';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import AddIcon from '@material-ui/icons/Add';
import { Refresh, MoreVert } from '@material-ui/icons';
import nuggetsStyle from '../../theme/styles/Nuggets';
import AddNugget from './AddNugget';
import NuggetActionConfirmDialog from './NuggetActionConfirmDialog';
import { getAgentNuggets } from '../../services/Nuggets';
import { nuggetStatus, nuggetAssetStatus, agentTypeValues } from '../../config/DataValues';
import { trackUserClick } from '../../utils/Activity';

const useStyles = makeStyles(nuggetsStyle);
const TooltipStyle = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgb(97 97 97 / 98%)',
    fontSize: '.8rem',
  },
}))(Tooltip);

const AgentNuggets = ({ module }) => {
  const classes = useStyles();
  const getDetails = window.localStorage.getItem('userDetail');
  const userId = getDetails ? Number(JSON.parse(getDetails).id) : 0;

  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [reloadRows, setReloadRows] = useState(false);

  const [viewVideoUrl, setViewVideoUrl] = useState('');
  const [viewVideoStatus, setViewVideoStatus] = useState('');

  const [openModel, setOpenModel] = useState(false);
  const [confirmModel, setConfirmModel] = useState(false);

  const [selectedNugget, setSelectedNugget] = useState(null);

  const deleteData = (id) => {
    setSelectedNugget(id);
    setConfirmModel(true);
  };

  useEffect(() => {
    setDataLoaded(false);
    getAgentNuggets(userId).then((res) => {
      setRows(res.data);
      setDataLoaded(true);
    });
  }, [reloadRows]);

  const refreshItems = () => {
    setReloadRows(!reloadRows);
  };

  const downloadVideo = (vidUrl) => {
    const fileName = Math.floor(Date.now() / 1000);
    const mp4Url = vidUrl.replace('.m3u8', `/high.mp4?download=${fileName}`);
    if (module === agentTypeValues.AGENTS) {
      trackUserClick(53, { video_url: mp4Url });
    } else {
      trackUserClick(82, { video_url: mp4Url });
    }

    window.open(mp4Url, '_blank');
  };

  const handleActions = (row, action) => {
    if (action === 'delete') {
      deleteData(row.id);
    } else if (action === 'download') {
      downloadVideo(row.videoUrl);
    }
  };

  const getToolTipTitle = (row) => {
    let title = '';
    if (row.status === nuggetStatus.PENDING) {
      if (Number(row.assetStatus) === nuggetAssetStatus.WAITING) {
        title = 'The video will soon be edited with required details.';
      } else if (Number(row.assetStatus) === nuggetAssetStatus.PREPARING) {
        title = 'The video editing is in progress. It takes a few minutes to complete the process.';
      } else if (Number(row.assetStatus) === nuggetAssetStatus.READY) {
        title = 'The video is ready for review. The support team will start reviewing it soon.';
      } else if (Number(row.assetStatus) === nuggetAssetStatus.FAILED) {
        title = 'The video editing failed. Please delete the video and re-upload it.';
      }
    } else if (row.status === nuggetStatus.APPROVED) {
      if (Number(row.assetStatus) === nuggetAssetStatus.MUX_WAITING) {
        title = 'The video is being finalised.';
      } else if (Number(row.assetStatus) === nuggetAssetStatus.MUX_UPLOADING) {
        title = 'The video is being finalised.';
      } else if (Number(row.assetStatus) === nuggetAssetStatus.MUX_FAIL) {
        title = 'Something went wrong finalising the video. Please contact support team.';
      }
    } else if (row.status === nuggetStatus.DISAPPROVED) {
      title = row.comments;
    }
    return title;
  };
  return (
    <>
      {!dataLoaded && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {dataLoaded && (
        <Box display="flex" justifyContent="flex-end">
          <Tooltip title="Refresh">
            <IconButton onClick={refreshItems} size="small">
              <Refresh />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {dataLoaded && rows.length > 0 && (
        <Grid
          component={Box}
          display="flex"
          justifyContent="flex-start"
          flexDirection="row"
          flexWrap="wrap"
        >
          {rows.map((row, i) => (
            <Grid component={Box} width={250} mr={2} mb={2}>
              <Card className={`${classes.card} ${classes.negcard}`}>
                <Box component={Paper} m={2} position="absolute" className={classes.cardItemCount}>
                  {i + 1}
                </Box>
                <ActionMenu id={row.id} selectAction={handleActions} row={row} />

                <Box
                  className={classes.playIconContainer}
                  onClick={() => {
                    setViewVideoUrl(row.videoUrl);
                    setViewVideoStatus(row.status);
                  }}
                >
                  <VideoPlayer
                    src={row.videoUrl}
                    width="250"
                    controls={false}
                    preload="metadata"
                    hideControls={[
                      'play',
                      'volume',
                      'seekbar',
                      'timer',
                      'playbackrates',
                      'fullscreen',
                    ]}
                    onReady={(p) => {
                      p.currentTime(4);
                      p.pause();
                    }}
                  />
                  <PlayArrowIcon className={classes.playIcon} />
                </Box>
                <CardContent className={classes.cardContent}>
                  <TooltipStyle title={getToolTipTitle(row)}>
                    <Box display="flex" justifyContent="center" style={{ cursor: 'pointer' }}>
                      {row.status === nuggetStatus.PENDING && (
                        <span color="warning" className={classes.statusPending}>
                          {Number(row.assetStatus) === nuggetAssetStatus.WAITING ? 'Waiting' : ''}
                          {Number(row.assetStatus) === nuggetAssetStatus.PREPARING
                            ? 'Processing'
                            : ''}
                          {Number(row.assetStatus) === nuggetAssetStatus.READY ? 'Pending' : ''}
                          {Number(row.assetStatus) === nuggetAssetStatus.FAILED ? 'Failed' : ''}
                        </span>
                      )}
                      {row.status === nuggetStatus.APPROVED && (
                        <span
                          className={
                            Number(row.assetStatus) === nuggetAssetStatus.MUX_FAIL
                              ? classes.statusInactive
                              : Number(row.assetStatus) === nuggetAssetStatus.MUX_READY
                              ? classes.statusActive
                              : classes.statusPending
                          }
                        >
                          {Number(row.assetStatus) === nuggetAssetStatus.MUX_WAITING
                            ? 'Finalising'
                            : ''}
                          {Number(row.assetStatus) === nuggetAssetStatus.MUX_UPLOADING
                            ? 'Finalising'
                            : ''}
                          {Number(row.assetStatus) === nuggetAssetStatus.MUX_FAIL ? 'Failed' : ''}
                          {Number(row.assetStatus) === nuggetAssetStatus.MUX_READY
                            ? 'Approved'
                            : ''}
                        </span>
                      )}
                      {row.status === nuggetStatus.DISAPPROVED && (
                        <span className={classes.statusInactive}>Disapproved</span>
                      )}
                    </Box>
                  </TooltipStyle>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    className={classes.otherDetails}
                  >
                    <div className={classes.rowDetail}>
                      <span className={classes.dateText}>
                        {row.created ? `Added at ${row.created} ` : ''}
                      </span>
                    </div>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
          {rows.length < 4 && (
            <Grid component={Box} width={250} height={315} mr={2} mb={2}>
              <Card
                component={Button}
                style={{ height: '100%', width: '100%', color: '#43a047' }}
                borderRadius={10}
                onClick={() => {
                  setOpenModel(true);
                  if (module === agentTypeValues.AGENTS) {
                    trackUserClick(30);
                  } else {
                    trackUserClick(61);
                  }
                }}
              >
                {module === agentTypeValues.AGENTS ? 'Add new nugget' : 'Add new minute'}
              </Card>
            </Grid>
          )}
        </Grid>
      )}
      {dataLoaded && rows.length === 0 && (
        <Box
          p={1}
          textAlign="center"
          borderRadius={10}
          my={18}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="p" align="center">
            You haven&apos;t uploaded any{' '}
            {module === agentTypeValues.AGENTS ? 'nuggets' : 'minutes'} yet.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenModel(true);
              if (module === agentTypeValues.AGENTS) {
                trackUserClick(30);
              } else {
                trackUserClick(61);
              }
            }}
            startIcon={<AddIcon />}
            style={{ margin: '20px 0', width: 'max-content' }}
          >
            Add New Video
          </Button>
        </Box>
      )}

      {openModel && (
        <AddNugget
          open={openModel}
          onClose={() => {
            setOpenModel(false);
          }}
          onSuccess={() => {
            setOpenModel(false);
            setReloadRows(!reloadRows);
          }}
          module={module}
        />
      )}
      {confirmModel && (
        <NuggetActionConfirmDialog
          open={confirmModel}
          action="delete"
          nuggetId={selectedNugget}
          handleClose={() => {
            setSelectedNugget(null);
            setConfirmModel(false);
          }}
          onSuccess={() => {
            setConfirmModel(false);
            setSelectedNugget(null);
            setReloadRows(!reloadRows);
          }}
        />
      )}

      {viewVideoUrl && (
        <ViewNuggets
          videoSrc={viewVideoUrl}
          videoStatus={viewVideoStatus}
          handleClose={() => {
            setViewVideoUrl(null);
          }}
        />
      )}
    </>
  );
};

const ViewNuggets = ({ handleClose, videoSrc, videoStatus }) => {
  const downloadVideo = (vidUrl) => {
    const fileName = Math.floor(Date.now() / 1000);
    const mp4Url = vidUrl.replace('.m3u8', `/high.mp4?download=${fileName}`);
    trackUserClick(53, { video_url: mp4Url });
    window.open(mp4Url, '_blank');
  };

  return (
    <Dialog open onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        disableTypography
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <h2>Video Preview</h2>
        {videoStatus === nuggetStatus.APPROVED && (
          <Button color="primary" onClick={() => downloadVideo(videoSrc)}>
            Download
          </Button>
        )}
      </DialogTitle>
      <DialogContent>
        <Box justifyContent="center" display="flex">
          <VideoPlayer controls src={videoSrc || ''} width="800" />
        </Box>
        <Box justifyContent="center" display="flex">
          <div style={{ marginTop: 15, marginBottom: 15 }}>
            Note: Some video preview may not work in browser, however once the video is converted
            then preview will work fine.
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ViewNuggets.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  videoStatus: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const ActionMenu = ({ row, selectAction }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (action) => {
    selectAction(row, action);
    handleClose();
  };
  return (
    <>
      <Box component={Paper} m={2} position="absolute" className={classes.cardItemMenu}>
        <IconButton
          aria-controls={`simple-menu${row.id}`}
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          color="primary"
        >
          <MoreVert />
        </IconButton>
      </Box>

      <Menu
        id={`simple-menu${row.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {row.status === nuggetStatus.APPROVED && (
          <MenuItem
            id={`simple-menu${row.id}1`}
            onClick={() => {
              handleMenuClick('download');
            }}
            button
            dense
          >
            Download
          </MenuItem>
        )}
        {(row.status === nuggetStatus.PENDING ||
          row.status === nuggetStatus.DISAPPROVED ||
          row.status === nuggetStatus.APPROVED) && (
          <MenuItem
            id={`simple-menu${row.id}2`}
            onClick={() => {
              handleMenuClick('delete');
            }}
            button
            dense
          >
            Delete
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
ActionMenu.propTypes = {
  row: PropTypes.objectOf().isRequired,
  selectAction: PropTypes.func.isRequired,
};

AgentNuggets.propTypes = {
  module: PropTypes.number.isRequired,
};

export default AgentNuggets;
