import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import { Box, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import VideoPlayer from 'react-video-js-player';

const ViewVideo = ({ handleClose, url }) => {
  const videoPreview = {
    src: url,
    poster: '',
  };

  return (
    <Dialog open onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Video Preview</DialogTitle>
      <DialogContent>
        <Box justifyContent="center" display="flex">
          <VideoPlayer
            controls
            src={videoPreview.src || ''}
            poster={videoPreview.poster || ''}
            width="800"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ViewVideo.propTypes = {
  handleClose: PropTypes.bool.isRequired,
  url: PropTypes.func.isRequired,
};

export default ViewVideo;
