/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Pagination, Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import JsFileDownloader from 'js-file-downloader';
import { getAgentSubmissions } from '../../../services/ShowSegments';
import listingStyle from '../../../theme/styles/TableListing';
import SnackbarMessage from '../../SnackbarMessage';
import { getAgentTypeLabel } from '../../../utils/GetModuleType';

const useStyles = makeStyles(listingStyle);

const Submissions = ({ id, onClose }) => {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reloadRows, setReloadRows] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const columns = [
    { name: 'Type', id: 'type', width: '15%' },
    { name: 'Name', id: 'name', width: '35%' },
    { name: 'Email', id: 'email', width: '35%' },
  ];

  useEffect(() => {
    getAgentSubmissions(id, activePage)
      .then((res) => {
        setRows(res.data.rows);
        setTotalPages(res.data.totalPages);
        setDataLoaded(true);
      })
      .catch(() => {
        setDataLoaded(true);
      });
  }, [reloadRows, activePage]);

  const downloadVideo = (rowData) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });

    // process for download video
    const fileExtension = rowData.videoUrl.split('.').pop();

    // eslint-disable-next-line no-unused-vars
    const download = new JsFileDownloader({
      url: rowData.videoUrl,
      timeout: 30 * 60 * 1000,
      nameCallback() {
        return `${rowData.submissionTitle}_${rowData.agentId}_${rowData.email}.${fileExtension}`;
      },
    });
    setTimeout(() => {
      setSnackbarMeesage({
        ...snackbarMeesage,
        message: 'Video is downloading, please do not close the browser or refresh the page.',
        type: 'success',
        show: true,
      });
    }, 500);
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Submissions</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((col) => (
                  <TableCell width={col.width} align={col.align || ''}>
                    {col.name}
                  </TableCell>
                ))}
                <TableCell align="right" width="10%">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!dataLoaded &&
                [1, 2, 3, 4, 5].map((val) => (
                  <TableRow key={`zpc-${val}`}>
                    <TableCell component="th" scope="row">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                  </TableRow>
                ))}
              {dataLoaded &&
                (rows.length > 0 ? (
                  rows.map((row) => (
                    <TableRow key={`sub-${row.id}`}>
                      <TableCell>{getAgentTypeLabel(row.agentType, false, true)}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell align="right">
                        <Link
                          color="primary"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            downloadVideo(row);
                          }}
                        >
                          Download
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      size="medium"
                      colSpan={4}
                      className={classes.noRecord}
                      style={{ padding: 20, fontSize: 15 }}
                    >
                      No records found
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {dataLoaded && totalPages > 1 && (
          <Pagination
            count={totalPages}
            showFirstButton
            showLastButton
            className={classes.tablePagination}
            onChange={(_, pageNumber) => {
              setActivePage(pageNumber);
              setReloadRows(!reloadRows);
            }}
            page={activePage}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button color="default" variant="contained" onClick={onClose} disableElevation>
          Close
        </Button>
      </DialogActions>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

Submissions.propTypes = {
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Submissions;
