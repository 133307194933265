import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';

// Style for sidebar navigations
const Style = (theme) => ({
  menuItemContainer: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuDrawer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  menuItemLogout: {
    backgroundColor: 'white',
    borderLeft: '5px solid white',
    position: 'absolute',
    bottom: '60px',
    width: '100%',
    '&:hover': {
      backgroundColor: green[50],
      borderLeft: '5px solid #e8f5e9',
    },
    '&.Mui-selected': {
      backgroundColor: green[50],
      borderLeft: '5px solid #4caf50',
      '&:hover': {
        backgroundColor: green[50],
        borderLeft: '5px solid #4caf50',
      },
    },
  },
  menuItem: {
    backgroundColor: 'white',
    borderLeft: '5px solid white',
    '&:hover': {
      backgroundColor: green[50],
      borderLeft: '5px solid #e8f5e9',
    },
    '&.Mui-selected': {
      backgroundColor: green[50],
      borderLeft: '5px solid #4caf50',
      '&:hover': {
        backgroundColor: green[50],
        borderLeft: '5px solid #4caf50',
      },
    },
  },
  menuIcon: {
    minWidth: 0,
    width: 30,
    color: grey[700],
    '& .MuiSvgIcon-root': {
      fontSize: '1.35rem',
    },
  },
  menuText: {
    marginLeft: 15,
    color: grey[700],
    '& .MuiTypography-root': {
      fontSize: '0.9rem',
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

export default Style;
