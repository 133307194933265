import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Table, TableCell, TableRow, Typography } from '@material-ui/core';
import { Room } from '@material-ui/icons';
import contractStyle from '../../theme/styles/Contract';
import { paymentFrequencies } from '../../config/DataValues';
import ContractZoneTable from './ContractZoneTable';

const useStyles = makeStyles(contractStyle);

const ViewContract = ({
  setContractZoneData,
  contractZoneData,
  zoneArr,
  updateList,
  setUpdateList,
  agentDetails,
  setContractValues,
  contractValues,
  contractStatus,
  contractNotes,
}) => {
  const classes = useStyles();

  const dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  useEffect(() => {
    setUpdateList(!updateList);
    if (contractStatus === null) {
      let subT = 0;
      contractZoneData.forEach((z) => {
        subT += z.nrpFee;
      });
      const values = {
        ...contractValues,
        subTotal: subT,
        discount: contractValues.discount,
        total: subT - contractValues.discount,
      };
      setContractValues(values);
    }
  }, [contractZoneData]);

  return (
    <div>
      <Box display="flex" flexDirection="row">
        <div className={classes.info}>
          <div className={classes.infoRow}>
            <div
              className={`${classes.infoRowAgentName} ${classes.infoRowCell}`}
            >{`${agentDetails.firstName} ${agentDetails.lastName}`}</div>
            <div className={`${classes.infoRowCell} ${classes.infoRowLocation}`}>
              {agentDetails.location && <Room />}
              <span>{agentDetails.location}</span>
            </div>
          </div>
          <div className={classes.infoRow}>
            <div className={classes.infoRowCell}>{agentDetails.email}</div>
            <div className={classes.infoRowCell}>{agentDetails.phone}</div>
          </div>
        </div>
        {contractStatus && (
          <div className={classes.contractStatus}>
            <div>
              <Typography>Status</Typography>
              <Typography variant="h4" color="primary">
                {contractStatus.toUpperCase()}
              </Typography>
            </div>
          </div>
        )}
      </Box>
      <ContractZoneTable
        setTempAssignedZoneData={setContractZoneData}
        tempAssignedZoneData={contractZoneData}
        updateList={updateList}
        zoneArr={zoneArr}
        quantity={paymentFrequencies[contractValues.subType].multiply}
        allowDelete={false}
      />
      <Table>
        <TableRow>
          <TableCell width="40%">Subscription</TableCell>
          <TableCell width="20%">{paymentFrequencies[contractValues.subType].label}</TableCell>

          <TableCell width="15%">Sub Total</TableCell>
          <TableCell align="right">{dollarUS.format(contractValues.subTotal)}</TableCell>
          <TableCell width="7%" />
        </TableRow>
        <TableRow>
          <TableCell>
            Subscription Start Date
            <br />
            <Typography color="textSecondary" variant="p">
              This is subscription start date however billing cycle will be varied depending upon
              first payment date.
            </Typography>
          </TableCell>
          <TableCell>{contractValues.subStartDate || '-'}</TableCell>

          <TableCell>Discount</TableCell>
          <TableCell align="right">{dollarUS.format(contractValues.discount)}</TableCell>
          <TableCell />
        </TableRow>

        <TableRow>
          <TableCell>Contract Expiry Date</TableCell>
          <TableCell>{contractValues.contractExpiryDate || '-'}</TableCell>
          <TableCell>Total</TableCell>
          <TableCell align="right">{dollarUS.format(contractValues.total)}</TableCell>
          <TableCell />
        </TableRow>
      </Table>
      {contractNotes && (
        <Box m={2}>
          Monthly Contract Notes:
          <Typography color="textSecondary" variant="p" className={classes.contractNotes}>
            {contractNotes}
          </Typography>
        </Box>
      )}
    </div>
  );
};

ViewContract.propTypes = {
  setContractZoneData: PropTypes.func,
  contractZoneData: PropTypes.arrayOf(),

  zoneArr: PropTypes.arrayOf().isRequired,
  updateList: PropTypes.bool.isRequired,
  setUpdateList: PropTypes.func.isRequired,
  setContractValues: PropTypes.func.isRequired,
  agentDetails: PropTypes.objectOf().isRequired,
  contractValues: PropTypes.objectOf().isRequired,
  contractStatus: PropTypes.string,
  contractNotes: PropTypes.string,
};
ViewContract.defaultProps = {
  setContractZoneData: () => true,
  contractZoneData: [],
  contractStatus: null,
  contractNotes: '',
};

export default ViewContract;
