import { getApiCall, postApiCall, deleteApiCall } from '../utils/Api';

export const addNotification = async (data) => {
  const result = await postApiCall(`/notification/create`, data);
  return result.data;
};

export const updateNotification = async (id, data) => {
  const result = await postApiCall(`/notification/${id}/update`, data);
  return result.data;
};

export const listNotification = async (page, params) => {
  const result = await getApiCall(`/notification/list?page=${page}&${params}`);
  return result.data;
};

export const viewNotificationById = async (id) => {
  const result = await getApiCall(`/notification/${id}/view`);
  return result.data;
};

export const removeNotification = async (id) => {
  const result = await deleteApiCall(`/notification/${id}/delete`);
  return result.data;
};
