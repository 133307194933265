import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, Container } from '@material-ui/core';
import VideoPlayer from 'react-video-js-player';
import NuggetActionConfirmDialog from './NuggetActionConfirmDialog';
import { nuggetAssetStatus, nuggetStatus } from '../../config/DataValues';
import { getNuggetById } from '../../services/Nuggets';

const NuggetPreview = ({ nuggetId, agentStatus }) => {
  const [viewData, setViewData] = useState(null);
  const [showNuggetActionConfirmDialog, setShowNuggetActionConfirmDialog] = useState(false);
  const [action, setAction] = useState(null);

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [reloadData, setReloadData] = useState(false);

  const openApprovalDialog = () => {
    setShowNuggetActionConfirmDialog(true);
    setAction('approve');
  };

  const openRejectionDialog = () => {
    setShowNuggetActionConfirmDialog(true);
    setAction('reject');
  };

  useEffect(() => {
    getNuggetById(nuggetId).then((res) => {
      setViewData(res.data);
      setIsDataLoaded(true);
    });
  }, [reloadData, nuggetId]);

  return (
    <Container>
      {!isDataLoaded ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box justifyContent="center" alignItems="center" display="flex" flexDirection="column">
          <VideoPlayer controls src={viewData?.videoUrl} width="800" />
          <div style={{ marginTop: 15, marginBottom: 15 }}>
            Note: Some video preview may not work in browser, however once the video is converted
            then preview will work fine.
          </div>
          {viewData.status === nuggetStatus.PENDING &&
            Number(viewData.assetStatus) === nuggetAssetStatus.READY &&
            agentStatus === 1 && (
              <Box display="flex" justifyContent="space-between" mt={2}>
                <Button
                  size="small"
                  onClick={openApprovalDialog}
                  variant="outlined"
                  disableElevation
                  color="primary"
                  fullWidth
                  style={{ marginRight: 10 }}
                >
                  Approve
                </Button>
                <Button
                  size="small"
                  onClick={openRejectionDialog}
                  variant="outlined"
                  disableElevation
                  color="secondary"
                  fullWidth
                  style={{ marginRight: 10 }}
                >
                  Reject
                </Button>
              </Box>
            )}
          {showNuggetActionConfirmDialog && (
            <NuggetActionConfirmDialog
              nuggetId={nuggetId}
              action={action}
              onSuccess={() => {
                setShowNuggetActionConfirmDialog(false);
                setAction(null);
                setReloadData(!reloadData);
              }}
              handleClose={() => {
                setShowNuggetActionConfirmDialog(false);
                setAction(null);
              }}
            />
          )}
        </Box>
      )}
    </Container>
  );
};

NuggetPreview.propTypes = {
  nuggetId: PropTypes.number.isRequired,
  agentStatus: PropTypes.number.isRequired,
};

export default NuggetPreview;
