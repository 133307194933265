// Style for zone page
const Style = () => ({
  viewTable: {
    '& .MuiTableCell-root': {
      borderBottom: '0px',
    },
  },
  viewLabel: {
    fontWeight: 'bold',
    verticalAlign: 'baseline',
  },
  codeChip: {
    marginRight: 5,
    marginBottom: 5,
  },
  detailsCell: {
    wordBreak: 'break-all',
  },
});

export default Style;
