import { Box, Button, CircularProgress, makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { updateMyProfile, viewMyDetails } from '../../services/Auth';
import formInput from '../../theme/styles/FormInput';
import Validations, { MaxLengthValidationFunc } from '../../utils/Validations';
import ProfileUploader from '../ProfileUploader';
import SnackbarMessage from '../SnackbarMessage';
import WYSIWYGEditor from '../WYSIWYG';

const useStyles = makeStyles(formInput);

const UpdateProfileForm = () => {
  const classes = useStyles();

  const [processing, setProcessing] = useState(false);
  const [userProfile, setUserProfile] = useState('');
  const [uploading, setUploading] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [refreshPageData, setRefreshPageData] = useState(1);

  const { handleSubmit, control, setValue, getValues } = useForm();

  useEffect(() => {
    viewMyDetails().then((res) => {
      const { data } = res;
      setValue('bio', data.bio);
      setValue('phone', data.phone);
      setUserProfile(res.data.profilePic);
    });
  }, [refreshPageData]);

  const submitForm = (data) => {
    setProcessing(true);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    updateMyProfile({ ...data, profilePic: userProfile })
      .then(() => {
        setProcessing(false);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'Your profile has been updated successfully.',
          type: 'success',
          show: true,
        });
        setRefreshPageData(refreshPageData + 1);
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
        setProcessing(false);
      });
  };

  const defaultFormValues = getValues();

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Box m={2}>
        <ProfileUploader
          profilePic={userProfile}
          onSuccess={(url) => {
            setUserProfile(url);
          }}
          setUploading={setUploading}
          updateHeaderImage
        />
      </Box>
      <Controller
        control={control}
        name="bio"
        id="bio"
        defaultValue={defaultFormValues.bio || ''}
        render={({ field: { value, onChange } }) => (
          <WYSIWYGEditor value={value} onChange={onChange} />
        )}
      />
      <Controller
        control={control}
        rules={{
          ...Validations.REQUIRED,
          ...MaxLengthValidationFunc(20),
          ...{ pattern: { value: /^[0-9 )(+-]+$/, message: 'Invalid value' } },
        }}
        name="phone"
        id="phone"
        defaultValue={defaultFormValues.phone || ''}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            variant="outlined"
            label="Phone Number*"
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
            className={classes.formInput}
            fullWidth
          />
        )}
      />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disableElevation
        endIcon={processing && <CircularProgress size={25} />}
        disabled={processing || uploading}
      >
        Update
      </Button>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </form>
  );
};

export default UpdateProfileForm;
