/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Link,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TableContainer,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import agentStyle from '../../theme/styles/Agents';
import listingStyle from '../../theme/styles/TableListing';
import { viewAgents } from '../../services/Team';

const useStyles = makeStyles(agentStyle);
const useStyles2 = makeStyles(listingStyle);

const AgentCell = ({ text, agentId, title, agentType }) => {
  const classes = useStyles();
  const classes2 = useStyles2();

  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  const columns = [
    { name: 'Name', id: 'name', width: '15%' },
    { name: 'Email', id: 'email', width: '15%' },
    { name: 'State', id: 'state', width: '10%' },
    { name: 'Status', id: 'status', width: '7%' },
    { name: 'Created', id: 'created', width: '17%' },
  ];

  const handleAgent = () => {
    if (text > 0) {
      setDataLoaded(false);
      setOpen(true);
      viewAgents(agentId, agentType)
        .then((res) => {
          setRows(res.data.rows);
          setDataLoaded(true);
        })
        .catch(() => {
          setDataLoaded(true);
        });
    }
  };

  return (
    <div>
      {text > 0 ? (
        <Link
          onClick={(e) => {
            e.preventDefault();
            handleAgent();
          }}
          href="#"
          className={classes2.linkText}
        >
          {text}
        </Link>
      ) : (
        <Typography variant="h6">-</Typography>
      )}
      <Dialog open={open} fullWidth maxWidth="md" onClose={() => setOpen(false)}>
        <DialogTitle>
          <div className={classes.titleContainer}>
            <Typography variant="h6">{title}</Typography>
            <IconButton size="small" onClick={() => setOpen(false)}>
              <Close />{' '}
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table className={classes.tableData} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((col) => (
                    <TableCell width={col.width}>{col.name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataLoaded &&
                  (rows.length > 0 ? (
                    rows.map((row) => (
                      <TableRow key={`ag-${row.id}`}>
                        <TableCell component="th" scope="row">
                          {`${row.firstName} ${row.lastName}`}
                        </TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.state}</TableCell>
                        <TableCell>
                          <span
                            className={
                              row.status === 'Active'
                                ? classes.statusActive
                                : classes.statusInactive
                            }
                          >
                            {row.status}
                          </span>
                        </TableCell>
                        <TableCell>{row.created}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        align="center"
                        size="medium"
                        colSpan={8}
                        className={classes.noRecord}
                        style={{ padding: 20, fontSize: 15 }}
                      >
                        No records found
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};

AgentCell.propTypes = {
  text: PropTypes.number.isRequired,
  agentId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  agentType: PropTypes.number.isRequired,
};

export default AgentCell;
