/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import {
  Box,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import Skeleton from '@material-ui/lab/Skeleton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import PlayArrow from '@material-ui/icons/PlayArrow';
import { Check, Close, CloudUpload } from '@material-ui/icons';
import ReactS3Uploader from 'react-s3-uploader';
import listingStyle from '../../theme/styles/TableListing';
import toolbarStyle from '../../theme/styles/TableToolbar';
import PreviewAdVideo from './PreviewAdVideo';
import SnackbarMessage from '../SnackbarMessage';
import ActionConfirmDialog from './ActionConfirmDialog';
import {
  getAgentAdVideos,
  createAgentAdVideo,
  updateAgentAdVideoUrl,
} from '../../services/AgentAdVideos';
import { viewAgentById } from '../../services/Agent';
import {
  agentAdVideoStatus,
  agentAdVideoAssetStatus,
  agentAdVideoType,
} from '../../config/DataValues';
import { getAgentTypeLabel } from '../../utils/GetModuleType';

const useStyles = makeStyles(listingStyle);
const useStyles2 = makeStyles(toolbarStyle);

const TOTAL_ALLOWED_VIDEOS = 4;

const TooltipStyle = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgb(97 97 97 / 98%)',
    fontSize: '.8rem',
  },
}))(Tooltip);

const AdVideosDialog = ({ agentId, handleClose, rowAgentType }) => {
  const classes = useStyles();
  const classes2 = useStyles2();
  const label = getAgentTypeLabel(rowAgentType, false, true);

  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [reloadRows, setReloadRows] = useState(false);
  const [agentStatus, setAgentStatus] = useState(1);
  const [agentWebProfile, setAgentWebProfile] = useState(false);

  const [addNewMode, setAddNewMode] = useState(false);
  const [previewMode, setPreviewMode] = useState(null);

  const [showActionConfirmDialog, setShowActionConfirmDialog] = useState(false);
  const [action, setAction] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const columns = [
    { name: 'No.', id: '', width: '5%' },
    { name: 'Preview', id: 'videoName', width: '15%' },
    { name: 'Type', id: 'adVideoType', width: '15%' },
    { name: 'Status', id: 'status', width: '15%' },
    { name: 'Comments', id: 'comment', width: '30%' },
    { name: 'Actions', id: 'action', width: '40%', align: 'right' },
  ];

  const deleteData = (row) => {
    setSelectedVideo(row.id);
    setShowActionConfirmDialog(true);
    setAction('delete');
  };

  const handlePreviewAdVideo = (id) => {
    setSelectedVideo(id);
    setPreviewMode(true);
  };

  const openListMode = () => {
    setPreviewMode(null);
    setAddNewMode(false);
    setReloadRows(!reloadRows);
  };

  const openApprovalDialog = (row) => {
    setSelectedVideo(row.id);
    setShowActionConfirmDialog(true);
    setAction('approve');
  };

  const openRejectionDialog = (row) => {
    setSelectedVideo(row.id);
    setShowActionConfirmDialog(true);
    setAction('reject');
  };

  const onActionSuccess = () => {
    setSelectedVideo(null);
    setShowActionConfirmDialog(false);
    setAction(null);
    setReloadRows(!reloadRows);
  };

  useEffect(() => {
    setDataLoaded(false);
    viewAgentById(agentId)
      .then((agentData) => {
        setAgentStatus(Number(agentData?.data?.status || 0));
        setAgentWebProfile(!!agentData?.data?.webUrl);
        getAgentAdVideos(agentId).then((res) => {
          setRows(res.data);
          setDataLoaded(true);
        });
      })
      .catch(() => {
        // no action
      });
  }, [reloadRows, agentId]);

  const refreshItems = () => {
    setReloadRows(!reloadRows);
  };

  const getToolTipTitle = (row) => {
    let title = '';
    if (row.status === agentAdVideoStatus.PENDING) {
      if (Number(row.assetStatus) === agentAdVideoAssetStatus.WAITING) {
        title = 'The video will soon be edited with required details.';
      } else if (Number(row.assetStatus) === agentAdVideoAssetStatus.PREPARING) {
        title = 'The video editing is in progress. It takes a few minutes to complete the process.';
      } else if (Number(row.assetStatus) === agentAdVideoAssetStatus.READY) {
        title = 'The video is ready for review.';
      } else if (Number(row.assetStatus) === agentAdVideoAssetStatus.FAILED) {
        title = 'The video editing failed. Please delete the video and re-upload it.';
      }
    } else if (row.status === agentAdVideoStatus.APPROVED) {
      if (Number(row.assetStatus) === agentAdVideoAssetStatus.MUX_WAITING) {
        title = 'The video is being finalised.';
      } else if (Number(row.assetStatus) === agentAdVideoAssetStatus.MUX_UPLOADING) {
        title = 'The video is being finalised.';
      } else if (Number(row.assetStatus) === agentAdVideoAssetStatus.MUX_FAIL) {
        title = 'Something went wrong finalising the video.';
      }
    } else if (row.status === agentAdVideoStatus.DISAPPROVED) {
      title = row.comments;
    }
    return title;
  };

  return (
    <Dialog open maxWidth="md" fullWidth>
      <DialogTitle>
        {addNewMode ? (
          'Add New Intro Video'
        ) : previewMode ? (
          'Preview Intro Video'
        ) : (
          <Box display="flex" justifyContent="space-between">
            <Box>{label} Intro Videos</Box>
            {agentWebProfile && (
              <Box display="flex">
                {rows.length < TOTAL_ALLOWED_VIDEOS && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes2.addNewBtn}
                    onClick={() => {
                      setAddNewMode(true);
                    }}
                    startIcon={<AddIcon />}
                    disabled={!dataLoaded}
                  >
                    Add New
                  </Button>
                )}
                <Box display="flex" justifyContent="flex-end" ml={2}>
                  <Button onClick={refreshItems} variant="contained" disableElevation>
                    Refresh
                  </Button>
                </Box>
              </Box>
            )}
            {!agentWebProfile && dataLoaded && (
              <Box display="flex">
                <Box display="flex" justifyContent="flex-end" ml={2}>
                  <Typography variant="body2" style={{ marginTop: 8, color: 'red' }}>
                    Videos can be uploaded once agent profile is published.
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </DialogTitle>
      <DialogContent>
        <Box pt={0.3} pb={3}>
          <Box mb={4} mt={0}>
            {addNewMode ? (
              <AddVideoMode agentId={agentId} onSuccess={openListMode} />
            ) : previewMode ? (
              <PreviewAdVideo
                adVideoId={selectedVideo}
                agentStatus={agentWebProfile ? agentStatus : 0}
              />
            ) : (
              <>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {columns.map((col) => (
                          <TableCell width={col.width} align={col.align || ''}>
                            <span>{col.name}</span>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!dataLoaded &&
                        [1, 2, 3, 4, 5].map((val) => (
                          <TableRow key={`st-${val}`}>
                            <TableCell component="th" scope="row">
                              <Skeleton variant="text" className={classes.placeholderSkeleton} />
                            </TableCell>
                            <TableCell>
                              <Skeleton variant="text" className={classes.placeholderSkeleton} />
                            </TableCell>
                            <TableCell align="right">
                              <Skeleton variant="text" className={classes.placeholderSkeleton} />
                            </TableCell>
                            <TableCell align="right">
                              <Skeleton variant="text" className={classes.placeholderSkeleton} />
                            </TableCell>
                            <TableCell align="right">
                              <Skeleton variant="text" className={classes.placeholderSkeleton} />
                            </TableCell>
                            <TableCell align="right">
                              <Skeleton variant="text" className={classes.placeholderSkeleton} />
                            </TableCell>
                          </TableRow>
                        ))}
                      {dataLoaded &&
                        (rows.length > 0 ? (
                          rows.map((row, i) => (
                            <TableRow key={`st-${row.id}`}>
                              <TableCell>{i + 1}</TableCell>
                              <TableCell component="th" scope="row">
                                <Button
                                  size="small"
                                  variant="outlined"
                                  startIcon={<PlayArrow />}
                                  disableElevation
                                  color="primary"
                                  onClick={() => {
                                    handlePreviewAdVideo(row.id);
                                  }}
                                  disabled={!row.videoUrl}
                                >
                                  Preview
                                </Button>
                              </TableCell>
                              <TableCell>Show Intro</TableCell>
                              <TableCell>
                                <TooltipStyle title={getToolTipTitle(row)}>
                                  <Box style={{ cursor: 'pointer' }}>
                                    {row.status === agentAdVideoStatus.PENDING && (
                                      <span color="warning" className={classes.statusPending}>
                                        {Number(row.assetStatus) === agentAdVideoAssetStatus.WAITING
                                          ? 'Waiting'
                                          : ''}
                                        {Number(row.assetStatus) ===
                                        agentAdVideoAssetStatus.PREPARING
                                          ? 'Processing'
                                          : ''}
                                        {Number(row.assetStatus) === agentAdVideoAssetStatus.READY
                                          ? 'Pending'
                                          : ''}
                                        {Number(row.assetStatus) === agentAdVideoAssetStatus.FAILED
                                          ? 'Failed'
                                          : ''}
                                      </span>
                                    )}
                                    {row.status === agentAdVideoStatus.APPROVED && (
                                      <span
                                        className={
                                          Number(row.assetStatus) ===
                                          agentAdVideoAssetStatus.MUX_FAIL
                                            ? classes.statusInactive
                                            : Number(row.assetStatus) ===
                                              agentAdVideoAssetStatus.MUX_READY
                                            ? classes.statusActive
                                            : classes.statusPending
                                        }
                                      >
                                        {Number(row.assetStatus) ===
                                        agentAdVideoAssetStatus.MUX_WAITING
                                          ? 'Finalising'
                                          : ''}
                                        {Number(row.assetStatus) ===
                                        agentAdVideoAssetStatus.MUX_UPLOADING
                                          ? 'Finalising'
                                          : ''}
                                        {Number(row.assetStatus) ===
                                        agentAdVideoAssetStatus.MUX_FAIL
                                          ? 'Failed'
                                          : ''}
                                        {Number(row.assetStatus) ===
                                        agentAdVideoAssetStatus.MUX_READY
                                          ? 'Approved'
                                          : ''}
                                      </span>
                                    )}
                                    {row.status === agentAdVideoStatus.DISAPPROVED && (
                                      <span className={classes.statusInactive}>Disapproved</span>
                                    )}
                                  </Box>
                                </TooltipStyle>
                              </TableCell>

                              <TableCell>{row.comments ? row.comments : '-'}</TableCell>
                              <TableCell align="right">
                                {Number(row.status) === agentAdVideoStatus.PENDING &&
                                  Number(row.assetStatus) === agentAdVideoAssetStatus.READY &&
                                  row.videoUrl &&
                                  agentWebProfile &&
                                  agentStatus === 1 && (
                                    <>
                                      <IconButton
                                        aria-label="approve"
                                        className={classes.deleteBtn}
                                        onClick={() => {
                                          openApprovalDialog(row);
                                        }}
                                      >
                                        <Tooltip title="Approve">
                                          <Check fontSize="small" color="primary" />
                                        </Tooltip>
                                      </IconButton>
                                      <IconButton
                                        aria-label="reject"
                                        className={classes.deleteBtn}
                                        onClick={() => {
                                          openRejectionDialog(row);
                                        }}
                                      >
                                        <Tooltip title="Reject">
                                          <Close fontSize="small" color="secondary" />
                                        </Tooltip>
                                      </IconButton>
                                    </>
                                  )}

                                <IconButton
                                  aria-label="delete"
                                  className={classes.deleteBtn}
                                  onClick={() => {
                                    deleteData(row);
                                  }}
                                >
                                  <Tooltip title="Delete">
                                    <DeleteIcon fontSize="small" color="secondary" />
                                  </Tooltip>
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              align="center"
                              size="medium"
                              colSpan={6}
                              className={classes.noRecord}
                              style={{ padding: 20, fontSize: 15 }}
                            >
                              No records found
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {(previewMode || addNewMode) && (
          <Button
            variant="outlined"
            color="primary"
            className={classes2.addNewBtn}
            onClick={() => {
              openListMode();
            }}
          >
            Back
          </Button>
        )}
        <Button onClick={handleClose} variant="contained" disableElevation>
          close
        </Button>
      </DialogActions>

      {showActionConfirmDialog && (
        <ActionConfirmDialog
          agentId={agentId}
          adVideoId={selectedVideo}
          action={action}
          onSuccess={onActionSuccess}
          handleClose={() => {
            setSelectedVideo(null);
            setShowActionConfirmDialog(false);
            setAction(false);
          }}
        />
      )}
    </Dialog>
  );
};

const AddVideoMode = ({ agentId, onSuccess }) => {
  const hiddenFileInput = useRef(null);

  const [progress, setProgress] = useState(0);
  const [fileSelected, setFileSelected] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [excludeOverlay, setExcludeOverlay] = useState(false);
  const [skipVideoValidation, setSkipVideoValidation] = useState(false);
  const [skipVideoLengthValidation, setSkipVideoLengthValidation] = useState(false);
  const [showVideoProgressTip, setShowVideoProgressTip] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleCheckBox = (e) => {
    if (e.target.checked) {
      setExcludeOverlay(true);
      setSkipVideoValidation(true);
      setSkipVideoLengthValidation(true);
    } else {
      setExcludeOverlay(false);
    }
  };

  const handleValidationCheckBox = (e) => {
    if (excludeOverlay) {
      setSkipVideoValidation(true);
    } else if (e.target.checked) {
      setSkipVideoValidation(true);
    } else {
      setSkipVideoValidation(false);
    }
  };

  const handleLengthValidationCheckBox = (e) => {
    if (excludeOverlay) {
      setSkipVideoLengthValidation(true);
    } else if (e.target.checked) {
      setSkipVideoLengthValidation(true);
    } else {
      setSkipVideoLengthValidation(false);
    }
  };

  const getSignedUrl = (file, callback) => {
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });
    if (file.type.split('/')[0] === 'video') {
      const payload = {
        agentId,
        adVideoType: agentAdVideoType.PRE_ROLL, // TODO: ask later from user
        objectName: file.name,
        contentType: file.type,
        excludeOverlay: excludeOverlay ? 1 : 0,
        skipVideoValidation: skipVideoValidation ? 1 : 0,
        skipVideoLengthValidation: skipVideoLengthValidation ? 1 : 0,
      };
      createAgentAdVideo(payload)
        .then((res) => {
          setShowVideoProgressTip(true);
          callback(res.data);
          setFileSelected(true);
        })
        .catch(() => {
          setHasError(true);
          setErrorMessage('Something went wrong.');
        });
    } else {
      setSnackbarMeesage({
        message: `Please select video file only.`,
        type: 'error',
        show: true,
      });
    }
  };

  const onProgress = (p) => {
    setProgress(p);
  };
  const onError = (error) => {
    setHasError(true);
    setErrorMessage(error);
  };
  const onFinish = (data) => {
    setProgress(100);
    setProgress(0);
    setFileSelected(false);

    const payload = {
      agentId,
      adVideoId: data.adVideoId,
      url: data.publicUrl,
    };
    updateAgentAdVideoUrl(payload)
      .then(() => {
        onSuccess();
        setFileSelected(true);
      })
      .catch(() => {
        setHasError(true);
        setErrorMessage('Something went wrong.');
      });
  };

  return (
    <>
      <ReactS3Uploader
        getSignedUrl={getSignedUrl}
        accept="video/*"
        onProgress={onProgress}
        onError={onError}
        onFinish={onFinish}
        uploadRequestHeaders={{
          'x-amz-acl': 'public-read',
        }}
        contentDisposition="auto"
        inputRef={hiddenFileInput}
        name="videoUpload"
        style={{ width: 0, height: 0, display: 'none' }}
      />
      <Box display="flex" flexDirection="column" style={{ marginBottom: 10 }}>
        <FormControlLabel
          control={<Checkbox checked={excludeOverlay} onChange={handleCheckBox} color="primary" />}
          label="Skip Overlay"
          disabled={fileSelected}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={skipVideoValidation}
              onChange={handleValidationCheckBox}
              color="primary"
            />
          }
          label="Skip video resolution validation while video conversion"
          disabled={fileSelected}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={skipVideoLengthValidation}
              onChange={handleLengthValidationCheckBox}
              color="primary"
            />
          }
          label="Skip Length Validation"
          disabled={fileSelected}
        />
      </Box>
      <Button
        onClick={handleClick}
        fullWidth
        variant="outlined"
        startIcon={<CloudUpload />}
        title={errorMessage}
      >
        {!fileSelected && 'Select Video'}
        {!hasError && fileSelected && progress < 100 && `File Uploading (${progress}%)`}
        {!hasError && fileSelected && progress === 100 && 'File Uploaded'}
        {hasError && 'Error'}
      </Button>
      <div style={{ marginBottom: 20, marginTop: 10, color: 'grey' }}>
        {showVideoProgressTip &&
          `Do not close the window until whole video is uploaded. Current progress - ${progress}% / 100%`}
      </div>
      <div style={{ marginTop: 50 }}>
        The video uploaded will get failed or auto-reject if it fails any of the following
        requirements:
        <br />
        <ul>
          <li>The video duration must be between 20 to 60 seconds.</li>
          <li>The video resolution must be minimum 1920 x 1080.</li>
          <li>The audio must be clear in the video.</li>
        </ul>
      </div>

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </>
  );
};

AddVideoMode.propTypes = {
  agentId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

AdVideosDialog.propTypes = {
  agentId: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  rowAgentType: PropTypes.number.isRequired,
};

export default AdVideosDialog;
