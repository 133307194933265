import {
  Box,
  Button,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { ArrowDownward, ImportExport, MoreVert } from '@material-ui/icons';
import { Pagination, Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PropTypes from 'prop-types';
import RoutePaths from '../../config/Routes';
import ConfirmDialog from './ConfirmDialog';
import { listAll } from '../../services/ContentEngine';
import listingStyle from '../../theme/styles/TableListing';
import TableToolbar from '../TableToolbar';
import FormDialog from './FormDialog';
import ViewDialog from './ViewDialog';
import { formatESTTimezone } from '../../utils/Datetime';

const useStyles = makeStyles(listingStyle);

const ContentEngine = () => {
  const classes = useStyles();
  const history = useHistory();

  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reloadRows, setReloadRows] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [contentDialog, setContentDialog] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);

  const [actionId, setActionId] = useState(0);

  const columns = [
    { name: 'Topic', id: 'topic', width: '25%' },
    { name: 'Social Media Channel', id: 'socialMediaChannel', width: '10%' },
    { name: 'Content Type', id: 'contenttype', width: '8%', align: 'center' },
    { name: 'Post Date', id: 'postdate', width: '15%', align: 'center' },
    { name: 'Status', id: 'status', width: '10%', align: 'center' },
  ];

  const getParams = () => {
    let queryString = '1=1';
    if (sortBy) queryString += `&sortBy=${sortBy}`;
    if (orderBy) queryString += `&orderBy=${orderBy}`;
    if (searchText) queryString += `&searchText=${searchText}`;
    return queryString.substring(4);
  };

  useEffect(() => {
    setDataLoaded(false);
    listAll(activePage, getParams())
      .then((res) => {
        setRows(res.data.rows);
        setTotalPages(res.data.totalPages);
        setDataLoaded(true);
      })
      .catch(() => {
        setDataLoaded(true);
      });
  }, [reloadRows, activePage]);

  const searchList = () => {
    setActivePage(1);
    setReloadRows(!reloadRows);
  };

  const sortHandler = (colId) => {
    setActivePage(1);
    setSortBy(colId);
    setOrderBy(orderBy === 'asc' ? 'desc' : 'asc');
    setReloadRows(!reloadRows);
  };

  const refreshList = () => {
    setReloadRows(!reloadRows);
  };

  const resetList = () => {
    setSearchText('');
    setOrderBy('');
    setSortBy('');
    setReloadRows(!reloadRows);
    setActivePage(1);
    history.push(RoutePaths.CONTENT_ENGINE);
  };

  const handleActions = (row, action) => {
    if (action === 'edit') {
      setActionId(row.id);
      setContentDialog(true);
    } else if (action === 'delete') {
      setActionId(row.id);
      setDeleteDialog(true);
    } else if (action === 'view') {
      setActionId(row.id);
      setViewDialog(true);
    }
  };

  return (
    <Box mt={2}>
      <div className={classes.filterToolbar}>
        <TableToolbar
          refreshList={refreshList}
          searchList={searchList}
          getSearchText={(e) => setSearchText(encodeURIComponent(e.target.value))}
          addNew={() => setContentDialog(true)}
          resetList={resetList}
        />
      </div>
      <TableContainer component={Paper} elevation={0}>
        <Table className={classes.tableData} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell width={col.width} align={col.align || ''}>
                  {!['schedule', 'submission'].includes(col.id) ? (
                    <TableSortLabel
                      active={sortBy === col.id}
                      direction={sortBy === col.id ? orderBy : 'asc'}
                      onClick={() => sortHandler(col.id)}
                      IconComponent={sortBy !== col.id ? ImportExport : ArrowDownward}
                    >
                      <span>{col.name}</span>
                    </TableSortLabel>
                  ) : (
                    col.name
                  )}
                </TableCell>
              ))}
              <TableCell align="right" width="10%">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!dataLoaded &&
              [1, 2, 3, 4, 5, 6].map((val) => (
                <TableRow key={`sub-${val}`}>
                  <TableCell component="th" scope="row">
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                </TableRow>
              ))}
            {dataLoaded &&
              (rows.length > 0 ? (
                rows.map((row) => (
                  <TableRow key={`sub-${row.id}`}>
                    <TableCell>{row.topic}</TableCell>
                    <TableCell>{row.socmedChannel}</TableCell>
                    <TableCell align="center">
                      {row.contentType.charAt(0).toUpperCase() + row.contentType.slice(1)}
                    </TableCell>
                    <TableCell align="center">{formatESTTimezone(row.postDate)}</TableCell>
                    <TableCell align="center">
                      <span
                        className={
                          row.status === 'Active' ? classes.statusActive : classes.statusInactive
                        }
                      >
                        {row.status}
                      </span>
                    </TableCell>
                    <TableCell align="right">
                      <ActionMenu row={row} selectAction={handleActions} />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    align="center"
                    size="medium"
                    colSpan={6}
                    className={classes.noRecord}
                    style={{ padding: 20, fontSize: 15 }}
                  >
                    No records found
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {dataLoaded && totalPages > 1 && (
        <Pagination
          count={totalPages}
          showFirstButton
          showLastButton
          className={classes.tablePagination}
          onChange={(_, pageNumber) => {
            setActivePage(pageNumber);
            setReloadRows(!reloadRows);
          }}
          page={activePage}
        />
      )}
      {deleteDialog && (
        <ConfirmDialog
          closeModel={() => {
            setDeleteDialog(false);
            setActionId(0);
          }}
          onSuccess={() => {
            setDeleteDialog(false);
            setActionId(0);
            setReloadRows(!reloadRows);
          }}
          deleteId={actionId}
        />
      )}

      {contentDialog && (
        <FormDialog
          onClose={() => {
            setContentDialog(false);
            setActionId(0);
          }}
          onSuccess={() => {
            setReloadRows(!reloadRows);
          }}
          editId={actionId}
        />
      )}
      {viewDialog && (
        <ViewDialog
          id={actionId}
          onClose={() => {
            setViewDialog(false);
            setActionId(0);
          }}
        />
      )}
    </Box>
  );
};

const ActionMenu = ({ row, selectAction }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (action) => {
    selectAction(row, action);
    handleClose();
  };
  return (
    <div>
      <Button aria-controls={`simple-menu${row.id}`} aria-haspopup="true" onClick={handleClick}>
        <MoreVert />
      </Button>
      <Menu
        id={`simple-menu${row.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {row.isEdit && (
          <MenuItem
            onClick={() => {
              handleMenuClick('edit');
            }}
            button
          >
            Edit
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            handleMenuClick('view');
          }}
          button
        >
          View
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClick('delete');
          }}
          button
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};
ActionMenu.propTypes = {
  row: PropTypes.objectOf().isRequired,
  selectAction: PropTypes.func.isRequired,
};

const SubmissionButton = ({ row, onClick }) => {
  const totalSubmission = row.submission || '∞';
  const totalSubmitted = row.submitted;

  return (
    <Button disableElevation onClick={() => onClick(row.id)}>
      {totalSubmitted}/{totalSubmission}
    </Button>
  );
};

SubmissionButton.propTypes = {
  row: PropTypes.objectOf().isRequired,
  onClick: PropTypes.objectOf().isRequired,
};
export default ContentEngine;
