/* eslint-disable prettier/prettier */
import { getApiCall, postApiCall } from '../utils/Api';

export const getAgentDiscountTokens = async (agentId, page, inactiveTokens = 0, prms) => {
  const result = await getApiCall(
    `/agent/${agentId}/discount-tokens?inactive_tokens=${inactiveTokens}&page=${page}${prms ? `&${prms}` : ''
    }`
  );
  return result.data;
};

export const cancelCreditById = async (id, reason) => {
  const result = await postApiCall(`/discount-tokens/${id}/cancel-credit`, { reason });
  return result.data;
};

export const createToken = async (data) => {
  const result = await postApiCall(`/discount-tokens/create`, data);
  return result.data;
};
