/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import SnackbarMessage from '../SnackbarMessage';
import { deleteSubmission } from '../../services/Approvals';
import { deleteBlog } from '../../services/Blogs';

const ConfirmDialog = ({ from, deleteId, closeModel, onSuccess }) => {
  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleDialog = () => {
    closeModel();
  };

  const handleConfirmation = (e) => {
    e.preventDefault();
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    if (from === 'submission') {
      deleteSubmission(deleteId)
        .then(() => {
          setProcessing(false);
          handleDialog();
          onSuccess();
        })
        .catch(({ response }) => {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.data.message,
            type: 'error',
            show: true,
          });
          setProcessing(false);
        });
    } else {
      deleteBlog(deleteId)
        .then(() => {
          setProcessing(false);
          handleDialog();
          onSuccess();
        })
        .catch(({ response }) => {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.data.message,
            type: 'error',
            show: true,
          });
          setProcessing(false);
        });
    }
  };

  return (
    <Dialog open onClose={handleDialog}>
      <DialogTitle id="alert-dialog-title">
        Delete {from === 'submission' ? 'Submission' : 'Blog'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Do you want to delete this record? This action can not be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirmation}
          color="secondary"
          autoFocus
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing}
        >
          Delete
        </Button>
        <Button onClick={handleDialog} color="primary">
          Cancel
        </Button>
      </DialogActions>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  from: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  closeModel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  deleteId: PropTypes.number,
};
ConfirmDialog.defaultProps = {
  deleteId: 0,
};

export default ConfirmDialog;
