import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import { removeTeam } from '../../services/Team';
import { removeUser } from '../../services/Auth';
import SnackbarMessage from '../SnackbarMessage';

const ConfirmDialog = ({ open, deleteId, removeFor, closeModel, title, onSuccess }) => {
  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleDialog = () => {
    closeModel();
  };

  const handleConfirmation = (e) => {
    e.preventDefault();
    setProcessing(true);
    if (deleteId) {
      if (removeFor === 'team') {
        removeTeam(deleteId)
          .then(() => {
            setProcessing(false);
            handleDialog();
            onSuccess();
          })
          .catch(() => {
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: 'Some error occurred while deleting team member, please try again.',
              type: 'error',
              show: 'error',
            });
            setProcessing(false);
          });
      } else {
        removeUser(deleteId)
          .then(() => {
            setProcessing(false);
            handleDialog();
            onSuccess();
          })
          .catch(() => {
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: 'Some error occurred while revoke user access, please try again.',
              type: 'error',
              show: 'error',
            });
            setProcessing(false);
          });
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleDialog}>
      <DialogTitle id="alert-dialog-title">
        {removeFor === 'team' ? `Delete ${title}` : title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {removeFor === 'team'
            ? `Do you want to delete this record? This action can not be undone.`
            : `Do you want to revoke access from this member?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirmation}
          color="secondary"
          autoFocus
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing}
        >
          {removeFor === 'team' ? `Delete` : `Yes`}
        </Button>
        <Button onClick={handleDialog} color="primary">
          Cancel
        </Button>
      </DialogActions>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  deleteId: PropTypes.number,
  title: PropTypes.string,
  removeFor: PropTypes.string,
};
ConfirmDialog.defaultProps = {
  deleteId: 0,
  title: '',
  removeFor: '',
};

export default ConfirmDialog;
