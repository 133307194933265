import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import ErrorWrapper from '../../layout/Error';
import RoutePaths from '../../config/Routes';
import errorStyle from '../../theme/styles/Error';

const useStyles = makeStyles(errorStyle);

// Page not found error
const NotFound = () => {
  const pageName = '404';
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    history.push(RoutePaths.HOME);
  };

  return (
    <ErrorWrapper>
      <Grid container spacing={0} align="center" justify="center" direction="column">
        <Container maxWidth="xs">
          <Container component="div" className={classes.errorPageText}>
            <Typography component="h1" variant="h1">
              {pageName}
            </Typography>
            <Typography component="p">The page you are looking for does not exist.</Typography>
          </Container>
          <Button variant="contained" color="primary" onClick={handleClick}>
            Back Home
          </Button>
        </Container>
      </Grid>
    </ErrorWrapper>
  );
};

export default NotFound;
