import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import toolbarStyle from '../theme/styles/TableToolbar';

const useStyles = makeStyles(toolbarStyle);

// Table toolbar
const TableToolbar = ({
  refreshList,
  searchList,
  getSearchText,
  addNew,
  resetList,
  showAddOption,
  inputSearch,
  forceInputTextChange,
}) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState(inputSearch);

  useEffect(() => {
    if (inputSearch !== searchText && (inputSearch !== '' || forceInputTextChange)) {
      setSearchText(inputSearch);
    }
  }, [inputSearch]);

  return (
    <>
      <div className={classes.filterLeft}>
        <TextField
          name="search"
          id="search"
          label="Search"
          className={classes.searchInput}
          value={searchText}
          onChange={(e) => {
            getSearchText(e);
            setSearchText(e.target.value);
            searchList();
          }}
        />
        <Button variant="contained" className={classes.searchFilterBtn} onClick={refreshList}>
          Refresh
        </Button>
        <Button
          variant="contained"
          className={classes.searchFilterBtn}
          onClick={() => {
            setSearchText('');
            resetList();
          }}
        >
          Reset
        </Button>
      </div>
      <div className={classes.filterRight}>
        {showAddOption && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            className={classes.addNewBtn}
            onClick={addNew}
          >
            Add New
          </Button>
        )}
      </div>
    </>
  );
};

TableToolbar.propTypes = {
  refreshList: PropTypes.func.isRequired,
  searchList: PropTypes.func.isRequired,
  getSearchText: PropTypes.func.isRequired,
  addNew: PropTypes.func.isRequired,
  resetList: PropTypes.func.isRequired,
  showAddOption: PropTypes.bool,
  inputSearch: PropTypes.string,
  forceInputTextChange: PropTypes.bool,
};

TableToolbar.defaultProps = {
  showAddOption: true,
  inputSearch: '',
  forceInputTextChange: false,
};
export default TableToolbar;
