import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import SnackbarMessage from '../SnackbarMessage';
import { postAgentBlog, releaseAgentBlog } from '../../services/Blogs';

const ConfirmDialog = ({ id, closeModel, userAction }) => {
  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleDialog = () => {
    closeModel();
  };

  const handleConfirmation = (e) => {
    e.preventDefault();
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    if (userAction === 'release') {
      releaseAgentBlog(id)
        .then(() => {
          setProcessing(false);
          handleDialog();
        })
        .catch((response) => {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.message,
            type: 'error',
            show: true,
          });
          setProcessing(false);
        });
    } else {
      postAgentBlog(id)
        .then(() => {
          setProcessing(false);
          handleDialog();
        })
        .catch((response) => {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.message,
            type: 'error',
            show: true,
          });
          setProcessing(false);
        });
    }
  };

  return (
    <Dialog open maxWidth="sm" onClose={handleDialog}>
      <DialogTitle id="alert-dialog-title">
        {userAction === 'release' ? 'Release Blog' : 'Claim Blog'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {userAction === 'release'
            ? 'Are you sure want to release this blog?'
            : 'Once you claim the blog, you will have 7 days to submit your blog data. Do you want to continue and claim the blog for submission?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirmation}
          color="secondary"
          autoFocus
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing}
        >
          Yes
        </Button>
        <Button onClick={handleDialog} color="primary">
          No
        </Button>
      </DialogActions>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModel: PropTypes.func.isRequired,
  id: PropTypes.number,
  userAction: PropTypes.string.isRequired,
};
ConfirmDialog.defaultProps = {
  id: 0,
};

export default ConfirmDialog;
