import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, Container } from '@material-ui/core';
import VideoPlayer from 'react-video-js-player';
import ActionConfirmDialog from './ActionConfirmDialog';
import { agentAdVideoAssetStatus, agentAdVideoStatus } from '../../config/DataValues';
import { getAgentAdVideoById } from '../../services/AgentAdVideos';

const PreviewVideo = ({ adVideoId, agentStatus }) => {
  const [viewData, setViewData] = useState(null);
  const [showAdVideoActionConfirmDialog, setShowAdVideoActionConfirmDialog] = useState(false);
  const [action, setAction] = useState(null);

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [reloadData, setReloadData] = useState(false);

  const openApprovalDialog = () => {
    setShowAdVideoActionConfirmDialog(true);
    setAction('approve');
  };

  const openRejectionDialog = () => {
    setShowAdVideoActionConfirmDialog(true);
    setAction('reject');
  };

  useEffect(() => {
    getAgentAdVideoById(adVideoId).then((res) => {
      setViewData(res.data);
      setIsDataLoaded(true);
    });
  }, [reloadData, adVideoId]);

  return (
    <Container>
      {!isDataLoaded ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box justifyContent="center" alignItems="center" display="flex" flexDirection="column">
          <VideoPlayer controls src={viewData?.videoUrl} width="800" />
          <div style={{ marginTop: 15, marginBottom: 15 }}>
            Note: Some video preview may not work in browser, however once the video is converted
            then preview will work fine.
          </div>
          {viewData.status === agentAdVideoStatus.PENDING &&
            Number(viewData.assetStatus) === agentAdVideoAssetStatus.READY &&
            agentStatus === 1 && (
              <Box display="flex" justifyContent="space-between" mt={2}>
                <Button
                  size="small"
                  onClick={openApprovalDialog}
                  variant="outlined"
                  disableElevation
                  color="primary"
                  fullWidth
                  style={{ marginRight: 10 }}
                >
                  Approve
                </Button>
                <Button
                  size="small"
                  onClick={openRejectionDialog}
                  variant="outlined"
                  disableElevation
                  color="secondary"
                  fullWidth
                  style={{ marginRight: 10 }}
                >
                  Reject
                </Button>
              </Box>
            )}
          {showAdVideoActionConfirmDialog && (
            <ActionConfirmDialog
              adVideoId={adVideoId}
              action={action}
              onSuccess={() => {
                setShowAdVideoActionConfirmDialog(false);
                setAction(null);
                setReloadData(!reloadData);
              }}
              handleClose={() => {
                setShowAdVideoActionConfirmDialog(false);
                setAction(null);
              }}
            />
          )}
        </Box>
      )}
    </Container>
  );
};

PreviewVideo.propTypes = {
  adVideoId: PropTypes.number.isRequired,
  agentStatus: PropTypes.number.isRequired,
};

export default PreviewVideo;
