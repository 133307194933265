import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import Cropper from 'cropperjs';
import PropTypes from 'prop-types';
import SnackbarMessage from '../SnackbarMessage';
import 'cropperjs/dist/cropper.css';
import { getContentSignedUrl, addAgentContentAsset } from '../../services/ContentEngine';

const CreateContentLogo = ({ selectedFile, closeCallback, imageDetails, refreshCallback }) => {
  const [cropperLoaded, setCropperLoaded] = useState(false);
  const [cropper, setCropper] = useState(null);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [fileBlob, setFileBlob] = useState(null);

  const minCroppedWidth = 200;
  const minCroppedHeight = 200;
  const maxCroppedWidth = Number(imageDetails.width);
  const cropDetails = {
    width: minCroppedWidth,
    height: minCroppedHeight,
    isValid: true,
  };

  const initCropper = () => {
    const image = document.getElementById('image-preview');

    const cropperRef = new Cropper(image, {
      viewMode: 2,
      zoomable: false,
      guides: false,
      data: {
        width: minCroppedWidth,
        height: minCroppedHeight,
      },
      crop(e) {
        const previousWidth = cropDetails.width;
        cropDetails.width = Number(parseFloat(e.detail.width).toFixed(0));
        cropDetails.height = Number(parseFloat(e.detail.height).toFixed(0));

        if (cropDetails.width < 400) {
          cropDetails.isValid =
            cropDetails.width >= minCroppedWidth &&
            cropDetails.height >= minCroppedHeight &&
            cropDetails.width <= maxCroppedWidth &&
            cropDetails.height === minCroppedHeight;

          if (!cropDetails.isValid) {
            cropperRef.setData({
              width: Math.max(minCroppedWidth, Math.min(maxCroppedWidth, cropDetails.width)),
              height: minCroppedHeight,
            });
          }
        } else if (previousWidth !== cropDetails.width) {
          const desiredHeight = Math.floor(cropDetails.width / 2);
          cropDetails.isValid =
            cropDetails.width >= minCroppedWidth &&
            cropDetails.height >= minCroppedHeight &&
            cropDetails.height === desiredHeight;

          if (!cropDetails.isValid) {
            cropperRef.setData({
              width: Math.max(minCroppedWidth, Math.min(maxCroppedWidth, cropDetails.width)),
              height: Math.max(minCroppedHeight, desiredHeight),
            });
          }
        } else {
          const desiredWidth = Math.floor(cropDetails.height * 2);
          cropDetails.isValid =
            cropDetails.width >= minCroppedWidth &&
            cropDetails.height >= minCroppedHeight &&
            cropDetails.width === desiredWidth;

          if (!cropDetails.isValid) {
            cropperRef.setData({
              width: Math.max(minCroppedWidth, Math.min(maxCroppedWidth, desiredWidth)),
              height: Math.max(
                minCroppedHeight,
                Math.min(cropDetails.height, Math.floor(cropDetails.width / 2))
              ),
            });
          }
        }

        window.document.getElementById('selected-area-text').innerHTML = `
        Selected area: ${cropDetails.width} x ${cropDetails.height}
        `;
      },
    });
    const contData = cropperRef.getContainerData(); // Get container data
    cropperRef.setCropBoxData({ height: contData.height, width: contData.width }); // set data
    setCropperLoaded(true);
    setCropper(cropperRef);
  };

  const closeCropModel = () => {
    window.FreshworksWidget('show');
    setCropper(null);
    closeCallback();
  };

  const onError = () => {
    setIsFileUploading(false);
  };

  const onFinish = async (data) => {
    if (data) {
      const payload = {
        url: data.publicUrl,
      };
      await addAgentContentAsset(payload)
        .then((resp) => {
          if (resp) {
            closeCropModel();
            refreshCallback();
          }
        })
        .catch((resp) => {
          setSnackbarMeesage({
            message: resp.message,
            type: 'error',
            show: true,
          });
        });
    }
  };

  const uploadFile = async (data) => {
    const option = {
      headers: {
        'Content-Type': selectedFile.type,
      },
    };
    try {
      await axios.put(data.signedUrl, fileBlob, option);
      await onFinish(data);
    } catch (e) {
      onError();
    }
  };

  const getCroppedImageUrl = async () => {
    setIsFileUploading(true);
    const data = cropper.getData();
    if (cropper) {
      await cropper
        .getCroppedCanvas({ width: Math.floor(data.width), height: Math.floor(data.height) })
        .toBlob(
          (blob) => {
            setFileBlob(blob);
            // cropper.destroy();
          },
          selectedFile.type,
          1
        );
    }
  };

  useEffect(() => {
    window.FreshworksWidget('hide');
    setTimeout(initCropper, 1000);
  }, []);

  useEffect(async () => {
    if (fileBlob !== null) {
      const payload = {
        objectName: selectedFile.name,
        contentType: selectedFile.type,
        module: 'content-agent',
      };
      await getContentSignedUrl(payload)
        .then(async (res) => {
          await uploadFile(res.data);
        })
        .catch(() => {
          setSnackbarMeesage({
            message: `Something went wrong.`,
            type: 'error',
            show: true,
          });
        });
      cropper.destroy();
      setIsFileUploading(false);
    }
  }, [fileBlob]);

  return (
    <Dialog open onClose={closeCropModel} maxWidth="lg" fullWidth>
      <DialogTitle>Select content logo</DialogTitle>
      <DialogContent>
        <img id="image-preview" src="#" alt="" hidden={!cropperLoaded} />

        {!cropperLoaded && <CircularProgress size={30} />}
      </DialogContent>
      <DialogActions>
        <Box display="flex" width="100%">
          <Box display="flex" flexDirection="row" flex={2}>
            <Typography variant="body2">
              Minimum resolution:{' '}
              <strong>
                {minCroppedWidth} x {minCroppedHeight}
              </strong>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <span
                id="selected-area-text"
                style={{ color: `${!cropDetails.isValid ? 'red' : 'black'}` }}
              >
                Selected area:{' '}
                <strong>
                  {cropDetails.width} x {cropDetails.height}
                </strong>
              </span>
            </Typography>
          </Box>
          <Button
            id="btn-select-crop"
            onClick={getCroppedImageUrl}
            color="primary"
            variant="contained"
            disableElevation
            style={{ marginRight: 10 }}
            disabled={!cropDetails.isValid || isFileUploading}
            startIcon={isFileUploading && <CircularProgress size={20} />}
          >
            Select
          </Button>
          <Button onClick={closeCropModel} color="default" variant="contained" disableElevation>
            Cancel
          </Button>
        </Box>
      </DialogActions>

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

CreateContentLogo.propTypes = {
  closeCallback: PropTypes.func.isRequired,
  selectedFile: PropTypes.objectOf().isRequired,
  imageDetails: PropTypes.objectOf().isRequired,
  refreshCallback: PropTypes.func.isRequired,
};

export default CreateContentLogo;
