/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Radio,
  FormControlLabel,
  RadioGroup,
  Link,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import { isFuture, isPast } from 'date-fns';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { ArrowDropDown } from '@material-ui/icons';
import formInput from '../../theme/styles/FormInput';
import Validations from '../../utils/Validations';
import SnackbarMessage from '../SnackbarMessage';
import { updateAgentBlog, getBlogById } from '../../services/Blogs';
import WYSIWYGEditor from '../WYSIWYG';

const useStyles = makeStyles(formInput);
const BlogFormDialog = ({ guideLink, editId, closeModel, onSuccess }) => {
  const classes = useStyles();

  const [isPublished, setIsPublished] = useState(false);
  // const [isView, setIsView] = useState(false);
  const [publishedDateError, setPublishedDateError] = useState('');
  const [openDateError, setOpenDateError] = useState('');
  const [closeDateError, setCloseDateError] = useState('');
  const [processing, setProcessing] = useState(false);
  const [standardVideoUrl, setStandardVideoUrl] = useState('');
  const [squareVideoUrl, setSquareVideoUrl] = useState('');
  const [selectedValue, setSelectedValue] = React.useState('text');
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [viewModel, setViewModel] = useState(false);
  const [viewUrl, setViewUrl] = useState('');

  const { handleSubmit, control, setValue, reset, getValues, watch } = useForm();

  useState(() => {}, []);

  const submitForm = (data) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    setProcessing(true);
    const payload = {
      articleText: data.articleText ? data.articleText : null,
      articleLink: data.articleLink ? data.articleLink : null,
    };

    updateAgentBlog(editId, payload)
      .then(() => {
        setProcessing(false);
        reset();
        onSuccess();
        closeModel();
      })
      .catch(({ response }) => {
        setProcessing(false);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
        setProcessing(false);
      });
  };

  const closeDate = watch('closeDate');
  const openDate = watch('openDate');
  const status = watch('status');

  const defaultFormValues = getValues();

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const GreenRadio = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={closeModel}>
      <DialogTitle>Create Blog</DialogTitle>
      <DialogContent>
        {guideLink && (
          <Link href={guideLink} target="blank">
            Guideline Link
          </Link>
        )}
        <form onSubmit={handleSubmit(submitForm)} id="submission-form">
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={selectedValue}
            onChange={handleChange}
          >
            <FormControlLabel value="text" control={<GreenRadio />} label="Article Text" />
            {selectedValue === 'text' && (
              <Controller
                control={control}
                rules={selectedValue === 'text' ? { ...Validations.REQUIRED } : null}
                name="articleText"
                id="articleText"
                defaultValue={defaultFormValues.articleText || ''}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <>
                    <WYSIWYGEditor value={value} onChange={onChange} />
                    {error && (
                      <Typography
                        style={{ marginTop: -15, marginBottom: 10 }}
                        className={classes.formErrorText}
                      >
                        {error.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            )}
            <FormControlLabel value="link" control={<GreenRadio />} label="Article Link" />
            {selectedValue === 'link' && (
              <Controller
                control={control}
                rules={
                  selectedValue === 'link'
                    ? { ...Validations.OPTIONAL_URL, ...Validations.REQUIRED }
                    : null
                }
                name="articleLink"
                id="articleLink"
                defaultValue={defaultFormValues.link || ''}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    variant="outlined"
                    label="Article Link"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    className={classes.formInput}
                    fullWidth
                  />
                )}
              />
            )}
          </RadioGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          type="submit"
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing || publishedDateError || openDateError || closeDateError}
          form="submission-form"
        >
          Submit
        </Button>

        <Button variant="contained" disableElevation onClick={closeModel}>
          Cancel
        </Button>
      </DialogActions>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

const UploadMenu = ({ handleAction, title, slug }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptClick = (action) => {
    handleAction(slug, action);
    handleClose();
  };

  return (
    <Box mb={2}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<ArrowDropDown />}
        fullWidth
        variant="outlined"
      >
        {title}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleOptClick('view')}>View</MenuItem>
        <MenuItem onClick={() => handleOptClick('delete')}>Delete</MenuItem>
      </Menu>
    </Box>
  );
};

UploadMenu.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  handleAction: PropTypes.func.isRequired,
};

BlogFormDialog.propTypes = {
  guideLink: PropTypes.string,
  editId: PropTypes.number,
  closeModel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

BlogFormDialog.defaultProps = {
  guideLink: '',
  editId: 0,
};

export default BlogFormDialog;
