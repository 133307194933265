import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DefaultEditor } from 'react-simple-wysiwyg';

function WYSIWYGEditor({ value, onChange }) {
  const editorId = `wysiwyg-${Math.floor(Math.random() * 9999999)}`;

  useEffect(() => {
    const btn1 = window.document.querySelectorAll(`#${editorId} button[title="Numbered list"]`);
    for (let i = 0; i < btn1.length; i += 1) {
      btn1[i].style.display = 'none';
    }
    const btn2 = window.document.querySelectorAll(`#${editorId} button[title="Link"]`);
    for (let i = 0; i < btn2.length; i += 1) {
      btn2[i].style.display = 'none';
    }
    const btn3 = window.document.querySelectorAll(`#${editorId} button[title="Clear formatting"]`);
    for (let i = 0; i < btn3.length; i += 1) {
      btn3[i].style.display = 'none';
    }
    const btn4 = window.document.querySelectorAll(`#${editorId} button[title="HTML mode"]`);
    for (let i = 0; i < btn4.length; i += 1) {
      btn4[i].style.display = 'none';
    }

    const sep = window.document.querySelectorAll(`#${editorId} .rsw-separator`);
    sep[3].style.display = 'none';

    const opt = window.document.querySelectorAll(`#${editorId} select[title="Styles"] option`);
    opt[3].style.display = 'none';
    opt[4].style.display = 'none';
  }, []);

  return (
    <div id={editorId} style={{ marginBottom: '20px' }}>
      <DefaultEditor value={value} onChange={onChange} />
    </div>
  );
}

WYSIWYGEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default WYSIWYGEditor;
