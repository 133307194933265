import { getApiCall, postApiCall, deleteApiCall } from '../utils/Api';

export const addBanner = async (data) => {
  const result = await postApiCall(`/banner/create`, data);
  return result.data;
};

export const updateBanner = async (id, data) => {
  const result = await postApiCall(`/banner/${id}/update`, data);
  return result.data;
};

export const listBanner = async (page, params) => {
  const result = await getApiCall(`/banner/list?page=${page}&${params}`);
  return result.data;
};

export const viewBannerById = async (id) => {
  const result = await getApiCall(`/banner/${id}/view`);
  return result.data;
};

export const removeBanner = async (id) => {
  const result = await deleteApiCall(`/banner/${id}/delete`);
  return result.data;
};

export const setFallbackBanner = async (id, flag) => {
  const result = await getApiCall(`/banner/${id}/${flag}/set-fallback`);
  return result.data;
};

export const getSignedImageUrl = async (data) => {
  const result = await postApiCall(`/banner/image-upload-url`, data);
  return result.data;
};

export const listInternalLinks = async () => {
  const result = await getApiCall(`/banner/internal-links`);
  return result.data;
};
