import grey from '@material-ui/core/colors/grey';

// Style for layout wrapper
const Style = (theme) => ({
  wrapper: {
    display: 'flex',
    minHeight: '100vh',
    maxWidth: 'none',
    backgroundColor: grey[200],
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    paddingBottom: '20px',
  },
  contentContract: {
    flexGrow: 1,
    marginTop: 25,
    marginBottom: 25,
  },
});

export default Style;
