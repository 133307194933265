import { createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';

// Extend default MuiTheme
const Default = createMuiTheme({
  palette: {
    primary: {
      main: green[600],
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        color: green[600],
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiTableHead: {
      root: {
        background: grey[100],
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 'bold',
      },
    },
    MuiTableSortLabel: {
      icon: {
        opacity: 1,
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
  },
});

export default Default;
