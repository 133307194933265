import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Container,
  Paper,
  Typography,
  Box,
  makeStyles,
  Table,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { format } from 'date-fns';
import { contractStatusValue, paymentFrequencies } from '../../config/DataValues';
import { createAmendmentSubscription, updataContractStatus } from '../../services/Contract';
import SnackbarMessage from '../SnackbarMessage';
import TableListing from '../../theme/styles/TableListing';
import { convertTimestampToDate, getCurrentTimestamp } from '../../utils/Datetime';

const dollarUS = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const useStyles = makeStyles(TableListing);

const AmendmentPayment = ({
  doPayForAmendment,
  finalAmendmentAmount,
  actualAmendmentAmount,
  subscriptionType,
  token,
  refershPage,
  unusedMoney,
  stripeCredit,
  subscriptionStartDate,
  pendingCancelationDetails,
}) => {
  const classes = useStyles();
  const currentTime = convertTimestampToDate(getCurrentTimestamp());
  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handlePayment = () => {
    setProcessing(true);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });

    createAmendmentSubscription(token)
      .then(async (res) => {
        if (res.data) {
          await updataContractStatus({ token, status: contractStatusValue.PAID });
          setSnackbarMeesage(() => ({
            ...snackbarMeesage,
            message:
              'Payment completed successfully. Do not close the window, you will be redirected to the final step.',
            type: 'success',
            show: true,
          }));
          setProcessing(false);
          setTimeout(() => {
            refershPage();
          }, 2500);
        }
        setProcessing(false);
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: `${response.data?.message}, please wait retrying...`,
          type: 'error',
          show: true,
        });
        setTimeout(() => {
          refershPage();
        }, 3000);
        setProcessing(false);
      });
  };

  const calculateNextBillingDate = () => {
    const currentDate = new Date();
    let nextBillingDate;
    if (subscriptionType) {
      if (subscriptionStartDate) {
        const subStartDate = new Date(Number(subscriptionStartDate) * 1000);
        nextBillingDate = subStartDate.setMonth(
          subStartDate.getMonth() + paymentFrequencies[subscriptionType].multiply
        );
      } else {
        nextBillingDate = currentDate.setMonth(
          currentDate.getMonth() + paymentFrequencies[subscriptionType].multiply
        );
      }

      return format(new Date(nextBillingDate), 'MMMM d, yyyy');
    }
    return '';
  };

  const nextBillingDate = calculateNextBillingDate();
  return (
    <Box py={3} width="50%" mx="auto">
      <Container component={Paper}>
        <Box py={3} justifyContent="center" alignItems="center">
          {doPayForAmendment ? (
            <>
              <Typography
                variant="h5"
                color="primary"
                style={{ marginBottom: 15, fontWeight: 'bold' }}
              >
                Confirm Your New Payment
              </Typography>
              <Typography variant="h5" style={{ marginBottom: 15, fontSize: 14 }}>
                Confirm your new{' '}
                {subscriptionType &&
                  paymentFrequencies[subscriptionType].label.toString().toLowerCase()}{' '}
                payment of {actualAmendmentAmount && dollarUS.format(actualAmendmentAmount)}, and
                pay your pro-rated balance of{' '}
                {finalAmendmentAmount && dollarUS.format(finalAmendmentAmount)}, then your next
                payment will not be due until {nextBillingDate}.
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant="h5"
                color="primary"
                style={{ marginBottom: 15, fontWeight: 'bold' }}
              >
                Confirm Your New Payment
              </Typography>

              <Typography variant="h5" style={{ marginBottom: 15, fontSize: 14 }}>
                Confirm your new{' '}
                {subscriptionType &&
                  paymentFrequencies[subscriptionType].label.toString().toLowerCase()}{' '}
                payment of {actualAmendmentAmount && dollarUS.format(actualAmendmentAmount)}, and
                pay your pro-rated balance of {dollarUS.format(0)}, then your next payment will not
                be due until {nextBillingDate}.
              </Typography>
            </>
          )}
          <Table width="100%">
            <TableRow>
              <TableCell className={classes.paymentPageCell}>
                New{' '}
                {subscriptionType &&
                  paymentFrequencies[subscriptionType].label.toString().toLowerCase()}{' '}
                Payment
              </TableCell>
              <TableCell align="right" className={classes.paymentPageCell}>
                {actualAmendmentAmount && dollarUS.format(actualAmendmentAmount)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.paymentPageCell}>
                Pro-rated credit from previous payment
              </TableCell>
              <TableCell align="right" className={classes.paymentPageCell}>
                - {unusedMoney && dollarUS.format(unusedMoney)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.paymentPageCell}>Other credit balance</TableCell>
              <TableCell align="right" className={classes.paymentPageCell}>
                - {dollarUS.format(stripeCredit)}
              </TableCell>
            </TableRow>
            {pendingCancelationDetails.zones.length > 0 && (
              <TableRow>
                <TableCell className={classes.paymentPageCell}>
                  {pendingCancelationDetails.zones.join(',')} (Pending cancelation zones)
                </TableCell>
                <TableCell align="right" className={classes.paymentPageCell}>
                  - {dollarUS.format(pendingCancelationDetails.discount)}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell className={classes.paymentPageCell}>
                <strong>Amount due now</strong>
              </TableCell>
              <TableCell align="right" className={classes.paymentPageCell}>
                <strong>
                  {doPayForAmendment
                    ? finalAmendmentAmount && dollarUS.format(finalAmendmentAmount)
                    : dollarUS.format(0)}
                </strong>
              </TableCell>
            </TableRow>
          </Table>
          <Box mt={1} mb={1}>
            <Typography variant="caption">
              {doPayForAmendment
                ? `*Pro-rated credit calculated at ${currentTime}, actual balance at time of payment may
            vary slightly.`
                : `*Your outstanding credit 
              of ${finalAmendmentAmount && dollarUS.format(finalAmendmentAmount)}
              will be credited toward your next payment(s). Credit calculated at ${currentTime}, actual credit will reflect time of confirmation and may vary slightly.`}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disableElevation
            fullWidth
            onClick={handlePayment}
            endIcon={processing && <CircularProgress size={25} />}
            disabled={processing}
          >
            {doPayForAmendment ? 'Confirm & Pay' : 'Confirm & Proceed'}
          </Button>
          <Box mt={0.5}>
            <Typography variant="caption">
              Your card on file will be charged the amount due immediately upon confirmation
            </Typography>
          </Box>
        </Box>
      </Container>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Box>
  );
};

AmendmentPayment.propTypes = {
  doPayForAmendment: PropTypes.bool.isRequired,
  finalAmendmentAmount: PropTypes.number.isRequired,
  subscriptionType: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
  refershPage: PropTypes.func.isRequired,
  actualAmendmentAmount: PropTypes.number.isRequired,
  unusedMoney: PropTypes.number.isRequired,
  stripeCredit: PropTypes.number.isRequired,
  subscriptionStartDate: PropTypes.number.isRequired,
  pendingCancelationDetails: PropTypes.shape({
    zones: PropTypes.arrayOf,
    discount: PropTypes.number,
  }).isRequired,
};
export default AmendmentPayment;
