import React, { useEffect, useState } from 'react';
import {
  Button,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Pagination, Skeleton } from '@material-ui/lab';
import { ArrowDropDown } from '@material-ui/icons';
import JsFileDownloader from 'js-file-downloader';
import { getPastSubmissions } from '../../services/Approvals';
import listingStyle from '../../theme/styles/TableListing';
import logo from '../../assets/images/ng.ico';
import SnackbarMessage from '../SnackbarMessage';
import { trackUserClick } from '../../utils/Activity';

const useStyles = makeStyles(listingStyle);

const PostSubmissionsTable = () => {
  const classes = useStyles();
  const getDetails = window.localStorage.getItem('userDetail');
  const userId = getDetails ? Number(JSON.parse(getDetails).id) : 0;

  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reloadRows, setReloadRows] = useState(false);

  useEffect(() => {
    setDataLoaded(false);
    getPastSubmissions(activePage).then((res) => {
      setRows(res.data.rows);
      setTotalPages(res.data.totalPages);
      setDataLoaded(true);
    });
  }, [activePage, reloadRows]);

  const downloadVideo = (type, url, row) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    trackUserClick(type === 'Standard' ? 41 : 42, {
      prompt_title: row.title,
      prompt_id: row.id,
      agent_id: userId,
    });
    // process for download video
    // eslint-disable-next-line no-unused-vars
    const download = new JsFileDownloader({
      url,
      timeout: 30 * 60 * 1000,
    });
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: 'Video id downloading, Please do not close the browser or refresh the page.',
      type: 'success',
      show: true,
    });
  };

  return (
    <>
      <Table className={classes.tableData}>
        <TableHead>
          <TableRow>
            <TableCell width="2%" />
            <TableCell width="20%">Prompt Title</TableCell>
            <TableCell width="10%">Status</TableCell>
            <TableCell width="10%">View</TableCell>
            <TableCell width="10%">Download</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!dataLoaded &&
            [1, 2, 3, 4, 5].map((val) => (
              <TableRow key={`sub-${val}`}>
                <TableCell component="th" scope="row">
                  <Skeleton variant="text" className={classes.placeholderSkeleton} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" className={classes.placeholderSkeleton} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" className={classes.placeholderSkeleton} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" className={classes.placeholderSkeleton} />
                </TableCell>
                <TableCell align="right">
                  <Skeleton variant="text" className={classes.placeholderSkeleton} />
                </TableCell>
              </TableRow>
            ))}
          {rows.map((row) => (
            <TableRow key={`sub-${row.id}`}>
              <TableCell>
                {row.videoUrl ? (
                  <img src={logo} alt={process.env.REACT_APP_NAME} width={30} height={20} />
                ) : (
                  ''
                )}
              </TableCell>
              <TableCell>{row.title}</TableCell>
              <TableCell>{row.schedule}</TableCell>
              <TableCell>
                {row.viewLink ? (
                  <Link target="_blank" href={row.viewLink}>
                    Click To Watch
                  </Link>
                ) : (
                  '-'
                )}
              </TableCell>
              <TableCell>
                <DownloadMenu row={row} downloadVideo={downloadVideo} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>

        {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      </Table>
      {dataLoaded && totalPages > 1 && (
        <Pagination
          count={totalPages}
          showFirstButton
          showLastButton
          className={classes.tablePagination}
          onChange={(_, pageNumber) => {
            setActivePage(pageNumber);
            setReloadRows(!reloadRows);
          }}
          page={activePage}
        />
      )}
    </>
  );
};

const DownloadMenu = ({ row, downloadVideo }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadVideo = (type, url) => {
    downloadVideo(type, url, row);
    handleClose();
  };
  if (!row.squareVideoUrl && !row.standardVideoUrl) {
    return '-';
  }
  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<ArrowDropDown />}
        size="small"
      >
        Download
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {row.standardVideoUrl && (
          <MenuItem onClick={() => handleDownloadVideo('Standard', row.standardVideoUrl)}>
            Download Standard Video
          </MenuItem>
        )}
        {row.squareVideoUrl && (
          <MenuItem onClick={() => handleDownloadVideo('Square', row.squareVideoUrl)}>
            Download Square Video
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
DownloadMenu.propTypes = {
  row: PropTypes.objectOf().isRequired,
  downloadVideo: PropTypes.func.isRequired,
};
export default PostSubmissionsTable;
