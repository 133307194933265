/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { TableSortLabel, Typography } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { ArrowDownward, ImportExport } from '@material-ui/icons';
import PrivateWrapper from '../../layout/Private';
import TableToolbar from '../../components/TableToolbar';
import listingStyle from '../../theme/styles/TableListing';
import listAll from '../../services/State';
import FormDialog from '../../components/state/FormDialog';
import ConfirmDialog from '../../components/state/ConfirmDialog';
import SnackbarMessage from '../../components/SnackbarMessage';
import UserTypes from '../../config/UserTypes';

const useStyles = makeStyles(listingStyle);

// State codes management
const States = () => {
  const pageName = 'States';
  const classes = useStyles();

  const history = useHistory();
  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;
  const IS_READONLY =
    userType === UserTypes.SALES_PERSON.value ||
    userType === UserTypes.ADMIN.value ||
    userType === UserTypes.SUPPORT.value ||
    userType === UserTypes.SALES_MANAGER.value;

  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reloadRows, setReloadRows] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const [openModel, setOpenModel] = useState(false);
  const [confirmModel, setConfirmModel] = useState(false);

  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const defaultSnackMessageState = {
    show: false,
    type: '',
    message: '',
  };
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const columns = [
    { name: 'Name', id: 'stateName', width: '30%' },
    { name: 'Code', id: 'code', width: '20%' },
    { name: 'Status', id: 'status', width: '10%' },
    { name: 'Zip Codes', id: 'zipCodes', width: '10%', align: 'right' },
    { name: 'Zones', id: 'zones', width: '10%', align: 'right' },
    { name: 'Lender Zones', id: 'lenderZones', width: '10%', align: 'right' },
  ];

  const getParams = () => {
    let queryString = '1=1';
    if (sortBy) queryString += `&sortBy=${sortBy}`;
    if (orderBy) queryString += `&orderBy=${orderBy}`;
    if (searchText) queryString += `&searchText=${searchText}`;

    return queryString.substring(4);
  };

  useEffect(() => {
    setSnackbarMeesage({
      ...defaultSnackMessageState,
    });
    setDataLoaded(false);
    listAll(activePage, getParams())
      .then((res) => {
        setRows(res.data.rows);
        setTotalPages(res.data.totalPages);
        setDataLoaded(true);
      })
      .catch(() => {
        setDataLoaded(true);
        setSnackbarMeesage({
          ...snackbarMeesage,
          show: true,
          message: 'Some error occurred while fetching state list, please try again.',
          type: 'error',
        });
      });
  }, [reloadRows, activePage]);

  const searchList = () => {
    setActivePage(1);
    setReloadRows(!reloadRows);
  };

  const sortHandler = (colId) => {
    setActivePage(1);
    setSortBy(colId);
    setOrderBy(orderBy === 'asc' ? 'desc' : 'asc');
    setReloadRows(!reloadRows);
  };

  const refreshList = () => {
    setReloadRows(!reloadRows);
  };

  const editData = (id) => {
    setEditId(id);
    setOpenModel(true);
  };

  const deleteData = (id) => {
    setDeleteId(id);
    setConfirmModel(true);
  };

  const resetList = () => {
    setSearchText('');
    setOrderBy('');
    setSortBy('');
    setReloadRows(!reloadRows);
    setActivePage(1);
    history.push('/state-codes');
  };

  return (
    <PrivateWrapper pageName={pageName}>
      <>
        <div className={classes.filterToolbar}>
          <TableToolbar
            refreshList={refreshList}
            searchList={searchList}
            getSearchText={(e) => setSearchText(encodeURIComponent(e.target.value))}
            addNew={() => setOpenModel(true)}
            resetList={resetList}
            showAddOption={!IS_READONLY}
          />
        </div>
        <TableContainer component={Paper}>
          <Table className={classes.tableData} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((col) => (
                  <TableCell width={col.width} align={col.align || ''}>
                    <TableSortLabel
                      active={sortBy === col.id}
                      direction={sortBy === col.id ? orderBy : 'asc'}
                      onClick={() => sortHandler(col.id)}
                      IconComponent={sortBy !== col.id ? ImportExport : ArrowDownward}
                    >
                      <span>{col.name}</span>
                    </TableSortLabel>
                  </TableCell>
                ))}
                {!IS_READONLY && (
                  <TableCell align="right" width="10%">
                    Action
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!dataLoaded &&
                [1, 2, 3, 4, 5].map((val) => (
                  <TableRow key={`st-${val}`}>
                    <TableCell component="th" scope="row">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    {!IS_READONLY && (
                      <TableCell align="right">
                        <Skeleton variant="text" className={classes.placeholderSkeleton} />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              {dataLoaded &&
                (rows.length > 0 ? (
                  rows.map((row) => (
                    <TableRow key={`st-${row.id}`}>
                      <TableCell component="th" scope="row">
                        {row.stateName}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.code}
                      </TableCell>
                      <TableCell>
                        <span
                          className={
                            row.status === 'Active' ? classes.statusActive : classes.statusInactive
                          }
                        >
                          {row.status}
                        </span>
                      </TableCell>
                      <TableCell align="right">
                        {row.zipCodes === 0 ? (
                          <Typography variant="h6">-</Typography>
                        ) : userType === UserTypes.SALES_PERSON.value ||
                          userType === UserTypes.SUPPORT.value ? (
                          <Typography className={classes.greenText}>{row.zipCodes}</Typography>
                        ) : (
                          <Link
                            className={classes.linkText}
                            to={
                              userType !== UserTypes.SALES_PERSON.value &&
                              `/zipcodes?stateId=${row.id}`
                            }
                          >
                            {row.zipCodes}
                          </Link>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {row.zones === 0 ? (
                          <Typography variant="h6">-</Typography>
                        ) : (
                          <Link className={classes.linkText} to={`/zones?stateId=${row.id}`}>
                            {row.zones}
                          </Link>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {row.lenderZones === 0 ? (
                          <Typography variant="h6">-</Typography>
                        ) : (
                          <Link className={classes.linkText} to={`/lender-zones?stateId=${row.id}`}>
                            {row.lenderZones}
                          </Link>
                        )}
                      </TableCell>
                      {!IS_READONLY && (
                        <TableCell align="right">
                          <IconButton
                            aria-label="edit"
                            className={classes.editBtn}
                            onClick={() => editData(row.id)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            className={classes.deleteBtn}
                            onClick={() => deleteData(row.id)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      size="medium"
                      colSpan={7}
                      className={classes.noRecord}
                      style={{ padding: 20, fontSize: 15 }}
                    >
                      No records found
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {dataLoaded && totalPages > 1 && (
          <Pagination
            count={totalPages}
            showFirstButton
            showLastButton
            className={classes.tablePagination}
            onChange={(_, pageNumber) => {
              setActivePage(pageNumber);
              setReloadRows(!reloadRows);
            }}
            page={activePage}
          />
        )}

        {openModel && (
          <FormDialog
            open={openModel}
            closeModel={() => {
              setOpenModel(false);
              setEditId(null);
            }}
            title={editId ? 'Edit State' : 'Add State'}
            onSuccess={() => {
              setReloadRows(!reloadRows);
            }}
            editId={editId}
          />
        )}
        {confirmModel && (
          <ConfirmDialog
            open={confirmModel}
            closeModel={() => {
              setConfirmModel(false);
              setDeleteId(null);
            }}
            title="State"
            onSuccess={() => {
              setReloadRows(!reloadRows);
            }}
            deleteId={deleteId}
          />
        )}
        {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      </>
    </PrivateWrapper>
  );
};

export default States;
