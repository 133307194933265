/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
import { ArrowDownward, ImportExport } from '@material-ui/icons';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Link from '@material-ui/core/Link';
import TableToolbar from '../TableToolbar';
import listingStyle from '../../theme/styles/TableListing';
import { listAllByAgentId } from '../../services/Inquiries';
import InquiryNote from './InquiryNote';
import InquiryNotesList from './InquiryNotesList';
import RoutePaths from '../../config/Routes';

const useStyles = makeStyles(listingStyle);

const InquiriesListing = () => {
  const classes = useStyles();
  const history = useHistory();

  const columns = [
    { name: 'Date', id: 'time', width: '10%' },
    { name: 'Name', id: 'name', width: '10%' },
    { name: 'Email', id: 'email', width: '10%' },
    { name: 'Phone', id: 'phone', width: '10%' },
    { name: 'Message', id: 'message', width: '35%' },
    { name: 'Notes', id: 'notes', width: '20%' },
  ];

  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reloadRows, setReloadRows] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [selectedId, setSelectedId] = useState(0);
  const [showAddNote, setShowAddNote] = useState(false);
  const [viewNotes, setViewNotes] = useState(false);

  const sortHandler = (colId) => {
    setActivePage(1);
    setSortBy(colId);
    setOrderBy(orderBy === 'asc' ? 'desc' : 'asc');
    setReloadRows(!reloadRows);
  };

  const getParams = () => {
    let queryString = '1=1';

    if (sortBy) queryString += `&sortBy=${sortBy}`;
    if (orderBy) queryString += `&orderBy=${orderBy}`;
    if (searchText) queryString += `&searchText=${searchText}`;

    return queryString.substring(4);
  };

  const searchList = () => {
    setActivePage(1);
    setReloadRows(!reloadRows);
  };

  const refreshList = () => {
    setReloadRows(!reloadRows);
  };

  const resetList = () => {
    setSearchText('');
    setOrderBy('');
    setSortBy('');
    setReloadRows(!reloadRows);
    setActivePage(1);
    history.push(RoutePaths.INQUIRIES);
  };

  useEffect(() => {
    setDataLoaded(false);

    listAllByAgentId(activePage, getParams())
      .then((res) => {
        setRows(res.data.rows);
        setTotalPages(res.data.totalPages);
        setDataLoaded(true);
      })
      .catch(() => {
        setDataLoaded(true);
      });
  }, [reloadRows, activePage]);

  const handleAddNote = (id) => {
    setSelectedId(id);
    setShowAddNote(true);
  };
  const handleAddNoteClose = () => {
    setSelectedId(0);
    setShowAddNote(false);
  };
  const handleAddNoteSuccess = () => {
    setSelectedId(0);
    setShowAddNote(false);
    refreshList();
  };

  const handleViewNotes = (id) => {
    setSelectedId(id);
    setViewNotes(true);
  };
  const handleViewNotesClose = () => {
    setSelectedId(0);
    setViewNotes(false);
    refreshList();
  };

  return (
    <>
      {' '}
      <div className={classes.filterToolbar}>
        <TableToolbar
          refreshList={refreshList}
          searchList={searchList}
          getSearchText={(e) => setSearchText(encodeURIComponent(e.target.value))}
          resetList={resetList}
          showAddOption={false}
        />
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.tableData} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell width={col.width} align={col.align || ''}>
                  {col.id === 'notes' && <span>{col.name}</span>}
                  {col.id !== 'notes' && (
                    <TableSortLabel
                      active={sortBy === col.id}
                      direction={sortBy === col.id ? orderBy : 'asc'}
                      onClick={() => sortHandler(col.id)}
                      IconComponent={sortBy !== col.id ? ImportExport : ArrowDownward}
                    >
                      <span>{col.name}</span>
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
              <TableCell width="5%" align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {!dataLoaded &&
              [1, 2, 3, 4, 5].map((val) => (
                <TableRow key={`aql-${val}`}>
                  <TableCell component="th" scope="row">
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                </TableRow>
              ))}
            {dataLoaded &&
              (rows.length > 0 ? (
                rows.map((row) => (
                  <TableRow key={`aq-${row.id}`}>
                    <TableCell valign="top">{row.time}</TableCell>
                    <TableCell valign="top">{row.name}</TableCell>
                    <TableCell valign="top">{row.email}</TableCell>
                    <TableCell valign="top">{row.phone}</TableCell>
                    <TableCell style={{ whiteSpace: 'pre-wrap' }}>
                      {row.message && row.message}
                      {!row.message && <i>No message included</i>}
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'pre-wrap' }}>
                      {row.notes > 0 && (
                        <>
                          <sup>{row.lastNoteDate}</sup>
                          <br />
                          {row.lastNote}
                          <br />
                          <Link href="#" onClick={() => handleViewNotes(row.id)} variant="body2">
                            View More
                          </Link>
                        </>
                      )}
                      {row.notes === 0 && (
                        <Link href="#" onClick={() => handleAddNote(row.id)} variant="body2">
                          +Add A Note
                        </Link>
                      )}
                    </TableCell>
                    <TableCell align="right" valign="top">
                      {row.notes > 0 && (
                        <IconButton
                          aria-label="add"
                          title="Add A Note"
                          size="small"
                          onClick={() => handleAddNote(row.id)}
                        >
                          <AddIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    align="center"
                    size="medium"
                    colSpan={7}
                    className={classes.noRecord}
                    style={{ padding: 20, fontSize: 15 }}
                  >
                    No records found
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {dataLoaded && totalPages > 1 && (
        <Pagination
          count={totalPages}
          showFirstButton
          showLastButton
          className={classes.tablePagination}
          onChange={(_, pageNumber) => {
            setActivePage(pageNumber);
            setReloadRows(!reloadRows);
          }}
          page={activePage}
        />
      )}
      {showAddNote && (
        <InquiryNote
          closeDialog={handleAddNoteClose}
          onSave={handleAddNoteSuccess}
          id={selectedId}
        />
      )}
      {viewNotes && <InquiryNotesList closeDialog={handleViewNotesClose} id={selectedId} />}
    </>
  );
};

export default InquiriesListing;
