/* eslint-disable no-unused-vars */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
  Link,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import formInput from '../../theme/styles/FormInput';
import { getBlogById } from '../../services/Blogs';
import WYSIWYGEditor from '../WYSIWYG';

const useStyles = makeStyles(formInput);
const ViewBlogDialog = ({ editId, closeModel }) => {
  const [isView, setIsView] = useState(false);
  const [viewLink, setViewLink] = useState('');
  const [articleText, setArticleText] = useState('');

  const { control, setValue, getValues } = useForm();

  useState(() => {
    getBlogById(editId).then((res) => {
      const { data } = res;
      if (data.viewLink) {
        setViewLink(data.viewLink);
      } else if (data.articleText) {
        setArticleText(data.articleText);
        setIsView(true);
      }
    });
  }, []);

  const defaultFormValues = getValues();

  return (
    <Dialog open fullWidth maxWidth="md" onClose={closeModel}>
      <DialogTitle>View Blog</DialogTitle>
      <DialogContent>
        {isView && (
          <Typography
            dangerouslySetInnerHTML={{
              __html: articleText,
            }}
          >
            {/* <Controller
              control={control}
              name="articleText"
              id="articleText"
              defaultValue={defaultFormValues.articleText || ''}
              render={({ field: { value, onChange } }) => (
                <WYSIWYGEditor value={value} onChange={onChange} isDiable />
              )}
            /> */}
          </Typography>
        )}
        {!isView && (
          <Typography>
            <Link href={viewLink} target="blank">
              {viewLink}
            </Link>
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disableElevation onClick={closeModel}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ViewBlogDialog.propTypes = {
  editId: PropTypes.number,
  closeModel: PropTypes.func.isRequired,
};

ViewBlogDialog.defaultProps = {
  editId: 0,
};

export default ViewBlogDialog;
