import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import ListNotifications from './ListNotifications';
import AddNewNotification from './AddNewNotification';

const ManageNotifications = () => {
  const [editNotificationData, setEditNotificationData] = useState({});
  const [listRefresh, setListRefresh] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <AddNewNotification
          editData={editNotificationData}
          editNotification={setEditNotificationData}
          setRefreshFlag={setListRefresh}
          deleteId={deleteId}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ListNotifications
          editNotification={setEditNotificationData}
          listRefresh={listRefresh}
          setRefreshFlag={setListRefresh}
          setDeleteId={setDeleteId}
        />
      </Grid>
    </Grid>
  );
};

export default ManageNotifications;
