import green from '@material-ui/core/colors/green';

// Style for widgets
const Style = () => ({
  pageTextHeading: {
    marginTop: 5,
    marginBottom: 5,
  },
  pageForm: {
    marginBottom: 5,
    marginTop: 5,
  },
  pageInput: {
    marginBottom: 5,
    marginTop: 5,
    width: '100%',
    '& .css-yk16xz-control': {
      minHeight: 56,
    },
    '& .css-1pahdxg-control': {
      minHeight: 56,
      borderColor: green[600],
      boxShadow: 'none',
      borderWidth: '2px',
    },
    '& .css-1pahdxg-control:hover': {
      borderColor: green[600],
      boxShadow: 'none',
      borderWidth: '2px',
    },
    '& .css-g1d714-ValueContainer': {
      fontSize: '1rem',
    },
  },
  submitBtn: {
    marginTop: 5,
  },
  errorText: {
    fontSize: '0.75rem',
    marginTop: '3px',
    fontWeight: '400',
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    color: '#f44336',
  },
  errorContainer: {
    color: '#f44336',
    textAlign: 'center',
  },
  successContainer: {
    color: green[900],
    textAlign: 'center',
  },
});

export default Style;
