import { deleteApiCall, getApiCall, postApiCall } from '../utils/Api';

export const getAgentAdVideos = async (agentId) => {
  const result = await getApiCall(`/agent/${agentId}/ad-videos`);
  return result.data;
};

export const createAgentAdVideo = async (data) => {
  const result = await postApiCall(`/agent-ad-videos/create`, data);
  return result.data;
};

export const updateAgentAdVideoUrl = async (data) => {
  const result = await postApiCall(`/agent-ad-videos/update-url`, data);
  return result.data;
};

export const updateAgentAdVideoStatus = async (data) => {
  const result = await postApiCall(`/agent-ad-videos/${data.adVideoId}/update-status`, data);
  return result.data;
};

export const getAgentAdVideoById = async (adVideoId) => {
  const result = await getApiCall(`/agent-ad-videos/${adVideoId}/view`);
  return result.data;
};

export const deleteAgentAdVideoById = async (adVideoId) => {
  const result = await deleteApiCall(`/agent-ad-videos/${adVideoId}/remove`);
  return result.data;
};
