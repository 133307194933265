import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import {
  Button,
  CardActionArea,
  Box,
  Paper,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import CardContent from '@material-ui/core/CardContent';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { listNotification, removeNotification } from '../../services/Notification';
import { renderDashboardIcon } from '../DashboardIcons';
import SnackbarMessage from '../SnackbarMessage';
import DashboardList from '../../theme/styles/DashboardList';

const useStyles = makeStyles(DashboardList);

const ListNotifications = ({ editNotification, listRefresh, setRefreshFlag, setDeleteId }) => {
  const classes = useStyles();
  const [editData, setEditData] = useState(null);
  const [isOpenNotifs, setIsOpenNotifs] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [deleteNotificationId, setDeleteNotificationId] = useState(null);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const { control } = useForm();

  useEffect(() => {
    setDataLoaded(false);
    setRefreshFlag(false);
    listNotification(1, `searchText=${encodeURIComponent(searchText)}`)
      .then((res) => {
        if (res && res.data) {
          setNotificationData(res.data.rows);
          setDataLoaded(true);
        }
      })
      .catch(() => {
        setNotificationData([]);
      });
  }, [refresh, searchText, listRefresh]);

  const openNotification = (id) => {
    setIsOpenNotifs(true);
    const data = notificationData.filter((n) => n.id === id);
    setEditData(data[0]);
  };

  const hideNotification = () => {
    setIsOpenNotifs(false);
    setIsDelete(false);
    setEditData(null);
  };

  const openDeleteDialog = (id) => {
    setDeleteNotificationId(id);
    setIsOpenNotifs(false);
    setIsDelete(true);
  };

  const editNotificationData = () => {
    editNotification(editData);
    hideNotification();
  };

  const deleteNotification = async (id) => {
    setDataLoaded(false);
    removeNotification(id)
      .then((res) => {
        if (res.success) {
          setDeleteId(id);
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: 'Notification has been deleted successfully.',
            type: 'success',
            show: true,
          });
          setRefresh(!refresh);
          setIsDelete(false);
        }
      })
      .catch(({ res }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: res.data.message,
          type: 'error',
          show: true,
        });
      });
  };

  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <Typography variant="h5">Active Notifications</Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Controller
                control={control}
                name="title"
                id="title"
                defaultValue=""
                render={({ field: { value }, fieldState: { error } }) => (
                  <TextField
                    variant="outlined"
                    placeholder="Search..."
                    value={value || searchText}
                    size="small"
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    error={!!error}
                    helperText={error ? error.message : null}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <hr />
          </Box>
        </CardContent>
        <div className={classes.listNotification}>
          <Scrollbars>
            {!dataLoaded &&
              [1, 2, 3, 4, 5, 6].map(() => (
                <CardContent>
                  <Box mt={-2}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Skeleton
                          variant="rounded"
                          style={{ height: '110px', borderRadius: '4px' }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              ))}
            {notificationData.length > 0 ? (
              notificationData.map((n) => (
                <CardContent>
                  <Box mt={-2}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Paper elevation={0} sx={3}>
                          <Card variant="outlined" className={classes.cardBackgroundColor}>
                            <CardActionArea onClick={() => openNotification(n.id)}>
                              <CardContent>
                                <Grid container spacing={3}>
                                  <Grid item xs={3}>
                                    {renderDashboardIcon(n.icon, '#43a047')}
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography variant="h6">{n.title}</Typography>
                                    <Typography noWrap>{n.description}</Typography>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Box display="flex" justifyContent="end">
                                      <NavigateNextIcon style={{ fontSize: '4rem' }} />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              ))
            ) : (
              <Box display="flex" justifyContent="center">
                <Typography variant="body2">No records found</Typography>
              </Box>
            )}
          </Scrollbars>
        </div>
      </Card>
      {isOpenNotifs && (
        <Dialog open fullWidth={false} maxWidth="xs" onClose={hideNotification}>
          <Box display="flex" justifyContent="end">
            <IconButton onClick={hideNotification}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box ml={2}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Box>
                      <NotificationsIcon className={classes.notificationIconStyle} />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Box>
                      <Typography variant="h5">{editData.title}</Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Box mt={2} className={classes.fontColor}>
                      <Typography>
                        {editData.startDate && format(new Date(editData.startDate), 'MM/dd/yyyy')} -{' '}
                        {editData.expiryDate && format(new Date(editData.expiryDate), 'MM/dd/yyyy')}
                        {!editData.expiryDate && ' No Expiry Date'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Box mt={2}>
                      <Typography style={{ maxHeight: 240 }}>{editData.description}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Box mt={5}>
              <Button variant="text" onClick={() => editNotificationData()}>
                Edit
              </Button>
              <Button variant="text" onClick={() => openDeleteDialog(editData.id)}>
                Delete
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
      {isDelete && (
        <Dialog open maxWidth="sm" onClose={hideNotification}>
          <Box display="flex" justifyContent="end">
            <IconButton onClick={hideNotification}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <DialogContentText id="alert-dialog-delete">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    <NotificationsIcon className={classes.notificationIconStyle} />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box mt={5} display="flex" justifyContent="center">
                    <Typography variant="h5">Delete This Notification?</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Box mt={3}>
                      <Typography variant="h5" align="center">
                        {editData.title}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={2} display="flex" justifyContent="center">
                    <Typography align="center">{editData.description}</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={3} />
                <Grid item xs={6}>
                  <Box
                    mt={5}
                    display="flex"
                    justifyContent="center"
                    className={classes.deleteButton}
                    onClick={() => deleteNotification(deleteNotificationId)}
                  >
                    <Button className={classes.buttonStyle}>Delete</Button>
                  </Box>
                </Grid>
                <Grid item xs={3} />
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </>
  );
};

ListNotifications.propTypes = {
  editNotification: PropTypes.func.isRequired,
  listRefresh: PropTypes.bool.isRequired,
  setRefreshFlag: PropTypes.func.isRequired,
  setDeleteId: PropTypes.func.isRequired,
};

export default ListNotifications;
