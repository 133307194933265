import { getApiCall, postApiCall } from '../utils/Api';

export const getDemoLinkAgentsList = async (params) => {
  const result = await getApiCall(`/sales/get-demo-link-agents?searchText=${params}`);
  return result.data;
};

export const getActiveDemoLink = async () => {
  const result = await getApiCall(`/sales/get-demo-link`);
  return result.data;
};

export const setActiveDemoLink = async (data) => {
  const result = await postApiCall(`/sales/set-demo-link`, data);
  return result.data;
};
