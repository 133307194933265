import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';
import VideoPlayer from 'react-video-js-player';
import { getVideoPreview } from '../../services/Agent';

const ViewVideo = ({ viewModelOpen, handleViewModel, userId }) => {
  const [open, setOpen] = useState(viewModelOpen);
  const [processing, setProcessing] = useState(false);
  const [videoPreview, setVideoPreview] = useState({
    src: '',
    poster: '',
  });

  const handleClose = () => {
    setOpen(false);
    handleViewModel(false);
  };

  useEffect(() => {
    setProcessing(true);
    getVideoPreview(userId)
      .then((res) => {
        setVideoPreview({ src: res.data.previewUrl, poster: '' });
        setProcessing(false);
      })
      .catch(() => {
        setVideoPreview({ src: '', poster: '' });
        setProcessing(false);
      });
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Video Preview</DialogTitle>
      <DialogContent>
        <Box justifyContent="center" display="flex">
          {!processing && (
            <VideoPlayer
              controls
              src={videoPreview.src || ''}
              poster={videoPreview.poster || ''}
              width="800"
            />
          )}
          {processing && <CircularProgress size={30} />}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ViewVideo.propTypes = {
  viewModelOpen: PropTypes.bool.isRequired,
  handleViewModel: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
};

export default ViewVideo;
