/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box, Button, CircularProgress, Grid, CardMedia, Card } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login';
import { makeStyles } from '@material-ui/core/styles';
import { getImageHeightAndWidth, validateProfilePicSize } from '../../utils/ImageUtil';
import 'cropperjs/dist/cropper.css';
import SnackbarMessage from '../SnackbarMessage';
import CreateContentLogo from './CreateContentLogo';
import { getAgentAsset, hasFBAccountSetup, setupFBAccount } from '../../services/ContentEngine';
import { trackUserClick } from '../../utils/Activity';
import ConfirmDialog from './ConfirmDialog';
import fbIcon from '../../assets/images/icons/facebook.png';

const useStyles = makeStyles({
  fbButton: {
    backgroundColor: '#1877F2',
    color: 'white',
    cursor: 'pointer',
    width: '300px',
    textTransform: 'none',
    padding: '5px 15px',
    border: '1px solid rgba(67, 160, 71, 0.5)',
    borderRadius: '4px',
    fontWeight: '600',
    lineHeight: '1.95',
    letterSpacing: '0.02857em',
    fontSize: '0.885rem',
    '& .fbicon-div': {
      width: '28px !important',
      height: '28px !important',
      borderRadius: '100% !important',
      background: 'white',
      float: 'left',
      '& img': {
        width: '28px',
        marginTop: 2,
      },
    },
  },
  fbButtonDisabled: {
    backgroundColor: '#1877F2',
    color: 'white',
    cursor: 'pointer',
    width: '300px',
    textTransform: 'none',
    padding: '5px 15px',
    border: '1px solid rgba(189, 189, 189, 0.5)',
    borderRadius: '4px',
    fontWeight: '600',
    lineHeight: '1.95',
    letterSpacing: '0.02857em',
    fontSize: '0.885rem',
    '& .fbicon-div': {
      width: '28px !important',
      height: '28px !important',
      borderRadius: '100% !important',
      background: 'white',
      float: 'left',
      '& img': {
        width: '28px',
        marginTop: 2,
      },
    },
  },
});

const ContentEnginePics = ({ refreshCallback }) => {
  const classes = useStyles();
  const FBAppId = process.env.REACT_APP_FB_APP_ID;
  const supportedExts = ['png'];

  const getDetails = window.localStorage.getItem('userDetail');
  const loggedInAgentId = getDetails ? Number(JSON.parse(getDetails).id) : 0;

  const [items, setItems] = useState({});
  const [refreshList, setRefreshList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [imageDetails, setImageDetails] = useState({ height: 0, width: 0 });
  const [cropperModel, setCropperModel] = useState(false);
  const [deleteLogo, setDeleteLogo] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFBSetup, setIsFBSetup] = useState(false);

  const imageValidations = {
    minWidth: 200,
    minHeight: 200,
  };

  const delayExecution = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const checkImageTransparency = async (file) =>
    new Promise((resolve, reject) => {
      /*
      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result;

        const view = new DataView(arrayBuffer);

        // is a PNG?
        if (view.getUint32(0) === 0x89504e47 && view.getUint32(4) === 0x0d0a1a0a) {
          const type = view.getUint8(8 + 8 + 9);

          return resolve(type === 4 || type === 6);
        }
        return resolve(false);
      };
      reader.readAsArrayBuffer(file);
      */

      const img = new Image();

      // create image from file
      img.src = URL.createObjectURL(file);

      img.onerror = reject;

      img.onload = () => {
        // create canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        // get image data
        const data = ctx.getImageData(0, 0, canvas.width, canvas.height);

        // check if image has any transparent background
        const hasTransparent = [...data.data].some(
          (value, index) => index % 4 === 3 && value < 255
        );

        return hasTransparent ? resolve(true) : resolve(false);
      };
    });

  useEffect(() => {
    setLoading(true);
    getAgentAsset(loggedInAgentId)
      .then((resp) => {
        setItems({
          url: resp.data.url,
          id: resp.data.id,
        });
        setLoading(false);
      })
      .catch(() => {
        setItems({});
        setLoading(false);
      });

    hasFBAccountSetup()
      .then((res) => {
        setIsFBSetup(Number(res.data) > 0);
      })
      .catch(() => {});
  }, [refreshList]);

  const handleFileChange = async (evt) => {
    trackUserClick(78);
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });

    await delayExecution(300);

    const input = evt.target;
    if (
      input.files &&
      input.files[0] &&
      supportedExts.includes(input.files[0].type.split('/')[1])
    ) {
      const imageDimensions = await validateProfilePicSize(input.files[0]);

      if (input.files[0].type.split('/')[0] === 'image') {
        const details = await getImageHeightAndWidth(input.files[0]);

        if (
          details.width < imageValidations.minWidth ||
          details.height < imageValidations.minHeight
        ) {
          setSnackbarMeesage({
            message: `The image must be minimum 200 x 200 resolution.`,
            type: 'error',
            show: true,
          });
        } else {
          try {
            await checkImageTransparency(input.files[0]);

            setImageDetails(details);
            setSelectedFile(input.files[0]);

            const reader = new FileReader();
            reader.onload = (e) => {
              document.getElementById('image-preview').src = e.target.result;
            };
            reader.readAsDataURL(input.files[0]);

            trackUserClick(84);
            setCropperModel(true);
          } catch (e) {
            setSnackbarMeesage({
              message: `The image must be transparent to be used as logo/watermark.`,
              type: 'error',
              show: true,
            });
          }
        }

        // clear input
        input.value = '';
      } else {
        if (!supportedExts.includes(input.files[0].type.split('/')[1])) {
          setSnackbarMeesage({
            message: `Sorry, 
            ${input.files[0].name} is invalid, allowed extensions are: ${supportedExts.join(', ')}`,
            type: 'error',
            show: true,
          });
        } else {
          setSnackbarMeesage({
            message: imageDimensions || `Please select image file only.`,
            type: 'error',
            show: true,
          });
        }

        // clear input
        input.value = '';
      }
    } else {
      setSnackbarMeesage({
        message: `Sorry, 
            ${input.files[0].name} is invalid, allowed extensions are: ${supportedExts.join(', ')}`,
        type: 'error',
        show: true,
      });
    }
  };

  const handleFacebookResponse = (response) => {
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });

    const payload = {
      ...response,
      status: response.accessToken ? 1 : 0,
      linkingModal: 1,
    };
    setupFBAccount(payload)
      .then(() => {
        if (payload.status === 0) {
          setSnackbarMeesage({
            message: 'There seems to be a problem linking your Facebook account. Please try again.',
            type: 'error',
            show: true,
          });
        } else {
          trackUserClick(89);
          setSnackbarMeesage({
            message: 'Your Facebook account has been successfully linked!',
            type: 'success',
            show: true,
          });
          setIsFBSetup(true);
        }
      })
      .catch(() => {
        setSnackbarMeesage({
          message: 'There seems to be a problem linking your Facebook account. Please try again.',
          type: 'error',
          show: true,
        });
      });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress style={{ marginTop: 30, marginBottom: 30 }} />
      </Box>
    );
  }

  return (
    <div>
      <input accept="image/*" id="profile-pic" type="file" onChange={handleFileChange} hidden />
      <canvas hidden />

      {!loading && items.url && (
        <>
          <Grid container spacing={1}>
            <Grid item>
              <Card component={Box} height={300} width={300} key={items.url}>
                <CardMedia
                  component="img"
                  alt=""
                  image={items.url}
                  title=""
                  height={300}
                  width={300}
                  style={{ objectFit: 'contain' }}
                />
              </Card>
            </Grid>
          </Grid>
          <Box mt={2} mb={6}>
            <Grid container spacing={2}>
              <Grid item={6} style={{ cursor: 'pointer' }}>
                <Button
                  style={{
                    backgroundColor: '#259329ad',
                    color: 'white',
                    borderColor: 'transparent',
                    cursor: 'pointer',
                    width: '150px',
                    textTransform: 'none',
                  }}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  color="primary"
                  fullWidth
                  variant="outlined"
                >
                  <label htmlFor="profile-pic" style={{ cursor: 'pointer' }}>
                    Replace Logo
                  </label>
                </Button>
              </Grid>
              <Grid item={6}>
                <Button
                  style={{
                    backgroundColor: '#ff000094',
                    borderColor: 'transparent',
                    color: 'white',
                    cursor: 'pointer',
                    width: '130px',
                    textTransform: 'none',
                  }}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  color="primary"
                  onClick={() => setDeleteLogo(true)}
                  fullWidth
                  variant="outlined"
                >
                  Delete Logo
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {!loading && !items.url && (
        <Box mt={4}>
          <Grid container spacing={2}>
            <Grid item={8} style={{ cursor: 'pointer' }}>
              <Button
                style={{
                  backgroundColor: '#43a047',
                  color: 'white',
                  cursor: 'pointer',
                  width: '300px',
                  textTransform: 'none',
                }}
                aria-controls="simple-menu"
                aria-haspopup="true"
                color="primary"
                onChange={handleFileChange}
                fullWidth
                variant="outlined"
              >
                <label htmlFor="profile-pic" style={{ cursor: 'pointer' }}>
                  Upload your logo/watermark
                </label>
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box mt={2} mb={4}>
        <Grid container spacing={2}>
          <Grid item={6} style={{ cursor: 'pointer' }}>
            <FacebookLogin
              appId={FBAppId}
              fields="name,email,picture"
              scope="pages_show_list,pages_manage_posts"
              callback={handleFacebookResponse}
              onFailure={() => {}}
              onClick={() => trackUserClick(88)}
              isDisabled={isFBSetup}
              textButton={isFBSetup ? 'Facebook page linked!' : 'Link your Facebook page'}
              cssClass={isFBSetup ? classes.fbButtonDisabled : classes.fbButton}
              icon={
                <div className="fbicon-div">
                  <img src={fbIcon} alt="facebook" />
                </div>
              }
              isMobile={false}
              disableMobileRedirect
            />
          </Grid>
        </Grid>
      </Box>
      {deleteLogo && (
        <ConfirmDialog
          id={items.id}
          closeCallback={() => {
            setDeleteLogo(false);
          }}
          successCallback={() => {
            setDeleteLogo(false);
            setItems({});
          }}
        />
      )}
      {cropperModel && (
        <CreateContentLogo
          selectedFile={selectedFile}
          closeCallback={() => {
            setCropperModel(false);
            setRefreshList(!refreshList);
          }}
          imageDetails={imageDetails}
          refreshCallback={refreshCallback}
        />
      )}
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </div>
  );
};

ContentEnginePics.propTypes = {
  refreshCallback: PropTypes.func.isRequired,
};

export default ContentEnginePics;
