import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  CircularProgress,
  DialogContent,
  Paper,
  Tabs,
  Tab,
  Box,
  DialogContentText,
} from '@material-ui/core';
import SnackbarMessage from '../SnackbarMessage';
import agentStyle from '../../theme/styles/Agents';
import formInput from '../../theme/styles/FormInput';
import PrepareContract from './PrepareContract';
import listAll from '../../services/Zones';
import ViewContract from './ViewContract';
import { cancelAmendment, sendAmendment, viewDraftAmendment } from '../../services/Contract';
import { contractStatusValue } from '../../config/DataValues';
import AmendContractOverdueDialog from './AmendContractOverdueDialog';
import CancelContract from './CancelContract';
import UserTypes from '../../config/UserTypes';
import CancelSubscriptionTab from '../agent/CancelSubscriptionTab';

const useStyles = makeStyles(formInput);
const useStyles2 = makeStyles(agentStyle);

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && <Box pt={3}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const AmendContractDialog = ({
  open,
  agentId,
  closeModal,
  onCancelAmendment,
  reloadAgentData,
  zoneType,
}) => {
  const classes = useStyles();
  const classes2 = useStyles2();
  const subStart = new Date();
  const contractExp = new Date();

  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;
  const hasAmendContractAccess = [
    UserTypes.SUPER_ADMIN.value,
    UserTypes.ADMIN.value,
    UserTypes.SALES_MANAGER.value,
  ].includes(userType);

  const [cancelAmendmentModal, setCancelAmendmentModal] = useState(false);
  const [cancelDraftAmendmentModal, setCancelDraftAmendmentModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [isDataLoaded, setIsDataloaded] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const [tabValue, setTabValue] = useState(0);
  const [contractStatus, setContractStatus] = useState(null);
  const [zoneObj, setZoneObj] = useState({});
  const [updateList, setUpdateList] = useState(false);
  const [refershData, setRefershData] = useState(false);

  const [contractValueError, setContractValueError] = useState(false);
  const [contractZoneData, setContractZoneData] = useState([]);
  const [agentPurhcasedZones, setAgentPurhcasedZones] = useState([]);
  const [activeContractValues, setActiveContractValues] = useState({
    subTotal: 0,
    discount: 0,
    total: 0,
    subType: 1,
    subStartDate: new Date(subStart.setDate(subStart.getDate() + 14)).toDateString(),
    contractExpiryDate: new Date(contractExp.setDate(contractExp.getDate() + 30)).toDateString(),
  });
  const [draftAmendmentValues, setDraftAmendmentValues] = useState({
    id: 0,
    subTotal: 0,
    discount: 0,
    total: 0,
    subType: 1,
    subStartDate: new Date(subStart.setDate(subStart.getDate() + 14)),
    contractExpiryDate: new Date(contractExp.setDate(contractExp.getDate() + 7)),
  });

  const [agentDetails, setAgentDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    location: '',
    draftAmendment: 0,
  });

  const [contractNotes, setContractNotes] = useState('');
  const [activeContractNotes, setActiveContractNotes] = useState('');
  const hasCancellationRights = [UserTypes.SUPER_ADMIN.value, UserTypes.ADMIN.value].includes(
    userType
  );

  const [isCancellationScheduled, setIsCancellationScheduled] = useState(false);
  const [isImmediateCancelReq, setIsmmediateCancelReq] = useState(false);
  const [isShowErrorDialog, setIsShowErrorDialog] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const doSendAmendment = () => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    setProcessing(true);
    const contractExpTmp = new Date();

    const payload = {
      contractDetails: {
        ...draftAmendmentValues,
        contractExpiryDate:
          draftAmendmentValues.contractExpiryDate ||
          new Date(contractExpTmp.setDate(contractExpTmp.getDate() + 30)),
        contractNotes,
      },
      contractZoneData: [...agentPurhcasedZones],
      agentId,
    };

    sendAmendment(payload)
      .then((res) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: res.data.contractMessage,
          type: res.data.contractStatus ? 'success' : 'error',
          show: true,
        });
        setProcessing(false);
        setRefershData(!refershData);
      })
      .catch(({ response }) => {
        if (response.data.message === 'overdue') {
          setIsShowErrorDialog(true);
        } else {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.data.message,
            type: 'error',
            show: true,
          });
        }

        setProcessing(false);
      });
  };

  const openCancelAmendmentModal = () => {
    setCancelAmendmentModal(true);
  };

  const openCancelDraftAmendmentModal = () => {
    setCancelDraftAmendmentModal(true);
  };

  useEffect(() => {
    listAll(null, null, zoneType).then((item) => {
      const tmpzoneObj = {};
      item.data.rows.forEach((zone) => {
        tmpzoneObj[zone.id] = zone.name;
      });
      setZoneObj(tmpzoneObj);
    });
  }, []);

  useEffect(() => {
    setProcessing(true);
    setIsDataloaded(true);
    viewDraftAmendment(agentId)
      .then((res) => {
        if (res.data.agentDetails) {
          setAgentDetails(res.data.agentDetails);
        }
        const contractStatusSimilarToCancelled = ['cancelled', 'terminated', 'expired'];

        if (
          res.data.activeContract &&
          !contractStatusSimilarToCancelled.includes(res.data.activeContract?.status || '')
        ) {
          setActiveContractValues({
            subTotal: res.data.activeContract.subTotal,
            discount: res.data.activeContract.discount,
            total: res.data.activeContract.total,
            subType: res.data.activeContract.subType,
            subStartDate: res.data.activeContract.subStartDate,
            contractExpiryDate: res.data.activeContract.contractExpiryDate,
          });
          setActiveContractNotes(res.data.activeContract.contractNotes || '');
          setIsCancellationScheduled(res.data.activeContract.isCancellationScheduled);
          setIsmmediateCancelReq(res.data.activeContract?.isImmediateCancelReq);
          const tmpExpDate = new Date();

          if (res.data.draftContractZoneItems) {
            setDraftAmendmentValues({
              ...res.data.draftAmendment,
              contractExpiryDate:
                res.data.draftAmendment.status !== contractStatusValue.DRAFT
                  ? res.data.draftAmendment.contractExpiryDate
                  : new Date(tmpExpDate.setDate(tmpExpDate.getDate() + 7)),
            });
            setContractNotes(res.data.draftAmendment.contractNotes || '');
            const agentPurchasedZones = res.data.draftContractZoneItems.map((item) => ({
              id: item.id,
              purchasedZone: item.zoneId,
              exclusivity: item.purchaseType === 'sold' ? 1 : 2,
              paymentStatus: 2,
              nrpFee: item.price,
            }));
            setAgentPurhcasedZones(agentPurchasedZones);
          } else {
            reloadAgentData();
          }
          const activeContractZones = res.data.activeContract.contractZones.map((item) => ({
            id: item.id,
            purchasedZone: item.zoneId,
            exclusivity: item.purchaseType === 'sold' ? 1 : 2,
            paymentStatus: item.paymentStatus === 'hold' ? 2 : 1,
            nrpFee: item.price,
          }));

          setContractZoneData(activeContractZones);
          setContractStatus(contractStatusValue[contractStatusValue.COMPLETED]);
          setProcessing(false);
          setIsDataloaded(false);
        }
      })
      .catch(() => {
        reloadAgentData();
      });
  }, [refershData]);

  // only show when it is prepare amendment tab with no cancellation request
  const canShowNotesPopup = tabValue === 0 && !isImmediateCancelReq && !isCancellationScheduled;

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>
        {draftAmendmentValues.status === 0 ? 'Prepare Amendment' : 'Amendment'}
      </DialogTitle>
      <DialogContent className={`${classes.dialogContent} ${classes.dialogContentNoTopPadding}`}>
        {isDataLoaded ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Paper elevation={0}>
              <Tabs value={tabValue} aria-label="agent tabs" indicatorColor="primary">
                <Tab
                  label={draftAmendmentValues.status === 0 ? 'Prepare Amendment' : 'Amendment'}
                  {...a11yProps(0)}
                  onClick={() => {
                    handleTabChange(null, 0);
                  }}
                />
                <Tab
                  label="Active Contract"
                  {...a11yProps(1)}
                  onClick={() => {
                    handleTabChange(null, 1);
                  }}
                />
                {hasCancellationRights && !isImmediateCancelReq && (
                  <Tab
                    label="Cancel Subscription"
                    {...a11yProps(2)}
                    onClick={() => {
                      handleTabChange(null, 2);
                    }}
                  />
                )}
              </Tabs>
            </Paper>
            <TabPanel value={tabValue} index={0}>
              {draftAmendmentValues.status === contractStatusValue.DRAFT ? (
                <PrepareContract
                  fromAmendContract
                  hasAmendContractAccess={hasAmendContractAccess}
                  setContractZoneData={setAgentPurhcasedZones}
                  contractZoneData={agentPurhcasedZones}
                  updateList={updateList}
                  setUpdateList={setUpdateList}
                  zoneArr={zoneObj}
                  agentDetails={{ ...agentDetails, agentId }}
                  setContractValues={setDraftAmendmentValues}
                  contractValues={draftAmendmentValues}
                  refershData={refershData}
                  setRefershData={setRefershData}
                  contractValueError={setContractValueError}
                  contractNotes={contractNotes}
                  setContractNotes={setContractNotes}
                  canShowNotesPopup={canShowNotesPopup}
                  zoneType={zoneType}
                />
              ) : (
                <ViewContract
                  setContractZoneData={setContractZoneData}
                  contractZoneData={agentPurhcasedZones}
                  updateList={updateList}
                  setUpdateList={setUpdateList}
                  zoneArr={zoneObj}
                  agentDetails={agentDetails}
                  setContractValues={setDraftAmendmentValues}
                  contractValues={draftAmendmentValues}
                  contractStatus={contractStatusValue[draftAmendmentValues.status]}
                  contractNotes={contractNotes}
                />
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <ViewContract
                setContractZoneData={setContractZoneData}
                contractZoneData={contractZoneData}
                updateList={updateList}
                setUpdateList={setUpdateList}
                zoneArr={zoneObj}
                agentDetails={agentDetails}
                setContractValues={setActiveContractValues}
                contractValues={activeContractValues}
                contractStatus={contractStatus}
                contractNotes={activeContractNotes}
              />
            </TabPanel>
            {hasCancellationRights && !isImmediateCancelReq && (
              <TabPanel value={tabValue} index={2}>
                <CancelSubscriptionTab
                  agentId={agentId}
                  refreshModalData={() => setRefershData(!refershData)}
                  closeModal={closeModal}
                  onImmediateContractReq={() => {
                    handleTabChange(null, 0);
                    setRefershData(!refershData);
                  }}
                />
              </TabPanel>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <>
          {hasAmendContractAccess && tabValue === 0 && draftAmendmentValues.status === 0 && (
            <>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                type="submit"
                disabled={
                  processing ||
                  agentPurhcasedZones.length === 0 ||
                  contractValueError ||
                  (draftAmendmentValues.status === 0 &&
                    draftAmendmentValues.subType === 1 &&
                    !contractNotes) ||
                  isCancellationScheduled ||
                  isImmediateCancelReq
                }
                onClick={doSendAmendment}
                className={classes2.addAgentFormBtn}
              >
                Send
              </Button>
              <Button
                variant="contained"
                disableElevation
                onClick={openCancelDraftAmendmentModal}
                disabled={processing}
                form="amend-contract"
                className={classes2.addAgentFormBtn}
              >
                Cancel Draft
              </Button>
            </>
          )}
          {hasAmendContractAccess &&
            tabValue === 0 &&
            draftAmendmentValues.id > 0 &&
            draftAmendmentValues.status >= contractStatusValue.SENT &&
            draftAmendmentValues.status !== contractStatusValue.COMPLETED && (
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                onClick={openCancelAmendmentModal}
              >
                Cancel Amendment
              </Button>
            )}
          <Button variant="contained" onClick={closeModal} disableElevation>
            Close
          </Button>
        </>
      </DialogActions>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      {cancelAmendmentModal && (
        <CancelContract
          handleDialog={() => {
            setCancelAmendmentModal(false);
          }}
          onSuccess={() => {
            onCancelAmendment();
          }}
          forAmendment
          contractId={draftAmendmentValues.id}
        />
      )}
      {cancelDraftAmendmentModal && (
        <CancelDraftAmendmentDialog
          open={cancelDraftAmendmentModal}
          handleDialog={() => {
            setCancelDraftAmendmentModal(false);
          }}
          draftAmendmentId={draftAmendmentValues.id || 0}
          onCancelAmendment={onCancelAmendment}
        />
      )}
      {isShowErrorDialog && (
        <AmendContractOverdueDialog
          handleDialog={() => {
            setIsShowErrorDialog(false);
            setProcessing(false);
          }}
        />
      )}
    </Dialog>
  );
};

AmendContractDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  agentId: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  onCancelAmendment: PropTypes.func.isRequired,
  reloadAgentData: PropTypes.func.isRequired,
  zoneType: PropTypes.number.isRequired,
};

const CancelDraftAmendmentDialog = ({
  handleDialog,
  open,
  draftAmendmentId,
  onCancelAmendment,
}) => {
  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const doCancelDraftAmendment = () => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    setProcessing(true);
    cancelAmendment(draftAmendmentId, { reason: 'Cancelled draft amendment.' })
      .then(() => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'Cancelled successfully.',
          type: 'success',
          show: true,
        });
        setProcessing(false);
        onCancelAmendment();
      })
      .catch((e) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: e.response.data.message,
          type: 'error',
          show: true,
        });
        setProcessing(false);
      });
  };

  return (
    <Dialog open={open} onClose={handleDialog}>
      <DialogTitle id="alert-dialog-title">Cancel draft amendment</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure want to cancel the draft ? This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={doCancelDraftAmendment}
          color="secondary"
          autoFocus
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing}
        >
          YES
        </Button>
        <Button onClick={handleDialog} color="primary">
          NO
        </Button>
      </DialogActions>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

CancelDraftAmendmentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func.isRequired,
  draftAmendmentId: PropTypes.number.isRequired,
  onCancelAmendment: PropTypes.func.isRequired,
};

export default AmendContractDialog;
