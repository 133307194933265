import green from '@material-ui/core/colors/green';

const drawerWidth = 250;

// Style for menubar and sidebar
const Style = (theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'white',
    color: green[600],
    boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.5)',
    display: 'flex',
    flexDirection: 'column',
  },
  mainAppBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  menuButton: {
    marginRight: '5px',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
  userMenu: {
    padding: 5,
    marginRight: 15,
    '& .MuiIconButton-label': {
      fontSize: '1rem',
    },
    '& .MuiSvgIcon-root': {
      marginRight: 5,
    },
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '&:focus': {
      backgroundColor: 'inherit',
    },
  },
  userOptions: {
    '& .MuiList-root': {
      width: 160,
    },
  },
  userProfile: {
    marginRight: 10,
    height: '30px',
    width: '30px',
    '& .MuiSvgIcon-root': {
      margin: '0px',
    },
  },
  logo: {
    width: '100px',
    marginTop: 10,
    marginBottom: 10,
  },
  topBar: {
    backgroundColor: 'rgba(0,0,0,0.85)',
    width: '100%',
    color: '#fff',
    zIndex: 99999,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2px 4px',
    alignItems: 'center',
  },
  paperWithTopBarStyle: {
    top: '40px !important',
  },

  adminViewBtn: {
    color: '#fff',
    textTransform: 'none',
  },
});

export default Style;
