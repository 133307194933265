import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ErrorWrapper from '../../layout/Error';
import ErrorStyle from '../../theme/styles/Error';
import isPageInIframe from '../../utils/isPageInIframe';
import { updataContractStatus } from '../../services/Contract';
import { contractStatusValue } from '../../config/DataValues';

const useStyles = makeStyles(ErrorStyle);

const ContractCancelledFrame = () => {
  const classes = useStyles();
  const { token } = useParams();

  useEffect(() => {
    const doCheck = isPageInIframe();
    if (doCheck) {
      const data = {
        token,
        status: contractStatusValue.CANCELLED,
      };
      updataContractStatus(data)
        .then(() => {
          window.parent.location.reload();
        })
        .catch(() => window.parent.location.reload());
    }
  }, []);

  return (
    <ErrorWrapper>
      <Grid container spacing={0} align="center" justify="center" direction="column">
        <Container maxWidth="xs">
          <Container component="div" className={classes.errorPageText}>
            <Typography component="h1" variant="h1">
              Cancelled
            </Typography>
            <Typography component="p">
              Looks like you&apos;ve cancelled the agreement/contract.
            </Typography>
            <Typography component="p">
              Do not close the window, the page will redirect you to the overview page.
            </Typography>
          </Container>
        </Container>
      </Grid>
    </ErrorWrapper>
  );
};

export default ContractCancelledFrame;
