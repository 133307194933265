import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import VideoPlayer from 'react-video-js-player';

const ViewAgentContent = ({
  topic,
  imageURL,
  videoURL,
  contentType,
  agentAsset,
  proposedText,
  showAgentAssetOverlay,
  onClose,
}) => {
  let urlToPreview = '';
  if (contentType === 'image') {
    urlToPreview = imageURL;
  } else if (contentType === 'video') {
    urlToPreview = videoURL;
  }

  return (
    <>
      <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
        <DialogTitle>{topic}</DialogTitle>
        <DialogContent>
          <Box>{proposedText}</Box>
          <br />
          <div style={{ position: 'relative', width: '500px', margin: '0px auto' }}>
            {contentType === 'image' && <img src={urlToPreview} alt="" style={{ width: '100%' }} />}

            {contentType === 'video' && (
              <>
                <VideoPlayer
                  controls
                  src={urlToPreview}
                  width="500"
                  config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                />

                <Box justifyContent="center" display="flex">
                  <div style={{ marginTop: 15, marginBottom: 15 }}>
                    Note: Some video preview may not work in browser.
                  </div>
                </Box>
              </>
            )}

            {agentAsset && showAgentAssetOverlay && urlToPreview && (
              <>
                <Box
                  style={{
                    position: 'absolute',
                    bottom: '20px',
                    left: 0,
                    width: '100%',
                    height: '90px',
                    textAlign: 'center',
                  }}
                >
                  <img src={agentAsset} alt="" height={90} />
                </Box>
              </>
            )}
          </div>
          <br />
        </DialogContent>
        <DialogActions>
          <Button disableElevation color="default" variant="contained" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ViewAgentContent.propTypes = {
  topic: PropTypes.string.isRequired,
  imageURL: PropTypes.string.isRequired,
  videoURL: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  agentAsset: PropTypes.string.isRequired,
  proposedText: PropTypes.string.isRequired,
  showAgentAssetOverlay: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ViewAgentContent;
