import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import { isFuture } from 'date-fns';
import { ArrowDropDown } from '@material-ui/icons';
import formInput from '../../theme/styles/FormInput';
import Validations from '../../utils/Validations';
import SnackbarMessage from '../SnackbarMessage';
import { createSubmission, updateSubmission, viewSubmissionById } from '../../services/Approvals';
import { createBlog, updateBlog, viewBlogById } from '../../services/Blogs';
import SubmissionVideoUpload from './SubmissionVideoUpload';
import ViewVideo from './ViewVideo';
import { formatSubmissionDate, formatSubmissionDateEOD } from '../../utils/Datetime';

const useStyles = makeStyles(formInput);
const FormDialog = ({ from, onClose, editId, onSuccess }) => {
  const classes = useStyles();

  const [isPublished, setIsPublished] = useState(false);
  const [publishedDateError, setPublishedDateError] = useState('');
  const [openDateError, setOpenDateError] = useState('');
  const [closeDateError, setCloseDateError] = useState('');
  const [processing, setProcessing] = useState(false);
  const [standardVideoUrl, setStandardVideoUrl] = useState('');
  const [squareVideoUrl, setSquareVideoUrl] = useState('');
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [viewModel, setViewModel] = useState(false);
  const [viewUrl, setViewUrl] = useState('');

  const { handleSubmit, control, setValue, reset, getValues, watch } = useForm();

  useState(() => {
    if (editId) {
      if (from === 'submission') {
        viewSubmissionById(editId).then((res) => {
          const { data } = res;
          setValue('title', data.title);
          setValue('description', data.description);
          setValue('submissionLimit', data.submission);
          setValue('status', data.status);
          if (data.status === 4) {
            setIsPublished(true);
          }
          if (data.openDate) {
            setValue('openDate', new Date(`${data.openDate} 00:00:00`));
          } else {
            setValue('openDate', null);
          }
          if (data.closeDate) {
            setValue('closeDate', new Date(`${data.closeDate} 23:59:00`));
          } else {
            setValue('closeDate', null);
          }
          if (data.publishedDate) {
            setValue('publishedDate', new Date(`${data.publishedDate} 00:00:00`));
          } else {
            setValue('publishedDate', null);
          }
          setValue('viewLink', data.viewLink);
          setStandardVideoUrl(data.standardVideoUrl);
          setSquareVideoUrl(data.squareVideoUrl);
        });
      } else {
        viewBlogById(editId).then((res) => {
          const { data } = res;
          setValue('title', data.title);
          setValue('description', data.description);
          setValue('blogLimit', data.submission);
          setValue('status', data.status);
          if (data.status === 4) {
            setIsPublished(true);
          }
          if (data.openDate) {
            setValue('openDate', new Date(`${data.openDate} 00:00:00`));
          } else {
            setValue('openDate', null);
          }
          if (data.closeDate) {
            setValue('closeDate', new Date(`${data.closeDate} 23:59:00`));
          } else {
            setValue('closeDate', null);
          }
          if (data.publishedDate) {
            setValue('publishedDate', new Date(`${data.publishedDate} 00:00:00`));
          } else {
            setValue('publishedDate', null);
          }
          setValue('guidelineLink', data.viewLink);
          setStandardVideoUrl(data.standardVideoUrl);
          setSquareVideoUrl(data.squareVideoUrl);
        });
      }
    } else {
      if (from !== 'submission') {
        setValue('blogLimit', 1);
      }

      setValue('openDate', new Date());
    }
  }, []);

  const submitForm = (data) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    setProcessing(true);
    const publishedDate = data.publishedDate ? formatSubmissionDate(data.publishedDate) : null;
    const payload = {
      title: data.title,
      description: data.description,
      openDate: data.openDate ? formatSubmissionDate(data.openDate) : null,
      closeDate: data.closeDate ? formatSubmissionDateEOD(data.closeDate) : null,
      status: Number(data.status),
      publishedDate: Number(data.status) === 4 ? publishedDate : null,
      viewLink: from === 'submission' ? data.viewLink : data.guidelineLink,
    };
    if (from === 'submission') {
      payload.submissionLimit = Number(data.submissionLimit);
      payload.standardVideoUrl = standardVideoUrl;
      payload.squareVideoUrl = squareVideoUrl;
    } else {
      payload.blogLimit = Number(data.blogLimit);
    }
    if (!editId) {
      if (from === 'submission') {
        createSubmission(payload)
          .then(() => {
            setProcessing(false);
            reset();
            onSuccess();
            onClose();
          })
          .catch(({ response }) => {
            setProcessing(false);
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: response.data.message,
              type: 'error',
              show: true,
            });
            setProcessing(false);
          });
      } else {
        createBlog(payload)
          .then(() => {
            setProcessing(false);
            reset();
            onSuccess();
            onClose();
          })
          .catch(({ response }) => {
            setProcessing(false);
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: response.data.message,
              type: 'error',
              show: true,
            });
            setProcessing(false);
          });
      }
    } else if (from === 'submission') {
      updateSubmission(editId, payload)
        .then(() => {
          setProcessing(false);
          reset();
          onSuccess();
          onClose();
        })
        .catch(({ response }) => {
          setProcessing(false);
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.data.message,
            type: 'error',
            show: true,
          });
          setProcessing(false);
        });
    } else {
      updateBlog(editId, payload)
        .then(() => {
          setProcessing(false);
          reset();
          onSuccess();
          onClose();
        })
        .catch(({ response }) => {
          setProcessing(false);
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.data.message,
            type: 'error',
            show: true,
          });
          setProcessing(false);
        });
    }
  };

  const closeDate = watch('closeDate');
  const status = watch('status');

  const onChangeStatus = (e) => {
    if (Number(e.target.value) === 4) {
      setIsPublished(true);
      setValue('publishedDate', new Date());
    } else if (Number(e.target.value) === 3 && !closeDate) {
      setValue('closeDate', new Date());
      setPublishedDateError('');
      setIsPublished(false);
    } else if (Number(e.target.value) === 1 || Number(e.target.value) === 2) {
      setPublishedDateError('');
      setIsPublished(false);
    } else {
      setPublishedDateError('');
      setIsPublished(false);
    }
  };

  const openDateChanged = (v) => {
    if (isFuture(new Date(v))) {
      setValue('status', 1);
      setIsPublished(false);
    } else {
      setValue('status', 2);
      setIsPublished(false);
    }
  };

  const handleAction = (slug, action) => {
    if (action === 'delete') {
      if (slug === 'standard') {
        setStandardVideoUrl('');
      } else if (slug === 'square') {
        setSquareVideoUrl('');
      }
    } else if (action === 'view') {
      if (slug === 'standard') {
        setViewUrl(standardVideoUrl);
      } else if (slug === 'square') {
        setViewUrl(squareVideoUrl);
      }

      setViewModel(true);
    }
  };

  const statusHelperText = () => {
    if (Number(status) !== 4) {
      return 'If open date or close date is set to past date, then status will be changed as per the dates automatically.';
    }
    return '';
  };

  const defaultFormValues = getValues();

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>
        {editId ? `Edit` : `Add New`} {from === 'submission' ? 'Submission' : 'Blog'}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(submitForm)} id="submission-form">
          <Controller
            control={control}
            rules={{ ...Validations.REQUIRED }}
            name="title"
            id="title"
            defaultValue={defaultFormValues.title || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label={from === 'submission' ? 'Prompt Title*' : 'Blog Title*'}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
                autoFocus
              />
            )}
          />
          <Controller
            control={control}
            name="description"
            id="description"
            defaultValue={defaultFormValues.description || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label={from === 'submission' ? 'Blog Description' : 'Blog Description'}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
                multiline
                rows={5}
              />
            )}
          />
          <Controller
            control={control}
            name={from === 'submission' ? 'submissionLimit' : 'blogLimit'}
            id={from === 'submission' ? 'submissionLimit' : 'blogLimit'}
            defaultValue={
              (from === 'submission'
                ? defaultFormValues.submissionLimit
                : defaultFormValues.blogLimit) || 0
            }
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label={from === 'submission' ? 'Submission Limit' : 'Blog Limit'}
                inputProps={{
                  min: 0,
                }}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={
                  // eslint-disable-next-line no-nested-ternary
                  error
                    ? error.message
                    : from === 'submission'
                    ? 'Set 0 for no limit on video submission.'
                    : ''
                }
                className={classes.formInput}
                fullWidth
                type="number"
                disabled={from === 'blog'}
              />
            )}
          />
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="openDate"
            id="openDate"
            defaultValue={defaultFormValues.openDate}
            render={({ field: { onChange, value } }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label="Open Date"
                  format="MM/dd/yyyy"
                  autoOk
                  clearable
                  onChange={(e) => {
                    setOpenDateError('');
                    if (e && e.toString() !== 'Invalid Date') {
                      const val = formatSubmissionDate(e);
                      onChange(e);
                      openDateChanged(val);
                    } else {
                      onChange(e);
                      if (!e) {
                        setOpenDateError('This field is required.');
                      } else {
                        setOpenDateError('Invalid Date.');
                      }
                    }
                  }}
                  inputVariant="outlined"
                  error={openDateError !== ''}
                  helperText={openDateError}
                  className={classes.formInput}
                  value={value}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
          />
          <Controller
            control={control}
            rules={{
              required: {
                value: Number(status) === 3,
                message: 'This field is required.',
              },
            }}
            name="closeDate"
            id="closeDate"
            defaultValue={defaultFormValues.closeDate || null}
            render={({ field: { onChange, value } }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label="Close Date"
                  format="MM/dd/yyyy"
                  autoOk
                  clearable
                  onChange={(e) => {
                    setCloseDateError('');
                    if ((!e || (e && e.toString() === 'Invalid Date')) && Number(status) === 3) {
                      if (!e) {
                        setCloseDateError('This field is required.');
                      } else {
                        setCloseDateError('Invalid Date.');
                      }
                    }
                    onChange(e);
                  }}
                  inputVariant="outlined"
                  error={closeDateError !== ''}
                  helperText={closeDateError}
                  className={classes.formInput}
                  value={value}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
          />
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="status"
            id="status"
            defaultValue={defaultFormValues.status || 2}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Status"
                value={value}
                onChange={(e) => {
                  onChange(e);
                  onChangeStatus(e);
                }}
                error={!!error}
                helperText={error ? error.message : statusHelperText()}
                className={classes.formInput}
                SelectProps={{
                  native: true,
                }}
                fullWidth
                select
              >
                <option key={1} value={1}>
                  Scheduled
                </option>
                <option key={2} value={2}>
                  Open
                </option>
                <option key={3} value={3}>
                  Closed
                </option>
                <option key={4} value={4}>
                  Published
                </option>
              </TextField>
            )}
          />
          {isPublished && (
            <Controller
              control={control}
              name="publishedDate"
              id="publishedDate"
              defaultValue={defaultFormValues.publishedDate || new Date()}
              render={({ field: { onChange, value } }) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    label="Publish Date"
                    format="MM/dd/yyyy"
                    autoOk
                    clearable
                    onChange={(e) => {
                      setPublishedDateError('');
                      if ((!e || (e && e.toString() === 'Invalid Date')) && Number(status) === 4) {
                        onChange(e);
                        if (!e) {
                          setPublishedDateError('This field is required.');
                        } else {
                          setPublishedDateError('Invalid Date.');
                        }
                      }
                      onChange(e);
                    }}
                    inputVariant="outlined"
                    error={publishedDateError !== ''}
                    helperText={publishedDateError}
                    className={classes.formInput}
                    value={value}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              )}
            />
          )}
          <Controller
            control={control}
            rules={Validations.OPTIONAL_URL}
            name={from === 'submission' ? 'viewLink' : 'guidelineLink'}
            id={from === 'submission' ? 'viewLink' : 'guidelineLink'}
            defaultValue={defaultFormValues.viewLink || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label={from === 'submission' ? 'View Link' : 'Guideline Link'}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
              />
            )}
          />
          {editId > 0 && !standardVideoUrl && from === 'submission' && (
            <SubmissionVideoUpload
              title="Upload Standard Video"
              submissionId={editId}
              onSuccess={(url) => {
                setStandardVideoUrl(url);
              }}
              slug="standard"
            />
          )}
          {standardVideoUrl && from === 'submission' && (
            <UploadMenu handleAction={handleAction} title="Manage Standard Video" slug="standard" />
          )}
          {editId > 0 && !squareVideoUrl && from === 'submission' && (
            <SubmissionVideoUpload
              title="Upload Square Video"
              submissionId={editId}
              onSuccess={(url) => {
                setSquareVideoUrl(url);
              }}
              slug="square"
            />
          )}
          {squareVideoUrl && from === 'submission' && (
            <UploadMenu handleAction={handleAction} title="Manage Square Video" slug="square" />
          )}
          <input type="file" name="video" accept="video/*" style={{ display: 'none' }} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          type="submit"
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing || publishedDateError || openDateError || closeDateError}
          form="submission-form"
        >
          Submit
        </Button>

        <Button variant="contained" disableElevation onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      {viewModel && (
        <ViewVideo
          handleClose={() => {
            setViewModel(false);
            setViewUrl('');
          }}
          url={viewUrl}
        />
      )}
    </Dialog>
  );
};

const UploadMenu = ({ handleAction, title, slug }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptClick = (action) => {
    handleAction(slug, action);
    handleClose();
  };

  return (
    <Box mb={2}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<ArrowDropDown />}
        fullWidth
        variant="outlined"
      >
        {title}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleOptClick('view')}>View</MenuItem>
        <MenuItem onClick={() => handleOptClick('delete')}>Delete</MenuItem>
      </Menu>
    </Box>
  );
};

UploadMenu.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  handleAction: PropTypes.func.isRequired,
};

FormDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  editId: PropTypes.number,
  from: PropTypes.string.isRequired,
};
FormDialog.defaultProps = {
  editId: 0,
};
export default FormDialog;
