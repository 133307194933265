import { deleteApiCall, getApiCall, postApiCall } from '../utils/Api';

const listAll = async (page, params) => {
  const result = await getApiCall(`/zipcode/list?page=${page}${params ? `&${params}` : ''}`);
  return result.data;
};

export const addZipCode = async (data) => {
  const result = await postApiCall(`/zipcode/create`, data);
  return result.data;
};

export const removeZipCode = async (id) => {
  const result = await deleteApiCall(`/zipcode/${id}/remove`);
  return result.data;
};

export const checkZipCodeAvailability = async (code) => {
  const result = await getApiCall(`/zipcode/${code}/check-availability`);
  return result.data;
};

export default listAll;
