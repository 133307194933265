import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Radio,
  FormControlLabel,
  RadioGroup,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import VideoPlayer from 'react-video-js-player';
import { CloudUpload, ArrowDropDown } from '@material-ui/icons';
import ReactS3Uploader from 'react-s3-uploader';
import { withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import React, { useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import formInput from '../../theme/styles/FormInput';
import Validations, { MaxLengthValidationFunc } from '../../utils/Validations';
import SnackbarMessage from '../SnackbarMessage';
import {
  addNewContent,
  viewById,
  updateContent,
  getContentSignedUrl,
} from '../../services/ContentEngine';

const useStyles = makeStyles(formInput);

const FormDialog = ({ from, onClose, editId, onSuccess }) => {
  const classes = useStyles();
  const hiddenFileInput = useRef(null);
  const [processing, setProcessing] = useState(false);
  const [selectedValue, setSelectedValue] = useState('image');
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [progress, setProgress] = useState(0);
  const [fileSelected, setFileSelected] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const [imageUrl, setImageURL] = useState('');
  const [videoUrl, setVideoURL] = useState('');
  const [contentError, setContentError] = useState('');

  const { handleSubmit, control, setValue, reset, getValues } = useForm();

  const GreenRadio = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  useState(() => {
    if (editId) {
      viewById(editId).then((res) => {
        const { data } = res;
        setSelectedValue(data.contentType);
        setImageURL(data.imageURL);
        setVideoURL(data.videoURL);
        setSelectedImageUrl(data.imageURL);
        setSelectedVideoUrl(data.videoURL);
        setValue('topic', data.topic);
        setValue('socmedChannel', data.socmedChannel);
        setValue('captionText', data.captionText);
        setValue('status', data.status);

        const offset = new Date().getTimezoneOffset();
        const scheduleDate = new Date(
          new Date(data.scheduleDate).setSeconds(
            new Date(data.scheduleDate).getSeconds() + offset * 60 - 5 * 60 * 60
          )
        );

        setValue('postDate', scheduleDate);
      });
    }
  }, []);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const submitForm = (data) => {
    if (selectedImageUrl || selectedVideoUrl) {
      setSnackbarMeesage({
        ...snackbarMeesage,
        message: '',
        type: '',
        show: false,
      });
      setProcessing(true);
      const offset = new Date().getTimezoneOffset();
      const postDate = new Date(
        new Date(data.postDate).setSeconds(
          new Date(data.postDate).getSeconds() + -1 * offset * 60 + 5 * 60 * 60
        )
      );
      const payload = {
        topic: data.topic,
        status: data.status,
        socmedChannel: data.socmedChannel,
        contentType: selectedValue,
        imageURL: selectedValue === 'image' ? selectedImageUrl : '',
        videoURL: selectedValue === 'video' ? selectedVideoUrl : '',
        captionText: data.captionText,
        scheduleDate: postDate,
      };
      if (editId) {
        updateContent(editId, payload)
          .then(() => {
            setProcessing(false);
            reset();
            onSuccess();
            onClose();
          })
          .catch((res) => {
            setProcessing(false);
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: res.data.message,
              type: 'error',
              show: true,
            });
            setProcessing(false);
          });
      } else {
        addNewContent(payload)
          .then(() => {
            setProcessing(false);
            reset();
            onSuccess();
            onClose();
          })
          .catch((res) => {
            setProcessing(false);
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: res.data.message,
              type: 'error',
              show: true,
            });
            setProcessing(false);
          });
      }
    } else {
      setContentError('Please select image or video.');
      setTimeout(() => {
        setContentError('');
      }, 3000);
    }
  };

  const handleChange = (event) => {
    if (event.target.value === 'image') {
      setVideoURL('');
      setSelectedVideoUrl('');
    } else {
      setImageURL('');
      setSelectedImageUrl('');
    }
    setSelectedValue(event.target.value);
  };

  const onProgress = (p) => {
    setProgress(p);
  };
  const onError = (error) => {
    setHasError(true);
    setErrorMessage(error);
  };
  const onFinish = (data) => {
    setProgress(0);

    if (selectedValue === 'image') {
      setImageURL(data.publicUrl);
      setSelectedImageUrl(data.publicUrl);
    } else {
      setVideoURL(data.publicUrl);
      setSelectedVideoUrl(data.publicUrl);
    }
    setFileSelected(false);
  };

  const getSignedUrl = async (file, callback) => {
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });

    if (file.type.split('/')[0] === (selectedValue === 'image' ? 'image' : 'video')) {
      if (selectedValue === 'image') {
        const acceptedImages = ['image/jpeg', 'image/gif', 'image/png', 'image/jpg'];
        if (!acceptedImages.includes(file.type)) {
          setHasError(true);
          setErrorMessage('Please select image of type jpeg, jpg, png or gif file only.');
          hiddenFileInput.current.value = '';
          return;
        }
      }

      const payload = {
        objectName: file.name,
        contentType: file.type,
        module: 'content-template',
      };
      getContentSignedUrl(payload)
        .then((res) => {
          setFileSelected(true);
          callback(res.data);
        })
        .catch(() => {
          setHasError(true);
          setErrorMessage('Something went wrong.');
          hiddenFileInput.current.value = '';
        });
    } else {
      setSnackbarMeesage({
        message: `Please select valid file only.`,
        type: 'error',
        show: true,
      });
    }
  };

  const defaultFormValues = getValues();

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>
        {editId ? `Edit` : `Add New`} {from === 'Content' ? 'Content' : 'Content'}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(submitForm)} id="submission-form">
          <Controller
            control={control}
            rules={{ ...Validations.REQUIRED, ...MaxLengthValidationFunc(255) }}
            name="topic"
            id="topic"
            defaultValue={defaultFormValues.title || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Topic*"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
                autoFocus
              />
            )}
          />
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="status"
            id="status"
            defaultValue={defaultFormValues.status || 1}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Status"
                value={value}
                onChange={(e) => {
                  onChange(e);
                }}
                error={!!error}
                className={classes.formInput}
                SelectProps={{
                  native: true,
                }}
                fullWidth
                select
              >
                <option key={1} value={1}>
                  Active
                </option>
                <option key={2} value={2}>
                  Inactive
                </option>
              </TextField>
            )}
          />
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="socmedChannel"
            id="socmedChannel"
            defaultValue={defaultFormValues.status || 1}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Social Media Channel"
                value={value}
                onChange={(e) => {
                  onChange(e);
                }}
                error={!!error}
                className={classes.formInput}
                SelectProps={{
                  native: true,
                }}
                fullWidth
                select
              >
                <option key={1} value={1}>
                  Facebook
                </option>
              </TextField>
            )}
          />
          <Box mb={2}>
            <fieldset style={{ borderRadius: 5, borderWidth: 1, borderColor: '#8080806e' }}>
              <legend
                style={{
                  color: 'rgba(0, 0, 0, 0.54)',
                  padding: '0px 5px',
                  fontSize: '0.850rem',
                  fontWeight: '400',
                  lineHeight: '1',
                  letterSpacing: '0.00938em',
                }}
              >
                Content Type
              </legend>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="controlled-radio-buttons-group"
                value={selectedValue}
                onChange={handleChange}
              >
                <div>
                  {!editId && (
                    <Box>
                      <FormControlLabel
                        value="image"
                        control={<GreenRadio />}
                        label="Upload Image"
                      />
                      <br />
                      {selectedValue === 'image' && (
                        <>
                          <ReactS3Uploader
                            getSignedUrl={getSignedUrl}
                            accept=".png, .jpg, .jpeg, .gif"
                            onProgress={onProgress}
                            onError={onError}
                            onFinish={onFinish}
                            uploadRequestHeaders={{
                              'x-amz-acl': 'public-read',
                            }}
                            contentDisposition="auto"
                            inputRef={hiddenFileInput}
                            name="imageUpload"
                            style={{ width: 0, height: 0, display: 'none' }}
                          />

                          <Button
                            onClick={handleClick}
                            fullWidth
                            variant="outlined"
                            startIcon={<CloudUpload />}
                            title={errorMessage}
                          >
                            {editId && !fileSelected ? 'Change Image' : null}
                            {!fileSelected && !editId && 'Select Image'}
                            {!hasError &&
                              fileSelected &&
                              progress < 100 &&
                              `File Uploading (${progress}%)`}
                            {!hasError && fileSelected && progress === 100 && 'File Uploaded'}
                            {hasError && 'Error'}
                          </Button>
                        </>
                      )}
                    </Box>
                  )}
                  {editId && selectedValue === 'image' ? (
                    <Box mb={1}>
                      {selectedValue === 'image' && (
                        <>
                          <ReactS3Uploader
                            getSignedUrl={getSignedUrl}
                            accept=".png, .jpg, .jpeg, .gif"
                            onProgress={onProgress}
                            onError={onError}
                            onFinish={onFinish}
                            uploadRequestHeaders={{
                              'x-amz-acl': 'public-read',
                            }}
                            contentDisposition="auto"
                            inputRef={hiddenFileInput}
                            name="imageUpload"
                            style={{ width: 0, height: 0, display: 'none' }}
                          />

                          <Button
                            onClick={handleClick}
                            fullWidth
                            variant="outlined"
                            startIcon={<CloudUpload />}
                            title={errorMessage}
                          >
                            {editId && !fileSelected && 'Change Image'}
                            {!fileSelected && !editId && 'Select Image'}
                            {!hasError &&
                              fileSelected &&
                              progress < 100 &&
                              `File Uploading (${progress}%)`}
                            {!hasError && fileSelected && progress === 100 && 'File Uploaded'}
                            {hasError && 'Error'}
                          </Button>
                        </>
                      )}
                    </Box>
                  ) : null}
                </div>
                <div>
                  {editId && selectedValue === 'video' ? (
                    <Box mb={1}>
                      {selectedValue === 'video' && (
                        <>
                          <ReactS3Uploader
                            getSignedUrl={getSignedUrl}
                            accept="video/*"
                            onProgress={onProgress}
                            onError={onError}
                            onFinish={onFinish}
                            uploadRequestHeaders={{
                              'x-amz-acl': 'public-read',
                            }}
                            contentDisposition="auto"
                            inputRef={hiddenFileInput}
                            name="videoUpload"
                            style={{ width: 0, height: 0, display: 'none' }}
                          />

                          <Button
                            onClick={handleClick}
                            fullWidth
                            variant="outlined"
                            startIcon={<CloudUpload />}
                            title={errorMessage}
                          >
                            {editId && !fileSelected ? 'Change Video' : null}
                            {!fileSelected && !editId && 'Select Video'}
                            {!hasError &&
                              fileSelected &&
                              progress < 100 &&
                              `File Uploading (${progress}%)`}
                            {!hasError && fileSelected && progress === 100 && 'File Uploaded'}
                            {hasError && 'Error'}
                          </Button>
                        </>
                      )}
                    </Box>
                  ) : null}
                  {!editId && (
                    <Box mb={1}>
                      <FormControlLabel
                        value="video"
                        control={<GreenRadio />}
                        label="Upload Video"
                      />
                      <br />
                      {selectedValue === 'video' && (
                        <>
                          <ReactS3Uploader
                            getSignedUrl={getSignedUrl}
                            accept="video/*"
                            onProgress={onProgress}
                            onError={onError}
                            onFinish={onFinish}
                            uploadRequestHeaders={{
                              'x-amz-acl': 'public-read',
                            }}
                            contentDisposition="auto"
                            inputRef={hiddenFileInput}
                            name="videoUpload"
                            style={{ width: 0, height: 0, display: 'none' }}
                          />

                          <Button
                            onClick={handleClick}
                            fullWidth
                            variant="outlined"
                            startIcon={<CloudUpload />}
                            title={errorMessage}
                          >
                            {editId && !fileSelected ? 'Change Video' : null}
                            {!fileSelected && !editId && 'Select Video'}
                            {!hasError &&
                              fileSelected &&
                              progress < 100 &&
                              `File Uploading (${progress}%)`}
                            {!hasError && fileSelected && progress === 100 && 'File Uploaded'}
                            {hasError && 'Error'}
                          </Button>
                        </>
                      )}
                    </Box>
                  )}
                </div>
              </RadioGroup>
              {contentError && (
                <Box display="flex">
                  <p
                    style={{ color: '#f44336' }}
                    className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
                  >
                    {contentError}
                  </p>
                </Box>
              )}

              {imageUrl && !fileSelected && (
                <Box mt={0.5}>
                  <img
                    alt=""
                    src={imageUrl}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '150px',
                      display: 'block',
                      marginBottom: '3px',
                      objectFit: 'contain',
                    }}
                  />
                </Box>
              )}

              {videoUrl && !fileSelected && (
                <Box mt={0.5}>
                  <VideoPlayer src={videoUrl} width="240" height="150" />
                </Box>
              )}
            </fieldset>
          </Box>

          <Controller
            control={control}
            name="captionText"
            id="captionText"
            rules={{ ...Validations.REQUIRED, ...MaxLengthValidationFunc(1000) }}
            defaultValue={defaultFormValues.description || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Caption Text*"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
                multiline
                rows={5}
              />
            )}
          />
          <Controller
            control={control}
            name="postDate"
            id="postDate"
            rules={{ ...Validations.REQUIRED }}
            defaultValue={defaultFormValues.displayDate || new Date()}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  label="Post Date & Time (Eastern Timezone)"
                  format="MM/dd/yyyy h:mm a"
                  autoOk
                  clearable
                  onChange={(e) => {
                    onChange(e);
                  }}
                  inputVariant="outlined"
                  helperText={error ? error.message : null}
                  error={!!error}
                  className={classes.formInput}
                  value={value}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          type="submit"
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing}
          form="submission-form"
        >
          Submit
        </Button>

        <Button variant="contained" disableElevation onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

const UploadMenu = ({ handleAction, title, slug }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptClick = (action) => {
    handleAction(slug, action);
    handleClose();
  };

  return (
    <Box mb={2}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<ArrowDropDown />}
        fullWidth
        variant="outlined"
      >
        {title}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleOptClick('view')}>View</MenuItem>
        <MenuItem onClick={() => handleOptClick('delete')}>Delete</MenuItem>
      </Menu>
    </Box>
  );
};

UploadMenu.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  handleAction: PropTypes.func.isRequired,
};

FormDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  editId: PropTypes.number,
  from: PropTypes.string.isRequired,
};
FormDialog.defaultProps = {
  editId: 0,
};
export default FormDialog;
