import { Box, CircularProgress, Container, Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PublicWrapper from '../../layout/Public';
import logo from '../../assets/images/logo.png';
import loginStyle from '../../theme/styles/Login';
import { validateInvitationToken } from '../../services/Auth';
import CreateAccountForm from '../../components/auth/CreateAccountForm';

const useStyles = makeStyles(loginStyle);

const Invitation = () => {
  const classes = useStyles();
  const params = useParams();

  const [validaToken, setValidToken] = useState(false);
  const [processingToken, setProcessingToken] = useState(false);
  const [userData, setUserData] = useState(null);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    setValidToken(false);
    setProcessingToken(true);
    validateInvitationToken(params.token)
      .then((res) => {
        setUserData(res.userData);
        setProcessingToken(false);
        setValidToken(true);
      })
      .catch(({ response }) => {
        setErrorText(response?.data.message || '');
        setProcessingToken(false);
        setValidToken(false);
      });
  }, []);

  return (
    <PublicWrapper>
      <Grid container spacing={0} align="center" justify="center" direction="column">
        <Container maxWidth="xs">
          <img src={logo} alt={process.env.REACT_APP_NAME} className={classes.logo} />
          {processingToken && <CircularProgress />}
          {!processingToken && validaToken && (
            <Box p={3}>
              <CreateAccountForm token={params.token} userData={userData} />
            </Box>
          )}
          {!processingToken && !validaToken && <Typography variant="h6">{errorText}</Typography>}
        </Container>
      </Grid>
    </PublicWrapper>
  );
};

export default Invitation;
