/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  InputAdornment,
  makeStyles,
  Table,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { isBefore } from 'date-fns';
import { paymentFrequencies, ContractSubTypes } from '../../config/DataValues';
import ContractZoneTable from './ContractZoneTable';
import ContractNoteDialog from './ContractNoteDialog';
import contractStyle from '../../theme/styles/Contract';

const useStyles = makeStyles(contractStyle);

const EnrollContract = ({
  tempAssignedZoneData,
  zoneArr,
  contractValues,
  setContractValues,
  contractValueError,
}) => {
  const classes = useStyles();

  const requireContractNotesFor = [ContractSubTypes.MONTHLY.value];
  const [contractZoneData, setContractZoneData] = useState(tempAssignedZoneData);
  const [discountError, setDiscountError] = useState('');
  const [nextRecurringDateError, setNextRecurringDateError] = useState('');
  const [openContractNotesDialog, setOpenContractNotesDialog] = useState(
    !!(
      contractValues.contractNotes === '' &&
      requireContractNotesFor.includes(Number(contractValues.subType))
    )
  );

  useEffect(() => {
    let subT = 0;
    contractZoneData.forEach((z) => {
      subT += z.nrpFee * paymentFrequencies[contractValues.subType].multiply;
    });
    const values = {
      ...contractValues,
      subTotal: subT,
      discount: contractValues.discount,
      total: subT - contractValues.discount,
    };

    setContractValues(values);
  }, [contractZoneData]);

  useEffect(() => {
    if (contractValues.subTotal - contractValues.discount < 0 && contractValues.subTotal > 0) {
      contractValueError(true);
      setDiscountError('Invalid discount');
    }

    const regex = /[0-9.]$/;
    if (!regex.test(contractValues.discount) || contractValues.discount < 0) {
      setDiscountError('Invalid value');
      contractValueError(true);
    }

    if (!contractValues.subStartDate || !contractValues.nextRecurringDate) {
      contractValueError(true);
    }

    if (
      contractValues.subStartDate &&
      contractValues.nextRecurringDate &&
      contractValues.subTotal - contractValues.discount > 0 &&
      (contractValues.discount ? contractValues.discount >= 0 : true) &&
      regex.test(contractValues.discount)
    ) {
      contractValueError(false);
      setDiscountError('');
    }

    if (
      contractValues.subTotal - contractValues.discount > 0 &&
      (contractValues.discount ? contractValues.discount >= 0 : true) &&
      regex.test(contractValues.discount)
    ) {
      setDiscountError('');
    }

    if (contractValues.isReactivate === 1) {
      if (
        contractValues.subStartDate &&
        contractValues.nextRecurringDate &&
        isBefore(new Date(contractValues.nextRecurringDate), new Date(contractValues.subStartDate))
      ) {
        setNextRecurringDateError('Next recurring date must be greater than start date.');
      } else {
        setNextRecurringDateError('');
      }
    }
  }, [contractValues]);

  useEffect(() => {
    let subT = 0;
    contractZoneData.forEach((z) => {
      subT += z.nrpFee * paymentFrequencies[contractValues.subType].multiply;
    });

    const values = {
      ...contractValues,
      subTotal: subT,
      total: subT - contractValues.discount,
    };

    if (
      contractValues.contractNotes === '' &&
      requireContractNotesFor.includes(Number(contractValues.subType))
    ) {
      setOpenContractNotesDialog(true);
    } else {
      values.contractNotes = '';
    }

    setContractValues(values);
  }, [contractValues.subType]);

  const dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const saveContractNotes = (notes, contractSubType) => {
    setContractValues({
      ...contractValues,
      contractNotes: notes,
      subType: contractSubType,
    });

    setOpenContractNotesDialog(false);
  };

  const closeContractNotesDialog = () => {
    setOpenContractNotesDialog(false);
    // if contract type requires contract notes and
    // no contract notes are provided then change the contract type to quarterly.
    if (requireContractNotesFor.includes(contractValues.subType)) {
      setContractValues({
        ...contractValues,
        subType: ContractSubTypes.QUARTERLY.value,
      });
    }
  };

  return (
    <>
      <ContractZoneTable
        setTempAssignedZoneData={setContractZoneData}
        tempAssignedZoneData={contractZoneData}
        zoneArr={zoneArr}
        quantity={paymentFrequencies[contractValues.subType].multiply}
        allowDelete={false}
      />
      <Table>
        <TableRow>
          <TableCell width="40%">Subscription</TableCell>
          <TableCell width="20%">
            <TextField
              label=""
              defaultValue={1}
              value={contractValues.subType}
              onChange={(e) => {
                setContractValues({
                  ...contractValues,
                  subType: Number(e.target.value),
                });
              }}
              SelectProps={{
                native: true,
              }}
              fullWidth
              select
              disabled={contractValues.isReactivate === 1}
            >
              <option key={1} value={1}>
                Monthly
              </option>
              <option key={2} value={2}>
                Quarterly
              </option>
              <option key={3} value={3}>
                Bi-Annual
              </option>
              <option key={4} value={4}>
                Annual
              </option>
            </TextField>
          </TableCell>
          <TableCell width="15%">Sub Total</TableCell>
          <TableCell align="right">{dollarUS.format(contractValues.subTotal)}</TableCell>
          <TableCell width="7%" />
        </TableRow>
        <TableRow>
          <TableCell>Subscription Start Date*</TableCell>
          <TableCell>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                format="MM/dd/yyyy"
                value={contractValues.subStartDate || null}
                maxDate={contractValues.isReactivate === 1 ? null : new Date()}
                autoOk
                clearable
                onChange={(e) => {
                  setContractValues({
                    ...contractValues,
                    subStartDate: e,
                  });
                }}
                disabled={contractValues.isReactivate === 1}
              />
            </MuiPickersUtilsProvider>
          </TableCell>

          <TableCell>Discount</TableCell>
          <TableCell align="right">
            <TextField
              label=""
              value={contractValues.discount}
              className={classes.discountInput}
              onChange={(e) => {
                const { value } = e.target;
                const values = {
                  ...contractValues,
                  discount: value,
                  total: contractValues.subTotal - value,
                };
                setContractValues(values);
              }}
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              error={discountError !== ''}
              helperText={discountError}
              fullWidth
              disabled={contractValues.isReactivate === 1}
            />
          </TableCell>
          <TableCell />
        </TableRow>
        <TableRow>
          <TableCell>Next Recurring Date*</TableCell>
          <TableCell>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                format="MM/dd/yyyy"
                value={contractValues.nextRecurringDate || null}
                minDate={new Date()}
                autoOk
                clearable
                onChange={(e) => {
                  setContractValues({
                    ...contractValues,
                    nextRecurringDate: e,
                  });
                }}
                error={nextRecurringDateError !== ''}
                helperText={nextRecurringDateError}
              />
            </MuiPickersUtilsProvider>
          </TableCell>

          <TableCell>Total</TableCell>
          <TableCell align="right">{dollarUS.format(contractValues.total)}</TableCell>
          <TableCell />
        </TableRow>
      </Table>

      {contractValues.contractNotes && (
        <Box m={2}>
          Monthly Contract Notes:
          <Typography color="textSecondary" variant="p" className={classes.contractNotes}>
            {contractValues.contractNotes}
          </Typography>
        </Box>
      )}
      {openContractNotesDialog && (
        <ContractNoteDialog
          contractSubType={contractValues.subType}
          defaultContractNotes={contractValues.contractNotes}
          closeDialog={closeContractNotesDialog}
          onSave={saveContractNotes}
        />
      )}
    </>
  );
};

EnrollContract.propTypes = {
  tempAssignedZoneData: PropTypes.arrayOf().isRequired,
  zoneArr: PropTypes.arrayOf().isRequired,
  contractValues: PropTypes.objectOf().isRequired,
  setContractValues: PropTypes.func.isRequired,
  contractValueError: PropTypes.func.isRequired,
};

export default EnrollContract;
