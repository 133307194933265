import { Button, CircularProgress, makeStyles, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import RoutePaths from '../../config/Routes';
import { acceptInvitation, createAgentAccount } from '../../services/Auth';
import formInput from '../../theme/styles/FormInput';
import Validations, {
  MaxLengthValidationFunc,
  MinLengthValidationFunc,
} from '../../utils/Validations';
import SnackbarMessage from '../SnackbarMessage';

const useStyles = makeStyles(formInput);

const CreateAccountForm = ({ token, userData, isForContractPage }) => {
  const classes = useStyles();
  const history = useHistory();

  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const { handleSubmit, control } = useForm();

  const submitForm = ({ password, confirmPassword }) => {
    setProcessing(true);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    if (isForContractPage) {
      createAgentAccount({ agentId: userData.id, password, confirmPassword })
        .then(() => {
          setProcessing(false);
          history.push(RoutePaths.LOGIN);
        })
        .catch(({ response }) => {
          setProcessing(false);
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.data.message,
            type: 'error',
            show: true,
          });
          setProcessing(false);
        });
    } else {
      acceptInvitation(token, { password, confirmPassword })
        .then(() => {
          setProcessing(false);
          history.push(RoutePaths.LOGIN);
        })
        .catch(({ response }) => {
          setProcessing(false);
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.data.message,
            type: 'error',
            show: true,
          });
          setProcessing(false);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Controller
        control={control}
        name="email"
        id="email"
        defaultValue={userData.email || ''}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            type="text"
            label="Email Address"
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
            className={classes.formInput}
            fullWidth
            disabled
          />
        )}
      />
      <Controller
        control={control}
        name="fName"
        id="fName"
        defaultValue={userData.firstName || ''}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            type="text"
            label="First Name"
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
            className={classes.formInput}
            fullWidth
            disabled
          />
        )}
      />
      <Controller
        control={control}
        name="lName"
        id="lName"
        defaultValue={userData.lastName || ''}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            type="text"
            label="Last Name"
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
            className={classes.formInput}
            fullWidth
            disabled
          />
        )}
      />
      <Controller
        control={control}
        rules={{
          ...Validations.REQUIRED,
          ...MaxLengthValidationFunc(80),
          ...MinLengthValidationFunc(6),
        }}
        name="password"
        id="password"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            type="password"
            label="Password*"
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
            className={classes.formInput}
            fullWidth
            autoFocus
          />
        )}
      />
      <Controller
        control={control}
        rules={{
          ...Validations.REQUIRED,
          ...MaxLengthValidationFunc(80),
          ...MinLengthValidationFunc(6),
        }}
        name="confirmPassword"
        id="confirmPassword"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            type="password"
            label="Confirm Password*"
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
            className={classes.formInput}
            fullWidth
          />
        )}
      />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disableElevation
        endIcon={processing && <CircularProgress size={25} />}
        disabled={processing}
        fullWidth
      >
        Submit
      </Button>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </form>
  );
};

export default CreateAccountForm;

CreateAccountForm.propTypes = {
  token: PropTypes.string.isRequired,
  userData: PropTypes.objectOf().isRequired,
  isForContractPage: PropTypes.bool,
};

CreateAccountForm.defaultProps = {
  isForContractPage: false,
};
