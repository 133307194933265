/* eslint-disable react/jsx-props-no-spreading */
import { Box, Paper, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PrivateWrapper from '../../layout/Private';
import DemoLink from '../../components/sales-tools/DemoLink';
import Videos from '../../components/sales-tools/Videos';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SalesTools = () => {
  const pageName = 'Sales Tools';
  const [activeTab, setActiveTab] = useState('demolink');

  return (
    <PrivateWrapper pageName={pageName}>
      <Box component={Paper} mt={2} p={2} minHeight="80vh">
        <Box>
          <Tabs
            value={activeTab}
            indicatorColor="primary"
            onChange={(_, v) => {
              setActiveTab(v);
            }}
          >
            <Tab value="demolink" label="Demo Link" {...a11yProps(0)} />
            <Tab value="videos" label="Videos" {...a11yProps(0)} />
          </Tabs>
        </Box>
        {activeTab === 'demolink' && (
          <Box>
            <DemoLink />
          </Box>
        )}

        {activeTab === 'videos' && (
          <Box>
            <Videos />
          </Box>
        )}
      </Box>
    </PrivateWrapper>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

export default SalesTools;
