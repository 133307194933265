import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import formInput from '../../theme/styles/FormInput';
import { validateSearchZipcodesV2 } from '../../services/Widgets';
import Validations, { MaxLengthValidationFunc } from '../../utils/Validations';
import SnackbarMessage from '../SnackbarMessage';

const useStyles = makeStyles(formInput);

const FormDialog = ({ closeModel, onSuccess, zoneSearchType }) => {
  const classes = useStyles();

  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const { handleSubmit, control } = useForm();

  const handleDialog = () => {
    closeModel();
  };

  const submitForm = (data) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    setProcessing(true);

    const zipcodeArr = data.zipcodes.split(',');
    const zipcodes = [];
    zipcodeArr.forEach((v) => {
      if (v.trim()) {
        zipcodes.push(v.trim());
      }
    });

    validateSearchZipcodesV2(
      {
        option: 'Manual',
        embedSource: '',
        zipcodes,
        name: data.name,
        email: data.email,
        phone: data.phone,
      },
      zoneSearchType
    )
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'Something went wrong.',
          type: 'error',
          show: true,
        });
      });
  };

  return (
    <Dialog open onClose={handleDialog} fullWidth>
      <DialogTitle>Create Manual Entry</DialogTitle>
      <form onSubmit={handleSubmit(submitForm)}>
        <DialogContent className={classes.dialogContent}>
          <Controller
            control={control}
            name="name"
            id="name"
            rules={{ ...Validations.REQUIRED, ...MaxLengthValidationFunc(100) }}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Name*"
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
                autoFocus
              />
            )}
          />

          <Controller
            control={control}
            rules={Validations.REQUIRED_EMAIL}
            name="email"
            id="email"
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Email Address*"
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
              />
            )}
          />

          <Controller
            control={control}
            rules={{
              ...MaxLengthValidationFunc(20),
              ...{ pattern: { value: /^[0-9 )(+-]+$/, message: 'Invalid value' } },
              ...Validations.REQUIRED,
            }}
            name="phone"
            id="phone"
            defaultValue=""
            className={classes.formInput}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Phone Number*"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
              />
            )}
          />

          <Controller
            control={control}
            name="zipcodes"
            id="zipcodes"
            rules={Validations.REQUIRED}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Zip Codes (Comma Seperated)*"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            type="submit"
            endIcon={processing && <CircularProgress size={25} />}
            disabled={processing}
          >
            Submit
          </Button>

          <Button variant="contained" onClick={handleDialog} disableElevation>
            Cancel
          </Button>
        </DialogActions>
      </form>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

FormDialog.propTypes = {
  closeModel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  zoneSearchType: PropTypes.number.isRequired,
};

export default FormDialog;
