/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import VideoPlayer from 'react-video-js-player';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  DialogContentText,
  TextField,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import formInput from '../../theme/styles/FormInput';
import { getAgentTypeLabel } from '../../utils/GetModuleType';
import Validations from '../../utils/Validations';
import {
  getAgentWebStatus,
  approveAgentWebStatus,
  rejectAgentWebStatus,
  updateAgentWebProfile,
} from '../../services/Agent';
import UserTypes from '../../config/UserTypes';

const useStyles = makeStyles(formInput);

const AgentWebStatus = ({ webStatus, successCallback, cancelCallback, agentId, rowAgentType }) => {
  const classes = useStyles();
  const label = getAgentTypeLabel(rowAgentType, false, true);

  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;
  const IS_SA = UserTypes.SUPER_ADMIN.value === userType;

  const [reloadData, setReloadData] = useState(false);
  const [profileData, setProfileData] = useState({
    currentName: '',
    currentEmail: '',
    currentPhone: '',
    currentBio: '',
    currentLocation: '',
    currentProfile: '',
    currentState: '',
    currentVideo: '',
    currentLocationText: '',
    currentFacebook: '',
    currentTwitter: '',
    currentInstagram: '',
    currentYouTube: '',
    currentPinterest: '',
    currentTikTok: '',
    currentLinkedIn: '',
    currentWebsite: '',
    liveName: '',
    liveEmail: '',
    livePhone: '',
    liveBio: '',
    liveLocation: '',
    liveProfile: '',
    liveState: '',
    liveVideo: '',
    liveLocationText: '',
    liveFacebook: '',
    liveTwitter: '',
    liveInstagram: '',
    liveYouTube: '',
    livePinterest: '',
    liveTikTok: '',
    liveLinkedIn: '',
    liveWebsite: '',
  });
  const [confirmApproval, setConfirmApproval] = useState(false);
  const [confirmReject, setConfirmReject] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [processing, setProcessing] = useState(0);
  const [memberSince, setMemberSince] = useState(null);
  const [buttonText, setButtonText] = useState(null);
  const [urlSlug, setUrlSlug] = useState('');
  const [showOldVideo, setShowOldVideo] = useState(false);
  const [showNewVideo, setShowNewVideo] = useState(false);

  useEffect(() => {
    getAgentWebStatus(agentId)
      .then((res) => {
        setProfileData(res.data);
        setMemberSince(res.data.memberSince);
        setButtonText(res.data.videoButtonText);
        setUrlSlug(res.data.profileSlug);

        if (res.data.currentVideo) {
          setTimeout(() => {
            setShowOldVideo(true);
          }, 500);
        }
        if (res.data.liveVideo) {
          setTimeout(() => {
            setShowNewVideo(true);
          }, 1000);
        }
      })
      .catch(() => {
        // ignore error
      });
  }, [reloadData]);

  const resetError = (message) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage('');
    }, 2000);
  };

  const handleApproveConfirm = () => {
    setConfirmApproval(!confirmApproval);
  };
  const approveRequest = () => {
    setProcessing(1);
    handleApproveConfirm();
    const payload = {
      ...profileData,
      memberSince,
      videoButtonText: buttonText,
      profileSlug: urlSlug,
    };

    approveAgentWebStatus(agentId, payload)
      .then(() => {
        successCallback();
      })
      .catch(() => {
        setProcessing(0);
        resetError('Something went wrong. Please try again.');
      });
  };

  const handleRejectConfirm = () => {
    setConfirmReject(!confirmReject);
  };
  const rejectRequest = (notes) => {
    setProcessing(2);
    handleRejectConfirm();
    const payload = {
      ...profileData,
      notes,
    };

    rejectAgentWebStatus(agentId, payload)
      .then(() => {
        successCallback();
      })
      .catch(() => {
        setProcessing(0);
        resetError('Something went wrong. Please try again.');
      });
  };

  const updateAgentProfileDataMemberSince = () => {
    setProcessing(3);
    const payload = {
      memberSince,
      videoButtonText: profileData.videoButtonText,
    };

    updateAgentWebProfile(agentId, payload)
      .then(() => {
        setProcessing(0);
        setReloadData(!reloadData);
      })
      .catch(() => {
        setProcessing(0);
        resetError('Something went wrong. Please try again.');
      });
  };

  const updateAgentProfileDataVideoButton = () => {
    setProcessing(4);
    const payload = {
      memberSince: profileData.memberSince,
      videoButtonText: buttonText,
    };

    updateAgentWebProfile(agentId, payload)
      .then(() => {
        setProcessing(0);
        setReloadData(!reloadData);
      })
      .catch(() => {
        setProcessing(0);
        resetError('Something went wrong. Please try again.');
      });
  };

  return (
    <>
      <Dialog open onClose={cancelCallback} fullWidth maxWidth="lg">
        <DialogTitle>{label} Web Profile</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={2} style={{ backgroundColor: '#dfdfdf' }}>
              <strong>Field</strong>
            </Grid>
            <Grid item xs={5} style={{ backgroundColor: '#dfdfdf' }}>
              <strong>Pending Profile</strong>
            </Grid>
            <Grid item xs={5} style={{ backgroundColor: '#dfdfdf' }}>
              <strong>Live Profile</strong>
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={2}>
              <strong>Name</strong>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor: profileData.currentName !== profileData.liveName ? '#ddf0dd' : '',
              }}
            >
              {profileData.currentName}
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor: profileData.currentName !== profileData.liveName ? '#ddf0dd' : '',
              }}
            >
              {profileData.liveName}
            </Grid>
          </Grid>

          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={2}>
              <strong>Email</strong>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentEmail !== profileData.liveEmail ? '#ddf0dd' : '',
              }}
            >
              {profileData.currentEmail}
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentEmail !== profileData.liveEmail ? '#ddf0dd' : '',
              }}
            >
              {profileData.liveEmail}
            </Grid>
          </Grid>

          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={2}>
              <strong>Phone</strong>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentPhone !== profileData.livePhone ? '#ddf0dd' : '',
              }}
            >
              {profileData.currentPhone}
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentPhone !== profileData.livePhone ? '#ddf0dd' : '',
              }}
            >
              {profileData.livePhone}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={2}>
              <strong>Facebook</strong>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentFacebook !== profileData.liveFacebook ? '#ddf0dd' : '',
              }}
            >
              {profileData.currentFacebook}
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentFacebook !== profileData.liveFacebook ? '#ddf0dd' : '',
              }}
            >
              {profileData.liveFacebook}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={2}>
              <strong>Twitter</strong>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentTwitter !== profileData.liveTwitter ? '#ddf0dd' : '',
              }}
            >
              {profileData.currentTwitter}
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentTwitter !== profileData.liveTwitter ? '#ddf0dd' : '',
              }}
            >
              {profileData.liveTwitter}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={2}>
              <strong>Instagram</strong>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentInstagram !== profileData.liveInstagram ? '#ddf0dd' : '',
              }}
            >
              {profileData.currentInstagram}
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentInstagram !== profileData.liveInstagram ? '#ddf0dd' : '',
              }}
            >
              {profileData.liveInstagram}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={2}>
              <strong>YouTube</strong>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentYouTube !== profileData.liveYouTube ? '#ddf0dd' : '',
              }}
            >
              {profileData.currentYouTube}
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentYouTube !== profileData.liveYouTube ? '#ddf0dd' : '',
              }}
            >
              {profileData.liveYouTube}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={2}>
              <strong>Pinterest</strong>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentPinterest !== profileData.livePinterest ? '#ddf0dd' : '',
              }}
            >
              {profileData.currentPinterest}
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentPinterest !== profileData.livePinterest ? '#ddf0dd' : '',
              }}
            >
              {profileData.livePinterest}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={2}>
              <strong>TikTok</strong>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentTikTok !== profileData.liveTikTok ? '#ddf0dd' : '',
              }}
            >
              {profileData.currentTikTok}
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentTikTok !== profileData.liveTikTok ? '#ddf0dd' : '',
              }}
            >
              {profileData.liveTikTok}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={2}>
              <strong>LinkedIn</strong>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentLinkedIn !== profileData.liveLinkedIn ? '#ddf0dd' : '',
              }}
            >
              {profileData.currentLinkedIn}
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentLinkedIn !== profileData.liveLinkedIn ? '#ddf0dd' : '',
              }}
            >
              {profileData.liveLinkedIn}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={2}>
              <strong>Website</strong>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentWebsite !== profileData.liveWebsite ? '#ddf0dd' : '',
              }}
            >
              {profileData.currentWebsite}
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentWebsite !== profileData.liveWebsite ? '#ddf0dd' : '',
              }}
            >
              {profileData.liveWebsite}
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={2}>
              <strong>Location</strong>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentLocationText !== profileData.liveLocationText ? '#ddf0dd' : '',
              }}
            >
              {profileData.currentLocationText}
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentLocationText !== profileData.liveLocationText ? '#ddf0dd' : '',
              }}
            >
              {profileData.liveLocationText}
            </Grid>
          </Grid>

          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={2}>
              <strong>Bio</strong>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor: profileData.currentBio !== profileData.liveBio ? '#ddf0dd' : '',
              }}
            >
              <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', width: '98%' }}>
                <div dangerouslySetInnerHTML={{ __html: profileData.currentBio }} />
              </div>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor: profileData.currentBio !== profileData.liveBio ? '#ddf0dd' : '',
              }}
            >
              <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', width: '98%' }}>
                <div dangerouslySetInnerHTML={{ __html: profileData.liveBio }} />
              </div>
            </Grid>
          </Grid>

          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={2}>
              <strong>Photo</strong>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentProfile !== profileData.liveProfile ? '#ddf0dd' : '',
              }}
            >
              {profileData.currentProfile && (
                <img src={profileData.currentProfile} alt="profile-pic" width="100" />
              )}
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentProfile !== profileData.liveProfile ? '#ddf0dd' : '',
              }}
            >
              {profileData.liveProfile && (
                <img src={profileData.liveProfile} alt="profile-pic" width="100" />
              )}
            </Grid>
          </Grid>

          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={2}>
              <strong>Video</strong>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentVideoAssetId !== profileData.liveVideoAssetId ? '#ddf0dd' : '',
              }}
            >
              {profileData.currentVideo !== '' && showOldVideo && (
                <VideoPlayer src={profileData.currentVideo} width="250" controls />
              )}
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                backgroundColor:
                  profileData.currentVideoAssetId !== profileData.liveVideoAssetId ? '#ddf0dd' : '',
              }}
            >
              {profileData.liveVideo !== '' && showNewVideo && (
                <VideoPlayer src={profileData.liveVideo} width="250" controls />
              )}
            </Grid>
          </Grid>

          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={2}>
              <strong>Member Since</strong>
            </Grid>
            <Grid item xs={8}>
              <TextField
                className={classes.formInput2}
                variant="outlined"
                value={memberSince}
                onChange={(e) => setMemberSince(e.target.value)}
                fullWidth
                placeholder="YYYY-MM-DD"
                disabled={!IS_SA}
              />
            </Grid>
            <Grid item xs={2}>
              {IS_SA && profileData.currentEmail !== '' && (
                <Button
                  onClick={updateAgentProfileDataMemberSince}
                  size="small"
                  color={processing === 3 ? 'secondary' : 'primary'}
                  disabled={processing === 3}
                >
                  {processing === 3 ? 'Updating...' : 'Update'}
                </Button>
              )}
            </Grid>
          </Grid>

          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={2}>
              <strong>Video Button</strong>
            </Grid>
            <Grid item xs={8}>
              <TextField
                className={classes.formInput2}
                variant="outlined"
                value={buttonText}
                onChange={(e) => setButtonText(e.target.value)}
                fullWidth
                placeholder="Override Video Button Text"
              />
            </Grid>
            <Grid item xs={2}>
              {profileData.currentEmail !== '' && (
                <Button
                  onClick={updateAgentProfileDataVideoButton}
                  size="small"
                  color={processing === 4 ? 'secondary' : 'primary'}
                  disabled={processing === 4}
                >
                  {processing === 4 ? 'Updating...' : 'Update'}
                </Button>
              )}
            </Grid>
          </Grid>

          <Grid container style={{ marginBottom: 10 }}>
            <Grid item xs={2}>
              <strong>URL Slug</strong>
            </Grid>
            <Grid item xs={8}>
              <TextField
                className={classes.formInput2}
                variant="outlined"
                value={urlSlug}
                onChange={(e) => setUrlSlug(e.target.value)}
                fullWidth
                disabled
              />
              <Grid item xs={2} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {errorMessage === '' && (
            <>
              {webStatus !== 'APPROVED' && processing === 0 && profileData.currentEmail !== '' && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    type="button"
                    onClick={handleApproveConfirm}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    type="button"
                    onClick={handleRejectConfirm}
                  >
                    Reject
                  </Button>
                </>
              )}
              <Button variant="contained" onClick={cancelCallback} disableElevation>
                Close
              </Button>
            </>
          )}
          <span style={{ color: 'red' }}>{errorMessage !== '' ? errorMessage : ''}</span>
        </DialogActions>
      </Dialog>

      {confirmApproval && (
        <ConfirmAction successCallback={approveRequest} cancelCallback={handleApproveConfirm} />
      )}

      {confirmReject && (
        <ConfirmRejectAction
          successCallback={(val) => rejectRequest(val)}
          cancelCallback={handleRejectConfirm}
        />
      )}
    </>
  );
};

AgentWebStatus.propTypes = {
  webStatus: PropTypes.string.isRequired,
  successCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  agentId: PropTypes.number.isRequired,
  rowAgentType: PropTypes.number.isRequired,
};

const ConfirmAction = ({ successCallback, cancelCallback }) => (
  <Dialog open onClose={cancelCallback}>
    <DialogTitle id="alert-dialog-title">Approve Request</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Do you want to approve the change request and publish on web profile?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={successCallback} color="primary">
        Yes
      </Button>
      <Button onClick={cancelCallback} color="primary">
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmAction.propTypes = {
  successCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
};

const ConfirmRejectAction = ({ successCallback, cancelCallback }) => {
  const { handleSubmit, control } = useForm();

  const handleConfirmation = (data) => {
    successCallback(data.reason);
  };

  return (
    <Dialog open onClose={cancelCallback}>
      <DialogTitle id="alert-dialog-title">Reject Request</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <p>Do you want to reject the change request and discard the new changes?</p>

          <form id="reject-web-changes">
            <Controller
              control={control}
              rules={Validations.REQUIRED}
              name="reason"
              id="reason"
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  style={{ marginTop: 10 }}
                  variant="outlined"
                  label="Reason*"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  fullWidth
                />
              )}
            />
          </form>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(handleConfirmation)} color="primary">
          Yes
        </Button>
        <Button onClick={cancelCallback} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmRejectAction.propTypes = {
  successCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
};

export default AgentWebStatus;
