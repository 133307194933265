import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import Validations from '../../utils/Validations';
import { cancelAmendment, cancelContract } from '../../services/Contract';
import SnackbarMessage from '../SnackbarMessage';

const CancelContract = ({ handleDialog, contractId, onSuccess, forAmendment }) => {
  const { handleSubmit, control } = useForm();

  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleConfirmation = (data) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });

    if (forAmendment) {
      cancelAmendment(contractId, data)
        .then(() => {
          setProcessing(false);
          onSuccess();
          handleDialog();
        })
        .catch(({ response }) => {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.data?.message || 'Something went wrong.',
            type: 'error',
            show: true,
          });
          setProcessing(false);
        });
      setProcessing(true);
    } else {
      cancelContract(contractId, data)
        .then(() => {
          setProcessing(false);
          onSuccess();
          handleDialog();
        })
        .catch(({ response }) => {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.data.message,
            type: 'error',
            show: true,
          });
          setProcessing(false);
        });
      setProcessing(true);
    }
  };

  return (
    <Dialog open onClose={handleDialog}>
      <DialogTitle> {forAmendment ? 'Cancel Amendment' : 'Cancel Contract'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {forAmendment
            ? 'Do you want to cancel the amendment?'
            : 'Do you want to cancel the contract?'}
        </DialogContentText>
        <form id="cancel-contract" onSubmit={handleSubmit(handleConfirmation)}>
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="reason"
            id="reason"
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Reason*"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                fullWidth
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          form="cancel-contract"
          color="secondary"
          autoFocus
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing}
        >
          Submit
        </Button>
        <Button onClick={handleDialog} color="primary">
          Cancel
        </Button>
      </DialogActions>

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

CancelContract.propTypes = {
  handleDialog: PropTypes.func.isRequired,
  contractId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  forAmendment: PropTypes.bool,
};

CancelContract.defaultProps = {
  forAmendment: false,
};

export default CancelContract;
