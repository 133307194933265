import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { isDefaultApp } from '../utils/AppDomain';
import RoutePaths from '../config/Routes';
import LoginPage from './auth/Login';
import LogoutPage from './auth/Logout';
import OverviewPage from './dashboard/Overview';
import AgentsPage from './agents/Agents';
import ReportsPage from './reports/Reports';
import StatesPage from './states/States';
import TeamPage from './team/Team';
import ZipcodesPage from './zipcodes/Zipcodes';
import ZonesPage from './zones/Zones';
import NotFoundPage from './error/NotFound';
import AppLoader from '../components/AppLoader';
import Default from '../theme/Default';
import { validateSession } from '../services/Auth';
import UserTypes from '../config/UserTypes';
import UpdateProfile from './auth/UpdateProfile';
import ForgotPassword from './forgot-password/ForgotPassword';
import ResetPassword from './forgot-password/ResetPassword';
import ChangePassword from './auth/ChangePassword';
import Invitation from './auth/Invitation';
import ZipcodeSearch from './widgets/ZipcodeSearch';
import ZipcodeSearchV2 from './widgets/ZipcodeSearchV2';
import ContractSignedFrame from './contract/ContractSignedFrame';
import ContractCancelledFrame from './contract/ContractCancelledFrame';
import Contract from './contract/Contract';
import Resources from './resources/Resources';
import Billing from './billing/Billing';
import AdsApprovalPage from './approvals/AdsApprovalPage';
import SubmissionsPage from './approvals/SubmissionsPage';
import ZipcodeSearchList from './widgets/ZipcodeSearchList';
import SalesToolsPage from './sales-tools/Index';
import DashboardPage from './dashboard/Dashboard';
import ContentEnginePage from './content-engine/ContentEnginePage';
import AdminResourcesPage from './resources/AdminResources';
import TvShow from './tv-show/TvShow';
import Participation from './participation/Participation';
import Content from './content/Content';

// Initialize app routes
const App = () => {
  const [appLoaded, setAppLoaded] = useState(false);
  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;

  const routeArr = [
    {
      path: RoutePaths.HOME,
      component: OverviewPage,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.AGENT.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      path: RoutePaths.SALES_DEMO_LINK,
      component: SalesToolsPage,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      path: RoutePaths.NRP_DASHBOARD,
      component: DashboardPage,
      allowUsers: [UserTypes.SUPER_ADMIN.value, UserTypes.ADMIN.value],
    },
    {
      path: RoutePaths.CONTENT_ENGINE,
      component: ContentEnginePage,
      allowUsers: [UserTypes.SUPER_ADMIN.value, UserTypes.ADMIN.value],
    },
    {
      path: RoutePaths.AGENTS,
      component: AgentsPage,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      path: RoutePaths.LENDERS,
      component: AgentsPage,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      path: RoutePaths.CE_USERS,
      component: AgentsPage,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      path: RoutePaths.TEAM,
      component: TeamPage,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      path: RoutePaths.ZONES,
      component: ZonesPage,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      path: RoutePaths.LENDER_ZONES,
      component: ZonesPage,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      path: RoutePaths.STATE_CODES,
      component: StatesPage,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      path: RoutePaths.ZIPCODES,
      component: ZipcodesPage,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      path: RoutePaths.ADMIN_RESOURCE,
      component: AdminResourcesPage,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      path: RoutePaths.WIDGET_ZIPCODE_SEARCH_LIST,
      component: ZipcodeSearchList,
      allowUsers: [UserTypes.SUPER_ADMIN.value, UserTypes.ADMIN.value],
    },
    {
      path: RoutePaths.REPORTS,
      component: ReportsPage,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      path: RoutePaths.UPDATE_PROFILE,
      component: UpdateProfile,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.AGENT.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      path: RoutePaths.CHANGE_PASSWORD,
      component: ChangePassword,
      allowUsers: [
        UserTypes.SUPER_ADMIN.value,
        UserTypes.ADMIN.value,
        UserTypes.SALES_PERSON.value,
        UserTypes.AGENT.value,
        UserTypes.SUPPORT.value,
        UserTypes.SALES_MANAGER.value,
      ],
    },
    {
      path: RoutePaths.RESOURCES,
      component: Resources,
      allowUsers: [UserTypes.AGENT.value],
    },
    {
      path: RoutePaths.RESOURCES_ARCHIVE_SUCCESS_CALLS,
      component: Resources,
      allowUsers: [UserTypes.AGENT.value],
    },
    {
      path: RoutePaths.CONTENT,
      component: Content,
      allowUsers: [UserTypes.AGENT.value],
    },
    {
      path: RoutePaths.TV_SHOW,
      component: TvShow,
      allowUsers: [UserTypes.AGENT.value],
    },
    {
      path: RoutePaths.PARTICIPATION,
      component: Participation,
      allowUsers: [UserTypes.AGENT.value],
    },
    {
      path: RoutePaths.BILLING,
      component: Billing,
      allowUsers: [UserTypes.AGENT.value],
    },
    {
      path: RoutePaths.AD_APPROVALS,
      component: AdsApprovalPage,
      allowUsers: [UserTypes.SUPER_ADMIN.value, UserTypes.ADMIN.value],
    },
    {
      path: RoutePaths.SUBMISSIONS,
      component: SubmissionsPage,
      allowUsers: [UserTypes.SUPER_ADMIN.value, UserTypes.ADMIN.value],
    },
  ];

  useEffect(() => {
    window.document.title = isDefaultApp() ? 'Negotiators' : 'Content Engine';
    const check301RedirectUrl = window.location.pathname + window.location.hash;
    switch (check301RedirectUrl) {
      case '/branding#profile-pics':
        window.location.href = '/resources#profile-pics';
        return;

      case '/content#profile-pics':
        window.location.href = '/resources#profile-pics';
        return;

      case '/branding#nrp-insights':
        window.location.href = '/participation#nrp-insights';
        return;

      case '/branding#guest-blogs':
        window.location.href = '/participation#guest-blogs';
        return;

      case '/branding#nrp-promo-link':
        window.location.href = '/tv-show#nrp-promo-link';
        return;

      case '/manage-profile#odis':
        window.location.href = '/tv-show#odis';
        return;

      case '/manage-profile#nuggets':
        window.location.href = '/tv-show#nuggets';
        return;

      case '/manage-profile#intro':
        window.location.href = '/tv-show#intro';
        return;

      case '/branding':
        window.location.href = '/content';
        return;

      case '/conversations':
        window.location.href = '/manage-profile';
        return;

      default:
        break;
    }

    validateSession()
      .then((res) => {
        window.localStorage.setItem('userDetail', res.data ? JSON.stringify(res.data) : '');
        setAppLoaded(true);
      })
      .catch(() => {
        setAppLoaded(true);
      });
  }, []);

  return (
    <ThemeProvider theme={Default}>
      <CssBaseline />

      {!appLoaded && <AppLoader />}

      {appLoaded && (
        <Router>
          <Switch>
            {routeArr.map((route) => {
              if (!route.allowUsers.includes(userType) && userType !== 0) {
                return null;
              }
              return <Route path={route.path} exact component={route.component} />;
            })}
            <Route path={RoutePaths.CONTRACT_CANCELLED} exact component={ContractCancelledFrame} />
            <Route path={RoutePaths.CONTRACT_SIGNED} exact component={ContractSignedFrame} />
            <Route path={RoutePaths.CONTRACT} exact component={Contract} />
            <Route path={RoutePaths.LOGIN} exact component={LoginPage} />
            <Route path={RoutePaths.LOGOUT} exact component={LogoutPage} />
            <Route path={RoutePaths.FORGOT_PASSWORD} exact component={ForgotPassword} />
            <Route path={RoutePaths.RESET_PASSWORD} exact component={ResetPassword} />
            <Route path={RoutePaths.INVITATION} exact component={Invitation} />
            <Route path={RoutePaths.WIDGET_ZIPCODE_SEARCH} exact component={ZipcodeSearch} />
            <Route path={RoutePaths.WIDGET_ZIPCODE_SEARCH_V2} exact component={ZipcodeSearchV2} />
            <Route path={RoutePaths.WIDGET_LENDER_ZIPCODE_SEARCH} exact component={ZipcodeSearch} />
            <Route
              path={RoutePaths.WIDGET_LENDER_ZIPCODE_SEARCH_V2}
              exact
              component={ZipcodeSearchV2}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </Router>
      )}
    </ThemeProvider>
  );
};

export default App;
