import { getApiCall, postApiCall, deleteApiCall } from '../utils/Api';
import { agentTypeValues, zoneTypeValues } from '../config/DataValues';

const listAll = async (page, params) => {
  const result = await getApiCall(`/team/list?page=${page}${params ? `&${params}` : ''}`);
  return result.data;
};

export const addNewTeam = async (data) => {
  const result = await postApiCall(`/team/create`, data);
  return result.data;
};

export const editTeam = async (id, data) => {
  const result = await postApiCall(`/team/${id}/update`, data);
  return result.data;
};

export const viewTeamById = async (id) => {
  const result = await getApiCall(`/team/${id}/view`);
  return result.data;
};

export const removeTeam = async (id) => {
  const result = await deleteApiCall(`/team/${id}/remove`);
  return result.data;
};
export const viewAgents = async (id, module = agentTypeValues.AGENTS) => {
  const result = await getApiCall(`/team/${id}/agents?module=${module}`);
  return result.data;
};

export const viewPurchase = async (id, params, module = zoneTypeValues.AGENTS) => {
  const result = await getApiCall(`/team/${id}/purchased-zone?purchase=${params}&module=${module}`);
  return result.data;
};

export const searchSalesPerson = async (searchText) => {
  const result = await getApiCall(`/team/search${searchText ? `?searchText=${searchText}` : ''}`);
  return result.data;
};

export default listAll;
