import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { listNotes } from '../../services/Inquiries';
import InquiryNote from './InquiryNote';

const InquiryNotesList = ({ closeDialog, id }) => {
  const [notes, setNotes] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [selectedData, setSelectedData] = useState({
    editId: 0,
    text: '',
    deleteNote: false,
  });

  useEffect(() => {
    listNotes(id)
      .then((res) => {
        setNotes(res.data);
      })
      .catch(() => {
        closeDialog();
      });
  }, [refresh]);

  const handleEditNote = (row) => {
    setSelectedData({
      editId: row.id,
      text: row.note,
      deleteNote: false,
    });
  };
  const handleEditNoteClose = () => {
    setSelectedData({
      editId: 0,
      text: '',
      deleteNote: false,
    });
  };
  const handleEditNoteCloseSuccess = () => {
    setSelectedData({
      editId: 0,
      text: '',
      deleteNote: false,
    });
    setRefresh(!refresh);
  };
  const handleDeleteNote = (row) => {
    setSelectedData({
      editId: row.id,
      text: row.note,
      deleteNote: true,
    });
  };

  return (
    <>
      <Dialog maxWidth="md" fullWidth open>
        <DialogTitle>List of Notes</DialogTitle>
        <DialogContent>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="30%">Date</TableCell>
                <TableCell width="55%">Note</TableCell>
                <TableCell width="15%" align="right">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notes.map((row) => (
                <TableRow key={`aqn-${row.id}`}>
                  <TableCell>{row.time}</TableCell>
                  <TableCell style={{ whiteSpace: 'pre-wrap' }}>{row.note}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="add"
                      title="Edit"
                      size="small"
                      onClick={() => {
                        handleEditNote(row);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="add"
                      title="Delete"
                      size="small"
                      color="secondary"
                      onClick={() => {
                        handleDeleteNote(row);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {notes.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    No record found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {selectedData.editId !== 0 && (
        <InquiryNote
          closeDialog={handleEditNoteClose}
          onSave={handleEditNoteCloseSuccess}
          id={id}
          editId={selectedData.editId}
          text={selectedData.text}
          deleteNote={selectedData.deleteNote}
        />
      )}
    </>
  );
};

InquiryNotesList.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

export default InquiryNotesList;
