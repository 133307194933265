import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Tabs,
  Tab,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createContract, viewByAgentId } from '../../services/Contract';
import listAll from '../../services/Zones';
import { fetchPurchasedZone } from '../../services/Agent';
import SnackbarMessage from '../SnackbarMessage';
import ViewContract from './ViewContract';
import PrepareContract from './PrepareContract';
import CancelContract from './CancelContract';
import AmendContractConfirmDialog from './AmendContractConfirmDialog';
import UserTypes from '../../config/UserTypes';
import agentStyle from '../../theme/styles/Agents';
import { ContractSubTypes } from '../../config/DataValues';
import CancelSubscriptionTab from '../agent/CancelSubscriptionTab';
import ManageTokens from '../discount-tokens/ManageTokens';

const useStyles = makeStyles(agentStyle);

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && <Box pt={3}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ManageContractModel = ({
  open,
  closeModel,
  agentId,
  onSuccess,
  onAmendContract,
  zoneType,
}) => {
  const classes = useStyles();
  const subStart = new Date();
  const contractExp = new Date();

  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;
  const hasAmendContractAccess = [
    UserTypes.SUPER_ADMIN.value,
    UserTypes.ADMIN.value,
    UserTypes.SALES_MANAGER.value,
  ].includes(userType);
  const hasCancellationRights = [UserTypes.SUPER_ADMIN.value, UserTypes.ADMIN.value].includes(
    userType
  );

  const [zoneObj, setZoneObj] = useState({});
  const [processing, setProcessing] = useState(false);
  const [contractValueError, setContractValueError] = useState(false);
  const [contractValues, setContractValues] = useState({
    subTotal: 0,
    discount: 0,
    total: 0,
    subType: 2,
    subStartDate: new Date(subStart.setDate(subStart.getDate() + 14)),
    contractExpiryDate: new Date(contractExp.setDate(contractExp.getDate() + 30)),
  });
  const [agentDetails, setAgentDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    location: '',
    draftAmendment: 0,
  });
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [creatingContract, setCreatingContract] = useState(false);
  const [contractZoneData, setContractZoneData] = useState([]);
  const [updateList, setUpdateList] = useState(false);
  const [contractStatus, setContractStatus] = useState(null);
  const [cancelContractModel, setCancelContractModel] = useState(false);
  const [amendContractModal, setAmendContractModal] = useState(false);
  const [contractId, setContractId] = useState(0);
  const [refreshContract, setRefreshContract] = useState(false);

  const [contractNotes, setContractNotes] = useState('');
  const requireContractNotesFor = [ContractSubTypes.MONTHLY.value];
  const [isCancellationScheduled, setIsCancellationScheduled] = useState(false);
  const [isImmediateCancelReq, setIsmmediateCancelReq] = useState(false);

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    listAll(null, null, zoneType).then((item) => {
      const tmpzoneObj = {};
      item.data.rows.forEach((zone) => {
        tmpzoneObj[zone.id] = zone.name;
      });
      setZoneObj(tmpzoneObj);
    });
  }, []);

  useEffect(() => {
    setProcessing(true);
    viewByAgentId(agentId)
      .then((res) => {
        setContractId(0);
        if (res.data.agentDetails) {
          setAgentDetails(res.data.agentDetails);
        }
        const contractStatusSimilarToCancelled = ['cancelled', 'terminated', 'expired'];

        if (
          res.data.contract &&
          !contractStatusSimilarToCancelled.includes(res.data.contract?.status || '')
        ) {
          setContractId(res.data.contract.id);
          setContractValues({
            subTotal: res.data.contract.subTotal,
            discount: res.data.contract.discount,
            total: res.data.contract.total,
            subType: res.data.contract.subType,
            subStartDate: res.data.contract.subStartDate,
            contractExpiryDate: res.data.contract.contractExpiryDate,
          });
          setIsCancellationScheduled(res.data.contract.isCancellationScheduled);
          setIsmmediateCancelReq(res.data.contract?.isImmediateCancelReq);
          const arr = res.data.contract.contractZones.map((item) => ({
            id: item.id,
            purchasedZone: item.zoneId,
            exclusivity: item.purchaseType === 'sold' ? 1 : 2,
            paymentStatus: item.paymentStatus === 'hold' ? 2 : 1,
            nrpFee: item.price,
          }));
          setContractZoneData(arr);
          setContractStatus(res.data.contract.status);
          setContractNotes(
            requireContractNotesFor.includes(res.data.contract.subType)
              ? res.data.contract?.contractNotes || ''
              : ''
          );
          setProcessing(false);
        } else {
          fetchPurchasedZone(agentId).then((resp) => {
            const filterHoldZones = resp.data.filter((z) => z.paymentStatus === 'hold');
            const arr = filterHoldZones.map((item) => ({
              id: item.id,
              purchasedZone: item.zoneId,
              exclusivity: item.purchaseType === 'sold' ? 1 : 2,
              paymentStatus: item.paymentStatus === 'hold' ? 2 : 1,
              nrpFee: item.price,
              status: item.status,
            }));
            setContractZoneData(arr);
            setContractStatus(null);

            const subStart2 = new Date();
            const contractExp2 = new Date();
            setContractValues({
              subTotal: 0,
              discount: 0,
              total: 0,
              subType: 2,
              subStartDate: new Date(subStart2.setDate(subStart2.getDate() + 14)),
              contractExpiryDate: new Date(contractExp2.setDate(contractExp2.getDate() + 30)),
            });
            setContractNotes('');
            setProcessing(false);
          });
        }
      })
      .catch(() => {
        closeModel();
        onSuccess();
      });
  }, [refreshContract]);

  const create = () => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    setCreatingContract(true);

    const contractExpTmp = new Date();

    const payload = {
      contractDetails: {
        ...contractValues,
        subStartDate: contractValues.subStartDate,
        contractExpiryDate:
          contractValues.contractExpiryDate ||
          new Date(contractExpTmp.setDate(contractExpTmp.getDate() + 30)),
        contractNotes,
      },
      contractZoneData: contractZoneData.map((zone) => ({
        zoneId: zone.purchasedZone,
        price: zone.nrpFee,
        purchaseType: zone.exclusivity,
      })),
      agentId,
    };

    createContract(payload, zoneType)
      .then((res) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: res.data.contractMessage,
          type: res.data.contractStatus ? 'success' : 'error',
          show: true,
        });
        setTimeout(() => {
          onSuccess();
          setCreatingContract(false);
          closeModel();
        }, 3000);
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
        setCreatingContract(false);
      });
  };

  const cancelContract = () => {
    setCancelContractModel(true);
  };

  const amendContract = () => {
    setAmendContractModal(true);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getTitle = () => {
    switch (tabValue) {
      case 0:
        return 'Manage Contract';
      case 1:
        return 'Manage Tokens';
      case 2:
        return 'Cancel Subscription';
      default:
        return 'Manage Contract';
    }
  };

  return (
    <Dialog open={open} onClose={closeModel} maxWidth="md" fullWidth>
      <DialogTitle>{getTitle()}</DialogTitle>
      <DialogContent>
        <>
          <Paper elevation={0}>
            <Tabs value={tabValue} aria-label="agent tabs" indicatorColor="primary">
              <Tab
                label="Manage Contract"
                {...a11yProps(0)}
                onClick={() => {
                  handleTabChange(null, 0);
                }}
              />
              {hasCancellationRights && (
                <Tab
                  label="Manage Tokens"
                  {...a11yProps(1)}
                  onClick={() => {
                    handleTabChange(null, 1);
                  }}
                />
              )}
              {hasCancellationRights && contractStatus === 'completed' && !isImmediateCancelReq && (
                <Tab
                  label="Cancel Subscription"
                  {...a11yProps(2)}
                  onClick={() => {
                    handleTabChange(null, 2);
                  }}
                />
              )}
            </Tabs>
          </Paper>
        </>
        <TabPanel value={tabValue} index={0}>
          {processing && (
            <Box display="flex" justifyContent="center">
              <CircularProgress />{' '}
            </Box>
          )}
          {!processing && contractId > 0 && (
            <ViewContract
              agentId={0}
              fromAddAgent
              setContractZoneData={setContractZoneData}
              contractZoneData={contractZoneData}
              updateList={updateList}
              setUpdateList={setUpdateList}
              zoneArr={zoneObj}
              agentDetails={agentDetails}
              setContractValues={setContractValues}
              contractValues={contractValues}
              contractStatus={contractStatus}
              contractNotes={contractNotes}
            />
          )}
          {!processing && contractId === 0 && (
            <PrepareContract
              agentId={0}
              fromAddAgent
              setContractZoneData={setContractZoneData}
              contractZoneData={contractZoneData}
              updateList={updateList}
              setUpdateList={setUpdateList}
              zoneArr={zoneObj}
              agentDetails={agentDetails}
              setContractValues={setContractValues}
              contractValues={contractValues}
              contractStatus={contractStatus}
              contractValueError={setContractValueError}
              contractNotes={contractNotes}
              setContractNotes={setContractNotes}
              canShowNotesPopup={tabValue === 0} // only show when it is manage contract modal
              zoneType={zoneType}
            />
          )}
        </TabPanel>
        {hasCancellationRights && (
          <TabPanel value={tabValue} index={1}>
            <ManageTokens
              agentId={agentId}
              refreshModalData={() => setRefreshContract(!refreshContract)}
              closeModal={closeModel}
            />
          </TabPanel>
        )}
        {hasCancellationRights && contractStatus === 'completed' && !isImmediateCancelReq && (
          <TabPanel value={tabValue} index={2}>
            <CancelSubscriptionTab
              agentId={agentId}
              refreshModalData={() => setRefreshContract(!refreshContract)}
              closeModal={closeModel}
              onImmediateContractReq={() => {
                handleTabChange(null, 0);
                setRefreshContract(!refreshContract);
              }}
            />
          </TabPanel>
        )}
      </DialogContent>
      <DialogActions className={classes.spaceBetween}>
        <div>
          {tabValue === 0 &&
            hasAmendContractAccess &&
            contractId > 0 &&
            (contractStatus === 'completed' || contractStatus === 'paid') && (
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                disabled={
                  agentDetails.draftAmendment || isCancellationScheduled || isImmediateCancelReq
                }
                endIcon={creatingContract && <CircularProgress size={25} />}
                onClick={amendContract}
              >
                Amend Contract
              </Button>
            )}
        </div>
        <div>
          {tabValue === 0 && contractId === 0 && (
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disabled={
                contractZoneData.length === 0 ||
                creatingContract ||
                contractValueError ||
                (contractValues.subType === 1 && !contractNotes)
              }
              endIcon={creatingContract && <CircularProgress size={25} />}
              onClick={create}
              className={classes.addAgentFormBtn}
            >
              Create Contract
            </Button>
          )}
          {tabValue === 0 && contractId > 0 && contractStatus !== 'completed' && (
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              disabled={contractZoneData.length === 0 || creatingContract || contractValueError}
              endIcon={creatingContract && <CircularProgress size={25} />}
              onClick={cancelContract}
              className={classes.addAgentFormBtn}
            >
              Cancel Contract
            </Button>
          )}
          <Button variant="contained" onClick={closeModel} disableElevation>
            {`${contractStatus === 'completed' || tabValue === 1 ? 'close' : 'Cancel'}`}
          </Button>
        </div>
      </DialogActions>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}

      {cancelContractModel && (
        <CancelContract
          handleDialog={() => {
            setCancelContractModel(false);
          }}
          onSuccess={() => {
            setRefreshContract(!refreshContract);
          }}
          contractId={contractId}
        />
      )}

      {amendContractModal && (
        <AmendContractConfirmDialog
          handleDialog={() => {
            setAmendContractModal(false);
          }}
          onSuccess={() => {
            onAmendContract();
          }}
          contractId={contractId}
        />
      )}
    </Dialog>
  );
};

ManageContractModel.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  agentId: PropTypes.number.isRequired,
  viewAmendContractDialog: PropTypes.func.isRequired,
  onAmendContract: PropTypes.func.isRequired,
  zoneType: PropTypes.number.isRequired,
};

export default ManageContractModel;
