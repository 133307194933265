import {
  Box,
  Button,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { ArrowDownward, ImportExport, MoreVert } from '@material-ui/icons';
import { Pagination, Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PropTypes from 'prop-types';
import RoutePaths from '../../../config/Routes';
import { listAll } from '../../../services/ShowSegments';
import listingStyle from '../../../theme/styles/TableListing';
import TableToolbar from '../../TableToolbar';
import FormDialog from './FormDialog';
import ConfirmDialog from './ConfirmDialog';
import Submissions from './Submissions';

const useStyles = makeStyles(listingStyle);

const ShowSegments = () => {
  const classes = useStyles();
  const history = useHistory();

  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reloadRows, setReloadRows] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const [addEditDialog, setAddEditDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);

  const [actionId, setActionId] = useState(0);
  const [showSubmissions, setShowSubmissions] = useState(0);

  const columns = [
    { name: 'Title', id: 'title', width: '35%' },
    { name: 'Special Theme', id: 'specialTheme', width: '25%' },
    { name: 'Submissions', id: 'submissions', width: '10%', align: 'center' },
    { name: 'Status', id: 'status', width: '10', align: 'center' },
    { name: 'Schedule', id: 'statusText', width: '15%', align: 'center' },
  ];

  const getParams = () => {
    let queryString = '1=1';
    if (sortBy) queryString += `&sortBy=${sortBy}`;
    if (orderBy) queryString += `&orderBy=${orderBy}`;
    if (searchText) queryString += `&searchText=${searchText}`;
    return queryString.substring(4);
  };

  useEffect(() => {
    setDataLoaded(false);
    listAll(activePage, getParams())
      .then((res) => {
        setRows(res.data.rows);
        setTotalPages(res.data.totalPages);
        setDataLoaded(true);
      })
      .catch(() => {
        setDataLoaded(true);
      });
  }, [reloadRows, activePage]);

  const searchList = () => {
    setActivePage(1);
    setReloadRows(!reloadRows);
  };

  const sortHandler = (colId) => {
    setActivePage(1);
    setSortBy(colId);
    setOrderBy(orderBy === 'asc' ? 'desc' : 'asc');
    setReloadRows(!reloadRows);
  };

  const refreshList = () => {
    setReloadRows(!reloadRows);
  };

  const resetList = () => {
    setSearchText('');
    setOrderBy('');
    setSortBy('');
    setReloadRows(!reloadRows);
    setActivePage(1);
    history.push(RoutePaths.SUBMISSIONS);
  };

  const handleActions = (row, action) => {
    if (action === 'edit') {
      setActionId(row.id);
      setAddEditDialog(true);
    } else if (action === 'delete') {
      setActionId(row.id);
      setDeleteDialog(true);
    } else if (action === 'view') {
      setActionId(row.id);
      setViewDialog(true);
    }
  };

  const toggleSubmissionList = (id) => {
    setShowSubmissions(id);
  };

  return (
    <Box mt={2}>
      <div className={classes.filterToolbar}>
        <TableToolbar
          refreshList={refreshList}
          searchList={searchList}
          getSearchText={(e) => setSearchText(encodeURIComponent(e.target.value))}
          addNew={() => setAddEditDialog(true)}
          resetList={resetList}
        />
      </div>
      <TableContainer component={Paper} elevation={0}>
        <Table className={classes.tableData} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell width={col.width} align={col.align || ''}>
                  {!['statusText'].includes(col.id) ? (
                    <TableSortLabel
                      active={sortBy === col.id}
                      direction={sortBy === col.id ? orderBy : 'asc'}
                      onClick={() => sortHandler(col.id)}
                      IconComponent={sortBy !== col.id ? ImportExport : ArrowDownward}
                    >
                      <span>{col.name}</span>
                    </TableSortLabel>
                  ) : (
                    col.name
                  )}
                </TableCell>
              ))}
              <TableCell align="center" width="5%">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!dataLoaded &&
              [1, 2, 3, 4, 5].map((val) => (
                <TableRow key={`sub-${val}`}>
                  <TableCell component="th" scope="row">
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                </TableRow>
              ))}
            {dataLoaded &&
              (rows.length > 0 ? (
                rows.map((row) => (
                  <TableRow key={`sub-${row.id}`}>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.specialTheme}</TableCell>
                    <TableCell align="center">
                      <Button onClick={() => toggleSubmissionList(row.id)}>
                        {row.submissions}
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      {row.status === 'Open' && (
                        <div className={classes.statusSegmentOpen}>{row.status}</div>
                      )}
                      {row.status === 'Scheduled' && (
                        <div className={classes.statusSegmentScheduled}>{row.status}</div>
                      )}
                      {row.status === 'Closed' && (
                        <div className={classes.statusSegmentClosed}>{row.status}</div>
                      )}
                    </TableCell>
                    <TableCell align="center">{row.statusText}</TableCell>
                    <TableCell align="right">
                      <ActionMenu row={row} selectAction={handleActions} />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    align="center"
                    size="medium"
                    colSpan={7}
                    className={classes.noRecord}
                    style={{ padding: 20, fontSize: 15 }}
                  >
                    No records found
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {dataLoaded && totalPages > 1 && (
        <Pagination
          count={totalPages}
          showFirstButton
          showLastButton
          className={classes.tablePagination}
          onChange={(_, pageNumber) => {
            setActivePage(pageNumber);
            setReloadRows(!reloadRows);
          }}
          page={activePage}
        />
      )}

      {(addEditDialog || viewDialog) && (
        <FormDialog
          onClose={() => {
            setAddEditDialog(false);
            setViewDialog(false);
            setActionId(0);
          }}
          onSuccess={() => {
            setReloadRows(!reloadRows);
          }}
          editId={actionId}
          viewOnly={viewDialog}
        />
      )}

      {deleteDialog && (
        <ConfirmDialog
          closeModel={() => {
            setDeleteDialog(false);
            setActionId(0);
          }}
          onSuccess={() => {
            setDeleteDialog(false);
            setActionId(0);
            setReloadRows(!reloadRows);
          }}
          deleteId={actionId}
        />
      )}

      {showSubmissions !== 0 && (
        <Submissions
          id={showSubmissions}
          onClose={() => {
            toggleSubmissionList(0);
          }}
        />
      )}
    </Box>
  );
};

const ActionMenu = ({ row, selectAction }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (action) => {
    selectAction(row, action);
    handleClose();
  };
  return (
    <div>
      <Button aria-controls={`simple-menu${row.id}`} aria-haspopup="true" onClick={handleClick}>
        <MoreVert />
      </Button>
      <Menu
        id={`simple-menu${row.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {row.allowEdit && (
          <MenuItem
            onClick={() => {
              handleMenuClick('edit');
            }}
            button
          >
            Edit
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleMenuClick('view');
          }}
          button
        >
          View
        </MenuItem>
        {row.allowDelete && (
          <MenuItem
            onClick={() => {
              handleMenuClick('delete');
            }}
            button
            disabled={row.submitted > 0}
          >
            Delete
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

ActionMenu.propTypes = {
  row: PropTypes.objectOf().isRequired,
  selectAction: PropTypes.func.isRequired,
};

export default ShowSegments;
