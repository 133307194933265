import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import formInput from '../../theme/styles/FormInput';
import { searchStateCode } from '../../services/State';
import { addZipCode } from '../../services/Zipcode';
import Validations, { MaxLengthValidationFunc } from '../../utils/Validations';
import SnackbarMessage from '../SnackbarMessage';

const useStyles = makeStyles(formInput);
const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
};

const FormDialog = ({ open, closeModel, title, onSuccess, qstateId }) => {
  const classes = useStyles();
  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const { handleSubmit, control, setValue, reset } = useForm();

  useEffect(() => {
    setValue('status', 1);
    if (qstateId) {
      setValue('zipCodeState', Number(qstateId));
    }
  }, []);

  const handleDialog = () => {
    reset();
    closeModel();
  };

  const submitForm = ({ code, city, zipCodeState, status }) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    setProcessing(true);
    const payload = {
      zipcode: code,
      city,
      stateCodeId: Number(zipCodeState.id),
      status: Number(status),
    };

    addZipCode(payload)
      .then(() => {
        setProcessing(false);
        handleDialog();
        onSuccess();
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
        setProcessing(false);
      });
  };

  const loadStateOptions = (inputStateValue, callback) => {
    searchStateCode(inputStateValue).then((res) => {
      callback(
        res.data.rows.map((i) => ({
          name: i.code,
          id: i.id,
        }))
      );
    });
  };

  return (
    <Dialog open={open} onClose={handleDialog} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={handleSubmit(submitForm)}>
        <DialogContent className={classes.dialogContent}>
          <Controller
            control={control}
            rules={{
              ...Validations.REQUIRED,
              ...MaxLengthValidationFunc(6),
            }}
            name="code"
            id="code"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Zip Code*"
                type="text"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
              />
            )}
          />
          <Controller
            control={control}
            rules={{
              ...Validations.REQUIRED,
              ...MaxLengthValidationFunc(40),
            }}
            name="city"
            id="city"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="City*"
                type="text"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
              />
            )}
          />
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="zipCodeState"
            id="zipCodeState"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.formInput}>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  value={value}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.id}
                  loadOptions={loadStateOptions}
                  onChange={(v) => onChange(v)}
                  placeholder="State*"
                  styles={customStyles}
                />
                {error && (
                  <Typography className={classes.formErrorText}>{error.message}</Typography>
                )}
              </div>
            )}
          />
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="status"
            id="status"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Status*"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                SelectProps={{
                  native: true,
                }}
                fullWidth
                select
              >
                <option key={1} value={1}>
                  Active
                </option>
                <option key={0} value={0}>
                  Inactive
                </option>
              </TextField>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            type="submit"
            endIcon={processing && <CircularProgress size={25} />}
            disabled={processing}
          >
            Submit
          </Button>

          <Button variant="contained" onClick={handleDialog} disableElevation>
            Cancel
          </Button>
        </DialogActions>
      </form>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  title: PropTypes.string,
  qstateId: PropTypes.string,
};
FormDialog.defaultProps = {
  title: '',
  qstateId: null,
};

export default FormDialog;
