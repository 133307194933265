import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import formInput from '../../theme/styles/FormInput';
import { searchStateName } from '../../services/State';
import ZoneZipCodes from './ZoneZipCodes';
import { creatZone, editZone, viewZoneById } from '../../services/Zones';
import Validations, { MaxLengthValidationFunc } from '../../utils/Validations';
import SnackbarMessage from '../SnackbarMessage';
import { zoneTypeValues } from '../../config/DataValues';

const useStyles = makeStyles(formInput);
const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
};

const FormDialog = ({ open, editId, closeModel, title, onSuccess, module }) => {
  const classes = useStyles();

  const [processing, setProcessing] = useState(false);
  const [zipCodes, setZipCodes] = useState([]);
  const [zipcodeError, setZipCodeError] = useState('');
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [isStateSelected, setIsStateSelected] = useState(0);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const { handleSubmit, control, setValue, reset, getValues } = useForm();

  useEffect(() => {
    if (editId) {
      viewZoneById(editId).then((res) => {
        const { data } = res;
        setValue('name', data.name);
        setValue('zoneType', data.type);
        setValue('zoneState', data.stateCodeId);
        setValue('cities', data.cities);
        setZipCodes(data.zoneZipCodes ? data.zoneZipCodes.split(', ') : []);
        setIsStateSelected(data.stateCodeId.id);
      });
    }
  }, []);

  const handleDialog = () => {
    reset();
    closeModel();
  };

  const submitForm = ({ name, zoneState, zoneType, cities }) => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    if (zipCodes.length > 0) {
      setProcessing(true);
      const payload = {
        name,
        stateCodeId: Number(zoneState.id),
        type: zoneType,
        status: 1,
        cities,
        zipCodes: zipCodes.join(', '),
      };

      if (editId) {
        editZone(editId, payload, module)
          .then((res) => {
            const checkFalseStatus = res.data.validateCodesres.filter((s) => s.status === false);
            setProcessing(false);
            handleDialog();
            onSuccess(checkFalseStatus.length);
          })
          .catch(({ response }) => {
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: response.data.message,
              type: 'error',
              show: true,
            });
            setProcessing(false);
          });
      } else {
        creatZone(payload, module)
          .then((res) => {
            const checkFalseStatus = res.data.validateCodesres.filter((s) => s.status === false);
            setProcessing(false);
            handleDialog();
            onSuccess(checkFalseStatus.length);
          })
          .catch(({ response }) => {
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: response.data.message,
              type: 'error',
              show: true,
            });
            setProcessing(false);
          });
      }
    } else {
      setZipCodeError('Please add zip code(s)');
    }
  };

  const loadStateOptions = (inputStateValue, callback) => {
    searchStateName(inputStateValue).then((res) => {
      callback(
        res.data.rows.map((i) => ({
          name: i.name,
          id: i.id,
        }))
      );
    });
  };

  const submitError = () => {
    if (zipCodes.length === 0) {
      setZipCodeError('Please add zip code(s)');
    }
  };

  const defaultFormValues = getValues();

  return (
    <Dialog open={open} onClose={handleDialog} fullWidth>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit(submitForm, submitError)} id="zone-form">
          <Controller
            control={control}
            rules={{ ...Validations.REQUIRED, ...MaxLengthValidationFunc(250) }}
            name="name"
            id="name"
            defaultValue={defaultFormValues.name || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Zone Name*"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
              />
            )}
          />
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="zoneState"
            id="zoneState"
            defaultValue={defaultFormValues.zoneState || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.formInput}>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  value={value}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.id}
                  loadOptions={loadStateOptions}
                  onChange={(v) => {
                    onChange(v);
                    setZipCodes([]);
                    setIsStateSelected(v.id);
                  }}
                  placeholder="State*"
                  styles={customStyles}
                />
                {error && (
                  <Typography className={classes.formErrorText}>{error.message}</Typography>
                )}
              </div>
            )}
          />
          <Controller
            control={control}
            rules={MaxLengthValidationFunc(20)}
            name="zoneType"
            id="zoneType"
            defaultValue={defaultFormValues.zoneType || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Zone Type"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
              />
            )}
          />
          <Controller
            control={control}
            name="cities"
            id="cities"
            defaultValue={defaultFormValues.cities || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Cities"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
                multiline
                rows={5}
              />
            )}
          />
          <ZoneZipCodes
            zipCodes={zipCodes}
            setZipCodes={setZipCodes}
            error={zipcodeError}
            clearError={setZipCodeError}
            stateCodeId={isStateSelected}
            setDisableSubmit={setDisableSubmit}
            zoneType={module}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disableElevation
          form="zone-form"
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing || disableSubmit}
        >
          Submit
        </Button>
        <Button variant="contained" onClick={handleDialog} disableElevation>
          Cancel
        </Button>
      </DialogActions>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  editId: PropTypes.number,
  title: PropTypes.string,
  module: PropTypes.number,
};
FormDialog.defaultProps = {
  editId: 0,
  title: '',
  module: zoneTypeValues.AGENTS,
};

export default FormDialog;
