import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import SnackbarMessage from '../SnackbarMessage';
import { removeAgentVideo } from '../../services/Agent';

const ConfirmDialog = ({ open, deleteId, closeModel, onSuccess }) => {
  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleDialog = () => {
    closeModel();
  };

  const handleConfirmation = (e) => {
    e.preventDefault();
    setProcessing(true);
    if (deleteId) {
      removeAgentVideo(deleteId)
        .then(() => {
          setProcessing(false);
          handleDialog();
          onSuccess();
        })
        .catch(() => {
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: 'Some error occurred , please try again.',
            type: 'error',
            show: 'error',
          });
          setProcessing(false);
        });
    }
  };

  return (
    <Dialog open={open} onClose={handleDialog}>
      <DialogTitle id="alert-dialog-title">Remove Video</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Do you want to delete this record? This action can not be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirmation}
          color="secondary"
          autoFocus
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing}
        >
          Remove
        </Button>
        <Button onClick={handleDialog} color="primary">
          Cancel
        </Button>
      </DialogActions>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  deleteId: PropTypes.number,
};
ConfirmDialog.defaultProps = {
  deleteId: 0,
};

export default ConfirmDialog;
