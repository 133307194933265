/* eslint-disable no-unused-vars */

import { deleteApiCall, getApiCall, postApiCall } from '../utils/Api';

/* eslint-disable import/prefer-default-export */
export const listAllSubmissions = async (page, params) => {
  const result = await getApiCall(`/submission/list?page=${page}${params ? `&${params}` : ''}`);
  return result.data;
};

export const viewSubmissionById = async (id) => {
  const result = await getApiCall(`/submission/${id}/view`);
  return result.data;
};

export const getSubmissionAgents = async (id, page) => {
  const result = await getApiCall(`/submission/${id}/agents?page=${page}`);
  return result.data;
};

export const getOpenPrompts = async () => {
  const result = await getApiCall(`/submission/open-submissions`);
  return result.data;
};

export const getPastSubmissions = async (page) => {
  const result = await getApiCall(`/submission/past-submissions?page=${page || 1}`);
  return result.data;
};

export const postAgentSubmission = async (id, data) => {
  const result = await postApiCall(`/submission/${id}/add-video`, data);
  return result.data;
};

export const deleteAgentSubmission = async (id) => {
  const result = await deleteApiCall(`/submission/${id}/delete-video`);
  return result.data;
};

export const createSubmission = async (data) => {
  const result = await postApiCall(`/submission/create`, data);
  return result.data;
};

export const updateSubmission = async (id, data) => {
  const result = await postApiCall(`/submission/${id}/update`, data);
  return result.data;
};

export const deleteSubmission = async (id) => {
  const result = await deleteApiCall(`/submission/${id}/delete`);
  return result.data;
};
