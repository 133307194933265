import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';

const VideoDialog = ({ closeModel, data }) => {
  const handleDialog = () => {
    closeModel();
  };

  return (
    <Dialog maxWidth="lg" open onClose={handleDialog}>
      <DialogTitle id="alert-dialog-title">{data.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <iframe
            title="Demo Video"
            src={data.url}
            width="640"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
          <p>
            <a href={data.vimeoUrl} target="_blank" rel="noreferrer">
              {data.title}
            </a>{' '}
            from{' '}
            <a href="https://vimeo.com/watchreveel" target="_blank" rel="noreferrer">
              Reveel Entertainment, Inc.
            </a>{' '}
            on{' '}
            <a href="https://vimeo.com" target="_blank" rel="noreferrer">
              Vimeo
            </a>
            .
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

VideoDialog.propTypes = {
  closeModel: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default VideoDialog;
