import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';

import SnackbarMessage from '../SnackbarMessage';
import formInput from '../../theme/styles/FormInput';
import { editAgent, viewAgentById } from '../../services/Agent';
import AddAgentForm from './AddAgentForm';
import agentStyle from '../../theme/styles/Agents';
import BioHtmlFiltered from './BioHtmlFiltered';
import { getAgentTypeLabel } from '../../utils/GetModuleType';

const useStyles = makeStyles(formInput);
const useStyles2 = makeStyles(agentStyle);

const FormDialog = ({ open, editId, closeModel, title, onSuccess, module }) => {
  const classes = useStyles();
  const classes2 = useStyles2();

  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const [tabValue, setTabValue] = useState(0);
  const [userProfile, setUserProfile] = useState('');
  const [uploading, setUploading] = useState(false);
  const [assetId, setAssetId] = useState(0);
  const [assetStatus, setAssetStatus] = useState('');
  const [hasSignedOrPaidContract, setHasSignedOrPaidContract] = useState(false);

  const [showHtmlFilterWarning, setShowHtmlFilterWarning] = useState(false);
  const [reloadForm, setReloadForm] = useState(1);

  const labelWordCase = getAgentTypeLabel(module, false, true);

  let onNextClick = false;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const { handleSubmit, control, setValue, reset, getValues } = useForm();

  useEffect(() => {
    if (editId) {
      viewAgentById(editId).then((res) => {
        setValue('firstName', res.data.firstName);
        setValue('lastName', res.data.lastName);
        setValue('email', res.data.email);
        setValue('phone', res.data.phone);
        setValue('facebook', res.data.facebook);
        setValue('twitter', res.data.twitter);
        setValue('instagram', res.data.instagram);
        setValue('youtube', res.data.youtube);
        setValue('pinterest', res.data.pinterest);
        setValue('tiktok', res.data.tiktok);
        setValue('linkedin', res.data.linkedin);
        setValue('website', res.data.website);
        setValue('stateCodeId', res.data.state.id ? res.data.state : '');
        setValue('location', res.data.location);
        setValue('bioText', res.data.bio);
        setAssetId(res.data.assetId);
        setAssetStatus(res.data.assetStatus);
        setUserProfile(res.data.profilePic);
        setHasSignedOrPaidContract(res.data.signedOrPaidContract);
      });
    } else {
      setValue('status', 0);
    }
  }, [reloadForm]);

  const handleDialog = () => {
    reset();
    closeModel();
  };

  const submitForm = ({
    firstName,
    lastName,
    email,
    phone,
    facebook,
    twitter,
    instagram,
    youtube,
    pinterest,
    tiktok,
    linkedin,
    website,
    stateCodeId,
    bioText,
    location,
  }) => {
    if (!onNextClick) {
      setSnackbarMeesage({
        ...snackbarMeesage,
        message: '',
        type: '',
        show: false,
      });
      setProcessing(true);
      const payload = {
        firstName,
        lastName,
        email,
        phone,
        facebook,
        twitter,
        instagram,
        youtube,
        pinterest,
        tiktok,
        linkedin,
        website,
        stateCodeId: stateCodeId ? Number(stateCodeId.id) : null,
        bioText,
        location,
        profilePic: userProfile,
        isMember: 1,
      };

      if (editId) {
        editAgent(editId, payload)
          .then((res) => {
            setProcessing(false);
            if (res.data.hasBioFiltered) {
              setReloadForm(reloadForm + 1);
              setShowHtmlFilterWarning(true);
            } else {
              handleDialog();
              onSuccess();
            }
          })
          .catch(({ response }) => {
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: response.data.message,
              type: 'error',
              show: true,
            });
            setProcessing(false);
          });
      }
    } else {
      if (tabValue === 0) {
        handleTabChange('_', tabValue + 1);
      }
      onNextClick = false;
    }
  };

  const defaultFormValues = getValues();

  const onFormError = () => {
    if (tabValue === 1) {
      handleTabChange(null, 0);
    }
  };

  const handleCloseHtmlFilteredNotice = () => {
    setShowHtmlFilterWarning(false);
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form
            id="add-agent"
            onSubmit={handleSubmit(submitForm, onFormError)}
            style={{ paddingTop: 10 }}
          >
            <AddAgentForm
              agentId={editId || null}
              assetId={assetId}
              control={control}
              defaultFormValues={defaultFormValues}
              userProfile={userProfile}
              setUserProfile={setUserProfile}
              setUploading={setUploading}
              assetStatus={assetStatus}
              hasSignedOrPaidContract={hasSignedOrPaidContract}
              module={module}
              isMember={1}
            />
          </form>
        </DialogContent>
        {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}

        <DialogActions className={classes2.spaceBetween}>
          <div>
            {!editId && (
              <Button
                variant="contained"
                color="primary"
                disableElevation
                type="submit"
                endIcon={processing && <CircularProgress size={25} />}
                disabled={processing || uploading}
                form="add-agent"
                className={classes2.addAgentFormBtn}
                onClick={() => {
                  onNextClick = false;
                }}
              >
                Add {labelWordCase}
              </Button>
            )}
          </div>
          <div>
            {editId && (
              <Button
                variant="contained"
                color="primary"
                disableElevation
                type="submit"
                endIcon={processing && <CircularProgress size={25} />}
                disabled={processing || uploading}
                form="add-agent"
                className={classes2.addAgentFormBtn}
              >
                Submit
              </Button>
            )}
            <Button variant="contained" onClick={handleDialog} disableElevation>
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      {showHtmlFilterWarning && <BioHtmlFiltered closeModel={handleCloseHtmlFilteredNotice} />}
    </>
  );
};

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  editId: PropTypes.number,
  title: PropTypes.string,
  module: PropTypes.number.isRequired,
};
FormDialog.defaultProps = {
  editId: 0,
  title: '',
};

export default FormDialog;
