import { deleteApiCall, getApiCall, postApiCall } from '../utils/Api';
import { zoneTypeValues } from '../config/DataValues';

const listAll = async (page, params, module = zoneTypeValues.AGENTS) => {
  const result = await getApiCall(
    `/zone/list?module=${module}${page ? `&page=${page}` : ''}${params ? `&${params}` : ''}`
  );
  return result.data;
};

export const creatZone = async (data, module = zoneTypeValues.AGENTS) => {
  const result = await postApiCall(`/zone/create?module=${module}`, data);
  return result.data;
};

export const viewZoneById = async (id) => {
  const result = await getApiCall(`/zone/${id}/view`);
  return result.data;
};

export const editZone = async (id, data, module = zoneTypeValues.AGENTS) => {
  const result = await postApiCall(`/zone/${id}/update?module=${module}`, data);
  return result.data;
};

export const removeZone = async (id) => {
  const result = await deleteApiCall(`/zone/${id}/remove`);
  return result.data;
};

export const viewAgents = async (id) => {
  const result = await getApiCall(`/zone/${id}/agents`);
  return result.data;
};

export const searchZone = async (searchText, module = zoneTypeValues.AGENTS) => {
  const result = await getApiCall(`/zone/search?searchText=${searchText}&module=${module}`);
  return result.data;
};

export const validateZipCode = async (data, module = zoneTypeValues.AGENTS) => {
  const result = await postApiCall(`/zone/validate-zipcode?module=${module}`, data);
  return result.data;
};

export const listAllZoneNames = async (module = zoneTypeValues.AGENTS) => {
  const result = await getApiCall(`/zone/list/all?module=${module}`);
  return result.data;
};

export const checkContracts = async (id) => {
  const result = await getApiCall(`/zone/${id}/check-contracts`);
  return result.data;
};

export default listAll;
