import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import pageStyle from '../../theme/styles/DashboardNav';

const useStyles = makeStyles(pageStyle);

const DashboardNavbar = ({ handleModuleChange, dashboardModules, activeModule }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={3}>
        <Card
          variant="outlined"
          className={activeModule === dashboardModules[0] ? classes.navCardActive : classes.navCard}
          onClick={() => handleModuleChange(dashboardModules[0])}
        >
          <CardContent>
            <NotificationsIcon className={classes.navIcon} />
            <Typography variant="h5" className={classes.navTitle}>
              Notifications
            </Typography>
            {/* <Typography variant="body2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua.
            </Typography> */}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Card
          variant="outlined"
          className={activeModule === dashboardModules[1] ? classes.navCardActive : classes.navCard}
          onClick={() => handleModuleChange(dashboardModules[1])}
        >
          <CardContent>
            <ViewCarouselIcon className={classes.navIcon} />
            <Typography variant="h5" className={classes.navTitle}>
              Banners
            </Typography>
            {/* <Typography variant="body2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua.
            </Typography> */}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Card
          variant="outlined"
          className={activeModule === dashboardModules[2] ? classes.navCardActive : classes.navCard}
          onClick={() => handleModuleChange(dashboardModules[2])}
        >
          <CardContent>
            <RecordVoiceOverIcon className={classes.navIcon} />
            <Typography variant="h5" className={classes.navTitle}>
              Bulletin Board
            </Typography>
            {/* <Typography variant="body2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua.
            </Typography> */}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Card
          variant="outlined"
          className={activeModule === dashboardModules[3] ? classes.navCardActive : classes.navCard}
          onClick={() => handleModuleChange(dashboardModules[3])}
        >
          <CardContent>
            <AssignmentTurnedInIcon className={classes.navIcon} />
            <Typography variant="h5" className={classes.navTitle}>
              Fast Track
            </Typography>
            {/* <Typography variant="body2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua.
            </Typography> */}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

DashboardNavbar.propTypes = {
  handleModuleChange: PropTypes.func.isRequired,
  dashboardModules: PropTypes.array.isRequired,
  activeModule: PropTypes.string.isRequired,
};

export default DashboardNavbar;
