import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import SnackbarMessage from '../SnackbarMessage';
import { deleteAgentShowVideo } from '../../services/ShowSegments';
import { trackUserClick } from '../../utils/Activity';

const ConfirmDialog = ({ deleteId, videoId, closeModel, onSuccess }) => {
  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const getDetails = window.localStorage.getItem('userDetail');
  const loggedInAgentId = getDetails ? Number(JSON.parse(getDetails).id) : 0;

  const handleDialog = () => {
    closeModel();
  };

  const handleConfirmation = (e) => {
    e.preventDefault();
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });

    deleteAgentShowVideo(deleteId, { videoId })
      .then(() => {
        trackUserClick(109, { agent_id: loggedInAgentId, show_segment_subs_call_id: deleteId });
        setProcessing(false);
        handleDialog();
        onSuccess();
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
        setProcessing(false);
      });
  };

  return (
    <Dialog open onClose={handleDialog}>
      <DialogTitle id="alert-dialog-title">Delete Submission</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Do you want to delete your uploaded video? This action can not be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirmation}
          color="secondary"
          autoFocus
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing}
        >
          Delete
        </Button>
        <Button onClick={handleDialog} color="primary">
          Cancel
        </Button>
      </DialogActions>
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  closeModel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  deleteId: PropTypes.number.isRequired,
  videoId: PropTypes.number.isRequired,
};

export default ConfirmDialog;
