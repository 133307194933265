import {
  Box,
  Grid,
  Button,
  CardMedia,
  Card,
  Paper,
  Typography,
  CardContent,
  CardActions,
  makeStyles,
  CardActionArea,
  Tab,
  Tabs,
  CircularProgress,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import VideoPlayer from 'react-video-js-player';
import { listResourceContentList } from '../../services/Resource';
// import ProfilePics from '../../components/branding/ProfilePics';
import profilePicStyle from '../../theme/styles/ProfilePics';
import Private from '../../layout/Private';
import ViewResource from '../../components/resources/ViewResource';
import { resourceCategories } from '../../config/DataValues';
import { trackUserClick } from '../../utils/Activity';
import RoutePaths from '../../config/Routes';

const useStyles = makeStyles(profilePicStyle);
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function getTabID(action) {
  const query = new URLSearchParams(window.location.search);
  if (action === 'category') {
    if (query.get('category')) {
      return decodeURIComponent(query.get('category'));
    }

    const currentUrl = window.location.href;
    if (currentUrl.includes(RoutePaths.RESOURCES_ARCHIVE_SUCCESS_CALLS)) {
      return resourceCategories[1];
    }

    // const urlParts = currentUrl.split('#');
    // const anchor = urlParts.length > 1 ? urlParts[1] : '';
    return resourceCategories[3];
    // return anchor === 'profile-pics' ? 'profilepics' : resourceCategories[3];
  }
  return query.get('id');
}

const Resources = () => {
  const pageName = 'Resources';
  const classes = useStyles();
  const getDetails = window.localStorage.getItem('userDetail');
  const userId = getDetails ? Number(JSON.parse(getDetails).id) : 0;
  const [resourceData, setResourceData] = useState(null);
  const [viewModel, setViewModel] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedId, setSelectedId] = useState(getTabID('id') || 0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(getTabID('category'));
  const [loading, setLoading] = useState(false);

  const openViewModel = (item) => {
    if (selectedCategory === resourceCategories[0]) {
      trackUserClick(70, { agent_id: userId, resource_id: item.id });
    } else if (selectedCategory === resourceCategories[1]) {
      trackUserClick(72, { agent_id: userId, resource_id: item.id });
    } else if (selectedCategory === resourceCategories[2]) {
      trackUserClick(73, { agent_id: userId, resource_id: item.id });
    } else if (selectedCategory === resourceCategories[3]) {
      trackUserClick(111, { agent_id: userId, resource_id: item.id });
    }

    setSelectedItem(item);
    setViewModel(true);
  };

  useEffect(() => {
    setLoading(true);
    if (selectedCategory === resourceCategories[0]) {
      trackUserClick(16);
    } else if (selectedCategory === resourceCategories[1]) {
      trackUserClick(18);
    } else if (selectedCategory === 'profilepics') {
      trackUserClick(33);
    } else if (selectedCategory === resourceCategories[2]) {
      trackUserClick(13);
    } else if (selectedCategory === resourceCategories[3]) {
      trackUserClick(110);
    }

    if (selectedCategory === 'profilepics') {
      setLoading(false);
    } else {
      listResourceContentList(selectedCategory).then((res) => {
        const data = res.data.rows;
        setResourceData(data);
        const selectedRes = data.filter((r) => r.id === Number(selectedId));
        if (selectedRes.length > 0) {
          openViewModel(selectedRes[0]);
          setViewModel(true);
        }
        setLoading(false);
      });
    }
  }, [selectedCategory]);

  useEffect(() => {
    setSelectedCategory(getTabID('category'));
  }, [window.location.pathname]);

  const download = async (item) => {
    if (selectedCategory === resourceCategories[0]) {
      trackUserClick(71, { agent_id: userId, resource_id: item.id });
    } else if (selectedCategory === resourceCategories[2]) {
      trackUserClick(74, { agent_id: userId, resource_id: item.id });
    }
    window.open(item.fileUrl);
  };

  return (
    <Private pageName={pageName}>
      <Box m={2} p={2} component={Paper} elevation={0} minHeight="85vh">
        <Box mt={2} mb={2}>
          {selectedCategory !== resourceCategories[1] && (
            <Tabs
              value={selectedCategory}
              aria-label="simple tabs example"
              indicatorColor="primary"
              onChange={(_, v) => {
                setSelectedCategory(v);
              }}
            >
              <Tab value={resourceCategories[3]} label={resourceCategories[3]} {...a11yProps(0)} />
              <Tab value={resourceCategories[2]} label={resourceCategories[2]} {...a11yProps(0)} />
              {/* <Tab value="profilepics" label="Profile Pics" {...a11yProps(0)} /> */}
            </Tabs>
          )}
        </Box>
        {/* {selectedCategory === 'profilepics' && (
          <Box mt={2}>
            <ProfilePics />
          </Box>
        )} */}
        <div>
          <Grid container spacing={2}>
            {loading && (
              <Box display="flex" justifyContent="center">
                <CircularProgress style={{ marginTop: 30, marginBottom: 30 }} />
              </Box>
            )}
            {!loading &&
              selectedCategory !== 'profilepics' &&
              (!resourceData || resourceData.length === 0) && (
                <div style={{ paddingTop: 50, paddingLeft: 20, fontSize: 15 }}>
                  {selectedCategory} - resources not found.
                </div>
              )}
            {!loading &&
              selectedCategory !== 'profilepics' &&
              resourceData &&
              resourceData.length > 0 &&
              resourceData.map((item) => (
                <Grid item key={`card-res-${item.id}`}>
                  <Card component={Box} width={300}>
                    <CardActionArea className={classes.resourcesCardAction}>
                      {item.type === 0 && (
                        <CardMedia
                          component="img"
                          alt=""
                          image={item.fileUrl}
                          title=""
                          height={200}
                          width={300}
                          className={classes.contentEngineImage}
                        />
                      )}

                      {item.type === 1 && (
                        <>
                          {item.videoThumb && (
                            <CardMedia
                              component="img"
                              alt=""
                              image={item.videoThumb}
                              title=""
                              height={200}
                              width={300}
                              className={classes.contentEngineImage}
                            />
                          )}
                          {!item.videoThumb && (
                            <VideoPlayer
                              controls={false}
                              src={item.fileUrl}
                              width="300"
                              height="200"
                              hideControls={[
                                'play',
                                'volume',
                                'seekbar',
                                'timer',
                                'playbackrates',
                                'fullscreen',
                              ]}
                              config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                            />
                          )}
                        </>
                      )}
                    </CardActionArea>
                    <CardContent className={classes.resourcesContent}>
                      <Typography variant="body1" className={classes.resourceTitle}>
                        {item.shortTitle}
                      </Typography>
                    </CardContent>
                    <CardActions className={classes.cardAction}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        size="small"
                        onClick={() => openViewModel(item)}
                      >
                        {item.type === 1 ? 'Play' : 'Preview'}
                      </Button>
                      {item.category !== resourceCategories[1] &&
                        item.category !== resourceCategories[3] && (
                          <Button
                            variant="contained"
                            color="primary"
                            type="button"
                            size="small"
                            onClick={() => download(item)}
                          >
                            Download
                          </Button>
                        )}
                    </CardActions>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </div>
      </Box>

      {viewModel && (
        <ViewResource
          onClose={() => {
            setViewModel(false);
          }}
          userId={userId}
          id={selectedItem.id}
        />
      )}
    </Private>
  );
};

export default Resources;
