import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Chip,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { Add, Done, MoreVert } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import listAgentCards, { makeDefaultCard, removeCard } from '../../services/Billing';
import listingStyle from '../../theme/styles/TableListing';
import AddCard from './AddCard';
import ConfirmDialog from './ConfirmDialog';
import SnackbarMessage from '../SnackbarMessage';

const useStyles = makeStyles(listingStyle);

const ManageCards = () => {
  const classes = useStyles();
  const [cards, setCards] = useState([]);
  const [editData, setEditData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addCard, setAddCard] = useState(false);
  const [refreshCardList, setRefreshCardList] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [makeDefaultModel, setMakeDefaultModal] = useState(false);
  const [makeDefaultProcessing, setMakeDefaultProcessing] = useState(false);

  const [removeCardModel, setRemoveCardModal] = useState(false);
  const [removeCardProcessing, setRemoveCardProcessing] = useState(false);

  const [actionId, setActionId] = useState(null);

  useEffect(() => {
    setCards([]);
    setLoading(true);
    listAgentCards()
      .then(({ data }) => {
        setCards(data);
        setLoading(false);
      })
      .catch(() => {
        setCards([]);
        setLoading(false);
      });
  }, [refreshCardList]);

  const toggleAddCard = () => {
    setEditData(null);
    setActionId(null);
    setAddCard(!addCard);
  };

  const toggleMakeDefault = () => {
    setMakeDefaultModal(!makeDefaultModel);
  };
  const toggleRemoveCard = () => {
    setRemoveCardModal(!removeCardModel);
  };

  const markAsDefault = async () => {
    setMakeDefaultProcessing(true);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });

    makeDefaultCard({ paymentMethodId: actionId })
      .then(() => {
        setRefreshCardList(!refreshCardList);
        setMakeDefaultProcessing(false);
        setActionId(null);
        toggleMakeDefault();
      })
      .catch(() => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: `Some error occurred, Please try again.`,
          type: 'error',
          show: true,
        });
        setMakeDefaultProcessing(false);
      });
  };

  const removeAgentCard = () => {
    setRemoveCardProcessing(true);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });

    removeCard({ paymentMethodId: actionId })
      .then(() => {
        setRefreshCardList(!refreshCardList);
        setRemoveCardProcessing(false);
        setActionId(null);
        toggleRemoveCard();
      })
      .catch(() => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: `Some error occurred, Please try again.`,
          type: 'error',
          show: true,
        });
        setRemoveCardProcessing(false);
      });
  };

  const handleActions = (row, action) => {
    if (action === 'edit') {
      setEditData(row);
      setActionId(row.id);
      setAddCard(true);
    } else if (action === 'remove') {
      setActionId(row.id);
      toggleRemoveCard();
    }
  };

  return (
    <div>
      <Box>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={toggleAddCard}
            startIcon={<Add />}
            disableElevation
          >
            Add new card
          </Button>
        </Box>
        <TableContainer component={Paper} className={classes.tableData} aria-label="cards table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="20%">Card Type</TableCell>
                <TableCell width="20%">Last Four Digit</TableCell>
                <TableCell width="20%">Expiry</TableCell>
                <TableCell width="20%">Default Card</TableCell>
                <TableCell width="20%" align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading &&
                [1, 2, 3, 4, 5].map((val) => (
                  <TableRow key={`card-${val}`}>
                    <TableCell component="th" scope="row">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                  </TableRow>
                ))}
              {cards.map((row) => (
                <TableRow>
                  <TableCell>{row.cardDetails.brand.toUpperCase()}</TableCell>
                  <TableCell>{`....${row.cardDetails.last4}`}</TableCell>
                  <TableCell>
                    {row.cardDetails.exp_month} / {row.cardDetails.exp_year}
                  </TableCell>
                  <TableCell>
                    {row.isDefault ? (
                      <Chip label="Default Card" color="primary" size="small" />
                    ) : (
                      <Button
                        color="default"
                        size="small"
                        startIcon={<Done />}
                        onClick={() => {
                          setActionId(row.id);
                          toggleMakeDefault();
                        }}
                        disableElevation
                      >
                        Mark As Default
                      </Button>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <ActionMenu id={row.id} selectAction={handleActions} row={row} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {addCard && (
        <AddCard
          toggleAddCard={toggleAddCard}
          cardData={editData}
          editId={actionId}
          refreshList={() => setRefreshCardList(!refreshCardList)}
        />
      )}

      {makeDefaultModel && (
        <ConfirmDialog
          title="Mark As Default"
          message="Are you sure you want to mark this card as default card?"
          onClose={() => {
            setActionId(null);
            setMakeDefaultProcessing(false);
            setMakeDefaultModal(false);
          }}
          approveButtonText="Yes"
          onApprove={markAsDefault}
          approveProcessing={makeDefaultProcessing}
        />
      )}

      {removeCardModel && (
        <ConfirmDialog
          title="Remove Card"
          message="Are you sure you want to remove this card?"
          onClose={() => {
            setActionId(null);
            setRemoveCardProcessing(false);
            setRemoveCardModal(false);
          }}
          approveButtonText="Yes"
          onApprove={removeAgentCard}
          approveProcessing={removeCardProcessing}
        />
      )}

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </div>
  );
};

const ActionMenu = ({ row, selectAction }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (action) => {
    selectAction(row, action);
    handleClose();
  };
  return (
    <div>
      <Button aria-controls={`simple-menu${row.id}`} aria-haspopup="true" onClick={handleClick}>
        <MoreVert />
      </Button>
      <Menu
        id={`simple-menu${row.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleMenuClick('edit');
          }}
          button
        >
          Update Billing Info
        </MenuItem>
        {!row.isDefault && (
          <MenuItem
            onClick={() => {
              handleMenuClick('remove');
            }}
            button
          >
            Remove
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
ActionMenu.propTypes = {
  row: PropTypes.objectOf().isRequired,
  selectAction: PropTypes.func.isRequired,
  // rowAgentType: PropTypes.number.isRequired,
};

export default ManageCards;
