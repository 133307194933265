// Agent Ce Post Status
const AgentCePostStatus = {
  SCHEDULED: {
    value: 1,
    text: 'Scheduled',
  },
  SKIPPED: {
    value: 2,
    text: 'Skipped',
  },
  PAST: {
    value: 3,
    text: 'Past',
  },
  POSTED: {
    value: 4,
    text: 'Posted',
  },
  UNSUCCESSFUL: {
    value: 5,
    text: 'Unsuccessful',
  },
};

export default AgentCePostStatus;
