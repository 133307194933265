import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Box, Button, Chip, MenuItem, TableSortLabel, Menu } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ArrowDownward, ImportExport, MoreVert, Pageview } from '@material-ui/icons';
import PropTypes from 'prop-types';
import RoutePaths from '../../config/Routes';
import PrivateWrapper from '../../layout/Private';
import TableToolbar from '../../components/TableToolbar';
import listingStyle from '../../theme/styles/TableListing';
import listAll from '../../services/Zones';
import FormDialog from '../../components/zone/FormDialog';
import ConfirmDialog from '../../components/zone/ConfirmDialog';
import WarningDialog from '../../components/zone/WarningDialog';
import SnackbarMessage from '../../components/SnackbarMessage';
import AgentCell from '../../components/zone/AgentCell';
import { viewStateById } from '../../services/State';
import ViewZone from '../../components/zone/ViewZone';
import UserTypes from '../../config/UserTypes';
import { checkZipCodeAvailability } from '../../services/Zipcode';
import { getZoneType } from '../../utils/GetModuleType';
import { zoneTypeValues } from '../../config/DataValues';

const useStyles = makeStyles(listingStyle);

// Zones management
const Zones = () => {
  const classes = useStyles();
  const history = useHistory();
  const rowZoneType = getZoneType();
  const pageName = rowZoneType === zoneTypeValues.AGENTS ? 'Zones' : 'Lender Zones';
  const agentLabel = rowZoneType === zoneTypeValues.AGENTS ? 'Agents' : 'Lenders';
  const agentLabelSmallSingular = rowZoneType === zoneTypeValues.AGENTS ? 'agent' : 'lender';

  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;
  const IS_READONLY =
    userType === UserTypes.SALES_PERSON.value || userType === UserTypes.SUPPORT.value;

  const query = new URLSearchParams(window.location.search);
  const qstateId = query.get('stateId');
  const qtype = query.get('type');

  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reloadRows, setReloadRows] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const [openModel, setOpenModel] = useState(false);
  const [confirmModel, setConfirmModel] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [viewModel, setViewModel] = useState(false);

  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const [filterdIDText, setFilterdIDText] = useState(null);
  const [zipNotAssigned, setZipNotAssigned] = useState(false);

  const defaultSnackMessageState = {
    show: false,
    type: '',
    message: '',
  };
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const columns = [
    { name: 'Name', id: 'name', width: '30%' },
    { name: 'State', id: 'state_code_id', width: '15%' },
    { name: 'Type', id: 'type', width: '20%' },
    { name: 'Sold Status', id: 'purchaseStatus', width: '10%' },
    { name: agentLabel, id: 'agents', width: '10%', align: 'right' },
  ];

  const getParams = () => {
    let queryString = '1=1';
    if (qstateId) queryString += `&stateId=${qstateId}`;
    if (sortBy) queryString += `&sortBy=${sortBy}`;
    if (orderBy) queryString += `&orderBy=${orderBy}`;
    if (qtype) queryString += `&type=${qtype}`;
    if (searchText) queryString += `&searchText=${searchText}`;

    return queryString.substring(4);
  };

  const isZipCode = (code) => {
    // code should not be blank
    // code should have max length of 5 chars
    // code should have number type
    const numberPattern = /^\d+$/;
    if (code && code.length === 5 && numberPattern.test(code)) {
      return true;
    }
    return false;
  };

  const checkZipCode = (text) => {
    if (isZipCode(text)) {
      checkZipCodeAvailability(text).then((res) => {
        if (res.data?.isAvailable) {
          setZipNotAssigned(true);
        }
      });
    }
  };

  useEffect(() => {
    setFilterdIDText(null);
    setZipNotAssigned(false);
    if (qstateId) {
      viewStateById(qstateId).then((res) => {
        setFilterdIDText(res.data.code);
      });
    }
    setDataLoaded(false);
    listAll(activePage, getParams(), rowZoneType)
      .then((res) => {
        if (res.data.rows.length === 0 && searchText) {
          checkZipCode(searchText);
        }
        setRows(res.data.rows);
        setTotalPages(res.data.totalPages);
        setDataLoaded(true);
      })
      .catch(() => {
        setDataLoaded(true);
      });
  }, [reloadRows, activePage]);

  useEffect(() => {
    setDataLoaded(false);
    setActivePage(1);
    setSearchText('');
    setSortBy('');
    setOrderBy('');
    setReloadRows(!reloadRows);
  }, [rowZoneType]);

  const searchList = () => {
    setSnackbarMeesage({
      show: false,
      message: '',
      type: '',
    });
    setActivePage(1);
    setReloadRows(!reloadRows);
  };

  const sortHandler = (colId) => {
    setActivePage(1);
    setSortBy(colId);
    setOrderBy(orderBy === 'asc' ? 'desc' : 'asc');
    setReloadRows(!reloadRows);
  };

  const refreshList = () => {
    setReloadRows(!reloadRows);
  };

  const editData = (id) => {
    setEditId(id);
    setOpenModel(true);
  };

  const deleteData = (id) => {
    setDeleteId(id);
    setConfirmModel(true);
  };

  const viewData = (id) => {
    setEditId(id);
    setViewModel(true);
  };

  const resetList = () => {
    setSearchText('');
    setOrderBy('');
    setSortBy('');
    setReloadRows(!reloadRows);
    setActivePage(1);
    if (rowZoneType === zoneTypeValues.LENDERS) {
      history.push(RoutePaths.LENDER_ZONES);
    } else {
      history.push(RoutePaths.ZONES);
    }
  };

  const clearURLquery = () => {
    setFilterdIDText(null);
    setReloadRows(!reloadRows);
    setActivePage(1);
    if (rowZoneType === zoneTypeValues.LENDERS) {
      history.push(RoutePaths.LENDER_ZONES);
    } else {
      history.push(RoutePaths.ZONES);
    }
  };

  const handleToggleWarning = () => {
    setWarningModal(!warningModal);
  };

  const handleActions = (row, action) => {
    if (action === 'edit') {
      editData(row.id);
    } else if (action === 'delete') {
      if (row.purchaseStatus !== 'open' || row.agents > 0) {
        handleToggleWarning();
      } else {
        deleteData(row.id);
      }
    } else if (action === 'view') {
      viewData(row.id);
    }
  };

  return (
    <PrivateWrapper pageName={pageName}>
      <>
        <div className={classes.filterToolbar}>
          <TableToolbar
            refreshList={refreshList}
            searchList={searchList}
            getSearchText={(e) => {
              setSearchText(encodeURIComponent(e.target.value));
            }}
            addNew={() => setOpenModel(true)}
            resetList={resetList}
            showAddOption={!IS_READONLY}
            inputSearch={decodeURIComponent(searchText)}
            forceInputTextChange
          />
        </div>
        {filterdIDText && (
          <Box mb={2}>
            Filtered by state: <Chip label={filterdIDText} onDelete={clearURLquery} />
          </Box>
        )}
        {qtype && (
          <Box mb={2}>
            Filtered by sold status:{' '}
            <Chip label={qtype.charAt(0).toUpperCase() + qtype.slice(1)} onDelete={clearURLquery} />
          </Box>
        )}
        <TableContainer component={Paper}>
          <Table className={classes.tableData} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((col) => (
                  <TableCell width={col.width} align={col.align || ''}>
                    <TableSortLabel
                      active={sortBy === col.id}
                      direction={sortBy === col.id ? orderBy : 'asc'}
                      onClick={() => sortHandler(col.id)}
                      IconComponent={sortBy !== col.id ? ImportExport : ArrowDownward}
                    >
                      <span>{col.name}</span>
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell align="right" width="15%">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!dataLoaded &&
                [1, 2, 3, 4, 5].map((val) => (
                  <TableRow key={`zn-${val}`}>
                    <TableCell component="th" scope="row">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                  </TableRow>
                ))}
              {dataLoaded &&
                (rows.length > 0 ? (
                  rows.map((row) => (
                    <TableRow key={`zn-${row.id}`}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell>{row.state}</TableCell>
                      <TableCell>{row.type}</TableCell>
                      <TableCell>
                        {row.purchaseStatus === 'open' && (
                          <span className={classes.statusActive}>Open</span>
                        )}
                        {row.purchaseStatus === 'half-sold' && (
                          <span className={classes.statusPending}>Half Sold</span>
                        )}
                        {row.purchaseStatus === 'sold' && (
                          <span className={classes.statusInactive}>Sold</span>
                        )}
                        {row.purchaseStatus === 'pending-cancelation' && (
                          <Tooltip title={row.toBeCanceledTooltip} placement="top">
                            <span className={classes.statusPendingCancelation}>
                              Pending Cancelation
                            </span>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <AgentCell
                          text={row.agents}
                          title={`${row.name} - ${agentLabel}`}
                          zoneId={row.id}
                          agentType={rowZoneType}
                        />
                      </TableCell>
                      {!IS_READONLY ? (
                        <TableCell align="right">
                          <ActionMenu
                            id={row.id}
                            selectAction={handleActions}
                            row={row}
                            userType={userType}
                          />
                        </TableCell>
                      ) : (
                        <TableCell align="right">
                          <IconButton
                            aria-label="delete"
                            className={classes.deleteBtn}
                            onClick={() => handleActions(row, 'view')}
                          >
                            <Pageview fontSize="small" />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      size="medium"
                      colSpan={6}
                      className={classes.noRecord}
                      style={{ padding: 20, fontSize: 15 }}
                    >
                      {zipNotAssigned && 'Zip Code Not Assigned'}
                      {!zipNotAssigned && 'No records found'}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {dataLoaded && totalPages > 1 && (
          <Pagination
            count={totalPages}
            showFirstButton
            showLastButton
            className={classes.tablePagination}
            onChange={(_, pageNumber) => {
              setActivePage(pageNumber);
              setReloadRows(!reloadRows);
            }}
            page={activePage}
          />
        )}

        {openModel && (
          <FormDialog
            open={openModel}
            closeModel={() => {
              setOpenModel(false);
              setEditId(null);
            }}
            title={editId ? 'Edit Zone' : 'Add Zone'}
            onSuccess={(res) => {
              if (res > 0) {
                setSnackbarMeesage({
                  ...snackbarMeesage,
                  show: true,
                  message: 'Some of the zip code(s) are already assigned to the other zone.',
                  type: 'warning',
                });
                setTimeout(() => {
                  setSnackbarMeesage({
                    ...defaultSnackMessageState,
                  });
                }, 5000);
              }
              setReloadRows(!reloadRows);
            }}
            editId={editId}
            module={rowZoneType}
          />
        )}

        {confirmModel && (
          <ConfirmDialog
            open={confirmModel}
            closeModel={() => {
              setConfirmModel(false);
              setDeleteId(null);
            }}
            title="Zone"
            onSuccess={() => {
              setReloadRows(!reloadRows);
            }}
            deleteId={deleteId}
          />
        )}
        {warningModal && (
          <WarningDialog
            title="Warning"
            description={`Zone information can not be deleted if zone is occupied by any ${agentLabelSmallSingular}. Please release this zone from assigned zones section of respective ${agentLabelSmallSingular}s in order to delete this zone.`}
            closeModel={handleToggleWarning}
          />
        )}

        {viewModel && (
          <ViewZone
            open={viewModel}
            closeModel={() => {
              setViewModel(false);
              setEditId(null);
            }}
            title="View Zone"
            editId={editId}
          />
        )}
        {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      </>
    </PrivateWrapper>
  );
};

const ActionMenu = ({ row, selectAction, userType }) => {
  let allowEdit = false;
  let allowDelete = false;
  if (userType === UserTypes.SUPER_ADMIN.value || userType === UserTypes.ADMIN.value) {
    allowEdit = true;
    allowDelete = true;
  } else if (userType === UserTypes.SALES_MANAGER.value && row.purchaseStatus === 'open') {
    allowEdit = true;
    allowDelete = true;
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (action) => {
    selectAction(row, action);
    handleClose();
  };
  return (
    <div>
      <Button aria-controls={`simple-menu${row.id}`} aria-haspopup="true" onClick={handleClick}>
        <MoreVert />
      </Button>
      <Menu
        id={`simple-menu${row.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleMenuClick('view');
          }}
          button
        >
          View
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClick('edit');
          }}
          disabled={!allowEdit}
          button
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClick('delete');
          }}
          disabled={!allowDelete}
          button
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};
ActionMenu.propTypes = {
  row: PropTypes.objectOf().isRequired,
  selectAction: PropTypes.func.isRequired,
  userType: PropTypes.number.isRequired,
};

export default Zones;
