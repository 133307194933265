import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import { Box, IconButton, TableContainer, Paper, makeStyles, Button } from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import listingStyle from '../../theme/styles/TableListing';
import VideoDialog from './VideoDialog';
import SnackbarMessage from '../SnackbarMessage';

const useStyles = makeStyles(listingStyle);

const Videos = () => {
  const classes = useStyles();
  const [isOpenVideoModel, setIsOpenVideoModel] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const videos = [
    {
      id: 1,
      title: "Testimonial Video from Agent NRP's",
      url: 'https://player.vimeo.com/video/728407162?h=8682b57570&title=0',
      vimeoUrl: 'https://vimeo.com/728407162',
      link: 'https://sales.negotiators.tv/video',
    },
    {
      id: 2,
      title: 'The Negotiators Opening Video',
      url: 'https://player.vimeo.com/video/734087732?h=3cb232b50d',
      vimeoUrl: 'https://vimeo.com/734087732',
      link: 'https://sales.negotiators.tv/opening',
    },
    {
      id: 3,
      title: 'NRP Where to get help',
      url: 'https://player.vimeo.com/video/734086808?h=58f5e2c1ac',
      vimeoUrl: 'https://vimeo.com/734086808',
      link: 'https://sales.negotiators.tv/help',
    },
    {
      id: 4,
      title: 'Introducing "The Negotiators"',
      url: 'https://player.vimeo.com/video/735147589?h=db89459905',
      vimeoUrl: 'https://vimeo.com/735147589',
      link: 'http://agents.negotiators.tv/video',
    },
    {
      id: 5,
      title: 'Spotlight/intro Example',
      url: 'https://player.vimeo.com/video/734063847?h=0f018ac26c',
      vimeoUrl: 'https://vimeo.com/734063847',
      link: 'https://sales.negotiators.tv/launch',
    },
    {
      id: 6,
      title: 'Welcome from The Executive Producer',
      url: 'https://player.vimeo.com/video/734068334?h=eba14e7ff8',
      vimeoUrl: 'https://vimeo.com/734068334',
      link: 'https://sales.negotiators.tv/ep',
    },
    {
      id: 7,
      title: 'Negotiator Nugget Example',
      url: 'https://player.vimeo.com/video/734067987?h=eec5cf6b0f',
      vimeoUrl: 'https://vimeo.com/734067987',
      link: 'https://sales.negotiators.tv/nugget-example',
    },
    {
      id: 8,
      title: 'Lenders Minute Example',
      url: 'https://player.vimeo.com/video/734071582?h=7af53a90b0',
      vimeoUrl: 'https://vimeo.com/734071582',
      link: 'https://sales.negotiators.tv/lenders-minute',
    },
  ];

  const copyLink = (url) => {
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });
    setTimeout(() => {
      setSnackbarMeesage({
        ...snackbarMeesage,
        message: 'Copied successfully.',
        type: 'success',
        show: true,
      });
      copy(url);
    }, 300);
  };

  const openVideo = (id) => {
    const filterData = videos.filter((el) => el.id === id);
    if (filterData) {
      setSelectedData(filterData[0]);
      setIsOpenVideoModel(true);
    }
  };

  return (
    <>
      <Grid container spacing={2} style={{ marginTop: 50, marginBottom: 20 }}>
        <Grid item xs={8} sm={6}>
          {videos.map((v) => (
            <Box display="flex" flexDirection="row">
              <TableContainer
                component={Paper}
                className={classes.tableData}
                aria-label="cards table"
                style={{ marginTop: 10 }}
              >
                <Box display="flex" flexDirection="row">
                  <Box
                    component="div"
                    sx={{ display: 'inline' }}
                    style={{
                      width: '80%',
                      minHeight: '50px',
                      padding: '10px 5px',
                    }}
                    mr={3}
                  >
                    {v.title}
                  </Box>
                  <Box component="div" sx={{ display: 'inline' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => openVideo(v.id)}
                      size="small"
                      title="Click to Play"
                      style={{ marginTop: 5, marginRight: 10 }}
                    >
                      Play
                    </Button>
                  </Box>
                  <Box component="div" sx={{ display: 'inline' }}>
                    <IconButton
                      onClick={() => copyLink(v.link)}
                      size="small"
                      title="Click to copy"
                      style={{ marginTop: 5 }}
                    >
                      <FileCopy />
                    </IconButton>
                  </Box>
                </Box>
              </TableContainer>
            </Box>
          ))}
          {isOpenVideoModel && (
            <VideoDialog
              closeModel={() => {
                setIsOpenVideoModel(false);
              }}
              data={selectedData}
            />
          )}
        </Grid>
      </Grid>

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </>
  );
};

export default Videos;
