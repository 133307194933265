const getCurrentAppDomain = () => window.location.host;

export const isDefaultApp = () => {
  const currentDomain = getCurrentAppDomain();
  return process.env.REACT_APP_DEFAULT_DOMAIN === currentDomain;
};

export const isContentEngineApp = () => {
  const currentDomain = getCurrentAppDomain();
  return process.env.REACT_APP_CONTENT_ENGINE_DOMAIN === currentDomain;
};

export const redirectToDefaultApp = () => {
  window.location.href = `https://${process.env.REACT_APP_DEFAULT_DOMAIN}`;
};

export const redirectToContentEngineApp = () => {
  window.location.href = `https://${process.env.REACT_APP_CONTENT_ENGINE_DOMAIN}`;
};

export const getUrlToDefaultApp = () => `https://${process.env.REACT_APP_DEFAULT_DOMAIN}`;
export const getUrlToContentEngineApp = () =>
  `https://${process.env.REACT_APP_CONTENT_ENGINE_DOMAIN}`;

export const getAPIBaseUrl = () => {
  if (isDefaultApp()) {
    return process.env.REACT_APP_API_ENDPOINT;
  }
  return process.env.REACT_APP_CONTENT_ENGINE_API;
};

export default getCurrentAppDomain;
