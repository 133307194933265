import green from '@material-ui/core/colors/green';

// Style for application whole loader
const Style = () => ({
  loader: {
    display: 'flex',
    height: '95vh',
    justifyContent: 'center',
    alignItems: 'center',
    color: green[600],
  },
});

export default Style;
