import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { CloudUpload } from '@material-ui/icons';
import ReactS3Uploader from 'react-s3-uploader';
import { createAgentAdVideo, updateAgentAdVideoUrl } from '../../services/AgentAdVideos';
import agentStyle from '../../theme/styles/Agents';
import SnackbarMessage from '../SnackbarMessage';
import { agentAdVideoType } from '../../config/DataValues';

const useStyles = makeStyles(agentStyle);

// eslint-disable-next-line no-unused-vars
const AddAgentAdVideo = ({ onClose, onSuccess, adVideoType }) => {
  const classes = useStyles();
  const hiddenFileInput = useRef(null);
  const getDetails = window.localStorage.getItem('userDetail');
  const loggedInUserId = getDetails ? Number(JSON.parse(getDetails).id) : 0;

  const [progress, setProgress] = useState(0);
  const [fileSelected, setFileSelected] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showVideoProgressTip, setShowVideoProgressTip] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const getSignedUrl = (file, callback) => {
    setSnackbarMeesage({
      message: '',
      type: '',
      show: false,
    });
    if (file.type.split('/')[0] === 'video') {
      const payload = {
        agentId: loggedInUserId,
        objectName: file.name,
        contentType: file.type,
        excludeOverlay: 0, // default value is 0
        adVideoType: agentAdVideoType.PRE_ROLL, // TODO: ask later from user
      };
      createAgentAdVideo(payload)
        .then((res) => {
          setShowVideoProgressTip(true);
          callback(res.data);
          setFileSelected(true);
        })
        .catch(() => {
          setHasError(true);
          setErrorMessage('Something went wrong.');
        });
    } else {
      setSnackbarMeesage({
        message: `Please select video file only.`,
        type: 'error',
        show: true,
      });
    }
  };

  const onProgress = (p) => {
    setProgress(p);
  };
  const onError = (error) => {
    setHasError(true);
    setErrorMessage(error);
  };
  const onFinish = (data) => {
    setProgress(100);
    setProgress(0);
    setFileSelected(false);

    const payload = {
      agentId: loggedInUserId,
      adVideoId: data.adVideoId,
      url: data.publicUrl,
    };
    updateAgentAdVideoUrl(payload)
      .then(() => {
        onSuccess();
        setFileSelected(true);
      })
      .catch(() => {
        setHasError(true);
        setErrorMessage('Something went wrong.');
      });
  };

  return (
    <Dialog open fullWidth maxWidth="md">
      <DialogTitle>
        <div className={classes.titleContainer}>
          <Typography variant="h6">Add New Intro Video</Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <ReactS3Uploader
          getSignedUrl={getSignedUrl}
          accept="video/*"
          onProgress={onProgress}
          onError={onError}
          onFinish={onFinish}
          uploadRequestHeaders={{
            'x-amz-acl': 'public-read',
          }}
          contentDisposition="auto"
          inputRef={hiddenFileInput}
          name="videoUpload"
          style={{ width: 0, height: 0, display: 'none' }}
        />

        <Button
          onClick={handleClick}
          fullWidth
          variant="outlined"
          startIcon={<CloudUpload />}
          title={errorMessage}
        >
          {!fileSelected && 'Select Video'}
          {!hasError && fileSelected && progress < 100 && `File Uploading (${progress}%)`}
          {!hasError && fileSelected && progress === 100 && 'File Uploaded'}
          {hasError && 'Error'}
        </Button>
        <div style={{ marginBottom: 20, marginTop: 10, color: 'grey' }}>
          {showVideoProgressTip &&
            `Do not close the window until whole video is uploaded. Current progress - ${progress}% / 100%`}
        </div>
        <div style={{ marginTop: 50 }}>
          The video uploaded will get failed or auto-reject if it fails any of the following
          requirements:
          <br />
          <ul>
            <li>The video duration must be between 20 to 60 seconds.</li>
            <li>The video resolution must be minimum 1920 x 1080.</li>
            <li>The audio must be clear in the video.</li>
          </ul>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} disableElevation>
          Close
        </Button>
      </DialogActions>

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Dialog>
  );
};

AddAgentAdVideo.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  adVideoType: PropTypes.number.isRequired,
};

export default AddAgentAdVideo;
