/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Grid,
  makeStyles,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format, isPast } from 'date-fns';
import {
  cancelAgentSubscription,
  getAgentPaymentDetails,
  resumeAgentSubscription,
  syncCancelSubWithStripe,
} from '../../services/Agent';
import SnackbarMessage from '../SnackbarMessage';
import { SubscriptionCancellationType } from '../../config/DataValues';
import listingStyle from '../../theme/styles/TableListing';
import contractStyle from '../../theme/styles/Contract';
import { convertContractTimestampToDate } from '../../utils/Datetime';

const useStyles = makeStyles(listingStyle);
const useStyles2 = makeStyles(contractStyle);

const CancelSubscriptionTab = ({ agentId, refreshModalData, onImmediateContractReq }) => {
  const dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const classes2 = useStyles2();

  const [data, setData] = useState({
    agentId,
    isCancellationScheduled: false,
    cancelSubAt: null,
    dateOfFirstPayment: null,
    initialSubStartDate: null,
    pastPaymentLogs: [],
    upcomingPaymentLogs: [],
    stripeEmail: null,
    stripeCredit: 0,
    isContractTermEnded: true,
  });

  const [selectedCancellationType, setSelectedCancellationType] = useState(
    SubscriptionCancellationType.IMMEDIATE.value
  );
  const [cancelAt, setCancelAt] = useState(null);
  const [dateError, setDateError] = useState(false);

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [dataLoadError, setDataLoadError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  useEffect(() => {
    setIsDataLoaded(false);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    getAgentPaymentDetails(agentId)
      .then((res) => {
        setData(res.data);

        if (res.data.isCancellationScheduled) {
          if (res.data.cancellationType === SubscriptionCancellationType.CUSTOM.value) {
            setCancelAt(res.data.cancelSubAt);
            setSelectedCancellationType(SubscriptionCancellationType.CUSTOM.value);
          } else {
            setSelectedCancellationType(res.data.cancellationType);
          }
        }
        setIsDataLoaded(true);
      })
      .catch(({ response }) => {
        setIsDataLoaded(true);
        setDataLoadError(true);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message:
            response?.data?.message || 'Something went wrong while getting subscription details.',
          type: 'error',
          show: true,
        });
      });
  }, [refreshData]);

  const cancelSubscription = () => {
    setProcessing(true);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    if (selectedCancellationType === SubscriptionCancellationType.CUSTOM.value && !cancelAt) {
      setSnackbarMeesage({
        ...snackbarMeesage,
        message: 'Please select date',
        type: 'error',
        show: true,
      });
      return;
    }

    cancelAgentSubscription({
      cancellationType: selectedCancellationType,
      cancelSubAt: cancelAt,
      agentId,
    })
      .then(() => {
        setProcessing(false);
        setRefreshData(!refreshData);
        if (selectedCancellationType === SubscriptionCancellationType.IMMEDIATE.value) {
          window.localStorage.setItem('agentEmail', data.stripeEmail);
          onImmediateContractReq();
        } else {
          refreshModalData();
        }
        setShowConfirmDialog(false);
      })
      .catch(({ response }) => {
        setProcessing(false);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data?.message || 'An unknown error ocuured.',
          type: 'error',
          show: true,
        });
        setShowConfirmDialog(false);
      });
  };

  const resumeSubscription = () => {
    setProcessing(true);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });

    resumeAgentSubscription(agentId)
      .then(() => {
        setProcessing(false);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'Subscription resumed successfully.',
          type: 'success',
          show: true,
        });
        setCancelAt(null);
        setRefreshData(!refreshData);
        refreshModalData();
      })
      .catch(({ response }) => {
        setProcessing(false);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data?.message || 'An unknown error ocuured.',
          type: 'error',
          show: true,
        });
      });
  };

  const cancelSubscriptionSyncWithStripe = () => {
    setProcessing(true);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });

    syncCancelSubWithStripe(agentId)
      .then(() => {
        setProcessing(false);
        setRefreshData(!refreshData);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: 'Subscription synced with stripe successfully.',
          type: 'success',
          show: true,
        });
        window.localStorage.setItem('agentEmail', data.stripeEmail);
        onImmediateContractReq();
      })
      .catch(({ response }) => {
        setProcessing(false);
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data?.message || 'An unknown error ocuured.',
          type: 'error',
          show: true,
        });
      });
  };

  const confirmAction = () => {
    setShowConfirmDialog(true);
  };

  const computeType = (type) => {
    let text = '';
    Object.keys(SubscriptionCancellationType).forEach((k) => {
      if (SubscriptionCancellationType[k].value === type) {
        text = SubscriptionCancellationType[k].text;
      }
    });

    return text;
  };

  const allowedOptions = () => {
    const optns = Object.keys(SubscriptionCancellationType).filter((k) => {
      if (k === 'EOCT' && data.isContractTermEnded) {
        return false;
      }
      return true;
    });

    return optns;
  };

  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);

  return (
    <Box>
      {!isDataLoaded && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}

      {isDataLoaded && !dataLoadError && (
        <Grid container spacing={2} direction="column" flexWrap="noWrap">
          <Grid item xs={12}>
            <TableContainer>
              <Table className={classes2.viewTable}>
                <TableRow>
                  <TableCell width="20%" className={classes2.subInfoItem}>
                    <span className={classes2.subInfoItemBold}>Subscription Start Date </span>
                  </TableCell>
                  <TableCell width="30%" className={classes2.subInfoItem}>
                    <span> {convertContractTimestampToDate(data.initialSubStartDate)}</span>
                  </TableCell>
                  <TableCell width="20%" className={classes2.subInfoItem}>
                    <span className={classes2.subInfoItemBold}>Date of First Payment </span>
                  </TableCell>
                  <TableCell width="30%" className={classes2.subInfoItem}>
                    <span>{convertContractTimestampToDate(data.dateOfFirstPayment)}</span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="20%" className={classes2.subInfoItem}>
                    <span className={classes2.subInfoItemBold}>Stripe Email </span>
                  </TableCell>
                  <TableCell width="30%" className={classes2.subInfoItem}>
                    <span>{data.stripeEmail}</span>
                  </TableCell>
                  <TableCell width="20%" className={classes2.subInfoItem}>
                    <span className={classes2.subInfoItemBold}>Stripe Credit </span>
                  </TableCell>
                  <TableCell width="30%" className={classes2.subInfoItem}>
                    {dollarUS.format(data.stripeCredit)}
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Grid>

          {data.alreadyCancelled && (
            <Grid item xs={12}>
              <Typography component="p">
                It looks like the subscription is not in sync with Stripe and it is already
                cancelled at Stripe. Click the button below to proceed and sync status with Stripe.
              </Typography>
              <Typography component="p" style={{ marginTop: 10 }}>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={cancelSubscriptionSyncWithStripe}
                  disabled={processing}
                  endIcon={processing && <CircularProgress size={25} />}
                >
                  Cancel Subscription
                </Button>
              </Typography>
            </Grid>
          )}

          {!data.alreadyCancelled && (
            <Grid item xs={12}>
              {data.isCancellationScheduled ? (
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography style={{ marginRight: 15 }}>
                    Cancellation At:{' '}
                    {selectedCancellationType !== SubscriptionCancellationType.CUSTOM.value
                      ? computeType(selectedCancellationType)
                      : data.cancelSubAt
                      ? convertContractTimestampToDate(data.cancelSubAt)
                      : '-'}
                  </Typography>
                  {data.allowResumeSubscription && (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      size="small"
                      style={{ marginRight: 10 }}
                      onClick={resumeSubscription}
                      endIcon={processing && <CircularProgress size={25} />}
                      disabled={processing}
                    >
                      Resume Subscription
                    </Button>
                  )}
                  {!data.allowResumeSubscription && (
                    <Typography color="secondary">
                      (The subscription can not be resumed as the zones are sold in other contract.)
                    </Typography>
                  )}
                </Box>
              ) : (
                <>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography style={{ marginRight: 10 }}>Cancel Subscription: </Typography>
                    <Select
                      style={{ marginRight: 10, padding: 0 }}
                      margin="dense"
                      id="cancellation-type"
                      value={selectedCancellationType}
                      onChange={(e) => {
                        setSelectedCancellationType(e.target.value);
                      }}
                      variant="outlined"
                    >
                      {allowedOptions().map((k) => (
                        <MenuItem value={SubscriptionCancellationType[k].value}>
                          {SubscriptionCancellationType[k].text}
                        </MenuItem>
                      ))}
                    </Select>

                    {selectedCancellationType === SubscriptionCancellationType.CUSTOM.value && (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          style={{ marginRight: 10 }}
                          format="MM/dd/yyyy"
                          minDate={minDate}
                          placeholder="MM/DD/YYYY"
                          value={cancelAt}
                          autoOk
                          clearable
                          onChange={(e) => {
                            if (!e || (e && e.toString() === 'Invalid Date') || isPast(e)) {
                              setDateError(true);
                            } else {
                              setDateError(false);
                              setCancelAt(format(new Date(e), 'yyyy-MM-dd'));
                            }
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    )}

                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      style={{ marginRight: 10 }}
                      onClick={confirmAction}
                      disabled={
                        !selectedCancellationType ||
                        (selectedCancellationType === SubscriptionCancellationType.CUSTOM.value &&
                          !cancelAt) ||
                        processing ||
                        dateError
                      }
                      endIcon={processing && <CircularProgress size={25} />}
                    >
                      Confirm
                    </Button>
                  </Box>
                  {cancelAt && !dateError && (
                    <div
                      style={{ marginTop: 10, color: '#24292f' }}
                    >{`The subscription will be cancelled at ${cancelAt} 06:30 AM UTC.`}</div>
                  )}
                </>
              )}
            </Grid>
          )}

          <PastPaymentLogsTable pastPaymentLogs={data.pastPaymentLogs} />

          {!data.alreadyCancelled && (
            <Grid item xs={12}>
              <UpcomingPaymentsTable upcomingPaymentLogs={data.upcomingPaymentLogs} />
            </Grid>
          )}
        </Grid>
      )}
      {dataLoadError && (
        <Grid container spacing={2} direction="column" alignContent="center">
          <Grid item>
            <p>It looks like something is wrong with the contract setup.</p>
          </Grid>
        </Grid>
      )}
      {showConfirmDialog && (
        <ConfirmCancellation
          processing={processing}
          handleConfirmation={cancelSubscription}
          open={showConfirmDialog}
          closeModal={() => {
            setShowConfirmDialog(false);
          }}
          cType={selectedCancellationType}
          cDate={
            selectedCancellationType === SubscriptionCancellationType.CUSTOM.value ? cancelAt : ''
          }
        />
      )}
      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
    </Box>
  );
};

function PastPaymentLogsTable({ pastPaymentLogs }) {
  const classes = useStyles();
  const dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const pastPaymentLogsClmns = [
    { name: 'Sr.No', id: 'srNo', width: '5%', align: 'right' },
    { name: 'Charge ID', id: 'chargeId', width: '55%' },
    { name: 'Date', id: 'created', width: '15%' },
    { name: 'Amount', id: 'amount', width: '15%', align: 'right' },
    { name: 'Status', id: 'status', width: '15%' },
  ];
  return (
    <Box display="flex" flexDirection="column" width="100%" px={1} mt={1.3}>
      <Typography variant="h6" style={{ marginBottom: 10, fontSize: '1rem' }}>
        Recent 10 Transactions
      </Typography>

      <TableContainer component={Paper}>
        <Table className={classes.tableData} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              {pastPaymentLogsClmns.map((col) => (
                <TableCell width={col.width} align={col.align || ''}>
                  <span>{col.name}</span>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {pastPaymentLogs.length > 0 ? (
              pastPaymentLogs.map((row, i) => (
                <TableRow key={`ppl-${row.id}`}>
                  <TableCell align="right">{i + 1}</TableCell>
                  <TableCell>{row.chargeId}</TableCell>
                  <TableCell>{convertContractTimestampToDate(row.created)}</TableCell>
                  <TableCell align="right">{dollarUS.format(row.amount)}</TableCell>
                  <TableCell>
                    <span
                      className={
                        row.status === 'succeeded' ? classes.statusActive : classes.statusInactive
                      }
                    >
                      {row.status}
                    </span>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  align="center"
                  size="medium"
                  colSpan={5}
                  className={classes.noRecord}
                  style={{ padding: 20, fontSize: 15 }}
                >
                  No records found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

PastPaymentLogsTable.propTypes = {
  pastPaymentLogs: PropTypes.arrayOf(PropTypes.any).isRequired,
};

function UpcomingPaymentsTable({ upcomingPaymentLogs }) {
  const classes = useStyles();
  const upcomingPaymentLogsClmns = [
    { name: 'Sr.No', id: 'srNo', width: '3%', align: 'right' },
    { name: 'Billing Date', id: 'billingDate', width: '60%' },
    { name: 'Amount', id: 'amount', width: '15%', align: 'right' },
  ];

  const dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <Box display="flex" flexDirection="column" mt={1.3}>
      <Typography variant="h6" style={{ marginBottom: 10, fontSize: '1rem' }}>
        Upcoming Payments
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.tableData} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              {upcomingPaymentLogsClmns.map((col) => (
                <TableCell width={col.width} align={col.align || ''}>
                  <span>{col.name}</span>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {upcomingPaymentLogs.length > 0 ? (
              upcomingPaymentLogs.map((row, i) => (
                <TableRow>
                  <TableCell align="right">{i + 1}</TableCell>
                  <TableCell>{convertContractTimestampToDate(row.billingDate)}</TableCell>
                  <TableCell align="right">{dollarUS.format(row.amount)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  align="center"
                  size="medium"
                  colSpan={3}
                  className={classes.noRecord}
                  style={{ padding: 20, fontSize: 15 }}
                >
                  No records found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

UpcomingPaymentsTable.propTypes = {
  upcomingPaymentLogs: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const ConfirmCancellation = ({
  open,
  processing,
  closeModal,
  handleConfirmation,
  cType,
  cDate,
}) => {
  const handleDialog = () => {
    closeModal();
  };

  const getDescription = () => {
    switch (cType) {
      case SubscriptionCancellationType.IMMEDIATE.value:
        return 'Are you sure want to Cancel Subscription Immediately?';
      case SubscriptionCancellationType.EOCP.value:
        return 'Are you sure want to Cancel Subscription at End of Contract Period?';
      case SubscriptionCancellationType.EOCT.value:
        return 'Are you sure want to Cancel Subscription at End of Contract Term?';
      case SubscriptionCancellationType.CUSTOM.value:
        return `Are you sure want to Cancel Subscription on ${cDate} 06:30 AM UTC.`;
      default:
        return '';
    }
  };

  return (
    <Dialog open={open} onClose={handleDialog}>
      <DialogTitle id="alert-dialog-title">Cancel Subscription</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{getDescription()}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirmation}
          color="secondary"
          autoFocus
          endIcon={processing && <CircularProgress size={25} />}
          disabled={processing}
        >
          Yes
        </Button>
        <Button onClick={handleDialog} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmCancellation.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleConfirmation: PropTypes.func.isRequired,
  cType: PropTypes.string.isRequired,
  processing: PropTypes.bool.isRequired,
  cDate: PropTypes.string.isRequired,
};

CancelSubscriptionTab.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  refreshModalData: PropTypes.func.isRequired,
  agentId: PropTypes.number.isRequired,
  onImmediateContractReq: PropTypes.func.isRequired,
};

export default CancelSubscriptionTab;
