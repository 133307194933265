import { green } from '@material-ui/core/colors';

const Style = (theme) => ({
  info: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    background: '#80808036',
    padding: '10px',
    marginBottom: '15px',
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    lineHeight: '2.5rem',
    paddingRight: 10,
  },
  infoRowCell: {
    flex: 1,
    paddingRight: 10,
  },
  infoRowAgentName: {
    fontWeight: 'bold',
    fontSize: '20px',
    paddingRight: 10,
  },
  infoRowLocation: {
    color: green[600],
    display: 'flex',
    alignItems: 'center',
    paddingRight: 10,
  },
  contractStatus: {
    marginLeft: '10px',
    marginBottom: '15px',
    flex: 1,
    textAlign: 'end',
    fontSize: '17px',
  },
  tableContainer: {
    margin: 'auto',
    display: 'flex',
    maxWidth: '87%',
  },
  iframe: { height: '1200px', marginTop: 10 },
  btnWrapper: {
    display: 'flex',
    marginTop: 40,
    alignItems: 'center',
  },
  btn: {
    marginRight: 10,
  },
  stepper: { margin: '10px 0 15px' },
  stepperContentWrapper: {
    display: 'flex',
    margin: '25px 0',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
  },
  discountInput: {
    '& input': {
      textAlign: 'end',
    },
  },
  mt10: {
    marginTop: 10,
  },
  failedContractWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    minHeight: '90vh',
    flexDirection: 'column',
  },
  finalMessage: {
    padding: '0 8px',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  linkText: {
    color: green[600],
    textDecoration: 'underline',
  },
  assignBtnContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    marginBottom: '15px',
  },
  assignNewZoneBtn: {
    marginTop: 'auto',
    marginLeft: 'auto',
    width: 'fit-content',
  },
  contractNotes: {
    marginLeft: 10,
    width: '100%',
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
  },

  subInfoItemBold: {
    fontWeight: 'bold',
  },
  subInfoItem: {
    padding: 4,
  },
  viewTable: {
    padding: 0,
    '& .MuiTableCell-root': {
      borderBottom: '0px',
    },
  },
});

export default Style;
