/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  Backdrop,
  Button,
  CircularProgress,
  ClickAwayListener,
  Link,
  Menu,
  MenuItem,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ArrowDownward, Check, ImportExport, MoreVert } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import PrivateWrapper from '../../layout/Private';
import TableToolbar from '../../components/TableToolbar';
import listingStyle from '../../theme/styles/TableListing';
import listAll, { getVideoPreview } from '../../services/Agent';
import FormDialog from '../../components/agent/FormDialog';
import FormDialogMember from '../../components/agent/FormDialogMember';
import ZoneCell from '../../components/agent/ZoneCell';
import ConfirmDialog from '../../components/agent/ConfirmDialog';
import UserForm from '../../components/agent/UserForm';
import SnackbarMessage from '../../components/SnackbarMessage';
import { resetMyPassword, viewUserById } from '../../services/Auth';
import RoutePaths from '../../config/Routes';
import UserTypes from '../../config/UserTypes';
import ManageContractModel from '../../components/contract/ManageContractModel';
import OdisProfileModel from '../../components/agent/OdisProfileModel';
import AgentWebStatus from '../../components/agent/AgentWebStatus';
import EnrollSubscription from '../../components/agent/EnrollSubscription';
import AmendContractDialog from '../../components/contract/AmendContractDialog';
import WarningDialog from '../../components/agent/WarningDialog';
import NuggetsDialog from '../../components/nuggets/NuggetsDialog';
import AdVideosIndicator from '../../components/agent-ad-videos/AdVideosIndicator';
import AdVideosDialog from '../../components/agent-ad-videos/AdVideosDialog';
import InviteTeamMember from '../../components/agent/InviteTeamMember';
import ResendInviteTeamMember from '../../components/agent/ResendInviteTeamMember';
import nuggetsStyle from '../../theme/styles/Nuggets';
import { nuggetStatus, agentTypeValues } from '../../config/DataValues';
import { getAgentType, getAgentTypeLabel } from '../../utils/GetModuleType';

const useStyles = makeStyles(listingStyle);
const useStyles2 = makeStyles(nuggetsStyle);

const ExpandableTableRow = ({
  children,
  leaderRow,
  handleActions,
  handleRowActions,
  searchText,
  expandableData,
}) => {
  const { id, hasTeamProfile, matchedSubProfile } = leaderRow;
  let expandedFlag = false;
  if (matchedSubProfile === 'Yes') {
    expandedFlag = true;
  }
  const [isExpanded, setIsExpanded] = useState(expandedFlag);
  const [rows, setRows] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const classes = useStyles();
  const rowAgentType = getAgentType();

  useEffect(() => {
    setDataLoaded(false);
    setDataLoaded(false);
    if (isExpanded) {
      listAll(1, `searchText=${searchText}&leaderId=${id}`, rowAgentType)
        .then((res) => {
          setRows(res.data.rows);
          expandableData(res.data.rows);
          setDataLoaded(true);
        })
        .catch(() => {
          setDataLoaded(true);
        });
    } else {
      setRows([]);
    }
  }, [isExpanded]);

  return (
    <>
      <TableRow>
        <TableCell padding="normal" size="small">
          {!!hasTeamProfile && (
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? (
                <KeyboardArrowDownIcon fontSize="small" />
              ) : (
                <ChevronRightIcon fontSize="small" />
              )}
            </IconButton>
          )}
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && !dataLoaded && (
        <TableRow>
          <TableCell align="center" size="medium" colSpan={14}>
            <div className={classes.loader}>
              <CircularProgress color="inherit" />
            </div>
          </TableCell>
        </TableRow>
      )}
      {isExpanded &&
        dataLoaded &&
        (rows.length > 0 ? (
          rows.map((row) => (
            <TableRow
              style={{
                backgroundColor: row.matchedRow === 'Yes' ? '#e8f5e9' : '',
              }}
              key={`agent-row-${row.id}`}
            >
              <TableCell
                padding="normal"
                size="small"
                style={{ textAlign: 'right', backgroundClip: 'padding-box' }}
              >
                <SubdirectoryArrowRightIcon fontSize="small" />
              </TableCell>
              <DataRow
                isMember={1}
                row={row}
                handleActions={handleActions}
                handleRowActions={handleRowActions}
              />
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell
              align="center"
              size="medium"
              colSpan={14}
              className={classes.noRecord}
              style={{ padding: 20, fontSize: 15 }}
            >
              No members found.
            </TableCell>
          </TableRow>
        ))}
    </>
  );
};

ExpandableTableRow.propTypes = {
  children: PropTypes.objectOf().isRequired,
  leaderRow: PropTypes.object.isRequired,
  handleActions: PropTypes.func.isRequired,
  handleRowActions: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  expandableData: PropTypes.func.isRequired,
};

const DataRow = ({ isMember, row, handleActions, handleRowActions }) => {
  const dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const classes = useStyles();
  const rowAgentType = getAgentType();

  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;
  const IS_SA_OR_ADMIN = [UserTypes.ADMIN.value, UserTypes.SUPER_ADMIN.value].includes(userType);
  const IS_SUPER_ADMIN = userType === UserTypes.SUPER_ADMIN.value;
  const IS_SUPPORT_LOGIN = userType === UserTypes.SUPPORT.value;
  const IS_SALES_MANAGER_LOGIN = userType === UserTypes.SALES_MANAGER.value;

  const calculateAssets = (agentData) => {
    const finalAssets = [];

    if (agentData.firstName || agentData.lastName) {
      finalAssets.push('Name');
    }
    if (agentData.email) {
      finalAssets.push('Email');
    }
    if (agentData.phone) {
      finalAssets.push('Phone');
    }
    if (agentData.bio) {
      finalAssets.push('Bio');
    }
    if (agentData.profilePic) {
      finalAssets.push('Picture');
    }
    if (agentData.assetId) {
      finalAssets.push('Video');
    }
    if (agentData.location) {
      finalAssets.push('Location');
    }

    return finalAssets;
  };

  return (
    <>
      <TableCell component="th" scope="row">
        {`${row.firstName} ${row.lastName}`}
      </TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell>{row.state}</TableCell>
      <TableCell>
        <span className={row.status === 'Active' ? classes.statusActive : classes.statusInactive}>
          {row.status}
        </span>
      </TableCell>

      {row.agentType !== agentTypeValues.CE_USERS && (
        <>
          <TableCell align="center">
            <AssetsToolTip assets={calculateAssets(row)} agentId={row.id} />
          </TableCell>
          <TableCell align="center">
            <NuggetIndicator
              nuggets={row.nuggets}
              onClick={() => {
                if (IS_SA_OR_ADMIN) {
                  handleRowActions(row, 'nugget-videos');
                }
              }}
              hasAccess={IS_SA_OR_ADMIN}
            />
          </TableCell>

          {rowAgentType === agentTypeValues.AGENTS && (
            <TableCell align="center">
              <AdVideosIndicator
                adVideos={row.adVideos}
                onClick={() => {
                  if (IS_SA_OR_ADMIN) {
                    handleRowActions(row, 'ad-videos');
                  }
                }}
                hasAccess={IS_SA_OR_ADMIN}
              />
            </TableCell>
          )}

          {!IS_SALES_MANAGER_LOGIN && (
            <>
              {rowAgentType === agentTypeValues.AGENTS && (
                <TableCell>
                  <Link
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      if (row.status !== 'Active' || row.agentOdisStatus === 'N/A') {
                        return;
                      }
                      handleRowActions(row, 'odis-status');
                    }}
                    className={classes.contractStatus}
                    target="_blank"
                  >
                    {row.status === 'Active' &&
                      (row.agentOdisStatus === 'N/A' ? '-' : row.agentOdisStatus)}
                    {row.status !== 'Active' &&
                      (row.agentOdisStatus === 'N/A' || row.terminatedContracts === 0
                        ? '-'
                        : 'DEACTIVATED')}
                  </Link>
                </TableCell>
              )}
              <TableCell>
                <Link
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    if (row.status !== 'Active' || row.webStatus === '-') {
                      return;
                    }
                    handleRowActions(row, 'web-status');
                  }}
                  className={classes.contractStatus}
                  target="_blank"
                >
                  {row.status === 'Active' && (row.webStatus === '-' ? '-' : row.webStatus)}
                  {row.status !== 'Active' &&
                    (row.webStatus === '-' || row.terminatedContracts === 0 ? '-' : 'DEACTIVATED')}
                </Link>
              </TableCell>
            </>
          )}

          {!IS_SUPPORT_LOGIN && <TableCell>{!isMember && row.salesPerson}</TableCell>}

          {!IS_SUPPORT_LOGIN && (
            <TableCell>
              {!isMember && row.contractStatus ? (
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    if (row.contractStatus.toUpperCase() === 'TERMINATED') {
                      return;
                    }

                    if (!row.allowEnrollSub) {
                      handleRowActions(row, 'agent-contract');
                    } else if (row.allowEnrollSub && IS_SUPER_ADMIN) {
                      handleActions(row, 'enroll-sub');
                    }
                  }}
                  href="#"
                  className={classes.contractStatus}
                >
                  {row.contractStatus === 'pending-cancelation' && (
                    <Tooltip title={row.toBeCanceledTooltip} placement="top">
                      <span className={classes.statusPendingCancelation}>PENDING CANCELATION</span>
                    </Tooltip>
                  )}
                  {row.contractStatus !== 'pending-cancelation' && row.contractStatus.toUpperCase()}
                </Link>
              ) : (
                !isMember && '-'
              )}
            </TableCell>
          )}
          <TableCell align="right">
            {!isMember && (
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  handleRowActions(row, 'agent-zone');
                }}
                href="#"
                className={classes.linkText}
              >
                {row.agentZone}
              </Link>
            )}
          </TableCell>
          {!IS_SUPPORT_LOGIN && (
            <TableCell align="right">{!isMember && dollarUS.format(row.nrpPrice)}</TableCell>
          )}
        </>
      )}

      <TableCell align="right">
        <ActionMenu
          isMember={isMember}
          id={row.id}
          selectAction={handleActions}
          row={row}
          rowAgentType={rowAgentType}
        />
      </TableCell>
    </>
  );
};

DataRow.propTypes = {
  isMember: PropTypes.number.isRequired,
  row: PropTypes.objectOf().isRequired,
  handleActions: PropTypes.func.isRequired,
  handleRowActions: PropTypes.func.isRequired,
};

// Agents management
const Agents = () => {
  const classes = useStyles();
  const history = useHistory();
  const rowAgentType = getAgentType();
  const pageName = getAgentTypeLabel(rowAgentType, true, true);
  const singularLabel = getAgentTypeLabel(rowAgentType, false, true);
  const singularSmallLabel = getAgentTypeLabel(rowAgentType);

  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;
  const IS_SUPPORT_LOGIN = userType === UserTypes.SUPPORT.value;
  const IS_SALES_MANAGER_LOGIN = userType === UserTypes.SALES_MANAGER.value;

  const query = new URLSearchParams(window.location.search);
  const qagentId = query.get('agentId');
  const autoAddMode = query.get('addMode');

  const [rows, setRows] = useState([]);
  const [subData, setSubData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [reloadRows, setReloadRows] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const [openModel, setOpenModel] = useState(false);
  const [openModelMember, setOpenModelMember] = useState(false);
  const [confirmModel, setConfirmModel] = useState(false);
  const [confirmTeamProfileModel, setConfirmTeamProfileModel] = useState(0);
  // const [isTeamMember, setIsTeamMember] = useState(0);
  const [warningModal, setWarningModal] = useState(false);
  const [userModel, setUserModel] = useState(false);
  const [loginAsConfrimModel, setLoginAsConfrimModel] = useState(false);
  const [manageContract, setManageContract] = useState(false);
  const [nuggetModal, setNuggetModal] = useState(false);
  const [adVideoModal, setAdVideoModal] = useState(false);
  const [showInviteTeamMember, setShowInviteTeamMember] = useState(false);
  const [showResendInvite, setShowResendInvite] = useState(false);

  const [editId, setEditId] = useState(null);
  const [findData, setFindData] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [teamProfileId, setTeamProfileId] = useState(null);
  const [accessEmail, setAccessEmail] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loginId, setLoginId] = useState(null);
  const [doHaveDraftAmendment, setDoHaveDraftAmendment] = useState(false);
  const [nuggetsForAgent, setNuggetsForAgent] = useState(null);
  const [adVideoForAgent, setAdVideoForAgent] = useState(null);

  const [manageZone, setManageZone] = useState(false);
  const [manageZoneData, setManageZoneData] = useState(null);
  const [manageOdisModel, setManageOdisModel] = useState(false);
  const [manageOdisData, setManageOdisData] = useState(null);
  const [manageWebStatusModel, setManageWebStatusModel] = useState(false);
  const [manageWebStatusData, setManageWebStatusData] = useState(null);

  const [enrollSubModel, setEnrollSubModel] = useState(false);
  const [enrollSubData, setEnrollSubData] = useState(null);
  const [reactivateFlow, setReactivateFlow] = useState(false);
  const [searchId, setSearchId] = useState(0);

  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [processingRequestResetPwd, setProcessingRequestResetPwd] = useState(false);

  const columns = [];
  if (rowAgentType === agentTypeValues.CE_USERS) {
    columns.push({ name: 'Name', id: 'name', width: '30%' });
    columns.push({ name: 'Email', id: 'email', width: '30%' });
    columns.push({ name: 'State', id: 'state', width: '15%' });
    columns.push({ name: 'Status', id: 'status', width: '15%' });
  } else {
    columns.push({ name: 'Name', id: 'name', width: '12%' });
    columns.push({ name: 'Email', id: 'email', width: '12%' });
    columns.push({ name: 'State', id: 'state', width: '8%' });
    columns.push({ name: 'Status', id: 'status', width: '8%' });
    columns.push({ name: 'Assets', id: 'assets', width: '5%', align: 'center' });

    if (rowAgentType === agentTypeValues.LENDERS) {
      columns.push(...[{ name: 'Lender Minutes', id: 'nuggets', width: '13%', align: 'right' }]);
    } else {
      columns.push(...[{ name: 'Nuggets', id: 'nuggets', width: '5%', align: 'center' }]);
      columns.push(...[{ name: 'Show Intro', id: 'adVideos', width: '8%', align: 'center' }]);
    }

    if (!IS_SALES_MANAGER_LOGIN) {
      if (rowAgentType === agentTypeValues.LENDERS) {
        columns.push(...[{ name: 'Web Status', id: 'webStatus', width: '10%' }]);
      } else {
        columns.push(
          ...[
            { name: 'ODIS Status', id: 'agentOdisStatus', width: '10%' },
            { name: 'Web Status', id: 'webStatus', width: '10%' },
          ]
        );
      }
    }
    if (!IS_SUPPORT_LOGIN) {
      columns.push(
        ...[
          { name: 'Sales Person', id: 'salesPerson', width: '12%' },
          { name: 'Contract Status', id: 'contractStatus', width: '10%' },
        ]
      );
    }
    columns.push({ name: 'Zones', id: 'agentZone', width: '8%', align: 'right' });

    if (!IS_SUPPORT_LOGIN) {
      columns.push({
        name: 'NRP',
        id: 'nrpPrice',
        width: '8%',
        align: 'right',
      });
    }
  }

  const getParams = () => {
    let queryString = '1=1';
    if (qagentId) queryString += `&agentId=${qagentId}`;
    if (sortBy) queryString += `&sortBy=${sortBy}`;
    if (orderBy) queryString += `&orderBy=${orderBy}`;
    if (searchText && !searchId) queryString += `&searchText=${searchText}`;
    if (searchId) queryString += `&id=${searchId}`;
    if (query.get('paidAgents')) queryString += `&onlyPaidAgents=${1}`;
    return queryString.substring(4);
  };

  useEffect(() => {
    setDataLoaded(false);
    listAll(activePage, getParams(), rowAgentType)
      .then((res) => {
        setRows(res.data.rows);
        setTotalPages(res.data.totalPages);
        setDataLoaded(true);
        if (autoAddMode) {
          setOpenModel(true);
        }
      })
      .catch(() => {
        setDataLoaded(true);
      });
  }, [reloadRows, activePage]);

  useEffect(() => {
    setDataLoaded(false);
    setActivePage(1);
    setSearchText('');
    setSortBy('');
    setOrderBy('');
    setReloadRows(!reloadRows);
  }, [rowAgentType]);

  const searchList = () => {
    setActivePage(1);
    setReloadRows(!reloadRows);
  };

  const sortHandler = (colId) => {
    setActivePage(1);
    setSortBy(colId);
    setOrderBy(orderBy === 'asc' ? 'desc' : 'asc');
    setReloadRows(!reloadRows);
  };

  const refreshList = () => {
    if (window.localStorage.getItem('agentEmail')) {
      const tmpSearchText = window.localStorage.getItem('agentEmail');
      setSearchText(encodeURIComponent(tmpSearchText));
      window.localStorage.removeItem('agentEmail');
      setActivePage(1);
      setReloadRows(!reloadRows);
    } else {
      setReloadRows(!reloadRows);
    }
  };

  const editData = (id, isMember) => {
    setEditId(id);
    if (!isMember) {
      setOpenModel(true);
    } else {
      setOpenModelMember(true);
    }
  };

  const userAccess = (email, id, uid) => {
    setAccessEmail(email);
    setEditId(id);
    setUserId(uid);
    setUserModel(true);
  };

  const deleteData = (id) => {
    setDeleteId(id);
    setConfirmModel(true);
  };

  const setTeamProfileData = (id, actionId) => {
    setTeamProfileId(id);
    setConfirmTeamProfileModel(actionId); // 1 for enable team profile, 2 for disable team profile
  };

  const openNuggetsForAgent = (id) => {
    setNuggetsForAgent(id);
    setNuggetModal(true);
  };

  const openAdVideosForAgent = (id) => {
    setAdVideoForAgent(id);
    setAdVideoModal(true);
  };

  const closeNuggetDialog = () => {
    setNuggetsForAgent(null);
    setNuggetModal(false);
    setReloadRows(!reloadRows);
  };

  const closeAdVideoDialog = () => {
    setAdVideoForAgent(null);
    setAdVideoModal(false);
    setReloadRows(!reloadRows);
  };

  const resetList = () => {
    setSearchText('');
    setOrderBy('');
    setSortBy('');
    setReloadRows(!reloadRows);
    setActivePage(1);
    setSearchId(0);
    if (rowAgentType === agentTypeValues.LENDERS) {
      history.push(RoutePaths.LENDERS);
    } else if (rowAgentType === agentTypeValues.AGENTS) {
      history.push(RoutePaths.AGENTS);
    } else {
      history.push(RoutePaths.CE_USERS);
    }
  };

  const requestResetPassword = (id) => {
    setProcessingRequestResetPwd(true);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: '',
      type: '',
      show: false,
    });
    viewUserById(id)
      .then((res) => {
        resetMyPassword({ userName: res.data.username })
          .then((resp) => {
            setProcessingRequestResetPwd(false);
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: resp.message,
              type: 'success',
              show: true,
            });
          })
          .catch(({ response }) => {
            setSnackbarMeesage({
              ...snackbarMeesage,
              message: response.data.message,
              type: 'error',
              show: true,
            });
            setProcessingRequestResetPwd(false);
          });
      })
      .catch(({ response }) => {
        setSnackbarMeesage({
          ...snackbarMeesage,
          message: response.data.message,
          type: 'error',
          show: true,
        });
        setProcessingRequestResetPwd(false);
      });
  };

  const handleToggleWarning = () => {
    setWarningModal(!warningModal);
  };

  const handleActions = (row, action, isMember = 0) => {
    if (action === 'edit') {
      editData(row.id, isMember);
    } else if (action === 'delete') {
      if (row.agentZone > 0 && isMember === 0) {
        handleToggleWarning();
      } else {
        deleteData(row.id);
      }
    } else if (action === 'access') {
      userAccess(row.email, row.id, row.userID);
    } else if (action === 'reset-pwd') {
      requestResetPassword(row.userID);
    } else if (action === 'login-as-agent') {
      // set data into one variable as per isMember
      // If isMember is 1 then we need to set expand data otherwise set main rows data
      // eslint-disable-next-line no-unused-expressions
      isMember ? setFindData(subData) : setFindData(rows);
      setLoginId(row.id);
      setLoginAsConfrimModel(true);
    } else if (action === 'contract') {
      setManageContract(true);
      setEditId(row.id);
      setDoHaveDraftAmendment(!!row.draftAmendment);
    } else if (action === 'manage-zones') {
      setDoHaveDraftAmendment(!!row.draftAmendment);
      setManageZoneData(row);
      setManageZone(true);
    } else if (action === 'enroll-sub') {
      setEnrollSubData(row);
      setEnrollSubModel(true);
    } else if (action === 're-activate') {
      setEnrollSubData(row);
      setReactivateFlow(true);
    } else if (action === 'enable-team-profile') {
      // show confirmation and complete, reload data
      setTeamProfileData(row.id, 1);
    } else if (action === 'disable-team-profile') {
      // show confirmation and complete (if no sub profiles)
      setTeamProfileData(row.id, 2);
    } else if (action === 'resend-member-invitation') {
      setEditId(row.id);
      setShowResendInvite(true);
    } else if (action === 'invite-team-member') {
      setEditId(row.id);
      setShowInviteTeamMember(true);
    }
  };

  const handleRowActions = (row, action) => {
    if (action === 'agent-zone') {
      setManageZoneData(row);
      setManageZone(true);
      setDoHaveDraftAmendment(!!row.draftAmendment);
    } else if (action === 'agent-contract') {
      setManageContract(true);
      setEditId(row.id);
      setDoHaveDraftAmendment(!!row.draftAmendment);
    } else if (action === 'web-status') {
      setManageWebStatusData(row);
      setManageWebStatusModel(true);
    } else if (action === 'odis-status') {
      setManageOdisModel(true);
      setManageOdisData(row.id);
    } else if (action === 'ad-videos') {
      openAdVideosForAgent(row.id);
    } else if (action === 'nugget-videos') {
      openNuggetsForAgent(row.id);
    }
  };

  const successWebStatusModal = () => {
    setSnackbarMeesage({
      ...snackbarMeesage,
      show: false,
    });
    setManageWebStatusModel(false);
    setManageWebStatusData(null);
    setSnackbarMeesage({
      ...snackbarMeesage,
      message: 'Web status updated successfully.',
      type: 'success',
      show: true,
    });
    setReloadRows(!reloadRows);
  };
  const closeWebStatusModal = () => {
    setManageWebStatusModel(false);
    setManageWebStatusData(null);
  };
  const processSearchString = (str) => {
    if (str.startsWith('ID:') && str.length > 3) {
      const idSearch = Number(str.split(':')[1]);
      if (idSearch > 0) {
        setSearchId(idSearch);
      }
    } else {
      setSearchId(0);
    }
  };

  const expandableData = (data) => {
    if (data) {
      setSubData(data);
    }
  };

  return (
    <PrivateWrapper pageName={pageName}>
      <div className={classes.filterToolbar}>
        <TableToolbar
          refreshList={refreshList}
          searchList={searchList}
          getSearchText={(e) => {
            processSearchString(e.target.value);
            setSearchText(encodeURIComponent(e.target.value));
          }}
          addNew={() => setOpenModel(true)}
          resetList={resetList}
          showAddOption={!IS_SUPPORT_LOGIN}
          inputSearch={decodeURIComponent(searchText)}
          forceInputTextChange
        />
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.tableData} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell padding="normal" sizze="small" />
              {columns.map((col) => (
                <TableCell width={col.width} align={col.align || ''} key={`agent-col-${col.id}`}>
                  {!['assets', 'nuggets', 'adVideos'].includes(col.id) ? (
                    <TableSortLabel
                      active={sortBy === col.id}
                      direction={sortBy === col.id ? orderBy : 'asc'}
                      onClick={() => sortHandler(col.id)}
                      IconComponent={sortBy !== col.id ? ImportExport : ArrowDownward}
                    >
                      <span>{col.name}</span>
                    </TableSortLabel>
                  ) : (
                    col.name
                  )}
                </TableCell>
              ))}
              <TableCell align="right" width="15%">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!dataLoaded &&
              [1, 2, 3, 4, 5].map((val) => (
                <TableRow key={`ag-${val}`}>
                  <TableCell>
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                  {columns.map((cs) => (
                    <TableCell key={`ag-${cs.id}-${val}`}>
                      <Skeleton variant="text" className={classes.placeholderSkeleton} />
                    </TableCell>
                  ))}
                  <TableCell>
                    <Skeleton variant="text" className={classes.placeholderSkeleton} />
                  </TableCell>
                </TableRow>
              ))}
            {dataLoaded &&
              (rows.length > 0 ? (
                rows.map((row) => (
                  <ExpandableTableRow
                    key={`ag-${row.id}`}
                    leaderRow={row}
                    handleActions={handleActions}
                    handleRowActions={handleRowActions}
                    searchText={searchText}
                    expandableData={expandableData}
                  >
                    <DataRow
                      isMember={0}
                      row={row}
                      handleActions={handleActions}
                      handleRowActions={handleRowActions}
                    />
                  </ExpandableTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    align="center"
                    size="medium"
                    colSpan={columns.length + 2}
                    className={classes.noRecord}
                    style={{ padding: 20, fontSize: 15 }}
                  >
                    No records found
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {dataLoaded && totalPages > 1 && (
        <Pagination
          count={totalPages}
          showFirstButton
          showLastButton
          className={classes.tablePagination}
          onChange={(_, pageNumber) => {
            setActivePage(pageNumber);
            setReloadRows(!reloadRows);
          }}
          page={activePage}
        />
      )}
      {openModel && (
        <FormDialog
          open={openModel}
          closeModel={() => {
            setOpenModel(false);
            setEditId(null);
            if (autoAddMode) {
              if (rowAgentType === agentTypeValues.LENDERS) {
                history.push(RoutePaths.LENDERS);
              } else {
                history.push(RoutePaths.AGENTS);
              }
            }
          }}
          title={editId ? `Edit ${singularLabel}` : `Add ${singularLabel}`}
          onSuccess={() => {
            setReloadRows(!reloadRows);
          }}
          editId={editId}
          module={rowAgentType}
        />
      )}
      {openModelMember && (
        <FormDialogMember
          open={openModelMember}
          closeModel={() => {
            setOpenModelMember(false);
            setEditId(null);
            if (autoAddMode) {
              if (rowAgentType === agentTypeValues.LENDERS) {
                history.push(RoutePaths.LENDERS);
              } else {
                history.push(RoutePaths.AGENTS);
              }
            }
          }}
          title={editId ? `Edit ${singularLabel}` : `Add ${singularLabel}`}
          onSuccess={() => {
            setReloadRows(!reloadRows);
          }}
          editId={editId}
          module={rowAgentType}
        />
      )}
      {confirmModel && (
        <ConfirmDialog
          open={confirmModel}
          closeModel={() => {
            setConfirmModel(false);
            setDeleteId(null);
          }}
          title={`Delete ${singularLabel}`}
          description="Do you want to delete this record? This action can not be undone."
          onSuccess={() => {
            setReloadRows(!reloadRows);
          }}
          deleteId={deleteId}
          rowAgentType={rowAgentType}
        />
      )}
      {confirmTeamProfileModel !== 0 && (
        <ConfirmDialog
          open={confirmTeamProfileModel}
          closeModel={() => {
            setConfirmTeamProfileModel(0);
            setTeamProfileId(null);
          }}
          title={`${confirmTeamProfileModel === 1 ? 'Enable' : 'Disable'} Team Profile`}
          description={`Do you want to ${
            confirmTeamProfileModel === 1 ? 'enable' : 'disable'
          } team profile for this ${singularLabel.toLowerCase()}?`}
          onSuccess={() => {
            setReloadRows(!reloadRows);
          }}
          action={`${confirmTeamProfileModel === 1 ? 'Enable' : 'Disable'}Profile`}
          teamProfileId={teamProfileId}
          rowAgentType={rowAgentType}
        />
      )}
      {warningModal && (
        <WarningDialog
          title="Warning"
          description={`${singularLabel} can not be deleted. Please release zones from assigned zones section in order to delete this record.`}
          closeModel={handleToggleWarning}
        />
      )}

      {userModel && (
        <UserForm
          open={userModel}
          closeModel={() => {
            setUserModel(false);
            setAccessEmail(null);
            setEditId(null);
            setUserId(null);
          }}
          title="Assign user access to"
          onSuccess={() => {
            setReloadRows(!reloadRows);
          }}
          accessEmail={accessEmail}
          agentId={editId}
          userId={userId}
        />
      )}

      {manageContract &&
        (doHaveDraftAmendment ? (
          <AmendContractDialog
            open={manageContract}
            closeModal={() => {
              setManageContract(false);
              setDoHaveDraftAmendment(false);
              setEditId(null);
              refreshList();
            }}
            onCancelAmendment={() => {
              setReloadRows(!reloadRows);
              setDoHaveDraftAmendment(false);
            }}
            reloadAgentData={() => {
              setReloadRows(!reloadRows);
              setManageContract(false);
              setDoHaveDraftAmendment(false);
            }}
            agentId={editId}
            zoneType={rowAgentType}
          />
        ) : (
          <ManageContractModel
            open={manageContract}
            closeModel={() => {
              setManageContract(false);
              setDoHaveDraftAmendment(false);
              setEditId(null);
              refreshList();
            }}
            onSuccess={() => {
              setReloadRows(!reloadRows);
            }}
            onAmendContract={() => {
              setReloadRows(!reloadRows);
              setDoHaveDraftAmendment(true);
            }}
            agentId={editId}
            zoneType={rowAgentType}
          />
        ))}

      {loginAsConfrimModel && (
        <ConfirmDialog
          open={loginAsConfrimModel}
          closeModel={() => {
            setLoginAsConfrimModel(false);
            setLoginId(null);
          }}
          title={
            findData.find((v) => v.id === loginId).hasAccess > 0
              ? `Login as ${singularLabel}`
              : `Access ${singularLabel} Account`
          }
          description={
            findData.find((v) => v.id === loginId).hasAccess > 0
              ? `Are you sure you want to login as ${singularSmallLabel}? You will be redirected to the ${singularSmallLabel} information view.`
              : `The ${singularSmallLabel} doesn't have profile access assigned yet. Do you still want to access the ${singularSmallLabel}'s account?`
          }
          onSuccess={() => {
            setLoginAsConfrimModel(false);
            setLoginId(null);
          }}
          deleteId={null}
          loginId={loginId}
          action="loginAs"
          rowAgentType={rowAgentType}
        />
      )}

      {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      {processingRequestResetPwd && (
        <Backdrop open className={classes.backdrop}>
          <CircularProgress color="inherit" />
          <Typography variant="body2">Processing Request...</Typography>
        </Backdrop>
      )}

      {manageZone && (
        <ZoneCell
          doHaveDraftAmendment={doHaveDraftAmendment}
          manageZoneData={manageZoneData}
          onClose={() => {
            setManageZone(false);
            setManageZoneData(null);
            refreshList();
          }}
          zoneType={rowAgentType}
        />
      )}

      {manageOdisModel && (
        <OdisProfileModel
          onClose={() => {
            setManageOdisData(null);
            setManageOdisModel(false);
            refreshList();
          }}
          agentId={manageOdisData}
        />
      )}

      {manageWebStatusModel && (
        <AgentWebStatus
          webUrl={manageWebStatusData.webUrl}
          webStatus={manageWebStatusData.webStatus}
          successCallback={successWebStatusModal}
          cancelCallback={closeWebStatusModal}
          agentId={manageWebStatusData.id}
          rowAgentType={rowAgentType}
        />
      )}

      {enrollSubModel && (
        <EnrollSubscription
          onClose={() => {
            setEnrollSubData(null);
            setEnrollSubModel(false);
            refreshList();
          }}
          data={enrollSubData}
          zoneType={rowAgentType}
          showReactivationFlow={false}
        />
      )}
      {reactivateFlow && (
        <EnrollSubscription
          onClose={() => {
            setEnrollSubData(null);
            setEnrollSubModel(false);
            setReactivateFlow(false);
            refreshList();
          }}
          data={enrollSubData}
          zoneType={rowAgentType}
          showReactivationFlow
        />
      )}

      {nuggetModal && (
        <NuggetsDialog
          handleClose={closeNuggetDialog}
          agentId={nuggetsForAgent}
          rowAgentType={rowAgentType}
        />
      )}
      {adVideoModal && (
        <AdVideosDialog
          handleClose={closeAdVideoDialog}
          agentId={adVideoForAgent}
          rowAgentType={rowAgentType}
        />
      )}
      {showInviteTeamMember && (
        <InviteTeamMember
          closeModel={() => {
            setEditId(null);
            setShowInviteTeamMember(false);
          }}
          onSuccess={() => {
            setEditId(null);
            setShowInviteTeamMember(false);
            refreshList();
          }}
          agentId={editId}
          rowAgentType={rowAgentType}
        />
      )}
      {showResendInvite && (
        <ResendInviteTeamMember
          closeModel={() => {
            setEditId(null);
            setShowResendInvite(false);
          }}
          onSuccess={() => {
            setEditId(null);
            setShowResendInvite(false);
            refreshList();
          }}
          agentId={editId}
        />
      )}
    </PrivateWrapper>
  );
};

const ActionMenu = ({ isMember, row, selectAction, rowAgentType }) => {
  const getDetails = window.localStorage.getItem('userDetail');
  const userType = getDetails ? Number(JSON.parse(getDetails).type) : 0;
  const ALLOW_LOGIN_AS =
    userType === UserTypes.SUPER_ADMIN.value ||
    userType === UserTypes.ADMIN.value ||
    userType === UserTypes.SUPPORT.value;
  const IS_ADMIN_LOGIN =
    userType === UserTypes.SUPER_ADMIN.value || userType === UserTypes.ADMIN.value;
  const IS_SUPPORT_LOGIN = userType === UserTypes.SUPPORT.value;
  const ALLOW_ENROLL = userType === UserTypes.SUPER_ADMIN.value;
  const label = getAgentTypeLabel(rowAgentType, false, true);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (action) => {
    selectAction(row, action, isMember);
    handleClose();
  };
  return (
    <div>
      <Button aria-controls={`simple-menu${row.id}`} aria-haspopup="true" onClick={handleClick}>
        <MoreVert />
      </Button>
      <Menu
        id={`simple-menu${row.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!IS_SUPPORT_LOGIN && (
          <MenuItem
            onClick={() => {
              handleMenuClick('access', isMember);
            }}
            button
          >
            {row.hasAccess > 0 ? 'Manage Access' : 'Assign Access'}
          </MenuItem>
        )}
        {!isMember && rowAgentType !== agentTypeValues.CE_USERS && (
          <MenuItem
            onClick={() => {
              handleMenuClick('manage-zones', isMember);
            }}
            button
          >
            Manage Zones
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleMenuClick('edit', isMember);
          }}
          button
        >
          Edit
        </MenuItem>
        {!IS_SUPPORT_LOGIN && (
          <MenuItem
            onClick={() => {
              handleMenuClick('delete', isMember);
            }}
            button
          >
            Delete
          </MenuItem>
        )}
        {(row.agentZone > 0 || row.draftAmendment) &&
          rowAgentType !== agentTypeValues.CE_USERS &&
          !IS_SUPPORT_LOGIN &&
          !row.allowEnrollSub &&
          !isMember && (
            <MenuItem
              onClick={() => {
                handleMenuClick('contract', isMember);
              }}
              button
            >
              Manage Contract
            </MenuItem>
          )}
        {row.allowEnrollSub &&
          ALLOW_ENROLL &&
          rowAgentType !== agentTypeValues.CE_USERS &&
          !isMember && (
            <MenuItem
              onClick={() => {
                handleMenuClick('enroll-sub', isMember);
              }}
              button
            >
              Enroll Subscription
            </MenuItem>
          )}
        {row.allowReactivate &&
          !IS_SUPPORT_LOGIN &&
          rowAgentType !== agentTypeValues.CE_USERS &&
          !isMember && (
            <MenuItem
              onClick={() => {
                handleMenuClick('re-activate', isMember);
              }}
              button
            >
              Reactivate Subscription
            </MenuItem>
          )}
        {ALLOW_LOGIN_AS && (
          <MenuItem
            onClick={() => {
              handleMenuClick('login-as-agent', isMember);
            }}
            button
            disabled={row.status !== 'Active'}
          >
            Login as {label}
          </MenuItem>
        )}
        {row.userID > 0 && (
          <MenuItem
            onClick={() => {
              handleMenuClick('reset-pwd', isMember);
            }}
            button
            disabled={row.status !== 'Active'}
          >
            Request Reset Password
          </MenuItem>
        )}
        {IS_ADMIN_LOGIN &&
          !isMember &&
          row.hasTeamProfile === 0 &&
          rowAgentType !== agentTypeValues.CE_USERS && (
            <MenuItem
              onClick={() => {
                handleMenuClick('enable-team-profile', isMember);
              }}
              button
            >
              Enable Team Profile
            </MenuItem>
          )}
        {IS_ADMIN_LOGIN &&
          !isMember &&
          row.hasTeamProfile === 1 &&
          rowAgentType !== agentTypeValues.CE_USERS && (
            <MenuItem
              onClick={() => {
                handleMenuClick('disable-team-profile', isMember);
              }}
              button
            >
              Disable Team Profile
            </MenuItem>
          )}
        {IS_ADMIN_LOGIN &&
          !isMember &&
          row.hasTeamProfile === 1 &&
          rowAgentType !== agentTypeValues.CE_USERS && (
            <MenuItem
              onClick={() => {
                handleMenuClick('invite-team-member', isMember);
              }}
              button
            >
              Invite Team Member
            </MenuItem>
          )}
        {IS_ADMIN_LOGIN &&
          !!isMember &&
          row.status === 'Pending' &&
          rowAgentType !== agentTypeValues.CE_USERS && (
            <MenuItem
              onClick={() => {
                handleMenuClick('resend-member-invitation', isMember);
              }}
              button
            >
              Resend Invitation
            </MenuItem>
          )}
      </Menu>
    </div>
  );
};
ActionMenu.propTypes = {
  isMember: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
  selectAction: PropTypes.func.isRequired,
  rowAgentType: PropTypes.number.isRequired,
};

const AssetsToolStyle = withStyles(() => ({
  tooltip: {
    backgroundColor: '#fff',
    border: '1px solid #dadde9',
    maxWidth: '350px',
  },
}))(Tooltip);

export const AssetsToolTip = ({ assets, agentId }) => {
  const [open, setOpen] = React.useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [fetchVideoUrl, setFetchVideoUrl] = useState();

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const getVideoUrl = () => {
    setFetchVideoUrl(true);
    getVideoPreview(agentId)
      .then((res) => {
        setVideoUrl(res.data.previewUrl);
      })
      .catch(() => {
        setFetchVideoUrl(false);
      });
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <AssetsToolStyle
          PopperProps={{
            disablePortal: true,
          }}
          interactive
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <TableContainer component={Paper}>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{assets.includes('Name') ? <Check color="primary" /> : '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>{assets.includes('Email') ? <Check color="primary" /> : '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Phone</TableCell>
                <TableCell>{assets.includes('Phone') ? <Check color="primary" /> : '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Picture</TableCell>
                <TableCell>
                  {assets.includes('Picture') ? <Check color="primary" /> : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Location</TableCell>
                <TableCell>
                  {assets.includes('Location') ? <Check color="primary" /> : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Bio</TableCell>
                <TableCell>{assets.includes('Bio') ? <Check color="primary" /> : '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Video</TableCell>
                <TableCell>{assets.includes('Video') ? <Check color="primary" /> : '-'}</TableCell>
              </TableRow>
              {assets.includes('Video') && (
                <TableRow>
                  <TableCell
                    colSpan="2"
                    align="center"
                    style={{ wordBreak: 'break-all', textAlign: 'justify', maxWidth: '350px' }}
                  >
                    {videoUrl === '' && (
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={getVideoUrl}
                        disabled={fetchVideoUrl}
                      >
                        {fetchVideoUrl ? 'Wait' : 'Get Video Link'}
                      </Button>
                    )}
                    {videoUrl !== '' && (
                      <>
                        <strong>Preview:</strong>
                        <br />
                        {videoUrl}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableContainer>
          }
        >
          <Button onClick={handleTooltipOpen}>{`${assets.length}/7`}</Button>
        </AssetsToolStyle>
      </div>
    </ClickAwayListener>
  );
};
AssetsToolTip.propTypes = {
  assets: PropTypes.arrayOf().isRequired,
  agentId: PropTypes.number.isRequired,
};

export const NuggetIndicator = ({ nuggets, onClick, hasAccess }) => {
  const classes2 = useStyles2();

  const TOTAL_ALLOWED_NUGGETS = 4;
  const [agentNuggetStatus, setagentNuggetStatus] = useState('checked');
  useEffect(() => {
    if (nuggets) {
      if (nuggets.length > 0) {
        nuggets.forEach((nugget) => {
          if (nugget.status === nuggetStatus.PENDING) {
            setagentNuggetStatus('pending');
          }
        });
      } else {
        setagentNuggetStatus('na');
      }
    }
  }, [nuggets]);

  const pending = classes2.pendingNuggetIndicator;
  const checked = classes2.checkedNuggetIndicator;
  const status = agentNuggetStatus;
  const indicatorClass =
    status === 'pending' ? pending : status === 'checked' ? checked : classes2.noNuggetIndicator;

  if (!hasAccess) {
    return (
      <Button
        className={indicatorClass}
        style={{ cursor: 'auto' }}
        disableElevation
        disableRipple
      >{`${nuggets.length}/${TOTAL_ALLOWED_NUGGETS}`}</Button>
    );
  }

  return (
    <Button
      className={indicatorClass}
      onClick={onClick}
    >{`${nuggets.length}/${TOTAL_ALLOWED_NUGGETS}`}</Button>
  );
};

NuggetIndicator.propTypes = {
  nuggets: PropTypes.arrayOf().isRequired,
  onClick: PropTypes.func.isRequired,
  hasAccess: PropTypes.bool.isRequired,
};
export default Agents;
