import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import ConfirmDialog from './ConfirmDialog';
import formInput from '../../theme/styles/FormInput';
import { viewUserById, createUser, updateUser } from '../../services/Auth';
import Validations, {
  MaxLengthValidationFunc,
  MinLengthValidationFunc,
} from '../../utils/Validations';
import SnackbarMessage from '../SnackbarMessage';

const useStyles = makeStyles(formInput);

const UserForm = ({ open, accessEmail, teamId, userId, closeModel, title, onSuccess }) => {
  const classes = useStyles();

  const [processing, setProcessing] = useState(false);
  const [snackbarMeesage, setSnackbarMeesage] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [confirmModel, setConfirmModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const getDetails = window.localStorage.getItem('userDetail');
  const storedUserId = getDetails ? Number(JSON.parse(getDetails).id) : 0;

  const { handleSubmit, control, setValue, reset, getValues } = useForm();

  useEffect(() => {
    if (userId) {
      viewUserById(userId).then((res) => {
        setValue('userName', res.data.username);
        setValue('password', res.data.saltKey);
        setValue('status', res.data.status);
      });
    } else {
      setValue('status', 1);
    }
  }, []);

  const handleDialog = () => {
    reset();
    closeModel();
  };
  const deleteUserAccess = () => {
    if (userId) {
      setDeleteId(userId);
      setConfirmModel(true);
    }
  };
  const submitForm = ({ userName, password, status }) => {
    setProcessing(true);
    const payload = {
      userName,
      password,
      createFor: 'team',
      email: accessEmail,
      personId: teamId,
      status: Number(status),
      canLogin: 'YES',
    };

    if (accessEmail && !userId) {
      createUser(payload)
        .then(() => {
          setProcessing(false);
          handleDialog();
          onSuccess();
        })
        .catch(({ response }) => {
          setProcessing(false);
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.data.message,
            type: 'error',
            show: true,
          });
          setProcessing(false);
        });
    }
    if (userId) {
      updateUser(userId, { status: Number(status) })
        .then(() => {
          setProcessing(false);
          handleDialog();
          onSuccess();
        })
        .catch(({ response }) => {
          setProcessing(false);
          setSnackbarMeesage({
            ...snackbarMeesage,
            message: response.data.message,
            type: 'error',
            show: true,
          });
          setProcessing(false);
        });
    }
  };

  const defaultFormValues = getValues();

  return (
    <Dialog open={open} onClose={handleDialog} fullWidth>
      <DialogTitle>{`${title} ${accessEmail}`}</DialogTitle>
      <form onSubmit={handleSubmit(submitForm)}>
        <DialogContent className={classes.dialogContent}>
          <Controller
            control={control}
            rules={{
              ...MaxLengthValidationFunc(250),
              ...Validations.REQUIRED_EMAIL,
            }}
            name="userName"
            id="userName"
            defaultValue={defaultFormValues.userName || accessEmail}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="User Name*"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
                disabled
              />
            )}
          />
          <Controller
            control={control}
            rules={{
              ...Validations.REQUIRED,
              ...MaxLengthValidationFunc(80),
              ...MinLengthValidationFunc(6),
            }}
            name="password"
            id="password"
            defaultValue={defaultFormValues.password || ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Password*"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                fullWidth
                type="password"
                disabled={userId}
              />
            )}
          />
          <Controller
            control={control}
            rules={Validations.REQUIRED}
            name="status"
            id="status"
            defaultValue={defaultFormValues.status || 0}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                variant="outlined"
                label="Status*"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                className={classes.formInput}
                SelectProps={{
                  native: true,
                }}
                fullWidth
                select
                disabled={userId === storedUserId}
              >
                <option key={1} value={1}>
                  Active
                </option>
                <option key={0} value={0}>
                  Inactive
                </option>
              </TextField>
            )}
          />
        </DialogContent>
        <DialogActions>
          {userId > 0 && (
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              disabled={processing}
              onClick={deleteUserAccess}
            >
              Revoke Access
            </Button>
          )}

          <Button
            variant="contained"
            color="primary"
            disableElevation
            type="submit"
            endIcon={processing && <CircularProgress size={25} />}
            disabled={processing}
          >
            {userId ? `Submit` : `Assign Access`}
          </Button>
          <Button variant="contained" onClick={handleDialog} disableElevation>
            Cancel
          </Button>
        </DialogActions>
        {confirmModel && (
          <ConfirmDialog
            open={confirmModel}
            closeModel={() => {
              setConfirmModel(false);
              setDeleteId(null);
            }}
            title={`Revoke access of ${accessEmail}`}
            onSuccess={() => {
              handleDialog();
              onSuccess();
            }}
            deleteId={deleteId}
            removeFor="user"
          />
        )}
        {snackbarMeesage.show && <SnackbarMessage {...snackbarMeesage} />}
      </form>
    </Dialog>
  );
};

UserForm.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  teamId: PropTypes.number,
  userId: PropTypes.number,
  accessEmail: PropTypes.string,
  title: PropTypes.string,
  removeFor: PropTypes.string,
};
UserForm.defaultProps = {
  accessEmail: '',
  teamId: 0,
  userId: null,
  title: '',
  removeFor: '',
};

export default UserForm;
